import { Component, OnInit } from '@angular/core';
import { ToastController, ModalController, NavController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../../service/util.service';
import { UserProfile } from '../../../../models/user-profile.model';
import * as XLSX from 'xlsx';
import { ReportService } from '../../report.service';
import { MonthName } from '../../../../enum/MonthName';
import { ReportStockComponent } from '../../../../component/report-stock/report-stock.component';

@Component({
  selector: 'app-report-stock',
  templateUrl: './report-stock.page.html',
  styleUrls: ['./report-stock.page.scss'],
})
export class ReportStockPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  formStockReport: FormGroup;
  warehouseList: any[] = [];
  reportData: any[] = [];
  stockField: any[] = [];

  earlyField: any = 'early_01';
  inField: any = 'in_01';
  outField: any = 'out_01';
  endField: any = 'end_01';

  year: any;
  month: any = '01';
  monthName: any;

  fileName= 'ReportStock.xlsx';
  rows: any;

  nameofMonth = MonthName;
  nameOfMonthList = Object.keys(MonthName).filter(
    nameofMonth => typeof this.nameofMonth[nameofMonth] === 'number'
  );

  reportDate: any;
  keywordSearch: any;
  warehouseId: any;

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private fb: FormBuilder,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    let todayDate = new Date();
    let convertDate = this.utilService.convertDate(todayDate);
    this.month = convertDate.months;

    this.buildStockReportForm();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.generateReport(convertDate.years, convertDate.months, '0', undefined);
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  async openMutationStockDetail(warehouseId: any, productId: any, 
    earlyStock: any, inStock: any, outStock: any, endStock: any,type:any) {
    const modal = await this.modalController.create({
      component: ReportStockComponent,
      componentProps: {
        'warehouseId': warehouseId,
        'productId': productId,
        'reportDate': this.reportDate,
        'earlyStock': earlyStock,
        'inStock': inStock,
        'outStock': outStock,
        'endStock': endStock,
        'type' : type
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }

  buildStockReportForm() {
    this.formStockReport = this.fb.group({
      stockDate: [],
      warehouseId: [],
      keywordSearch: [null]
    });
  }

  setParameterReport() {
    const stockReportForm = this.formStockReport.getRawValue();
    let dateConvert = this.utilService.convertDate(stockReportForm.stockDate);
    this.year = dateConvert.years;
    this.month = dateConvert.months;
    this.keywordSearch = stockReportForm.keywordSearch;
    this.warehouseId = this.warehouseId;
    this.generateReport(dateConvert.years, dateConvert.months, this.warehouseId, this.keywordSearch);
  }

  generateReport(year: any, month: any, warehouseId: any, keywords: any) {
    let transDate = year + '-' + month;
    let monthInt = parseInt(month) - 1;
    this.monthName = this.nameOfMonthList[monthInt];

    this.reportDate = transDate;

    let options = {
      "token": this.token,
      "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list,
      "year": year,
      "month": month,
      "warehouse_id": warehouseId,
      "keywords": keywords ? keywords : 'null'
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.reportService.getReportStock(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.warehouseList = response.results.warehouse_data;
          this.reportData = response.results.stock_data;
          this.stockField = response.results.stock_field;
          this.earlyField = this.stockField[0];
          this.inField = this.stockField[1];
          this.outField = this.stockField[2];
          this.endField = this.stockField[3];
          this.rows = response.results.stock_data;
  
          this.formStockReport = this.fb.group({
            stockDate: [transDate],
            warehouseId: [parseInt(this.reportData[0].warehouse_id)],
            keywordSearch: [null]
          });
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  clearFilters() {
    this.keywordSearch = undefined;

    this.generateReport(this.year, this.month, this.warehouseId, this.keywordSearch);
  }
  
  public selectWarehouse(event){
    this.warehouseId = event.value.id;
  }
}
