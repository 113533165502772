import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { MenuController, Platform } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';

import { ConnectivityProvider } from './providers/connectivity.provider';
import { UserProfile } from './models/user-profile.model';
import { AppService } from './app.service';
import { UtilService } from './service/util.service';
import { Subscription } from 'rxjs';

//import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  isBarang: any;
  isUmum: any;
  isPenjualan: any;
  isPersediaan: any;
  isMasterPenjualan: any;
  db: any;
  listParent: any;
  listChild1: any;
  listChild2: any;

  token: any;
  userProfile: UserProfile = new UserProfile();
  
  counterName: any = '-';
  branchName: any = '-';
  counterTransDate: any;

  loggedIn = false;
  dark = false;
  startPage: any;

  subscription: Subscription;
  
  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private connectivityProvider: ConnectivityProvider,
    private appService: AppService,
    private utilService: UtilService
  ) {
    this.initializeApp();
    this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;
        }
    });
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();

    // this.openDB();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.setSidebarmenu();
      }
    });
  }

  setSidebarmenu() {
    let dateConvert;
    let newTransDate = '-';

    if(this.userProfile.counter_detail) {
      this.counterName = this.userProfile.counter_id !== 0 ? this.userProfile.counter_detail.counter_name : '-';
      this.branchName = this.userProfile.branch_id !== 0 ? this.userProfile.branch_detail.branch_name : '-';
      dateConvert = this.utilService.convertDate(this.userProfile.counter_detail.trans_date);
      newTransDate = dateConvert.dates + '/' + dateConvert.months + '/' + dateConvert.years;
    }
    
    this.counterTransDate = newTransDate;
    let options = {
      "token": this.token
    };

    this.appService.getSidebarMenu(this.userProfile.username, this.userProfile.role_id, options)
    .subscribe((response: any) => {
        this.listParent = response.results.parent;
        this.listChild1 = response.results.child1;
        this.listChild2 = response.results.child2;
        this.startPage = response.results.start_page;
        let accessMenu: any[] = [];
        this.storage.get('user_menu_access').then((user) => {
          if(!user) {
            if(this.listParent!=undefined){
              for(let x = 0; x < this.listParent.length; x++) {
                let arrChild1: any[] = this.listChild1[this.listParent[x].id];
                if(arrChild1 !== undefined) {
                  for(let y = 0; y < arrChild1.length; y++) {
                    let arrChild2: any[] = this.listChild2[arrChild1[y].id];
                    if(arrChild2 !== undefined) {
                      for(let z = 0; z < arrChild2.length; z++) {
                        accessMenu.push(arrChild2[z]);
                      }
                    }
                  }
                }
              }
            }

            this.storage.set('user_menu_access', accessMenu);
          }
        });
    }, (err) => {
      console.log(err);
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      //this.checkConnectivity();
      /* 
      const fpPromise = FingerprintJS.load({
        apiKey: '51M7Dcs10QMulpAYdmaK', region: 'ap'
      });

      fpPromise
      .then(fp => fp.get())
      .then(result => this.storage.set('visitor_id', result.visitorId));
      */
    });
  }

  checkConnectivity() {
    this.connectivityProvider.appIsOnline$.subscribe(online => {
      // console.log(online);
      if (online) {
          // call functions or methods that need to execute when app goes online (such as sync() etc)
      } else {
          // call functions on network offline, such as firebase.goOffline()
      }
    })
  }

  openDB() {
    this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
    this.createOfflineTable();
  }

  createOfflineTable() {
    let sqlQuery: string = 'CREATE TABLE IF NOT EXISTS offline_store(table_name, column_name, method, data_id, status)';

    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(sqlQuery, [],
        (tx, result) => {
          console.log('offline_store Table Created');
          resolve(result);
        },
        (error) => {
          console.log('offline_store Table Created');
          reject(error);
        });
      });
    });

  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:getmenu', () => {
      Promise.all([
        this.storage.get('user_token'),
        this.storage.get('user_profile')
      ])
      .then(([token, profile]) => {
        if(token) {
          this.token = token;
          this.userProfile = new UserProfile(profile);
          this.setSidebarmenu();
        }
      });
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
      this.clearSidebarMenu();
    });
  }

  clearSidebarMenu() {
    this.listParent = null;
    this.listChild1 = null;
    this.listChild2 = null;
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/login');
    });
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }
}
