import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { SalesTransaction, SalesTransactionDetail, SalesTransactionPayments } from '../../models/sales-transaction.model';
import { PointMutation } from '../../models/point-mutation.model';
import { MemberPoint } from '../../models/member-point.model';
import { StockMutation } from '../../models/stock-mutation.model';
import { MstVoucherDetail } from '../../models/mst-voucher.model';
import { SalesTransactionBundle } from '../../models/sales-transaction-bundle.model';

@Injectable({
  providedIn: 'root'
})
export class PosService {

  constructor(private httpService: HttpService) { }

  getSetupCashier(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-cashier', params);
  }


  getStock(params: any): Observable<any> {
    return this.httpService.get('get-stock', params);
  }
  getProduct(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/product', params);
  }
  getProductPackage(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/product-package', params);
  }

  getLatestReceiptNo(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/latest-receipt-no', params);
  }

  getMember(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/member', params);
  }
  getMemberByPhone(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/get-member-by-phone', params);
  }

  getMemberById(params: any, memberId: any): Observable<any> {
    return this.httpService.get(`member-point/get-by-customer/${memberId}`, params);
  }

  getVoucher(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/voucher', params);
  }

  getEdcMachine(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/edc', params);
  }

  getPromotion(params: any): Observable<any> {
   // return this.httpService.get('sales-transaction/promotion', params);
   return this.httpService.get('sales-transaction-auto/promotion', params);
  }

  getCurrencies(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/currency', params);
  }

  getPaymentMethods(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/payment-methods', params);
  }

  addSalesTransaction(data: SalesTransaction): Observable<any> {
    return this.httpService.post('sales-transaction/store', data);
  }

  addSalesTransactionDetail(data: SalesTransactionDetail): Observable<any> {
    return this.httpService.post('sales-transaction-detail/store', data);
  }

  addSalesTransactionPayment(data: SalesTransactionPayments): Observable<any> {
    return this.httpService.post('sales-transaction-payment/store', data);
  }

  addPointMutation(data: PointMutation): Observable<any> {
    return this.httpService.post('point-mutation', data);
  }

  addStockMutation(data: StockMutation): Observable<any> {
    return this.httpService.post('stock-mutation/store', data);
  }

  addSalesTransactionBundle(data: SalesTransactionBundle): Observable<any> {
    return this.httpService.post('sales-transaction/storebundle', data);
  }

  updateMemberPoint(data: MemberPoint, memberId: any): Observable<any> {
    return this.httpService.put(`member-point/${memberId}`, data);
  }

  updateVoucherUsed(data: MstVoucherDetail): Observable<any> {
    return this.httpService.put('voucher-used-update', data);
  }

  manageStock(params: any): Observable<any> {
    return this.httpService.get('stock/manage-stock', params);
  }

  checkConnectionServer(params: any): Observable<any> {
    return this.httpService.post('check-connection', params);
  }
  checkStock(data: any): Observable<any> {
    return this.httpService.get('check-stock',data);
  }
  checkStockItemPromo(data: any): Observable<any> {
    return this.httpService.get('check-stock-auto-promo',data);
  }
  storeProductSales(coun:any) {
    let key='1';
    let value=JSON.stringify(coun);
    localStorage.setItem(key, value);
  }
}
