import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { OtherReceiving, OtherReceivingDetail } from '../../models/other-receiving.model';
import { ApprovalHistory } from '../../models/approval-history.model';
import { StockMutation } from '../../models/stock-mutation.model';
import { OtherReceivingBundle } from '../../models/other-receiving-bundle.model';
import { OtherReceivingClosedBundle } from '../../models/other-receiving-cl-bundle.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtherReceivingService {

  constructor(private httpService: HttpService) { }

  getOtherReceiving(params: any): Observable<any> {
    return this.httpService.get('other-receiving', params);
  }

  getLocalData(name: any, params: any): Observable<any> {
    let apiUrl: any;
    if(name === 'counter') { apiUrl = 'counter-all'; }
    if(name === 'warehouse') { apiUrl = 'warehouse-all'; }
    if(name === 'purpose') { apiUrl = 'purpose-all'; }

    return this.httpService.get(apiUrl, params);
  }

  getOtherReceivingforCreate(params: any): Observable<any> {
    return this.httpService.get('other-receiving/create', params);
  }

  getOtherReceivingforEdit(otherReceivingId: any, params: any): Observable<any> {
    return this.httpService.get(`other-receiving/${otherReceivingId}/edit`, params);
  }

  getProduct(params: any): Observable<any> {
    return this.httpService.get('product-all', params);
  }

  addOtherReceiving(data: OtherReceiving): Observable<any> {
    return this.httpService.post('other-receiving', data);
  }
  
  addOtherReceivingDetail(data: OtherReceivingDetail): Observable<any> {
    return this.httpService.post('other-receiving-details', data);
  }

  addApprovalHistory(data: ApprovalHistory): Observable<any> {
    return this.httpService.post('approval-history', data);
  }

  addStockMutation(data: StockMutation): Observable<any> {
    return this.httpService.post('stock-mutation/store', data);
  }

  addOtherReceivingBundle(data: OtherReceivingBundle): Observable<any> {
    return this.httpService.post('other-receiving-bundle', data);
  }

  updateOtherReceivingBundle(otherReceivingId: any, data: OtherReceivingClosedBundle): Observable<any> {
    return this.httpService.put(`update-other-receiving-bundle/${otherReceivingId}`, data);
  }

  updateOtherReceiving(otherReceivingId: any, data: OtherReceiving): Observable<any> {
    return this.httpService.put(`other-receiving/${otherReceivingId}`, data);
  }

  updateOtherReceivingDetail(otherReceivingId: any, data: OtherReceivingDetail): Observable<any> {
    return this.httpService.put(`other-receiving-details/${otherReceivingId}`, data);
  }

  manageStock(params: any): Observable<any> {
    return this.httpService.get('stock/manage-stock', params);
  }
}
