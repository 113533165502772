import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';
import { MasterProductGimmickService } from '../master-product-gimmick.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-master-product-gimmick-create',
  templateUrl: './master-product-gimmick-create.page.html',
  styleUrls: ['./master-product-gimmick-create.page.scss'],
})
export class MasterProductGimmickCreatePage implements OnInit {

  formMstProductGimmickCreate: FormGroup;
  token: any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  statusProduct: any[] = ['Not Active', 'Active'];
  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private mstProductGimmickService: MasterProductGimmickService,
    private router: Router) { }

  ngOnInit() {
    this.buildFormMstProductCreate();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormMstProductCreate() {
    this.formMstProductGimmickCreate = this.fb.group({
      productName: [null, Validators.required],
      initialName: [null, Validators.required],
      barcode: [null],
      productWidth: [null],
      productLength: [null],
      productHeight: [null],
      activeStatus: [null, Validators.required],
    });
  }
  
  inputProduct() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formMstProduct = this.formMstProductGimmickCreate.value;
      let productWidth = formMstProduct.productWidth;
      if(productWidth=='null'){
        productWidth='0';
      }
      let productLength =formMstProduct.productLength;
      if(productLength=='null'){
        productLength='0';
      }
      let productHeight=formMstProduct.productHeight;
      if(productHeight=='null'){
        productHeight='0';
      }
      const params = new HttpParams()
        .set('token', this.token)
        .set('product_name', formMstProduct.productName)
        .set('initial_name', formMstProduct.initialName)
        .set('barcode', formMstProduct.barcode)
        .set('product_length', productLength)
        .set('product_width', productWidth)
        .set('product_height', productHeight)
        .set('active', formMstProduct.activeStatus)
        .set('created_by',  this.userProfile.username);
      this.mstProductGimmickService.addProduct(params).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/master-product-gimmick']);;
          }
        }
      ]
    });

    await alert.present();
  }


}
