import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PicPage } from './pic.page';

const routes: Routes = [
  {
    path: '',
    component: PicPage
  },
  {
    path: 'pic-detail',
    loadChildren: () => import('./pic-detail/pic-detail.module').then( m => m.PicDetailPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PicPageRoutingModule {}
