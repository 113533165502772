import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterProductPacketPage } from './master-product-packet.page';

const routes: Routes = [
  {
    path: '',
    component: MasterProductPacketPage
  },
  {
    path: 'master-product-packet-create',
    loadChildren: () => import('./master-product-packet-create/master-product-packet-create.module').then( m => m.MasterProductPacketCreatePageModule)
  },
  {
    path: 'master-product-packet-edit',
    loadChildren: () => import('./master-product-packet-edit/master-product-packet-edit.module').then( m => m.MasterProductPacketEditPageModule)
  },  {
    path: 'package-counter',
    loadChildren: () => import('./package-counter/package-counter.module').then( m => m.PackageCounterPageModule)
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterProductPacketPageRoutingModule {}
