import { WarehouseMutation } from './warehouse-mutation.model';
import { WarehouseMutationDetail } from './warehouse-mutation-detail.model';

export class WarehouseMutationBundle {

    warehouseMutation: WarehouseMutation;
    warehouseMutationDetail: WarehouseMutationDetail;

    constructor(model?) {
        model = model || {};
        this.warehouseMutation = model.warehouseMutation || new WarehouseMutation();
        this.warehouseMutationDetail = model.warehouseMutationDetail || new WarehouseMutationDetail();
    }
}