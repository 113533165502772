import { OtherReceiving, OtherReceivingDetail } from './other-receiving.model';
import { StockMutation } from './stock-mutation.model';
import { ApprovalHistory } from './approval-history.model';

export class OtherReceivingClosedBundle {

    otherReceiving: OtherReceiving;
    otherReceivingDetail: OtherReceivingDetail;
    stockMutation: StockMutation;
    approvalHistory: ApprovalHistory;

    constructor(model?) {
        model = model || {};
        this.otherReceiving = model.otherReceiving || new OtherReceiving();
        this.otherReceivingDetail = model.otherReceivingDetail || new OtherReceivingDetail();
        this.stockMutation = model.stockMutation || new StockMutation();
        this.approvalHistory = model.approvalHistory || new ApprovalHistory();
    }
}