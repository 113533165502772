import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { MstPromotion, MstPromotionType } from '../../models/mst-promotion.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SetupResellerRewardService {
  constructor(private httpService: HttpService) { }
  
  getPromotion(params: any): Observable<any> {
    return this.httpService.get('setup-claim-insentive', params);
  }
  
  getDataforCreate(params: any): Observable<any> {
    return this.httpService.get('setup-claim-insentive/create', params);
  }

  addSetupIncentive(data:any): Observable<any> {
    return this.httpService.post('setup-claim-insentive', data);
  }
  
  getIncentiveforEdit(incentiveId: any, params: any): Observable<any> {
    return this.httpService.get(`setup-claim-insentive/${incentiveId}/edit`, params);
  }

  updateSetupIncentive(data:any, incentiveId: any,): Observable<any> {
    return this.httpService.put(`setup-claim-insentive/${incentiveId}`, data);
  }
}
