import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Role } from '../../models/role.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpService: HttpService) { }

  getRole(params: any): Observable<any> {
    return this.httpService.get('role', params);
  }

  getRoleforEdit(roleId: any, params: any): Observable<any> {
    return this.httpService.get(`role/${roleId}/edit`, params);
  }

  addRole(data: Role): Observable<any> {
    return this.httpService.post('role', data);
  }

  updateRole(roleId: any, data: Role): Observable<any> {
    return this.httpService.put(`role/${roleId}`, data);
  }

  deleteRole(roleId: any): Observable<any> {
    return this.httpService.delete(`role/${roleId}`);
  }
}
