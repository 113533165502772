import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { PriceGroupService } from '../price-group.service';
import { MstPriceGroupBundle } from '../../../models/mst-price-group-bundle.model';
import { PriceGroupComponent } from '../../../component/price-group/price-group.component';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';
import { MyService } from '../../../service/my.service';

@Component({
  selector: 'app-price-group-create',
  templateUrl: './price-group-create.page.html',
  styleUrls: ['./price-group-create.page.scss'],
})
export class PriceGroupCreatePage implements OnInit {

  @ViewChild('uploadFileInput', { static: false }) uploadFileInput: ElementRef;

  token: any;
  userProfile: UserProfile = new UserProfile();
  counterId: any;
  counterName: any;
  priceGroupDetailData: any[] = [];
  rows: any[];
  counterList: any[] = [];
  priceGroupTypeList: any[] = [{id: 'RG', type_name: 'REGULER'}, {id: 'RS', type_name: 'RESELLER'}];
  fileName= 'Group_Harga.xlsx';

  formPriceGroupCreate: FormGroup;
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private modalController: ModalController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private priceGroupService: PriceGroupService,
    private router: Router,
    private myService:MyService
  ) { }

  ngOnInit() {
    this.buildFormPriceGroupCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      console.log('init form add price group');
      console.log(token);
      console.log(profile);
      console.log(access);
      if(token) {
        console.log('init form add price group');
        this.token = token;
        this.userProfile = new UserProfile(profile);
        //this.counterId = this.userProfile.counter_detail.id;
       //this.counterName = this.userProfile.counter_detail.counter_name;
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getPriceGroupforCreate();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  buildFormPriceGroupCreate() {
    this.formPriceGroupCreate = this.fb.group({
      counterId: [null, Validators.required],
      priceGroupType: [null, Validators.required],
      docDate: [null, Validators.required],
      description: [null, Validators.required],
      keywordSearch: [null]
    });
  }

  getPriceGroupforCreate() {    
    console.log('panggil api untuk ambil data counter');
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };
      this.priceGroupService.getPriceGroupforCreate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.rows = response.results.mst_product_data;
          this.priceGroupDetailData = response.results.mst_product_data;
          this.counterList = response.results.mst_counter_data;
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  searchProduct() {
    const formPriceGroup = this.formPriceGroupCreate.value;
    this.rows = this.priceGroupDetailData.filter(item => {
      return item.product_name.toLowerCase().indexOf(formPriceGroup.keywordSearch) !== -1 ||
        item.id.toString().toLowerCase().indexOf(formPriceGroup.keywordSearch) !== -1;
    });
  }

  async changePriceProduct(productData: any) {
    const modal = await this.modalController.create({
      component: PriceGroupComponent,
      componentProps: {
        'productId': productData.id,
        'productName': productData.product_name,
        'productPrice': productData.price,
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      let checkProduct: any[] = this.priceGroupDetailData.find(x => x.id === data[0].product_id);
      if (checkProduct !== undefined) {
        let index: number = this.priceGroupDetailData.indexOf(checkProduct);
        this.priceGroupDetailData[index]['price'] =  data[0].price;
        this.rows[this.rows.indexOf(this.rows.find(x => x.id === data[0].product_id))]['price'] = data[0].price;
      }
    });

    return await modal.present();
  }

  input() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formPriceGroup = this.formPriceGroupCreate.value;
      let arrProduct: any = [];
      let arrPrice: any = [];

      let docDateConvert = this.utilService.convertDate(formPriceGroup.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;
      for(let x = 0; x < this.priceGroupDetailData.length; x++) {
        arrProduct[x] = this.priceGroupDetailData[x].id;
        arrPrice[x] = this.myService.removeSeparator(this.priceGroupDetailData[x].price);
      }

      const mstPriceGroupBundle = new MstPriceGroupBundle();
      mstPriceGroupBundle.mstPriceGroup.counter_id = this.counterId;
      mstPriceGroupBundle.mstPriceGroup.price_group_types = formPriceGroup.priceGroupType;
      mstPriceGroupBundle.mstPriceGroup.valid_periode = documentDate;
      mstPriceGroupBundle.mstPriceGroup.description = formPriceGroup.description;
      mstPriceGroupBundle.mstPriceGroup.created_by = this.userProfile.username;

      mstPriceGroupBundle.mstPriceGroupDetail.product_id = arrProduct;
      mstPriceGroupBundle.mstPriceGroupDetail.price = arrPrice;

      this.priceGroupService.addPriceGroup(mstPriceGroupBundle).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/price-group']);;
          }
        }
      ]
    });

    await alert.present();
  }

  onFileSelect(event) {
    let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      if (!_.includes(af, file.type)) {
        this.toastCtrl.create({ duration: 2000, message: 'Hanya untuk upload file excel !' }).then(t => t.present());
      }
    }
  }

  uploadFile() {
    if (!this.uploadFileInput.nativeElement.files[0]) {
      alert('Please fill valid details!');
      return false;
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formPriceGroup = this.formPriceGroupCreate.value;
      let docDateConvert = this.utilService.convertDate(formPriceGroup.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;
      
      const formData = new FormData();
      formData.append('counter_id', this.counterId);
      formData.append('price_group_types', formPriceGroup.priceGroupType);
      formData.append('description', formPriceGroup.description);
      formData.append('valid_periode', documentDate);
      formData.append('created_by', this.userProfile.username);
      formData.append('file', this.uploadFileInput.nativeElement.files[0]);

      this.priceGroupService.uploadFile(formData).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.uploadFileInput.nativeElement.value = "";
          this.showConfirmInput();
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  
  selectCounter(event){
    this.counterId = event.value.id;
  }

}
