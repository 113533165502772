import { Component, Input, OnInit, ViewChild } from '@angular/core';
  import { IonicSelectableComponent } from 'ionic-selectable';
  import { ReportService } from '../../../pages/report/report.service';
  import { ModalController, ToastController } from '@ionic/angular';
  import { UtilService } from '../../../service/util.service';
  import { Storage } from '@ionic/storage';
  import { UserProfile } from '../../../models/user-profile.model';
import { ProductComponent } from '../product/product.component';
@Component({
  selector: 'app-product-package',
  templateUrl: './product-package.component.html',
  styleUrls: ['./product-package.component.scss'],
})
export class ProductPackageComponent implements OnInit {
  
  
    @Input() branchList: any[];
    @Input() counterList: any[];
    @Input() productList: any;
    @Input() paramProductId: any;
    @Input() paramIsiProductId:any;
    @Input() paramBranchId: any;
    @Input() paramCounterId: any;
    @Input() paramStatus: any;
    
    @ViewChild('branchComponent', { static: false }) branchComponent: IonicSelectableComponent;
    @ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;
    
    branchId:any;
    counterId: any;
    periodStart: any;
    periodEnd: any;
    token: any;
    userProfile: UserProfile = new UserProfile();
    counterData: any[] = [];
    branchData: any[] = [];
    counterByLogin : any;
    status:number=2;
    
    selectedCounters: any[];
    selectedBranchs:any[];
    selectedProducts:any[]=[];
    selectedIsiProducts:any[]=[];
    products:any[]=[];
    keyword:any;
    checkedAllProduct:boolean=false;
  
    constructor(    
      private storage: Storage,
      private reportService: ReportService,
      public modalController: ModalController,
      public toastCtrl : ToastController,
      private utilService: UtilService
    ) { }
  
    ngOnInit() {
      this.counterId = this.paramCounterId ? this.paramCounterId : '';
      this.branchId = this.paramBranchId ? this.paramBranchId : '';
      this.status = this.paramStatus ? this.paramStatus : '2';
      this.selectedProducts =[];
      this.selectedIsiProducts =[];
      let productIdSplit = this.paramProductId !== '' ? String(this.paramProductId).split(',') : [];
      if(productIdSplit.length>0){
        for(let i=0;i<productIdSplit.length;i++){
          productIdSplit[i] = productIdSplit[i].replace(/'/g, "");
          let findProduct = (this.productList.find(x => x.id === productIdSplit[i]));
          if(findProduct){
            this.selectedProducts.push({
              id: findProduct.id,
              barcode: findProduct.barcode,
              name: findProduct.product_name
            });
          }
        }
      }
      let isiProductIdSplit = this.paramIsiProductId !== '' ? String(this.paramIsiProductId).split(',') : [];
      if(isiProductIdSplit.length>0){
        for(let i=0;i<isiProductIdSplit.length;i++){
          isiProductIdSplit[i] = isiProductIdSplit[i].replace(/'/g, "");
          let findProduct = (this.productList.find(x => x.id === isiProductIdSplit[i]));
          if(findProduct){
            this.selectedIsiProducts.push({
              id: findProduct.id,
              barcode: findProduct.barcode,
              name: findProduct.product_name
            });
          }
        }
      }
      Promise.all([
        this.storage.get('user_token'),
        this.storage.get('user_profile')
      ])
      .then(([token, profile]) => {
        if(token) {
          this.token = token;
          this.utilService.loadingPresent('Harap tunggu...')
          .then(() => {
            this.userProfile = new UserProfile(profile);
            this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
            let options = { 
              "token": this.token,
              "counter_id": this.counterByLogin
            };
            this.reportService.getActiveBranch(options).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.branchData = response.results;          
              
              let branchIdSplit = this.branchId !== '' ? String(this.branchId).split(',') : [];
              this.selectedBranchs=[];
              for(let i= 0; i < this.branchList.length; i++) {        
                let selectedBranch: any = this.branchData.find(x => x.id ==  branchIdSplit[i]);
                if(selectedBranch){
                  let idx =this.branchData.indexOf(selectedBranch);
                  this.selectedBranchs.push(this.branchData[idx]);            
                }
              }
              this.filterCounterByBranch();
            }, () => {});
          });
        }
      })
    }
    
    toggleItems() {
      this.branchComponent.toggleItems(
        this.branchComponent.itemsToConfirm.length ? false : true
      );
    }
  
    confirm() {
      this.branchList = [];
      this.branchComponent.confirm();
      this.branchComponent.close();
      this.branchList = this.branchComponent.value;
      this.counterId ='';
      this.filterCounterByBranch();
    }
  
    filterCounterByBranch(){
      this.selectedCounters=[];
      let idBranch = '';
      for(let i=0;i<this.selectedBranchs.length;i++){
        idBranch += this.selectedBranchs[i].id+',';
      }
      this.branchId = idBranch.replace(/(^,)|(,$)/g, "");
         
      let options = { 
        "token": this.token,
        "branch_id": this.branchId,
        "counter_id": this.counterByLogin 
      };
      this.reportService.getActiveCounterByBranchId(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.counterData = [];
        this.counterData = response.results;
        let checkedCounter =this.counterId !=='' ? this.counterId :this.counterByLogin ;
        let counterIdSplit = checkedCounter !== '' ? String(checkedCounter).split(',') : [];
        let idCounter='';
        for(let i= 0; i < checkedCounter.length; i++) {
          let selectedCounter: any = this.counterData.find(x => x.id ==  counterIdSplit[i]);
          if(selectedCounter){
            let idx =this.counterData.indexOf(selectedCounter);
            this.selectedCounters.push(this.counterData[idx]);          
            idCounter += this.counterData[idx]['id']+',';
          }
        }
        idCounter = idCounter.replace(/(^,)|(,$)/g, "");
        this.counterId = idCounter;
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });    
    }
    
    toggleCounterItems() {
      this.counterComponent.toggleItems(
        this.counterComponent.itemsToConfirm.length ? false : true
      );
    }
    async openAdvancedFilters(typeProduct:any) {
      let paramProduct='';
      if(typeProduct=='paket'){
        paramProduct=this.paramProductId;
      }
      else if(typeProduct=='isi'){
        paramProduct=this.paramIsiProductId;
      }
      const modal = await this.modalController.create({
        component: ProductComponent,
        componentProps: {
          productList:this.productList,
          paramProductId: paramProduct,
          typeProduct:typeProduct
        },
        backdropDismiss: false
      });
      modal.onDidDismiss().then((modelData) => {
        if(typeProduct=='paket'){
          this.paramProductId='';
          this.selectedProducts = modelData.data.selectedProducts;
          if(this.selectedProducts.length>0){
            for(let i=0;i<this.selectedProducts.length;i++){
              this.paramProductId+="'"+this.selectedProducts[i].id+"',";
            }
          }
        }
        else if(typeProduct=='isi'){
          this.selectedIsiProducts =  modelData.data.selectedProducts;
          this.paramIsiProductId='';
          if(this.selectedIsiProducts.length>0){
            for(let i=0;i<this.selectedIsiProducts.length;i++){
              this.paramIsiProductId+="'"+this.selectedIsiProducts[i].id+"',";
            }
          }
        }
      });
      return await modal.present();
    }
    
  
    confirmCounter() {
      this.counterComponent.confirm();
      this.counterComponent.close();
      
      this.counterList = [];
      this.counterList = this.counterComponent.value;
      let idCounter ='';
      let idBranch = [];
      for(let i=0;i<this.counterList.length;i++){
        idCounter += this.counterList[i].id+',';
        idBranch.push(this.counterList[i].branch_id);
      }
      idCounter = idCounter.replace(/(^,)|(,$)/g, "");
      this.counterId = idCounter;
  
      if(this.branchId!=''&& this.counterId==''){//jika pilih branch tanpa pilih counter, maka ambil all counter di cabang tsb
        let idCounter =''; 
        for(let i=0;i<this.counterData.length;i++){
          idCounter += this.counterData[i].id+',';
        }
        idCounter = idCounter.replace(/(^,)|(,$)/g, "");
        this.counterId = idCounter;
  
      }
      else if(this.branchId==''&& this.counterId!=''){ // jika tidak pilih branch, setelah pilih counter maka pilih branch sesuai counter
        this.selectedBranchs=[];
        this.branchId ='';
        for(let i=0;i<idBranch.length;i++){             
          let selectedBranch: any = this.branchData.find(x => x.id ==  idBranch[i]);
          if(selectedBranch){
            let idx =this.branchData.indexOf(selectedBranch);
            this.selectedBranchs.push(this.branchData[idx]);
            this.branchId += idBranch[i]+',';           
          }
        } 
        this.branchId = this.branchId.replace(/(^,)|(,$)/g, "");
      }
      else if(this.branchId==''&& this.counterId==''){ // jika tidak pilih branch dan cabang, maka pilih semua counter & cabang
        let idCounter =''; 
        let idBranch='';
        for(let i=0;i<this.counterByLogin.length;i++){
          idCounter += this.counterByLogin[i]+',';
        }
        idCounter = idCounter.replace(/(^,)|(,$)/g, "");
        this.counterId = idCounter;
  
        for(let i=0;i<this.branchData.length;i++){   
          idBranch += this.branchData[i]['id']+','; 
        } 
        idBranch = idBranch.replace(/(^,)|(,$)/g, "");
        this.branchId = idBranch;
      }
    }
    
    async dismissModal(code: any) {
      let dataReturn: any;
      if(code === 'generate') {
           dataReturn = {
              'branch':this.branchId,
              'counter':this.counterId,
              'product':this.selectedProducts,
              'isiProduct':this.selectedIsiProducts,
              'status':this.status
           }
      }
      if(code === 'close') {
        dataReturn = 'null';
      }    
      await this.modalController.dismiss(dataReturn);
    }
  
    

}  