import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { ResellerCashbackService } from '../reseller-cashback.service';

@Component({
  selector: 'app-reseller-cashback-detail',
  templateUrl: './reseller-cashback-detail.page.html',
  styleUrls: ['./reseller-cashback-detail.page.scss'],
})
export class ResellerCashbackDetailPage implements OnInit {

  token: any;
  formResellerCashback: FormGroup;
  userProfile: UserProfile = new UserProfile();

  resellerMonthlyRewardId: any;
  resellerMonthlyRewardData: any;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private resellerCashbackService: ResellerCashbackService) { }

  ngOnInit() {
    this.buildFormResellerCashback();
  
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.getData();
      }
    });
  }

  buildFormResellerCashback() {
    this.formResellerCashback = this.fb.group({
      id: [null],
      name: [null],
      transDateStart: [{value: null, disabled: true}],
      transDateEnd: [{value: null, disabled: true}],
      totalTransaction: [0],
      paidDate: [{value: null, disabled: true}],
      paidAmount: [0]
    });
  }
  
  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      this.resellerMonthlyRewardId = snapshot.resellerMonthlyRewardId;

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.resellerCashbackService.getResellerMonthlyRewardforDetail(this.resellerMonthlyRewardId, { "token": this.token })
        .subscribe((response) => {
          this.utilService.loadingDismiss();
          this.resellerMonthlyRewardData = response.results.reseller_monthly_reward_data;

          if(this.resellerMonthlyRewardData) {
            this.formResellerCashback = this.fb.group({
              id: [this.resellerMonthlyRewardData[0].id],
              name: [this.resellerMonthlyRewardData[0].customer_name],
              transDateStart: [{value: this.resellerMonthlyRewardData[0].period_transaction_start, disabled: true}],
              transDateEnd: [{value: this.resellerMonthlyRewardData[0].period_transaction_end, disabled: true}],
              totalTransaction: [this.resellerMonthlyRewardData[0].total_transaction],
              paidDate: [{value: this.resellerMonthlyRewardData[0].paid_date, disabled: true}],
              paidAmount: [this.resellerMonthlyRewardData[0].paid_amount]
            });
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

}
