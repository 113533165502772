import {  AfterViewInit, Component, ElementRef, ViewChild  } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { Chart } from 'chart.js';
import { UserData } from '../../providers/user-data';
import { UtilService } from '../../service/util.service';
import { DashboardService } from './dashboard.service';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements AfterViewInit {

  @ViewChild('barCanvas', {static: false}) private barCanvas: ElementRef;
  @ViewChild('barTargetCanvas', {static: false}) private barTargetCanvas: ElementRef;
  barChart: any;
  barTargetChart:any;
  listCounter: any = [];
  chartData: any = [];
  chartDataReguler: any = [];
  chartDataReseller: any = [];
  totalData: number;
  totalTarget : number;
  token: any;
  periodeChart: any;
  periodeChartTarget: any;
  yearChart: any;
  bar: any;
  barTarget:any;
  periode: any;
  year: any;
  month: any;
  userProfile: UserProfile = new UserProfile();
  datasets: any= [];
  chartDataOmset: any = [];
  chartDataTarget: any = [];

  constructor(
    private dashboardService: DashboardService,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private navCtrl: NavController,
    private storage: Storage
  ) { }
  
  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        window.dispatchEvent(new CustomEvent('user:getmenu'));
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

 async  ngAfterViewInit() {
    this.periodeChart = new Date().toISOString();
    this.periodeChartTarget = new Date().toISOString();
    this.periode = new Date().toISOString();
    this.bar = '3';
    this.barTarget= '2';        
     await this.getChartSalesTarget(true);
      await this.getChartSales(true);
      await this.getSumarySalesPerCounter();
  }
  async getChartSalesTarget(firstLoad:any=false) {
    if(!firstLoad) {
      this.barTargetChart.destroy();
    }
    let dateConvert = this.utilService.convertDate(this.periodeChartTarget);
    this.yearChart = dateConvert.years;
    this.chartDataOmset = [];
    this.chartDataTarget = [];
    let options = {
      "token": this.token,
      "year": this.yearChart,
      "bar": this.barTarget
    };
      this.dashboardService.getChartSalesTarget(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.chartDataOmset = response.results.data_omset;
        this.chartDataTarget = response.results.data_target;
        this.datasets= [
          {
            label: `Target ${this.yearChart}`,
            data: this.chartDataTarget,
            fill: false,
            borderColor: '#4169E1',
            backgroundColor: '#4169E1'
          },
          {
            label: `Omset ${this.yearChart}`,
            data: this.chartDataOmset,
            fill: false,
            borderColor: '#32CD32',
            backgroundColor: '#32CD32'
          }
        ];
        this.barTargetChartMethod();
      
    });
  }
  async getChartSales(firstLoad:any=false) {
    if(!firstLoad) {
      this.barChart.destroy();
    }
    let dateConvert = this.utilService.convertDate(this.periodeChart);
    this.yearChart = dateConvert.years;
    this.chartDataReguler = [];
    this.chartDataReseller = [];
    let options = {
      "token": this.token,
      "year": this.yearChart,
      "bar": this.bar
    };
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.dashboardService.getChartSales(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(this.bar == '1') {
          this.chartData = response.results.data_perbulan;
          this.datasets= [
            {
              label: `Penjualan ${this.yearChart}`,
              data: this.chartData,
              fill: false,
              borderColor: '#4169E1',
              backgroundColor: '#4169E1'
            }
          ];
        }
        else if(this.bar=='2' || this.bar =='3'){
          this.chartDataReguler = response.results.data_perbulan_reguler;
          this.chartDataReseller = response.results.data_perbulan_reseller;
          //this.chartDataReguler/this.chartDataReseller *100
          this.datasets= [
            {
              label: `Penjualan Reguler ${this.yearChart}`,
              data: this.chartDataReguler,
              fill: false,
              borderColor: '#4169E1',
              backgroundColor: '#4169E1'
            },
            {
              label: `Penjualan Reseller ${this.yearChart}`,
              data: this.chartDataReseller,
              fill: false,
              borderColor: '#32CD32',
              backgroundColor: '#32CD32'
            }
          ];
        }
        this.barChartMethod();
      }, (err) => {
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  async getSumarySalesPerCounter() {
    let dateConvert = this.utilService.convertDate(this.periode);
    this.year = dateConvert.years;
    this.month = dateConvert.months;
    let options = {
      "token": this.token,
      "year": this.year,
      "month": this.month
    };
    this.listCounter = new Array;
    this.totalData = 0;
    this.totalTarget = 0;
    this.dashboardService.getSumarySalesPerCounter(options).subscribe((response) => {
      this.listCounter = response.results.data_percounter;
      this.totalData = 0;
      this.totalTarget = 0;
      for (let i = 0; i < this.listCounter.length; i++) {
        this.totalData += parseInt(this.listCounter[i].total);
        this.totalTarget += parseInt(this.listCounter[i].target);
      }
    }, (err) => {
      console.log(err);
      if(err.error.error === 'token_expired') {
        this.userData.logout().then(() => {
          this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
          this.navCtrl.navigateForward(['/login']);
        });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      }
    });
  }

  async  barTargetChartMethod() {
      this.barTargetChart = new Chart(this.barTargetCanvas.nativeElement, {
        type: 'bar',
        data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September','Oktober', 'November', 'December'],
          datasets: this.datasets
        },
        options:  {
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  if(parseInt(value) >= 1000){
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return  value;
                  }
                }
              },
              type: 'linear',
              callbacks: {
                label: function(tooltipItem) {
                  return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                }
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem,data) {
              return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
              }
            }
          },
        }
      });
    
  }  
  async  barChartMethod() {
    if (this.bar=='1' || this.bar=='2'){
      this.barChart = new Chart(this.barCanvas.nativeElement, {
        type: 'bar',
        data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September','Oktober', 'November', 'December'],
          datasets: this.datasets
        },
        options:  {
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  if(parseInt(value) >= 1000){
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return  value;
                  }
                }
              },
              type: 'linear',
              callbacks: {
                label: function(tooltipItem) {
                  return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                }
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem,data) {
              return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
              }
            }
          },
        }
      });
    }
    else if(this.bar=='3'){
      this.barChart = new Chart(this.barCanvas.nativeElement, {
        type: 'bar',
        data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September','Oktober', 'November', 'December'],
          datasets: this.datasets
        },
        options:  {
          scales: {
            xAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              }
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  if(parseInt(value) >= 1000){
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  } else {
                    return  value;
                  }
                }
              },
              type: 'linear',
              callbacks: {
                label: function(tooltipItem) {
                  return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                }
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem,data) {
                var total = 0;
                for (var i = 0; i < data.datasets.length; i++){
                  total += Number(data.datasets[i].data[tooltipItem.index]);
                }
                let percent = Math.round(Number(tooltipItem.yLabel/total)*100);
              return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') +"("+percent+"%)";
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }

  getLabelPrintNominal(nominal: any): String {
    let nominalString = new String(nominal);
    let totalLength: number = 12;

    let diff: number = totalLength - nominalString.length;
    let lblNominal: String = '';

    if(diff !== 0) {
      for(let i = 0; i < diff; i++) {
        lblNominal = lblNominal + ' ';
      }

      lblNominal = lblNominal + nominalString.toString();
    } else {
      lblNominal = nominalString;
    }

    return lblNominal;
  }
  formatRupiah(angka: string) {
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
  }

}
