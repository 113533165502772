import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { ResellerMonthlyReward } from '../../models/reseller-monthly-reward.model';

@Injectable({
  providedIn: 'root'
})
export class ResellerCashbackService {

  constructor(private httpService: HttpService) { }

  getResellerCashback(params: any): Observable<any> {
    return this.httpService.get('reseller-monthly-rewards', params);
  }

  getTotalTransactionMonthly(params: any): Observable<any> {
    return this.httpService.get('total-transaction-reseller', params);
  }

  getResellerMonthlyRewardforDetail(resellerMonthlyRewardId: any, params: any): Observable<any> {
    return this.httpService.get(`reseller-monthly-rewards/${resellerMonthlyRewardId}/edit`, params);
  }

  addResellerMonthlyReward(data: ResellerMonthlyReward): Observable<any> {
    return this.httpService.post('reseller-monthly-rewards', data);
  }
}
