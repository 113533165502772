import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-af-product-receiving',
  templateUrl: './af-product-receiving.component.html',
  styleUrls: ['./af-product-receiving.component.scss'],
})
export class AfProductReceivingComponent implements OnInit {

  constructor(
    public modalController: ModalController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private alertController: AlertController
    ) { }

  ngOnInit() {}

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }

}
