import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterProductPacketCreatePage } from './master-product-packet-create.page';

const routes: Routes = [
  {
    path: '',
    component: MasterProductPacketCreatePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterProductPacketCreatePageRoutingModule {}
