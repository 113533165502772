import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { ProductReceivingComponent } from '../../../component/product-receiving/product-receiving.component';
import { ProductReceivingService } from '../product-receiving.service';
import { ProductReceivingBundle } from '../../../models/product-receiving-bundle.model';
import { FindProductComponent } from '../../../component/find-product/find-product.component';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-product-receiving-create',
  templateUrl: './product-receiving-create.page.html',
  styleUrls: ['./product-receiving-create.page.scss'],
})
export class ProductReceivingCreatePage implements OnInit {

  token: any;
  productSearch: Product[];
  productList: Product[];
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  warehouseList: any[] = [];
  unitType: any[] = ['PIECES'];
  productRequestDetailList: any[] = [];

  formProductReceivingCreate: FormGroup;
  productReceivingId: any;
  productRequestDetails: any[] = [];

  productRequestNumber: any;
  roleAccess = new RoleAccess();
  warehouseId :any='';
  counterId :any='';  
  selectedWarehouses:any[];
  warehouseListShow:any[]=[];
  proposalNumber:any='';
  counterIdList : any='';
  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private modalController: ModalController,
    private productReceivingService: ProductReceivingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormProductReceiving();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        this.counterIdList =this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getProductReceivingForCreate();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getProductReceivingForCreate() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.productReceivingService.getProductReceivingforCreate(options)
      .subscribe((res: any) => {
        this.utilService.loadingDismiss();
        if (res.status.code === 200) {
          this.productList = res.results.product_data;
          this.counterList = res.results.counter_data;
          this.warehouseList = res.results.warehouse_data;
          this.warehouseListShow = this.warehouseList;

          this.buildFormProductReceiving();
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  buildFormProductReceiving() {
    let newDate = new Date();
    let convertDate = this.utilService.convertDate(newDate);
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formProductReceivingCreate = this.fb.group({
      docDate: [todayDate, Validators.required],
      proposalNumber:[this.proposalNumber ? this.proposalNumber : null],
      productRequestId: [this.productRequestNumber ? this.productRequestNumber : null, Validators.required],
      counterId: [this.counterId !='' ? parseInt(this.counterId) : null, Validators.required],
      warehouseId: [this.warehouseId != '' ? parseInt(this.warehouseId) : null, Validators.required],
      courier: [null, Validators.required],
      description: [null, Validators.required]
    });
  }

  filterProducts(products: Product[], text: string) {
    return products.filter(product => {
      return product.product_name.toLowerCase().indexOf(text) !== -1 ||
        product.id.toString().toLowerCase().indexOf(text.toLowerCase()) !== -1;
    });
  }

  inputBundle() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formProductReceive = this.formProductReceivingCreate.value;

      let docDateConvert = this.utilService.convertDate(formProductReceive.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      let arrProduct: any = [];
      let arrQty: any = [];
      let arrUnitType: any = [];
      let arrQtyReceive: any = [];
      let arrDesc: any = [];

      for(let x = 0; x < this.productRequestDetailList.length; x++) {
        let htmlIdQtyReceive: any = 'qtyReceive_' + x;
        let htmlIdDescription: any = 'description_' + x;
        let qtyReceive: any = (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;
        let description: any = (<HTMLInputElement>document.getElementById(htmlIdDescription)).value;

        arrProduct[x] = this.productRequestDetailList[x].product_id;
        arrQty[x] = parseInt(this.productRequestDetailList[x].qty_request);
        arrUnitType[x] = 0;
        arrQtyReceive[x] = parseInt(qtyReceive);
        arrDesc[x] = description;
      }

      const productReceivingBundle = new ProductReceivingBundle();
      productReceivingBundle.productReceiving.product_request_id = formProductReceive.productRequestId;
      productReceivingBundle.productReceiving.counter_id = this.counterId;
      productReceivingBundle.productReceiving.warehouse_id = this.warehouseId;
      productReceivingBundle.productReceiving.doc_date = documentDate;
      productReceivingBundle.productReceiving.receipt_no = '0';
      productReceivingBundle.productReceiving.courier = formProductReceive.courier;
      productReceivingBundle.productReceiving.desc = formProductReceive.description; 
      productReceivingBundle.productReceiving.created_by = this.userProfile.username;

      productReceivingBundle.productReceivingDetail.product_id = arrProduct;
      productReceivingBundle.productReceivingDetail.qty = arrQtyReceive;
      productReceivingBundle.productReceivingDetail.unit = arrUnitType;
      productReceivingBundle.productReceivingDetail.qty_request = arrQty;
      productReceivingBundle.productReceivingDetail.descriptions = arrDesc;

      this.productReceivingService.addReceivingBundle(productReceivingBundle)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/product-receiving']);;
          }
        }
      ]
    });

    await alert.present();
  }

  async openAdvancedFilters() {
    let warehouseId = this.warehouseId;

    const modal = await this.modalController.create({
      component: ProductReceivingComponent,
      componentProps: {
        token: this.token,
        idCounter: this.counterIdList,
        idWarehouse: warehouseId
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData.data.length>0) {
        this.productRequestNumber = modelData.data[0].id;
        this.proposalNumber=modelData.data[0].proposal_number;
        this.counterId = modelData.data[0].counter_id;
        this.warehouseId = modelData.data[0].warehouse_id;
        this.getProductRequestDetail(modelData.data[0].id);
      }
    });

    return await modal.present();
  }

  getProductRequestDetail(productRequestId: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {

      let options = {
        "token": this.token,
        "product_request_id": productRequestId
      }

      this.productReceivingService.getProductRequestDetail(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.productRequestDetails = response.results;
        if(this.productRequestDetails.length > 0) {
          this.productRequestDetailList = [];
          for(let i = 0; i < this.productRequestDetails.length; i++) {
            let filterProduct = this.filterProducts(this.productList, this.productRequestDetails[i].product_id);
            this.productRequestDetailList.push({
              'id' : i + 1,
              'product_id' : this.productRequestDetails[i].product_id,
              'product_name' : filterProduct[0].product_name,
              'qty_request': this.productRequestDetails[i].qty,
              'qty_receive': this.productRequestDetails[i].qty,
              'description': null,
              'unit': 'Pcs'
            });
          }
        } else {
          this.productRequestDetailList = [];
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  addDetail() {
    let length = this.productRequestDetailList.length;
    this.productRequestDetailList.push({
      'id' : length + 1,
      'product_id' : null,
      'product_name' : null,
      'unit': null
    });
  }

  async findProduct(index: any) {
    const modal = await this.modalController.create({
      component: FindProductComponent,
      componentProps: {
        'productList': this.productList
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if(data) {
        let findProduct = this.productRequestDetailList.indexOf(data);
        if(findProduct === -1) {
          this.productRequestDetailList[index].product_id = data.id;
          this.productRequestDetailList[index].product_name = data.product_name;
        }
      }
    });

    return await modal.present();
  }

  deleteProduct(index: any) {
    this.productRequestDetailList.splice(index, 1);
  }

  public selectWarehouse(event){
    this.warehouseId = event.value.id;
  }
    
  public selectCounter(event){
    this.counterId = event.value.id;
    this.warehouseId ='';
    this.selectedWarehouses=[];
    let filterWarehouse = this.warehouseList.filter(x => parseInt(x.counter_id) === parseInt(this.counterId));
    this.warehouseListShow = filterWarehouse;  
  }
  public validateQty(el:number,index:any,input:any){
    let str = input.value;
    let maxInput = this.productRequestDetailList[index]['qty_request'];
    if(str>maxInput){
      input.value = maxInput;
      this.showAlert('Qty Receive tidak boleh lebih dari Qty Request');
    }
  }

  async showAlert(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
  
  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
