import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../models/user-profile.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Storage } from '@ionic/storage';
import { PromotionsService } from '../promotions/promotions.service';
import { PromotionDetailComponent } from '../../component/promotion-detail/promotion-detail.component';
import { MyService } from '../../service/my.service';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../environments/environment';

class Product {
  public id: string;
  public product_name: string;
  public barcode: string;
}

@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.page.html',
  styleUrls: ['./promotion-detail.page.scss'],
})
export class PromotionDetailPage implements OnInit {

  token: any;
  promotionId: any;
  promotionData: any[] = [];
  promotionDetailData: any;
  productRequiredData: any;
  promotionFreeItemData: any[] = [];
  promotionTypeId: any;
  promotionTypeName: any;
  productSearch: Product[];
  productList: Product[];
  productGimmickList:any[];
  userProfile: UserProfile = new UserProfile();
  discountTypeList: any[]=['Nominal','Persentase','Free Item'];
  discountType:number=0;
  stockData: any[] = [];
  searchValue: any[] = [];
  productScan: any[] = [];
  productScanDisplay: any[] = [];
  listProductScan: any='';
  listProductScanDisplay: any='';
  productRequired: any[] = [];
  productRequiredDisplay: any[] = [];
  productFreeItem: any[] = [];
  productFreeItemType: any[] = [];
  productFreeItemDisplay: any[] = [];
  showButton:any[]=[];
  titleButtonOpen:any[]=[];

  formPromotionDetailEdit: FormGroup;
  
  mstProductList: Array<any>;
  mstProductGimmickList: Array<any>;

  showPromoBase : boolean = false;
  showProduct : boolean = false;
  showDiscountType:boolean=false;
  showListProduct:boolean=false;
  selectedProduct : boolean = false;
  basedOnList : any[]=['Total Belanja','Total Belanja per Produk','Product'];
  priceTypeList : any[]=['Potongan','Harga Khusus','Persentase'];
  productTypelist : any[]=['All Products','Selected Product'];
  promoBasedOnValue: number =0;
  productValue : number = 0;
  counterId:number=0;
  btnAddTitle = 'Product';
  priceType:number=0;
  showJenisDiskon = false;
  approval_status:number=0;
  showBerlakuUntuk:boolean=false;
  showNilaiN:boolean=false;
  minimumTotalBelanja:number=0;
  promotionName:string='';
  counterName:string='';
  warehouseName:string='';
  validOn:any='';
  earlyPeriod:any='';
  endPeriod:any='';

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private promotionService: PromotionsService,
    private myService :MyService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.buildFormPromotionDetailEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.getData();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormPromotionDetailEdit() {
    this.formPromotionDetailEdit = this.fb.group({
      priceType:[],
      basedOnValue: [],
      productType : [],
      discountType:[],
      detail: this.fb.array([]),
      productRequired:this.fb.array([])
    });
  }

  getData() {
    console.log(this.promoBasedOnValue);
    this.validOn='';
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.promotionId = AES.decrypt(snapshot.i, myKey).toString(utf8);

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.promotionService.getPromotionDetailforEdit(this.promotionId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.titleButtonOpen[0] ='buka';
          this.promotionData = response.results.promotion_data;
          this.promotionDetailData = response.results.promotion_details_data;
          this.promotionFreeItemData = response.results.promotion_free_items;
          this.productList = response.results.product_data;
          this.productGimmickList = response.results.product_gimmick_data;
          this.stockData = response.results.stock_data;
          this.productRequiredData = response.results.product_required_data;
          if(this.promotionData.length > 0) {
            this.earlyPeriod = this.promotionData[0].early_period;
            this.endPeriod = this.promotionData[0].end_period;
            if(this.promotionData[0].valid_on_su === '1') {this.validOn+='Minggu,'; };
            if(this.promotionData[0].valid_on_mo === '1') { this.validOn+='Senin,'; };
            if(this.promotionData[0].valid_on_tu === '1') { this.validOn+= 'Selasa,'; };
            if(this.promotionData[0].valid_on_we === '1') { this.validOn+= 'Rabu,'; };
            if(this.promotionData[0].valid_on_th === '1') { this.validOn+= 'Kamis,'; };
            if(this.promotionData[0].valid_on_fr === '1') { this.validOn+= 'Jumat,'; };
            if(this.promotionData[0].valid_on_sa === '1') { this.validOn+= 'Sabtu,'; };
            this.approval_status =this.promotionData[0].approval_status;
            this.promotionTypeId = this.promotionData[0].promotion_type_id;
            this.promotionTypeName = this.promotionData[0].promotion_type_name;
            this.promoBasedOnValue = parseInt(this.promotionData[0].promo_based_on);
            console.log('after:'+this.promoBasedOnValue);
            this.productValue = this.promotionData[0].product;
            this.counterId = this.promotionData[0].counter_id;
            this.priceType=parseInt(this.promotionData[0].price_type);
            if(this.promotionData[0].discount_type!=null){
              this.discountType = parseInt(this.promotionData[0].discount_type);
            }
            this.listProductScan =this.promotionData[0].product_list;
            this.listProductScanDisplay=this.promotionData[0].product_list;
            if(this.listProductScanDisplay!=null && this.listProductScanDisplay!=undefined){
            this.listProductScanDisplay= this.listProductScanDisplay.replace(";", ",");
            }
            if(this.promotionTypeName !== 'NOMINAL' && this.promotionTypeName!='PROMO BERTINGKAT' ){
             // this.basedOnList=['Total Belanja','Total Belanja per Produk'];
            }
            if(this.promotionTypeName === 'FREE ITEM (NOMINAL)' ){
              this.basedOnList=['Total Belanja','Total Belanja per Produk'];
            }
            if(this.promotionTypeName === 'PROMO BERTINGKAT' || this.promotionTypeName === 'PERSENTASE' || this.promotionTypeName === 'NOMINAL' || this.promotionTypeName === 'FREE ITEM (NOMINAL)' || this.promotionTypeName === 'FREE ITEM AND NOMINAL') {
              this.showPromoBase = true;
            }
            if(this.promoBasedOnValue==2 || this.promotionTypeName === 'FREE ITEM (ITEM)'){
              this.showProduct =true;
            }
            if(this.promotionTypeName === 'FREE ITEM AND NOMINAL'){
              this.showJenisDiskon=true;
              if(this.priceType!=1 && this.priceType!=3){
                this.showPromoBase = false;
              }
              this.showDiscountType=false;
            }
            if(this.promotionTypeName=='PROMO BERTINGKAT'){
              this.showDiscountType=true;
              this.btnAddTitle = 'Diskon';
              this.showProduct=true;
              if(this.promoBasedOnValue==0 || this.promoBasedOnValue==1){
                this.showProduct=false;
              }
              if(this.productValue==2){
                this.showListProduct=true;
              }
            }

            if(this.productValue==2){
              this.selectedProduct = true;
            }
            else{
              this.selectedProduct = false;
            }
            this.promotionName =this.promotionData[0].promotion_name;
            this.counterName = this.promotionData[0].counter_name;
            this.warehouseName = this.promotionData[0].warehouse_name;
            this.formPromotionDetailEdit = this.fb.group({
              priceType: [this.priceType],
              basedOnValue: [parseInt(this.promotionData[0].promo_based_on)],
              productType : [parseInt(this.promotionData[0].product)],
              discountType:[this.discountType],
              detail: this.fb.array([]),
              productRequired:this.fb.array([])
            });
          }

          if(this.productRequiredData) {
            for(let i = 0; i < this.productRequiredData.length; i++) {
              
              let qty = '0';
              if(this.productRequiredData[i].qty!=0){
                qty = parseInt(this.productRequiredData[i].qty).toString();
                qty=  this.myService.separator(qty);
              }
              const productRequired = this.fb.group({
                requiredPCode: [ null],
                requiredQty: [qty, Validators.required],
                requiredQtyType: [this.productRequiredData[i].qty_type, Validators.required],
              
              });
              this.getProductRequiredArray.push(productRequired);
              if ( this.productRequiredData[i].product_id){
                let str = this.productRequiredData[i].product_id;
                let re = /;/gi;
                let newstr = str.replace(re, "/ ");

                this.productRequired[i] = this.productRequiredData[i].product_id;
                this.productRequiredDisplay[i] = newstr;
              }
            }
          }
          if(this.promotionDetailData) {
            for(let i = 0; i < this.promotionDetailData.length; i++) {
              let maxDiscount = '0';
              let maxBuy = '0';
              let maxFreeItem = '0';
              let freeQty='0';
              let minBuy = '0';
              let qtyType='0';
              let appliesTo = '0';
              if(this.promotionDetailData[i].max_discount!=0){
                maxDiscount = parseInt(this.promotionDetailData[i].max_discount).toString();
                maxDiscount=  this.myService.separator(maxDiscount);
              }
              if(this.promotionDetailData[i].maximum_buy!=0){
                maxBuy = parseInt(this.promotionDetailData[i].maximum_buy).toString();
                maxBuy = this.myService.separator(maxBuy);
              }
              if(this.promotionDetailData[i].maximum_free_item!=0){
                maxFreeItem = parseInt(this.promotionDetailData[i].maximum_free_item).toString();
                maxFreeItem = this.myService.separator(maxFreeItem);
              }
              if(this.promotionDetailData[i].free_qty!=0){
                freeQty = parseInt(this.promotionDetailData[i].free_qty).toString();
                freeQty = this.myService.separator(freeQty);
              }
              if(this.promotionDetailData[i].minimum_buy!=0){
                minBuy = parseInt(this.promotionDetailData[i].minimum_buy).toString();
                minBuy = this.myService.separator(minBuy);
              }
              if(this.promotionDetailData[i].applies_to!=0){
                appliesTo = parseInt(this.promotionDetailData[i].applies_to).toString();
                appliesTo = this.myService.separator(appliesTo);
              }
              qtyType = this.promotionDetailData[i].qty_type;
              let minimum_transaction = parseInt(this.promotionDetailData[i].minimum_transaction).toString();
              minimum_transaction=  this.myService.separator(minimum_transaction);let value = parseInt(this.promotionDetailData[i].value).toString();
              value=  this.myService.separator(value);
              const detail = this.fb.group({
                detailId: [this.promotionDetailData[i].id],
                product: [ null],
                appliesTo:[appliesTo],
                minTotalTrans: [minimum_transaction],
                value: [value],
                freeQty: [freeQty],
                minBuy: [minBuy],
                maxBuy: [maxBuy],
                multipleFlag: [this.promotionDetailData[i].multiple_flag],
                maxDiscount : [maxDiscount],
                maxFreeItem : [maxFreeItem],
                freeItemType :[this.promotionDetailData[i].free_item_type],
                qtyType:[qtyType]
              });
              this.getDetailArray.push(detail);
              if ( this.promotionDetailData[i].product_id){
                let str = this.promotionDetailData[i].product_id;
                let re = /;/gi;
                let newstr = str.replace(re, ", ");

                this.productScan[i] = this.promotionDetailData[i].product_id;
                this.productScanDisplay[i] = newstr;
              }
              


              // free item
              let productList: string = '';
              let productListDisplay: string = '';
              let freeProductType:string = '';
              if(this.promotionFreeItemData.length > 0) {
                for(let x = 0; x < this.promotionFreeItemData.length; x++) {
                  if(parseInt(this.promotionFreeItemData[x].promotion_detail_id) === parseInt(this.promotionDetailData[i].id)) {
                    productList = productList + this.promotionFreeItemData[x].product_id;
                    freeProductType = freeProductType +  this.promotionFreeItemData[x].product_type;
                    productListDisplay = productListDisplay + this.promotionFreeItemData[x].product_id;
                    if((x+1) !== this.promotionFreeItemData.length) {
                      if(parseInt(this.promotionFreeItemData[x+1].promotion_detail_id) === parseInt(this.promotionDetailData[i].id)) {
                        productList = productList + ';';
                        freeProductType = freeProductType + ';';
                        productListDisplay = productListDisplay + ', ';
                      }
                    }
                  }
                }
              }
              this.productFreeItem[i] = productList;
              this.productFreeItemType[i]=freeProductType;
              this.productFreeItemDisplay[i] = productListDisplay;
              this.showButton[i] = '1';
              if(this.promotionDetailData[i].free_item_type==1){
                this.showButton[i] = '0';
              }
              this.titleButtonOpen[i] = 'Buka';
              if(this.promotionDetailData[i].free_item_type==1){
                this.productFreeItemDisplay[i] = 'sama dengan Product Scan';
                this.titleButtonOpen[i] = 'ganti';
              }
            }
          }

          this.mstProductList = [];
          this.mstProductGimmickList = [];
          for (let i = 0; i < this.productList.length; i++) {
            
            let checkStock: any = this.stockData.find(x => x.product_id === this.productList[i].id);
            let stockProduct: number = checkStock ? checkStock.end_stock : 0;

            let id = String(this.productList[i].id);
            let index: number = parseInt(id);
           // if(Number.isNaN(index)) {
              //
            //} else {
              this.mstProductList.push({
                id: String(this.productList[i].id),
                barcode: this.productList[i].barcode,
                product_name: this.productList[i].product_name,
                stock: stockProduct,
                terpakai: 0
              });
            //}
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  addDetail() {
    const detail = this.fb.group({
      detailId: [0],
      product: [this.promotionTypeName !== 'FREE ITEM (ITEM)' && this.promotionTypeName !== 'FREE ITEM (NOMINAL)' ? Validators.required : null],
      appliesTo:[0],
      minTotalTrans: [0],
      value: [0],
      freeQty: [0],
      minBuy: [0],
      maxBuy:[0],
      multipleFlag: [this.promoBasedOnValue==3 ? '1' :'0'],
      maxDiscount : [0],
      maxFreeItem :[0],
      freeItemType : [0],
      qtyType :['0']
    });
    this.getDetailArray.push(detail);

    this.showButton[this.getDetailArray.length-1] = '1';
    this.titleButtonOpen[this.getDetailArray.length-1] = 'Buka';
  }
  addProductRequired() {
    const productRequired = this.fb.group({
      requiredPCode: [0],
      requiredQty: [0],
      requiredQtyType: ['0']
    });
    this.getProductRequiredArray.push(productRequired);
  }

  get getDetailArray() {
    return (<FormArray>this.formPromotionDetailEdit.get('detail'));
  }
  get getProductRequiredArray() {
    return (<FormArray>this.formPromotionDetailEdit.get('productRequired'));
  }

  filterProducts(products: Product[], text: string) {
    return products.filter(product => {
      return product.product_name.toLowerCase().indexOf(text) !== -1 ||
        product.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  searchProducts(event: {
    component: IonicSelectableComponent,
    text: string
  }, index: any) {

    let text: string;

    if(this.searchValue.length > 0) {
      text = this.searchValue[index] ? this.searchValue[index] : '';
    }

    text = event.text !== '' ? event.text.trim().toLowerCase() : text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterProducts(this.productList, text);
    this.searchValue[index] = text;
    event.component.endSearch();
  }

  productChange(event: {
    component: IonicSelectableComponent,
    value: any
  }, index: any) {
    let product: any = event.value;
    if(product) {
      let checkStock: any = this.stockData.find(x => x.product_id === product.id);
      let stock: any = checkStock ? checkStock.end_stock : 0;
      (this.getDetailArray.at(index) as FormGroup).get('stock').patchValue(stock);
    }
  }

  deleteDetail(i: any) {
    this.getDetailArray.removeAt(i);
    if((this.promotionTypeName=== 'NOMINAL' || this.promotionTypeName=='PERSENTASE') && this.productScanDisplay){
      this.productScanDisplay.splice(i, 1);
      this.productScan.splice(i, 1);
    }
    if(this.promotionTypeName === 'FREE ITEM (ITEM)') {
      this.productScan.splice(i, 1);
      this.productScanDisplay.splice(i, 1);
      this.productFreeItem.splice(i, 1);
      this.productFreeItemDisplay.splice(i, 1);
    }

    if(this.promotionTypeName === 'FREE ITEM (NOMINAL)') {
      this.productFreeItem.splice(i, 1);
      this.productFreeItemDisplay.splice(i, 1);
    }
  }

  deleteRequired(i: any) {
    this.getProductRequiredArray.removeAt(i);
    if((this.promotionTypeName=== 'NOMINAL' || this.promotionTypeName=='PERSENTASE') && this.productScanDisplay){
      //this.productScanDisplay.splice(i, 1);
      //this.productScan.splice(i, 1);
    }
  }

  validateInput() {
    const formPromotionDetail = this.formPromotionDetailEdit.value;
    let result = false;

    if(this.promotionTypeName === 'FREE ITEM (ITEM)') {
      let x = 0;
      while(x < formPromotionDetail.detail.length) {
        if(formPromotionDetail.detail[x].minBuy===0) {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Minimum Beli yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        if(formPromotionDetail.detail[x].freeQty===0) {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Qty Hadiah yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        if(!this.productFreeItem[x] && this.productFreeItemDisplay[x]==='') {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Produk Hadiah yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        if(this.productFreeItemDisplay[x] ==='sama dengan Product Scan'){
          formPromotionDetail.detail[x].freeItemType =1;
        }
        x++;
      }
    }

    if(this.promotionTypeName === 'FREE ITEM (NOMINAL)') {
      let x = 0;
      while(x < formPromotionDetail.detail.length) {
        if(formPromotionDetail.detail[x].minTotalTrans===0) {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Minimum Transaksi yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        if(formPromotionDetail.detail[x].freeQty===0) {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Qty Hadiah yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        if(!this.productFreeItem[x] && this.productFreeItemDisplay[x]==='') {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Produk Hadiah yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        if(this.productFreeItemDisplay[x] ==='sama dengan Product Scan'){
          formPromotionDetail.detail[x].freeItemType =1;
        }
        x++;
      }
    }

    if(this.promotionTypeName === 'PERSENTASE' || this.promotionTypeName === 'NOMINAL') {
      let x = 0;
      while(x < formPromotionDetail.detail.length) {
        if(((this.promoBasedOnValue == 2  || this.promoBasedOnValue ==3 )&& this.productValue==2)  &&  this.productScanDisplay[x]==null && formPromotionDetail.detail[x].qtyType=='0'){
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Product Promo yang masih kosong, silahkan diisi terlebih dahul' }).then(t => t.present());
          result = true;
          break;
        }
         if(this.promoBasedOnValue == 3 && this.productScanDisplay[x]==null && formPromotionDetail.detail[x].qtyType=='0'){
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Product Promo yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        if((this.promoBasedOnValue !=3 ) && (!formPromotionDetail.detail[x].minTotalTrans || formPromotionDetail.detail[x].minTotalTrans===0 )) {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Minimum Transaksi yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        if(formPromotionDetail.detail[x].value===0) {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Diskon yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
          result = true;
          break;
        }
        x++;
      }
    }

    if(this.promoBasedOnValue==2 && this.productValue==0){
      this.toastCtrl.create({ duration: 2000, message: 'Terdapat kolom yang masih kosong, silahkan diisi terlebih dahulu!' }).then(t => t.present());
      result = true;
    }
    if(result === false) {
      this.updateData();
    }
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formPromotionDetail = this.formPromotionDetailEdit.value;
      let arrPromotionId: any = [];
      let arrProduct: any = [];
      let arrMinimumTransaction: any = [];
      let arrType: any = [];
      let arrValue: any = [];
      let arrFreeQty: any = [];
      let arrMinimumBuy: any = [];
      let arrMaximumBuy : any = [];
      let arrFreeItemUsed: any = [];
      let arrMultipleFlag: any = [];
      let arrFreeItem: any = [];
      let arrMaxDiscount: any = [];
      let arrMaxFreeItem : any =[];
      let arrFreeItemType : any = [];
      let arrFreeItemProductType :any=[];
      let arrProductRequired :any=[];
      let arrQtyRequired :any=[];
      let arrQtyTypeRequired :any=[];
      let arrAppliesTo :any=[];
      let arrQtyType:any=[];

      for(let x = 0; x < formPromotionDetail.detail.length; x++) {
        arrPromotionId[x] = parseInt(this.promotionId);
        arrProduct[x] = this.productScan[x],
        arrMinimumTransaction[x] =this.myService.removeSeparator(formPromotionDetail.detail[x].minTotalTrans);
        arrAppliesTo[x] = this.myService.removeSeparator(formPromotionDetail.detail[x].appliesTo);
        arrType[x] = this.promotionTypeId;
        arrValue[x] = this.myService.removeSeparator(formPromotionDetail.detail[x].value);
        arrFreeQty[x] = this.myService.removeSeparator(formPromotionDetail.detail[x].freeQty);
        arrMinimumBuy[x] = this.myService.removeSeparator(formPromotionDetail.detail[x].minBuy);
        arrMaximumBuy[x] = this.myService.removeSeparator(formPromotionDetail.detail[x].maxBuy);
        arrFreeItemUsed[x] = 0;
        arrMultipleFlag[x] = formPromotionDetail.detail[x].multipleFlag;
        arrMaxDiscount[x] = this.myService.removeSeparator(formPromotionDetail.detail[x].maxDiscount);
        arrMaxFreeItem[x] = this.myService.removeSeparator(formPromotionDetail.detail[x].maxFreeItem);
        arrFreeItemType[x] = formPromotionDetail.detail[x].freeItemType;
        arrQtyType[x] =formPromotionDetail.detail[x].qtyType;
        this.promotionTypeName === 'PROMO BERTINGKAT' || this.promotionTypeName === 'FREE ITEM AND NOMINAL' || this.promotionTypeName === 'FREE ITEM (ITEM)' || this.promotionTypeName === 'FREE ITEM (NOMINAL)' ? arrFreeItem[x] = this.productFreeItem[x] : arrFreeItem = [];
        this.promotionTypeName === 'PROMO BERTINGKAT' || this.promotionTypeName === 'FREE ITEM AND NOMINAL' || this.promotionTypeName === 'FREE ITEM (ITEM)' || this.promotionTypeName === 'FREE ITEM (NOMINAL)' ? arrFreeItemProductType[x] = this.productFreeItemType[x] : arrFreeItemProductType = [];
      }
      for(let x = 0; x < formPromotionDetail.productRequired.length; x++) {
        arrProductRequired[x] = this.productRequired[x],
        arrQtyRequired[x] = this.myService.removeSeparator(formPromotionDetail.productRequired[x].requiredQty);
        arrQtyTypeRequired[x] =formPromotionDetail.productRequired[x].requiredQtyType;
      }

      let options = {
        "token": this.token,
        "promotion_id": arrPromotionId,
        "product_id": arrProduct,
        "applies_to" : arrAppliesTo,
        "type" : arrType,
        "value": arrValue,
        "free_qty" : arrFreeQty,
        "minimum_buy" : arrMinimumBuy,
        "minimum_transaction" : arrMinimumTransaction,
        "free_item_used" : arrFreeItemUsed,
        "multiple_flag" : arrMultipleFlag,
        "free_item" : arrFreeItem,
        "free_item_product_type":arrFreeItemProductType,
        "free_item_type": arrFreeItemType,
        "promo_based_on" :this.promoBasedOnValue ? this.promoBasedOnValue : 0,
        "product_type" : this.productValue,
        "discount_type":this.discountType,
        "product_list":this.listProductScan,
        "max_discount" : arrMaxDiscount,
        "max_free_item" : arrMaxFreeItem,
        "maximum_buy" : arrMaximumBuy,
        "product_required":arrProductRequired,
        "qty_product_required":arrQtyRequired,
        "qty_type_product_required":arrQtyTypeRequired,
        "price_type":this.priceType.toString(),
        "qty_type":arrQtyType
      };
      this.promotionService.addPromotionDetail(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    })
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/promotions']);;
          }
        }
      ]
    });

    await alert.present();
  }
  checkPromo(field: any) {
    let cssClass: any = '';
    if(field=='appliesTo'){
      cssClass='display-none';
      if((this.promotionTypeName=='PERSENTASE' && this.promoBasedOnValue==3) || (this.promotionTypeName=='NOMINAL' && this.promoBasedOnValue==3)){
        cssClass='';
      }
    }
    if (field=='product'){
      cssClass = 'display-none';
    }
    if (field=='price'){
      if(this.priceType !==2){
        cssClass = 'display-none';
      }
    }
    if (field=='total_belanja'){
      
      if((this.priceType==3 && this.promoBasedOnValue==0) || (this.promotionTypeName==='PROMO BERTINGKAT' && this.discountType==0)|| this.priceType ===2 || this.promotionTypeName === 'FREE ITEM (ITEM)' || this.promoBasedOnValue==3 || (this.promotionTypeName==='PROMO BERTINGKAT' && (this.promoBasedOnValue===3  && this.discountType===0))){
        cssClass = 'display-none';
      }
    }
    if (field=='product_scan'){
      if((this.priceType==3 && this.promoBasedOnValue==0) || (this.promotionTypeName === 'FREE ITEM AND NOMINAL' && this.priceType==2)||(this.promotionTypeName === 'FREE ITEM AND NOMINAL' && this.promoBasedOnValue==1) ||(this.promotionTypeName=='FREE ITEM AND NOMINAL' && this.productValue==1)|| this.promotionTypeName=='PROMO BERTINGKAT' ||(this.promotionTypeName=='FREE ITEM (ITEM)' && this.productValue==1) || (this.promotionTypeName=='FREE ITEM (NOMINAL)' && this.promoBasedOnValue==1) ||(this.promotionTypeName=='FREE ITEM (NOMINAL)' && this.productValue==1) ||(this.promotionTypeName === 'NOMINAL' || this.promotionTypeName === 'PERSENTASE')  && this.promoBasedOnValue!=3 && this.productValue !=2){
        cssClass = 'display-none';
      }
    }
    if (field=='min_buy'){
     //if((this.promotionTypeName==='PROMO BERTINGKAT' && this.promoBasedOnValue!==3) || this.promotionTypeName === 'FREE ITEM AND NOMINAL' || this.promotionTypeName === 'FREE ITEM (NOMINAL)'  || this.promotionTypeName === 'PERSENTASE' ||( this.promotionTypeName === 'NOMINAL' && this.promoBasedOnValue!=3)){
      if((this.promotionTypeName==='PROMO BERTINGKAT' && this.discountType==0) || (this.promotionTypeName==='PROMO BERTINGKAT' && this.promoBasedOnValue!==3) || (this.promotionTypeName === 'FREE ITEM AND NOMINAL' &&  this.promoBasedOnValue!=3) || this.promotionTypeName === 'FREE ITEM (NOMINAL)'  || (this.promotionTypeName === 'PERSENTASE' && this.promoBasedOnValue!=3) ||( this.promotionTypeName === 'NOMINAL' && this.promoBasedOnValue!=3)){
        cssClass = 'display-none';
      }
    }
    if (field=='max_buy'){
      if(  (this.promotionTypeName === 'FREE ITEM AND NOMINAL')|| this.promotionTypeName === 'FREE ITEM AND NOMINAL' ||  this.promotionTypeName === 'FREE ITEM (ITEM)'  || this.promotionTypeName === 'FREE ITEM (NOMINAL)'  || (this.promotionTypeName === 'PERSENTASE'  && this.promoBasedOnValue!=3) || ( this.promotionTypeName === 'NOMINAL' && this.promoBasedOnValue!=3) || this.promotionTypeName=='PROMO BERTINGKAT'){
        cssClass = 'display-none';
      }
    }
    if (field=='free_item'){
      if(this.promotionTypeName !== 'FREE ITEM AND NOMINAL' && this.promotionTypeName !== 'FREE ITEM (ITEM)' && this.promotionTypeName !== 'FREE ITEM (NOMINAL)' ){
        if( this.promotionTypeName==='PROMO BERTINGKAT'&& this.discountType===3){
          cssClass = '';
        }
        else{
          cssClass = 'display-none';
        }
      }
      else if(this.promotionTypeName==='FREE ITEM AND NOMINAL' && (this.priceType==3 && this.promoBasedOnValue==0)){
        cssClass = 'display-none';
      }
    }
    if (field=='nominal'){
      if(this.promotionTypeName==='PROMO BERTINGKAT'){

      if(this.priceType ===2 || this.discountType!==1){
        cssClass = 'display-none';}
      }
      else if((this.priceType ===2 || this.promotionTypeName !== 'NOMINAL' && this.promotionTypeName !== 'FREE ITEM AND NOMINAL') || (this.priceType ===3 && this.promotionTypeName === 'FREE ITEM AND NOMINAL')){
        cssClass = 'display-none';
      }
    }
    if (field=='persentase'){
      if(this.promotionTypeName==='PROMO BERTINGKAT'){
        if(this.priceType==3 && this.promoBasedOnValue!=0 ){
          cssClass = '';
        }
        if(this.discountType!==2){
        cssClass = 'display-none';
        }
      }
      else if((this.promotionTypeName !== 'PERSENTASE' && this.promotionTypeName !== 'FREE ITEM AND NOMINAL') || (this.priceType !==3 && this.promotionTypeName === 'FREE ITEM AND NOMINAL')){
        cssClass = 'display-none';
      }
    }
    if (field=='qty_hadiah'){
      if(this.promotionTypeName  !== 'FREE ITEM AND NOMINAL'  && this.promotionTypeName  !== 'FREE ITEM (ITEM)' && this.promotionTypeName !== 'FREE ITEM (NOMINAL)' ){
        if( this.promotionTypeName==='PROMO BERTINGKAT'&& this.discountType===3){
            cssClass = '';
        }
        else{
          cssClass = 'display-none';
        }
      }
      else if(this.promotionTypeName==='FREE ITEM AND NOMINAL' && (this.priceType==3 && this.promoBasedOnValue==0)){
        cssClass = 'display-none';
      }
    }
    if (field=='multiple'){
      if((this.promotionTypeName==='FREE ITEM AND NOMINAL' && this.priceType==3 && this.promoBasedOnValue==0) ||(this.promotionTypeName === 'PERSENTASE' && this.promoBasedOnValue!=3)|| (this.promotionTypeName === 'NOMINAL' && this.promoBasedOnValue==3) || (this.promotionTypeName === 'PROMO BERTINGKAT' && (this.promoBasedOnValue==1 ||(this.promoBasedOnValue==2))) || (this.promotionTypeName === 'PROMO BERTINGKAT' && (this.discountType!=1 && this.discountType!=3))){
        cssClass = 'display-none';
      }
    }
    if (field=='max_discount'){
      if( (this.promotionTypeName === 'FREE ITEM AND NOMINAL')|| (this.promotionTypeName === 'FREE ITEM (NOMINAL)' || this.promotionTypeName === 'FREE ITEM (ITEM)')||(this.promotionTypeName === 'NOMINAL' && this.promoBasedOnValue==3) || (this.promotionTypeName === 'PROMO BERTINGKAT' && this.discountType!=2)){
        cssClass = 'display-none';
      }
    }
    if (field=='max_free_item'){
      if( this.promotionTypeName !== 'FREE ITEM AND NOMINAL' && this.promotionTypeName !== 'FREE ITEM (NOMINAL)' &&  this.promotionTypeName !== 'FREE ITEM (ITEM)' ){
        if( this.promotionTypeName==='PROMO BERTINGKAT'&& this.discountType===3){
          cssClass = '';
        }
        else{
          cssClass = 'display-none';
        }
      }
      else if(this.promotionTypeName==='FREE ITEM AND NOMINAL'){
        if(this.priceType==3 && this.promoBasedOnValue==0){
          cssClass='display-none';
        }
      }
    }
    
    if (field=='qty_type'){
      if(
        (this.promotionTypeName=='PERSENTASE' && (this.promoBasedOnValue==1|| (this.promoBasedOnValue==2 && this.productValue==1)))
        || (this.promotionTypeName=='NOMINAL' && (this.promoBasedOnValue==1|| (this.promoBasedOnValue==2 && this.productValue==1)))
        || (this.promotionTypeName=='FREE ITEM (NOMINAL)' && (this.promoBasedOnValue==1|| (this.promoBasedOnValue==2 && this.productValue==1)))
        || (this.promotionTypeName=='FREE ITEM (ITEM)'  && this.productValue==1)
        || this.promotionTypeName=='PROMO BERTINGKAT' 
        || (this.promotionTypeName=='FREE ITEM AND NOMINAL'&& (this.priceType==2 || this.promoBasedOnValue==1|| (this.promoBasedOnValue==2 && this.productValue==1)))// lanjut sini
        
      ){
        
        cssClass = 'display-none';
      }
    }
    return cssClass;
  }

  async openPromotionDetail(index: any, action: any) {
    const modal = await this.modalController.create({
      component: PromotionDetailComponent,
      componentProps: {
        'token':this.token,
        'counterId':this.counterId,
        'mstProductList': this.mstProductList,
        'mstProductGimmickList': this.productGimmickList,
        'productScan': this.productScan[index] ? this.productScan[index] : null,
        'productFreeItem': this.productFreeItem[index] ? this.productFreeItem[index] : null,
        'listProductScan':this.listProductScan ? this.listProductScan :null,
        'productRequired':this.productRequired[index] ? this.productRequired[index] :null,
        'stockData':this.stockData,
        'action': action,
        'viewOnly':"N"
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData.data !== '') {
        let dataProduct: any[] = modelData.data;
        let productList: string = '';
        let productListDisplay: string = '';
        let productType:string='';

        if(dataProduct.length > 0) {
          for(let i = 0; i < dataProduct.length; i++) {
            productList = productList + dataProduct[i].id;
            productListDisplay = productListDisplay + dataProduct[i].id;
            productType = productType+dataProduct[i].type;
            if((i+1) !== dataProduct.length) {
              productList = productList + ';';
              productType=productType+';';
              if(action=='productRequired'){
                productListDisplay = productListDisplay + ' / ';
              }else{
                productListDisplay = productListDisplay + ', ';}
            }
          }

          if(action === 'product') {
            this.productScan[index] = productList;
            this.productScanDisplay[index] = productListDisplay;
          }
          else if(action === 'listProduct') {
            this.listProductScan= productList;
            this.listProductScanDisplay= productListDisplay;
          }
          else if(action === 'productRequired') {
            this.productRequired[index]= productList;
            this.productRequiredDisplay[index]= productListDisplay;
          }
          else if(action === 'bonus') {
            this.productFreeItemType[index]=productType;
            this.productFreeItem[index] = productList;
            this.productFreeItemDisplay[index] = productListDisplay;
            this.showButton[index] = '1';
            if(this.promoBasedOnValue == 1  && this.productValue==0){
              this.showButton[index] = '0';
            }
            this.titleButtonOpen[index] = 'Buka';
            this.formPromotionDetailEdit.value.detail[index]['freeItemType'] = 0;
          }
        } else {
          if(action === 'product') {
            this.productScan[index] = null;
            this.productScanDisplay[index] = null;
          }

          if(action === 'bonus') {
            this.productFreeItem[index] = null;
            this.productFreeItemType[index]=null;
            this.productFreeItemDisplay[index] = null;
          }
        }
      } else {
        if(action === 'product') {
          this.productScan[index] = null;
          this.productScanDisplay[index] = null;
        }

        if(action === 'bonus') {
          this.productFreeItem[index] = null;
          this.productFreeItemType[index]=null;
          if(this.showButton[index] == '1'){
            this.productFreeItemDisplay[index] = null;
          }
        }
      }
    });

    return await modal.present();
  }

  sameAsProductScan(index: any) {
    this.formPromotionDetailEdit.value.detail[index]['freeItemType'] = 1;
    this.productFreeItem[index] = null;
    this.productFreeItemDisplay[index] = 'sama dengan Product Scan';
    this.showButton[index] = '0';
    this.titleButtonOpen[index] = 'ganti';
  }
  

  changePriceType(event:any) {   
    if(this.getDetailArray.length==0){
      this.deleteDetail(0);
    }
    for (let i=this.getDetailArray.length;i>= 0;i--){
      this.deleteDetail(i);
    }
    this.priceType = event.detail.value;
    if(this.priceType==2){
      this.showPromoBase=false;
      this.showProduct=false;
    }
    else{
      this.showPromoBase=true;
     //sini this.showProduct=true;
    }
    const detail = this.fb.group({
      detailId: [0],
      product: [ null],
      appliesTo:[],
      minTotalTrans: [0],
      value: [0],
      freeQty: [0],
      minBuy: [0],
      maxBuy: [0],
      multipleFlag: ['1'],
      maxDiscount :[0],
      maxFreeItem:[0],
      freeItemType :[0],
      qtyType :['0']
    });
    this.getDetailArray.push(detail);
  }

  promoBasedOn(event:any) {
    this.selectedProduct = false;
    this.productValue = 0;
    this.promoBasedOnValue = event.detail.value;
    this.showProduct = false;

    this.formPromotionDetailEdit = this.fb.group({
      priceType: [this.priceType],
      basedOnValue: [ this.promoBasedOnValue],
      productType : [0],
      discountType:[0],
      detail: this.fb.array([]),
      productRequired:this.fb.array([])
    });
    if(this.getDetailArray.length==0){
      this.deleteDetail(0);
    }
    for (let i=this.getDetailArray.length;i>= 0;i--){
      this.deleteDetail(i);
    }
    if(this.promotionTypeName=='PROMO BERTINGKAT' && this.promoBasedOnValue==1 && this.discountType!==0 && this.discountType!==3){
      this.selectedProduct = true;
    }
    if(this.promoBasedOnValue==3){
      this.productValue = 2;
      this.selectedProduct = true;
    }

    if(this.promoBasedOnValue ==2 ){
      this.showProduct = true;
    }
    else{
      const detail = this.fb.group({
        detailId: [0],
        product: [this.promotionTypeName !== 'FREE ITEM (ITEM)' && this.promotionTypeName !== 'FREE ITEM (NOMINAL)' ? Validators.required : null],
        appliesTo:[0],
        minTotalTrans: [0],
        value: [0],
        freeQty: [0],
        minBuy: [0],
        maxBuy: [0],
        multipleFlag: ['1'],
        maxDiscount :[0],
        maxFreeItem:[0],
        freeItemType :[0],
        qtyType :['0']
      });
      this.getDetailArray.push(detail);
    }
  }


  chooseProduct(event:any){    
    this.showListProduct =false;
    
    this.productValue = event.detail.value;
    if(this.promotionTypeName!='PROMO BERTINGKAT'){
        for (let i=this.getDetailArray.length;i>= 0;i--){
          this.deleteDetail(i);
        }
    
      if(this.promoBasedOnValue==3){
        this.productValue = 2;
      }
    }
      if((this.getDetailArray.length ==0 && this.promotionTypeName=='PROMO BERTINGKAT') || (this.promotionTypeName!='PROMO BERTINGKAT' && (event.detail.value!=0 || this.promoBasedOnValue!=2))){
        const detail = this.fb.group({
          detailId: [0],
          product: [this.promotionTypeName !== 'FREE ITEM (ITEM)' && this.promotionTypeName !== 'FREE ITEM (NOMINAL)' ? Validators.required : null],
          appliesTo:[0],
          minTotalTrans: [0],
          value: [0],
          freeQty: [0],
          minBuy: [0],
          maxBuy: [0],
          multipleFlag: ['1'],
          maxDiscount :[0],
          maxFreeItem : [0],
          freeItemType :[0],
          qtyType :['0']
        });
        this.getDetailArray.push(detail);
      }
  
    if(this.productValue==2){
      this.showButton[0] = '1';
    }
    if(this.productValue==2){
      this.selectedProduct = true;
      if(this.promotionTypeName=='PROMO BERTINGKAT'){
        this.showListProduct =true;
      }
    }
    else{
      this.selectedProduct = false;
    }
  }
  chooseDiscountType(event:any){
    this.discountType = event.detail.value;
    if(this.promoBasedOnValue==1 &&  this.discountType!==0){
      this.selectedProduct = true;
    }
    if(this.promoBasedOnValue==3){
      this.showProduct=true;
    }
  }
  getChangeNominal( index: any,columnname:any) {
    let arrform=<FormArray>this.formPromotionDetailEdit.controls['detail'];    
    let cur= arrform.controls[index].value[columnname];
    let curWithSeparator='0';
    if(cur!='0' && cur!='' && cur!=null){
     // const parts = this.myService.unFormat(String(cur)).split(this.DECIMAL_SEPARATOR);
      curWithSeparator = this.myService.separator(cur);// parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
      curWithSeparator = String(curWithSeparator).replace(/[^0-9,]/g, '');
    }
    arrform.controls[index].patchValue({[columnname]:curWithSeparator});
  }
  getChangeNominalRequired( index: any,columnname:any) {
    let arrform=<FormArray>this.formPromotionDetailEdit.controls['productRequired'];    
    let cur= arrform.controls[index].value[columnname];
    let curWithSeparator='0';
    if(cur!='0' && cur!='' && cur!=null){
      curWithSeparator = this.myService.separator(cur);// parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
      curWithSeparator = String(curWithSeparator).replace(/[^0-9,]/g, '');
    }
    arrform.controls[index].patchValue({[columnname]:curWithSeparator});
  }
}
