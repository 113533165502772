import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { SetupApprovalService } from './setup-approval.service';

@Component({
  selector: 'app-setup-approval',
  templateUrl: './setup-approval.component.html',
  styleUrls: ['./setup-approval.component.scss'],
})
export class SetupApprovalComponent implements OnInit {

  @Input() userId: any;
  @Input() approvalName: any;
  @Input() approvalLevel: any;
  @Input() token: any;
  @Input() no: any;
  
  users: any[]=[];
  keyword: any;
  constructor(
    public modalController: ModalController,
    private setupApprovalService: SetupApprovalService,
    private utilService: UtilService,
    private toastCtrl: ToastController
  ) { }

  ngOnInit() {

    console.log(this.no);
  }
  

  async dismissModal() {
    let close: any[] = [];

      let productData = {
        userId: this.userId,
        approvalTingkat: this.approvalLevel
      };

      close.push(productData);
    
    await this.modalController.dismiss(close);
  }

  async search() {
    this.users = [];
    if(this.keyword.length >= 3) {
      this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
        let options = {
          "token": this.token,
          "keyword": this.keyword
        };

        this.setupApprovalService.getUserSearch(options).subscribe(async (response) => {
          if(response.results) {
            this.users = [];
            if(response.results.data.length > 0) {
              console.log(this.approvalLevel);
              for (let index = 0; index < response.results.data.length; index++) {
                this.users.push({
                  user_id: response.results.data[index].id,
                  name: response.results.data[index].name,
                  email: response.results.data[index].email,
                  level: this.approvalLevel,
                  action: this.userId,
                  no:this.no
                }); 
              }
            }
            this.utilService.loadingDismiss();
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari user.' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari user.' }).then(t => t.present());
        });
      });
    }
  }

  chooseUser(id:number){
    let user = this.users.find(x => x.user_id === id);
    this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {      
      this.modalController.dismiss({user: user});
    });
  }
}
