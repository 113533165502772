import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-find-product',
  templateUrl: './find-product.component.html',
  styleUrls: ['./find-product.component.scss'],
})
export class FindProductComponent implements OnInit {

  @Input() productList: any[];
  productSearch: any[];
  productParam: any;

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() {}

  async dismissModal(product) {
    await this.modalController.dismiss(product);
  }

  searchProduct() {
    this.productSearch = [];
    this.productSearch = this.productList.filter(product => {
      return product.product_name.toString().toLowerCase().indexOf(this.productParam) !== -1 ||
        product.id.toString().toLowerCase().indexOf(this.productParam) !== -1;
    });
  }

}
