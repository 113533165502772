import { ProductReceiving, ProductReceivingDetail } from './product-receiving.model';

export class ProductReceivingBundle {

    productReceiving: ProductReceiving;
    productReceivingDetail: ProductReceivingDetail;

    constructor(model?) {
        model = model || {};
        this.productReceiving = model.productReceiving || new ProductReceiving();
        this.productReceivingDetail = model.productReceivingDetail || new ProductReceivingDetail();
    }
}