import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, ToastController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { WarehouseMutationInService } from './warehouse-mutation-in.service';
import { WarehouseMutationStatus } from '../../enum/WarehouseMutationStatus';

@Component({
  selector: 'app-warehouse-mutation-in',
  templateUrl: './warehouse-mutation-in.page.html',
  styleUrls: ['./warehouse-mutation-in.page.scss'],
})
export class WarehouseMutationInPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  rows: any;
  warehouseMutationList: any;
  filteredData: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterId: any = '';

  db: any;

  mutationStatus = WarehouseMutationStatus;
  warehouseMutationStatusList = Object.keys(WarehouseMutationStatus).filter(
    mutationStatus => typeof this.mutationStatus[mutationStatus] === 'number'
  );

  counterTableName = 'mst_counters';
  counterColumnList = '(id, branch_id, counter_name, trans_date, first_address, last_address, phone, footer_text, latitude, longitude, active, created_by, updated_by, created_at, updated_at)';
  warehouseTableName = 'mst_warehouses';
  warehouseColumnList = '(id, counter_id, warehouse_name, warehouse_type_id, unit_size, length_size, width_size, height_size, active, created_by, updated_by, created_at, updated_at)';

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private warehouseMutationService: WarehouseMutationInService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.pageCallback({ offset: this.page.offset });
        // this.openDB();
        // this.getLocalData(this.counterTableName, this.counterColumnList, 'counter');
        // this.getLocalData(this.warehouseTableName, this.warehouseColumnList, 'warehouse');
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list,
        "menu_name": "warehouse_in"
      };

      this.warehouseMutationService.getWarehouseMutation(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.warehouseMutationList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  goToUpdatePage(id: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        warehouseMutationId: id
      }
    };
    this.navCtrl.navigateForward(['/warehouse-mutation-in/warehouse-mutation-in-detail'], navigationExtras);
  }

  async openAdvancedFilters() {
    //
  }

  getLocalData(table: any, column: any, name: any) {
    this.warehouseMutationService.getLocalData(name, { "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          let tableName = table;
          let columnList = column;
          
          this.createTable(tableName, columnList).then((res) => {
            if(tableName === 'mst_counters') { this.inputCounterLocalData(response); }
            if(tableName === 'mst_warehouses') { this.inputWarehouseLocalData(response); }
            
          }, (err) => {
            console.log(err);
          });
        }
      }
    }, () => {
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  createTable(tableName: any, columnList: any) {
    let sqlQuery: string = 'CREATE TABLE IF NOT EXISTS ' + tableName + columnList;

    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(sqlQuery, [],
        (tx, result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  execQuery(sqlQuery: any) {
    this.db.transaction((tx) => {
      tx.executeSql(sqlQuery, [],
      (tx, result) => {
      }, (error) => {
        console.log(error);
      });
    });
  }

  inputCounterLocalData(response: any) {
    for (let i = 0; i < response.results.length; i++) {

      let insertQuery = 'INSERT INTO ' + this.counterTableName + this.counterColumnList + ' VALUES (' +
      response.results[i].id + ', ' + response.results[i].branch_id + ', "' +
      response.results[i].counter_name + '", "' +
      response.results[i].trans_date + '", "' + response.results[i].first_address + '", "' +
      response.results[i].last_address + '", "' + response.results[i].phone + '", "' + 
      response.results[i].footer_text + '", "' + response.results[i].latitude + '", "' + 
      response.results[i].longitude + '", "' + response.results[i].active + '", "' +
      response.results[i].created_by + '", "' + response.results[i].updated_by + '", "' +
      response.results[i].created_at + '", "' + response.results[i].updated_at + '")';

      let selectQuery = 'SELECT * FROM ' + this.counterTableName + ' WHERE id = ' + response.results[i].id;
      let deleteQuery = 'DELETE FROM ' + this.counterTableName + ' WHERE id = ' + response.results[i].id;

      this.db.transaction((tx) => {
        tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length === 0) {
            this.execQuery(insertQuery);
          } else {
            this.execQuery(deleteQuery);
            this.execQuery(insertQuery);
          }
        }, (error) => {
          console.log(error);
        });
      });
    }
  }

  inputWarehouseLocalData(response: any) {
    for (let i = 0; i < response.results.length; i++) {

      let insertQuery = 'INSERT INTO ' + this.warehouseTableName + this.warehouseColumnList + ' VALUES (' +
      response.results[i].id + ', ' + response.results[i].counter_id + ', "' +
      response.results[i].warehouse_name + '", ' + response.results[i].warehouse_type_id + ', "' +
      response.results[i].unit_size + '", ' + response.results[i].length_size + ', ' +
      response.results[i].width_size + ', ' + response.results[i].height_size + ', "' +
      response.results[i].active + '", "' + response.results[i].created_by + '", "' +
      response.results[i].updated_by + '", "' + response.results[i].created_at + '", "' +
      response.results[i].updated_at + '")';

      let selectQuery = 'SELECT * FROM ' + this.warehouseTableName + ' WHERE id = ' + response.results[i].id;
      let deleteQuery = 'DELETE FROM ' + this.warehouseTableName + ' WHERE id = ' + response.results[i].id;

      this.db.transaction((tx) => {
        tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length === 0) {
            this.execQuery(insertQuery);
          } else {
            this.execQuery(deleteQuery);
            this.execQuery(insertQuery);
          }
        }, (error) => {
          console.log(error);
        });
      });
    }
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 6;
    let keys = Object.keys(this.warehouseMutationStatusList[0]);
    this.rows = this.filteredData.filter(function(item){
      for (let i=0; i < columnLength; i++){
        if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });

    this.page.offset = 0;
  }

  checkStatus(status: any) {
    let cssClass: any = '';
    if(status === '0') { cssClass = 'status pending'}
    if(status === '1') { cssClass = 'status ok'}
    if(status === '2') { cssClass = 'status void'}
    if(status === '3') { cssClass = 'status void'}
    return cssClass;
  }

}
