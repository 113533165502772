import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReceiptVoucherEditPageRoutingModule } from './receipt-voucher-edit-routing.module';

import { ReceiptVoucherEditPage } from './receipt-voucher-edit.page';

import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    IonicSelectableModule,
    ReceiptVoucherEditPageRoutingModule
  ],
  declarations: [ReceiptVoucherEditPage]
})
export class ReceiptVoucherEditPageModule {}
