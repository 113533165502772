import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProductRequestService } from '../product-request.service';
import { ProductRequestBundle } from '../../../models/product-request-bundle.model';
import { ProductRequestStatus } from '../../../enum/ProductRequestStatus';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import { FindProductComponent } from '../../../component/find-product/find-product.component';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-product-request-detail',
  templateUrl: './product-request-detail.page.html',
  styleUrls: ['./product-request-detail.page.scss'],
})
export class ProductRequestDetailPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  productRequestId: any;
  productList: Product[];
  counterList: any[] = [];
  warehouseList: any[] = [];
  productRequestData: any;
  productRequestDetailData: any[] = [];
  approvalHistoryData: any[] = [];
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;
  unitType: any[] = ['PIECES'];
  statusRequest: any = 0;
  statusName: any;
  productRequestDetailList: any[] = [];

  formProductRequestDetail: FormGroup;

  requestStatus = ProductRequestStatus;
  requestStatusList = Object.keys(ProductRequestStatus).filter(
    requestStatus => typeof this.requestStatus[requestStatus] === 'number'
  );

  userAccess: any[] = [];
  roleAccess = new RoleAccess();
  roleName: any;

  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];
  showProposal:boolean=false;
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private modalController: ModalController,
    private storage: Storage,
    private requestService: ProductRequestService,
    private router: Router,
    private userDataProvider: UserData
  ) { }

  ngOnInit() {
    this.buildFormProductRequestDetail();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userAccess = access;
        this.roleName = this.userProfile.role_detail ? this.userProfile.role_detail.role_name : null;
        this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getAllProduct();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getAllProduct() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.requestService.getProduct({ "token": this.token }).subscribe((response) => {
        if (response.status.code === 200) {
          this.productList = response.results;
        }
  
        this.getData();
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.productRequestId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.productRequestId = this.productRequestId.replaceAll('"','');
      let options = { 
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.requestService.getRequestforEdit(this.productRequestId, options)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        this.productRequestData = response.results.product_request_data;
        this.productRequestDetailData = response.results.product_request_detail_data;
        this.counterList = response.results.counter_data;
        this.warehouseList = response.results.warehouse_data;

        let userCreate: any = response.results.user_create_data;
        this.createdBy = userCreate ? userCreate[0].name : null;
        let userUpdate: any = response.results.user_update_data;
        this.updatedBy = userUpdate ? userUpdate[0].name : null;
        this.approvalHistoryData = response.results.approval_history_data;

        if(this.productRequestData.length > 0) {
          this.statusRequest = this.productRequestData[0].status;
          this.createdAt = this.productRequestData[0].created_at;
          this.updatedAt = this.productRequestData[0].updated_at;
          this.statusName = this.requestStatusList[parseInt(this.statusRequest)];

          this.buildFormProductRequestDetail();
        }

        if(this.productRequestDetailData.length > 0) {
          this.productRequestDetailList = [];
          for(let i = 0; i < this.productRequestDetailData.length; i++) {
            this.productRequestDetailList.push({
              'id' : i + 1,
              'product_id' : this.productRequestDetailData[i].product_id,
              'product_name' : this.productRequestDetailData[i].product_name,
              'qty_request': this.productRequestDetailData[i].qty,
              'unit': 'Pcs'
            });
          }
        } else {
          this.productRequestDetailList = [];
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  buildFormProductRequestDetail() {
    let proposalPeriod =null;
    if(this.productRequestData){
      if(this.productRequestData[0].proposal_number){
        this.showProposal=true;
      }
      if(this.productRequestData[0].proposal_start_date && this.productRequestData[0].proposal_end_date){
        proposalPeriod = this.productRequestData[0].proposal_start_date+' s/d '+ this.productRequestData[0].proposal_end_date ;
      }
    };
    this.formProductRequestDetail = this.fb.group({
      productRequestId: [this.productRequestData ? this.productRequestData[0].id : null],
      docDate: [this.productRequestData ? this.productRequestData[0].doc_date : null],
      needDate: [this.productRequestData ? this.productRequestData[0].need_date : null],
      counterId: [this.productRequestData ? this.productRequestData[0].counter_id : null],
      warehouseId: [this.productRequestData ? this.productRequestData[0].warehouse_id : null],
      description: [this.productRequestData ? this.productRequestData[0].desc : null],
      proposalNumber: [this.productRequestData ? this.productRequestData[0].proposal_number : null],
      proposalPeriod: [proposalPeriod],
      proposalDesc: [this.productRequestData ? this.productRequestData[0].proposal_desc : null],
      status: [this.statusName ? this.statusName : this.requestStatusList[0]]
    });
  }

  saveUpdate(action: any, status: any) {

    const formProductRequest = this.formProductRequestDetail.value;
    const productRequestBundle = new ProductRequestBundle();
    if(action === 'data') {
      let docDateConvert = this.utilService.convertDate(formProductRequest.docDate);
      let needDateConvert = this.utilService.convertDate(formProductRequest.needDate);
      let docDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;
      let dateOfNeed = needDateConvert.years + '-' + needDateConvert.months + '-' + needDateConvert.dates;

      productRequestBundle.productRequest.doc_date = docDate;
      productRequestBundle.productRequest.need_date = dateOfNeed;
      productRequestBundle.productRequest.desc = formProductRequest.description;

      let arrProduct: any = [];
      let arrQty: any = [];
      let arrUnitType: any = [];

      for(let x = 0; x < this.productRequestDetailList.length; x++) {
        let htmlIdQtyRequest: any = 'qtyRequest_' + x;
        let qtyRequest: any = (<HTMLInputElement>document.getElementById(htmlIdQtyRequest)).value;

        arrProduct[x] = this.productRequestDetailList[x].product_id;
        arrQty[x] = parseInt(qtyRequest);
        arrUnitType[x] = 0;
      }

      productRequestBundle.productRequestDetail.product_id = arrProduct;
      productRequestBundle.productRequestDetail.qty = arrQty;
      productRequestBundle.productRequestDetail.unit = arrUnitType;
    }

    if(action === 'status') {
      let timezoneName = this.userProfile.counter_detail.timezone !== undefined ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
      let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
      let convertDate = this.utilService.convertDate(new Date());
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

      productRequestBundle.approvalHistory.transaction_id = this.productRequestId;
      productRequestBundle.approvalHistory.username = this.userProfile.username;
      productRequestBundle.approvalHistory.status = status;
      productRequestBundle.approvalHistory.trans_date = transDate + ' ' + transTime;
    }

    productRequestBundle.productRequest.status = status;
    productRequestBundle.productRequest.updated_by = this.userProfile.username;

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.requestService.updateRequestBundle(this.productRequestId, productRequestBundle).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/product-request']);;
          }
        }
      ]
    });

    await alert.present();
  }

  addDetail() {
    let length = this.productRequestDetailList.length;
    this.productRequestDetailList.push({
      'id' : length + 1,
      'product_id' : null,
      'product_name' : null,
      'unit': null
    });
  }

  async findProduct(index: any) {
    const modal = await this.modalController.create({
      component: FindProductComponent,
      componentProps: {
        'productList': this.productList
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if(data) {
        let findProduct = this.productRequestDetailList.indexOf(data);
        if(findProduct === -1) {
          this.productRequestDetailList[index].product_id = data.id;
          this.productRequestDetailList[index].product_name = data.product_name;
        }
      }
    });

    return await modal.present();
  }

  deleteProduct(index: any) {
    this.productRequestDetailList.splice(index, 1);
  }

  /*addDetail() {
    const detail = this.fb.group({
      product: [null, Validators.required],
      qty: [0, Validators.required],
      typeUnit: [0]
    });
    this.getDetailArray.push(detail);
  }

  get getDetailArray() {
    return (<FormArray>this.formProductRequestDetail.get('detail'));
  }

  filterProducts(products: Product[], text: string) {
    return products.filter(product => {
      return product.product_name.toLowerCase().indexOf(text) !== -1 ||
        product.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  searchProducts(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterProducts(this.productList, text);
    event.component.endSearch();
  }

  deleteDetail(i: any) {
    this.getDetailArray.removeAt(i);
  }
  
  openDB() {
    this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  getCounterList() {
    let selectQuery = 'SELECT * FROM mst_counters';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.counterList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let counterData = {
                'id': result.rows[x].id,
                'counter_name': result.rows[x].counter_name
              };
              this.counterList.push(counterData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getWarehouseList() {
    let selectQuery = 'SELECT * FROM mst_warehouses';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.warehouseList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let warehouseData = {
                'id': result.rows[x].id,
                'warehouse_name': result.rows[x].warehouse_name
              };
              this.warehouseList.push(warehouseData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }
  
  saveApprovalHistory(status: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let convertTime = this.utilService.convertDateWithMoment(new Date(), 'Asia/Jakarta');
      let convertDate = this.utilService.convertDate(new Date());
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

      const approvalHistory = new ApprovalHistory();
      approvalHistory.transaction_id = this.productRequestId;
      approvalHistory.username = this.userProfile.username;
      approvalHistory.status = status;
      approvalHistory.trans_date = transDate + ' ' + transTime;

      this.requestService.addApprovalHistory(approvalHistory).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  saveUpdateRequestDetail() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formProductRequestDetail = this.formProductRequestDetail.value;
      let arrProduct: any = [];
      let arrQty: any = [];
      let arrUnitType: any = [];

      for(let x = 0; x < formProductRequestDetail.detail.length; x++) {
        arrProduct[x] = formProductRequestDetail.detail[x].product.id;
        arrQty[x] = parseInt(formProductRequestDetail.detail[x].qty);
        arrUnitType[x] = formProductRequestDetail.detail[x].typeUnit;
      }

      const requestDetail = new ProductRequestDetail();
      requestDetail.product_id = arrProduct;
      requestDetail.qty = arrQty;
      requestDetail.unit = arrUnitType;

      this.requestService.updateRequestDetail(this.productRequestId, requestDetail).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }*/

}
