import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RekapPromoPageRoutingModule } from './rekap-promo-routing.module';

import { RekapPromoPage } from './rekap-promo.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RekapPromoPageRoutingModule
  ],
  declarations: [RekapPromoPage]
})
export class RekapPromoPageModule {}
