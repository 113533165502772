import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController, NavController } from '@ionic/angular';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';
import { UserData } from '../../providers/user-data';

@Component({
  selector: 'app-product-receiving',
  templateUrl: './product-receiving.component.html',
  styleUrls: ['./product-receiving.component.scss'],
})
export class ProductReceivingComponent implements OnInit {
  
  @Input() idCounter: any;
  @Input() idWarehouse: any;
  @Input() token: any;
  baseUrl: any;
  productRequestData: any[] = [];

  constructor(
    public modalController: ModalController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private http: HttpClient,
    private userData: UserData,
    private navCtrl: NavController,
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
    this.getProductRequestDetail();
  }

  async dismissModal(productRequest: any) {
    let dataReturn: any[] = [];
    if(productRequest.id!==undefined){
      let data = {
        'id': productRequest.id,
        'counter_id': productRequest.counter_id,
        'warehouse_id': productRequest.warehouse_id,
        'proposal_number': productRequest.proposal_number
      };
      dataReturn.push(data);
    }
    await this.modalController.dismiss(dataReturn);
  }

  getProductRequestDetail() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const params = new HttpParams()
        .set('token', this.token)
        .set('counter_id', this.idCounter)
        .set('warehouse_id', this.idWarehouse);

      this.http.get(this.baseUrl + 'product-request-get-by-parameter', { params })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        this.productRequestData = response.results;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

}
