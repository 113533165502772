import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { WarehouseMutationInService } from '../warehouse-mutation-in.service';
import { WarehouseMutation } from '../../../models/warehouse-mutation.model';
import { ApprovalHistory } from '../../../models/approval-history.model';
import { WarehouseMutationStatus } from '../../../enum/WarehouseMutationStatus';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-warehouse-mutation-in-detail',
  templateUrl: './warehouse-mutation-in-detail.page.html',
  styleUrls: ['./warehouse-mutation-in-detail.page.scss'],
})
export class WarehouseMutationInDetailPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  warehouseList: any[] = [];
  mutationTypeList: any[] = [];
  
  warehouseMutationData: any;
  warehouseMutationDetailData: any[] = [];
  approvalHistoryData: any[] = [];
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;
  statusMutation: any = 0;

  formWarehouseMutationDetail: FormGroup;
  warehouseMutationId: any;

  db: any;

  mutationStatus = WarehouseMutationStatus;
  warehouseMutationStatusList = Object.keys(WarehouseMutationStatus).filter(
    mutationStatus => typeof this.mutationStatus[mutationStatus] === 'number'
  );

  userAccess: any[] = [];
  roleAccess = new RoleAccess();
  roleName: any;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private storage: Storage,
    private warehouseMutationService: WarehouseMutationInService,
    private router: Router,
    private userDataProvider: UserData
  ) { }

  ngOnInit() {
    this.buildFormWarehouseMutationDetail();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userAccess = access;
        this.roleName = this.userProfile.role_detail ? this.userProfile.role_detail.role_name : null;
        this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
        this.getData();
        // this.openDB();
        // this.getCounterList();
        // this.getWarehouseList();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getCounterList() {
    let selectQuery = 'SELECT * FROM mst_counters';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.counterList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let counterData = {
                'id': result.rows[x].id,
                'counter_name': result.rows[x].counter_name
              };
              this.counterList.push(counterData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getWarehouseList() {
    let selectQuery = 'SELECT * FROM mst_warehouses';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.warehouseList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let warehouseData = {
                'id': result.rows[x].id,
                'warehouse_name': result.rows[x].warehouse_name
              };
              this.warehouseList.push(warehouseData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  buildFormWarehouseMutationDetail() {
    this.formWarehouseMutationDetail = this.fb.group({
      warehouseMutationId: [null],
      fromCounterId: [null],
      fromWarehouseId: [null],
      toCounterId: [null],
      toWarehouseId: [null],
      mutationTypeId: [null],
      description: [null],
      status: [ this.warehouseMutationStatusList[0]],
      detail: this.fb.array([])
    });
  }

  get getDetailArray() {
    return (<FormArray>this.formWarehouseMutationDetail.get('detail'));
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      this.warehouseMutationId = snapshot.warehouseMutationId;

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.warehouseMutationService.getWarehouseMutationforEdit(this.warehouseMutationId,
          { "token": this.token, "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list })
          .subscribe((response) => {
          this.utilService.loadingDismiss();
          this.warehouseMutationData = response.results.warehouse_mutation_data;
          this.warehouseMutationDetailData = response.results.warehouse_mutation_detail_data;
          this.mutationTypeList = response.results.warehouse_mutation_type_data;
          this.counterList = response.results.mst_counter_data;
          this.warehouseList = response.results.mst_warehouse_data;
          let userCreate: any = response.results.user_create_data;
          this.createdBy = userCreate ? userCreate[0].name : null;
          let userUpdate: any = response.results.user_update_data;
          this.updatedBy = userUpdate ? userUpdate[0].name : null;
          this.approvalHistoryData = response.results.approval_history_data;
          if(this.warehouseMutationData.length > 0) {
            this.statusMutation = this.warehouseMutationData[0].receive_status;
            this.createdAt = this.warehouseMutationData[0].created_at;
            this.updatedAt = this.warehouseMutationData[0].updated_at;
            let statusName = this.warehouseMutationStatusList[parseInt(this.statusMutation)];

            this.formWarehouseMutationDetail = this.fb.group({
              warehouseMutationId: [this.warehouseMutationData[0].id],
              fromCounterId: [this.warehouseMutationData[0].from_counter_id],
              fromWarehouseId: [this.warehouseMutationData[0].from_warehouse_id],
              toCounterId: [this.warehouseMutationData[0].to_counter_id],
              toWarehouseId: [this.warehouseMutationData[0].to_warehouse_id],
              mutationTypeId: [this.warehouseMutationData[0].warehouse_mutation_type_id],
              description: [this.warehouseMutationData[0].desc],
              status: [statusName],
              detail: this.fb.array([])
            });
          }

          if(this.warehouseMutationDetailData.length > 0) {
            for(let i = 0; i < this.warehouseMutationDetailData.length; i++) {
              const detail = this.fb.group({
                productId: [this.warehouseMutationDetailData[i].product_id],
                product: [this.warehouseMutationDetailData[i].product_name],
                qty: [this.warehouseMutationDetailData[i].qty],
                descDetail: [this.warehouseMutationDetailData[i].desc_detail]
              });
              this.getDetailArray.push(detail);
            }
          } else {
            this.getDetailArray.clear();
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  saveUpdate(status: any) {

    const warehouseMutation = new WarehouseMutation();

    warehouseMutation.status = '1';
    warehouseMutation.receive_status = status;
    warehouseMutation.updated_by = this.userProfile.username;

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.warehouseMutationService.updateMutationOut(this.warehouseMutationId, warehouseMutation)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.saveApprovalHistory(status);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  saveApprovalHistory(status: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let convertTime = this.utilService.convertDateWithMoment(new Date(), 'Asia/Jakarta');
      let convertDate = this.utilService.convertDate(new Date());
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

      const approvalHistory = new ApprovalHistory();
      approvalHistory.transaction_id = this.warehouseMutationId;
      approvalHistory.username = this.userProfile.username;
      approvalHistory.status = status;
      approvalHistory.trans_date = transDate + ' ' + transTime;
      approvalHistory.remark = 'MUTATION_IN';

      this.warehouseMutationService.addApprovalHistory(approvalHistory).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/warehouse-mutation-in']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
