import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { PurposeService } from '../purpose.service';
import { MstPurpose } from '../../../models/mst-purpose.model';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-purpose-create',
  templateUrl: './purpose-create.page.html',
  styleUrls: ['./purpose-create.page.scss'],
})
export class PurposeCreatePage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  formPurposeCreate: FormGroup;
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private purposeService: PurposeService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormPurposeCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormPurposeCreate() {
    this.formPurposeCreate = this.fb.group({
      purposeName: [null, Validators.required]
    })
  }

  inputPurpose() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const purposeForm = this.formPurposeCreate.getRawValue();
      const mstPurpose = new MstPurpose();
      mstPurpose.purpose_name = purposeForm.purposeName;
      mstPurpose.status = '1';
      mstPurpose.created_by = this.userProfile.username;

      this.purposeService.addPurpose(mstPurpose).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/purpose']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
