import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

  constructor(private storage: Storage) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    //console.log(request);
    const storage = Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')]
    );
    return from(storage)
      .pipe(
        switchMap(data => {
          if (data[0]) {
            request = request.clone({
              params: request.params.set('token', data[0])
            })
          }

          return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                // do nothing for now
              }
              return event;
            }),
            catchError((error: HttpErrorResponse) => {
              return throwError(error);
            })
          );
        })
      );
  }
}