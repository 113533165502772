export class ProductReceiving {

    id: string;
    product_request_id: string;
    counter_id: number;
    warehouse_id: number;
    doc_date: string;
    receipt_no: string;
    courier: string;
    desc: string;
    status: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.product_request_id = model.product_request_id || null;
        this.counter_id = model.counter_id || 0;
        this.warehouse_id = model.warehouse_id || 0;
        this.doc_date = model.doc_date || null;
        this.receipt_no = model.receipt_no || null;
        this.courier = model.courier || null;
        this.desc = model.desc || null;
        this.status = model.status || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}

export class ProductReceivingDetail {

    product_receiving_id: string[];
    product_id: string[];
    qty: number[];
    unit: string[];
    qty_request: number[];
    descriptions: string[];

    constructor(model?) {
        model = model || {};
        this.product_receiving_id = model.product_receiving_id || [];
        this.product_id = model.product_id || [];
        this.qty = model.qty || [];
        this.unit = model.unit || [];
        this.qty_request = model.qty_request || [];
        this.descriptions = model.descriptions || [];
    }
}