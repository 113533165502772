import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';
import { MstWarehouse } from '../../models/mst-warehouse.model';

@Injectable({
  providedIn: 'root'
})
export class GudangService {

  constructor(private httpService: HttpService) { }

  getWarehouse(params: any): Observable<any> {
    return this.httpService.get('warehouse', params);
  }

  getWarehouseforCreate(params: any): Observable<any> {
    return this.httpService.get('warehouse/create', params);
  }

  getWarehouseforEdit(warehouseId: any, params: any): Observable<any> {
    return this.httpService.get(`warehouse/${warehouseId}/edit`, params);
  }

  addWarehouse(data: MstWarehouse): Observable<any> {
    return this.httpService.post('warehouse', data);
  }

  updateWarehouse(warehouseId: any, data: MstWarehouse): Observable<any> {
    return this.httpService.put(`warehouse/${warehouseId}`, data);
  }

  deleteWarehouse(warehouseId: any): Observable<any> {
    return this.httpService.delete(`warehouse/${warehouseId}`);
  }
}
