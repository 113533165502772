import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ResellerCashbackPage } from './reseller-cashback.page';

const routes: Routes = [
  {
    path: '',
    component: ResellerCashbackPage
  },
  {
    path: 'reseller-cashback-create',
    loadChildren: () => import('./reseller-cashback-create/reseller-cashback-create.module').then( m => m.ResellerCashbackCreatePageModule)
  },
  {
    path: 'reseller-cashback-detail',
    loadChildren: () => import('./reseller-cashback-detail/reseller-cashback-detail.module').then( m => m.ResellerCashbackDetailPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResellerCashbackPageRoutingModule {}
