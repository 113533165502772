import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterRewardPage } from './master-reward.page';

const routes: Routes = [
  {
    path: '',
    component: MasterRewardPage
  },
  {
    path: 'master-reward-create',
    loadChildren: () => import('./master-reward-create/master-reward-create.module').then( m => m.MasterRewardCreatePageModule)
  },
  {
    path: 'master-reward-edit',
    loadChildren: () => import('./master-reward-edit/master-reward-edit.module').then( m => m.MasterRewardEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterRewardPageRoutingModule {}
