import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import {  FormBuilder } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { CounterService } from '../counter.service';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { SearchUserComponent } from '../../../component/search-user/search-user.component';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

class User {
  public id: string;
  public name: string;
}

@Component({
  selector: 'app-counter-edit',
  templateUrl: './counter-edit.page.html',
  styleUrls: ['./counter-edit.page.scss'],
})
export class CounterEditPage implements OnInit {

  counterId: any;
  counterData: any;
  token: any;
  locationList:any[]=[];
  userList:any[]=[];
  statusCounter: any[] = ['Not Active', 'Active'];
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();

  searchValue: any[] = [];
  branchList: any[] = [];
  counterShapeList:any[]=[];
  branchId:any;  
  shapeId:any;
  locationId:any;
  coordinatorId:any='';
  coordinatorName:any='';
  assistantManagerId:any = 0;
  assistantManagerName:any ='';
  spgId:any[]=[];
  spgName:any[]=[];
  jumlahSpg:number=0;
  counterCode:any;
  counterName:any;
  transDate:any;
  stockPeriod:any;
  firstAddress:any;
  lastAddress:any;
  phone:any;
  footerText:any;
  timezone:any;
  activeStatus:any;
  luas:any;
  tipe:any;
  bentuk:any;
  meja_highlight:any;
  meja_promo:any;
  jumlah_backwall:any;
  ops_by:any;
  pic:any;
  sharing_margin:any;
  konsi_or_non:any;
  sistem_kerjasama:any;
  biaya_sewa:any;
  periode_sewa:any;

  selectedBranchs:any ='';
  selectedShapes:any='';
  selectedLocations:any='';
  selectedAssistantManagers:any='';
  selectedCoordinators:any='';

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private counterService: CounterService,
    private userData: UserData,
    private router: Router,
    private modalController: ModalController
  ) { }

  ngOnInit() {

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.counterId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.counterService.getCounterforEdit(this.counterId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.counterData = response.results.data;
          this.branchList = response.results.branch_data;
          this.branchId = this.counterData.branch_id;
          this.shapeId=this.counterData.bentuk;
          this.locationId=this.counterData.location;
          this.counterShapeList = response.results.counter_shape_data;
          this.locationList = response.results.counter_location_data;
          this.userList = response.results.user_data;
          this.coordinatorId=this.counterData.coordinator;
          this.assistantManagerId=this.counterData.assistant_manager;
          this.jumlahSpg=this.counterData.jumlah_spg;
          let daftarSpg=this.counterData.daftar_spg;
          let arraySpg = daftarSpg !== '' ? String(daftarSpg).split(',') : [];
          for(let i=0;i<arraySpg.length;i++){
            let selectedUser: any = this.userList.find(x => x.id ==  arraySpg[i]);
            if(selectedUser){
              let idx =this.userList.indexOf(selectedUser);            
              this.spgName[i]=this.userList[idx].name;          
              this.spgId[i]=this.userList[idx].id;
            }
          }


          let selectedBranch: any = this.branchList.find(x => x.id ==  this.counterData.branch_id);
          if(selectedBranch){
            this.selectedBranchs=selectedBranch;
          } 

          let selectedShape: any = this.counterShapeList.find(x => x.id ==  this.counterData.bentuk);
          if(selectedBranch){
            this.selectedShapes=selectedShape;
          } 

          let selectedLocation: any = this.locationList.find(x => x.id ==  this.counterData.location);
          if(selectedLocation){
            this.selectedLocations=selectedLocation;
          } 
        
          this.assistantManagerId = this.counterData.assistant_manager;
          let selectedAssistantManager: any = this.userList.find(x => x.id ==  this.counterData.assistant_manager);
          if(selectedAssistantManager){
            this.assistantManagerName=selectedAssistantManager.name;
          } 
        
          this.coordinatorId = this.counterData.coordinator;
          let selectedCoordinator: any = this.userList.find(x => x.id ==  this.counterData.coordinator);
          if(selectedCoordinator){
            this.coordinatorName=selectedCoordinator.name;
          } 
          this.branchId=this.counterData.branch_id;
          this.counterCode=this.counterData.counter_code;
          this.counterName=this.counterData.counter_name;
          this.transDate=this.counterData.trans_date;
          this.stockPeriod=this.counterData.stock_period;
          this.firstAddress=this.counterData.first_address;
          this.lastAddress=this.counterData.last_address;
          this.phone=this.counterData.phone;
          this.footerText=this.counterData.footer_text;
          this.timezone=this.counterData.timezone;
          this.activeStatus=parseInt(this.counterData.active);
          this.luas=this.counterData.luas;
          this.tipe=this.counterData.tipe;
          this.bentuk=this.counterData.bentuk;
          this.meja_highlight=this.counterData.meja_highlight;
          this.meja_promo=this.counterData.meja_promo;
          this.jumlah_backwall=this.counterData.jumlah_backwall;
          this.ops_by=this.counterData.ops_by;
          this.pic=this.counterData.pic;
          this.sharing_margin=this.counterData.sharing_margin;
          this.konsi_or_non=this.counterData.konsi_or_non;
          this.sistem_kerjasama=this.counterData.sistem_kerjasama;
          this.biaya_sewa=this.counterData.biaya_sewa;
          this.periode_sewa=this.counterData.periode_sewa;
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let dateConvert = this.utilService.convertDate(this.transDate);
      let newTransDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;
      let dateConvert2 = this.utilService.convertDate(this.stockPeriod);
      let newStockPeriod = dateConvert2.years + '-' + dateConvert2.months + '-' + dateConvert2.dates;
      let daftarSpg = '';
      for(let i=0;i<this.spgId.length;i++){
        if(this.spgId[i]!='0'){
          daftarSpg += this.spgId[i]+',';
        }
      }
      daftarSpg = daftarSpg.replace(/(^,)|(,$)/g, "");
      let detail ={
        'branch_id' :this.branchId,
        'counter_code' :this.counterCode,
        'counter_name' :this.counterName,
        'trans_date' :newTransDate,
        'stock_period' :newStockPeriod,
        'first_address' :this.firstAddress,
        'last_address' :this.lastAddress,
        'phone' :this.phone,
        'footer_text' :this.footerText,
        'timezone' :this.timezone,
        'active' :this.activeStatus,
        'updated_by' :this.userProfile.username,
        'luas' : this.luas,
        'tipe' : this.tipe,
        'bentuk' : this.shapeId,
        'meja_highlight' : this.meja_highlight,
        'meja_promo' : this.meja_promo,
        'jumlah_backwall' : this.jumlah_backwall,
        'ops_by' : this.ops_by,
        'pic' : this.pic,
        'sharing_margin':this.sharing_margin,
        'konsi_or_non':this.konsi_or_non,
        'sistem_kerjasama' : this.sistem_kerjasama,
        'biaya_sewa':this.biaya_sewa,
        'periode_sewa':this.periode_sewa,
        'location':this.locationId,
        'assistant_manager':this.assistantManagerId,
        'coordinator':this.coordinatorId,
        'jumlah_spg':this.jumlahSpg,
        'daftar_spg':daftarSpg
      }

      this.counterService.updateCounter(this.counterId, detail).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/counter']);;
          }
        }
      ]
    });

    await alert.present();
  }

  selectBranch(event){
    this.branchId = event.value.id;
  }

  selectShape(event){
    this.shapeId = event.value.id;
  }

  selectLocation(event){
    this.locationId = event.value.id;
  }
  
  searchUser(event: {
    component: IonicSelectableComponent,
    text: string
  }, index: any) {
    let text: string;

    if(this.searchValue.length > 0) {
      text = this.searchValue[index] ? this.searchValue[index] : '';
    }

    text = event.text !== '' ? event.text.trim().toLowerCase() : '';
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterProducts(this.userList, text);
    this.searchValue[index] = text;
    event.component.endSearch();
  }

  filterProducts(products: User[], text: string) {
    return products.filter(product => {
      return product.name.toLowerCase().indexOf(text) !== -1 ||
        product.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  createSpg(){
    this.spgId=[];
    for(let i=0;i< (this.jumlahSpg);i++){
      this.spgId.push(0);
      this.spgName.push('');
    }
  }
   
  async openSearchUser(type:any,no:number) {
    const modal = await this.modalController.create({
      component: SearchUserComponent,
      componentProps: {
        "counterId": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
        'token': this.token,
        'listUser':this.userList
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      console.log(modelData);
      if (modelData !== null && modelData.data!= undefined) {
        if(type=='co'){
          this.coordinatorId = (modelData.data.userId);
          this.coordinatorName = (modelData.data.name);
        }
        else if(type=='am'){
          this.assistantManagerId = (modelData.data.userId);
          this.assistantManagerName = (modelData.data.name);
        }
        else if(type=='spg'){
          this.spgId[no]=(modelData.data.userId);
          this.spgName[no]=(modelData.data.name);
        }
      }
    });

    return await modal.present();
  }

  removeCoordinator(){
    this.coordinatorId = 0;
    this.coordinatorName ='';
  }
  
  removeAssistantManager(){
    this.assistantManagerId = 0;
    this.assistantManagerName ='';
  }
  removeSpg(i:number){
    this.spgId[i] = 0;
    this.spgName[i] ='';
  }
}
