import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ComplimentComponent } from './component/compliment/compliment.component';
import { DownPaymentComponent } from './component/down-payment/down-payment.component';
import { MemberPointComponent } from './component/member-point/member-point.component';
import { VoucherComponent } from './component/voucher/voucher.component';
import { CashierComponent } from './component/advanced-filter/cashier/cashier.component';
import { ReportCashierComponent } from './component/advanced-filter/report-cashier/report-cashier.component';
import { ProductRequestComponent } from './component/advanced-filter/product-request/product-request.component';
import { AfProductReceivingComponent } from './component/advanced-filter/af-product-receiving/af-product-receiving.component';
import { ProductReceivingComponent } from './component/product-receiving/product-receiving.component';
import { ReportStockComponent } from './component/report-stock/report-stock.component';
import { PriceGroupComponent } from './component/price-group/price-group.component';
import { MemberResellerComponent } from './component/member-reseller/member-reseller.component';
import { RedemPointComponent } from './component/redem-point/redem-point.component';
import { PromotionDetailComponent } from './component/promotion-detail/promotion-detail.component';
import { PromotionFreeItemComponent } from './component/promotion-free-item/promotion-free-item.component';
import { MasterProductComponent } from './component/advanced-filter/master-product/master-product.component';
import { ProductSalesComponent } from './component/product-sales/product-sales.component';
import { ReportStockAuditrailComponent } from './component/advanced-filter/report-stock-auditrail/report-stock-auditrail.component';
import { ReportProductRequestComponent } from './component/advanced-filter/report-product-request/report-product-request.component';
import { ReportProductRequestDetailComponent } from './component/report-product-request-detail/report-product-request-detail.component';
import { ReportCashierBranchListComponent } from './component/report-cashier-branch-list/report-cashier-branch-list.component';
import { ReportCashierCounterListComponent } from './component/report-cashier-counter-list/report-cashier-counter-list.component';
import { ReportProductReceivingComponent } from './component/advanced-filter/report-product-receiving/report-product-receiving.component';
import { ReportProductReceivingDetailComponent } from './component/report-product-receiving-detail/report-product-receiving-detail.component';
import { FindProductComponent } from './component/find-product/find-product.component';
import { AfPriceGroupComponent } from './component/advanced-filter/af-price-group/af-price-group.component';
import { SearchProductComponent } from './component/search-product/search-product.component';
import { RequestVoidComponent } from './component/advanced-filter/request-void/request-void.component';

import { IonicSelectableModule } from 'ionic-selectable';

import { Network } from '@ionic-native/network/ngx';

import { AutoCompleteModule } from 'ionic4-auto-complete';

import { CoreModule } from './core/core.module';
import { SearchProductPriceGroupComponent } from './component/search-product-price-group/search-product-price-group.component';
import { AddProductPriceGroupComponent } from './component/add-product-price-group/add-product-price-group.component';
import { MemberResellerClaimInsentifComponent } from './component/member-reseller-claim-insentif/member-reseller-claim-insentif.component';

import { ReportOtherExpencesComponent } from './component/advanced-filter/report-other-expences/report-other-expences.component';
import { ReportOtherExpencesDetailComponent } from './component/report-other-expences-detail/report-other-expences-detail.component';
import { WarehouseListComponent } from './component/warehouse-list/warehouse-list.component';

import {ProductSalesFreeItemComponent} from './component/product-sales-free-item/product-sales-free-item.component';
import { ReportOtherReceivingComponent } from './component/advanced-filter/report-other-receiving/report-other-receiving.component';
import { ReportOtherReceivingDetailComponent } from './component/report-other-receiving-detail/report-other-receiving-detail.component';
import { ReportPointRegularComponent } from './component/report-point-regular/report-point-regular.component';
import { SetupApprovalComponent } from './component/setup-approval/setup-approval.component';
import { SetupPicComponent } from './component/setup-pic/setup-pic.component';
import { SalesTargetComponent } from  './component/sales-target/sales-target.component';
import { AfSalesTargetComponent } from  './component/advanced-filter/af-sales-target/af-sales-target.component';
import { AfProductPerCounterComponent} from './component/advanced-filter/af-product-per-counter/af-product-per-counter.component';
import { ReportSummaryComponent } from './component/report-summary/report-summary.component';
import { ReportSummaryStockComponent } from './component/report-summary-stock/report-summary-stock.component';
import { ReportCounterComponent } from './component/report-counter/report-counter.component';
import { SearchUserComponent } from './component/search-user/search-user.component';
import {ResellerTransactionListComponent} from './component/reseller-transaction-list/reseller-transaction-list.component';
import { ReportRekapPromoComponent } from './component/advanced-filter/report-rekap-promo/report-rekap-promo.component';
import { UploadPromoComponent } from './component/upload-promo/upload-promo.component';
import { ProductPackageComponent } from './component/advanced-filter/product-package/product-package.component';
import { ProductComponent } from './component/advanced-filter/product/product.component';
import { ShowMoreComponent } from './component/show-more/show-more.component';
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot({
      mode:'md'
    }),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    NgxDatatableModule,
    IonicSelectableModule,
    AutoCompleteModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [
    ComplimentComponent,
    DownPaymentComponent,
    MemberPointComponent,
    VoucherComponent,
    CashierComponent,
    ReportCashierComponent,
    ProductRequestComponent,
    ProductReceivingComponent,
    AfProductReceivingComponent,
    ReportStockComponent,
    PriceGroupComponent,
    MemberResellerComponent,
    RedemPointComponent,
    PromotionDetailComponent,
    PromotionFreeItemComponent,
    MasterProductComponent,
    ProductSalesComponent,
    ReportStockAuditrailComponent,
    ReportProductRequestComponent,
    ReportProductRequestDetailComponent,
    ReportCashierBranchListComponent,
    ReportCashierCounterListComponent,
    ReportProductReceivingComponent,
    ReportProductReceivingDetailComponent,
    ReportOtherExpencesComponent,
    ReportOtherExpencesDetailComponent,
    ReportOtherReceivingComponent,
    FindProductComponent,
    AfPriceGroupComponent,
    SearchProductComponent,
    SearchProductPriceGroupComponent,
    AddProductPriceGroupComponent,
    MemberResellerClaimInsentifComponent,
    RequestVoidComponent,
    WarehouseListComponent,
    ProductSalesFreeItemComponent,
    ReportOtherReceivingDetailComponent,
    ReportPointRegularComponent,
    SetupApprovalComponent,
    SetupPicComponent,
    SalesTargetComponent,
    AfSalesTargetComponent,
    AfProductPerCounterComponent,
    ReportSummaryComponent,
    ReportSummaryStockComponent,
    ReportCounterComponent,
    SearchUserComponent,
    ResellerTransactionListComponent,
    ReportRekapPromoComponent,
    UploadPromoComponent,
    ProductPackageComponent,
    ProductComponent,
    ShowMoreComponent
  ],
  declarations: [
    AppComponent,
    ComplimentComponent,
    DownPaymentComponent,
    MemberPointComponent,
    VoucherComponent,
    CashierComponent,
    ReportCashierComponent,
    ProductRequestComponent,
    ProductReceivingComponent,
    AfProductReceivingComponent,
    ReportStockComponent,
    PriceGroupComponent,
    MemberResellerComponent,
    RedemPointComponent,
    PromotionDetailComponent,
    PromotionFreeItemComponent,
    MasterProductComponent,
    ProductSalesComponent,
    ReportStockAuditrailComponent,
    ReportProductRequestComponent,
    ReportProductRequestDetailComponent,
    ReportCashierBranchListComponent,
    ReportCashierCounterListComponent,
    ReportProductReceivingComponent,
    ReportProductReceivingDetailComponent,
    ReportOtherExpencesComponent,
    ReportOtherExpencesDetailComponent,
    ReportOtherReceivingComponent,
    FindProductComponent,
    AfPriceGroupComponent,
    SearchProductComponent,
    SearchProductPriceGroupComponent,
    AddProductPriceGroupComponent,
    MemberResellerClaimInsentifComponent,
    RequestVoidComponent,
    WarehouseListComponent,
    ProductSalesFreeItemComponent,
    ReportOtherReceivingDetailComponent,
    ReportPointRegularComponent,
    SetupApprovalComponent,
    SetupPicComponent,
    SalesTargetComponent,
    AfSalesTargetComponent,
    AfProductPerCounterComponent,
    ReportSummaryComponent,
    ReportSummaryStockComponent,
    ReportCounterComponent,
    SearchUserComponent,
    ResellerTransactionListComponent,
    ReportRekapPromoComponent,
    UploadPromoComponent,
    ProductPackageComponent,
    ProductComponent,
    ShowMoreComponent
  ],
  providers: [InAppBrowser, SplashScreen, StatusBar, Network],
  bootstrap: [AppComponent]
})
export class AppModule {}
