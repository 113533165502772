import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private httpService: HttpService) { }

  getDashboardStockData(params: any): Observable<any> {
    return this.httpService.get('stock/dashboard-stock', params);
  }
}
