import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { environment } from '../../../../environments/environment';
import { Storage } from '@ionic/storage';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.page.html',
  styleUrls: ['./customer-edit.page.scss'],
})
export class CustomerEditPage implements OnInit {

  baseUrl: any;
  formCustomerEdit: FormGroup;
  customerId: any;
  customerData: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private http: HttpClient,
    private storage: Storage,
    private userData: UserData,
    private router: Router
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
    this.buildFormCustomerEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
        this.getData();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormCustomerEdit() {
    this.formCustomerEdit = this.fb.group({
      customerName: [],
      identityNumber: [],
      phone: []
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.customerId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.http.get(this.baseUrl + 'customer/' + this.customerId + '/edit?token=' + this.token)
        .subscribe((response: any) => {
          this.utilService.loadingDismiss();
          this.customerData = response.results.data;

          this.formCustomerEdit = this.fb.group({
            customerName: [this.customerData.customer_name, Validators.required],
            identityNumber: [this.customerData.identity_number, Validators.required],
            phone: [this.customerData.phone, Validators.required]
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const customerData = this.formCustomerEdit.getRawValue();

      const paramsOpts = new HttpParams()
        .set('token', this.token)

      const reqOpts = new HttpHeaders()
        .set('Accept', 'application/json'),
      formData = new FormData();
      formData.append('customer_name', customerData.customerName);
      formData.append('identity_number', customerData.identityNumber);
      formData.append('phone', customerData.phone);
      formData.append('updated_by', this.userProfile.name);
      formData.append('_method', 'PUT');

      this.http.post(this.baseUrl + 'customer/' + this.customerId, formData, { headers: reqOpts, params: paramsOpts })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/customer']);;
          }
        }
      ]
    });

    await alert.present();
  }

}