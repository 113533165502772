import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { PriceGroupService } from './price-group.service';
import { AfPriceGroupComponent } from '../../component/advanced-filter/af-price-group/af-price-group.component';
import { RoleAccess } from '../../models/role-access.model';
import { HttpClient } from '@angular/common/http';
import { PriceGroupApprovalStatus } from '../../enum/PriceGroupApprovalStatus';
import { environment } from '../../../environments/environment';
import AES = require('crypto-js/aes');


@Component({
  selector: 'app-price-group',
  templateUrl: './price-group.page.html',
  styleUrls: ['./price-group.page.scss'],
})
export class PriceGroupPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  token: any;
  userProfile: UserProfile = new UserProfile();
  modelData: any;
  rows: any;
  priceGroupList: any;
  filteredData: any;
  counterId: any='';
  counterList: any[] = [];
  keywordSearch: any;
  
  roleAccess = new RoleAccess();
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private alertController : AlertController,
    private userData: UserData,
    private modalController: ModalController,
    private priceGroupService: PriceGroupService,
    private router: Router,
    private httpClient:HttpClient
  ) { }

  ngOnInit() {
  }
  approvalStatusName = PriceGroupApprovalStatus;
  approvalStatusList = Object.keys(PriceGroupApprovalStatus).filter(
    approvalStatusName => typeof this.approvalStatusName[approvalStatusName] === 'number'
  );
  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.pageCallback({ offset: this.page.offset });
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords" :this.keywordSearch ? this.keywordSearch : 'null',
        "counter_id": this.counterId !== '' ? this.counterId : this.userProfile.counter_id_list
      };

      this.priceGroupService.getPriceGroup(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        //this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.priceGroupList = response.results.data;

        if(this.counterList.length === 0) {
          this.getCounterList();
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  getCounterList() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };
      this.priceGroupService.getPriceGroupforCreate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.counterList = response.results.mst_counter_data;
        }/*
      this.priceGroupService.getCounter({ "token": this.token }).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.counterList = response.results;*/
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Counter' }).then(t => t.present());
      });
    });
  }

  goToPriceGroupCreate() {
    this.navCtrl.navigateForward(['/price-group/price-group-create']);
  }

  goToUpdatePage(id: any,formType:any) {
    let myKey =environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID,
        t: formType
      }
    };
    this.navCtrl.navigateForward(['/price-group/price-group-edit'], navigationExtras);
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 5;
    let keys = Object.keys(this.priceGroupList[0]);
    this.rows = this.filteredData.filter(function(item){
      for (let i=0; i < columnLength; i++){
        if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });

    this.page.offset = 0;
  }

  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: AfPriceGroupComponent,
      componentProps: {
        counterList: this.counterList
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData.data !== undefined) {
        this.counterId = modelData.data;
        this.reloadTable();
      } else {
        this.counterId = '';
        this.reloadTable();
      }
    });

    return await modal.present();
  }
  
  async showConfirmSend(id: any) {
    const alert = await this.alertController.create({
      header: 'Send Approval Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk send approval data ini?',
      buttons: [
        {
          text: 'Tidak',
          handler: () => {}
        },
        {
          text: 'Ya',
          handler: () => {
            this.sendApproval(id);
          }
        }
      ]
    });

    await alert.present();
  }
  
  clearFilters() {
    this.counterId = '';
    this.pageCallback({ offset: this.page.offset });
  }

  sendApproval(id: any) {
    let options = {
      "token": this.token,
      "id": id,
      "updated_by": this.userProfile.username,
      "action":'3'
    };
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.priceGroupService.sendApproval(options).subscribe((response) => {
        console.log(response);
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          console.log('send approval');
          console.log(response);
          this.reloadTable();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

}
