import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CashierPage } from './cashier.page';

const routes: Routes = [
  {
    path: '',
    component: CashierPage
  },
  {
    path: 'cashier-create',
    loadChildren: () => import('./cashier-create/cashier-create.module').then( m => m.CashierCreatePageModule)
  },
  {
    path: 'cashier-edit',
    loadChildren: () => import('./cashier-edit/cashier-edit.module').then( m => m.CashierEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CashierPageRoutingModule {}
