export class PettyCash {

    id: string;
    counter_id: number;
    petty_cash_category_id: number;
    doc_date: string;
    type: string;
    amount: number;
    desc: string;
    status: string;
    created_by: string;
    updated_by: string;
    

    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.counter_id = model.counter_id || 0;
        this.petty_cash_category_id = model.petty_cash_category_id || 0;
        this.doc_date = model.doc_date || null;
        this.type = model.type || null;
        this.amount = model.amount || 0;
        this.desc = model.desc || null;
        this.status = model.status || null;
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}