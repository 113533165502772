import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { WarehouseMutationOutService } from '../warehouse-mutation-out.service';
import { WarehouseMutationBundle } from '../../../models/warehouse-mutation-bundle.model';
import { forkJoin } from 'rxjs';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-warehouse-mutation-out-create',
  templateUrl: './warehouse-mutation-out-create.page.html',
  styleUrls: ['./warehouse-mutation-out-create.page.scss'],
})
export class WarehouseMutationOutCreatePage implements OnInit {

  token: any;
  productSearch: Product[];
  productList: Product[];
  userProfile: UserProfile = new UserProfile();
  warehouseData: any[] = [];
  fromCounterList: any[] = [];
  fromWarehouseList: any[] = [];
  toCounterList: any[] = [];
  toWarehouseList: any[] = [];
  mutationTypeList: any[] = [];

  formWarehouseMutationCreate: FormGroup;
  warehouseMutationId: any;

  db: any;

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private warehouseMutationService: WarehouseMutationOutService
  ) { }

  ngOnInit() {
    this.buildFormWarehouseMutationCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.getData();
        /*this.openDB();
        this.getFromCounterList();*/
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      forkJoin([
        this.warehouseMutationService.getProduct({ "token": this.token }),
        this.warehouseMutationService.getWarehouseMutationforCreate({ "token": this.token, "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list })
      ])
      .subscribe(([product, create]) => {
        this.utilService.loadingDismiss();
        if (product.status.code === 200) {
          this.productList = product.results;
        }

        if (create.status.code === 200) {
          this.mutationTypeList = create.results;
          this.toCounterList = create.counter_data;
          this.fromCounterList = create.counter_data;
          this.warehouseData = create.warehouse_data;
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  buildFormWarehouseMutationCreate() {
    this.formWarehouseMutationCreate = this.fb.group({
      fromCounterId: [null, Validators.required],
      fromWarehouseId: [null, Validators.required],
      toCounterId: [null, Validators.required],
      toWarehouseId: [null, Validators.required],
      mutationTypeId: [null, Validators.required],
      description: [null, Validators.required],
      detail: this.fb.array([])
    });
  }

  addDetail() {
    const detail = this.fb.group({
      product: [null, Validators.required],
      qty: [0, Validators.required],
      descDetail: [null]
    });
    this.getDetailArray.push(detail);
  }

  get getDetailArray() {
    return (<FormArray>this.formWarehouseMutationCreate.get('detail'));
  }

  filterProducts(products: Product[], text: string) {
    return products.filter(product => {
      return product.product_name.toLowerCase().indexOf(text) !== -1 ||
        product.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  searchProducts(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterProducts(this.productList, text);
    event.component.endSearch();
  }

  deleteDetail(i: any) {
    this.getDetailArray.removeAt(i);
  }

  getFromCounterList() {
    let selectQuery = 'SELECT * FROM mst_counters';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.fromCounterList = [];
            this.toCounterList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let counterData = {
                'id': result.rows[x].id,
                'counter_name': result.rows[x].counter_name
              };
              this.fromCounterList.push(counterData);
              this.toCounterList.push(counterData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getWarehouseList(event, action) {
    let counterId = event.target.value;
    if(this.warehouseData.length > 0) {
      if(action === 'from') { this.fromWarehouseList = []; }
      if(action === 'to') { this.toWarehouseList = []; }

      for(let x = 0; x < this.warehouseData.length; x++) {
        if(this.warehouseData[x].counter_id === counterId) {
          if(action === 'from') { this.fromWarehouseList.push(this.warehouseData[x]); }
          if(action === 'to') { this.toWarehouseList.push(this.warehouseData[x]); }
        }
      }

      this.refreshForm(action);
    } else {
      this.toastCtrl.create({ duration: 2000, message: 'Data Warehouse kosong!' }).then(t => t.present());
    }
  }

  refreshForm(action: any) {
    const formWarehouseMutation = this.formWarehouseMutationCreate.value;
    let detailArray: any[] = formWarehouseMutation.detail;
    this.formWarehouseMutationCreate = this.fb.group({
      fromCounterId: [formWarehouseMutation.fromCounterId, Validators.required],
      fromWarehouseId: action === 'from' ? [null, Validators.required] : [formWarehouseMutation.fromWarehouseId, Validators.required],
      toCounterId: [formWarehouseMutation.toCounterId],
      toWarehouseId: action === 'to' ? [null, Validators.required] : [formWarehouseMutation.toWarehouseId, Validators.required],
      mutationTypeId: [formWarehouseMutation.mutationTypeId, Validators.required],
      description: [formWarehouseMutation.description, Validators.required],
      detail: this.fb.array([])
    });
    
    if(detailArray.length > 0) {
      for(let i = 0; i < detailArray.length; i++) {
        let productId: string = detailArray[i].product.id.trim().toLowerCase();
        let filterProduct = this.filterProducts(this.productList, productId);
        const detail = this.fb.group({
          product: [filterProduct[0]],
          qty: [detailArray[i].qty],
          descDetail: [detailArray[i].descDetail]
        });
        this.getDetailArray.push(detail);
      }
    }
  }

  inputData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {

      const formWarehouseMutation = this.formWarehouseMutationCreate.value;
      let fromCounterId = formWarehouseMutation.fromCounterId;
      let fromWarehouseId = formWarehouseMutation.fromWarehouseId;
      let toCounterId = formWarehouseMutation.toCounterId;
      let toWarehouseId = formWarehouseMutation.toWarehouseId;
      let mutationTypeId = formWarehouseMutation.mutationTypeId;
      let description = formWarehouseMutation.description;
      
      let arrProduct: any = [];
      let arrQty: any = [];
      let arrDescDetail: any = [];

      for(let x = 0; x < formWarehouseMutation.detail.length; x++) {
        arrProduct[x] = formWarehouseMutation.detail[x].product.id;
        arrQty[x] = parseInt(formWarehouseMutation.detail[x].qty);
        arrDescDetail[x] = formWarehouseMutation.detail[x].descDetail;
      }

      const warehouseMutationBundle = new WarehouseMutationBundle();
      warehouseMutationBundle.warehouseMutation.from_counter_id = fromCounterId;
      warehouseMutationBundle.warehouseMutation.from_warehouse_id = fromWarehouseId;
      warehouseMutationBundle.warehouseMutation.to_counter_id = toCounterId;
      warehouseMutationBundle.warehouseMutation.to_warehouse_id = toWarehouseId;
      warehouseMutationBundle.warehouseMutation.warehouse_mutation_type_id = mutationTypeId;
      warehouseMutationBundle.warehouseMutation.desc = description;
      warehouseMutationBundle.warehouseMutation.status = '0';
      warehouseMutationBundle.warehouseMutation.receive_status = '0';
      warehouseMutationBundle.warehouseMutation.created_by = this.userProfile.username;

      warehouseMutationBundle.warehouseMutationDetail.product_id = arrProduct;
      warehouseMutationBundle.warehouseMutationDetail.qty = arrQty;
      warehouseMutationBundle.warehouseMutationDetail.desc_detail = arrDescDetail;

      this.warehouseMutationService.addWarehouseMutation(warehouseMutationBundle).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/warehouse-mutation-out']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
