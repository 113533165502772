import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-petty-cash',
  templateUrl: './report-petty-cash.page.html',
  styleUrls: ['./report-petty-cash.page.scss'],
})
export class ReportPettyCashPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
