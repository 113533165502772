import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MasterProductDetailPageRoutingModule } from './master-product-detail-routing.module';

import { MasterProductDetailPage } from './master-product-detail.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MasterProductDetailPageRoutingModule
  ],
  declarations: [MasterProductDetailPage]
})
export class MasterProductDetailPageModule {}
