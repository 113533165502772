import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProductBrandPageRoutingModule } from './product-brand-routing.module';

import { ProductBrandPage } from './product-brand.page';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ProductBrandPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [ProductBrandPage],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ProductBrandPageModule {}
