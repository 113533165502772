import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { ChangePasswordService } from './change-password.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss'],
})
export class ChangePasswordPage implements OnInit {

  token: any;
  baseUrl: any;
  formChangePassword: FormGroup;
  userProfile: UserProfile = new UserProfile();
  oldPassword: any;
  isUserKpi:boolean=true;
  showOldPassword:boolean;
  showNewPassword:boolean;
  showConfirmNewPassword:boolean;

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private changePasswordService: ChangePasswordService,
    private http: HttpClient) { 
      this.baseUrl = environment.apiUrl;
    }

  ngOnInit() {
    this.buildFormChangePassword();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_password')
    ])
    .then(([token, profile, password]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        if(this.userProfile.is_user_kpi=='N'){
          this.isUserKpi=false;
        }else{
          this.toastCtrl.create({ duration: 2000, message: 'Silahkan ganti password melalui aplikasi KPI' }).then(t => t.present());
        }
        this.oldPassword = password;
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  ionViewDidEnter() {
    this.showOldPassword=false;
    this.showNewPassword=false;
    this.showConfirmNewPassword=false;
  }
  buildFormChangePassword() {
    this.formChangePassword = this.fb.group({
      oldPassword: [null, Validators.required],
      newPassword: [null, Validators.compose([
        Validators.minLength(6),
        Validators.required
     ])],
      confirmationNewPassword: [null, Validators.compose([
        Validators.minLength(6),
        Validators.required
     ])]
    });
  }

  savePassword() {
    const changePasswordForm = this.formChangePassword.getRawValue();
    if(changePasswordForm.oldPassword === this.oldPassword) {
      if(changePasswordForm.newPassword === changePasswordForm.confirmationNewPassword) {
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          let userId: any = this.userProfile.id;
          const reqOpts = new HttpHeaders()
            .set('Accept', 'application/json'),
          formData = new FormData();
          formData.append('user_id', userId);
          formData.append('old_password', changePasswordForm.oldPassword);
          formData.append('new_password', changePasswordForm.newPassword);
          formData.append('updated_by', this.userProfile.username);
          this.http.post(this.baseUrl + 'change-password', formData, { headers: reqOpts })
          .subscribe((response: any) => {
            this.utilService.loadingDismiss();
            if(response.status.code === 201) {
              this.storage.set('user_password', changePasswordForm.newPassword);
              this.showConfirmInput();
            } else {
              this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
            }
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          })
        });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Pengisian password baru dengan konfirmasi password baru tidak cocok!' }).then(t => t.present());
      }
    } else {
      this.toastCtrl.create({ duration: 2000, message: 'Password lama salah!' }).then(t => t.present());
    }
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Password berhasil diubah!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.buildFormChangePassword();
          }
        }
      ]
    });

    await alert.present();
  }

  toggleOldPasswordVisibility() {
    this.showOldPassword = !this.showOldPassword;
  }
  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }
  toggleConfirmPasswordVisibility() {
    this.showConfirmNewPassword = !this.showConfirmNewPassword;
  }
}
