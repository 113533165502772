import { Injectable } from '@angular/core';

import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MasterProductCounterService {

  constructor(
    private httpService :HttpService
  ) { }
  
  getProducts(params: any): Observable<any> {
    return this.httpService.get('product-per-counter', params);
  }
  
  getProductForEdit(PCode: any, params: any): Observable<any> {
    return this.httpService.get(`product-per-counter/${PCode}/edit`, params);
  }
  pullProductforCounter(params:any):Observable<any>{
    return this.httpService.get(`pull-product-for-counter`,params);
  }

  updateProduct(PCode: any, params: any): Observable<any> {
    return this.httpService.put(`product-per-counter/${PCode}`, params);
  }
  
  uploadFile(data: any): Observable<any> {
    return this.httpService.post('upload-product-per-counter', data);
  }

  getCounterList(params: any): Observable<any> {
    return this.httpService.get('counter-list-per-user', params);
  }

  countProductBeforeLoad(params: any): Observable<any> {
    return this.httpService.get('count-product', params);
  }
}
