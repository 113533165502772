import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductReceivingPage } from './product-receiving.page';

const routes: Routes = [
  {
    path: '',
    component: ProductReceivingPage
  },
  {
    path: 'product-receiving-create',
    loadChildren: () => import('./product-receiving-create/product-receiving-create.module').then( m => m.ProductReceivingCreatePageModule)
  },
  {
    path: 'product-receiving-detail',
    loadChildren: () => import('./product-receiving-detail/product-receiving-detail.module').then( m => m.ProductReceivingDetailPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductReceivingPageRoutingModule {}
