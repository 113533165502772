import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-report-product-receiving-detail',
  templateUrl: './report-product-receiving-detail.component.html',
  styleUrls: ['./report-product-receiving-detail.component.scss'],
})
export class ReportProductReceivingDetailComponent implements OnInit {

  @Input() reportDataDetail: any[];

  productReceivingId: any;
  productRequestId: any;
  counterName: any;
  warehouseName: any;
  docDate: any;
  courier: any;
  statusName: any;
  description: any;

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() {
    if(this.reportDataDetail.length > 0) {
      this.productReceivingId = this.reportDataDetail[0].product_receiving_id;
      this.productRequestId = this.reportDataDetail[0].product_request_id;
      this.counterName = this.reportDataDetail[0].counter_name;
      this.warehouseName = this.reportDataDetail[0].warehouse_name;
      this.docDate = this.reportDataDetail[0].doc_date;
      this.courier = this.reportDataDetail[0].courier;
      this.statusName = this.reportDataDetail[0].status === '0' ? 'OPEN' : this.reportDataDetail[0].status === '1' ? 'CLOSED' : this.reportDataDetail[0].status === '2' ? 'VOID' : '-';
      this.description = this.reportDataDetail[0].desc;
    }
  }

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }

}
