import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReportOtherExpencesPageRoutingModule } from './report-other-expences-routing.module';

import { ReportOtherExpencesPage } from './report-other-expences.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReportOtherExpencesPageRoutingModule
  ],
  declarations: [ReportOtherExpencesPage]
})
export class ReportOtherExpencesPageModule {}
