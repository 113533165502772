
import { Component, OnInit, ViewChild } from '@angular/core';

import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';
import { NavController, ToastController } from '@ionic/angular';
import { UserData } from '../../providers/user-data';
import { Router } from '@angular/router';
import { IonicSelectableComponent } from 'ionic-selectable';
@Component({
  selector: 'app-select-cashier',
  templateUrl: './select-cashier.page.html',
  styleUrls: ['./select-cashier.page.scss'],
})
export class SelectCashierPage implements OnInit {

  token: any;
  userProfile: UserProfile= new UserProfile();
  cashierList:any[]=[];
  counterList:any[]=[];
  cashier:any='';
  selectedCashier:any=[];
@ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private userData: UserData,
    private router: Router) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_counter')
    ])
    .then(async ([token, profile,counter]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.cashier = this.userProfile.cashier_id;
        this.cashierList=this.userProfile.cashier_id_list;
        this.selectedCashier =[];
        console.log(this.cashierList);
        if(this.cashierList!=null){
          this.counterList =counter;
          let selectedCounter: any = this.cashierList.find(x => x.id ==  this.userProfile.cashier_id);
          if(selectedCounter){
            let idx =this.cashierList.indexOf(selectedCounter);
            this.selectedCashier=(this.cashierList[idx]);
          }
          console.log('sini');
          console.log(this.counterList);
          window.dispatchEvent(new CustomEvent('user:getmenu'));
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  selectCashier(event){
    this.cashier = event.value.id;
    this.userProfile.cashier_id =this.cashier;
    let cashierPOS = this.cashierList.find(x=>x.id==this.cashier);
    let counterId =0;
    if(cashierPOS)
    {
      counterId = cashierPOS.counter_id
    }
    this.userProfile.counter_id_pos =counterId;
    console.log(this.counterList);
    let counterPOS = this.counterList.find(x=>x.id==counterId);
    if(counterPOS){
      this.userProfile.counter_detail =counterPOS;
    }
    this.storage.set('user_profile', this.userProfile);
  }
  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/login');
    });
  }
}