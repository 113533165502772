import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { OtherExpences, OtherExpencesDetail } from '../../models/other-expences.model';
import { ApprovalHistory } from '../../models/approval-history.model';
import { StockMutation } from '../../models/stock-mutation.model';
import { OtherExpencesBundle } from '../../models/other-expences-bundle.model';
import { OtherExpencesClosedBundle } from '../../models/other-expences-cl-bundle.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtherExpencesService {

  constructor(private httpService: HttpService) { }

  getOtherExpences(params: any): Observable<any> {
    return this.httpService.get('other-expences', params);
  }

  getLocalData(name: any, params: any): Observable<any> {
    let apiUrl: any;
    if(name === 'counter') { apiUrl = 'counter-all'; }
    if(name === 'warehouse') { apiUrl = 'warehouse-all'; }
    if(name === 'purpose') { apiUrl = 'purpose-all'; }

    return this.httpService.get(apiUrl, params);
  }

  getOtherExpencesforCreate(params: any): Observable<any> {
    return this.httpService.get('other-expences/create', params);
  }

  getOtherExpencesforEdit(otherExpencesId: any, params: any): Observable<any> {
    return this.httpService.get(`other-expences/${otherExpencesId}/edit`, params);
  }

  getProduct(params: any): Observable<any> {
    return this.httpService.get('product-all', params);
  }

  addOtherExepences(data: OtherExpences): Observable<any> {
    return this.httpService.post('other-expences', data);
  }
  
  addOtherExepencesDetail(data: OtherExpencesDetail): Observable<any> {
    return this.httpService.post('other-expences-details', data);
  }

  addApprovalHistory(data: ApprovalHistory): Observable<any> {
    return this.httpService.post('approval-history', data);
  }

  addStockMutation(data: StockMutation): Observable<any> {
    return this.httpService.post('stock-mutation/store', data);
  }

  addOtherExpencesBundle(data: OtherExpencesBundle): Observable<any> {
    return this.httpService.post('other-expences-bundle', data);
  }

  updateOtherExpencesBundle(otherExpencesId: any, data: OtherExpencesClosedBundle): Observable<any> {
    return this.httpService.put(`update-other-expences-bundle/${otherExpencesId}`, data);
  }

  updateOtherExepences(otherExpencesId: any, data: OtherExpences): Observable<any> {
    return this.httpService.put(`other-expences/${otherExpencesId}`, data);
  }

  updateOtherExepencesDetail(otherExpencesId: any, data: OtherExpencesDetail): Observable<any> {
    return this.httpService.put(`other-expences-details/${otherExpencesId}`, data);
  }

  manageStock(params: any): Observable<any> {
    return this.httpService.get('stock/manage-stock', params);
  }
}
