import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Storage } from '@ionic/storage';
import { MasterProductPacketService } from '../master-product-packet.service';
import { MstProductPackageBundle } from '../../../models/mst-product-package-bundle.model';
import { RoleAccess } from '../../../models/role-access.model';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { MyService } from '../../../service/my.service';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-master-product-packet-create',
  templateUrl: './master-product-packet-create.page.html',
  styleUrls: ['./master-product-packet-create.page.scss'],
})
export class MasterProductPacketCreatePage implements OnInit {

  token: any;
  productSearch: Product[];
  productList: any[] =[];
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  brandList: any[] = [];
  subBrandListMaster: any[] = [];
  subBrandList: any[] = [];
  typeList: any[] = [];
  subTypeListMaster: any[] = [];
  subTypeList: any[] = [];
  categoryList: any[] = [];
  subCategoryListMaster: any[] = [];
  subCategoryList: any[] = [];
  marketingTypeList: any[] = [];
  price:number=0;
  showPrice:string='';
  formProductPackageCreate: FormGroup;
  roleAccess = new RoleAccess();

  db: any;
  counterId:any[]=[];
  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private packageService: MasterProductPacketService,
    private userData: UserData,
    private router: Router,
    private myService :MyService
  ) { }

  ngOnInit() {
    this.buildFormProductPackage();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getProductPackageforCreate();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormProductPackage() {
    this.formProductPackageCreate = this.fb.group({
      counterId: [null, Validators.required],
      productPackageName: [null, Validators.required],
      productPackageInitialName: [null, Validators.required],
      brandId: [null, Validators.required],
      subBrandId: [null, Validators.required],
      typeId: [null, Validators.required],
      subTypeId: [null, Validators.required],
      categoryId: [null, Validators.required],
      subCategoryId: [null],
      marketingTypeId: [null, Validators.required],
      barcode: [0, Validators.required],
      price: [0, Validators.required],
      length: [0, Validators.required],
      width: [0, Validators.required],
      height: [0, Validators.required],
      allocationQty: [0],
      detail: this.fb.array([])
    });
  }

  addDetail() {
    const detail = this.fb.group({
      product: [null, Validators.required],
      price: [0, Validators.required],
      qty: [0, Validators.required]
    });
    this.getDetailArray.push(detail);
  }

  get getDetailArray() {
    return (<FormArray>this.formProductPackageCreate.get('detail'));
  }

  filterProducts(products: any[], text: string) {
    let filterProduct =[];
    for(let i=0;i<products.length;i++)
    {
      let productCounter =(products[i].product);
      if(productCounter.length>0){
       // for(let j=0;j<productCounter.length;j++){
        let ada = productCounter.filter(x =>
              x.id.toString().toLowerCase().indexOf(text) !== -1 || 
              x.product_name.toLowerCase().indexOf(text) !== -1) ;
         if(ada.length>0){
          for(let j=0;j<ada.length;j++){
            let isExitst = filterProduct.find(y=>y.id==ada[j].id);
            if(!isExitst){
              filterProduct.push(ada[j]);
            }
          }
         }
      }
    }
    return filterProduct;
  }

  searchProducts(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterProducts(this.productList, text);
    event.component.endSearch();
  }

  deleteDetail(i: any) {
    this.getDetailArray.removeAt(i);
  }

  getProductPackageforCreate() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = { 
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.packageService.getProductPackageforCreate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.counterList = response.results.mst_counter_data;
          this.brandList = response.results.mst_product_brand_data;
          this.subBrandListMaster = response.results.mst_product_sub_brand_data;
          this.typeList = response.results.mst_product_type_data;
          this.subTypeListMaster = response.results.mst_product_sub_type_data;
          this.categoryList = response.results.mst_product_category_data;
          this.subCategoryListMaster = response.results.mst_product_sub_category_data;
          this.marketingTypeList = response.results.mst_product_marketing_type_data;
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  getProduct(counter_id:number) {
   
      let options = {
        "token": this.token,
        "counter_id": counter_id
      };

      this.packageService.getListProduct(options).subscribe((response) => {
       if (response.status.code === 200) {
         this.productList.push({counter:counter_id,product:response.results});
        }
      }, () => {
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
  }


  getSubBrandList(event) {
    let brandId = event.target.value;
    if(this.subBrandListMaster.length > 0) {
      this.subBrandList = [];
      this.subBrandList = this.subBrandListMaster.filter(x => x.product_brand_id === brandId);
    } else {
      this.toastCtrl.create({ duration: 2000, message: 'Data Product Sub Brand kosong!' }).then(t => t.present());
    }

    this.refreshForm('subBrandId');
  }

  getSubTypeList(event) {
    let typeId = event.target.value;
    if(this.subTypeListMaster.length > 0) {
      this.subTypeList = [];
      this.subTypeList = this.subTypeListMaster.filter(x => x.product_type_id === typeId);
    } else {
      this.toastCtrl.create({ duration: 2000, message: 'Data Product Sub Type kosong!' }).then(t => t.present());
    }

    this.refreshForm('subTypeId');
  }

  getSubCategoryList(event) {
    let categoryId = event.target.value;
    if(this.subCategoryListMaster.length > 0) {
      this.subCategoryList = [];
      this.subCategoryList = this.subCategoryListMaster.filter(x => x.product_category_id === categoryId);
    } else {
      this.toastCtrl.create({ duration: 2000, message: 'Data Product Sub Category kosong!' }).then(t => t.present());
    }

    this.refreshForm('subCategoryId');
  }

  refreshForm(action: any) {
    const form = this.formProductPackageCreate.value;
    let detailArray: any[] = form.detail;
    this.formProductPackageCreate = this.fb.group({
      counterId: [form.counterId, Validators.required],
      productPackageName: [form.productPackageName, Validators.required],
      productPackageInitialName: [form.productPackageInitialName, Validators.required],
      brandId: [form.brandId, Validators.required],
      subBrandId: [action === 'subBrandId' ? null : form.subBrandId, Validators.required],
      typeId: [form.typeId, Validators.required],
      subTypeId: [action === 'subTypeId' ? null : form.subTypeId, Validators.required],
      categoryId: [form.categoryId, Validators.required],
      subCategoryId: [action === 'subCategoryId' ? null : form.subCategoryId],
      marketingTypeId: [form.marketingTypeId, Validators.required],
      barcode: [form.barcode, Validators.required],
      price: [form.price, Validators.required],
      length: [form.length, Validators.required],
      width: [form.width, Validators.required],
      height: [form.height, Validators.required],
      allocationQty: [form.allocationQty],
      detail: this.fb.array([])
    });
    
    if(detailArray.length > 0) {
      for(let i = 0; i < detailArray.length; i++) {
        let productId: string = detailArray[i].product.id.trim().toLowerCase();
        let filterProduct = this.filterProducts(this.productList, productId);
        const detail = this.fb.group({
          product: [filterProduct[0], Validators.required],
          price: [detailArray[i].price, Validators.required],
          qty: [detailArray[i].qty, Validators.required]
        });
        
        this.getDetailArray.push(detail);
      }
    }
  }

  inputData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let convertDate = this.utilService.convertDate(new Date());
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      const formProductPackage = this.formProductPackageCreate.value;
      let arrProduct: any = [];
      let arrPrice: any = [];
      let arrQty: any = [];
      let arrCreatedBy: any = [];

      let arrStockMutationTypeId: any = [];
      let arrValue: any = [];
      let arrStockMove: any = [];
      let arrTransDate: any = [];

      for(let x = 0; x < formProductPackage.detail.length; x++) {
        arrProduct[x] = formProductPackage.detail[x].product.id;
        arrPrice[x] = this.myService.removeSeparator(formProductPackage.detail[x].price);
        arrQty[x] = this.myService.removeSeparator(formProductPackage.detail[x].qty);
        arrCreatedBy[x] = this.userProfile.username;

        arrStockMutationTypeId[x] = 'MP'; // MP = MUTASI PAKET
        arrValue[x] = 0;
        arrStockMove[x] = 'O';
        arrTransDate[x] = transDate;
      }

      const mstProductPackageBundle = new MstProductPackageBundle();
      mstProductPackageBundle.allocationQty = formProductPackage.allocationQty;
      mstProductPackageBundle.mstProduct.product_brand_id = formProductPackage.brandId;
      mstProductPackageBundle.mstProduct.product_sub_brand_id = parseInt(formProductPackage.subBrandId) < 1000 ? "0" + formProductPackage.subBrandId : formProductPackage.subBrandId;
      mstProductPackageBundle.mstProduct.product_type_id = formProductPackage.typeId;
      mstProductPackageBundle.mstProduct.product_sub_type_id = parseInt(formProductPackage.subTypeId) < 1000 ? "0" + formProductPackage.subTypeId : formProductPackage.subTypeId;
      mstProductPackageBundle.mstProduct.product_category_id = formProductPackage.categoryId;
      mstProductPackageBundle.mstProduct.product_sub_category_id = parseInt(formProductPackage.subCategoryId) < 1000 ? "0" + formProductPackage.subCategoryId : formProductPackage.subCategoryId;
      mstProductPackageBundle.mstProduct.product_marketing_type_id = formProductPackage.marketingTypeId;
      mstProductPackageBundle.mstProduct.barcode = formProductPackage.barcode;
      mstProductPackageBundle.mstProduct.product_name = formProductPackage.productPackageName;
      mstProductPackageBundle.mstProduct.initial_name = formProductPackage.productPackageInitialName;
      mstProductPackageBundle.mstProduct.unit = 'PKT';
      mstProductPackageBundle.mstProduct.price = this.myService.removeSeparator(formProductPackage.price);
      mstProductPackageBundle.mstProduct.length_size = this.myService.removeSeparator(formProductPackage.length);
      mstProductPackageBundle.mstProduct.width_size = this.myService.removeSeparator(formProductPackage.width);
      mstProductPackageBundle.mstProduct.height_size = this.myService.removeSeparator(formProductPackage.height);
      mstProductPackageBundle.mstProduct.created_by = this.userProfile.username;

      mstProductPackageBundle.mstProductPackage.product_id = arrProduct;
      mstProductPackageBundle.mstProductPackage.price = arrPrice;
      mstProductPackageBundle.mstProductPackage.qty = arrQty;
      mstProductPackageBundle.mstProductPackage.created_by = arrCreatedBy;

      let options={
        packageBundle:mstProductPackageBundle,
        counterId:this.counterId
      }

      this.packageService.addProductPackageMultipleCounter(options).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/master-product-packet']);;
          }
        }
      ]
    });

    await alert.present();
  }
  getChangeNominal(columnname:any){
    let arrform=<FormArray>this.formProductPackageCreate.controls[columnname];    
    let cur= arrform.value;
    let curWithSeparator='0';
    if(cur!='0' && cur!='' && cur!=null){
      curWithSeparator = this.myService.separator(cur);
      curWithSeparator = String(curWithSeparator).replace(/[^0-9,]/g, '');
    }
    this.formProductPackageCreate.controls[columnname].setValue(curWithSeparator);
  }
  onChangePrice(index:any,columnname:any){
    this.price=0;
    const formProductPackage = this.formProductPackageCreate.value;
    for(let x = 0; x < formProductPackage.detail.length; x++) {
      let price =(this.myService.removeSeparator(formProductPackage.detail[x].price));
      let qty =(this.myService.removeSeparator(formProductPackage.detail[x].qty));
      var calculatePrice=price * qty;
      this.price += calculatePrice;
    };
    this.showPrice = this.myService.separator( this.price.toString());
    this.showPrice = String( this.showPrice).replace(/[^0-9,]/g, '');
    let arrform=<FormArray>this.formProductPackageCreate.controls['detail'];    
    let cur= arrform.controls[index].value[columnname];
    let curWithSeparator='0';
    if(cur!='0' && cur!='' && cur!=null){
      curWithSeparator = this.myService.separator(cur);
      curWithSeparator = String(curWithSeparator).replace(/[^0-9,]/g, '');
    }
    arrform.controls[index].patchValue({[columnname]:curWithSeparator});
  }
  selectCounter(event){
    this.counterId = [];
    this.productList=[];
    for(let i= 0; i < event.value.length; i++) {
      this.counterId.push(event.value[i]['id']);
      this.getProduct(event.value[i]['id']);
    }
  }
}
