import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { GudangCreatePageRoutingModule } from './gudang-create-routing.module';

import { GudangCreatePage } from './gudang-create.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    GudangCreatePageRoutingModule
  ],
  declarations: [GudangCreatePage]
})
export class GudangCreatePageModule {}
