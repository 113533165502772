import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../report.service';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../../service/util.service';
import { Storage } from '@ionic/storage';
import * as XLSX from 'xlsx';
import { RoleAccess } from '../../../../models/role-access.model';
import { UserData } from '../../../../providers/user-data';
import { Router } from '@angular/router';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportSummaryComponent } from '../../../../component/report-summary/report-summary.component';

@Component({
  selector: 'app-report-summary-sales',
  templateUrl: './report-summary-sales.page.html',
  styleUrls: ['./report-summary-sales.page.scss'],
})
export class ReportSummarySalesPage implements OnInit {
  dataSummarySales:any[]=[];
  dataSummarySalesPerItemPerCounter:any[]=[];
  dataTimeseriesSales:any[]=[];
  allDataSummaryDaily:any[]=[];
  allDataSummaryMonthly:any[]=[];
  counterListHeader:any[]=[];
  countCounter:number=0;
  countDate:number=0;
  year : any=(new Date()).getFullYear();
  month: any=(new Date()).getMonth();
  token : any;  
  yearTimeSales : any = new Date().toISOString();
  periodeSales :any = new Date().toISOString();
  periodeEndSales :any = new Date().toISOString();
  dateStart:any;
  dateEnd:any;
  colspanTotal:number=1;
  totalQtySummary:number= 0;
  totalNettoSummary:number=0

  //ini untuk timeseries  
  arrayBottomTimeSeries:any= [];
  sumPerCounter:any=[];
  totalQtyTimeSeries:number=0;
  totalNettoTimeSeries:number=0;
  
  roleAccess = new RoleAccess();
  userProfile: UserProfile = new UserProfile();

  ListTypeReport :any[]=['','Summary','Timeseries'];
  
  monthList :any[]=['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'];
  monthListShow:any[]=[];
  monthIndexShow:any[]=[];
  periodType:any='monthly';
  branchList:any;
  counterList:any;
  productList:any;
  brandList:any;
  subBrandList:any;
  categoryList:any;
  subCategoryList:any;
  cashierTypeList:any;
  cashierList:any;
  typeList:any;
  subTypeList:any;

  counterByLogin : any;
  //filter
  reportTypeId:number=0;
  rekapPerId:any='';
  rekapPerId2:any='';
  cashierTypeId:any='';
  reportTypeName:any='';
  rekapPerList:any=[];
  rekapPerName:any='';
  filterCounterName:any='';
  filterBrandName:any='';
  filterSubBrandName:any='';
  filterTypeName:any='';
  filterSubTypeName:any='';
  filterCategoryName:any='';
  filterSubCategoryName:any='';
  filterCashierTypeName:any='';
  filterCashierName:any='';

  showTitleSummary:boolean=false;
  showTitleTimeseries:boolean=false;
  showReportSummary:boolean=false;
  showReportTimeseries:boolean=false;
  reportSummaryTitle:any='Hide Report';
  reportTimeseriesTitle:any='Hide Report';

  //filter kolom result
  filterByProduct:boolean=false;
  filterByCounter:boolean=false;
  filterByBrand:boolean=false;
  filterBySubBrand:boolean=false;
  filterByType:boolean=false;
  filterBySubType:boolean=false;
  filterByCategory:boolean=false;
  filterBySubCategory:boolean=false;
  filterByCashierType:boolean=false;
  filterByCashierId:boolean=false;

  // di send ke api untuk generate report
  paramCounter = '';
  paramBrand = '';
  paramSubBrand = '';
  paramType = '';
  paramSubType = '';
  paramCategory = '';
  paramSubCategory = '';
  paramCashierType = '';
  paramCashier ='';
  arrayBranch=[];
  arrayCounter=[];
  arrayBrand=[];
  arrayBrand2=[];
  arraySubBrand=[];
  arraySubBrand2=[];
  arrayType=[];
  arrayType2=[];
  arraySubType=[];
  arraySubType2=[];
  arrayCategory=[];
  arrayCategory2=[];
  arraySubCategory=[];
  arraySubCategory2=[];
  arrayCashierType=[];
  arrayCashierType2=[];
  arrayCashierId=[];
  arrayCashierId2=[];

  constructor(
    private reportService :ReportService,
    private toastCtrl : ToastController,
    private utilService :UtilService,
    private storage: Storage,
    private userData: UserData,
    private navCtrl: NavController,
    private router: Router,
    private modalController :ModalController
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        
        this.rekapPerList = [
          {'name':'Counter','id':1},
          {'name':'Brand','id':2},
          {'name':'Sub Brand','id':3},
          {'name':'Category','id':4},
          {'name':'Sub Category','id':5},
          {'name':'Type','id':6},
          {'name':'Sub Type','id':7},
          {'name':'Product','id':8},
          {'name':'Cashier Type','id':9},
          {'name':'Cashier','id':10}
        ];
        
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": this.counterByLogin
          };
          this.reportService.getReportSummaryIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.branchList = response.results.branch_data;
            this.counterList = response.results.counter_data;
            this.brandList = response.results.brand_data;
            this.subBrandList = response.results.data_sub_brand;
            this.categoryList = response.results.data_category;
            this.subCategoryList = response.results.data_sub_category;
            this.typeList = response.results.data_type;
            this.subTypeList = response.results.data_sub_type;
            this.cashierTypeList = response.results.data_cashier_type;
            this.cashierList = response.results.data_cashier;
          });
        });
      }
    });
  }
  
  resetfilter(){
    this.allDataSummaryMonthly=[];
    this.allDataSummaryDaily=[];
    this.counterListHeader=[];
    this.countDate=0;
    this.countCounter=0;
    this.showTitleSummary = false;
    this.showTitleTimeseries = false;
    this.dataSummarySalesPerItemPerCounter=[];
    this.dataSummarySales = [];
    this.dataTimeseriesSales = [];
    this.rekapPerName ='';
    this.rekapPerId ='';
    this.rekapPerId2 ='';
    this.reportTypeName ='';
    this.reportTypeId = 0;
    this.filterCounterName = '';
    this.filterBrandName = '';
    this.filterSubBrandName = '';
    this.filterTypeName = '';
    this.filterSubTypeName = '';
    this.filterCategoryName = '';
    this.filterSubCategoryName = '';
    this.filterCashierTypeName = '';
    this.filterCashierName = '';
    this.filterByProduct=false;
    this.filterByCounter= false;
    this.filterByBrand= false;
    this.filterBySubBrand= false;
    this.filterByType= false;
    this.filterBySubType= false;
    this.filterByCategory= false;
    this.filterBySubCategory= false;
    this.filterByCashierType=false;
    this.filterByCashierId=false;
  }
  
  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ReportSummaryComponent,
      componentProps: {
        reportTypeId:this.reportTypeId,
        periodType:this.periodType,
        periodStart:this.periodeSales,
        periodEnd:this.periodeEndSales,
        rekapPer:this.rekapPerId,
        rekapPer2:this.rekapPerId2,
        arrayBranch:this.arrayBranch,
        counterByLogin: this.counterByLogin,
        productList: this.productList,
        arrayCounter: this.arrayCounter,
        arrayBrand: this.arrayBrand,
        arrayBrand2: this.arrayBrand2,
        arraySubBrand: this.arraySubBrand,
        arraySubBrand2: this.arraySubBrand2,
        arrayCategory: this.arrayCategory,
        arrayCategory2: this.arrayCategory2,
        arraySubCategory: this.arraySubCategory,
        arraySubCategory2: this.arraySubCategory2,
        arrayType: this.arrayType,
        arrayType2: this.arrayType2,
        arraySubType: this.arraySubType,
        arraySubType2: this.arraySubType2,
        arrayCashierType: this.arrayCashierType,
        arrayCashierType2: this.arrayCashierType2,
        arrayCashierId: this.arrayCashierId,
        arrayCashierId2: this.arrayCashierId2,
        brandList: this.brandList,
        subBrandList: this.subBrandList,
        categoryList: this.categoryList,
        subCategoryList: this.subCategoryList,
        typeList: this.typeList,
        subTypeList: this.subTypeList,
        cashierTypeList:this.cashierTypeList,
        cashierList:this.cashierList
      },
      backdropDismiss: false
    });
    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if (data !== 'null') {
        this.allDataSummaryDaily=[];
        this.counterListHeader=[];
        this.resetfilter();
        this.reportTypeId = data[0]['reportType'];
        this.reportTypeName =this.ListTypeReport[data[0]['reportType']];
        this.periodType = data[0]['periodType'];
        
        let rekapPer = this.rekapPerList.find(x => x.id === data[0]['rekapPer']);
        let rekapPer2 = this.rekapPerList.find(x => x.id === data[0]['rekapPer2']);
        if(data[0]['rekapPer']=='1' || data[0]['rekapPer2']=='1'){
          this.filterByCounter=true;
        }
        if(data[0]['rekapPer']=='2' || data[0]['rekapPer2']=='2'){
          this.filterByBrand=true;
        }
        if(data[0]['rekapPer']=='3' || data[0]['rekapPer2']=='3'){
          this.filterBySubBrand=true;
        }
        if(data[0]['rekapPer']=='4' || data[0]['rekapPer2']=='4'){
          this.filterByCategory=true;
        }
        if(data[0]['rekapPer']=='5' || data[0]['rekapPer2']=='5'){
          this.filterBySubCategory=true;
        }
        if(data[0]['rekapPer']=='6' || data[0]['rekapPer2']=='6'){
          this.filterByType=true;
        }
        if(data[0]['rekapPer']=='7' || data[0]['rekapPer2']=='7'){
          this.filterBySubType=true;
        }
        if(data[0]['rekapPer']=='8' || data[0]['rekapPer2']=='8'){
          this.filterByProduct=true;
        }
        if(data[0]['rekapPer']=='9' || data[0]['rekapPer2']=='9'){
          this.filterByCashierType=true;
        }
        if(data[0]['rekapPer']=='10' || data[0]['rekapPer2']=='10'){
          this.filterByCashierId=true;
        }
        
        this.arrayBranch =[];
        if(data[0]['branchId']!=''){
          let arrBranch1 = data[0]['branchId'].toString().split(',');
          for(let b=0;b<arrBranch1.length;b++){
            this.arrayBranch.push(arrBranch1[b]);
          }
        }        

        
        let arrCounter= [];
        if(data[0]['counterId']!=''){
          arrCounter= data[0]['counterId'].split(',');
        }
        if(arrCounter.length>0){
          for(let i=0;i<arrCounter.length;i++){
            let filterCounter = this.counterList.find(x => x.id == arrCounter[i]);
            if(filterCounter){
              this.filterCounterName += filterCounter.counter_name+','; 
            }          
          }
        }

        let arrBrand= [];
       
        if(data[0]['brandId']!=''){
          arrBrand= data[0]['brandId'].split(',');
        }
        let arrBrand2=  [];
        if(data[0]['brandId2']!=''){
          arrBrand2= data[0]['brandId2'].split(',');
        }
        if(arrBrand.length>0){
          for(let i=0;i<arrBrand.length;i++){
            let filterBrand = this.brandList.find(x => x.id == arrBrand[i]);
            this.filterBrandName += filterBrand.product_brand_name+',';           
          }
        }

        if(arrBrand2.length>0){
          for(let i=0;i<arrBrand2.length;i++){
            let filterBrand = this.brandList.find(x => x.id == arrBrand2[i]);
            this.filterBrandName += filterBrand.product_brand_name+',';
          }
        }

        let arrSubBrand= [];
        if(data[0]['subBrandId']!=''){
          arrSubBrand = data[0]['subBrandId'].split(',');
        }
        let arrSubBrand2 = [];
        if(data[0]['subBrandId2']!=''){
          arrSubBrand2 = data[0]['subBrandId2'].split(',');
        }
        if(arrSubBrand.length>0){
          for(let i=0;i<arrSubBrand.length;i++){
            let filterSubBrand = this.subBrandList.find(x => x.id == arrSubBrand[i]);
            this.filterSubBrandName += filterSubBrand.product_sub_brand_name+',';
          }
        }
        if(arrSubBrand2.length>0){
          for(let i=0;i<arrSubBrand2.length;i++){
            let filterSubBrand = this.subBrandList.find(x => parseInt(x.id) === parseInt(arrSubBrand2[i]));
            this.filterSubBrandName += filterSubBrand.product_sub_brand_name+',';
          }
        }

        let arrType= [];
        if(data[0]['typeId']!=''){
          arrType= data[0]['typeId'].split(',');
        }
        let arrType2=  [];
        if(data[0]['typeId2']!=''){
          arrType2= data[0]['typeId2'].split(',');
        }
        if(arrType.length>0){
          for(let i=0;i<arrType.length;i++){
            let filterType = this.typeList.find(x => parseInt(x.id) === parseInt(arrType[i]));
            this.filterTypeName += filterType.product_type_name+',';
          }
        }
        if(arrType2.length>0){
          for(let i=0;i<arrType2.length;i++){
            let filterType = this.typeList.find(x => parseInt(x.id) === parseInt(arrType2[i]));
            this.filterTypeName += filterType.product_type_name+',';
          }
        }

        let arrSubType= [];
        if(data[0]['subTypeId']!=''){
          arrSubType= data[0]['subTypeId'].split(',');
        }
        let arrSubType2=  [];
        if(data[0]['subTypeId2']!=''){
          arrSubType2= data[0]['subTypeId2'].split(',');
        }
        if(arrSubType.length>0){
          for(let i=0;i<arrSubType.length;i++){
            let filterSubType = this.subTypeList.find(x => parseInt(x.id) === parseInt(arrSubType[i]));
            this.filterSubTypeName += filterSubType.product_sub_type_name+',';
          }
        }
        if(arrSubType2.length>0){
          for(let i=0;i<arrSubType2.length;i++){
            let filterSubType = this.subTypeList.find(x => parseInt(x.id) === parseInt(arrSubType2[i]));
            this.filterSubTypeName += filterSubType.product_sub_type_name+',';
          }
        }

        let arrCategory= [];
        if(data[0]['categoryId']!=''){
          arrCategory= data[0]['categoryId'].split(',');
        }
        let arrCategory2=  [];
        if(data[0]['categoryId2']!=''){
          arrCategory2= data[0]['categoryId2'].split(',');
        }
        if(arrCategory.length>0){
          for(let i=0;i<arrCategory.length;i++){
            let filterCategory = this.categoryList.find(x => x.id == arrCategory[i]);
            this.filterCategoryName += filterCategory.product_category_name+',';
          }
        }
        if(arrCategory2.length>0){
          for(let i=0;i<arrCategory2.length;i++){
            let filterCategory = this.categoryList.find(x => x.id == arrCategory2[i]);
            this.filterCategoryName += filterCategory.product_category_name+',';
          }
        }

        let arrSubCategory= [];
        if(data[0]['subCategoryId']!=''){
          arrSubCategory= data[0]['subCategoryId'].split(',');
        }
        let arrSubCategory2=  [];
        if(data[0]['subCategoryId2']!=''){
          arrSubCategory2= data[0]['subCategoryId2'].split(',');
        }
        if(arrSubCategory.length>0){
          for(let i=0;i<arrSubCategory.length;i++){
            let filterSubCategory = this.subCategoryList.find(x => x.id == arrSubCategory[i]);
            this.filterSubCategoryName += filterSubCategory.product_sub_category_name+',';
          }
        }
        if(arrSubCategory2.length>0){
          for(let i=0;i<arrSubCategory2.length;i++){
            let filterSubCategory = this.subCategoryList.find(x => x.id == arrSubCategory2[i]);
            this.filterSubCategoryName += filterSubCategory.product_sub_category_name+',';
          }
        }

        let arrCashierType= [];
        if(data[0]['cashierTypeId']!=''){
          arrCashierType= data[0]['cashierTypeId'].split(',');
        }
        let arrCashierType2=  [];
        if(data[0]['cashierTypeId2']!=''){
          arrCashierType2= data[0]['cashierTypeId2'].split(',');
        }
        if(arrCashierType.length>0){
          for(let i=0;i<arrCashierType.length;i++){             
            let filterCashierType = this.cashierTypeList.find(x => x.id == arrCashierType[i]);
            this.filterCashierTypeName += filterCashierType.cashier_type_name+',';
          }
        }
        if(arrCashierType2.length>0){
          for(let i=0;i<arrCashierType2.length;i++){
            let filterCashierType = this.cashierTypeList.find(x => x.id == arrCashierType2[i]);
            this.filterCashierTypeName += filterCashierType.cashier_type_name+',';
          }
        }
        
        let arrCashierId= [];
        if(data[0]['cashierId']!=''){
          arrCashierId= data[0]['cashierId'].split(',');
        }
        let arrCashierId2=  [];
        if(data[0]['cashierId2']!=''){
          arrCashierId2= data[0]['cashierId2'].split(',');
        }
        if(arrCashierId.length>0){
          for(let i=0;i<arrCashierId.length;i++){             
            let filterCashier = this.cashierList.find(x => x.id == arrCashierId[i]);
            this.filterCashierName += filterCashier.cashier_name+',';
          }
        }
        if(arrCashierId2.length>0){
          for(let i=0;i<arrCashierId2.length;i++){
            let filterCashier = this.cashierList.find(x => x.id == arrCashierId2[i]);
            this.filterCashierName += filterCashier.cashier_name+',';
          }
        }

        if(rekapPer){
          this.rekapPerName += rekapPer.name+',';
          this.rekapPerId =rekapPer.id;
        }
        if(rekapPer2){
          this.rekapPerName += rekapPer2.name;
          this.rekapPerId2 =rekapPer2.id;

        }
        let dateOfPeriod:any=0;
        if(data[0]['periodStart']!=''){
          this.periodeSales = data[0]['periodStart'];
          let periodeConvert = this.utilService.convertDate(this.periodeSales);
          this.dateStart = periodeConvert.years + '-' + periodeConvert.months + '-' + periodeConvert.dates;
         
          this.year = periodeConvert.years;
          this.month = periodeConvert.months;
          dateOfPeriod=periodeConvert.dates;
        }
        if(data[0]['periodEnd']!=''){
          this.periodeEndSales = data[0]['periodEnd'];
          let periodeEndConvert = this.utilService.convertDate(this.periodeEndSales);
          this.dateEnd = periodeEndConvert.years + '-' + periodeEndConvert.months + '-' + periodeEndConvert.dates;             
        }
        if(data[0]['periodStart']!=data[0]['periodEnd']){
          this.countDate=2;
        }
        this.paramCounter ='';
        this.arrayCounter =[];
        if(data[0]['counterId']!=''){
          this.paramCounter +=  data[0]['counterId']+ ',';
          let arrCounter1 = data[0]['counterId'].split(',');
          for(let b=0;b<arrCounter1.length;b++){
            this.arrayCounter.push(arrCounter1[b]);
          }
        }
        this.paramCounter +=  data[0]['counterId2'];

        this.paramBrand ='';
        this.arrayBrand =[];
        this.arrayBrand2 =[];
        if(data[0]['brandId']!=''){
          this.paramBrand +=  data[0]['brandId']+ ',';
          let arrBrand1 = data[0]['brandId'].split(',');
          for(let b=0;b<arrBrand1.length;b++){
            this.arrayBrand.push(arrBrand1[b]);
          }
        }
        if(data[0]['brandId2']!=''){
          this.paramBrand +=  data[0]['brandId2'];
          let arrBrand2 = data[0]['brandId2'].split(',');
          for(let b=0;b<arrBrand2.length;b++){
            this.arrayBrand2.push(arrBrand2[b]);
          }
        }

        this.paramSubBrand ='';
        this.arraySubBrand =[];
        this.arraySubBrand2 =[];
        if(data[0]['subBrandId']!=''){
          this.paramSubBrand +=  data[0]['subBrandId']+ ',';
          let arrSubBrand1 = data[0]['subBrandId'].split(',');
          for(let b=0;b<arrSubBrand1.length;b++){
            this.arraySubBrand.push(arrSubBrand1[b]);
          }
        }
        if(data[0]['subBrandId2']!=''){
          this.paramSubBrand +=  data[0]['subBrandId2'];
          let arrSubBrand2 = data[0]['subBrandId2'].split(',');
          for(let b=0;b<arrSubBrand2.length;b++){
            this.arraySubBrand2.push(arrSubBrand2[b]);
          }
        }
        this.paramType = '';
        this.arrayType=[];
        this.arrayType2 =[];
        if(data[0]['typeId']!=''){
         this.paramType +=  data[0]['typeId']+  ',';
         let arrType1 = data[0]['typeId'].split(',');
         for(let b=0;b<arrType1.length;b++){
           this.arrayType.push(arrType1[b]);
         }
        }
        if(data[0]['typeId2']!=''){
          this.paramType +=  data[0]['typeId2'];
          let arrType2 = data[0]['typeId2'].split(',');
          for(let b=0;b<arrType2.length;b++){
            this.arrayType2.push(arrType2[b]);
          }
        }

        this.paramSubType = '';
        this.arraySubType=[];
        this.arraySubType2 =[];
        if(data[0]['subTypeId']!=''){
          this.paramSubType +=  data[0]['subTypeId']+  ',';
          let arrSubType1 = data[0]['subTypeId'].split(',');
          for(let b=0;b<arrSubType1.length;b++){
            this.arraySubType.push(arrSubType1[b]);
          }
        }
        if(data[0]['subTypeId2']!=''){
          this.paramSubType +=  data[0]['subTypeId2'];
          let arrSubType2 = data[0]['subTypeId2'].split(',');
          for(let b=0;b<arrSubType2.length;b++){
            this.arraySubType2.push(arrSubType2[b]);
          }
        }
        
        this.paramCategory ='';
        this.arrayCategory=[];
        this.arrayCategory2 =[];
        if(data[0]['categoryId']!=''){
          this.paramCategory +=  data[0]['categoryId']+ ',';
          let arrCategory1 = data[0]['categoryId'].split(',');
          for(let b=0;b<arrCategory1.length;b++){
            this.arrayCategory.push(arrCategory1[b]);
          }
        }
        if(data[0]['categoryId2']!=''){
          this.paramCategory +=  data[0]['categoryId2'];
          let arrCategory2 = data[0]['categoryId2'].split(',');
          for(let b=0;b<arrCategory2.length;b++){
            this.arrayCategory2.push(arrCategory2[b]);
          }
        }

        this.paramSubCategory = '';
        this.arraySubCategory=[];
        this.arraySubCategory2 =[];
        if(data[0]['subCategoryId']!=''){
          this.paramSubCategory +=  data[0]['subCategoryId']+  ',';
          let arrSubCategory1 = data[0]['subCategoryId'].split(',');
          for(let b=0;b<arrSubCategory1.length;b++){
            this.arraySubCategory.push(arrSubCategory1[b]);
          }
        }
        if(data[0]['categoryId2']!=''){
          this.paramSubCategory +=  data[0]['subCategoryId2'];
          let arrSubCategory2 = data[0]['subCategoryId2'].split(',');
          for(let b=0;b<arrSubCategory2.length;b++){
            this.arraySubCategory2.push(arrSubCategory2[b]);
          }
        }

        this.paramCashierType ='';
        this.arrayCashierType=[];
        this.arrayCashierType2 =[];
        if(data[0]['cashierTypeId']!=''){
          this.paramCashierType +=  data[0]['cashierTypeId']+ ',';
          let arrCashierType1 = data[0]['cashierTypeId'].split(',');
          for(let b=0;b<arrCashierType1.length;b++){
            this.arrayCashierType.push(arrCashierType1[b]);
          }
        }
        if(data[0]['cashierTypeId2']!=''){
          this.paramCashierType +=  data[0]['cashierTypeId2'];
          let arrCashierType2 = data[0]['cashierTypeId2'].split(',');
          for(let b=0;b<arrCashierType2.length;b++){
            this.arrayCashierType2.push(arrCashierType2[b]);
          }
        }
        
        this.paramCashier ='';
        this.arrayCashierId=[];
        this.arrayCashierId2 =[];
        if(data[0]['cashierId']!=''){
          this.paramCashier +=  data[0]['cashierId']+ ',';
          let arrCashier1 = data[0]['cashierId'].split(',');
          for(let b=0;b<arrCashier1.length;b++){
            this.arrayCashierId.push(arrCashier1[b]);
          }
        }
        if(data[0]['cashierId2']!=''){
          this.paramCashier +=  data[0]['cashierId2'];
          let arrCashier2 = data[0]['cashierId2'].split(',');
          for(let b=0;b<arrCashier2.length;b++){
            this.arrayCashierId2.push(arrCashier2[b]);
          }
        }

        this.colspanTotal = 1;
        if(data[0]['rekapPer']!=''){
          if(data[0]['rekapPer']!='9'){
            this.colspanTotal+=2;
          }
          else{
            this.colspanTotal+=1;
          }
        }
        if(data[0]['rekapPer2']!=''){
          if(data[0]['rekapPer2']!='9'){
            this.colspanTotal+=2;
          }
          else{
            this.colspanTotal+=1;
          }
        }
        if(this.reportTypeId==2){
          this.colspanTotal+=2;
        }
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
            // generate report
          let body = {
            year: this.year,
            month: this.month,
            date: dateOfPeriod,
            dateStart:this.dateStart,
            dateEnd:this.dateEnd,
            reportTypeId:this.reportTypeId,
            counterId: this.paramCounter,
            brandId: this.paramBrand,
            subBrandId: this.paramSubBrand,
            typeId: this.paramType,
            subTypeId: this.paramSubType,
            categoryId: this.paramCategory,
            subCategoryId: this.paramSubCategory,
            rekapPer:data[0]['rekapPer'],
            rekapPer2:data[0]['rekapPer2'],
            cashierTypeId:this.paramCashierType,
            cashierId:this.paramCashier
          };
          if(this.reportTypeId!=2){
            if(this.periodType=='monthly'){
              this.reportService.getReportSummarySales(body).subscribe((response) => {
                this.utilService.loadingDismiss();
                if(response.status.code === 200) {
                  this.dataSummarySales = response.results.data_filter;
                  this.allDataSummaryMonthly= response.results.summary_sales;
                  this.counterListHeader=response.results.list_counter;
                  this.showTitleSummary = true;
                  this.showReportSummary= true;
                  this.totalQtySummary =0;
                  this.totalNettoSummary =0;
                  for(let i=0;i<this.dataSummarySales.length;i++){
                    this.totalQtySummary += parseInt(this.dataSummarySales[i].qty);
                    this.totalNettoSummary += parseInt(this.dataSummarySales[i].netto);
                  }
                } else {
                  this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
                }
              }, (err) => {
                this.utilService.loadingDismiss();
                console.log(err);
                this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
              });
            }
            else{
              this.reportService.getReportSummarySalesDaily(body).subscribe((response) => {
                this.utilService.loadingDismiss();
                if(response.status.code === 200) {
                  this.allDataSummaryDaily = response.results.summary_sales_dailies;
                  this.counterListHeader=response.results.list_counter;
                  this.dataSummarySales = response.results.data_filter;
                  this.dataSummarySalesPerItemPerCounter = response.results.total_per_item_per_counter;
                  this.showTitleSummary = true;
                  this.showReportSummary= true;
                  this.sumPerCounter=[];
                  let arrSumData:any[] =[];
                  for(let i=0;i<this.counterListHeader.length;i++){
                    let counterId=this.counterListHeader[i].counter_id;
                    for(let j=0;j<this.dataSummarySales.length;j++){
                      let qty = this.getSummaryDaily(counterId,this.dataSummarySales[j], this.counterListHeader[i].trans_date,'qty');
                      let netto = this.getSummaryDaily(counterId,this.dataSummarySales[j], this.counterListHeader[i].trans_date,'netto');                     
                      let findCounter=this.sumPerCounter.find(x => x.counter_id === counterId);
                      if(findCounter){
                        findCounter.qty+=parseInt(qty);
                        findCounter.netto+=parseInt(netto);
                      }
                      else{
                        this.sumPerCounter.push({
                          'counter_id':this.counterListHeader[i].counter_id,
                          'counter_name':this.counterListHeader[i].counter_name,
                          'qty':parseInt(qty),
                          'netto':parseInt(netto),
                          'trans_date':'0'
                        })
                      }                      
                    }
                    if(this.counterListHeader[i+1]){
                      if(this.counterListHeader[i+1].counter_id==counterId){
                      }
                      else{
                        let findCounter=this.sumPerCounter.find(x => x.counter_id === counterId);
                        findCounter.idx=i+1;
                        arrSumData.push(findCounter);
                      }
                    }
                    if(i==this.counterListHeader.length-1){
                      let findCounter=this.sumPerCounter.find(x => x.counter_id === counterId);
                      findCounter.idx=i+1;
                      arrSumData.push(findCounter);
                    }
                  }

                  this.countCounter=(arrSumData.length);
                  if(this.countCounter>1 && this.countDate>1){
                    let idx=0;
                    for(let x = 0; x < arrSumData.length; x++) {
                      let indexInsert: number = arrSumData[x].idx;
                      this.counterListHeader.splice(indexInsert+idx, 0, arrSumData[x]);
                      idx++;
                    }
                  }
                  this.totalQtySummary =0;
                  this.totalNettoSummary =0;
                  for(let i=0;i<this.dataSummarySales.length;i++){
                    this.totalQtySummary += parseInt(this.dataSummarySales[i].qty);
                    this.totalNettoSummary += parseInt(this.dataSummarySales[i].netto);
                  }
                } else {
                  this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
                }
              }, (err) => {
                this.utilService.loadingDismiss();
                console.log(err);
                this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
              });
            }
          }
          if(this.reportTypeId!=1){
            this.reportService.getReportTimeseriesSales(body).subscribe((response) => {
              this.utilService.loadingDismiss();
              if(response.status.code === 200) {
                this.arrayBottomTimeSeries = [];
                this.dataTimeseriesSales = response.results.timeseries_sales;
                this.showTitleTimeseries = true;
                this.showReportTimeseries=true;
                this.monthListShow = [];
                this.monthIndexShow =[];
                let setMonth = parseInt(this.month);
                let firstMonth =0;
                this.totalQtyTimeSeries = 0;
                this.totalNettoTimeSeries = 0;
                for(let i=0;i<12;i++){
                  let qtyThisMonth = 0;
                  let nettoThisMonth = 0;
                  if(setMonth<12){
                    this.monthListShow.push(this.monthList[setMonth]+' '+(parseInt(this.year)-1));  
                    this.monthIndexShow.push(setMonth+1);
                    for(let j=0;j<this.dataTimeseriesSales.length;j++){
                        let an = this.getQty(this.dataTimeseriesSales[j],(setMonth+1));
                        let net = this.getNetto(this.dataTimeseriesSales[j],(setMonth+1));
                        qtyThisMonth +=parseInt(an);
                        nettoThisMonth += parseInt(net);
                        if(this.dataTimeseriesSales[j]['totalQty']){
                          this.dataTimeseriesSales[j]['totalQty'] += an;
                        }
                        else{
                          this.dataTimeseriesSales[j]['totalQty'] = an;
                        }
                        if(this.dataTimeseriesSales[j]['totalNetto']){
                          this.dataTimeseriesSales[j]['totalNetto'] += net;
                        }
                        else{
                          this.dataTimeseriesSales[j]['totalNetto'] = net;
                        }
                        this.totalQtyTimeSeries += parseInt(an);
                        this.totalNettoTimeSeries += parseInt(net);
                    }
                  }
                  else{
                    this.monthListShow.push(this.monthList[firstMonth]+' '+this.year);
                    this.monthIndexShow.push(firstMonth+1);     
                    for(let j=0;j<this.dataTimeseriesSales.length;j++){
                      let an = this.getQty(this.dataTimeseriesSales[j],(firstMonth+1));
                      let net = this.getNetto(this.dataTimeseriesSales[j],(firstMonth+1));
                      qtyThisMonth +=parseInt(an);
                      nettoThisMonth += parseInt(net);
                      if(this.dataTimeseriesSales[j]['totalQty']){
                        this.dataTimeseriesSales[j]['totalQty'] += an;
                      }
                      else{
                        this.dataTimeseriesSales[j]['totalQty'] = an;
                      }
                      if(this.dataTimeseriesSales[j]['totalNetto']){
                        this.dataTimeseriesSales[j]['totalNetto'] += net;
                      }
                      else{
                        this.dataTimeseriesSales[j]['totalNetto'] = net;
                      }
                      this.totalQtyTimeSeries += parseInt(an);
                      this.totalNettoTimeSeries += parseInt(net);
                  } 
                    firstMonth++;

                  } 
                  this.arrayBottomTimeSeries.push({
                    'qty':qtyThisMonth,
                    'netto':nettoThisMonth
                  });
                  setMonth++;
                }
                this.filterByCounter=true;
              } else {
                this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
              }
            }, (err) => {
              this.utilService.loadingDismiss();
              console.log(err);
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            });
          }
        })
      }
    });
    return await modal.present();
  }  
   
  exportToExcelSummary() {
    /* table id is passed over here */   
   let element = document.getElementById('excel-table-summary');
   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   /* save to file */
   XLSX.writeFile(wb, 'Report Summary Sales '+this.monthList[parseInt(this.month)-1]+'_'+this.year+'.xlsx');
 }
   
  exportToExcelTimeseries() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table-timeseries');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Report Timeseries Sales '+this.monthList[parseInt(this.month)-1]+'_'+this.year+'.xlsx');
  }
  
  toggleReportSummary() {
    if(this.showReportSummary === false) {
      this.showReportSummary = true;
      this.reportSummaryTitle = 'Hide Report';
    } else {
      this.showReportSummary = false;
      this.reportSummaryTitle = 'Show Report';
    }
  }

  toggleReportTimeseries() {
    if(this.showReportTimeseries === false) {
      this.showReportTimeseries = true;
      this.reportTimeseriesTitle = 'Hide Report';
    } else {
      this.showReportTimeseries = false;
      this.reportTimeseriesTitle = 'Show Report';
    }
  }

  getQty(obj: any, month: any): string { //untuk timeseries
    if(month<10){
      month = '0'+month;
    }
    return obj['qty_'+month]
  }
  
  getNetto(obj: any, month: any): string { //untuk timeseries
    if(month<10){
      month = '0'+month;
    }
    return obj['netto_'+month]
  }

  getSummaryDaily(counter_id: any, data: any, trans_date:any,type:any): string {
    let rekapPerx:any;
    
    if((this.rekapPerId=='2' && this.rekapPerId2=='2') ||(this.rekapPerId=='2' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id );
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='3') ||(this.rekapPerId=='3' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id  && x.product_sub_brand_id==data.product_sub_brand_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id  && x.product_category_id==data.product_category_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_brand_id==data.product_brand_id  && x.cashier_id==data.cashier_id);
    }

    else if((this.rekapPerId=='3' && this.rekapPerId2=='3') ||(this.rekapPerId=='3' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_brand_id==data.product_sub_brand_id );
    }
    
    else if((this.rekapPerId=='3' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_category_id==data.product_category_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_brand_id==data.product_sub_brand_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_brand_id==data.product_sub_brand_id  && x.cashier_id==data.cashier_id);
    }

    else if((this.rekapPerId=='4' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_category_id==data.product_category_id );
    }
    
    else if((this.rekapPerId=='4' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_category_id==data.product_category_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_category_id==data.product_category_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_category_id==data.product_category_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_category_id==data.product_category_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_category_id==data.product_category_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_category_id==data.product_category_id  && x.cashier_id==data.cashier_id);
    }
    
    else if((this.rekapPerId=='5' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_category_id==data.product_sub_category_id );
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_category_id==data.product_sub_category_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_category_id==data.product_sub_category_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_category_id==data.product_sub_category_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_category_id==data.product_sub_category_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_category_id==data.product_sub_category_id  && x.cashier_id==data.cashier_id);
    }    
    
    else if((this.rekapPerId=='6' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_type_id==data.product_type_id );
    }    
    else if((this.rekapPerId=='6' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_type_id==data.product_type_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_type_id==data.product_type_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_type_id==data.product_type_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_type_id==data.product_type_id  && x.cashier_id==data.cashier_id);
    }
        
    else if((this.rekapPerId=='7' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='7')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_type_id==data.product_sub_type_id );
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='7')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_type_id==data.product_sub_type_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='7')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_type_id==data.product_sub_type_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='7')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_sub_type_id==data.product_sub_type_id  && x.cashier_id==data.cashier_id);
    }
    
    else if((this.rekapPerId=='8' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='8')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_id==data.product_id );
    }
    else if((this.rekapPerId=='8' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='8')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_id==data.product_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='8' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='8')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.product_id==data.product_id  && x.cashier_id==data.cashier_id);
    }    
    else if((this.rekapPerId=='9' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='9')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.cashier_type_id==data.cashier_type_id );
    }
    else if((this.rekapPerId=='9' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='9')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.cashier_type_id==data.cashier_type_id  && x.cashier_id==data.cashier_id);
    }
    else if((this.rekapPerId=='10' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='10')){
      rekapPerx = this.allDataSummaryDaily.find(
        x => x.counter_id === counter_id && x.trans_date==trans_date && x.cashier_id==data.cashier_id );
    };
    let val = '0';
    if(rekapPerx){
      if(type=='qty'){
      val=rekapPerx.qty;
      }
      else if(type=='netto'){
        val=rekapPerx.netto;
      }
    }
    return val;
  }
  
  getNettoSummaryperCounter(counter_id: any, data: any,type:any): string {
    let rekapPerx:any;
    
    if((this.rekapPerId=='2' && this.rekapPerId2=='2') ||(this.rekapPerId=='2' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id  && x.product_brand_id==data.product_brand_id );
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='3') ||(this.rekapPerId=='3' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_sub_brand_id==data.product_sub_brand_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_category_id==data.product_category_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='2')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.cashier_id==data.cashier_id);
    }

    else if((this.rekapPerId=='3' && this.rekapPerId2=='3') ||(this.rekapPerId=='3' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='3')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id );
    }
    
    else if((this.rekapPerId=='3' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='3')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_category_id==data.product_category_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='3')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='3')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='3')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='3')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='3')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='3')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.cashier_id==data.cashier_id);
    }

    else if((this.rekapPerId=='4' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='4')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id );
    }
    
    else if((this.rekapPerId=='4' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='4')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='4')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='4')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='4')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='4')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='4')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.cashier_id==data.cashier_id);
    }
    
    else if((this.rekapPerId=='5' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='5')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id );
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='5')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='5')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='5')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='5')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='5')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.cashier_id==data.cashier_id);
    }    
    
    else if((this.rekapPerId=='6' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='6')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id );
    }    
    else if((this.rekapPerId=='6' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='6')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='6')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='6')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='6')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id  && x.cashier_id==data.cashier_id);
    }
        
    else if((this.rekapPerId=='7' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='7')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_type_id==data.product_sub_type_id );
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='7')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_type_id==data.product_sub_type_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='7')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_type_id==data.product_sub_type_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='7')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_sub_type_id==data.product_sub_type_id  && x.cashier_id==data.cashier_id);
    }
    
    else if((this.rekapPerId=='8' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='8')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_id==data.product_id );
    }
    else if((this.rekapPerId=='8' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='8')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_id==data.product_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='8' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='8')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.product_id==data.product_id  && x.cashier_id==data.cashier_id);
    }    
    else if((this.rekapPerId=='9' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='9')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.cashier_type_id==data.cashier_type_id );
    }
    else if((this.rekapPerId=='9' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='9')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.cashier_type_id==data.cashier_type_id  && x.cashier_id==data.cashier_id);
    }
    else if((this.rekapPerId=='10' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='10')){
      rekapPerx = this.dataSummarySalesPerItemPerCounter.find(
        x => x.counter_id === counter_id && x.cashier_id==data.cashier_id );
    };
    
    let val = '0';
    if(rekapPerx){
      if(type=='qty'){
      val=rekapPerx.qty;
      }
      else if(type=='netto'){
        val=rekapPerx.netto;
      }
    }
    return val;
  }
  //MONTHLY  
  getSummaryperCounterMonthly(counter_id: any, data: any,type:any): string {
    let rekapPerx:any;
    if((this.rekapPerId=='2' && this.rekapPerId2=='2') ||(this.rekapPerId=='2' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id );
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='3') ||(this.rekapPerId=='3' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_sub_brand_id==data.product_sub_brand_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_category_id==data.product_category_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='2' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='2')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_brand_id==data.product_brand_id  && x.cashier_id==data.cashier_id);
    }

    else if((this.rekapPerId=='3' && this.rekapPerId2=='3') ||(this.rekapPerId=='3' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id );
    }
    
    else if((this.rekapPerId=='3' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_category_id==data.product_category_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='3' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='3')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_brand_id==data.product_sub_brand_id  && x.cashier_id==data.cashier_id);
    }

    else if((this.rekapPerId=='4' && this.rekapPerId2=='4') ||(this.rekapPerId=='4' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id );
    }
    
    else if((this.rekapPerId=='4' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.product_sub_category_id==data.product_sub_category_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='4' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='4')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_category_id==data.product_category_id  && x.cashier_id==data.cashier_id);
    }
    
    else if((this.rekapPerId=='5' && this.rekapPerId2=='5') ||(this.rekapPerId=='5' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id );
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.product_type_id==data.product_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='5' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='5')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_category_id==data.product_sub_category_id  && x.cashier_id==data.cashier_id);
    }    
    
    else if((this.rekapPerId=='6' && this.rekapPerId2=='6') ||(this.rekapPerId=='6' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id );
    }    
    else if((this.rekapPerId=='6' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id  && x.product_sub_type_id==data.product_sub_type_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='6' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='6')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_type_id==data.product_type_id  && x.cashier_id==data.cashier_id);
    }
        
    else if((this.rekapPerId=='7' && this.rekapPerId2=='7') ||(this.rekapPerId=='7' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='7')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_type_id==data.product_sub_type_id );
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='7')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_type_id==data.product_sub_type_id  && x.product_id==data.product_id);
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='7')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_type_id==data.product_sub_type_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='7' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='7')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_sub_type_id==data.product_sub_type_id  && x.cashier_id==data.cashier_id);
    }
    
    else if((this.rekapPerId=='8' && this.rekapPerId2=='8') ||(this.rekapPerId=='8' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='8')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_id==data.product_id );
    }
    else if((this.rekapPerId=='8' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='8')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_id==data.product_id  && x.cashier_type_id==data.cashier_type_id);
    }
    else if((this.rekapPerId=='8' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='8')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.product_id==data.product_id  && x.cashier_id==data.cashier_id);
    }    
    else if((this.rekapPerId=='9' && this.rekapPerId2=='9') ||(this.rekapPerId=='9' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='9')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.cashier_type_id==data.cashier_type_id );
    }
    else if((this.rekapPerId=='9' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='9')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.cashier_type_id==data.cashier_type_id  && x.cashier_id==data.cashier_id);
    }
    else if((this.rekapPerId=='10' && this.rekapPerId2=='10') ||(this.rekapPerId=='10' && this.rekapPerId2=='') ||(this.rekapPerId=='' && this.rekapPerId2=='10')){
      rekapPerx = this.allDataSummaryMonthly.find(
        x => x.counter_id === counter_id && x.cashier_id==data.cashier_id );
    };
    let val = '0';
    if(rekapPerx){
      if(type=='qty'){
      val=rekapPerx.qty;
      }
      else if(type=='netto'){
        val=rekapPerx.netto;
      }
    }
    return val;
  }
}