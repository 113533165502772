import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-master-product',
  templateUrl: './master-product.component.html',
  styleUrls: ['./master-product.component.scss'],
})
export class MasterProductComponent implements OnInit {

  @Input() brandList: any[];
  @Input() typeList: any[];
  @Input() categoryList: any[];

  @Input() brandId: any;
  @Input() typeId: any;
  @Input() categoryId: any;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  async dismissModal(code: any) {
    let dataReturn: any[] = [];
    if(code === 'search') {
      let data = {
        'brand_id': this.brandId,
        'type_id': this.typeId,
        'category_id': this.categoryId
      };

      dataReturn.push(data);
    }

    if(code === 'close') {
      dataReturn = [];
    }
    
    await this.modalController.dismiss(dataReturn);
  }

  clearField() {
    this.brandId = undefined;
    this.typeId = undefined;
    this.categoryId = undefined;
  }

}
