import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ClaimIncentivePageRoutingModule } from './claim-incentive-routing.module';

import { ClaimIncentivePage } from './claim-incentive.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ClaimIncentivePageRoutingModule,
    NgxDatatableModule,
    IonicSelectableModule
  ],
  declarations: [ClaimIncentivePage]
})
export class ClaimIncentivePageModule {}
