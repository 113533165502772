import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { PromotionsService } from '../../promotions/promotions.service';
import { MstPromotionType } from '../../../models/mst-promotion.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-promotion-type-create',
  templateUrl: './promotion-type-create.page.html',
  styleUrls: ['./promotion-type-create.page.scss'],
})
export class PromotionTypeCreatePage implements OnInit {

  token: any;
  formPromotionTypeCreate: FormGroup;
  userProfile: UserProfile = new UserProfile();

  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private promotionService: PromotionsService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormPromotionTypeCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
      }
    });
  }

  buildFormPromotionTypeCreate() {
    this.formPromotionTypeCreate = this.fb.group({
      promotionTypeName: [null, Validators.required]
    });
  }

  inputPromotionType() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const promotionTypeData = this.formPromotionTypeCreate.getRawValue();
      const promotionType = new MstPromotionType();
      promotionType.promotion_type_name = promotionTypeData.promotionTypeName;
      promotionType.created_by = this.userProfile.username;

      this.promotionService.addPromotionType(promotionType).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/promotion-type']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
