import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { StockOpnameService } from '../stock-opname.service';
import { StockOpnameBundle, StockOpnameBundleUpload } from '../../../models/stock-opname.model';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-stock-opname-create',
  templateUrl: './stock-opname-create.page.html',
  styleUrls: ['./stock-opname-create.page.scss'],
})
export class StockOpnameCreatePage implements OnInit {

  @ViewChild('uploadFileInput', { static: false }) uploadFileInput: ElementRef;

  token: any;
  formStockOpnameCreate: FormGroup;
  stockOpnameDetailData: any[] = [];
  warehouseData: any[] = [];
  warehouseListShow:any[]=[];
  counterData: any[] = [];
  userProfile: UserProfile = new UserProfile();
  stockOpnameId: any;
  fileName= 'Stock_Opname.xlsx';

  productBarcode: any;
  inputMethod: any = '1';
  stockOpnameScanList: any[] = [];

  printers: string[];
  isDefaultPrinterSelected = false;
  selectedPrinter: any;
  transDate: any;

  monthList: any[] = ['JAN', 'FEB', 'MAR', 'APR', 'MEI', 'JUN', 'JUL', 'AGU', 'SEP', 'OKT', 'NOV', 'DES'];

  roleAccess = new RoleAccess();
  
  warehouseId :any='';
  counterId :any='';
  selectedWarehouses:any[];
  docDate:any='';
  productPrice:any[]=[];
  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private stockOpnameService: StockOpnameService,
    private userData: UserData,
    private router: Router
  ) {
    // WebSocket settings
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (this.jspmWSStatus()) {
            // get client installed printers
            JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
              this.printers = myPrinters;
              this.isDefaultPrinterSelected = true;
            });
        }
    };
  }

  ngOnInit() {
    this.buildFormStockOpnameCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile); 
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.utilService.loadingPresent('Harap tunggu...')
          .then(() => {
            let options = { 
              "token": this.token,
              "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
            };
            this.stockOpnameService.getStockOpnameforCreate(options).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.warehouseData = response.results.warehouse_data;
              this.warehouseListShow = this.warehouseData;
              this.counterData = response.results.counter_data;
              this.buildFormStockOpnameCreate();
            }, () => {
              this.utilService.loadingDismiss();
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            });
          });
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  jspmWSStatus() { // Check JSPM WebSocket status
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
        alert('JSPM has blocked this website!');
        return false;
    }
  }

  printStockOpname(counterName: string, warehouseName: string, docDate: any) {
    //Create a ClientPrintJob
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    let loginCounterName ='';
    let firstAddress = '';
    let lastAddress = '';
    let phone = '';
    if((this.userProfile.counter_detail)){
      if(this.userProfile.counter_detail.counter_name){
        loginCounterName = this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
      }
      if(this.userProfile.counter_detail.first_address){
        firstAddress = this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address)); 
      }
      if(this.userProfile.counter_detail.last_address){
        lastAddress = this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address)) ;
      }
      if(this.userProfile.counter_detail.phone){
        phone = this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone));
      }
    }
    cmds += loginCounterName;
    cmds += newLine;
    cmds += firstAddress;
    cmds += newLine;
    cmds += lastAddress;
    cmds += newLine;
    cmds += phone;
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += 'NO STOCK OPNAME : ' + this.stockOpnameId;
    cmds += newLine;
    cmds += 'COUNTER         : ' + counterName.toUpperCase();
    cmds += newLine;
    cmds += 'GUDANG          : ' + warehouseName.toUpperCase();
    cmds += newLine;
    cmds += 'TANGGAL         : ' + docDate;
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)

    //Start List Product Stock Opname
    let listProduct: any[] = this.inputMethod === '0' ? this.stockOpnameDetailData : this.stockOpnameScanList;
    
    let totalQp=0;
    let totalQf=0;
    let totalQs=0;
    let totalPriceDiff=0;
    for(let x = 0; x < listProduct.length; x++) {
      let htmlIdQtyPhysic: any = 'qtyPhysic_' + listProduct[x].id;
      let qtyPhysic: any = (<HTMLInputElement>document.getElementById(htmlIdQtyPhysic)).value;
      cmds += this.getLblPCodeAndName(String(listProduct[x].id), String(listProduct[x].product_name));
      cmds += newLine;

      if(parseInt(qtyPhysic)==0 && parseInt(listProduct[x].qty_program)==0){
        //jangan dicetak
      }
      else{
        let qtyDiff = parseInt(qtyPhysic) - parseInt(listProduct[x].qty_program);
        
        totalQp +=parseInt(listProduct[x].qty_program);
        totalQf +=parseInt(qtyPhysic);
        totalQs +=qtyDiff;
        let priceDiff = parseInt(listProduct[x].price) * qtyDiff;
        totalPriceDiff += priceDiff;
        cmds += this.getLblQtyAndPrice(String(qtyPhysic), String(listProduct[x].qty_program), String(qtyDiff), String(listProduct[x].price), String(priceDiff));
        cmds += newLine;
      }
    }
    cmds += '---------------------------------';
    cmds += newLine;
    cmds += 'Total :';
    let formatPrice = this.formatRupiah(totalPriceDiff.toString());
    if(totalPriceDiff<0){
      formatPrice = '-'+formatPrice;
    }
    let diffLength: number = 33 - ('Total  :'.length)-(formatPrice.length);
    for(let s=0;s<diffLength;s++){
      cmds += " ";
    }
    cmds +=String(formatPrice);
    cmds += newLine;
    cmds += newLine;
    let printTotalQf =  this.formatRupiah(totalQf.toString());
    let printTotalQp =  this.formatRupiah(totalQp.toString());
    let printTotalQs =  this.formatRupiah(totalQs.toString());
    let totalQfLength = printTotalQf.length;
    let totalQpLength = printTotalQp.length;
    let totalQsLength = printTotalQs.length;
    let pickupLength = totalQfLength;
    if(totalQpLength >=totalQfLength && totalQpLength >= totalQsLength) {
      pickupLength = totalQpLength;
    }
    else if(totalQsLength >= totalQfLength && totalQsLength >= totalQpLength) {
      pickupLength = totalQsLength;
    }
    let diffQfLength = pickupLength - totalQfLength;
    cmds += 'Total QF :';
    for(let l=0;l<diffQfLength;l++){
      cmds += ' ';
    }
    cmds += printTotalQf;
    cmds += newLine;
    let diffQpLength = pickupLength - totalQpLength;
    cmds += 'Total QP :';
    for(let l=0;l<diffQpLength;l++){
      cmds += ' ';
    }
    cmds += printTotalQp;
    cmds += newLine;
    let diffQsLength = pickupLength - totalQsLength;
    cmds += 'Total QS :';
    for(let l=0;l<diffQsLength;l++){
      cmds += ' ';
    }
    cmds += printTotalQs;
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += newLine;

    cpj.printerCommands = cmds;
    
    //Send print job to printer!
    console.log(cmds);
    cpj.sendToClient();
    this.showConfirmInput();
  }

  getLblPCodeAndName(PCode: String, productName: String): String {
    let lblPCodeAndName: String = '';
    let receiptRowLength: number = 33;

    let combineString: String = PCode + ' ' + productName;
    let stringLength = combineString.length;
    if(stringLength > receiptRowLength) {
      let finalPrpductName = productName.slice(0, productName.length - (stringLength - receiptRowLength));

      lblPCodeAndName = PCode + ' ' + finalPrpductName;
    } else {
      lblPCodeAndName = combineString;
    }

    return lblPCodeAndName;
  }

  getLblQtyAndPrice(qtyPhysic: String, qtyProgram: String, qtyDiff: String, price: String, priceDiff: String): String {
    let lblQtyAndPrice: String = '';
    let lblQtyPhysic: String = '';
    let lblQtyProgram: String = '';
    let lblQtyDiff: String = '';
    let lblPrice: String = '';
    let lblPriceDiff: String = '';
    let receiptRowLength: number = 33;

    let qtyPhysicLength: number = qtyPhysic.length;
    let qtyProgramLength: number = qtyProgram.length;
    let qtyDiffLength: number = qtyDiff.length;
    let priceLength: number = price.length;
    let priceDiffLength: number = priceDiff.length;
    let diffLength: number = receiptRowLength - (qtyPhysicLength + qtyProgramLength + qtyDiffLength + priceLength + priceDiffLength);
    if(diffLength !== 0) {
      let maxLengthQty = 4;
      let maxLengthPrice = 8;

      let forQtyPhysic = maxLengthQty - qtyPhysicLength;
      if(forQtyPhysic !== 0) {
        for(let i = 0; i < forQtyPhysic; i++) {
          lblQtyPhysic = lblQtyPhysic + ' ';
        }
  
        lblQtyPhysic = lblQtyPhysic + '' + qtyPhysic;
      } else {
        lblQtyPhysic = qtyPhysic;
      }

      let forQtyProgram = maxLengthQty - qtyProgramLength;
      if(forQtyProgram !== 0) {
        for(let i = 0; i < forQtyProgram; i++) {
          lblQtyProgram = lblQtyProgram + ' ';
        }
  
        lblQtyProgram = lblQtyProgram + '' + qtyProgram;
      } else {
        lblQtyProgram = qtyProgram;
      }

      let forQtyDiff = maxLengthQty - qtyDiffLength;
      if(forQtyDiff !== 0) {
        for(let i = 0; i < forQtyDiff; i++) {
          lblQtyDiff = lblQtyDiff + ' ';
        }
  
        lblQtyDiff = lblQtyDiff + '' + qtyDiff;
      } else {
        lblQtyDiff = qtyDiff;
      }

      let forPrice = maxLengthPrice - priceLength;
      if(forPrice !== 0) {
        for(let i = 0; i < forPrice; i++) {
          lblPrice = lblPrice + ' ';
        }
  
        lblPrice = lblPrice + '' + price;
      } else {
        lblPrice = price;
      }

      let forPriceDiff = maxLengthPrice - priceDiffLength;
      if(forPriceDiff !== 0) {
        for(let i = 0; i < forPriceDiff; i++) {
          lblPriceDiff = lblPriceDiff + ' ';
        }
  
        lblPriceDiff = lblPriceDiff + '' + priceDiff;
      } else {
        lblPriceDiff = priceDiff;
      }

      lblQtyAndPrice = ' ' + lblQtyPhysic + ' ' + lblQtyProgram + ' ' + lblQtyDiff + ' ' + lblPrice + ' ' + lblPriceDiff;
    } else {
      lblQtyAndPrice = ' ' + qtyPhysic + ' ' + qtyProgram + ' ' + qtyDiff + ' ' + price + ' ' + priceDiff;
    }

    return lblQtyAndPrice;
  }

  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  getStockOpnameDetail() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const stockOpnameForm = this.formStockOpnameCreate.getRawValue();
      let dateConvert = this.utilService.convertDate(stockOpnameForm.docDate);
      let stockField = 'end_'+dateConvert.months; 
      let month = dateConvert.months;
      let year = dateConvert.years;
      
      const formStockOpname = this.formStockOpnameCreate.value;
      let docDateConvert = this.utilService.convertDate(formStockOpname.docDate);
      this.docDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      let options = {
        "token": this.token,
        "warehouse_id": this.warehouseId,
        "doc_date":this.docDate,
        "stock_field": stockField,
        "month": month,
        "year": year,
        "counter_id": this.counterId
      };

      this.stockOpnameService.getStockOpnameDetailProduct(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.stockOpnameDetailData = response.results;
        this.stockOpnameId = response.stock_opname_id;
        this.productPrice = response.product_price;
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  buildFormStockOpnameCreate() {
    this.formStockOpnameCreate = this.fb.group({
      counterId: [this.counterId !='' ? parseInt(this.counterId) : null, Validators.required],
      warehouseId: [this.warehouseId != '' ? parseInt(this.warehouseId) : null, Validators.required],
      docDate: [this.docDate != '' ? this.docDate : null, Validators.required],
      description: [null, Validators.required],
      inputMethod: ['1']
    });
  }

  changeQty(id: any, qtyProgram: any, qtyPhysic: any) {
    let count = parseInt(qtyPhysic) - parseInt(qtyProgram);
    let htmlId = 'qtyDiff_'+id;
    document.getElementById(htmlId).innerHTML = count.toString();
  }

  sameQty(id: any, qtyProgram: any) {
    let htmlId = 'qtyPhysic_'+id;
    (<HTMLInputElement>document.getElementById(htmlId)).value = qtyProgram;
    this.changeQty(id, qtyProgram, qtyProgram);
  }

  getProduct(productBarcode: any) {
    let checkItemBarcode: any[] = this.stockOpnameScanList.find(x => x.barcode === productBarcode);
    if (checkItemBarcode === undefined) {
      let getProduct = this.stockOpnameDetailData.find(x => x.barcode === productBarcode);
      if(getProduct) {
        this.stockOpnameScanList.unshift(getProduct);
      } else {
        let checkItemPcode: any[] = this.stockOpnameDetailData.find(x => x.id === productBarcode);      
          if(checkItemPcode) {
            this.stockOpnameScanList.unshift(checkItemPcode);
          }
          else{
            let message = 'Code tidak valid.';
            this.showAlertBarcodeNotValid(message);
        }
      }
    }
    
    this.productBarcode = "";
    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }

  checkInputMethod() {
    const formStockOpname = this.formStockOpnameCreate.value;
    this.inputMethod = formStockOpname.inputMethod;
  }

  input() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formStockOpname = this.formStockOpnameCreate.value;
      let docDateConvert = this.utilService.convertDate(formStockOpname.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      let arrProductId: any = [];
      let arrQtyPhysic: any = [];
      let arrQtyProgram: any = [];
      let arrPrice: any = [];

      if(this.inputMethod === '0') {
        for(let x = 0; x < this.stockOpnameDetailData.length; x++) {
          let htmlIdQtyPhysic: any = 'qtyPhysic_' + this.stockOpnameDetailData[x].id;
          let qtyPhysic: any = (<HTMLInputElement>document.getElementById(htmlIdQtyPhysic)).value;
  
          arrProductId[x] = this.stockOpnameDetailData[x].id;
          arrQtyPhysic[x] = parseInt(qtyPhysic);
          arrQtyProgram[x] = parseInt(this.stockOpnameDetailData[x].qty_program);
          let getPrice= this.productPrice.find(y => y.product_id === arrProductId[x]);
          if(getPrice){
            this.stockOpnameDetailData[x].price = getPrice.price;
          }
          arrPrice[x] = this.stockOpnameDetailData[x].price;
        }
      }
      if(this.inputMethod === '1') {
        for(let x = 0; x < this.stockOpnameScanList.length; x++) {
          let htmlIdQtyPhysic: any = 'qtyPhysic_' + this.stockOpnameScanList[x].id;
          let qtyPhysic: any = (<HTMLInputElement>document.getElementById(htmlIdQtyPhysic)).value;
  
          arrProductId[x] = this.stockOpnameScanList[x].id;
          arrQtyPhysic[x] = parseInt(qtyPhysic);
          arrQtyProgram[x] = parseInt(this.stockOpnameScanList[x].qty_program);
          let getPrice= this.productPrice.find(y => y.product_id === arrProductId[x]);
          if(getPrice){
            this.stockOpnameScanList[x].price = getPrice.price;
          }
          arrPrice[x] = this.stockOpnameScanList[x].price;
        }
      }

      const stockOpnameBundle = new StockOpnameBundle();
      stockOpnameBundle.stockOpname.counter_id = this.counterId;
      stockOpnameBundle.stockOpname.warehouse_id = this.warehouseId;
      stockOpnameBundle.stockOpname.doc_date = documentDate;
      stockOpnameBundle.stockOpname.desc = formStockOpname.description;
      stockOpnameBundle.stockOpname.status = '0';
      stockOpnameBundle.stockOpname.created_by = this.userProfile.username;

      stockOpnameBundle.stockOpnameDetail.product_id = arrProductId;
      stockOpnameBundle.stockOpnameDetail.qty_real = arrQtyPhysic;
      stockOpnameBundle.stockOpnameDetail.qty_program = arrQtyProgram;
      stockOpnameBundle.stockOpnameDetail.price = arrPrice;
     
      this.stockOpnameService.addStockOpnameBundle(stockOpnameBundle).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          let responseInput = response.results.data;
          this.stockOpnameId = responseInput ? responseInput.id : '0';
          let findCounter = this.counterData.find(x => x.id === parseInt(this.counterId));
          let counterName = findCounter ? findCounter.counter_name : '-';
          let findWarehouse = this.warehouseData.find(x => x.id === parseInt(this.warehouseId));
          let warehouseName = findWarehouse ? findWarehouse.warehouse_name : '-';
          let monthName: any = this.monthList[(parseInt(docDateConvert.months)-1)];
          let transDate = docDateConvert.dates + '-' + monthName + '-' + docDateConvert.years;
          this.printStockOpname(counterName, warehouseName, transDate);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
      
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/stock-opname']);;
          }
        }
      ]
    });

    await alert.present();
  }

  async showAlertBarcodeNotValid(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    });

    await alert.present();
  }

  onFileSelect(event) {
    let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      if (!_.includes(af, file.type)) {
        this.toastCtrl.create({ duration: 2000, message: 'Hanya untuk upload file excel !' }).then(t => t.present());
      }
    }
  }

  uploadFile() {
    if (!this.uploadFileInput.nativeElement.files[0]) {
      alert('Please fill valid details!');
      return false;
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formStockOpname = this.formStockOpnameCreate.value;
      let docDateConvert = this.utilService.convertDate(formStockOpname.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      const formData = new FormData();
      formData.append('id',  this.stockOpnameId);
      formData.append('counter_id', this.counterId);
      formData.append('warehouse_id', this.warehouseId);
      formData.append('doc_date', documentDate);
      formData.append('desc', formStockOpname.description);
      formData.append('status', '0');
      formData.append('created_by', this.userProfile.username);
      formData.append('file', this.uploadFileInput.nativeElement.files[0]);

      this.stockOpnameService.uploadFile(formData).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.uploadFileInput.nativeElement.value = "";
          this.showConfirmInput();
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  getLabelHeaderReceipt(headerText: String) {
    let totalLength: number = headerText.length;
    let maxTextLengthforReceipt = 33;
    let resultText = "";
    if(totalLength > maxTextLengthforReceipt) {
      resultText = headerText.slice(0, maxTextLengthforReceipt);
    } else {
      let diff = maxTextLengthforReceipt - totalLength;
      let divide = (diff - (diff % 2)) / 2;
      let space = "";
      for(let i = 0; i < divide; i++) {
        space = space + " ";
      }
      
      resultText = space + headerText + space;
    }

    return resultText;
  }
  
  public selectWarehouse(event){
    this.warehouseId = event.value.id;
  }
    
  public selectCounter(event){
    this.counterId = event.value.id;
    this.warehouseId ='';
    this.selectedWarehouses=[];
    let filterWarehouse = this.warehouseData.filter(x => parseInt(x.counter_id) === parseInt(this.counterId));
    this.warehouseListShow = filterWarehouse;  
  }

  private formatRupiah(angka: string) {
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
    // return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }
}
