import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModalKasirPage } from './modal-kasir.page';

const routes: Routes = [
  {
    path: '',
    component: ModalKasirPage
  },
  {
    path: 'modal-kasir-create',
    loadChildren: () => import('./modal-kasir-create/modal-kasir-create.module').then( m => m.ModalKasirCreatePageModule)
  },
  {
    path: 'modal-kasir-edit',
    loadChildren: () => import('./modal-kasir-edit/modal-kasir-edit.module').then( m => m.ModalKasirEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalKasirPageRoutingModule {}
