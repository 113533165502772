import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OtherReceivingPage } from './other-receiving.page';

const routes: Routes = [
  {
    path: '',
    component: OtherReceivingPage
  },
  {
    path: 'other-receiving-create',
    loadChildren: () => import('./other-receiving-create/other-receiving-create.module').then( m => m.OtherReceivingCreatePageModule)
  },
  {
    path: 'other-receiving-edit',
    loadChildren: () => import('./other-receiving-edit/other-receiving-edit.module').then( m => m.OtherReceivingEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OtherReceivingPageRoutingModule {}
