import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable, observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimInsentifResellerService {

  constructor(private httpService: HttpService) { }
  generate(data: any): Observable<any> {
    return this.httpService.post('generate-claim-insentif', data);
  }
}
