import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { RoleAccess } from '../../../models/role-access.model';
import { UserProfile } from '../../../models/user-profile.model';
import { UserData } from '../../../providers/user-data';
import { UtilService } from '../../../service/util.service';
import { SetupApprovalService } from '../setup-approval.service';
import { SetupApprovalComponent } from '../../../component/setup-approval/setup-approval.component';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-setup-approval-edit',
  templateUrl: './setup-approval-edit.page.html',
  styleUrls: ['./setup-approval-edit.page.scss'],
})
export class SetupApprovalEditPage implements OnInit {

  module: any;  
  formSetupApprovalEdit: FormGroup;
  token: any;
  setupApprovalData: any;
  setupApprovalDetailData:any;
  rows:any[];
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  countLevel:number=0;
  saveLevel : number = 0;
  saveButton :boolean = false;
  no:number=1;
  constructor(
    private activeRoute: ActivatedRoute,    
    private fb: FormBuilder,
    private utilService: UtilService,
    private setupApprovalService: SetupApprovalService,
    private toastCtrl: ToastController,    
    private navCtrl: NavController,
    private modalController: ModalController,
    private alertController: AlertController,
    private storage: Storage,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    
    this.buildFormSetupApprovalEdit();
    
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.geSetupApprovalforEdit();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  
  buildFormSetupApprovalEdit() {
    this.formSetupApprovalEdit = this.fb.group({
      moduleName: [null]
    });
  }

  geSetupApprovalforEdit() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.module = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.setupApprovalService.getSetupApprovalforEdit(this.module, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.setupApprovalData = response.results.data_header;
          this.rows = response.results.data_detail;
          this.no = this.rows.length;
          if(this.no>0){
            for(let i=0;i<this.no;i++){
              this.rows[i].no=(i+1);
            }
          }
          this.countLevel = this.rows.length;
          this.saveLevel = this.countLevel;
          if(this.setupApprovalData) {
            this.formSetupApprovalEdit = this.fb.group({
              moduleName: [this.setupApprovalData.module]
            });
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    })
  }

  async changeApproval(approvalData: any) {
    let userId ='0';
    let approvalName ='';
    let approvalLevel = this.module;
    let no =this.no;
    if(approvalData!='0'){
      userId = approvalData.user_id;
      approvalName = approvalData.name;
      approvalLevel = approvalData.level;
      no=approvalData.no;
    }
    else{
      approvalLevel=1;
    }

    const modal = await this.modalController.create({
      component: SetupApprovalComponent,
      componentProps: {
        'userId': userId,
        'approvalName': approvalName,
        'approvalLevel': approvalLevel,
        'no': no
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data.user;
      if(data){
        let action =data.action;
        let checkUser: any[] = this.rows.find(x => x.no === data.no);
        if (checkUser !== undefined && action !='0') {
          let index: number = this.rows.indexOf(checkUser);
          this.rows[index]['name'] = data.name;
          this.rows[index]['user_id'] = data.user_id;
          this.rows[index]['level'] = data.level;
          this.saveButton = true;
        }
        else{
          this.countLevel = this.countLevel + 1;
          let addData = {
            'module_id':this.module,
            'level':data.level,
            'user_id': data.user_id,
            'no': this.no+1,
            'name':data.name,
            'id':0
          };
          this.rows.push(addData);
          this.rows = [...this.rows];
          this.saveButton = true;
        }
      }
      this.utilService.loadingDismiss();
    });
    return await modal.present();
  }
  updateApproval(){
    let options = {
      "token": this.token,
      "module": this.module,
      "username": this.userProfile.username,
      "details" :this.rows
    };
    this.setupApprovalService.updateSetupApproval(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      if(response.status.code === 200) {
        this.showConfirmUpdate();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
      }
    }, (err) => {
      this.utilService.loadingDismiss();
      if(err.error.error === 'token_expired') {
        this.userData.logout().then(() => {
          this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
          this.navCtrl.navigateForward(['/login']);
        });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      }
    });
  }  

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/setup-approval']);;
          }
        }
      ]
    });
    await alert.present();
  }
  
  async showConfirmDelete(id: any) {
    const alert = await this.alertController.create({
      header: 'Delete Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk hapus data ini?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: () => {
            this.deleteData(id);
          }
        }
      ]
    });

    await alert.present();
  }
  
  deleteData(id: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.setupApprovalService.deleteApproval(id).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.navCtrl.navigateForward(['/setup-approval']);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }


}
