import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UserProfile } from '../../../../models/user-profile.model';
import { UserData } from '../../../../providers/user-data';
import { UtilService } from '../../../../service/util.service';
import { environment } from '../../../../../environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-summary-transaction-reseller',
  templateUrl: './report-summary-transaction-reseller.page.html',
  styleUrls: ['./report-summary-transaction-reseller.page.scss'],
})
export class ReportSummaryTransactionResellerPage implements OnInit {    
    page = {
      limit: 10,
      count: 0,
      offset: 0,
      orderBy: '',
      orderDir: 'desc'
    };
    periode: any= new Date().toISOString();
    totalBelanja: any='all';
    baseUrl: any;
    rows: any;
    token: any;
    userProfile: UserProfile = new UserProfile();
    customerList: any;
    filteredData: any;
    keywordSearch: any;
    year: any;
    month: any;
    minTrans:any=0;
    maxTrans:any=0;
    
    periodStart: any;
    periodEnd: any;
    dataResellerTransactions:any=[];
    constructor(
      private storage: Storage,
      private http: HttpClient,
      private navCtrl: NavController,
      private alertController: AlertController,
      private utilService: UtilService,
      private toastCtrl: ToastController,
      private userData: UserData
  
    ) { 
      this.baseUrl = environment.apiUrl;
    }
  
    ngOnInit() {
    }
  
    ionViewDidEnter() {
      Promise.all([
        this.storage.get('user_token'),
        this.storage.get('user_profile')
      ])
      .then(([token, profile]) => {
        if(token) {
          this.token = token;
          this.userProfile = new UserProfile(profile);
          let todayDate = new Date();
          let convertDateStart = this.periodStart ? this.utilService.convertDate(this.periodStart) : this.utilService.convertDate(todayDate);
          let convertDateEnd = this.periodEnd ? this.utilService.convertDate(this.periodEnd) : this.utilService.convertDate(todayDate);
          let startDate = convertDateStart.years + '-' + convertDateStart.months + '-' + convertDateStart.dates;
          let endDate = convertDateEnd.years + '-' + convertDateEnd.months + '-' + convertDateEnd.dates;
          this.periodStart = startDate;
          this.periodEnd = endDate;

        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
          this.navCtrl.navigateForward(['/login']);
        }
      });
    }
  
    pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
      this.page.offset = pageInfo.offset;
      this.reloadTable();
      console.log('pageCallback');
    }
    
    sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
      this.page.orderDir = sortInfo.sorts[0].dir;
      this.page.orderBy = sortInfo.sorts[0].prop;
      this.reloadTable();
      console.log('sortCallback');
    }
  
    reloadTable() {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let offset = this.page.offset + 1;
          let dateConvert = this.utilService.convertDate(this.periode);
          this.year = dateConvert.years;
          this.month = dateConvert.months;
          this.dataResellerTransactions = [];
          this.rows =[];
          let todayDate = new Date();
          let convertDateStart = this.periodStart ? this.utilService.convertDate(this.periodStart) : this.utilService.convertDate(todayDate);
          let convertDateEnd = this.periodEnd ? this.utilService.convertDate(this.periodEnd) : this.utilService.convertDate(todayDate);
          let startDate = convertDateStart.years + '-' + convertDateStart.months + '-' + convertDateStart.dates;
          let endDate = convertDateEnd.years + '-' + convertDateEnd.months + '-' + convertDateEnd.dates;
          this.periodStart = startDate;
          this.periodEnd = endDate;

          const params = new HttpParams()
          .set('token', this.token)
          .set('year', this.year)
          .set('month', this.month)
          .set('keywords', '')
          .set('start_date', this.periodStart)
          .set('end_date', this.periodEnd)
          .set('minimum_total_belanja', this.minTrans)
          .set('maximum_total_belanja', this.maxTrans)
          .set('page', offset.toString());
  
        this.http.get(this.baseUrl + 'summary-transaction-reseller', { params })
        .subscribe((response: any) => {
          this.page.count = response.results.total;
          this.rows = response.results.data;
          this.dataResellerTransactions = response.data_excel;
          this.filteredData = response.results.data;
          this.customerList = response.results.data;
          this.utilService.loadingDismiss();
        }, (err) => {
          this.utilService.loadingDismiss();
          console.log(err);
          if(err.error.error === 'token_expired') {
            this.userData.logout().then(() => {
              this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
              this.navCtrl.navigateForward(['/login']);
            });
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          }
        });
      });
    }
  
    async showDeleteSuccess() {
      const alert = await this.alertController.create({
        header: 'Notification',
        cssClass:'custom-alert-class',
        message: 'Berhasil hapus data!',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.pageCallback({ offset: this.page.offset });
            }
          }
        ]
      });
  
      await alert.present();
    }
  
    filterDatatable(event) {
      let val = event.target.value.toLowerCase();
      let columnLength = 5;
      let keys = Object.keys(this.customerList[0]);
      this.rows = this.filteredData.filter(function(item){
        console.log('item', item);
        for (let i=0; i < columnLength; i++){
          if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
            return true;
          }
        }
      });
  
      this.page.offset = 0;
    }

    generate(){
      this.reloadTable();

    }

    exportToExcel() {
      /* table id is passed over here */   
      let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, 'Report Summary Transaction.xlsx');
    }
    
  
  }
  
