import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ProductReceivingService } from '../product-receiving.service';
import { ProductReceiving } from '../../../models/product-receiving.model';
import { ApprovalHistory } from '../../../models/approval-history.model';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import { ProductReceivingClosed } from '../../../models/product-receiving-closed.model';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-product-receiving-detail',
  templateUrl: './product-receiving-detail.page.html',
  styleUrls: ['./product-receiving-detail.page.scss'],
})
export class ProductReceivingDetailPage implements OnInit {

  productReceivingId: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  warehouseList: any[] = [];
  productReceivingData: any;
  productReceivingDetailData: any[] = [];
  approvalHistoryData: any[] = [];
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;
  unitType: any[] = ['PIECES'];
  statusName: any;
  productRequestDetailList: any[] = [];

  formProductReceivingDetail: FormGroup;

  readonlyVal: boolean = false;

  userAccess: any[] = [];
  roleAccess = new RoleAccess();
  roleName: any;

  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private productReceivingService: ProductReceivingService,
    private router: Router,
    private userDataProvider: UserData
  ) { }

  ngOnInit() {
    this.buildFormProductReceivingDetail();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userAccess = access;
        this.roleName = this.userProfile.role_detail ? this.userProfile.role_detail.role_name : null;
        this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormProductReceivingDetail() {
    this.formProductReceivingDetail = this.fb.group({
      productReceivingId: [this.productReceivingData ? this.productReceivingData[0].id : null],
      productRequestId: [this.productReceivingData ? this.productReceivingData[0].product_request_id : null],
      counterId: [this.productReceivingData ? this.productReceivingData[0].counter_id : null],
      warehouseId: [this.productReceivingData ? this.productReceivingData[0].warehouse_id : null],
      docDate: [this.productReceivingData ? this.productReceivingData[0].doc_date : null],
      courier: [this.productReceivingData ? this.productReceivingData[0].courier : null],
      description: [this.productReceivingData ? this.productReceivingData[0].desc : null],
      proposalNumber: [this.productReceivingData ? this.productReceivingData[0].proposal_number : null],
      status: [this.statusName ? this.statusName : null]
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.productReceivingId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.productReceivingId = this.productReceivingId.replaceAll('"','');
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let options = {
          "token": this.token,
          "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
        };

        this.productReceivingService.getProductReceivingbyId(this.productReceivingId, options)
        .subscribe((response: any) => {
          this.utilService.loadingDismiss();
          this.productReceivingData = response.results.product_receiving_data;
          this.productReceivingDetailData = response.results.product_receiving_detail_data;
          this.counterList = response.results.counter_data;
          this.warehouseList = response.results.warehouse_data;

          let userCreate: any = response.results.user_create_data;
          this.createdBy = userCreate ? userCreate[0].name : null;
          let userUpdate: any = response.results.user_update_data;
          this.updatedBy = userUpdate ? userUpdate[0].name : null;
          this.approvalHistoryData = response.results.approval_history_data;

          if(this.productReceivingData.length > 0) {
            this.createdAt = this.productReceivingData[0].created_at;
            this.updatedAt = this.productReceivingData[0].updated_at;
            if(this.productReceivingData[0].status === '0') { this.statusName = 'Open'; }
            if(this.productReceivingData[0].status === '1') { this.statusName = 'Closed'; }
            if(this.productReceivingData[0].status === '2') { this.statusName = 'Void'; }

            this.buildFormProductReceivingDetail();
          }

          if(this.productReceivingDetailData.length > 0) {
            this.productRequestDetailList = [];
            for(let i = 0; i < this.productReceivingDetailData.length; i++) {
              this.productRequestDetailList.push({
                'id' : i + 1,
                'product_id' : this.productReceivingDetailData[i].product_id,
                'product_name' : this.productReceivingDetailData[i].product_name,
                'qty_request': this.productReceivingDetailData[i].qty_request,
                'qty_receive': this.productReceivingDetailData[i].qty,
                'description': this.productReceivingDetailData[i].descriptions,
                'unit': 'Pcs'
              });
            }
          }
           else {
            this.productRequestDetailList = [];
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateData() {
    //
  }

  closedDataBundle() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formProductReceive = this.formProductReceivingDetail.value;

      let converDoctDate = this.utilService.convertDate(formProductReceive.docDate);
      let docDate = converDoctDate.years + '-' + converDoctDate.months + '-' + converDoctDate.dates;

      let arrWarehouseId: any[] = [];
      let arrTransactionId: any[] = [];
      let arrStockMutationTypeId: any[] = [];
      let arrProductId: any[] = [];
      let arrQty: any[] = [];
      let arrValue: any[] = [];
      let arrStockMove: any[] = [];
      let arrTransDate: any[] = [];

      for(let x = 0; x < this.productRequestDetailList.length; x++) {
        arrWarehouseId[x] = formProductReceive.warehouseId;
        arrTransactionId[x] = formProductReceive.productReceivingId;
        arrStockMutationTypeId[x] = 'RG'; // RG = RECEIPT GOOD
        arrProductId[x] = this.productRequestDetailList[x].product_id;
        arrQty[x] = this.productRequestDetailList[x].qty_receive;
        arrValue[x] = 0;
        arrStockMove[x] = 'I';
        arrTransDate[x] = docDate;
      }

      let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
      let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
      let convertDate = this.utilService.convertDate(new Date());
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

      const productReceivingClosed = new ProductReceivingClosed();
      productReceivingClosed.productReceiving.id = formProductReceive.productReceivingId;
      productReceivingClosed.productReceiving.status = '1';
      productReceivingClosed.productReceiving.updated_by = this.userProfile.username;

      productReceivingClosed.stockMutation.warehouse_id = arrWarehouseId;
      productReceivingClosed.stockMutation.transaction_id = arrTransactionId;
      productReceivingClosed.stockMutation.stock_mutation_type_id = arrStockMutationTypeId;
      productReceivingClosed.stockMutation.product_id = arrProductId;
      productReceivingClosed.stockMutation.qty = arrQty;
      productReceivingClosed.stockMutation.value = arrValue;
      productReceivingClosed.stockMutation.stock_move = arrStockMove;
      productReceivingClosed.stockMutation.trans_date = arrTransDate;
      
      productReceivingClosed.approvalHistory.transaction_id = this.productReceivingId;
      productReceivingClosed.approvalHistory.username = this.userProfile.username;
      productReceivingClosed.approvalHistory.status = '1';
      productReceivingClosed.approvalHistory.trans_date = transDate + ' ' + transTime;

      this.productReceivingService.closedReceivingBundle(productReceivingClosed)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  closeData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let newDate = new Date();
      let convertDate = this.utilService.convertDate(newDate);

      const formProductReceive = this.formProductReceivingDetail.value;
      const formProductReceivingDetail = this.formProductReceivingDetail.value;
      let mutationType: any = 'I';

      let arrYear: any[] = [];
      let arrMonth: any[] = [];
      let arrWarehouseId: any[] = [];
      let arrProductId: any[] = [];
      let arrMutationType: any[] = [];
      let arrQty: any[] = [];
      let arrValue: any[] = [];

      for(let x = 0; x < formProductReceivingDetail.detail.length; x++) {
        arrYear[x] = convertDate.years;
        arrMonth[x] = convertDate.months;
        arrWarehouseId[x] = formProductReceive.warehouseId;
        arrProductId[x] = formProductReceivingDetail.detail[x].productId;
        arrMutationType[x] = mutationType;
        arrQty[x] = formProductReceivingDetail.detail[x].qtyReceive;
        arrValue[x] = 0;
      }

      let options = {
        "token": this.token,
        "year": arrYear,
        "month": arrMonth,
        "warehouse_id": arrWarehouseId,
        "product_id": arrProductId,
        "mutation_type": arrMutationType,
        "qty": arrQty,
        "value": arrValue
      };

      this.productReceivingService.manageStock(options).subscribe((response) => {
        if(response.status.code === 201) {
          const productReceivingData = new ProductReceiving();
          productReceivingData.status = '1';
          productReceivingData.updated_by = this.userProfile.username;

          this.productReceivingService.updateProductReceiving(this.productReceivingId, productReceivingData).subscribe((response) => {
            this.utilService.loadingDismiss();
            if(response.status.code === 201) {
              // this.showConfirmUpdate();
              this.saveApprovalHistory('1');
            } else {
              this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
            }
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        } else {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  saveApprovalHistory(status: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
      let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
      let convertDate = this.utilService.convertDate(new Date());
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

      const approvalHistory = new ApprovalHistory();
      approvalHistory.transaction_id = this.productReceivingId;
      approvalHistory.username = this.userProfile.username;
      approvalHistory.status = status;
      approvalHistory.trans_date = transDate + ' ' + transTime;

      this.productReceivingService.addApprovalHistory(approvalHistory).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/product-receiving']);;
          }
        }
      ]
    });

    await alert.present();
  }

  /*get getDetailArray() {
    return (<FormArray>this.formProductReceivingDetail.get('detail'));
  }
  
  openDB() {
    this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  getCounterList() {
    let selectQuery = 'SELECT * FROM mst_counters';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.counterList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let counterData = {
                'id': result.rows[x].id,
                'counter_name': result.rows[x].counter_name
              };
              this.counterList.push(counterData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getWarehouseList() {
    let selectQuery = 'SELECT * FROM mst_warehouses';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.warehouseList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let warehouseData = {
                'id': result.rows[x].id,
                'warehouse_name': result.rows[x].warehouse_name
              };
              this.warehouseList.push(warehouseData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }*/

}
