

import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../report.service';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../../service/util.service';
import { Storage } from '@ionic/storage';
import * as XLSX from 'xlsx';
import { RoleAccess } from '../../../../models/role-access.model';
import { UserData } from '../../../../providers/user-data';
import { Router } from '@angular/router';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportSummaryStockComponent } from '../../../../component/report-summary-stock/report-summary-stock.component';


@Component({
  selector: 'app-report-summary-stock',
  templateUrl: './report-summary-stock.page.html',
  styleUrls: ['./report-summary-stock.page.scss'],
})
export class ReportSummaryStockPage implements OnInit {
  dataSummaryStock:any[]=[];
  year : any=(new Date()).getFullYear();
  month: any=(new Date()).getMonth();
  token : any;  
  periodeReport:any = '';
  periodeStart:any = new Date().toISOString();
  periodeEnd:any = new Date().toISOString();
  colspanTotal:number=1;
  totalQtySummary:number= 0;
  totalNettoSummary:number=0

  arrayBottom:any= [];
  qtyEarlyAllDay:number=0;
  qtyInAllDay:number=0;
  qtyOutAllDay:number=0;
  qtyEndAllDay:number=0;
  
  roleAccess = new RoleAccess();
  userProfile: UserProfile = new UserProfile();
  
  monthList :any[]=['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'];
  
  branchList:any;
  counterList:any;
  productList:any;
  brandList:any;
  subBrandList:any;
  categoryList:any;
  subCategoryList:any;
  typeList:any;
  subTypeList:any;

  counterByLogin : any;
  //filter
  startPeriod:any='';
  endPeriod:any='';
  rekapPerId:any='';
  rekapPerId2:any='';
  rekapPerList:any=[];
  rekapPerName:any='';
  filterCounterName:any='';
  filterBrandName:any='';
  filterSubBrandName:any='';
  filterTypeName:any='';
  filterSubTypeName:any='';
  filterCategoryName:any='';
  filterSubCategoryName:any='';

  showTitleSummary:boolean=false;
  showReportSummary:boolean=false;
  showReportStock:boolean=true;
  reportTitle:any='Hide Report';

  //filter kolom result
  filterByProduct:boolean=false;
  filterByCounter:boolean=false;
  filterByBrand:boolean=false;
  filterBySubBrand:boolean=false;
  filterByType:boolean=false;
  filterBySubType:boolean=false;
  filterByCategory:boolean=false;
  filterBySubCategory:boolean=false;

  // di send ke api untuk generate report
  paramCounter = '';
  paramBrand = '';
  paramSubBrand = '';
  paramType = '';
  paramSubType = '';
  paramCategory = '';
  paramSubCategory = '';

  arrayDate:any[]=[];
  constructor(
    private reportService :ReportService,
    private toastCtrl : ToastController,
    private utilService :UtilService,
    private storage: Storage,
    private userData: UserData,
    private navCtrl: NavController,
    private router: Router,
    private modalController :ModalController
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        
        this.rekapPerList = [
          {'name':'Counter','id':1},
          {'name':'Brand','id':2},
          {'name':'Sub Brand','id':3},
          {'name':'Category','id':4},
          {'name':'Sub Category','id':5},
          {'name':'Type','id':6},
          {'name':'Sub Type','id':7},
          {'name':'Product','id':8},
        ];
        
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {     
          this.userProfile = new UserProfile(profile);
          this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": this.counterByLogin
          };
          this.reportService.getReportSummaryIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.branchList = response.results.branch_data;
            this.counterList = response.results.counter_data;
            this.brandList = response.results.brand_data;
            this.subBrandList = response.results.data_sub_brand;
            this.categoryList = response.results.data_category;
            this.subCategoryList = response.results.data_sub_category;
            this.typeList = response.results.data_type;
            this.subTypeList = response.results.data_sub_type;
          });
        });
      }
    });
  }
  
  
  getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(currentDate)
      var dat = new Date(currentDate)
      dat.setDate(dat.getDate() + 1);
      currentDate = dat;
    }
    return dateArray;
  }

  resetfilter(){
    this.showTitleSummary = false;
    this.dataSummaryStock = [];
    this.rekapPerName ='';
    this.rekapPerId ='';
    this.rekapPerId2 ='';
    this.filterCounterName = '';
    this.filterBrandName = '';
    this.filterSubBrandName = '';
    this.filterTypeName = '';
    this.filterSubTypeName = '';
    this.filterCategoryName = '';
    this.filterSubCategoryName = '';
    this.filterByProduct=false;
    this.filterByCounter= false;
    this.filterByBrand= false;
    this.filterBySubBrand= false;
    this.filterByType= false;
    this.filterBySubType= false;
    this.filterByCategory= false;
    this.filterBySubCategory= false;
  }
  
  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ReportSummaryStockComponent,
      componentProps: {
        periodStart:this.periodeStart,
        periodEnd:this.periodeEnd,
        rekapPer:this.rekapPerId,
        rekapPer2:this.rekapPerId2,
        branchList:this.branchList,
        counterList: this.counterList,
        productList: this.productList,
        brandList: this.brandList,
        subBrandList: this.subBrandList,
        categoryList: this.categoryList,
        subCategoryList: this.subCategoryList,
        typeList: this.typeList,
        subTypeList: this.subTypeList
      },
      backdropDismiss: false
    });
    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if (data !== 'null') {
        console.log(data);
        this.resetfilter();
        
        let rekapPer = this.rekapPerList.find(x => x.id === data[0]['rekapPer']);
        let rekapPer2 = this.rekapPerList.find(x => x.id === data[0]['rekapPer2']);
        if(data[0]['rekapPer']=='1' || data[0]['rekapPer2']=='1'){
          this.filterByCounter=true;
        }
        if(data[0]['rekapPer']=='2' || data[0]['rekapPer2']=='2'){
          this.filterByBrand=true;
        }
        if(data[0]['rekapPer']=='3' || data[0]['rekapPer2']=='3'){
          this.filterBySubBrand=true;
        }
        if(data[0]['rekapPer']=='4' || data[0]['rekapPer2']=='4'){
          this.filterByCategory=true;
        }
        if(data[0]['rekapPer']=='5' || data[0]['rekapPer2']=='5'){
          this.filterBySubCategory=true;
        }
        if(data[0]['rekapPer']=='6' || data[0]['rekapPer2']=='6'){
          this.filterByType=true;
        }
        if(data[0]['rekapPer']=='7' || data[0]['rekapPer2']=='7'){
          this.filterBySubType=true;
        }
        if(data[0]['rekapPer']=='8' || data[0]['rekapPer2']=='8'){
          this.filterByProduct=true;
        }
        
        let arrCounter= [];
       
        if(data[0]['counterId']!=''){
          arrCounter= data[0]['counterId'].split(',');
        }
        if(arrCounter.length>0){
          for(let i=0;i<arrCounter.length;i++){
            let filterCounter = this.counterList.find(x => x.id == arrCounter[i]);
            this.filterCounterName += filterCounter.counter_name+',';           
          }
        }

        let arrBrand= [];
       
        if(data[0]['brandId']!=''){
          arrBrand= data[0]['brandId'].split(',');
        }
        let arrBrand2=  [];
        if(data[0]['brandId2']!=''){
          arrBrand2= data[0]['brandId2'].split(',');
        }
        if(arrBrand.length>0){
          for(let i=0;i<arrBrand.length;i++){
            let filterBrand = this.brandList.find(x => x.id == arrBrand[i]);
            this.filterBrandName += filterBrand.product_brand_name+',';           
          }
        }

        if(arrBrand2.length>0){
          for(let i=0;i<arrBrand2.length;i++){
            let filterBrand = this.brandList.find(x => x.id == arrBrand2[i]);
            this.filterBrandName += filterBrand.product_brand_name+',';
          }
        }

        let arrSubBrand= [];
        if(data[0]['subBrandId']!=''){
          arrSubBrand = data[0]['subBrandId'].split(',');
        }
        let arrSubBrand2 = [];
        if(data[0]['subBrandId2']!=''){
          arrSubBrand2 = data[0]['subBrandId2'].split(',');
        }
        if(arrSubBrand.length>0){
          for(let i=0;i<arrSubBrand.length;i++){
            let filterSubBrand = this.subBrandList.find(x => x.id == arrSubBrand[i]);
            this.filterSubBrandName += filterSubBrand.product_sub_brand_name+',';
          }
        }
        if(arrSubBrand2.length>0){
          for(let i=0;i<arrSubBrand2.length;i++){
            let filterSubBrand = this.subBrandList.find(x => parseInt(x.id) === parseInt(arrSubBrand2[i]));
            this.filterSubBrandName += filterSubBrand.product_sub_brand_name+',';
          }
        }

        let arrType= [];
        if(data[0]['typeId']!=''){
          arrType= data[0]['typeId'].split(',');
        }
        let arrType2=  [];
        if(data[0]['typeId2']!=''){
          arrType2= data[0]['typeId2'].split(',');
        }
        if(arrType.length>0){
          for(let i=0;i<arrType.length;i++){
            let filterType = this.typeList.find(x => parseInt(x.id) === parseInt(arrType[i]));
            this.filterTypeName += filterType.product_type_name+',';
          }
        }
        if(arrType2.length>0){
          for(let i=0;i<arrType2.length;i++){
            let filterType = this.typeList.find(x => parseInt(x.id) === parseInt(arrType2[i]));
            this.filterTypeName += filterType.product_type_name+',';
          }
        }

        let arrSubType= [];
        if(data[0]['subTypeId']!=''){
          arrSubType= data[0]['subTypeId'].split(',');
        }
        let arrSubType2=  [];
        if(data[0]['subTypeId2']!=''){
          arrSubType2= data[0]['subTypeId2'].split(',');
        }
        if(arrSubType.length>0){
          for(let i=0;i<arrSubType.length;i++){
            let filterSubType = this.subTypeList.find(x => parseInt(x.id) === parseInt(arrSubType[i]));
            this.filterSubTypeName += filterSubType.product_sub_type_name+',';
          }
        }
        if(arrSubType2.length>0){
          for(let i=0;i<arrSubType2.length;i++){
            let filterSubType = this.subTypeList.find(x => parseInt(x.id) === parseInt(arrSubType2[i]));
            this.filterSubTypeName += filterSubType.product_sub_type_name+',';
          }
        }

        let arrCategory= [];
        if(data[0]['categoryId']!=''){
          arrCategory= data[0]['categoryId'].split(',');
        }
        let arrCategory2=  [];
        if(data[0]['categoryId2']!=''){
          arrCategory2= data[0]['categoryId2'].split(',');
        }
        if(arrCategory.length>0){
          for(let i=0;i<arrCategory.length;i++){
            let filterCategory = this.categoryList.find(x => x.id == arrCategory[i]);
            this.filterCategoryName += filterCategory.product_category_name+',';
          }
        }
        if(arrCategory2.length>0){
          for(let i=0;i<arrCategory2.length;i++){
            let filterCategory = this.categoryList.find(x => x.id == arrCategory2[i]);
            this.filterCategoryName += filterCategory.product_category_name+',';
          }
        }

        let arrSubCategory= [];
        if(data[0]['subCategoryId']!=''){
          arrSubCategory= data[0]['subCategoryId'].split(',');
        }
        let arrSubCategory2=  [];
        if(data[0]['subCategoryId2']!=''){
          arrSubCategory2= data[0]['subCategoryId2'].split(',');
        }
        if(arrSubCategory.length>0){
          for(let i=0;i<arrSubCategory.length;i++){
            let filterSubCategory = this.subCategoryList.find(x => x.id == arrSubCategory[i]);
            this.filterSubCategoryName += filterSubCategory.product_sub_category_name+',';
          }
        }
        if(arrSubCategory2.length>0){
          for(let i=0;i<arrSubCategory2.length;i++){
            let filterSubCategory = this.subCategoryList.find(x => x.id == arrSubCategory2[i]);
            this.filterSubCategoryName += filterSubCategory.product_sub_category_name+',';
          }
        }

        if(rekapPer){
          this.rekapPerName += rekapPer.name+',';
          this.rekapPerId =rekapPer.id;
        }
        if(rekapPer2){
          this.rekapPerName += rekapPer2.name;
          this.rekapPerId2 =rekapPer2.id;

        }
        if(data[0]['periodStart']!=''){
         
          this.periodeStart= data[0]['periodStart'];
          let periodeConvert = this.utilService.convertDate(this.periodeStart);
          this.year = periodeConvert.years;
          this.month = periodeConvert.months;
          let startDate = periodeConvert.dates;
          this.startPeriod = this.year+"-"+this.month+"-"+startDate;
          
          this.periodeReport =this.startPeriod;
        }
        if(data[0]['periodEnd']!=''){
          let periodeEndConvert = this.utilService.convertDate(data[0]['periodEnd']);
          let endYear = periodeEndConvert.years;
          let endMonth = periodeEndConvert.months;
          let endDate = periodeEndConvert.dates;
          this.endPeriod = endYear+"-"+endMonth+"-"+endDate;
          if(this.endPeriod!=this.startPeriod){
            this.periodeReport += ' s/d '+this.endPeriod;
          }
        }
        
        this.paramCounter ='';
        if(data[0]['counterId']!=''){
          this.paramCounter +=  data[0]['counterId']+ ',';
        }
        this.paramCounter +=  data[0]['counterId2'];


        this.paramBrand ='';
        if(data[0]['brandId']!=''){
          this.paramBrand +=  data[0]['brandId']+ ',';
        }
        this.paramBrand +=  data[0]['brandId2'];

        this.paramSubBrand ='';
        if(data[0]['subBrandId']!=''){
          this.paramSubBrand +=  data[0]['subBrandId']+ ',';
        }
        this.paramSubBrand +=  data[0]['subBrandId2'];

        this.paramType = '';
        if(data[0]['typeId']!=''){
         this.paramType +=  data[0]['typeId']+  ',';
        }
        this.paramType +=  data[0]['typeId2'];

        this.paramSubType = '';
        if(data[0]['subTypeId']!=''){
          this.paramSubType +=  data[0]['subTypeId']+  ',';
        }
        this.paramSubType +=  data[0]['subTypeId2'];        
        
        this.paramCategory ='';
        if(data[0]['categoryId']!=''){
          this.paramCategory +=  data[0]['categoryId']+ ',';
        }
        this.paramCategory +=  data[0]['categoryId2'];

        this.paramSubCategory = '';
        if(data[0]['subCategoryId']!=''){
          this.paramSubCategory +=  data[0]['subCategoryId']+  ',';
        }
        this.paramSubCategory +=  data[0]['subCategoryId2'];

        this.colspanTotal = 1;
        if(data[0]['rekapPer']!=''){
          if(data[0]['rekapPer']!='9'){
            this.colspanTotal+=2;
          }
          else{
            this.colspanTotal+=1;
          }
        }
        if(data[0]['rekapPer2']!=''){
          if(data[0]['rekapPer']!='9'){
            this.colspanTotal+=2;
          }
          else{
            this.colspanTotal+=1;
          }
        }
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          // generate report
          var dateArray = this.getDates(new Date(this.startPeriod), (new Date(this.endPeriod)));
          this.arrayDate = [];
          for (let i = 0; i < dateArray.length; i ++ ) {
            let getYear = new Date(dateArray[i]).getFullYear();
            let getMonth = new Date(dateArray[i]).getMonth();
            let getDate = new Date(dateArray[i]).getDate();
            let datecomplete = '';
            datecomplete += getYear+'-';
            if ((getMonth+1)<10){
              datecomplete +='0';
            }
            datecomplete += (getMonth+1)+'-';
            if (getDate<10){
              datecomplete +='0'+getDate;
            }
            else{
              datecomplete += getDate;
            }
            this.arrayDate.push({'date':datecomplete});
          }
          let body = {
            year: this.year,
            month: this.month,
            counterId: this.paramCounter,
            brandId: this.paramBrand,
            subBrandId: this.paramSubBrand,
            typeId: this.paramType,
            subTypeId: this.paramSubType,
            categoryId: this.paramCategory,
            subCategoryId: this.paramSubCategory,
            rekapPer:data[0]['rekapPer'],
            rekapPer2:data[0]['rekapPer2'],
            startPeriod:this.startPeriod,
            endPeriod:this.endPeriod,
            arrayDate:this.arrayDate
          };

            this.reportService.getReportSummaryStock(body).subscribe((response) => {
              this.utilService.loadingDismiss();
              
             

              if(response.status.code === 200) {
                this.arrayBottom = [];
                this.dataSummaryStock = response.results.summary_stock;
                this.showReportStock=true;
                
                for (let d=0;d<this.arrayDate.length;d++){
                  let qtyEarlyThisDay = 0;
                  let qtyInThisDay = 0;
                  let qtyOutThisDay = 0;
                  let qtyEndThisDay = 0;
                  this.qtyEarlyAllDay = 0;
                  this.qtyInAllDay = 0;
                  this.qtyOutAllDay = 0;
                  this.qtyEndAllDay = 0;

                  for(let i=0;i<this.dataSummaryStock.length;i++){
                    this.qtyEarlyAllDay += this.dataSummaryStock[i]['qty_awal'];
                    this.qtyInAllDay += this.dataSummaryStock[i]['qty_in'];
                    this.qtyOutAllDay += this.dataSummaryStock[i]['qty_out'];
                    this.qtyEndAllDay += this.dataSummaryStock[i]['qty_akhir'];
                    qtyEarlyThisDay += parseInt(this.dataSummaryStock[i][this.arrayDate[d]['date']+'_Early']);
                    qtyInThisDay += parseInt(this.dataSummaryStock[i][this.arrayDate[d]['date']+'_I']);
                    qtyOutThisDay += parseInt(this.dataSummaryStock[i][this.arrayDate[d]['date']+'_O']);
                    qtyEndThisDay += parseInt(this.dataSummaryStock[i][this.arrayDate[d]['date']+'_End']);
                  }
                  this.arrayBottom.push({
                    'date':this.arrayDate[d]['date'],
                    'qty_awal':qtyEarlyThisDay,
                    'qty_in':qtyInThisDay,
                    'qty_akhir':qtyEndThisDay,
                    'qty_out':qtyOutThisDay
                  });

                }
              } else {
                this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
              }
            }, (err) => {
              this.utilService.loadingDismiss();
              console.log(err);
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            });
        })          
      }
    });

    return await modal.present();
  }
  
   
   
  exportToExcelStock() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table-stock');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Report Stock '+this.monthList[parseInt(this.month)-1]+'_'+this.year+'.xlsx');
  }
  
  toggleReportStock() {
    if(this.showReportStock === false) {
      this.showReportStock = true;
      this.reportTitle = 'Hide Report';
    } else {
      this.showReportStock = false;
      this.reportTitle = 'Show Report';
    }
  }

  getQty(obj: any, month: any): string {  
    if(month<10){
      month = '0'+month;
    }
    return obj['qty_'+month]
  }
  
  getNetto(obj: any, month: any): string {    
    if(month<10){
      month = '0'+month;
    }
    return obj['netto_'+month]
  }
  
  getQtyAwal(obj: any, month: any): string {  
    console.log(obj);
    console.log(month.date);
    /*if(month<10){
      month = '0'+month;
    }
    return obj['qty_'+month]*/
    
    console.log(obj[month+'_I']);
    return obj[month.date+'_I'];
  }
  getQtyEarly(obj: any, day: any): number {  
    let qtyEarly=0
    if(obj[day.date+'_Early']){
      qtyEarly =obj[day.date+'_Early'];
    }
    return qtyEarly;
  }

  getQtyIn(obj: any, day: any): number {  
    let qtyIn=0
    if(obj[day.date+'_I']){
      qtyIn =obj[day.date+'_I'];
    }
    return qtyIn;
  }

  getQtyOut(obj: any, day: any): number {  
    let qtyOut=0
    if(obj[day.date+'_O']){
      qtyOut =obj[day.date+'_O'];
    }
    return qtyOut;
  }
  
  getQtyEnd(obj: any, day: any): number {  
    let qtyEnd=0
    if(obj[day.date+'_End']){
      qtyEnd =obj[day.date+'_End'];
    }
    return qtyEnd;
  }
  
}

