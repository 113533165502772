import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WarehouseMutationInPage } from './warehouse-mutation-in.page';

const routes: Routes = [
  {
    path: '',
    component: WarehouseMutationInPage
  },
  {
    path: 'warehouse-mutation-in-detail',
    loadChildren: () => import('./warehouse-mutation-in-detail/warehouse-mutation-in-detail.module').then( m => m.WarehouseMutationInDetailPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WarehouseMutationInPageRoutingModule {}
