import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { ClosedMonthService } from './closed-month.service';
import { MstCounter } from '../../models/mst-counter.model';

@Component({
  selector: 'app-closed-month',
  templateUrl: './closed-month.page.html',
  styleUrls: ['./closed-month.page.scss'],
})
export class ClosedMonthPage implements OnInit {

  token: any;
  formClosedMonth: FormGroup;
  userProfile: UserProfile = new UserProfile();

  periodNow: any;
  arrPeriodNow: any[] = [];
  periodBefore: any;
  arrPeriodBefore: any[] = [];
  periodNext: any;
  arrPeriodNext: any[] = [];

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private closedMonthService: ClosedMonthService) { }

  ngOnInit() {
    this.buildFormClosedMonth();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        if(this.userProfile.counter_detail) {
          this.setData();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'User ini belum dipasangkan dengan Kode Counter !' }).then(t => t.present());
        }  
      }
    });
  }

  setData() {
    let periodNow = new Date(this.userProfile.counter_detail.stock_period);
    let periodBefore = new Date(periodNow.getFullYear(), periodNow.getMonth(), 0);
    let periodNext = new Date(periodNow.getFullYear(), periodNow.getMonth() + 2, 0);

    let periodNowConvert = this.utilService.convertDate(periodNow);
    this.periodNow = this.userProfile.counter_detail.stock_period;
    this.arrPeriodNow.push({'year': periodNowConvert.years, 'month': periodNowConvert.months, 'date': periodNowConvert.dates});

    let periodBeforeConvert = this.utilService.convertDate(periodBefore);
    this.periodBefore = periodBeforeConvert.years + '-' + periodBeforeConvert.months + '-' + periodBeforeConvert.dates;
    this.arrPeriodBefore.push({'year': periodBeforeConvert.years, 'month': periodBeforeConvert.months, 'date': periodBeforeConvert.dates});

    let periodNextConvert = this.utilService.convertDate(periodNext);
    this.periodNext = periodNextConvert.years + '-' + periodNextConvert.months + '-' + periodNextConvert.dates;
    this.arrPeriodNext.push({'year': periodNextConvert.years, 'month': periodNextConvert.months, 'date': periodNextConvert.dates});

    this.formClosedMonth = this.fb.group({
      periodBefore: [{value: this.periodBefore, disabled: true}],
      periodNow: [{value: this.periodNow, disabled: true}],
      periodNext: [{value: this.periodNext, disabled: true}]
    });
  }

  buildFormClosedMonth() {
    this.formClosedMonth = this.fb.group({
      periodBefore: [{value: null, disabled: true}],
      periodNow: [{value: null, disabled: true}],
      periodNext: [{value: null, disabled: true}]
    });
  }

  processClosedMonth() {
    let counterId: any = this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : null;
    if(counterId !== null) {
      let options: any = {  
        'year': this.arrPeriodNext[0].year,
        'month': this.arrPeriodNext[0].month,
        'year_before': this.arrPeriodNow[0].year,
        'month_before': this.arrPeriodNow[0].month,
        'counter_id': counterId
      };

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.closedMonthService.processClosedMonth(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 200) {
            let nextDate = new Date(this.periodNow);
            nextDate.setDate(nextDate.getDate() + 1);
            let nextDateConvert = this.utilService.convertDate(nextDate);
            let newNextDate = nextDateConvert.years + '-' + nextDateConvert.months + '-' + nextDateConvert.dates;
            this.updateTransDateAndStockPeriod(counterId, newNextDate);
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    } else {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'User ini belum dipasangkan dengan Kode Counter !' }).then(t => t.present());
    }
  }

  updateTransDateAndStockPeriod(counterId: any, newTransDate: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const mstCounter = new MstCounter();
      mstCounter.branch_id = this.userProfile.counter_detail.branch_id;
      mstCounter.counter_code = this.userProfile.counter_detail.counter_code;
      mstCounter.counter_name = this.userProfile.counter_detail.counter_name;
      mstCounter.trans_date = newTransDate;
      mstCounter.stock_period = this.periodNext;
      mstCounter.first_address = this.userProfile.counter_detail.first_address;
      mstCounter.last_address = this.userProfile.counter_detail.last_address;
      mstCounter.phone = this.userProfile.counter_detail.phone;
      mstCounter.footer_text = this.userProfile.counter_detail.footer_text;
      mstCounter.timezone = this.userProfile.counter_detail.timezone;
      mstCounter.active = this.userProfile.counter_detail.active;
      mstCounter.updated_by = this.userProfile.username;

      this.closedMonthService.updateCounter(counterId, mstCounter).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.userProfile.counter_detail = response.results.data;
          this.storage.set('user_profile', this.userProfile);
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Proses tutup bulan berhasil!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.setData();
          }
        }
      ]
    });

    await alert.present();
  }

}
