import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, NavController, ToastController, IonContent } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { StockOpnameService } from '../stock-opname.service';
import { StockOpname, StockOpnameDetail, StockOpnameBundle } from '../../../models/stock-opname.model';
import { ApprovalHistory } from '../../../models/approval-history.model';
import { StockMutation } from '../../../models/stock-mutation.model';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import * as XLSX from 'xlsx';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-stock-opname-edit',
  templateUrl: './stock-opname-edit.page.html',
  styleUrls: ['./stock-opname-edit.page.scss'],
})
export class StockOpnameEditPage implements OnInit {
  @ViewChild(IonContent, { static: false }) content: IonContent;

  token: any;
  userProfile: UserProfile = new UserProfile();
  formStockOpnameEdit: FormGroup;
  stockOpnameId: any;
  stockOpnameData: any;
  stockOpnameDetailData: any[] = [];
  productData: any[] = [];
  counterData: any[] = [];
  warehouseData: any[] = [];
  changeDetailTableCount: any = 0;
  stockOpnameStatus: any = '0';
  productBarcode: any;

  approvalHistoryData: any[] = [];
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;

  roleAccess = new RoleAccess();
  userAccess: any[] = [];
  roleName: any;
  fileName: any;

  warehouseId :any='';
  counterId :any='';
  selectedWarehouses:any[];
  selectedCounters:any[];
  warehouseList:any[]=[];

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private stockOpnameService: StockOpnameService,
    private router: Router,
    private userDataProvider: UserData
    ) { }

  ngOnInit() {
    this.buildFormStockOpnameEdit();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleName = this.userProfile.role_detail ? this.userProfile.role_detail.role_name : null;
        this.userAccess = access;
        this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormStockOpnameEdit() {
    let selectedWarehouse: any = this.warehouseList.find(x => x.id ==  this.warehouseId);
    if(selectedWarehouse){
      let idx =this.warehouseList.indexOf(selectedWarehouse);
      this.selectedWarehouses=(this.warehouseList[idx]);
      if(this.stockOpnameStatus!=='0'){
        this.warehouseId = this.warehouseList[idx]['warehouse_name'];
      }
    }
    let selectedCounter: any = this.counterData.find(x => x.id ==  this.counterId);
    if(selectedCounter){
      let idx =this.counterData.indexOf(selectedCounter);
      this.selectedCounters=(this.counterData[idx]);
      if(this.stockOpnameStatus!=='0'){
        this.counterId = this.counterData[idx]['counter_name'];
      }
    }
    this.formStockOpnameEdit = this.fb.group({
      stockOpnameId: [this.stockOpnameData ? this.stockOpnameData[0].id : null],
      counterId: [this.counterId !='' ? this.counterId : null, Validators.required],
      warehouseId: [this.warehouseId != '' ? this.warehouseId : null, Validators.required],
      docDate: [this.stockOpnameData ? this.stockOpnameData[0].doc_date : null, Validators.required],
      description: [this.stockOpnameData ? this.stockOpnameData[0].desc : null, Validators.required]
    });
    
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.stockOpnameId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.stockOpnameId = this.stockOpnameId.replaceAll('"','');
      this.fileName = this.stockOpnameId+".xlsx";
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let dateConvert = this.utilService.convertDate(new Date());
        let stockField = 'end_'+dateConvert.months;
        let options = { 
          "token": this.token,
          "stock_field": stockField,
          "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
        };
        this.stockOpnameService.getStockOpnameforEdit(this.stockOpnameId, options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.stockOpnameData = response.results.stock_opname_data;
          this.stockOpnameDetailData = response.results.stock_opname_detail_data;
          this.productData = response.results.product_data;
          this.counterData = response.results.counter_data;
          this.warehouseData = response.results.warehouse_data;
          this.warehouseList = this.warehouseData;
          this.changeDetailTableCount = 0;
          let userCreate: any = response.results.user_create_data;
          this.createdBy = userCreate ? userCreate[0].name : null;
          let userUpdate: any = response.results.user_update_data;
          this.updatedBy = userUpdate ? userUpdate[0].name : null;
          this.approvalHistoryData = response.results.approval_history_data;
          if(this.stockOpnameData) {
            this.stockOpnameStatus = this.stockOpnameData[0].status;
            this.createdAt = this.stockOpnameData[0].created_at;
            this.updatedAt = this.stockOpnameData[0].updated_at;
            this.counterId = parseInt(this.stockOpnameData[0].counter_id);
            this.warehouseId = parseInt(this.stockOpnameData[0].warehouse_id);
            this.buildFormStockOpnameEdit();
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  getStockOpnameDetail() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let dateConvert = this.utilService.convertDate(new Date());
      let stockField = 'end_'+dateConvert.months;
      const stockOpnameForm = this.formStockOpnameEdit.getRawValue();
      let options = {
        "token": this.token,
        "warehouse_id": stockOpnameForm.warehouseId,
        "stock_field": stockField
      };

      this.stockOpnameService.getStockOpnameforCreate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.stockOpnameDetailData = response.results;
        this.changeDetailTableCount = 1;
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  clearDetail() {
    this.stockOpnameDetailData = [];
  }

  changeQty(id: any, qtyProgram: any, qtyPhysic: any) {
    if(this.stockOpnameStatus === '0') {
      let count = parseInt(qtyPhysic) - parseInt(qtyProgram);
      let htmlId = 'qtyDiff_'+id;
      document.getElementById(htmlId).innerHTML = count.toString();
    }
  }

  sameQty(id: any, qtyProgram: any) {
    if(this.stockOpnameStatus === '0') {
      let htmlId = 'qtyPhysic_'+id;
      (<HTMLInputElement>document.getElementById(htmlId)).value = qtyProgram;
      this.changeQty(id, qtyProgram, qtyProgram);
    }
  }

  update(action: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formStockOpname = this.formStockOpnameEdit.value;
      let docDateConvert = this.utilService.convertDate(formStockOpname.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      let arrStockOpnameId: any = [];
      let arrProductId: any = [];
      let arrQtyPhysic: any = [];
      let arrQtyProgram: any = [];
      let arrPrice: any = [];

      for(let x = 0; x < this.stockOpnameDetailData.length; x++) {
        let htmlIdQtyPhysic: any = 'qtyPhysic_' + this.stockOpnameDetailData[x].id;
        let qtyPhysic: any = (<HTMLInputElement>document.getElementById(htmlIdQtyPhysic)).value;

        arrStockOpnameId[x] = this.stockOpnameId;
        if(this.changeDetailTableCount === 0) { arrProductId[x] = this.stockOpnameDetailData[x].product_id; }
        if(this.changeDetailTableCount === 1) { arrProductId[x] = this.stockOpnameDetailData[x].id; }
        arrQtyPhysic[x] = parseInt(qtyPhysic);
        arrQtyProgram[x] = parseInt(this.stockOpnameDetailData[x].qty_program);
        arrPrice[x] = this.stockOpnameDetailData[x].price;
      }

      const stockOpnameBundle = new StockOpnameBundle();
      stockOpnameBundle.stockOpname.counter_id = this.counterId;
      stockOpnameBundle.stockOpname.warehouse_id = this.warehouseId;
      stockOpnameBundle.stockOpname.doc_date = documentDate;
      stockOpnameBundle.stockOpname.desc = formStockOpname.description;
      stockOpnameBundle.stockOpname.status = action === 'data' ? '0' : '1';
      stockOpnameBundle.stockOpname.updated_by = this.userProfile.username;

      stockOpnameBundle.stockOpnameDetail.stock_opname_id = arrStockOpnameId;
      stockOpnameBundle.stockOpnameDetail.product_id = arrProductId;
      stockOpnameBundle.stockOpnameDetail.qty_real = arrQtyPhysic;
      stockOpnameBundle.stockOpnameDetail.qty_program = arrQtyProgram;
      stockOpnameBundle.stockOpnameDetail.price = arrPrice;

      if(action === 'status') {
        let convertTime = this.utilService.convertDateWithMoment(new Date(), 'Asia/Jakarta');
        let convertDate = this.utilService.convertDate(new Date());
        let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
        let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

        stockOpnameBundle.approvalHistory.transaction_id = this.stockOpnameId;
        stockOpnameBundle.approvalHistory.username = this.userProfile.username;
        stockOpnameBundle.approvalHistory.status = '1';
        stockOpnameBundle.approvalHistory.trans_date = transDate + ' ' + transTime;
      }

      this.stockOpnameService.updateStockOpnameBundle(this.stockOpnameId, stockOpnameBundle).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  getProduct(productBarcode: any) {
    let checkItemBarcode: any[] = this.stockOpnameDetailData.find(x => x.barcode === productBarcode);
    if (checkItemBarcode === undefined) {
      let getProduct = this.productData.find(x => x.barcode === productBarcode);
      if(getProduct) {
        this.stockOpnameDetailData.unshift({
          'stock_opname_id': this.stockOpnameId,
          'product_id': getProduct.id,
          'qty_real': 0,
          'qty_program': getProduct.qty_program,
          'product_name': getProduct.product_name,
          'barcode': getProduct.barcode,
          'unit': getProduct.unit,
          'price': getProduct.price,
        });
      }else {
        let checkItemPcode: any[] = this.stockOpnameDetailData.find(x => x.product_id === productBarcode);      
        if (checkItemPcode === undefined) {
          let getProductId = this.productData.find(x => x.id === productBarcode);     
          if(getProductId) {
            this.stockOpnameDetailData.unshift({
              'stock_opname_id': this.stockOpnameId,
              'product_id': getProductId.id,
              'qty_real': 0,
              'qty_program': getProductId.qty_program,
              'product_name': getProductId.product_name,
              'barcode': getProductId.barcode,
              'unit': getProductId.unit,
              'price': getProductId.price,
            });
          }
          else{
            let message = 'Code tidak valid.';
            this.showAlertBarcodeNotValid(message);
          }
        } 
      }
    }
    
    this.productBarcode = "";
    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }

  updateHeader(action: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formStockOpname = this.formStockOpnameEdit.value;
      let docDateConvert = this.utilService.convertDate(formStockOpname.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      const stockOpname = new StockOpname();
      stockOpname.counter_id = this.counterId;
      stockOpname.warehouse_id = this.warehouseId;
      stockOpname.doc_date = documentDate;
      stockOpname.desc = formStockOpname.description;
      stockOpname.updated_by = this.userProfile.username;

      if(action === 'data') { stockOpname.status = '0'; }
      if(action === 'status') { stockOpname.status = '1'; }

      this.stockOpnameService.updateStockOpname(this.stockOpnameId, stockOpname).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          if(action === 'data') { this.inputDetail(this.stockOpnameId); }
          if(action === 'status') { this.saveApprovalHistory(stockOpname.status); }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  inputDetail(stockOpnameId: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let arrStockOpnameId: any = [];
      let arrProductId: any = [];
      let arrQtyPhysic: any = [];
      let arrQtyProgram: any = [];
      let arrPrice: any = [];

      for(let x = 0; x < this.stockOpnameDetailData.length; x++) {
        let htmlIdQtyPhysic: any = 'qtyPhysic_' + this.stockOpnameDetailData[x].id;
        let qtyPhysic: any = (<HTMLInputElement>document.getElementById(htmlIdQtyPhysic)).value;

        arrStockOpnameId[x] = stockOpnameId;
        if(this.changeDetailTableCount === 0) { arrProductId[x] = this.stockOpnameDetailData[x].product_id; }
        if(this.changeDetailTableCount === 1) { arrProductId[x] = this.stockOpnameDetailData[x].id; }
        arrQtyPhysic[x] = parseInt(qtyPhysic);
        arrQtyProgram[x] = parseInt(this.stockOpnameDetailData[x].qty_program);
        arrPrice[x] = this.stockOpnameDetailData[x].price;
      }

      const stockOpnameDetail = new StockOpnameDetail();
      stockOpnameDetail.stock_opname_id = arrStockOpnameId;
      stockOpnameDetail.product_id = arrProductId;
      stockOpnameDetail.qty_real = arrQtyPhysic;
      stockOpnameDetail.qty_program = arrQtyProgram;
      stockOpnameDetail.price = arrPrice;

      this.stockOpnameService.addStockOpnameDetail(stockOpnameDetail).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  saveApprovalHistory(status: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let convertTime = this.utilService.convertDateWithMoment(new Date(), 'Asia/Jakarta');
      let convertDate = this.utilService.convertDate(new Date());
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

      const approvalHistory = new ApprovalHistory();
      approvalHistory.transaction_id = this.stockOpnameId;
      approvalHistory.username = this.userProfile.username;
      approvalHistory.status = status;
      approvalHistory.trans_date = transDate + ' ' + transTime;

      this.stockOpnameService.addApprovalHistory(approvalHistory).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.saveMutationStock();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  checkStatus() {
    let readOnly: boolean = false;
    if(this.stockOpnameStatus !== '0') { readOnly = true; }
    return readOnly;
  }

  saveMutationStock() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formStockOpname = this.formStockOpnameEdit.value;
      let docDateConvert = this.utilService.convertDate(formStockOpname.docDate);

      let newDate = new Date();
      let convertDate = this.utilService.convertDate(newDate);
      let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

      let arrUpdateStock: any[] = [];
      let arrWarehouseId: any[] = [];
      let arrTransactionId: any[] = [];
      let arrStockMutationTypeId: any[] = [];
      let arrProductId: any[] = [];
      let arrQty: any[] = [];
      let arrValue: any[] = [];
      let arrStockMove: any[] = [];
      let arrTransDate: any[] = [];
      let arrYear: any[] = [];
      let arrMonth: any[] = [];

      for(let x = 0; x < this.stockOpnameDetailData.length; x++) {
        let qtyReal: any = parseInt(this.stockOpnameDetailData[x].qty_real);
        let qtyProgram: any = parseInt(this.stockOpnameDetailData[x].qty_program);
        if(qtyReal !== qtyProgram) {
          arrUpdateStock.push(this.stockOpnameDetailData[x]);
        }
      }

      if(arrUpdateStock.length > 0) {
        for(let x = 0; x < arrUpdateStock.length; x++) {
          arrYear[x] = convertDate.years;
          arrMonth[x] = convertDate.months;
          arrWarehouseId[x] = this.warehouseId;
          arrTransactionId[x] = this.stockOpnameId;
          arrStockMutationTypeId[x] = 'SO'; // SO = STOCK OPNAME
          if(this.changeDetailTableCount === 0) { arrProductId[x] = arrUpdateStock[x].product_id; }
          if(this.changeDetailTableCount === 1) { arrProductId[x] = arrUpdateStock[x].id; }
  
          let qtyReal: any = parseInt(arrUpdateStock[x].qty_real);
          let qtyProgram: any = parseInt(arrUpdateStock[x].qty_program);
          if(qtyReal > qtyProgram) {
            arrQty[x] = qtyReal - qtyProgram;
            arrStockMove[x] = 'I';
          } else {
            arrQty[x] = qtyProgram - qtyReal;
            arrStockMove[x] = 'O';
          }
          arrValue[x] = 0;
          arrTransDate[x] = todayDate;
        }

        const stockMutationData = new StockMutation();
        stockMutationData.warehouse_id = arrWarehouseId;
        stockMutationData.transaction_id = arrTransactionId;
        stockMutationData.stock_mutation_type_id = arrStockMutationTypeId;
        stockMutationData.product_id = arrProductId;
        stockMutationData.qty = arrQty;
        stockMutationData.value = arrValue;
        stockMutationData.stock_move = arrStockMove;
        stockMutationData.trans_date = arrTransDate;

        this.stockOpnameService.addStockMutation(stockMutationData).subscribe((response) => {
          if(response.status.code === 201) {

            let options = {
              "token": this.token,
              "year": arrYear,
              "month": arrMonth,
              "warehouse_id": arrWarehouseId,
              "product_id": arrProductId,
              "mutation_type": arrStockMove,
              "qty": arrQty,
              "value": arrValue
            };

            this.stockOpnameService.manageStock(options).subscribe((response) => {
              this.utilService.loadingDismiss();
              if(response.status.code === 201) {
                this.showConfirmUpdate();
              } else {
                this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
              }
            }, () => {
              this.utilService.loadingDismiss();
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            });
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      } else {
        this.utilService.loadingDismiss();
        this.showConfirmUpdate();
      }
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/stock-opname']);;
          }
        }
      ]
    });

    await alert.present();
  }

  async showAlertBarcodeNotValid(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    });

    await alert.present();
  }

  scrollToTop() {
    this.content.scrollToTop(1500);
  }

  scrollToBottom() {
    this.content.scrollToBottom(1500);
  }

  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  
  public selectWarehouse(event){
    this.warehouseId = event.value.id;
  }
    
  public selectCounter(event){
    this.counterId = event.value.id;
    this.warehouseId ='';
    this.selectedWarehouses=[];
    let filterWarehouse = this.warehouseData.filter(x => parseInt(x.counter_id) === parseInt(this.counterId));
    this.warehouseList = filterWarehouse;  
  }
}
