import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable, observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private httpService: HttpService) { }

  getReportCashierIndex(params: any): Observable<any> {
    return this.httpService.get('report-cashier', params);
  }

  getReportStockAuditrailIndex(params: any): Observable<any> {
    return this.httpService.get('report-stock-auditrail', params);
  }

  getReportProductRequestIndex(params: any): Observable<any> {
    return this.httpService.get('report-product-request', params);
  }

  getReportProductReceivingIndex(params: any): Observable<any> {
    return this.httpService.get('report-product-receiving', params);
  }

  getReportCashier(rekapPer: any, params: any): Observable<any> {
    let url: any;

    if(rekapPer === '0') {
      url = 'report-cashier/transaction';
    } else if(rekapPer === '1') {
      url = 'report-cashier/transaction-detail';
    } else if(rekapPer === '2') {
      url = 'report-cashier/transaction-product';
    } else if(rekapPer === '3') {
      url = 'report-cashier/transaction-brand';
    } else if(rekapPer === '4') {
      url = 'report-cashier/transaction-prize';
    } else if(rekapPer === '5') {
      url = 'report-cashier/transaction-void';
    } else if(rekapPer === '6') {
      url = 'report-cashier/transaction-sumdate';
    } else if(rekapPer === '7') {
      url = 'report-cashier/package-product';
    } else if(rekapPer === '8') {
      url = 'report-cashier/package-brand';
    } else {
      url = '';
    }

    return this.httpService.get(url, params);
  }

  getReportStock(params: any): Observable<any> {
    return this.httpService.get('report-stock', params);
  }

  getReportStockAuditrail(params: any): Observable<any> {
    return this.httpService.get('generate-report-stock-auditrail', params);
  }

  getReportProductRequest(params: any): Observable<any> {
    return this.httpService.get('generate-report-product-request', params);
  }

  getReportProductReceiving(params: any): Observable<any> {
    return this.httpService.get('generate-report-product-receiving', params);
  }

  getReportProductRequestDetail(params: any): Observable<any> {
    return this.httpService.get('generate-report-product-request-detail', params);
  }

  getReportProductReceivingDetail(params: any): Observable<any> {
    return this.httpService.get('generate-report-product-receiving-detail', params);
  }

  getMutationStock(params: any): Observable<any> {
    return this.httpService.get('stock-mutation-get', params);
  }

  saveVoidTransaction(data: any): Observable<any> {
    return this.httpService.post('save-void-transaction', data);
  }

  getSalesByReceiptNumber(params: any, receiptNo: any): Observable<any> {
    return this.httpService.get(`get-sales-transaction/${receiptNo}`, params);
  }

  getReportOtherExpencesIndex(params: any): Observable<any> {
    return this.httpService.get('report-other-expences', params);
  } 
  
  getReportOtherExpences(params: any): Observable<any> {
    return this.httpService.get('generate-report-other-expences', params);
  }
  getReportOtherExpencesDetail(params: any): Observable<any> {
    return this.httpService.get('generate-other-expences-detail', params);
  }

  getReportOtherReceivingIndex(params: any): Observable<any> {
    return this.httpService.get('report-other-receiving', params);
  }
  getReportOtherReceiving(params: any): Observable<any> {
    return this.httpService.get('generate-report-other-receiving', params);
  }
  getReportOtherReceivingDetail(params: any): Observable<any> {
    return this.httpService.get('generate-other-receiving-detail', params);
  }
  
  getReportMemberPointRegular(params: any): Observable<any> {
    return this.httpService.get('report-member-point-regular', params);
  }
  getMutationPoint(params: any): Observable<any> {
    return this.httpService.get('mutation-point-member-regular', params);
  }
  getActiveBranch(params: any): Observable<any> {
    return this.httpService.get('active-branch-list', params);
  }
  getCounterByBranchId(params: any): Observable<any> {
    return this.httpService.get('counter-list-by-branch', params);
  }
  getActiveCounterByBranchId(params: any): Observable<any> {
    return this.httpService.get('active-counter-list-by-branch', params);
  }
  getWarehouseByCounterId(params: any): Observable<any> {
    return this.httpService.get('warehouse-by-counter', params);
  }
  getCashierByCounterId(params: any): Observable<any> {
    return this.httpService.get('cashier-list-by-counter', params);
  }
  getUserByCounterId(params: any): Observable<any> {
    return this.httpService.get('user-list-by-counter', params);
  }
  getReportSummarySales(params: any): Observable<any> {
    return this.httpService.post('summary-sales-report', params);
  }
  getReportSummarySalesDaily(params: any): Observable<any> {
    return this.httpService.post('report-summary-sales-daily', params);
  }
  getReportTimeseriesSales(params: any): Observable<any> {
    return this.httpService.post('timeseries-sales-report', params);
  }

  getReportSummaryIndex(params: any): Observable<any> {
    return this.httpService.get('filter-summary-sales-report', params);
  }
  getReportSummaryStock(params: any): Observable<any> {
    return this.httpService.post('summary-stock-report', params);
  }
  getReportNationalStock(params: any): Observable<any> {
    return this.httpService.post('national-stock-report', params);
  }
  getReportCounter(params: any): Observable<any> {
    return this.httpService.post('report-counter', params);
  }
  indexReportCounter(params: any): Observable<any> {
    return this.httpService.get('report-counter', params);
  }
  getReportClaimIncentives(params: any): Observable<any> {
    return this.httpService.post('report-claim-incentives', params);
  }
  loadReportClaimIncentives(params: any): Observable<any> {
    return this.httpService.get('reseller-reward', params);
  }
  getReportRekapPromo(params: any): Observable<any> {
    return this.httpService.get('report-rekap-promo', params);
  }
}
