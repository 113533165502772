import {  Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { Storage } from '@ionic/storage';
import { ReportService } from '../../../pages/report/report.service';
import { UserProfile } from '../../../models/user-profile.model';

@Component({
  selector: 'app-report-other-expences',
  templateUrl: './report-other-expences.component.html',
  styleUrls: ['./report-other-expences.component.scss'],
})
export class ReportOtherExpencesComponent implements OnInit {

  @Input() counterList: any[];
  @Input() warehouseList: any[];
  @Input() purposeList: any[];
  @Input() paramOtherExpencesId: any;
  @Input() paramBranchId: any;
  @Input() paramCounterId: any;
  @Input() paramWarehouseId: any;
  @Input() paramDocDateStart: any;
  @Input() paramDocDateEnd: any;  
  @Input() paramPurposeId:any;

  otherExpencesId: any;
  counterId: any = null;
  warehouseId: any = null;
  purposeId : any = null;
  docDateStart: any;
  docDateEnd: any;
  branchData: any[] = [];
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterByLogin:any;
  selectedBranchs:any[];
  selectedCounters: any[];
  selectedWarehouses: any[];
  selectedPurposes:any[];
  branchId: any;

  constructor(
    public modalController: ModalController,
    private utilService: UtilService,
    private reportService: ReportService,
    private storage: Storage,
    private toastCtrl: ToastController) { }

  ngOnInit() {
    let convertDate = this.utilService.convertDate(new Date());
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
    this.otherExpencesId = this.otherExpencesId ? this.otherExpencesId : null;
    this.docDateStart = this.paramDocDateStart ? this.paramDocDateStart : todayDate;
    this.docDateEnd = this.paramDocDateEnd ? this.paramDocDateEnd : todayDate;
    this.branchId = this.paramBranchId ? this.paramBranchId : '';
    this.counterId = this.paramCounterId ? this.paramCounterId : '';
    this.warehouseId = this.paramWarehouseId ? this.paramWarehouseId : '';
    this.purposeId = this.paramPurposeId ? this.paramPurposeId : '';
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
      }
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.userProfile = new UserProfile(profile);
        this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
        let options = { 
          "token": this.token,
          "counter_id": this.counterByLogin
        };
        this.reportService.getReportCashierIndex(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.branchData = response.results.branch_data;
          let branchIdSplit = this.branchId !== '' ? String(this.branchId).split(',') : [];
          this.selectedBranchs=[];
          for(let i= 0; i < branchIdSplit.length; i++) {        
            let selectedBranch: any = this.branchData.find(x => x.id ==  branchIdSplit[i]);
            if(selectedBranch){
              let idx =this.branchData.indexOf(selectedBranch);
              this.selectedBranchs.push(this.branchData[idx]);            
            }
          }
          this.selectedPurposes =[];
          let purposeIdSplit = this.purposeId !== '' ? String(this.purposeId).split(',') : [];
          for(let i= 0; i < purposeIdSplit.length; i++) {
            let selectedPurpose: any = this.purposeList.find(x => x.id ==  purposeIdSplit[i]);
            if(selectedPurpose){
              let idx =this.purposeList.indexOf(selectedPurpose);
              this.selectedPurposes=(this.purposeList[idx]);            
            }
          }
          this.filterCounterByBranch();
        })
      })
    })
  }
  
  
  getSelectedWarehouse(event: any) {
   this.warehouseId = event.target.value;
  } 
   
  public getSelectedPurpose(event) {
    this.purposeId = event.value.id;
  }

  async dismissModal(code: any) {
    let dataReturn: any[] = [];

    if(code === 'generate') {
      let convertDocDateStart = this.utilService.convertDate(this.docDateStart);
      let convertDocDateEnd = this.utilService.convertDate(this.docDateEnd);
      
      let listCounterName='';
      let counterIdSplit = this.counterId !== '' ? String(this.counterId).split(',') : [];      
      for(let i=0;i<counterIdSplit.length;i++){        
        let selectedCounter: any = this.counterList.find(x => x.id ==  counterIdSplit[i]);
        if(selectedCounter){
          let idx =this.counterList.indexOf(selectedCounter);
          listCounterName+= this.counterList[idx]['counter_name']+',';            
        }
      }
      let listWarehouseName ='';
      let warehouseIdSplit = this.warehouseId !== '' ? String(this.warehouseId).split(',') : [];      
      for(let i=0;i<warehouseIdSplit.length;i++){        
        let selectedWarehouse: any = this.warehouseList.find(x => x.id ==  warehouseIdSplit[i]);
        if(selectedWarehouse){
          let idx =this.warehouseList.indexOf(selectedWarehouse);
          listWarehouseName+= this.warehouseList[idx]['warehouse_name']+',';            
        }
      }
      let purposeName='';
      let purposeIdSplit = this.purposeId !== '' ? String(this.purposeId).split(',') : [];      
      for(let i=0;i<purposeIdSplit.length;i++){        
        let selectedPurpose: any = this.purposeList.find(x => x.id ==  purposeIdSplit[i]);
        if(selectedPurpose){
          let idx =this.purposeList.indexOf(selectedPurpose);
          purposeName+= this.purposeList[idx]['purpose_name']+',';            
        }
      }

      let data = {
        otherExpencesId: this.otherExpencesId ? this.otherExpencesId : null,        
        branchId: this.branchId,
        counterId: this.counterId  ?  this.counterId : 0,
        counterName: listCounterName,
        warehouseId :  this.warehouseId ?  this.warehouseId : 0,
        warehouseName : listWarehouseName,
        docDateStart: convertDocDateStart.years + '-' + convertDocDateStart.months + '-' + convertDocDateStart.dates,
        docDateEnd: convertDocDateEnd.years + '-' + convertDocDateEnd.months + '-' + convertDocDateEnd.dates,
        purposeId :  this.purposeId ?  this.purposeId : 0,
        purposeName : purposeName,
      };

      dataReturn.push(data);
    }

    if(code === 'close') {
      dataReturn = [];
    }

    await this.modalController.dismiss(dataReturn);
  }
    
  public selectCounter(event){
    this.counterId = event.value.id;    
    this.filterWarehouseByCounter();
  }

  public selectWarehouse(event){    
    let idWarehouse = '';
    if(event.value.length>0){
      for(let i=0;i<event.value.length;i++){
        idWarehouse += event.value[i]['id']+',';
      }
    }
    this.warehouseId = idWarehouse.replace(/(^,)|(,$)/g, "");
  }

  public selectBranch(event){
    this.branchId = event.value.id;       
    this.filterCounterByBranch();
  }
  
  filterCounterByBranch(){    
    this.selectedCounters=[];    
    let idBranch = '';
    for(let i=0;i<this.selectedBranchs.length;i++){
      idBranch += this.selectedBranchs[i].id+',';
    }
    this.branchId = idBranch.replace(/(^,)|(,$)/g, "");
    let options = { 
      "token": this.token,
      "branch_id": this.branchId,
      "counter_id": this.counterByLogin 
    };
    this.reportService.getCounterByBranchId(options).subscribe((response) => {
      this.utilService.loadingDismiss();      
      this.counterList = [];
      this.counterList = response.results;
      this.counterId = this.paramCounterId ? this.paramCounterId : '';
      
      let counterIdSplit = this.counterId !== '' ? String(this.counterId).split(',') : [];
      for(let i= 0; i < counterIdSplit.length; i++) {        
        let selectedCounter: any = this.counterList.find(x => x.id ==  counterIdSplit[i]);
        if(selectedCounter){
          let idx =this.counterList.indexOf(selectedCounter);
          this.selectedCounters.push(this.counterList[idx]);            
        }
      }
      this.filterWarehouseByCounter();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });  
  }
  
  filterWarehouseByCounter(){
    this.selectedWarehouses = [];
    this.warehouseList =[];
    let idCounter = '';
    for(let i=0;i<this.selectedCounters.length;i++){
      idCounter += this.selectedCounters[i].id+',';
    }
    this.counterId = idCounter.replace(/(^,)|(,$)/g, "");
    let options = { 
      "token": this.token,
      "counter_id": this.counterId 
    };
    
    this.reportService.getWarehouseByCounterId(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.warehouseId = this.paramWarehouseId ? this.paramWarehouseId : '';
     
      this.warehouseList = response.results;
      let warehouseIdSplit = this.warehouseId !== '' ? String(this.warehouseId).split(',') : [];
      for(let i= 0; i < this.branchData.length; i++) {        
        let selectedWarehouse: any = this.warehouseList.find(x => x.id ==  warehouseIdSplit[i]);
        if(selectedWarehouse){
          let idx =this.warehouseList.indexOf(selectedWarehouse);
          this.selectedWarehouses.push(this.warehouseList[idx]);
        }
      }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }
}