import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { PettyCash } from '../../models/petty-cash.model'

@Injectable({
  providedIn: 'root'
})
export class PettyCashService {

  constructor(private httpService: HttpService) { }

  getPettyCash(params: any):  Observable<any> {
    return this.httpService.get('petty-cash', params);
  }

  getDataCreatePettyCash(params: any): Observable<any> {
    return this.httpService.get('petty-cash/create', params);
  }

  addPettyCash(data: PettyCash): Observable<any> {
    return this.httpService.post('petty-cash', data);
  }

  getPettyCashEdit(pettyCashId: any, params: any): Observable<any> {
    return this.httpService.get(`petty-cash/${pettyCashId}/edit`, params);
  }

  updatePettyCash(pettyCashId: any, data: PettyCash): Observable<any> {
    return this.httpService.put(`petty-cash/${pettyCashId}`, data);
  }

  deletePettyCash(pettyCashId: any): Observable<any> {
    return this.httpService.delete('petty-cash/'+pettyCashId);
  }

}
