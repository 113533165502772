import { Injectable } from '@angular/core';
import { HttpService } from './service/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private httpService: HttpService) { }

  getSidebarMenu(username: any, roleId: any, params: any): Observable<any> {
    return this.httpService.get(`sidebar-menu/${username}/${roleId}`, params);
  }
}
