import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { PettyCashService } from '../petty-cash.service';
import { PettyCash } from '../../../models/petty-cash.model';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';

@Component({
  selector: 'app-petty-cash-create',
  templateUrl: './petty-cash-create.page.html',
  styleUrls: ['./petty-cash-create.page.scss'],
})
export class PettyCashCreatePage implements OnInit {

  formPettyCashCreate: FormGroup;

  userProfile: UserProfile = new UserProfile();
  token: any;
  katPettyCashList: any[] = [];
  typeList: any[] = [];
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private pettyCashService: PettyCashService,
    private userData: UserData,
    private router: Router

  ) { }

  ngOnInit() {
    this.buildFormPettyCashCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.pettyCashService.getDataCreatePettyCash({"token": this.token}).subscribe((response) => {
            this.utilService.loadingDismiss();
            let convertDate = this.utilService.convertDate(new Date());
            let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
            this.katPettyCashList = response.results.petty_cash_category_data;
            this.typeList = response.results.type_data;
            this.formPettyCashCreate.patchValue({transDate: this.userProfile.counter_detail ? this.userProfile.counter_detail.trans_date:todayDate});
          });
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
    
  }

  buildFormPettyCashCreate() {
    this.formPettyCashCreate = this.fb.group({
      transDate: [null, Validators.required],
      katPettyCash: [null, Validators.required],
      type: [null, Validators.required],
      jumlah: [0, Validators.required],
      keterangan: [null, Validators.required]
    })
  }

  inputPettyCash() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const pettyCashForm = this.formPettyCashCreate.getRawValue();
      let dateConvert = this.utilService.convertDate(pettyCashForm.transDate);
      let newTransDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;
      const pettyCash = new PettyCash;
      pettyCash.counter_id = this.userProfile.counter_id;
      pettyCash.petty_cash_category_id = pettyCashForm.katPettyCash;
      pettyCash.doc_date = newTransDate;
      pettyCash.type = pettyCashForm.type;
      pettyCash.amount = pettyCashForm.jumlah;
      pettyCash.desc = pettyCashForm.keterangan;
      pettyCash.created_by = this.userProfile.username;

      this.pettyCashService.addPettyCash(pettyCash).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/petty-cash']);;
          }
        }
      ]
    });

    await alert.present();
  }
}
