import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { MstCashier } from '../../models/mst-cashier.model';

@Injectable({
  providedIn: 'root'
})
export class CashierService {

  constructor(private httpService: HttpService) { }

  getCashier(params: any): Observable<any> {
    return this.httpService.get('cashier', params);
  }

  getCashierforEdit(cashierId: any, params: any): Observable<any> {
    return this.httpService.get(`cashier/${cashierId}/edit`, params);
  }

  getCashierforCreate(params: any): Observable<any> {
    return this.httpService.get('cashier/create', params);
  }

  getCounter(params: any): Observable<any> {
    return this.httpService.get('counter-all', params);
  }

  addCashier(data: MstCashier): Observable<any> {
    return this.httpService.post('cashier', data);
  }

  updateCashier(cashierId: any, data: MstCashier): Observable<any> {
    return this.httpService.put(`cashier/${cashierId}`, data);
  }

  setupCashier(cashierId: any, data: MstCashier): Observable<any> {
    return this.httpService.put(`setup-cashier/${cashierId}`, data);
  }

  deleteCashier(cashierId: any): Observable<any> {
    return this.httpService.delete(`cashier/${cashierId}`);
  }
}
