import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { PettyCashService } from '../petty-cash.service';
import { PettyCash } from '../../../models/petty-cash.model';
import { UserProfile } from '../../../models/user-profile.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-petty-cash-edit',
  templateUrl: './petty-cash-edit.page.html',
  styleUrls: ['./petty-cash-edit.page.scss'],
})
export class PettyCashEditPage implements OnInit {

  formPettyCashEdit: FormGroup;

  userProfile: UserProfile = new UserProfile();
  token: any;
  katPettyCashList: any[] = [];
  typeList: any[] = [];
  pettyCashId: any;
  pettyCashData: any;
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private pettyCashService: PettyCashService,
    private userData: UserData,
    private router: Router

  ) { }

  ngOnInit() {
    this.buildFormPettyCashBuild();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });

  }

  buildFormPettyCashBuild() {
    this.formPettyCashEdit = this.fb.group({
      noPettyCash: [null, Validators.required],
      transDate: [null, Validators.required],
      katPettyCash: [null, Validators.required],
      type: [null, Validators.required],
      jumlah: [0, Validators.required],
      keterangan: [null, Validators.required]
    })
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.pettyCashId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.pettyCashService.getPettyCashEdit(this.pettyCashId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.pettyCashData = response.results.petty_cash_data;
          this.katPettyCashList = response.results.petty_cash_category_data;
          this.typeList = response.results.type_data;
          if(this.pettyCashData) {
            this.formPettyCashEdit = this.fb.group({
              noPettyCash: [this.pettyCashData.id, Validators.required],
              transDate: [this.pettyCashData.doc_date, Validators.required],
              katPettyCash: [this.pettyCashData.petty_cash_category_id, Validators.required],
              type: [this.pettyCashData.type, Validators.required],
              jumlah: [this.pettyCashData.amount, Validators.required],
              keterangan: [this.pettyCashData.desc, Validators.required]
            });
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updatePettyCash() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const pettyCashForm = this.formPettyCashEdit.getRawValue();
      let dateConvert = this.utilService.convertDate(pettyCashForm.transDate);
      let newTransDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;
      const pettyCash = new PettyCash;
      pettyCash.counter_id = this.userProfile.counter_id;
      pettyCash.petty_cash_category_id = pettyCashForm.katPettyCash;
      pettyCash.doc_date = newTransDate;
      pettyCash.type = pettyCashForm.type;
      pettyCash.amount = pettyCashForm.jumlah;
      pettyCash.desc = pettyCashForm.keterangan;
      pettyCash.created_by = this.userProfile.username;

      this.pettyCashService.updatePettyCash(this.pettyCashId, pettyCash).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/petty-cash']);;
          }
        }
      ]
    });

    await alert.present();
  }
}
