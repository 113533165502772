import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CashierCreatePageRoutingModule } from './cashier-create-routing.module';

import { CashierCreatePage } from './cashier-create.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    CashierCreatePageRoutingModule
  ],
  declarations: [CashierCreatePage]
})
export class CashierCreatePageModule {}
