import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { PromotionsService } from '../../pages/promotions/promotions.service';

@Component({
  selector: 'app-upload-promo',
  templateUrl: './upload-promo.component.html',
  styleUrls: ['./upload-promo.component.scss'],
})
export class UploadPromoComponent implements OnInit {
  @ViewChild('uploadFileInputPromo', { static: false }) uploadFileInputPromo: ElementRef;
  @Input() username: any;
  @Input() id : any;
  @Input() action :any;
  @Input() template :any;
  @Input() userGuide :any;
  dataHeader:any =[];
  dataDetail:any =[];
  dataRequired:any =[];
  filename:any;
  showPromoHeader:boolean=false;
  showPromoDetail:boolean=false;
  showPromoRequired:boolean=false;
  promoBasedOnList : any[]=['','Total Belanja','Total Belanja per Produk','Produk'];
  productTypeList:any[]=['','All Product','Selected Product'];
  constructor(
    public modalController: ModalController,
    private promotionService: PromotionsService,
    private toastCtrl:ToastController,
    private utilService:UtilService,
    private navCtrl:NavController,
    private alertController:AlertController
  ) { }

  ngOnInit() {

  }
  ionViewDidEnter() {
    if (this.id!=0){      
      this.promotionService.detailUploadById(this.id).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.filename = response.results.data.original_filename;
          this.filename = this.filename.replace('.xlsx','');
          this.dataHeader = response.results.header;
          this.dataDetail = response.results.detail;
          this.dataRequired = response.results.required;
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    }
  }

  async dismissModal() {
    let close: any[] = [];
    close.push({id:0});  
    await this.modalController.dismiss(close);
  }
  
  onFileSelect(event) {
    let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!_.includes(af, file.type)) {
        this.toastCtrl.create({ duration: 2000, message: 'Hanya untuk upload file excel !' }).then(t => t.present());
      }
    }
  }

  uploadFile() {
    if (!this.uploadFileInputPromo.nativeElement.files[0]) {
      alert('Please fill valid details!');
      return false;
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formData = new FormData();
      formData.append('created_by', this.username);
      formData.append('file', this.uploadFileInputPromo.nativeElement.files[0]);

      this.promotionService.uploadFile(formData).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.uploadFileInputPromo.nativeElement.value = "";
          this.showConfirmInput();
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            this.navCtrl.navigateForward(['/promotions/promotion-upload']);;
            let close: any[] = [];
            close.push({id:1});  
            await this.modalController.dismiss(close);
          }
        }
      ]
    });
    await alert.present();
  }  
  
  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('table_header');
    var ws= XLSX.utils.table_to_sheet(element);
    var el2 = document.getElementById('table_detail');
    var ws2 = XLSX.utils.table_to_sheet(el2);
    var el3= document.getElementById('table_required');
    var ws3 = XLSX.utils.table_to_sheet(el3);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Header');
    XLSX.utils.book_append_sheet(wb, ws2, 'Detail');
    XLSX.utils.book_append_sheet(wb, ws3, 'Product Required');
    /* save to file */
    XLSX.writeFile(wb, 'Result_'+this.filename+'.xlsx');
  }

  showPromoHeaderTable() {
    if(this.showPromoHeader === false) {
      this.showPromoHeader = true;
    } else {
      this.showPromoHeader = false;
    }
  }
  showPromoDetailTable() {
    if(this.showPromoDetail === false) {
      this.showPromoDetail = true;
    } else {
      this.showPromoDetail = false;
    }
  }
  showPromoRequiredTable() {
    if(this.showPromoRequired=== false) {
      this.showPromoRequired = true;
    } else {
      this.showPromoRequired = false;
    }
  }
}
