import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { MstPriceGroupBundle } from '../../models/mst-price-group-bundle.model';

@Injectable({
  providedIn: 'root'
})
export class PriceGroupService {

  constructor(private httpService: HttpService) { }

  getCounter(params: any): Observable<any> {
    return this.httpService.get('counter-all', params);
  }
  
  getPriceGroup(params: any): Observable<any> {
    return this.httpService.get('price-group', params);
  }

  getPriceGroupforCreate(params: any): Observable<any> {
    return this.httpService.get('price-group/create', params);
  }

  getPriceGroupforEdit(priceGroupId: any, params: any): Observable<any> {
    return this.httpService.get(`price-group/${priceGroupId}/edit`, params);
  }

  addPriceGroup(data: MstPriceGroupBundle): Observable<any> {
    return this.httpService.post('price-group', data);
  }

  updatePriceGroup(priceGroupId: any, data: MstPriceGroupBundle): Observable<any> {
    return this.httpService.put(`price-group/${priceGroupId}`, data);
  }

  uploadFile(data: any): Observable<any> {
    return this.httpService.post('upload-price-group', data);
  }

  sendApproval(data: any): Observable<any> {
    return this.httpService.get('price-group/approve-by-wa', data);
  }

  getApproval(params: any): Observable<any> {
    return this.httpService.get(`price-group/get-approval`, params);
  }

  addHistoryWa(params: any): Observable<any> {
    return this.httpService.post('history-whatsapp', params);
  }

  getPriceGroupforViewDetail(priceGroupId: any): Observable<any> {
    return this.httpService.get(`view-price-group/${priceGroupId}`,);
  }
}
