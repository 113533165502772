import { Component, ViewChild, OnInit } from '@angular/core';
import { IonInput, ToastController, NavController, AlertController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserData } from '../../providers/user-data';
import { UserOptions } from '../../interfaces/user-options';
import { UtilService } from '../../service/util.service';
import { Storage } from '@ionic/storage';
import { LoginService } from './login.service';

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['./login.scss'],
})
export class LoginPage implements OnInit {
  @ViewChild('username', {static: false}) userUsernameInput: IonInput;
  @ViewChild('password', {static: false}) userPasswordInput: IonInput;

  login: UserOptions = { username: '', password: '' ,
    email:'',
    nik:'',
    whatsappNumber:'',
    fullname:'',
    confirmPassword:''
  };
  submitted = false;
  multipleCashier = false;
  showPassword:boolean=false;
  constructor(
    public userData: UserData,
    public router: Router,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private storage: Storage,
    private loginService: LoginService,
    private navCtrl: NavController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.checkLogin();
  }

  ionViewDidEnter() {
    this.showPassword=false;
    this.checkLogin();
  }

  checkLogin() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
      .then(([token, profile]) => {
      if(token) {
        this.navCtrl.navigateForward(['/home']);
      }
    });
  }

  onLogin(form: NgForm) {
    this.submitted = true;
    if (form.valid) {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.loginService.userLoginKPI(this.login.username, this.login.password)
        .subscribe((response: any) => {
          if(response.status=='200'){
            let name = response.data.employee_name;
            let email = response.data.email;
            let employee_id = response.data.employee_id;
            let nik = response.data.employee_nik;
            this.loginService.genJWTtoken(this.login.username, this.login.password,name,email,employee_id,nik)
            .subscribe((response: any) => {
              this.utilService.loadingDismiss();
              if(response.token) {
                this.storage.set('hasLoggedIn', true);
                this.storage.set('username', this.login.username);
                this.storage.set('user_password', this.login.password);
                this.storage.set('user_token', response.token);
                this.storage.set('user_counter', response.counter);
                window.dispatchEvent(new CustomEvent('user:login'));
                let cashier_id_list = response.user.cashier_id_list;
                if(cashier_id_list.length>1){
                  this.multipleCashier=true;
                }
                else if(cashier_id_list.length==1){
                  response.user.cashier_id=cashier_id_list[0].id;
                  response.user.counter_id_pos=cashier_id_list[0].counter_id;
                  let findcounter=response.counter.find(x=>x.id==cashier_id_list[0].counter_id);
                  if(findcounter){
                    response.user.counter_detail=findcounter;
                  }
                }
                this.storage.set('user_profile', response.user);
                if(this.multipleCashier){
                  this.router.navigateByUrl('/empty-page');
                }else{
                  if(response.user.start_page == 'dashboard') {
                    this.router.navigateByUrl('/dashboard');
                  }
                  else if(response.user.start_page == 'home') {
                    this.router.navigateByUrl('/home');
                  }else {
                    this.router.navigateByUrl('/empty-page');
                  }
                }
              } else {
                this.utilService.loadingDismiss();
                this.toastCtrl.create({ duration: 2000, message: 'Email atau Password salah!' }).then(t => t.present());
              }
            })
          }
          else{
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Email atau Password salah!' }).then(t => t.present());
          }
        }, (err) => {
          this.utilService.loadingDismiss();
          if(err.error.messages){
            if(err.error.messages.error){
             // errMessage = err.error.messages.error;
              //cek apakah user terdaftar sebagai user non KPI. Jika terdaftar maka proses login
              let param ={
                'username':this.login.username,
                'userKpi':'N'
              }
              this.loginService.checkUserNonKPI(param).subscribe((res: any) => {
                if(res.code==200){
                  this.onLoginNotKPI(form);
                }
                else{
                  let errMessage = res.message ? res.message : 'User Not Found';
                  this.toastCtrl.create({ duration: 2000, message: errMessage }).then(t => t.present());
                }
              });
            }
          }
          else{
            let errMessage = err.messages ? err.messages : 'Terdapat error koneksi!';
            this.toastCtrl.create({ duration: 2000, message: errMessage }).then(t => t.present());
          };
        });
      });
    }
  }
  onLoginNotKPI(form: NgForm) {
    this.submitted = true;
    if (form.valid) {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.loginService.userLogin(this.login.username, this.login.password)
        .subscribe((response: any) => {
          this.utilService.loadingDismiss();
          if(response.token) {
            this.storage.set('hasLoggedIn', true);
            this.storage.set('username', this.login.username);
            this.storage.set('user_password', this.login.password);
            this.storage.set('user_token', response.token);
            this.storage.set('user_counter', response.counter);
            window.dispatchEvent(new CustomEvent('user:login'));
            let cashier_id_list = response.user.cashier_id_list;
            if(cashier_id_list.length>1){
              this.multipleCashier=true;
            }
            else if(cashier_id_list.length==1){
              response.user.cashier_id=cashier_id_list[0].id;
              response.user.counter_id_pos=cashier_id_list[0].counter_id;
              let findcounter=response.counter.find(x=>x.id==cashier_id_list[0].counter_id);
              if(findcounter){
                response.user.counter_detail=findcounter;
              }
            }
            this.storage.set('user_profile', response.user);
            if(response.user.start_page == 'dashboard') {
              this.router.navigateByUrl('/dashboard');
            }
            else if(response.user.start_page == 'empty') {
              this.router.navigateByUrl('/empty-page');
            }else {
              this.router.navigateByUrl('/home');
            }
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Email atau Password salah!' }).then(t => t.present());
          }
        }, (err) => {
          let errMessage: any = err.error.message ? err.error.message : 'Terdapat error koneksi!';
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: errMessage }).then(t => t.present());
        });
      });
    }
  }
  gotoRegisterPage(){
    this.navCtrl.navigateForward(['/register']);
  }
  gotoRegisterKPIPage(){
    window.open('https://kpi.vci.co.id/login/form-register', '_blank');
  }
  gotoForgetPasswordPage(){
    this.navCtrl.navigateForward(['/forget-password']);
  }
  moveField(code: any) {
    switch(code.name) {

        case "email":
          setTimeout(() => {
            this.userUsernameInput.setFocus();
          }, 300);
        break;

        case "password":
          setTimeout(() => {
            this.userPasswordInput.setFocus();
          }, 300);
        break;

        default:
          break;
    }
  }

  clearCache() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      caches.keys().then(function(names) {
        for(let name of names) {
          console.log(name);
          caches.delete(name);
        }
      });

      this.utilService.loadingDismiss();
      this.showNotifAfterUpdates("Berhasil lakukan update!");
    });
  }

  async showNotifAfterUpdates(messageValue: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: messageValue,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            window.location.href = "./";
          }
        }
      ]
    });

    await alert.present();
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}