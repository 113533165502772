import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { PosService } from '../../pages/pos/pos.service';
import { UtilService } from '../../service/util.service';

@Component({
  selector: 'app-promotion-free-item',
  templateUrl: './promotion-free-item.component.html',
  styleUrls: ['./promotion-free-item.component.scss'],
})
export class PromotionFreeItemComponent implements OnInit {

  @Input() freeItemList: any[];
  @Input() arrayFreeItemUsed:any[];
  @Input() productScan:any;
  @Input() stock:any[];
  @Input() promotion_name:any;

  freeItemGet : number = 0;
  freeItemUsed : number = 0;
  freeItemRemaining : number = 0;
  maxInput : number = 0;
  totalInput : number = 0;
  isReadonly :boolean = false;
  indexPromo :number =0;

  constructor(
    public modalController: ModalController,
    private alertController: AlertController,
    private toastCtrl : ToastController,
    private utilService: UtilService) { }

  ngOnInit() {
    let checkProductScan=  this.arrayFreeItemUsed.find(x => (x.product_id.toString().toUpperCase()) === (this.productScan.toString().toUpperCase()) );
    if(checkProductScan){
      let index = this.arrayFreeItemUsed.indexOf(checkProductScan);
      this.indexPromo = index;
    }
    this.utilService.loadingPresent('Harap tunggu...')
    .then( () => {
      for(let i=0;i< this.freeItemList.length;i++){
        let pcode = this.freeItemList[i]['product_id'];
        let checkFreeItemUsed=  this.arrayFreeItemUsed[this.indexPromo]['array_item'].find(x => parseInt(x.product_id) === parseInt(pcode) );
        if(checkFreeItemUsed){
          let index = this.arrayFreeItemUsed[this.indexPromo]['array_item'].indexOf(checkFreeItemUsed);          
          this.freeItemList[i]['used'] = this.arrayFreeItemUsed[this.indexPromo]['array_item'][index]['qty_free'];
          if(this.freeItemList[i]['used']>this.maxInput){
            this.freeItemList[i]['used']=0;
          }
        }
        else{
          this.freeItemList[i]['used'] = 0;
        }

        this.freeItemList[i]['stock'] = 0;
        let checkStock=  this.stock.find(x => (x.product_id.toString()) === (pcode.toString()) );
        if(checkStock){
          
          this.freeItemList[i]['stock'] = checkStock.stock;
        }
        this.utilService.loadingDismiss();
      }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan Stock' }).then(t => t.present());
    });
    this.freeItemGet = this.arrayFreeItemUsed[this.indexPromo]['free_item_max'];
    this.freeItemUsed = this.arrayFreeItemUsed[this.indexPromo]['free_item_used'];
    this.freeItemRemaining = this.arrayFreeItemUsed[this.indexPromo]['free_item_remaining'];    
    this.maxInput = this.freeItemGet;
  }

  countFreeItem(el:number,index:any,input:any){
    let str = input.value;
    if(str!=0 && str.substring(0,1)=='0'){
      input.value = str.replace('0','');
    }
    if(el<0){
      this.showAlert('Free Item tidak boleh kurang dari 0');
      this.freeItemList[index]['used'] = 0;
      input.value = 0;
    }
    else if (el > this.maxInput){
      this.showAlert('Free Item tidak boleh lebih dari '+this.maxInput);
      this.freeItemList[index]['used'] = 0;
      input.value = 0;
    }
    else{
      if(el >  this.freeItemList[index]['stock']){
        this.showAlert('Stok tidak cukup');
        this.freeItemList[index]['used'] = 0;
        input.value=0;
      }
    }
    this.totalInput = 0;
    for(let i=0;i<this.freeItemList.length;i++){
      this.totalInput+=this.freeItemList[i]['used'];
    }

    if (this.totalInput > this.maxInput){
      this.showAlert('Free Item tidak boleh lebih dari '+this.maxInput);
      this.freeItemList[index]['used'] = 0;
      input.value = 0;
      this.totalInput = this.totalInput - el;
    }
    this.freeItemUsed = this.totalInput;
    this.freeItemRemaining = this.freeItemGet - this.freeItemUsed;
    this.arrayFreeItemUsed[this.indexPromo]['free_item_used'] = this.freeItemUsed;
    this.arrayFreeItemUsed[this.indexPromo]['free_item_remaining'] = this.freeItemRemaining;
    this.arrayFreeItemUsed[this.indexPromo]['used'] = str;
    this.freeItemList[index]['product_scan'] = this.productScan;
    if(this.freeItemRemaining==0){
      this.isReadonly = true;
    }
    else{
      this.isReadonly = false;
    }
 }

  async dismissModal() {
    await this.modalController.dismiss('');
  }

  async chooseProduct() {
    let data = {
      'freeItemList':this.freeItemList,
      'arrayFreeItemUsed':this.arrayFreeItemUsed
    }
    await this.modalController.dismiss(data);
  }

  async showAlert(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }
}