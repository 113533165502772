import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, AlertController, ToastController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { PettyCashCategoryService } from './petty-cash-category.service';
import { RoleAccess } from '../../models/role-access.model';
import { AES } from 'crypto-js';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-petty-cash-category',
  templateUrl: './petty-cash-category.page.html',
  styleUrls: ['./petty-cash-category.page.scss'],
})
export class PettyCashCategoryPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  rows: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  pettyCashCategoryList: any;
  filteredData: any;
  
  roleAccess = new RoleAccess();
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private alertController: AlertController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private pettyCashCategoryService: PettyCashCategoryService,
    private router: Router
  ) { }
  
  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.pageCallback({ offset: this.page.offset });
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });

  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString()
      };

      this.pettyCashCategoryService.getPettyCashCategory(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.pettyCashCategoryList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });

    });
  }

  goToPettyCashCategoryCreate() {
    this.navCtrl.navigateForward(['/petty-cash-category/petty-cash-category-create']);
  }

  goToUpdatePage(id: any) {
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/petty-cash-category/petty-cash-category-edit'], navigationExtras);
  }

  async showConfirmDelete(id: any) {
    const alert = await this.alertController.create({
      header: 'Delete Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk hapus data ini?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: () => {
            this.deleteData(id);
          }
        }
      ]
    });

    await alert.present();
  }

  deleteData(id: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.pettyCashCategoryService.deletePettyCashCategory(id).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showDeleteSuccess();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  } 

  async showDeleteSuccess() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Berhasil hapus data!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.pageCallback({ offset: this.page.offset });
          }
        }
      ]
    });

    await alert.present();
  }

  filterDatatable(e) {

  }

  sortCallback(e) {

  }

}
