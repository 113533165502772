export class SetorKasir {

    id: number;
    counter_id: number;
    user_id: number;
    cashier_capital_id: number;
    transaction_date: string;
    amount: number;
    diff: number;
    cash: number;
    credit_card: number;
    debit_card: number;
    voucher: number;
    description: string;
    cashier_capital_amount: number;
    cash_report: number;
    change: number;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || 0;
        this.user_id = model.user_id || 0;
        this.cashier_capital_id = model.cashier_capital_id || 0;
        this.transaction_date = model.transaction_date || null;
        this.amount = model.amount || 0;
        this.diff = model.diff || 0;
        this.cash = model.cash || 0;
        this.credit_card = model.credit_card || 0;
        this.debit_card = model.debit_card || 0;
        this.voucher = model.voucher || 0;
        this.description = model.description || null;
        this.cashier_capital_amount = model.cashier_capital_amount || 0;
        this.cash_report = model.cash_report || 0;
        this.change = model.change || 0;
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}