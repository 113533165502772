import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MyService } from '../../service/my.service';

@Component({
  selector: 'app-price-group',
  templateUrl: './price-group.component.html',
  styleUrls: ['./price-group.component.scss'],
})
export class PriceGroupComponent implements OnInit {

  @Input() productId: any;
  @Input() productName: any;
  @Input() productPrice: any;

  newProductPrice: number = 0;
  displayProductPrice: string = '';

  constructor(
    public modalController: ModalController,
    private myService :MyService
  ) { }

  ngOnInit() {}

  async dismissModal() {
    let close: any[] = [];

    if(this.newProductPrice !== 0) {
      let productData = {
        product_id: this.productId,
        price: this.newProductPrice
      };

      close.push(productData);
    } else {
      let productData = {
        product_id: this.productId,
        price: this.productPrice
      };

      close.push(productData);
    }
    
    await this.modalController.dismiss(close);
  }
  getChangeNominal(){
      this.displayProductPrice = this.myService.separator(this.displayProductPrice.toString());
      this.displayProductPrice = String(this.displayProductPrice).replace(/[^0-9,]/g, '');
      this.newProductPrice =this.myService.removeSeparator(this.displayProductPrice);
    
  }

}
