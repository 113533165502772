import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReportSummarySalesPageRoutingModule } from './report-summary-sales-routing.module';

import { ReportSummarySalesPage } from './report-summary-sales.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReportSummarySalesPageRoutingModule
  ],
  declarations: [ReportSummarySalesPage]
})
export class ReportSummarySalesPageModule {}
