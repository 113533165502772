import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PromotionApprovalPageRoutingModule } from './promotion-approval-routing.module';

import { PromotionApprovalPage } from './promotion-approval.page';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,NgxDatatableModule,
    PromotionApprovalPageRoutingModule
  ],
  declarations: [PromotionApprovalPage]
})
export class PromotionApprovalPageModule {}
