import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { UserData } from '../../providers/user-data';
import { UtilService } from '../../service/util.service';
import { SetupApprovalService } from './setup-approval.service';
import { RoleAccess } from '../../models/role-access.model';
import { UserProfile } from '../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { AES } from 'crypto-js';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-setup-approval',
  templateUrl: './setup-approval.page.html',
  styleUrls: ['./setup-approval.page.scss'],
})
export class SetupApprovalPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };
  rows: any;
  token: any;
  keywordSearch: any;
  filteredData: any;

  roleAccess = new RoleAccess();
  userProfile: UserProfile = new UserProfile();  

  constructor(
    private utilService: UtilService,
    private userData: UserData,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private setupApprovalService: SetupApprovalService,    
    private storage: Storage,
    private router: Router
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.pageCallback({ offset: this.page.offset });
        } 
      }
    });
    
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords" :this.keywordSearch ? this.keywordSearch : 'null'
      };

      this.setupApprovalService.getSetupApproval(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        //this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;

        
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  goToUpdatePage(id:any){
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/setup-approval/setup-approval-edit'], navigationExtras);
  }
}
