import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterProductCounterPage } from './master-product-counter.page';

const routes: Routes = [
  {
    path: '',
    component: MasterProductCounterPage
  },
  {
    path: 'master-product-counter-edit',
    loadChildren: () => import('./master-product-counter-edit/master-product-counter-edit.module').then( m => m.MasterProductCounterEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterProductCounterPageRoutingModule {}
