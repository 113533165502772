export class ApprovalHistory {

    transaction_id: string;
    level: number;
    username: string;
    status: string;
    trans_date: string;
    remark: string;

    constructor(model?) {
        model = model || {};
        this.transaction_id = model.transaction_id || null;
        this.level = model.level || 0;
        this.username = model.username || null;
        this.status = model.status || '0';
        this.trans_date = model.trans_date || null;
        this.remark = model.remark || null;
    }
}