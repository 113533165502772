import { Injectable } from '@angular/core';
import { Observable, Subject, fromEvent, merge, of} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

@Injectable({ providedIn: 'root' })
export class DisplayCustomerProvider {

  public appIsOnline$: Observable<any>;

  public storageSub= new Subject<string>();


  constructor(
    private storage: Storage) {

    this.initConnectivityMonitoring();

  }

  private initConnectivityMonitoring() {
    
    if (localStorage.getItem('1')=='-10') return ;
    this.appIsOnline$ = merge(
      of(null),
      fromEvent(window, 'storage')
    ).pipe(map(() => this.gettest()))
   


  }
  async gettest() {
    let av ='y';
    await Promise.all([
      this.storage.get('abc'),
    ])
    .then(([abc]) => {
      av = abc; 
      
    });
    console.log('AV :'+av);
    return av;
  }
}