import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PromotionUploadPageRoutingModule } from './promotion-upload-routing.module';

import { PromotionUploadPage } from './promotion-upload.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PromotionUploadPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [PromotionUploadPage]
})
export class PromotionUploadPageModule {}
