import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { VoucherService } from '../voucher.service';
import { MstVoucher } from '../../../models/mst-voucher.model';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-voucher-create',
  templateUrl: './voucher-create.page.html',
  styleUrls: ['./voucher-create.page.scss'],
})
export class VoucherCreatePage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  formVoucherCreate: FormGroup;
  currencyList: any[] = [];
  voucherCode: any[] = [];
  nominalVoucher: any = 0;
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private voucherService: VoucherService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormVoucherCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.utilService.loadingPresent('Harap tunggu...')
          .then(() => {
            this.voucherService.getVoucherforCreate({ "token": this.token }).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.currencyList = response.results;
            });
          });
        }
      }
    });
  }

  buildFormVoucherCreate() {
    this.formVoucherCreate = this.fb.group({
      nominal: [0, Validators.required],
      currencyId: [null, Validators.required],
      voucherTotal: [0, Validators.required],
      numberLength: [0, Validators.required],
      expiredDate: [null, Validators.required]
    });
  }

  generateVoucher() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const voucherForm = this.formVoucherCreate.getRawValue();
      let totalCode: any = voucherForm.voucherTotal;
      let lengthCode: any = voucherForm.numberLength;
      this.nominalVoucher = voucherForm.nominal;
      this.voucherCode = [];

      if(totalCode !== 0 && lengthCode !== 0) {
        let minValue: any = '1';
        let maxValue: any = '9';
        for(let x = 1; x < lengthCode; x++) {
          minValue = minValue + '0';
          maxValue = maxValue + '9';
        }

        let options = {
          "token": this.token,
          "min_value": minValue,
          "max_value": maxValue,
          "total_code": totalCode
        };

        this.voucherService.getGenerateVoucher(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 200) {
            this.voucherCode = response.results;
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      } else {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan isi jumlah digit dan jumlah kode voucher terlebih dahulu' }).then(t => t.present());
      }
    });
  }

  inputVoucher() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      if(this.voucherCode.length !== 0) {
        const voucherForm = this.formVoucherCreate.getRawValue();
        let dateConvert = this.utilService.convertDate(voucherForm.expiredDate);
        let newExpiredDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;
        
        const mstVoucher = new MstVoucher();
        mstVoucher.voucher_code = this.voucherCode;
        mstVoucher.nominal = voucherForm.nominal;
        mstVoucher.currency = voucherForm.currencyId;
        mstVoucher.expired_date = newExpiredDate;
        mstVoucher.status = '0';
        mstVoucher.created_by = this.userProfile.username;

        this.voucherService.addVoucher(mstVoucher).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.showConfirmInput();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      } else {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan generate voucher terlebih dahulu.' }).then(t => t.present());
      }
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/voucher']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
