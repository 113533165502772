import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OtherExpencesPage } from './other-expences.page';

const routes: Routes = [
  {
    path: '',
    component: OtherExpencesPage
  },
  {
    path: 'other-expences-create',
    loadChildren: () => import('./other-expences-create/other-expences-create.module').then( m => m.OtherExpencesCreatePageModule)
  },
  {
    path: 'other-expences-edit',
    loadChildren: () => import('./other-expences-edit/other-expences-edit.module').then( m => m.OtherExpencesEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OtherExpencesPageRoutingModule {}
