import { MstProduct } from './mst-product.model';
import { MstProductPackage } from './mst-product-package.model';
import { MstCounter } from './mst-counter.model';
import { MstWarehouse } from './mst-warehouse.model';
import { StockMutation } from './stock-mutation.model';

export class MstProductPackageBundle {

    allocationQty: number;
    mstProductPackage: MstProductPackage;
    mstProduct: MstProduct;
    mstCounter: MstCounter;
    mstWarehouse: MstWarehouse;
    stockMutation: StockMutation;

    constructor(model?) {
        model = model || {};
        this.allocationQty = model.allocationQty || 0;
        this.mstProductPackage = model.mstProductPackage || new MstProductPackage();
        this.mstProduct = model.mstProduct || new MstProduct();
        this.mstWarehouse = model.mstWarehouse || new MstWarehouse();
        this.mstCounter = model.mstCounter || new MstCounter();
        this.stockMutation = model.stockMutation || new StockMutation();
    }
}