import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { UserData } from '../../providers/user-data';
import { MasterProductGimmickService } from '../master-product-gimmick/master-product-gimmick.service';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { RoleAccess } from '../../models/role-access.model';
import { UserProfile } from '../../models/user-profile.model';
import AES = require('crypto-js/aes');

@Component({
  selector: 'app-master-product-gimmick',
  templateUrl: './master-product-gimmick.page.html',
  styleUrls: ['./master-product-gimmick.page.scss'],
})
export class MasterProductGimmickPage implements OnInit {

 
  baseUrl: any;
  token: any;
  roleAccess = new RoleAccess();
  userProfile: UserProfile = new UserProfile();
  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: 'Menu_Name',
    orderDir: 'desc'
  };
  rows: any;
  keywordSearch: any;
  productList: any;
  filteredData: any;
  
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private alertController: AlertController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private modalController: ModalController,
    private mstProductGimmickService: MasterProductGimmickService,
    private router: Router
    ) {
      this.baseUrl = environment.apiUrl;
    }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.utilService.loadingPresent('Harap tunggu...')
          .then(() => {
              this.utilService.loadingDismiss();
              this.pageCallback({ offset: this.page.offset });
          });
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  
  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords": this.keywordSearch ? this.keywordSearch : 'null',
      };

      this.mstProductGimmickService.getProductGimmick(options)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        //this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.productList = response.results.data;
        console.log(this.productList);
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }
  
  goToMstProductGimmickView(id:any){
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams:{
        i:encryptID
      }
    };
    this.navCtrl.navigateForward(['/master-product-gimmick/master-product-gimmick-detail'],navigationExtras);
  }
  goToMstProductGimmickCreate(){
    this.navCtrl.navigateForward(['/master-product-gimmick/master-product-gimmick-create']);    
  }
  goToMstProductGimmickUpdatePage(id: any) {
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/master-product-gimmick/master-product-gimmick-edit'], navigationExtras);
  }
  
  searchWithKeywords() {
    this.pageCallback({ offset: this.page.offset });
  }
}
