import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SetupResellerRewardCreatePage } from './setup-reseller-reward-create.page';

const routes: Routes = [
  {
    path: '',
    component: SetupResellerRewardCreatePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SetupResellerRewardCreatePageRoutingModule {}
