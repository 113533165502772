import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class CompletePluginService implements AutoCompleteService {

  baseUrl: any;
  token: any;

  labelAttribute = 'item';
  formValueAttribute = 'item';

  constructor(
    private http: HttpClient,
    private storage: Storage) {
    this.baseUrl = environment.apiUrl;

    this.storage.get('user_token')
    .then((token) => {
      if(token) {
        this.token = token;
      }
    });
  }

  getResults(keyword: string) {
    if (!keyword) { return false; }

     return this.http.get(this.baseUrl + 'sales-transaction/product-concat' + '?token=' + this.token).pipe(map(
        (response: any) => {
          let data = response.results;
           return data.filter(
              (result) => {
                 return result.item.toLowerCase().includes(keyword.toLowerCase());
              }
           );
        }
     ));
  }
}
