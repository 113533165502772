import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PromotionEditPageRoutingModule } from './promotion-edit-routing.module';

import { PromotionEditPage } from './promotion-edit.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    PromotionEditPageRoutingModule
  ],
  declarations: [PromotionEditPage]
})
export class PromotionEditPageModule {}
