export class WarehouseMutation {
    
    id: string;
    from_counter_id: number;
    from_warehouse_id: number;
    to_counter_id: number;
    to_warehouse_id: number;
    warehouse_mutation_type_id: number;
    desc: string;
    status: string;
    receive_status: string;
    created_by: string;
    updated_by: string;
    
    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.from_counter_id = model.from_counter_id || 0;
        this.from_warehouse_id = model.from_warehouse_id || 0;
        this.to_counter_id = model.to_counter_id || 0;
        this.to_warehouse_id = model.to_warehouse_id || 0;
        this.warehouse_mutation_type_id = model.warehouse_mutation_type_id || 0;
        this.desc = model.desc || null;
        this.status = model.status || '0';
        this.receive_status = model.receive_status || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}