import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../../models/user-profile.model';
import { UtilService } from '../../../service/util.service';
import { SetorKasirService } from '../setor-kasir.service';
import { SetorKasir } from '../../../models/setor-kasir.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setor-kasir-create',
  templateUrl: './setor-kasir-create.page.html',
  styleUrls: ['./setor-kasir-create.page.scss'],
})
export class SetorKasirCreatePage implements OnInit {

  formSetorKasirCreate: FormGroup;
  userProfile: UserProfile = new UserProfile();
  token: any;
  userKasirList: any[] =  [];
  paymentMethodTypeList: any[] =  [];
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private setorKasirService: SetorKasirService,
    private userData: UserData,
    private router: Router
  ) {  }

  ngOnInit() {
    this.fromBuildSetorKasirCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.utilService.loadingPresent('Harap tunggu...')
          .then(() => {
            this.userProfile = new UserProfile(profile);
            let convertDate = this.utilService.convertDate(new Date());
            let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
            this.setorKasirService.getUserKasir( this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list,{ "token": this.token}).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.userKasirList = response.results.user_kasir_data;
              this.formSetorKasirCreate.patchValue({transDate: this.userProfile.counter_detail ? this.userProfile.counter_detail.trans_date : todayDate});
            });
          });
        }
    })

  }

  fromBuildSetorKasirCreate() {
    let initFg = {
      transDate: [null, Validators.required],
      userKasir: [0, Validators.required],
      noModalKasir: [0, Validators.required],
      nilaiModal: [0, Validators.required],
      tunai: [0, Validators.required],
      kembalian: [0, Validators.required],
      nilaiTunai: [0, Validators.required],
      kKredit: [0, Validators.required],
      kDebit: [0, Validators.required],
      voucher: [0, Validators.required],
      nilaiSetor: [0, Validators.required],
      selisih: [0, Validators.required],
      keterangan: [null, Validators.required]
    };
    
    this.formSetorKasirCreate = this.fb.group(initFg);
  }

  inputSetorKasir() {
    this.utilService.loadingPresent('Harap Tunggu ...')
      .then(() => {
        const setorKasirForm = this.formSetorKasirCreate.getRawValue();
        let dateConvert = this.utilService.convertDate(setorKasirForm.transDate);
        let newTransDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;

        const setorKasir = new SetorKasir();
        setorKasir.counter_id = this.userProfile.counter_id;
        setorKasir.user_id = setorKasirForm.userKasir;
        setorKasir.cashier_capital_id = setorKasirForm.noModalKasir;
        setorKasir.transaction_date = newTransDate;
        setorKasir.amount = setorKasirForm.nilaiSetor;
        setorKasir.diff = setorKasirForm.selisih;
        setorKasir.cash = setorKasirForm.nilaiTunai;
        setorKasir.credit_card = setorKasirForm.kKredit;
        setorKasir.debit_card = setorKasirForm.kDebit;
        setorKasir.voucher = setorKasirForm.voucher;
        setorKasir.description = setorKasirForm.keterangan;
        setorKasir.cashier_capital_amount = setorKasirForm.nilaiModal;
        setorKasir.cash_report = setorKasirForm.tunai;
        setorKasir.change = setorKasirForm.kembalian;
        setorKasir.created_by = this.userProfile.username;

        this.setorKasirService.addSetorKasir(setorKasir).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.showConfirmInput();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        })

    })
  }

  getNilai(event) {
    let userKasirId = event.detail.value;
    const setorKasirForm = this.formSetorKasirCreate.getRawValue();
    let dateConvert = this.utilService.convertDate(setorKasirForm.transDate);
    let transDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.setorKasirService.getNilaiSetorKasir(userKasirId, transDate, { "token": this.token}).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.formSetorKasirCreate.patchValue({
          noModalKasir : response.results.cashier_capital_data.id,
          nilaiModal : response.results.cashier_capital_data.amount,
          tunai: response.results.deposite_data.tot_cash,
          kembalian: response.results.deposite_data.tot_change,
          nilaiTunai: response.results.deposite_data.tot_cash_act,
          kKredit: response.results.deposite_data.tot_credit_card,
          kDebit: response.results.deposite_data.tot_debit_card,
          voucher: response.results.deposite_data.tot_voucher
        });

        this.hitungSelisih();
      });
    });
  }

  clearInput() {
    this.formSetorKasirCreate.patchValue({
      userKasir : 0,
      noModalKasir : 0,
      nilaiModal : 0,
      tunai : 0,
      kembalian : 0,
      nilaiTunai : 0,
      kKredit : 0,
      kDebit : 0,
      voucher : 0,
      nilaiSetor : 0,
      selisih : 0,
      keterangan : null
    });
  }

  hitungSelisih() {
    const setorKasirForm = this.formSetorKasirCreate.getRawValue();
    let nilaiModal = parseInt(setorKasirForm.nilaiModal);
    let nilaiSetor = parseInt((setorKasirForm.nilaiSetor === null) ? 0 : setorKasirForm.nilaiSetor) ;
    let nilaiTunai = parseInt(setorKasirForm.nilaiTunai);

    let selisih = nilaiSetor - (nilaiModal + nilaiTunai);
    this.formSetorKasirCreate.patchValue({selisih: selisih});
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/setor-kasir']);;
          }
        }
      ]
    });

    await alert.present();
  }

};
