import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { VoucherService } from '../voucher.service';
import { MstVoucher } from '../../../models/mst-voucher.model';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-voucher-edit',
  templateUrl: './voucher-edit.page.html',
  styleUrls: ['./voucher-edit.page.scss'],
})
export class VoucherEditPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  formVoucherEdit: FormGroup;
  id:any;
  batchNo: any;
  voucherData: any;
  voucherCodeList: any[] = [];
  currencyList: any[] = [];
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private voucherService: VoucherService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormVoucherEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormVoucherEdit() {
    this.formVoucherEdit = this.fb.group({
      batchNo: [],
      nominal: [],
      currencyId: [],
      expiredDate: []
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.id = AES.decrypt(snapshot.i, myKey).toString(utf8);

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.voucherService.getVoucherforEdit(this.id, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.voucherData = response.results.header_data;
          this.voucherCodeList = response.results.detail_data;
          this.currencyList = response.results.currency_data;

          this.formVoucherEdit = this.fb.group({
            batchNo: [this.voucherData[0].batch_no, Validators.required],
            nominal: [this.voucherData[0].nominal, Validators.required],
            currencyId: [this.voucherData[0].currency, Validators.required],
            expiredDate: [this.voucherData[0].expired_date, Validators.required]
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateVoucher() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const voucherForm = this.formVoucherEdit.getRawValue();
      let dateConvert = this.utilService.convertDate(voucherForm.expiredDate);
      let newExpiredDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;
      const mstVoucher = new MstVoucher();
      mstVoucher.nominal = voucherForm.nominal;
      mstVoucher.currency = voucherForm.currencyId;
      mstVoucher.expired_date = newExpiredDate;
      mstVoucher.updated_by = this.userProfile.username;

      this.voucherService.updateVoucher(this.batchNo, mstVoucher).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/voucher']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
