import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, AlertController, ToastController, ModalController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { CashierComponent } from '../../component/advanced-filter/cashier/cashier.component';
import { CashierService } from './cashier.service';
import { MstCashier } from '../../models/mst-cashier.model';
import { RoleAccess } from '../../models/role-access.model';
import { AES } from 'crypto-js';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.page.html',
  styleUrls: ['./cashier.page.scss'],
})
export class CashierPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  modelData: any;
  rows: any;
  cashierList: any;
  filteredData: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterId: any = '';
  accessType: any;
  visitorId: any;
  counterList: any[] = [];

  db: any;
  keywordSearch: any;
  roleAccess = new RoleAccess();

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private alertController: AlertController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private modalController: ModalController,
    private cashierService: CashierService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('visitor_id'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, visitorId, access]) => {
      if(token) {
        this.token = token;
        this.visitorId = visitorId;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
        else{
          this.accessType = this.userData.checkAccessType(this.userProfile.counter_id, this.userProfile.branch_id);
          this.pageCallback({ offset: this.page.offset });
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords" :this.keywordSearch ? this.keywordSearch : 'null',
        "counter_id": this.counterId !== '' ? this.counterId : this.userProfile.counter_id_list
      };

      this.cashierService.getCashier(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        //this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.cashierList = response.results.data;

        if(this.counterList.length === 0) {
          this.getCounterList();
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  getCounterList() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.cashierService.getCounter({ "token": this.token }).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.counterList = response.results;
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Counter' }).then(t => t.present());
      });
    });
  }

  clearFilters() {
    this.counterId = '';
    this.pageCallback({ offset: this.page.offset });
  }

  goToCashierCreate() {
    this.navCtrl.navigateForward(['/cashier/cashier-create']);
  }

  goToUpdatePage(id: any) {
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/cashier/cashier-edit'], navigationExtras);
  }

  deleteData(id: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.cashierService.deleteCashier(id).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showDeleteSuccess();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showDeleteSuccess() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Berhasil hapus data!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.pageCallback({ offset: this.page.offset });
          }
        }
      ]
    });

    await alert.present();
  }

  async showConfirmDelete(id: any) {
    const alert = await this.alertController.create({
      header: 'Delete Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk hapus data ini?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: () => {
            this.deleteData(id);
          }
        }
      ]
    });

    await alert.present();
  }

  async showConfirmCashierSetup(data: any) {
    let notifMessage: any = '';
    let findSetupCashier: any = this.cashierList.find(x => x.setup_device_id === this.visitorId);
    if(findSetupCashier) {
      if(data.setup_device_id === findSetupCashier.setup_device_id) {
        notifMessage = "Anda yakin untuk melepas kode Kassa '" + data.cashier_name + "' dari device ini?";
      } else {
        let messageSetup: any = "Pada device ini sudah terpasang kode Kassa : " + findSetupCashier.cashier_name;
        this.toastCtrl.create({ duration: 2000, message: messageSetup }).then(t => t.present());
      }
    } else {
      if(data.setup_device_id !== null) {
        if(data.setup_device_id === this.visitorId) {
          notifMessage = "Anda yakin untuk melepas kode Kassa '" + data.cashier_name + "' dari device ini?";
        } else {
          let messageSetup: any = "Pada device ini sudah terpasang kode Kassa : " + data.cashier_name;
          this.toastCtrl.create({ duration: 2000, message: messageSetup }).then(t => t.present());
        }
      } else {
        notifMessage = "Anda yakin untuk memasang kode Kassa '"+ data.cashier_name + "' pada device ini?";
      }
    }

    if(notifMessage !== '') {
      const alert = await this.alertController.create({
        header: 'Setup Confirmation',
        cssClass:'custom-alert-class',
        message: notifMessage,
        buttons: [
          {
            text: 'Cancel',
            handler: () => {}
          },
          {
            text: 'OK',
            handler: () => {
              this.setupCashier(data);
            }
          }
        ]
      });
  
      await alert.present();
    }
  }

  async showNotifAfterCashierSetup(setup: any) {
    let message = setup === '1' ? "Berhasil pasang kode Kassa!" : "Berhasil lepas kode Kassa!";
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.pageCallback({ offset: this.page.offset });
          }
        }
      ]
    });

    await alert.present();
  }

  setupCashier(data: any) {
    this.utilService.loadingPresent()
    .then(() => {
      const mstCashier = new MstCashier();
      mstCashier.setup = data.setup === '0' ? '1' : '0';
      mstCashier.setup_device_id = data.setup === '0' ? this.visitorId : null;
      mstCashier.updated_by = this.userProfile.username;

      this.cashierService.setupCashier(data.id, mstCashier).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showNotifAfterCashierSetup(mstCashier.setup);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, (responseErr) => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: responseErr.error.message }).then(t => t.present());
        this.pageCallback({ offset: this.page.offset });
      });
    });
  }

  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: CashierComponent,
      componentProps: {
        counterList: this.counterList
      }
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData.data !== undefined) {
        this.counterId = modelData.data;
        this.reloadTable();
      } else {
        this.counterId = '';
        this.reloadTable();
      }
    });

    return await modal.present();
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 5;
    let keys = Object.keys(this.cashierList[0]);
    this.rows = this.filteredData.filter(function(item){
      for (let i=0; i < columnLength; i++){
        if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });

    this.page.offset = 0;
  }

  /*openDB() {
    this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  createTable(tableName: any, columnList: any) {
    let sqlQuery: string = 'CREATE TABLE IF NOT EXISTS ' + tableName + columnList;

    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(sqlQuery, [],
        (tx, result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  execQuery(sqlQuery: any) {
    this.db.transaction((tx) => {
      tx.executeSql(sqlQuery, [],
      (tx, result) => {
      }, (error) => {
        console.log(error);
      });
    });
  }

  getLocalCashier() {
    let selectQuery = 'SELECT * FROM mst_cashiers_setup';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            let cashierData = {
              'id': result.rows[0].id,
              'counter_id': result.rows[0].counter_id,
              'cashier_name': result.rows[0].cashier_name,
              'active': result.rows[0].active,
              'setup': result.rows[0].setup,
              'created_by': result.rows[0].created_by,
              'updated_by': result.rows[0].updated_by,
              'created_at': result.rows[0].created_at,
              'updated_at': result.rows[0].updated_at,
            };
            this.localCashierList = cashierData;
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  insertLocalCashierSetup(response: any) {
    let tableName = 'mst_cashiers_setup';
    let columnList = '(id, counter_id, cashier_name, active, setup, created_by, updated_by, created_at, updated_at)';
    
    let setupVal: any = response.setup === '0' ? '1' : '0';

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.createTable(tableName, columnList).then((res) => {
        let insertQuery = 'INSERT INTO ' + tableName + columnList + ' VALUES (' +
        response.id + ', ' + response.counter_id + ', "' +
        response.cashier_name + '", "' + response.active + '", "' +
        setupVal + '", "' + response.created_by + '", "' +
        response.updated_by + '", "' + response.created_at + '", "' + 
        response.updated_at + '")';
  
        let selectQuery = 'SELECT * FROM ' + tableName
        let deleteQuery = 'DELETE FROM ' + tableName
  
        this.db.transaction((tx) => {
          tx.executeSql(selectQuery, [],
          (tx, result) => {
            this.utilService.loadingDismiss();
            if(result.rows.length === 0) {
              if(setupVal === '1') {
                this.execQuery(insertQuery);
                this.getLocalCashier();
              } else {
                this.execQuery(deleteQuery);
                this.localCashierList = null;
              }
              this.showNotifAfterCashierSetup();
            } else {
              if(result.rows[0].id === response.id) {
                this.execQuery(deleteQuery);
                this.localCashierList = null;
                if(setupVal === '1') {
                  this.execQuery(insertQuery);
                  this.getLocalCashier();
                }
                this.showNotifAfterCashierSetup();
              } else {
                let messageSetup: any = 'Pada device ini sudah terpasang kode Kassa : ' + response.cashier_name;
                this.toastCtrl.create({ duration: 2000, message: messageSetup }).then(t => t.present());
              }
            }
          }, (error) => {
            this.utilService.loadingDismiss();
            console.log(error);
          });
        });
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
      });
    });
  }

  getCounterList() {
    this.cashierService.getCounter({ "token": this.token }).subscribe(response => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          let tableName = 'mst_counters';
          let columnList = '(id, branch_id, counter_name, trans_date, first_address, last_address, phone, footer_text, latitude, longitude, active, created_by, updated_by, created_at, updated_at)';
          
          this.createTable(tableName, columnList).then((res) => {
            for (let i = 0; i < response.results.length; i++) {

              let insertQuery = 'INSERT INTO ' + tableName + columnList + ' VALUES (' +
              response.results[i].id + ', ' + response.results[i].branch_id + ', "' +
              response.results[i].counter_name + '", "' + response.results[i].trans_date + '", "' +
              response.results[i].first_address + '", "' + response.results[i].last_address + '", "' +
              response.results[i].phone + '", "' + response.results[i].footer_text + '", "' +
              response.results[i].latitude + '", "' + response.results[i].longitude + '", "' +
              response.results[i].active + '", "' +
              response.results[i].created_by + '", "' + response.results[i].updated_by + '", "' +
              response.results[i].created_at + '", "' + response.results[i].updated_at + '")';

              let selectQuery = 'SELECT * FROM ' + tableName + ' WHERE id = ' + response.results[i].id;
              let deleteQuery = 'DELETE FROM ' + tableName + ' WHERE id = ' + response.results[i].id;

              this.db.transaction((tx) => {
                tx.executeSql(selectQuery, [],
                (tx, result) => {
                  if(result.rows.length === 0) {
                    this.execQuery(insertQuery);
                  } else {
                    this.execQuery(deleteQuery);
                    this.execQuery(insertQuery);
                  }
                }, (error) => {
                  console.log(error);
                });
              });
            }
          }, (err) => {
            console.log(err);
          });
        }
      }
    }, () => {
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  relog() {
    Promise.all([
      this.storage.get('user_email'),
      this.storage.get('user_password')
    ])
    .then(([email, password]) => {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.loginService.userLogin(email, password)
        .subscribe((response: any) => {
          this.utilService.loadingDismiss();
          if(response.token) {
            this.storage.set('user_token', response.token);
            this.toastCtrl.create({ duration: 2000, message: 'Refresh kembali laman ini.' }).then(t => t.present());
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Email atau Password salah!' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }*/

}
