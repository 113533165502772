import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpService: HttpService) { }

  
  getChartSales(params: any): Observable<any> {
    return this.httpService.get('dashboard/chart-sales', params);
  }
  getChartSalesTarget(params: any): Observable<any> {
    return this.httpService.get('dashboard/chart-sales-target', params);
  }


  getSumarySalesPerCounter(params: any): Observable<any> {
    return this.httpService.get('dashboard/summary-sales-percounter', params);
  }
}
