import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { RoleAccess } from '../../models/role-access.model';
import { ClaimIncentiveService } from './claim-incentive.service';
import { ResellerTransactionListComponent } from '../../component/reseller-transaction-list/reseller-transaction-list.component';
@Component({
  selector: 'app-claim-incentive',
  templateUrl: './claim-incentive.page.html',
  styleUrls: ['./claim-incentive.page.scss'],
})
export class ClaimIncentivePage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };
  periode: any= new Date().toISOString();
  totalBelanja: any='all';
  baseUrl: any;
  rows: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  customerList: any;
  filteredData: any;
  keywordSearch: any='';  
  roleAccess = new RoleAccess();          
  startDate: any= new Date().toISOString();
  endDate: any= new Date().toISOString();
  incentiveList:any[];
  setupIncentive:any='';
  monthly:boolean=false; 
  year: any;
  month: any;
  periodeId:any;
  periodeType:any;
  arrSumData: any[] = [];
  reportData: any;
  id:number=0;
  constructor(
    private storage: Storage,
    private http: HttpClient,
    private navCtrl: NavController,
    private alertController: AlertController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private router: Router,
    private claimIncentiveService:ClaimIncentiveService,
    private modalController:ModalController
  ) { 
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        this.reloadTable();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  changePeriode(){  
    let dateConvert = this.utilService.convertDate(this.periode);
    this.year = dateConvert.years;
    this.month = dateConvert.months;

  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
        let setupIncetiveId ='';
        this.id=0;
        this.periodeType=0;
        if(this.setupIncentive!=''){
          setupIncetiveId = this.setupIncentive['id']
          this.id = this.setupIncentive['id'];
          this.periodeType = this.setupIncentive['periode'];
        }
        
        const params = new HttpParams()
        .set('token', this.token)
        .set('year', this.year)
        .set('month', this.month)
        .set('start_date', this.startDate)
        .set('end_date', this.endDate)
        .set('total_belanja', this.totalBelanja)
        .set('page', offset.toString())
        .set('setup_incentive',setupIncetiveId)
        .set('keywords',this.keywordSearch);

      this.http.get(this.baseUrl + 'claim-incentive', { params })
      .subscribe((response: any) => {
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.customerList = response.results.data;
        this.incentiveList = response.setup_incentive;
        this.utilService.loadingDismiss();
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  async showDeleteSuccess() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Berhasil hapus data!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.pageCallback({ offset: this.page.offset });
          }
        }
      ]
    });

    await alert.present();
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 5;
    let keys = Object.keys(this.customerList[0]);
    this.rows = this.filteredData.filter(function(item){
      console.log('item', item);
      for (let i=0; i < columnLength; i++){
        if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });

    this.page.offset = 0;
  }

  incentiveChange(){
    let detailSetupIncentive = this.incentiveList.find(x => x.id === this.setupIncentive['id']);
    this.monthly =false;
    if(detailSetupIncentive){
      let idx = this.incentiveList.indexOf(detailSetupIncentive);
      this.periodeId=this.incentiveList[idx]['periode'];
      if(this.incentiveList[idx]['periode']=='0'){
        let dateConvert = this.utilService.convertDate(this.periode);
        this.year = dateConvert.years;
        this.month = dateConvert.months;
        this.monthly=true;
      }
      else{
        this.startDate = this.incentiveList[idx]['early_period'];
        this.endDate = this.incentiveList[idx]['end_period'];
      }
    }
  }
  
  async openListTransaction(setupId: any, resellerId: any,totalBelanja:any,totalBelanjaDownline:any) {
    if( this.periodeType=='0'){
      totalBelanja = parseInt(totalBelanja)+parseInt(totalBelanjaDownline);
    }
    const modal = await this.modalController.create({
      component: ResellerTransactionListComponent,
      componentProps: {
        'setupId': setupId,
        'resellerId': resellerId,
        'totalBelanja':totalBelanja,
        'year':this.year,
        'month':this.month,
        'username': this.userProfile.username,
        'userProfile':this.userProfile,
        'typeForm':'give'
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then(() => {
      this.reloadTable();
    });

    return await modal.present();
  }

  generate(){
    if(!this.setupIncentive['id']){
      this.toastCtrl.create({ duration: 2000, message: 'Mohon Pilih Reward' }).then(t => t.present());
    }
    else{
      this.rows=[];
      this.page.count = 0;
      this.filteredData = '';
      this.reloadTable();
    }
  }
}