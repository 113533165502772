import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PaymentVoucherCreatePage } from './payment-voucher-create.page';

const routes: Routes = [
  {
    path: '',
    component: PaymentVoucherCreatePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentVoucherCreatePageRoutingModule {}
