export class MstProductPackage {

    product_package_id: string[];
    product_id: string[];
    price: number[];
    qty: number[];
    active: string[];
    created_by: string[];
    updated_by: string[];

    constructor(model?) {
        model = model || {};
        this.product_package_id = model.product_package_id || [];
        this.product_id = model.product_id || [];
        this.price = model.price || [];
        this.qty = model.qty || [];
        this.active = model.active || [];
        this.created_by = model.created_by || [];
        this.updated_by = model.updated_by || [];
    }
}