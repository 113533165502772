import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-product-sales-free-item',
  templateUrl: './product-sales-free-item.component.html',
  styleUrls: ['./product-sales-free-item.component.scss'],
})
export class ProductSalesFreeItemComponent implements OnInit {

  @Input() pCode: any;
  @Input() productName: any;
  @Input() qtyFree: any;

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  async dismissModal() {
    let close: any[] = [];

    let productSalesData = {
      qtyFree: this.qtyFree ? this.qtyFree : 0,
    };

    close.push(productSalesData);
    
    await this.modalController.dismiss(close);
  }

}
