import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { CashierService } from '../cashier.service';
import { MstCashier } from '../../../models/mst-cashier.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cashier-edit',
  templateUrl: './cashier-edit.page.html',
  styleUrls: ['./cashier-edit.page.scss'],
})
export class CashierEditPage implements OnInit {

  formCashierEdit: FormGroup;
  cashierId: any;
  cashierData: any;
  token: any;
  counterData: any[] = [];
  statusCashier: any[] = ['Not Active', 'Active'];
  userProfile: UserProfile = new UserProfile();  
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private cashierService: CashierService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormCashierEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormCashierEdit() {
    this.formCashierEdit = this.fb.group({
      counterId: [this.cashierData ? this.cashierData.counter_id : 0, Validators.required],
      cashierName: [this.cashierData ? this.cashierData.cashier_name : 0, Validators.required],
      activeStatus: [this.cashierData ? parseInt(this.cashierData.active) : 0, Validators.required]
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.cashierId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let options = {
          "token": this.token,
          "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
        };
        this.cashierService.getCashierforEdit(this.cashierId, options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.cashierData = response.results.data;
          this.counterData = response.results.counter_data;

          this.buildFormCashierEdit();
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const cashierForm = this.formCashierEdit.getRawValue();
      const mstCashier = new MstCashier();
      mstCashier.counter_id = cashierForm.counterId;
      mstCashier.cashier_name = cashierForm.cashierName;
      mstCashier.active = cashierForm.activeStatus;
      mstCashier.updated_by = this.userProfile.username;

      this.cashierService.updateCashier(this.cashierId, mstCashier).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/cashier']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
