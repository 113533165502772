import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { ProductRequestService } from '../product-request.service';
import { ProductRequestBundle } from '../../../models/product-request-bundle.model';
import { FindProductComponent } from '../../../component/find-product/find-product.component';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-product-request-create',
  templateUrl: './product-request-create.page.html',
  styleUrls: ['./product-request-create.page.scss'],
})
export class ProductRequestCreatePage implements OnInit {

  token: any;
  productSearch: Product[];
  productList: Product[];
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  warehouseList: any[] = [];
  unitType: any[] = ['PIECES'];

  formProductRequestCreate: FormGroup;
  productRequestId: any;

  productRequestDetailList: any[] = [];
  
  roleAccess = new RoleAccess();
  warehouseId :any='';
  counterId :any='';  
  selectedWarehouses:any[];
  warehouseListShow:any[]=[];

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private modalController: ModalController,
    private requestService: ProductRequestService,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormProductRequest();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getAllProduct();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getAllProduct() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.requestService.getProduct({ "token": this.token }).subscribe((response) => {
        if (response.status.code === 200) {
          this.productList = response.results;
        }

        this.getProductRequestCreateData();
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  
  getProductRequestCreateData() {
    let options = { 
      "token": this.token,
      "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
    };

    this.requestService.getRequestforCreate(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      if (response.status.code === 200) {
        this.counterList = response.results.counter_data;
        this.warehouseList = response.results.warehouse_data;
        this.warehouseListShow = this.warehouseList;

        this.buildFormProductRequest();
      }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  buildFormProductRequest() {
    let newDate = new Date();
    let convertDate = this.utilService.convertDate(newDate);
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formProductRequestCreate = this.fb.group({
      docDate: [todayDate, Validators.required],
      needDate: [todayDate, Validators.required],
      counterId: [this.counterId !='' ? parseInt(this.counterId) : null, Validators.required],
      warehouseId: [this.warehouseId != '' ? parseInt(this.warehouseId) : null, Validators.required],
      description: [null, Validators.required]
    });
  }

  inputBundle() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formProductRequest = this.formProductRequestCreate.value;
      let counterId = this.counterId;
      let warehouseId = this.warehouseId;
      let desc = formProductRequest.description;
      let docDateConvert = this.utilService.convertDate(formProductRequest.docDate);
      let needDateConvert = this.utilService.convertDate(formProductRequest.needDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;
      let dateOfNeed = needDateConvert.years + '-' + needDateConvert.months + '-' + needDateConvert.dates;

      let arrProduct: any = [];
      let arrQty: any = [];
      let arrUnitType: any = [];

      for(let x = 0; x < this.productRequestDetailList.length; x++) {
        let htmlIdQtyRequest: any = 'qtyRequest_' + x;
        let qtyRequest: any = (<HTMLInputElement>document.getElementById(htmlIdQtyRequest)).value;

        arrProduct[x] = this.productRequestDetailList[x].product_id;
        arrQty[x] = parseInt(qtyRequest);
        arrUnitType[x] = 0;
      }

      const productRequestBundle = new ProductRequestBundle();
      productRequestBundle.productRequest.counter_id = counterId;
      productRequestBundle.productRequest.warehouse_id = warehouseId;
      productRequestBundle.productRequest.doc_date = documentDate;
      productRequestBundle.productRequest.need_date = dateOfNeed;
      productRequestBundle.productRequest.desc = desc;
      productRequestBundle.productRequest.status = '0';
      productRequestBundle.productRequest.created_by = this.userProfile.username;

      productRequestBundle.productRequestDetail.product_id = arrProduct;
      productRequestBundle.productRequestDetail.qty = arrQty;
      productRequestBundle.productRequestDetail.unit = arrUnitType;

      this.requestService.addRequestBundle(productRequestBundle)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/product-request']);;
          }
        }
      ]
    });

    await alert.present();
  }

  addDetail() {
    let length = this.productRequestDetailList.length;
    this.productRequestDetailList.push({
      'id' : length + 1,
      'product_id' : null,
      'product_name' : null,
      'unit': null
    });
  }

  async findProduct(index: any) {
    const modal = await this.modalController.create({
      component: FindProductComponent,
      componentProps: {
        'productList': this.productList
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if(data) {
        let findProduct = this.productRequestDetailList.indexOf(data);
        if(findProduct === -1) {
          this.productRequestDetailList[index].product_id = data.id;
          this.productRequestDetailList[index].product_name = data.product_name;
        }
      }
    });

    return await modal.present();
  }

  deleteProduct(index: any) {
    this.productRequestDetailList.splice(index, 1);
  }

  public selectWarehouse(event){
    this.warehouseId = event.value.id;
  }
    
  public selectCounter(event){
    this.counterId = event.value.id;
    this.warehouseId ='';
    this.selectedWarehouses=[];
    let filterWarehouse = this.warehouseList.filter(x => parseInt(x.counter_id) === parseInt(this.counterId));
    this.warehouseListShow = filterWarehouse;  
  }
}
