import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavController, AlertController, ToastController, ModalController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { RedemPointComponent } from '../../component/redem-point/redem-point.component';

@Component({
  selector: 'app-reward-end-point',
  templateUrl: './reward-end-point.page.html',
  styleUrls: ['./reward-end-point.page.scss'],
})
export class RewardEndPointPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  baseUrl: any;
  rows: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  customerList: any;
  filteredData: any;

  constructor(
    private storage: Storage,
    private http: HttpClient,
    private navCtrl: NavController,
    private alertController: AlertController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private modalController: ModalController
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.pageCallback({ offset: this.page.offset });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      const params = new HttpParams()
        .set('token', this.token)
        .set('page', offset.toString());

      this.http.get(this.baseUrl + 'reward-point-reseller', { params })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.customerList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  goToRegisterResellerCreate() {
    this.navCtrl.navigateForward(['/register-reseller/register-reseller-create']);
  }

  goToUpdatePage(id: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        resellerId: id
      }
    };
    this.navCtrl.navigateForward(['/register-reseller/register-reseller-edit'], navigationExtras);
  }

  deleteData(id: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const params = new HttpParams()
        .set('token', this.token)
      this.http.delete(this.baseUrl + 'customer-reseller/' + id, { params })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showDeleteSuccess();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showDeleteSuccess() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Berhasil hapus data!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.pageCallback({ offset: this.page.offset });
          }
        }
      ]
    });

    await alert.present();
  }

  async showConfirmDelete(id: any) {
    const alert = await this.alertController.create({
      header: 'Delete Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk hapus data ini?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: () => {
            this.deleteData(id);
          }
        }
      ]
    });

    await alert.present();
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 5;
    let keys = Object.keys(this.customerList[0]);
    this.rows = this.filteredData.filter(function(item){
      for (let i=0; i < columnLength; i++){
        if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });

    this.page.offset = 0;
  }

  async openRedemPoint(id,resellerName,sisaPoint) {
      const modal = await this.modalController.create({
        component: RedemPointComponent,
        componentProps: {
          'token': this.token,
          'id': id,
          'resellerName': resellerName,
          'sisaPoint': sisaPoint,
        },
        backdropDismiss: false
      });
  
      modal.onDidDismiss().then((modelData) => {
        this.reloadTable();
      });
      return await modal.present();
  }
}
