import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { SetorKasirService } from '../setor-kasir.service';
import { Storage } from '@ionic/storage';
import { SetorKasir } from '../../../models/setor-kasir.model';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-setor-kasir-edit',
  templateUrl: './setor-kasir-edit.page.html',
  styleUrls: ['./setor-kasir-edit.page.scss'],
})
export class SetorKasirEditPage implements OnInit {

  token: any;
  setorKasirId: any;
  setorKasirData: any;
  formSetorKasirEdit: FormGroup;
  userProfile: UserProfile = new UserProfile();
  userKasirList: any[] = [];
  roleAccess = new RoleAccess();
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private utilService: UtilService,
    private setorKasirService: SetorKasirService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private alertController: AlertController,
    private userData: UserData,
    private router: Router
  ) { 
    this.activatedRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.setorKasirId = AES.decrypt(snapshot.i, myKey).toString(utf8);
    })
  }

  ngOnInit() {
    this.fromBuildSetorKasirEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.utilService.loadingPresent('Harap tunggu...')
          .then(() => {
            this.userProfile = new UserProfile(profile);
            this.setorKasirService.getSetorKasirEdit(this.setorKasirId, this.userProfile.counter_id,{ "token": this.token}).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.setorKasirData = response.results.cashier_deposite_data;
              this.formSetorKasirEdit = this.fb.group({
                transDate: [this.setorKasirData.transaction_date, Validators.required],
                userKasir: [this.setorKasirData.user_kasir_name, Validators.required],
                noModalKasir: [this.setorKasirData.cashier_capital_id, Validators.required],
                nilaiModal: [this.setorKasirData.cashier_capital_amount, Validators.required],
                tunai: [this.setorKasirData.cash_report, Validators.required],
                kembalian: [this.setorKasirData.change, Validators.required],
                nilaiTunai: [this.setorKasirData.cash, Validators.required],
                kKredit: [this.setorKasirData.credit_card, Validators.required],
                kDebit: [this.setorKasirData.debit_card, Validators.required],
                voucher: [this.setorKasirData.voucher, Validators.required],
                nilaiSetor: [this.setorKasirData.amount, Validators.required],
                selisih: [this.setorKasirData.diff, Validators.required],
                keterangan: [this.setorKasirData.description, Validators.required]
              });
            });
          });
        }
    })

  }

  fromBuildSetorKasirEdit() {
    let initFg = {
      transDate: [null, Validators.required],
      userKasir: [0, Validators.required],
      noModalKasir: [0, Validators.required],
      nilaiModal: [0, Validators.required],
      tunai: [0, Validators.required],
      kembalian: [0, Validators.required],
      nilaiTunai: [0, Validators.required],
      kKredit: [0, Validators.required],
      kDebit: [0, Validators.required],
      voucher: [0, Validators.required],
      nilaiSetor: [0, Validators.required],
      selisih: [0, Validators.required],
      keterangan: [null, Validators.required]
    };
    
    this.formSetorKasirEdit = this.fb.group(initFg);
  }

  updateData() {
    this.utilService.loadingPresent('Harap Tunggu ...')
      .then(() => {
        const setorKasirForm = this.formSetorKasirEdit.getRawValue();
        let dateConvert = this.utilService.convertDate(setorKasirForm.transDate);
        let newTransDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;

        const setorKasir = new SetorKasir();
        setorKasir.counter_id = this.userProfile.counter_id;
        setorKasir.user_id = this.setorKasirData.user_id;
        setorKasir.cashier_capital_id = setorKasirForm.noModalKasir;
        setorKasir.transaction_date = newTransDate;
        setorKasir.amount = setorKasirForm.nilaiSetor;
        setorKasir.diff = setorKasirForm.selisih;
        setorKasir.cash = setorKasirForm.nilaiTunai;
        setorKasir.credit_card = setorKasirForm.kKredit;
        setorKasir.debit_card = setorKasirForm.kDebit;
        setorKasir.voucher = setorKasirForm.voucher;
        setorKasir.description = setorKasirForm.keterangan;
        setorKasir.cashier_capital_amount = setorKasirForm.nilaiModal;
        setorKasir.cash_report = setorKasirForm.tunai;
        setorKasir.change = setorKasirForm.kembalian;
        setorKasir.updated_by = this.userProfile.username;

        this.setorKasirService.updateSetorKasir(this.setorKasirId, setorKasir).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.showConfirmInput();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        })

    })
  }
  
  hitungSelisih() {
    const setorKasirForm = this.formSetorKasirEdit.getRawValue();
    let nilaiModal = parseInt(setorKasirForm.nilaiModal);
    let nilaiSetor = parseInt((setorKasirForm.nilaiSetor === null) ? 0 : setorKasirForm.nilaiSetor) ;
    let nilaiTunai = parseInt(setorKasirForm.nilaiTunai);

    let selisih = nilaiSetor - (nilaiModal + nilaiTunai);
    this.formSetorKasirEdit.patchValue({selisih: selisih});
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/setor-kasir']);;
          }
        }
      ]
    });

    await alert.present();
  }
}
