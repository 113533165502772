import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators,FormArray } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { PromotionsService } from '../promotions.service';
import { MstPromotion } from '../../../models/mst-promotion.model';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-promotion-create',
  templateUrl: './promotion-create.page.html',
  styleUrls: ['./promotion-create.page.scss'],
})
export class PromotionCreatePage implements OnInit {

  token: any;
  formPromotionCreate: FormGroup;
  promotionTypeData: any[] = [];
  counterList: any[] = [];
  warehouseList: any[] = [];
  userProfile: UserProfile = new UserProfile();
  //cashierType: any[] = ['Semua Kasir', 'Kasir Reguler', 'Kasir Reseller'];
  cashierType: any[] = ['Kasir Reguler'];

  db: any;
  checkedAll:boolean=false;

  roleAccess = new RoleAccess();
  warehouseId :any='';
  counterId :any='';
  selectedWarehouses:any[];
  warehouseListShow:any[]=[];

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private promotionService: PromotionsService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormPromotionCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.create!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          else{
            let options = {
              "token": this.token,
              "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
            };
            this.promotionService.getPromotionforCreate(options).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.promotionTypeData = response.results.promotion_type_data;
              this.counterList = response.results.counter_data;
              this.warehouseList = response.results.warehouse_data;
              this.warehouseListShow = this.warehouseList;
            });
          }
        });
      }
    });
  }

  buildFormPromotionCreate() {
    this.formPromotionCreate = this.fb.group({
      counterId: [null, Validators.required],
      warehouseId: [null, Validators.required],
      promotionName: [null, Validators.required],
      earlyPeriod: [null, Validators.required],
      endPeriod: [null, Validators.required],
      promotionType: [null, Validators.required],
      promotionCashierType: [1, Validators.required],
      minimum: [0, Validators.required],
      validOnSun: [false],
      validOnMon: [false],
      validOnTue: [false],
      validOnWed: [false],
      validOnThu: [false],
      validOnFri: [false],
      validOnSat: [false],
    });
  }

  inputPromotions() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const promotionData = this.formPromotionCreate.getRawValue();
      let earlyPeriods = this.utilService.convertDate(promotionData.earlyPeriod);
      let endPeriods = this.utilService.convertDate(promotionData.endPeriod);
      let newEarlyPeriod = earlyPeriods.years + '-' + earlyPeriods.months + '-' + earlyPeriods.dates;
      let newEndPeriod = endPeriods.years + '-' + endPeriods.months + '-' + endPeriods.dates;

      let validOnSun: any = '0', validOnMon: any = '0', validOnTue: any = '0', 
      validOnWed: any = '0', validOnThu: any = '0', validOnFri: any = '0', validOnSat: any = '0';

      if(promotionData.validOnSun === true) { validOnSun = '1'; };
      if(promotionData.validOnMon === true) { validOnMon = '1'; };
      if(promotionData.validOnTue === true) { validOnTue = '1'; };
      if(promotionData.validOnWed === true) { validOnWed = '1'; };
      if(promotionData.validOnThu === true) { validOnThu = '1'; };
      if(promotionData.validOnFri === true) { validOnFri = '1'; };
      if(promotionData.validOnSat === true) { validOnSat = '1'; };
      let counterList ='';
      let whList='';
      for(let i=0;i<this.counterId.length;i++){
        counterList+=this.counterId[i]+',';
      }
      for(let i=0;i<this.warehouseId.length;i++){
        whList+=this.warehouseId[i]+',';
      }
      const mstPromotion = new MstPromotion();
      mstPromotion.counter_id = counterList;
      mstPromotion.warehouse_id = whList;
      mstPromotion.promotion_name = promotionData.promotionName;
      mstPromotion.early_period = newEarlyPeriod;
      mstPromotion.end_period = newEndPeriod;
      mstPromotion.promotion_type_id = promotionData.promotionType;
      mstPromotion.promotion_cashier_type = String(promotionData.promotionCashierType);
      mstPromotion.minimum = promotionData.minimum;
      mstPromotion.valid_on_su = validOnSun;
      mstPromotion.valid_on_mo = validOnMon;
      mstPromotion.valid_on_tu = validOnTue;
      mstPromotion.valid_on_we = validOnWed;
      mstPromotion.valid_on_th = validOnThu;
      mstPromotion.valid_on_fr = validOnFri;
      mstPromotion.valid_on_sa = validOnSat;
      mstPromotion.created_by = this.userProfile.username;

      this.promotionService.addPromotion(mstPromotion).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  getCounterList() {
    let selectQuery = 'SELECT * FROM mst_counters order by counter_name asc';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.counterList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let counterData = {
                'id': result.rows[x].id,
                'counter_name': result.rows[x].counter_name
              };
              this.counterList.push(counterData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/promotions']);;
          }
        }
      ]
    });

    await alert.present();
  }

  uncheckAll() {
    let promotionName = this.formPromotionCreate.getRawValue().promotionName;
    let earlyPeriod = this.formPromotionCreate.getRawValue().earlyPeriod;
    let endPeriod = this.formPromotionCreate.getRawValue().endPeriod;
    let promotionType = this.formPromotionCreate.getRawValue().promotionType;
    let promotionCashierType = this.formPromotionCreate.getRawValue().promotionCashierType;
    let minimum = this.formPromotionCreate.getRawValue().minimum;
    
    let validOnSun :boolean =true;
    let validOnMon :boolean =true;
    let validOnTue :boolean =true;
    let validOnWed :boolean =true;
    let validOnThu :boolean =true;
    let validOnFri :boolean =true;
    let validOnSat :boolean =true;
    if(this.checkedAll==false){
      validOnSun = true;
      validOnMon = true;
      validOnTue = true;
      validOnWed = true;
      validOnThu = true;
      validOnFri = true;
      validOnSat = true;
      this.checkedAll = true;
    }
    else{
      validOnSun = false;
      validOnMon = false;
      validOnTue = false;
      validOnWed = false;
      validOnThu = false;
      validOnFri = false;
      validOnSat = false;
      this.checkedAll = false;
    }   
    this.formPromotionCreate = this.fb.group({
      promotionName: [promotionName, Validators.required],
      earlyPeriod: [earlyPeriod, Validators.required],
      endPeriod: [endPeriod, Validators.required],
      promotionType: [promotionType, Validators.required],
      promotionCashierType: [promotionCashierType, Validators.required],
      minimum: [minimum, Validators.required],
      validOnSun: [validOnSun],
      validOnMon: [validOnMon],
      validOnTue: [validOnTue],
      validOnWed: [validOnWed],
      validOnThu: [validOnThu],
      validOnFri: [validOnFri],
      validOnSat: [validOnSat],
    });
    
  }

  selectWarehouse(event){
    this.warehouseId = [];
  //  this.warehouseId = event.value.id;
    for(let i= 0; i < event.value.length; i++) {
       
     this.warehouseId.push(event.value[i]['id']);
     }
  }
    
  selectCounter(event){
    console.log(event.value);
    this.counterId = [];//event.value.id;
    this.warehouseId ='';
    this.selectedWarehouses=[];
    this.warehouseListShow=[];
    let wh =[];
    for(let i= 0; i < event.value.length; i++) {
      this.counterId.push(event.value[i]['id']);
      wh[i]=(this.warehouseList.filter(x => parseInt(x.counter_id) === parseInt( event.value[i]['id'])));
    }
    let a=0;
    for(let i=0;i<wh.length;i++){
      for(let j=0;j<wh[i].length;j++){
        this.warehouseListShow[a]=wh[i][j];
        a++;
      }
    }
  }
}