import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';

@Component({
  selector: 'app-request-void',
  templateUrl: './request-void.component.html',
  styleUrls: ['./request-void.component.scss'],
})

export class RequestVoidComponent implements OnInit {
  
  @Input() paramPeriodStart: any;
  @Input() paramPeriodEnd: any;
  @Input() paramCounterId:any;
  @Input() counterList: any[];

  counterNameCheckBox: any[] = [];  
  showCounterCheckBox: boolean = false;
  
  counterId: any;
  periodStart: any;
  periodEnd: any;
  constructor(
    public modalController: ModalController,
    private utilService: UtilService,) { }

  ngOnInit() {

    console.log('ini pop up:'+this.paramCounterId);
    let todayDate = new Date();
    let convertDateStart = this.paramPeriodStart ? this.utilService.convertDate(this.paramPeriodStart) : this.utilService.convertDate(todayDate);
    let convertDateEnd = this.paramPeriodEnd ? this.utilService.convertDate(this.paramPeriodEnd) : this.utilService.convertDate(todayDate);
    let startDate = convertDateStart.years + '-' + convertDateStart.months + '-' + convertDateStart.dates;
    let endDate = convertDateEnd.years + '-' + convertDateEnd.months + '-' + convertDateEnd.dates;
    this.periodStart = startDate;
    this.periodEnd = endDate;
    this.counterId = this.paramCounterId ? this.paramCounterId : '';
    let counterIdSplit = this.counterId !== '' ? String(this.counterId).split(',') : [];
    for(let x = 0; x < this.counterList.length; x++) {
      let isChecked = false;
      if(counterIdSplit.length > 0) {
        let findId = counterIdSplit.indexOf(String(this.counterList[x].id));
        isChecked = findId !== -1 ? true : false;
      }

      let data = {
        'counter_id': this.counterList[x].id,
        'counter_name': this.counterList[x].counter_name,
        'isChecked': isChecked
      };

      this.counterNameCheckBox.push(data);
    }
  }

  showCounterListCheckBox() {
    if(this.showCounterCheckBox === false) {
      this.showCounterCheckBox = true;
    } else {
      this.showCounterCheckBox = false;
    }
  }
  
  async dismissModal(code: any) {
    let dataReturn: any;

    let listCounterId: any = '';
    if(this.counterNameCheckBox.length > 0) {
      for(let i = 0; i < this.counterNameCheckBox.length; i++) {
        if(this.counterNameCheckBox[i].isChecked === true) {
          if(listCounterId === '') {
            listCounterId = this.counterNameCheckBox[i].counter_id;
          } else {
            listCounterId = listCounterId + ',' + this.counterNameCheckBox[i].counter_id;
          }
        }
      }

      this.counterId = listCounterId;
    }

    if(code === 'generate') {
        dataReturn =  this.periodStart +
          '#' + this.periodEnd + '#' + this.counterId ;
      
    }
    if(code === 'close') {
      dataReturn = 'null';
    }
    
    await this.modalController.dismiss(dataReturn);
  }

}
