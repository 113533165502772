import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { Menu } from '../../../models/menu.model';
import { MenuService } from '../menu.service';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-menu-create',
  templateUrl: './menu-create.page.html',
  styleUrls: ['./menu-create.page.scss'],
})
export class MenuCreatePage implements OnInit {

  @ViewChild('parentComponent', { static: false }) parentComponent: IonicSelectableComponent;
  token: any;
  formMenuCreate: FormGroup;
  parentMenuData: any[] = [];
  userProfile: UserProfile = new UserProfile();
  parentId:any;

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.buildFormMenuCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, menu]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let checkAccess = menu.find(x => x.root === 'menu');
          if(!checkAccess || checkAccess.create!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          else{
            this.menuService.getMenuforCreate({ "token": this.token }).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.parentMenuData = response.results.data_perent_menu;
            });
          }
        });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormMenuCreate() {
    this.formMenuCreate = this.fb.group({
      parentId: [0],
      menuName: [null, Validators.required],
      root: [null, Validators.required],
      weight: [0, Validators.required],
      icon: [null, Validators.required]
    });
  }

  inputMenu() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const menuForm = this.formMenuCreate.getRawValue();
      const menu = new Menu();
      menu.parent_id = this.parentId;
      menu.menu_name = menuForm.menuName;
      menu.root = menuForm.root;
      menu.weight = menuForm.weight;
      menu.icon = menuForm.icon;
      menu.created_by = this.userProfile.username;

      this.menuService.addMenu(menu).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/menu']);;
          }
        }
      ]
    });

    await alert.present();
  }
  
  selectParent(){
    this.parentComponent.confirm();
    this.parentComponent.close();
    this.parentId = this.parentComponent.value.id;
  }

}
