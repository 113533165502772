import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-redem-point',
  templateUrl: './redem-point.component.html',
  styleUrls: ['./redem-point.component.scss'],
})
export class RedemPointComponent implements OnInit {

  @Input() token: any;
  @Input() id: any;
  @Input() resellerName: any;
  @Input() sisaPoint: any;
  
  pointRedem: any=0;
  keterangan: any;
  baseUrl: any;
  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private http: HttpClient,
    private utilService: UtilService,
    private toastCtrl: ToastController
  ) { 
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {}

  async dismissModal() {
    let param = [];
    await this.modalController.dismiss(param);
  }

  async cekValid() {
    if(parseInt(this.pointRedem) > parseInt(this.sisaPoint)) {
      setTimeout(async () => {
        const alert = await this.alertController.create({
          header: 'Notification',
          cssClass:'custom-alert-class',
          message: 'Point redem melebihi sisa point',
          buttons: [
            {
              text: 'OK',
              handler: () => {
                this.pointRedem = 0;
              }
            }
          ]
        });
        await alert.present();
      }, 1000);
    }
  }

  async redem() {
    if(this.pointRedem < 1) {
      const alert = await this.alertController.create({
        header: 'Notification',
        cssClass:'custom-alert-class',
        message: 'Masukan point redem minimal 1',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.pointRedem = 0;
            }
          }
        ]
      });
      await alert.present();
      return false;
    }

    if(this.keterangan == '') {
      const alert = await this.alertController.create({
        header: 'Notification',
        cssClass:'custom-alert-class',
        message: 'Masukan keterangan hadiah',
        buttons: [
          {
            text: 'OK',
            handler: () => {}
          }
        ]
      });
      await alert.present();
      return false;
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {

      const paramsOpts = new HttpParams()
        .set('token', this.token)

      const reqOpts = new HttpHeaders()
        .set('Accept', 'application/json'),
      formData = new FormData();
      formData.append('customerId', this.id);
      formData.append('sisaPoint', this.sisaPoint);
      formData.append('pointRedem', this.pointRedem);
      formData.append('ket', this.keterangan);

      this.http.post(this.baseUrl + 'reward-point-reseller/redem-point', formData, { headers: reqOpts, params: paramsOpts })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showConfirmInput();
        }else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            await this.modalController.dismiss();
          }
        }
      ]
    });

    await alert.present();
  }
}
