import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReceiptVoucherEditPage } from './receipt-voucher-edit.page';

const routes: Routes = [
  {
    path: '',
    component: ReceiptVoucherEditPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReceiptVoucherEditPageRoutingModule {}
