import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { MasterProductService } from '../master-product.service';
import { MstProduct } from '../../../models/mst-product.model';
import { RoleAccess } from '../../../models/role-access.model';
import { MyService } from '../../../service/my.service';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-master-product-edit',
  templateUrl: './master-product-edit.page.html',
  styleUrls: ['./master-product-edit.page.scss'],
})
export class MasterProductEditPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  PCode: any;
  productData: any;
  brandList: any[] = [];
  subBrandList: any[] = [];
  subBrandListServer: any[] = [];
  typeList: any[] = [];
  subTypeList: any[] = [];
  subTypeListServer: any[] = [];
  categoryList: any[] = [];
  subCategoryList: any[] = [];
  subCategoryListServer: any[] = [];
  marketingTypeList: any[] = [];
  statusProduct: any[] = ['Not Active', 'Active'];
  statusListingProduct: any[] = ['Not_Listing', 'Listing'];

  formMstProductEdit: FormGroup;
  roleAccess = new RoleAccess();
  isVirtualBundling:number=0;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private mstProductService: MasterProductService,
    private router: Router,
    private myService:MyService
  ) { }

  ngOnInit() {
    this.buildFormMstProductEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getMstProductforEdit();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormMstProductEdit() {
    this.formMstProductEdit = this.fb.group({
      productId: [],
      productName: [null, Validators.required],
      initialName: [null, Validators.required],
      barcode: [null, Validators.required],
      marketingTypeId: [null, Validators.required],
      brandId: [null, Validators.required],
      subBrandId: [null, Validators.required],
      typeId: [null, Validators.required],
      subTypeId: [null, Validators.required],
      categoryId: [null, Validators.required],
      subCategoryId: [null, Validators.required],
      price: [0, Validators.required],
      listingStatus :[],
      activeStatus: []
    });
  }

  getMstProductforEdit() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.PCode = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.PCode = this.PCode.replaceAll('"','');

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.mstProductService.getProductForEdit(this.PCode, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.productData = response.results.mst_product;
          this.brandList = response.results.mst_product_brand_data;
          this.subBrandListServer = response.results.mst_product_sub_brand_data;
          this.subBrandList = this.subBrandListServer.filter(x => x.product_brand_id === this.productData.product_brand_id);
          this.typeList = response.results.mst_product_type_data;
          this.subTypeListServer = response.results.mst_product_sub_type_data;
          this.subTypeList = this.subTypeListServer.filter(x => x.product_type_id === this.productData.product_type_id);
          this.categoryList = response.results.mst_product_category_data;
          this.subCategoryListServer = response.results.mst_product_sub_category_data;
          this.subCategoryList = this.subCategoryListServer.filter(x => x.product_category_id === this.productData.product_category_id);
          this.marketingTypeList = response.results.mst_product_marketing_type_data;
          this.isVirtualBundling = parseInt(this.productData.is_virtual_bundling);
          let price = this.myService.separator(this.productData.price);
          price = String(price).replace(/[^0-9,]/g, '');
          this.formMstProductEdit = this.fb.group({
            productId: [this.productData.id],
            productName: [this.productData.product_name, Validators.required],
            initialName: [this.productData.initial_name, Validators.required],
            barcode: [this.productData.barcode, Validators.required],
            marketingTypeId: [parseInt(this.productData.product_marketing_type_id), Validators.required],
            brandId: [this.productData.product_brand_id, Validators.required],
            subBrandId: [parseInt(this.productData.product_sub_brand_id), Validators.required],
            typeId: [this.productData.product_type_id, Validators.required],
            subTypeId: [parseInt(this.productData.product_sub_type_id), Validators.required],
            categoryId: [this.productData.product_category_id, Validators.required],
            subCategoryId: [parseInt(this.productData.product_sub_category_id), Validators.required],
            price: [price, Validators.required],
            activeStatus: [parseInt(this.productData.active), Validators.required],
            listingStatus : [parseInt(this.productData.listing_status),Validators.required],
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  getSubTypeList(event) {
    let productTypeId = event.target.value;
    let subTypeList = this.subTypeListServer.filter(x => x.product_type_id === productTypeId);
    this.subTypeList = subTypeList ? subTypeList : this.subTypeListServer;
  }

  getSubCategoryList(event) {
    let productCategoryId = event.target.value;
    let subCategoryList = this.subCategoryListServer.filter(x => x.product_category_id === productCategoryId);
    this.subCategoryList = subCategoryList ? subCategoryList : this.subCategoryListServer;
  }

  getSubBrandList(event) {
    let productBrandId = event.target.value;
    let subBrandList = this.subBrandListServer.filter(x => x.product_brand_id === productBrandId);
    this.subBrandList = subBrandList ? subBrandList : this.subBrandListServer;
  }

  updateProduct() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formMstProduct = this.formMstProductEdit.value;
      const mstProduct = new MstProduct();
      mstProduct.product_name = formMstProduct.productName;
      mstProduct.initial_name = formMstProduct.initialName;
      mstProduct.barcode = formMstProduct.barcode;
      mstProduct.product_brand_id = formMstProduct.brandId;
      mstProduct.product_sub_brand_id = parseInt(formMstProduct.subBrandId) < 1000 ? "0" + formMstProduct.subBrandId : formMstProduct.subBrandId;
      mstProduct.product_type_id = formMstProduct.typeId;
      mstProduct.product_sub_type_id = parseInt(formMstProduct.subTypeId) < 1000 ? "0" + formMstProduct.subTypeId : formMstProduct.subTypeId;
      mstProduct.product_category_id = formMstProduct.categoryId;
      mstProduct.product_sub_category_id = parseInt(formMstProduct.subCategoryId) < 1000 ? "0" + formMstProduct.subCategoryId : formMstProduct.subCategoryId;
      mstProduct.product_marketing_type_id = formMstProduct.marketingTypeId;
      mstProduct.price = this.myService.removeSeparator(formMstProduct.price);
      mstProduct.active = formMstProduct.activeStatus;
      mstProduct.listing_status = formMstProduct.listingStatus;
      mstProduct.updated_by = this.userProfile.username;

      this.mstProductService.updateProduct(this.PCode, mstProduct).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/master-product']);;
          }
        }
      ]
    });

    await alert.present();
  }
  getChangeNominal(columnname:any){
    let arrform=<FormArray>this.formMstProductEdit.controls[columnname];    
    let cur= arrform.value;
    let curWithSeparator='0';
    if(cur!='0' && cur!='' && cur!=null){
      curWithSeparator = this.myService.separator(cur);
      curWithSeparator = String(curWithSeparator).replace(/[^0-9,]/g, '');
    }
    this.formMstProductEdit.controls[columnname].setValue(curWithSeparator);
  }
}
