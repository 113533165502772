import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { RegisterService } from '../register/register.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.page.html',
  styleUrls: ['./forget-password.page.scss'],
})
export class ForgetPasswordPage implements OnInit {
  title:any='';
  identity:any='';
  showBoxReset:boolean=false;
  showBoxForget:boolean=false;
  newPassword:any='';
  userId:any;
  confirmNewPassword:any;
  msgConfirmPassword:any='';
  showNewPassword:boolean=false;
  showConfirmNewPassword:boolean=false;
  constructor(
    private navCtrl:NavController,
    private utilService:UtilService,
    private toastCtrl:ToastController,
    private alertController:AlertController,
    private registerService:RegisterService,
    private activeRoute:ActivatedRoute
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.title='';
    this.showBoxForget=false;
    this.showBoxReset=false;
    this.newPassword='';
    this.identity='';
    this.userId='';
    this.confirmNewPassword ='';
    this.showNewPassword=false;
    this.showConfirmNewPassword=false;
    this.getToken();
  }
  gotoLoginPage(){
    this.navCtrl.navigateForward(['/login']);
  }
  
  async showAlert(message: any,action:any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            if(action=='goto'){
              this.gotoLoginPage();
            }
          }
        }
      ]
    });
    await alert.present();
  }
  async submitForgetPassword(){
    await this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.registerService.forgetPassword(this.identity)
        .subscribe( (response: any) => {
          this.utilService.loadingDismiss();
          if(response.status=='200'){
            this.showAlert('Silahkan cek email anda.','goto');
            console.log(response);
          }
          else if(response.status=100){
            this.utilService.loadingDismiss();
            this.showAlert(response.message,'stay');
          }
          else{
            this.toastCtrl.create({ duration: 2000, message: 'Registrasi Gagal' }).then(t => t.present());
          }
        }, (err) => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Registrasi Gagal' }).then(t => t.present());
        });
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal simpan product.' }).then(t => t.present());
      });
  }
  
  getToken() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let token =snapshot.t;
      if(token!='' && token !=undefined){
        //cek status token
        this.registerService.checkToken(token)
        .subscribe( (response: any) => {
          this.utilService.loadingDismiss();
          if(response.status=='200'){
            this.title='Reset Password';
            this.showBoxReset=true;
            this.userId=response.user.id;
          }
          else if(response.status=100){
            this.utilService.loadingDismiss();
            this.showAlert(response.message,'stay');
          }
          else{
            this.toastCtrl.create({ duration: 2000, message: 'Reset Password Gagal' }).then(t => t.present());
          }
        }, (err) => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      }
      else{
        this.showBoxForget=true;
        this.title='Forget Password';
      }
    });
  }
  async submitResetPassword(){
    let error =0;
    this.msgConfirmPassword='';
    if(this.newPassword.length<6){
      error++;
      this.msgConfirmPassword='Password minimum 6 character';
    }
    else{
      if(this.newPassword!=this.confirmNewPassword){
        error++;
        this.msgConfirmPassword="Password and Confirm Password didn't match";

      }
    }
    if(error==0){
      const alert = await this.alertController.create({
        header: 'Reset Confirmation',
        cssClass:'custom-alert-class',
        message: 'Apakah anda yakin untuk ganti password?',
        buttons: [
          {
            text: 'Cancel',
            handler: () => {}
          },
          {
            text: 'OK',
            handler: () => {
              this.resetPassword();
            }
          }
        ]
      });

      await alert.present();
    }
    else{
      this.showAlert(this.msgConfirmPassword,'stay');
    }
  }
  async resetPassword(){
    await this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.registerService.resetPassword(this.newPassword,this.userId)
        .subscribe( (response: any) => {
          this.utilService.loadingDismiss();
          if(response.status=='200'){
            this.showAlert(response.message,'goto');
          }
          else if(response.status=100){
            this.utilService.loadingDismiss();
            this.showAlert(response.message,'stay');
          }
          else{
            this.toastCtrl.create({ duration: 2000, message: 'Reset Password Gagal' }).then(t => t.present());
          }
        }, (err) => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal simpan product.' }).then(t => t.present());
      });
  }
  togglePasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }
  toggleConfirmPasswordVisibility() {
    this.showConfirmNewPassword = !this.showConfirmNewPassword;
  }
  
}
