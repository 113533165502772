import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { OtherReceivingService } from '../other-receiving.service';
import { OtherReceivingBundle } from '../../../models/other-receiving-bundle.model';
import { FindProductComponent } from '../../../component/find-product/find-product.component';
import { RoleAccess } from '../../../models/role-access.model';
import { Router } from '@angular/router';
import { IonicSelectableComponent } from 'ionic-selectable';


class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-other-receiving-create',
  templateUrl: './other-receiving-create.page.html',
  styleUrls: ['./other-receiving-create.page.scss'],
})
export class OtherReceivingCreatePage implements OnInit {
  @ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;
  @ViewChild('warehouseComponent', { static: false }) warehouseComponent: IonicSelectableComponent;

  token: any;
  productSearch: Product[];
  productList: Product[];
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  warehouseData: any[] = [];
  purposeData: any[] = [];
  otherReceivingDetailList: any[] = [];

  formOtherReceivingCreate: FormGroup;
  otherReceivingId: any;
  roleAccess = new RoleAccess();
  warehouseId :any='';
  counterId :any='';  
  selectedWarehouses:any[];
  warehouseListShow:any[]=[];

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private modalController: ModalController,
    private receivingService: OtherReceivingService,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormOtherReceiving();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getOtherReceivingForCreate();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getOtherReceivingForCreate() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.receivingService.getOtherReceivingforCreate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.productList = response.results.product_data;
          this.counterList = response.results.counter_data;
          this.warehouseData = response.results.warehouse_data;
          this.warehouseListShow = this.warehouseData;
          this.purposeData = response.results.purpose_data;

          this.buildFormOtherReceiving();
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  buildFormOtherReceiving() {
    let newDate = new Date();
    let convertDate = this.utilService.convertDate(newDate);
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formOtherReceivingCreate = this.fb.group({
      counterId: [this.counterId !='' ? parseInt(this.counterId) : null, Validators.required],
      warehouseId: [this.warehouseId != '' ? parseInt(this.warehouseId) : null, Validators.required],
      purposeId: [this.purposeData.length > 0 ? parseInt(this.purposeData[0].id) : null, Validators.required],
      docDate: [todayDate, Validators.required],
      description: [null, Validators.required]
    });
  }

  inputBundle() {
    if(this.otherReceivingDetailList.length==0){
      this.toastCtrl.create({ duration: 2000, message: 'Mohon input product' }).then(t => t.present());
    }
    else{
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        const formOtherReceiving = this.formOtherReceivingCreate.value;
        let docDateConvert = this.utilService.convertDate(formOtherReceiving.docDate);
        let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

        let arrProduct: any = [];
        let arrQty: any = [];
        let arrUnitType: any = [];
        let arrDesc: any = [];

        for(let x = 0; x < this.otherReceivingDetailList.length; x++) {
          let htmlIdQtyReceive: any = 'qtyReceive_' + x;
          let htmlIdDesc: any = 'description_' + x;
          let qtyReceive: any = (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;
          let description: any = (<HTMLInputElement>document.getElementById(htmlIdDesc)).value;

          arrProduct[x] = this.otherReceivingDetailList[x].product_id;
          arrQty[x] = parseInt(qtyReceive);
          arrUnitType[x] = 'PCS';
          arrDesc[x] = description;
        }

        const otherReceivingBundle = new OtherReceivingBundle();
        otherReceivingBundle.otherReceiving.counter_id = this.counterId;
        otherReceivingBundle.otherReceiving.warehouse_id = this.warehouseId;
        otherReceivingBundle.otherReceiving.purpose_id = formOtherReceiving.purposeId;
        otherReceivingBundle.otherReceiving.doc_date = documentDate;
        otherReceivingBundle.otherReceiving.desc = formOtherReceiving.description;
        otherReceivingBundle.otherReceiving.status = '0';
        otherReceivingBundle.otherReceiving.created_by = this.userProfile.username;

        otherReceivingBundle.otherReceivingDetail.product_id = arrProduct;
        otherReceivingBundle.otherReceivingDetail.qty = arrQty;
        otherReceivingBundle.otherReceivingDetail.unit = arrUnitType;
        otherReceivingBundle.otherReceivingDetail.description = arrDesc;

        this.receivingService.addOtherReceivingBundle(otherReceivingBundle)
        .subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.showConfirmInput();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    }
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/other-receiving']);;
          }
        }
      ]
    });

    await alert.present();
  }

  addDetail() {
    let length = this.otherReceivingDetailList.length;
    this.otherReceivingDetailList.push({
      'id' : length + 1,
      'product_id' : null,
      'product_name' : null,
      'unit': null
    });
  }

  async findProduct(index: any) {
    const modal = await this.modalController.create({
      component: FindProductComponent,
      componentProps: {
        'productList': this.productList
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if(data) {
        let findProduct = this.otherReceivingDetailList.indexOf(data);
        if(findProduct === -1) {
          this.otherReceivingDetailList[index].product_id = data.id;
          this.otherReceivingDetailList[index].product_name = data.product_name;
        }
      }
    });

    return await modal.present();
  }

  deleteProduct(index: any) {
    this.otherReceivingDetailList.splice(index, 1);
  }  

    
  
  confirmCounter() {
    this.counterComponent.confirm();
    this.counterComponent.close();
    this.selectedWarehouses=[];
    this.counterId = this.counterComponent.value.id;
  }
  
  confirmWarehouse(){
    this.warehouseComponent.confirm();
    this.warehouseComponent.close();
    this.warehouseId = this.warehouseComponent.value.id;
  }
}
