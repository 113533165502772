export class ProductRequest {

    id: string;
    counter_id: number;
    warehouse_id: number;
    doc_date: string;
    need_date: string;
    desc: string;
    status: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.counter_id = model.counter_id || 0;
        this.warehouse_id = model.warehouse_id || 0;
        this.doc_date = model.doc_date || null;
        this.need_date = model.need_date || null;
        this.desc = model.desc || null;
        this.status = model.status || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}

export class ProductRequestDetail {

    product_request_id: string[];
    product_id: string[];
    qty: number[];
    unit: string[];
    qty_receipt: number[];

    constructor(model?) {
        model = model || {};
        this.product_request_id = model.product_request_id || [];
        this.product_id = model.product_id || [];
        this.qty = model.qty || [];
        this.unit = model.unit || [];
        this.qty_receipt = model.qty_receipt || [];
    }
}