import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RecalculateStockPageRoutingModule } from './recalculate-stock-routing.module';

import { RecalculateStockPage } from './recalculate-stock.page';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RecalculateStockPageRoutingModule,
    IonicSelectableModule
  ],
  declarations: [RecalculateStockPage]
})
export class RecalculateStockPageModule {}
