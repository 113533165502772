import { Injectable } from '@angular/core';

import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SalesTargetService {

  constructor(
    private httpService: HttpService
  ) { }
  getCounter(params: any): Observable<any> {
    return this.httpService.get('counter-list-per-user', params);
  }  
  getSalesTarget(params: any): Observable<any> {
    return this.httpService.get('sales-target', params);
  }
  getSalesTargetCreate(params: any): Observable<any> {
    return this.httpService.get('sales-target/create', params);
  }  
  addSalesTarget(data: any): Observable<any> {
    return this.httpService.post('sales-target', data);
  }
  getSalesTargetforEdit(salesTargetId: any, params: any): Observable<any> {
    return this.httpService.get(`sales-target/${salesTargetId}/edit`, params);
  }
  updateSalesTarget(salesTargetId: any, params: any): Observable<any> {
    return this.httpService.put(`sales-target/${salesTargetId}`, params);
  }
  getProductByCounter(params: any): Observable<any> {
    return this.httpService.get(`sales-target/get-product-by-counter`, params);
  }
  getProductBySalesTargetId(params: any): Observable<any> {
    return this.httpService.get(`sales-target/get-product-by-sales-target-id`, params);
  }
  
  uploadFile(data: any): Observable<any> {
    return this.httpService.post('upload-sales-target', data);
  }
}
