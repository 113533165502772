import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-satuan',
  templateUrl: './satuan.page.html',
  styleUrls: ['./satuan.page.scss'],
})
export class SatuanPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
