
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { DisplayCustomerService } from '../display-customer.service';
import { UserProfile } from '../../../models/user-profile.model';
import { UserData } from '../../../providers/user-data';
import { browserRefresh } from '../../../app.component';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';
@Component({
  selector: 'app-slider-form',
  templateUrl: './slider-form.page.html',
  styleUrls: ['./slider-form.page.scss'],
})
export class SliderFormPage implements OnInit {

  formSliderCreate: FormGroup;
  active_flag:string='Y';
  @ViewChild('uploadFileInput', { static: false }) uploadFileInput: ElementRef;
  id :any =0;
  token:any='';
  userProfile: UserProfile = new UserProfile();
  browserRefresh: boolean;
  filename:any='';
  showUpload:boolean=true;
  title:string='Tambah';
  counterList: any[] = [];
  counterId :any='';
  selectedCounter:any[]=[];
  roleAccess = new RoleAccess();
  constructor(
    private fb: FormBuilder,
    private utilService:UtilService,
    private toastCtrl:ToastController,
    private navCtrl:NavController,
    private alertController:AlertController,
    private displayCustomerService:DisplayCustomerService,
    private userData: UserData,
    private storage: Storage,
    private router: Router) { 
    }
    

  ngOnInit() {
    this.browserRefresh = browserRefresh;    
    this.buildForm();
    console.log('refreshed?:', browserRefresh);
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.checkAccess(this.router.url, access, this.userProfile);
        console.log(this.userProfile);
        if(!this.roleAccess){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
        //  this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  checkAccess(url: any, userAccess: any, userProfile: any) {
    let arrTemp = String(url).split('/');
    console.log(arrTemp);
    let thisPage = arrTemp[1];
    if(arrTemp.length==3){
      thisPage = arrTemp[1] +'/'+arrTemp[2];
    }
    let checkUserAccess: any = userAccess.find(x => x.root === thisPage);
    const roleAccess = new RoleAccess();
    if(checkUserAccess) {
      roleAccess.approve = checkUserAccess.approve;
      roleAccess.create = checkUserAccess.create;
      roleAccess.delete = checkUserAccess.delete;
      roleAccess.download = checkUserAccess.download;
      roleAccess.menu_id = checkUserAccess.id;
      roleAccess.read = checkUserAccess.read;
      roleAccess.read_all = checkUserAccess.read_all;
      roleAccess.role_id = userProfile.role_id;
      roleAccess.update = checkUserAccess.update;
      roleAccess.view = checkUserAccess.view;
    }

    return roleAccess;
  }
  buildForm() {
    if(localStorage.getItem('slider-id')){
      this.id=localStorage.getItem('slider-id');
    }
    if(this.id!='0'){
      this.title='Edit';
      this.showUpload=false;
    }
    this.formSliderCreate = this.fb.group({
      active_flag: [this.active_flag, Validators.required],
      counterId:[]
    });
  }
  async getData() {
    await this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "id":this.id,
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

       this.displayCustomerService.getDataforEdit(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.counterList = response.results.counter_data;
        if(response.results.data){
          this.active_flag = response.results.data.active_flag;
          this.filename = response.results.data.filename; 
          let arrCounter= [];
          if(response.results.data.counter_id_list!=''){
            arrCounter= response.results.data.counter_id_list.split(',');
          } 
          if(arrCounter.length>0){
            for(let i=0;i<arrCounter.length;i++){
              
              let selectedCounter: any = this.counterList.find(x => x.id ==  arrCounter[i]);
              if(selectedCounter){
                this.selectedCounter.push(selectedCounter);
                this.counterId += selectedCounter.id+',';
              }
            }
          }
        }
        this.formSliderCreate = this.fb.group({
          active_flag: [this.active_flag, Validators.required],
          counterId:[this.selectedCounter]
        });
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  onFileSelect(event) {
    let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      //if (!_.includes(af, file.type)) {
        //this.toastCtrl.create({ duration: 2000, message: 'Hanya untuk upload file excel !' }).then(t => t.present());
      //}
    }
  }
  uploadFile() {
    if(this.showUpload){
    if (!this.uploadFileInput.nativeElement.files[0]) {
      alert('Please fill valid details!');
      return false;
    }
  }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formStockOpname = this.formSliderCreate.value;
      const formData = new FormData();
      formData.append('id',  this.id);
      formData.append('counter_id_list',  this.counterId.toString());
      formData.append('active_flag',  formStockOpname.active_flag);
      formData.append('username',  this.userProfile.username);
      if(this.showUpload){
        formData.append('file', this.uploadFileInput.nativeElement.files[0]);
      }
      if(this.id=='0'){
        this.displayCustomerService.createSlider(formData).subscribe((response) => {
          this.utilService.loadingDismiss();
          if (response.status.code === 200) {
            this.uploadFileInput.nativeElement.value = "";
            this.showConfirmInput();
          }
        }, (err) => {
          this.utilService.loadingDismiss();
          console.log(err);
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      }
      else{
        this.displayCustomerService.updateSlider(formData).subscribe((response) => {
          this.utilService.loadingDismiss();
          if (response.status.code === 200) {
            if(this.showUpload){
              this.uploadFileInput.nativeElement.value = "";
            }
            this.showConfirmInput();
          }
        }, (err) => {
          this.utilService.loadingDismiss();
          console.log(err);
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });

      }
    });
  }
  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/display-customer/slider-list']);;
          }
        }
      ]
    });
    await alert.present();
  }
  changeFile(){
    this.showUpload = true;
  }
  selectCounter(event){
    this.counterId = [];
    for(let i= 0; i < event.value.length; i++) {
      this.counterId.push(event.value[i]['id']);
    }
    console.log(this.counterId.toString());
  }
}
