import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { ReceiptVoucher, ReceiptVoucherDetail } from '../../models/receipt-voucher.model';
import { ApprovalHistory } from '../../models/approval-history.model';

@Injectable({
  providedIn: 'root'
})
export class ReceiptVoucherService {

  constructor(private httpService: HttpService) { }

  getReceiptVoucher(params: any): Observable<any> {
    return this.httpService.get('receipt-voucher', params);
  }

  getLocalData(name: any, params: any): Observable<any> {
    let apiUrl: any;
    if(name === 'counter') { apiUrl = 'counter-all'; }
    if(name === 'cash-bank') { apiUrl = 'cash-bank-all'; }
    if(name === 'coa') { apiUrl = 'coa-all'; }
    if(name === 'currency') { apiUrl = 'sales-transaction/currency'; }

    return this.httpService.get(apiUrl, params);
  }

  getMstCoaConcat(params: any): Observable<any> {
    return this.httpService.get('coa-concat', params);
  }

  getLatestReceiptVoucherId(params: any): Observable<any> {
    return this.httpService.get('receipt-voucher/create', params);
  }

  getReceiptVoucherbyId(receiptVoucherId: any, params: any): Observable<any> {
    return this.httpService.get(`receipt-voucher/${receiptVoucherId}/edit`, params);
  }

  addReceiptVoucher(data: ReceiptVoucher): Observable<any> {
    return this.httpService.post('receipt-voucher', data);
  }

  addReceiptVoucherDetail(data: ReceiptVoucherDetail): Observable<any> {
    return this.httpService.post('receipt-voucher-details/store', data);
  }

  addApprovalHistory(data: ApprovalHistory): Observable<any> {
    return this.httpService.post('approval-history', data);
  }

  updateReceiptVoucher(receiptVoucherId: any, data: ReceiptVoucher): Observable<any> {
    return this.httpService.put(`receipt-voucher/${receiptVoucherId}`, data);
  }
}
