import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PromotionTypeEditPage } from './promotion-type-edit.page';

const routes: Routes = [
  {
    path: '',
    component: PromotionTypeEditPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PromotionTypeEditPageRoutingModule {}
