import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PriceGroupApprovalPageRoutingModule } from './price-group-approval-routing.module';

import { PriceGroupApprovalPage } from './price-group-approval.page';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PriceGroupApprovalPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [PriceGroupApprovalPage]
})
export class PriceGroupApprovalPageModule {}
