import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, ToastController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { StockOpnameService } from './stock-opname.service';
import { StockOpnameStatus } from '../../enum/StockOpnameStatus';
import { RoleAccess } from '../../models/role-access.model';
import { AES } from 'crypto-js';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-stock-opname',
  templateUrl: './stock-opname.page.html',
  styleUrls: ['./stock-opname.page.scss'],
})
export class StockOpnamePage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  token: any;
  userProfile: UserProfile = new UserProfile();
  modelData: any;
  rows: any;
  stockOpnameList: any;
  filteredData: any;

  stockOpnameStatus = StockOpnameStatus;
  stockOpnameStatusList = Object.keys(StockOpnameStatus).filter(
    stockOpnameStatus => typeof this.stockOpnameStatus[stockOpnameStatus] === 'number'
  );

  printers: string[];
  isDefaultPrinterSelected = false;
  selectedPrinter: any;

  userAccess: any[] = [];
  roleAccess = new RoleAccess();
  roleName: any;
  keywordSearch: any;

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private router: Router,
    private stockOpnameService: StockOpnameService
  ) {
    // WebSocket settings
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (this.jspmWSStatus()) {
            // get client installed printers
            JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
              this.printers = myPrinters;
              this.isDefaultPrinterSelected = true;
            });
        }
    };
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userAccess = access;
        this.roleName = this.userProfile.role_detail ? this.userProfile.role_detail.role_name : null;
        this.roleAccess = this.userData.checkAccess(this.router.url, this.userAccess, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.pageCallback({ offset: this.page.offset });
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  jspmWSStatus() { // Check JSPM WebSocket status
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
        alert('JSPM has blocked this website!');
        return false;
    }
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords" :this.keywordSearch ? this.keywordSearch : 'null',
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.stockOpnameService.getStockOpname(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        //this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.stockOpnameList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  goToStockOpnameCreate() {
    this.navCtrl.navigateForward(['/stock-opname/stock-opname-create']);
  }

  goToUpdatePage(id: any) {
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/stock-opname/stock-opname-edit'], navigationExtras);
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 5;
    let keys = Object.keys(this.stockOpnameList[0]);
    this.rows = this.filteredData.filter(function(item){
      for (let i=0; i < columnLength; i++){
        if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });

    this.page.offset = 0;
  }

  openAdvancedFilters() {
    //
  }

  checkStatus(status: any) {
    let cssClass: any = '';
    if(status === '0') { cssClass = 'status pending'}
    if(status === '1') { cssClass = 'status closed'}
    if(status === '2') { cssClass = 'status void'}
    if(status === '3') { cssClass = 'status processInBranch'}
    return cssClass;
  }

  getDataForPrint(stockOpnameId: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "stock_opname_id": stockOpnameId
      };

      this.stockOpnameService.getStockOpnameforPrint(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        let stockOpnameData: any[] = response.results;
        if(stockOpnameData.length > 0) {
          this.printStockOpname(stockOpnameData);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  printStockOpname(stockOpnameData: any[]) {
    //Create a ClientPrintJob
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += '---------------------------------';
    cmds += 'NO STOCK OPNAME : ' + stockOpnameData[0].stock_opname_id;
    cmds += newLine;
    cmds += 'COUNTER : ' + String(stockOpnameData[0].counter_name).toUpperCase();
    cmds += newLine;
    cmds += 'GUDANG : ' + String(stockOpnameData[0].warehouse_name).toUpperCase();
    cmds += newLine;
    cmds += 'TANGGAL : ' + stockOpnameData[0].doc_date;
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)

    //Start List Product Stock Opname
   // cmds += 'PCODE  NAMA PRODUK       QF QP QS';
    let totalQp=0;
    let totalQf=0;
    let totalQs=0;
    let totalPriceDiff=0;
    for(let x = 0; x < stockOpnameData.length; x++) {
      let qty_physics = parseInt(stockOpnameData[x].qty_physics);
      let qty_program = parseInt(stockOpnameData[x].qty_program);
      let price = parseInt(stockOpnameData[x].price);
      let qtyDiff = qty_physics - qty_program;
      totalQf += qty_physics;
      totalQp += qty_program;
      totalQs += qtyDiff;
      if(qty_physics==0 && qty_program==0){
        //jangan dicetak
      }
      else{
        let priceDiff= qtyDiff*price;
        totalPriceDiff += priceDiff;
        cmds += this.getLblPCodeAndName(String(stockOpnameData[x].product_id), String(stockOpnameData[x].product_name), 
        '', '', '');
        cmds += newLine;
        cmds += this.getLblQtyAndPrice(String(stockOpnameData[x].qty_physics), String(stockOpnameData[x].qty_program),String(qtyDiff),
         String(price), String(priceDiff));
        cmds += newLine;
      }
    }
    cmds += '---------------------------------';
    cmds += newLine;
    cmds += 'Total  :';

    let formatPrice = this.formatRupiah(totalPriceDiff.toString());
    if(totalPriceDiff<0){
      formatPrice = '-'+formatPrice;
    }
    let diffLength: number = 33 - ('Total  :'.length)-(formatPrice.length);
    for(let s=0;s<diffLength;s++){
      cmds += " ";
    }
    cmds +=String(formatPrice);
    cmds += newLine;
    cmds += newLine;
    let printTotalQf =  this.formatRupiah(totalQf.toString());
    let printTotalQp =  this.formatRupiah(totalQp.toString());
    let printTotalQs =  this.formatRupiah(totalQs.toString());
    let totalQfLength = printTotalQf.length;
    let totalQpLength = printTotalQp.length;
    let totalQsLength = printTotalQs.length;
    let pickupLength = totalQfLength;
    if(totalQpLength >=totalQfLength && totalQpLength >= totalQsLength) {
      pickupLength = totalQpLength;
    }
    else if(totalQsLength >= totalQfLength && totalQsLength >= totalQpLength) {
      pickupLength = totalQsLength;
    }
    let diffQfLength = pickupLength - totalQfLength;
    cmds += 'Total QF :';
    for(let l=0;l<diffQfLength;l++){
      cmds += ' ';
    }
    cmds += printTotalQf;
    cmds += newLine;
    let diffQpLength = pickupLength - totalQpLength;
    cmds += 'Total QP :';
    for(let l=0;l<diffQpLength;l++){
      cmds += ' ';
    }
    cmds += printTotalQp;
    cmds += newLine;
    let diffQsLength = pickupLength - totalQsLength;
    cmds += 'Total QS :';
    for(let l=0;l<diffQsLength;l++){
      cmds += ' ';
    }
    cmds += printTotalQs;
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += newLine;
    cmds += '           TERIMA KASIH';
    cmds += newLine;
    cmds += newLine;
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    let counterName ='';
    let firstAddress = '';
    let lastAddress = '';
    let phone = '';
    if((this.userProfile.counter_detail)){
      if(this.userProfile.counter_detail.counter_name){
        counterName = this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
      }
      if(this.userProfile.counter_detail.first_address){
        firstAddress = this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address)); 
      }
      if(this.userProfile.counter_detail.last_address){
        lastAddress = this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address)) ;
      }
      if(this.userProfile.counter_detail.phone){
        phone = this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone));
      }
    }
    cmds += counterName;
    cmds += newLine;
    cmds += firstAddress;
    cmds += newLine;
    cmds += lastAddress;
    cmds += newLine;
    cmds += phone;
    cmds += newLine;
    cmds += cut;
    cpj.printerCommands = cmds;
    console.log(cmds);
    //Send print job to printer!
    cpj.sendToClient();
  }

  getLblPCodeAndName(PCode: String, productName: String, qtyPhysics: String, qtyProgram: String, qtyDiff: String): String {
    let lblPCodeAndName: String = '';
    let receiptRowLength: number = 33;

    let combineString: String = PCode+' '+productName+'.. '+qtyPhysics+' '+qtyProgram+' '+qtyDiff;
    let stringLength = combineString.length;
    if(stringLength > receiptRowLength) {
      let finalPrpductName = productName.slice(0, productName.length - (stringLength - receiptRowLength));
      lblPCodeAndName = PCode+' '+finalPrpductName+'.. '+qtyPhysics+' '+qtyProgram+' '+qtyDiff;
    } else {
      lblPCodeAndName = combineString;
    }

    return lblPCodeAndName;
  }

  getLblQtyAndPrice(qtyPhysic: String, qtyProgram: String, qtyDiff: String, price: String, priceDiff: String): String {
    let lblQtyAndPrice: String = '';
    let lblQtyPhysic: String = '';
    let lblQtyProgram: String = '';
    let lblQtyDiff: String = '';
    let lblPrice: String = '';
    let lblPriceDiff: String = '';
    let receiptRowLength: number = 33;

    let qtyPhysicLength: number = qtyPhysic.length;
    let qtyProgramLength: number = qtyProgram.length;
    let qtyDiffLength: number = qtyDiff.length;
    let priceLength: number = price.length;
    let priceDiffLength: number = priceDiff.length;
    let diffLength: number = receiptRowLength - (qtyPhysicLength + qtyProgramLength + qtyDiffLength + priceLength + priceDiffLength);
    if(diffLength !== 0) {
      let maxLengthQty = 4;
      let maxLengthPrice = 8;

      let forQtyPhysic = maxLengthQty - qtyPhysicLength;
      if(forQtyPhysic !== 0) {
        for(let i = 0; i < forQtyPhysic; i++) {
          lblQtyPhysic = lblQtyPhysic + ' ';
        }
  
        lblQtyPhysic = lblQtyPhysic + '' + qtyPhysic;
      } else {
        lblQtyPhysic = qtyPhysic;
      }

      let forQtyProgram = maxLengthQty - qtyProgramLength;
      if(forQtyProgram !== 0) {
        for(let i = 0; i < forQtyProgram; i++) {
          lblQtyProgram = lblQtyProgram + ' ';
        }
  
        lblQtyProgram = lblQtyProgram + '' + qtyProgram;
      } else {
        lblQtyProgram = qtyProgram;
      }

      let forQtyDiff = maxLengthQty - qtyDiffLength;
      if(forQtyDiff !== 0) {
        for(let i = 0; i < forQtyDiff; i++) {
          lblQtyDiff = lblQtyDiff + ' ';
        }
  
        lblQtyDiff = lblQtyDiff + '' + qtyDiff;
      } else {
        lblQtyDiff = qtyDiff;
      }

      let forPrice = maxLengthPrice - priceLength;
      if(forPrice !== 0) {
        for(let i = 0; i < forPrice; i++) {
          lblPrice = lblPrice + ' ';
        }
  
        lblPrice = lblPrice + '' + price;
      } else {
        lblPrice = price;
      }

      let forPriceDiff = maxLengthPrice - priceDiffLength;
      if(forPriceDiff !== 0) {
        for(let i = 0; i < forPriceDiff; i++) {
          lblPriceDiff = lblPriceDiff + ' ';
        }
  
        lblPriceDiff = lblPriceDiff + '' + priceDiff;
      } else {
        lblPriceDiff = priceDiff;
      }

      lblQtyAndPrice = ' ' + lblQtyPhysic + ' ' + lblQtyProgram + ' ' + lblQtyDiff + ' ' + lblPrice + ' ' + lblPriceDiff;
    } else {
      lblQtyAndPrice = ' ' + qtyPhysic + ' ' + qtyProgram + ' ' + qtyDiff + ' ' + price + ' ' + priceDiff;
    }

    return lblQtyAndPrice;
  }

  getLabelHeaderReceipt(headerText: String) {
    let totalLength: number = headerText.length;
    let maxTextLengthforReceipt = 33;
    let resultText = "";
    if(totalLength > maxTextLengthforReceipt) {
      resultText = headerText.slice(0, maxTextLengthforReceipt);
    } else {
      let diff = maxTextLengthforReceipt - totalLength;
      let divide = (diff - (diff % 2)) / 2;
      let space = "";
      for(let i = 0; i < divide; i++) {
        space = space + " ";
      }
      
      resultText = space + headerText + space;
    }

    return resultText;
  }

  private formatRupiah(angka: string) {
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
    // return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }

}
