export class MstCounter {

    id: number;
    branch_id: number;
    counter_code: string;
    counter_name: string;
    trans_date: string;
    stock_period: string;
    first_address: string;
    last_address: string;
    phone: string;
    footer_text: string;
    timezone: string;
    latitude: string;
    longitude: string;
    active: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.branch_id = model.branch_id || 0;
        this.counter_code = model.counter_code || null;
        this.counter_name = model.counter_name || null;
        this.trans_date = model.trans_date || null;
        this.stock_period = model.stock_period || null;
        this.first_address = model.first_address || null;
        this.last_address = model.last_address || null;
        this.phone = model.phone || null;
        this.footer_text = model.footer_text || null;
        this.timezone = model.timezone || '0';
        this.latitude = model.latitude || null;
        this.longitude = model.longitude || null;
        this.active = model.active || '1';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}