import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators,FormArray } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { SetupResellerRewardService } from '../setup-reseller-reward.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { PromotionDetailComponent } from '../../../component/promotion-detail/promotion-detail.component';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';
class Product {
  public id: string;
  public product_name: string;
  public barcode: string;
}
@Component({
  selector: 'app-setup-reseller-reward-edit',
  templateUrl: './setup-reseller-reward-edit.page.html',
  styleUrls: ['./setup-reseller-reward-edit.page.scss'],
})
export class SetupResellerRewardEditPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  db: any;
  roleAccess = new RoleAccess();  
  searchValue: any[] = [];
  productList: Product[];
  brandList:any[];
  selectedProduct: boolean=false;
  productScan: any[] = [];
  productScanDisplay: any[] = [];
  mstBarcodeList: Array<any>;
  mstProductList: Array<any>;

  formDetailCreate: FormGroup;
  selectedBrandList :any='';
  selectedProductList:any='';
  
  productListDisplay: string = '';
  incentiveId:number;
  incentiveData:any;
  incentiveDetailData:any;
  rewardTypeList:any;
  mstRewardList:any[]=[];
  customPeriode:boolean=false;
  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private modalController : ModalController,
    private navCtrl: NavController,
    private setupResellerRewardService: SetupResellerRewardService,
    private userData: UserData,
    private router: Router,
    private activeRoute:ActivatedRoute
  ) { }


  ngOnInit() {
    this.buildFormIncentiveEdit();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.activeRoute.queryParams.subscribe((snapshot) => {
          let myKey=environment.myKey;
          this.incentiveId = parseInt(AES.decrypt(snapshot.i, myKey).toString(utf8));
        });
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.create!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          let options = {
            "token": this.token,
            "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
          };
          this.setupResellerRewardService.getIncentiveforEdit(this.incentiveId,options).subscribe((response) => {
            this.utilService.loadingDismiss();
            if(!response.results.data){
              this.toastCtrl.create({ duration: 2000, message: 'Data Not Found' }).then(t => t.present());
              this.navCtrl.navigateForward(['/setup-reseller-reward']);
            }          
            this.productList = response.results.product_data;
            this.brandList = response.results.brand_data;
            this.incentiveData = response.results.data;
            this.incentiveDetailData = response.results.detail_data;
            this.rewardTypeList=response.results.reward_type_data;
            this.mstRewardList = response.results.reward_data;
            
            let str = this.incentiveData.product_list;
            let newstr ='';
            if(str!='' && str!=null){
              let re = /;/gi;
              newstr = str.replace(re, ", ");
            }
            this.productScan[0] = this.incentiveData.product_list;
            this.productScanDisplay[0]= newstr;
              
            let strBrand = this.incentiveData.brand_list;
            let brandIdSplit = strBrand !== '' ? String(strBrand).split(',') : [];
            let selectedBrands=[];
            for(let i= 0; i < this.brandList.length; i++) {
              let selectedBrand: any = this.brandList.find(x => x.id ==  brandIdSplit[i]);
              if(selectedBrand){
                let idx =this.brandList.indexOf(selectedBrand);
                selectedBrands.push(this.brandList[idx]);
              }
            }
              
            this.formDetailCreate = this.fb.group({
              brand:[selectedBrands],
              periode:[this.incentiveData.periode],
              incentiveName: [this.incentiveData.promotion_name, Validators.required],
              earlyPeriod: [this.incentiveData.early_period],
              endPeriod: [this.incentiveData.end_period],
              product:[],
              detail: this.fb.array([])
            });

            this.mstBarcodeList = [];
            this.mstProductList = [];
            for (let i = 0; i < this.productList.length; i++) {
              this.mstBarcodeList[this.productList[i].barcode] = {
                id: String(this.productList[i].id),
                barcode: this.productList[i].barcode,
                product_name: this.productList[i].product_name,
                stock: 0,
                terpakai: 0
              }
              
              let id = String(this.productList[i].id);
              let index: number = parseInt(id);
              if(Number.isNaN(index)) {
              } else {
                this.mstProductList[index] = {
                  id: String(this.productList[i].id),
                  barcode: this.productList[i].barcode,
                  product_name: this.productList[i].product_name,
                  stock: 0,
                  terpakai: 0
                }
              }
            }
            if(this.incentiveDetailData) {
              for(let i = 0; i < this.incentiveDetailData.length; i++) {
                let minimum = '';
                let master_reward_id = '';
                if(this.incentiveDetailData[i].minimum!=0){
                  minimum = this.incentiveDetailData[i].minimum;
                }
                console.log(this.incentiveDetailData[i]);
                if(this.incentiveDetailData[i].master_reward_id!=''){
                  master_reward_id = this.incentiveDetailData[i].master_reward_id;
                }

                let rewardIdSplit = master_reward_id !== '' ? String(master_reward_id).split(',') : [];
                let selectedRewards=[];
                for(let i= 0; i < this.mstRewardList.length; i++) {        
                  let selectedReward: any = this.mstRewardList.find(x => x.id ==  rewardIdSplit[i]);
                  if(selectedReward){
                    let idx =this.mstRewardList.indexOf(selectedReward);
                    selectedRewards.push(this.mstRewardList[idx]);
                  }
                } 

                const detail = this.fb.group({
                  detailId: [this.incentiveDetailData[i].id],
                  minTotalTrans: [parseInt(this.incentiveDetailData[i].minimum), Validators.required],
                  reward : [selectedRewards],
                  rewardQty:[parseInt(this.incentiveDetailData[i].reward_qty)],
                  multipleFlag:[this.incentiveDetailData[i].multiple_flag]
                });
  
                this.getDetailArray.push(detail);
              }
            }
          });
        });
      }
    });
  }

  buildFormIncentiveEdit() {
    this.formDetailCreate = this.fb.group({
      brand:[],
      periode:[0],
      incentiveName: [null, Validators.required],
      earlyPeriod: [null],
      endPeriod: [null],
      product:[],
      detail: this.fb.array([])
    });
  }

  inputIncentives() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const incentiveData = this.formDetailCreate.getRawValue();
     
      let newEarlyPeriod ='';
      let newEndPeriod ='';
      if(this.customPeriode){
        let earlyPeriods = this.utilService.convertDate(incentiveData.earlyPeriod);
        let endPeriods = this.utilService.convertDate(incentiveData.endPeriod);
        newEarlyPeriod = earlyPeriods.years + '-' + earlyPeriods.months + '-' + earlyPeriods.dates;
        newEndPeriod = endPeriods.years + '-' + endPeriods.months + '-' + endPeriods.dates;
      }
      const formDetail = this.formDetailCreate.value;
      
      let arrDetail: any = [];
      let arrMinimum: any = [];
      let arrReward: any = [];
      let arrRewardQty: any = [];
      let arrMultiple: any = [];
      for(let x = 0; x < formDetail.detail.length; x++) {
        arrDetail[x] =  x;
        arrMinimum[x] =  formDetail.detail[x].minTotalTrans;
        arrReward[x] =   formDetail.detail[x].reward;
        arrRewardQty[x]= formDetail.detail[x].rewardQty;
        arrMultiple[x] = formDetail.detail[x].multipleFlag
      }
      let mstIncentive = {
        periode:incentiveData.periode,
        arrDetail:arrDetail,
        arrMinimum:arrMinimum,
        arrReward:arrReward,
        arrRewardQty:arrRewardQty,
        arrMultiple:arrMultiple,
        incentive_name : incentiveData.incentiveName,
        early_period : newEarlyPeriod,
        end_period : newEndPeriod,
        brand_list : this.selectedBrandList,
        product_list : this.productListDisplay,
        created_by : this.userProfile.username,
     };

      this.setupResellerRewardService.updateSetupIncentive(mstIncentive,this.incentiveId).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/setup-reseller-reward']);;
          }
        }
      ]
    });

    await alert.present();
  } 

  async openListProduct(index: any, action: any) {
    const modal = await this.modalController.create({
      component: PromotionDetailComponent,
      componentProps: {
        'mstProductList': this.mstProductList,
        'mstBarcodeList': this.mstBarcodeList,
        'productScan': this.productScan[index] ? this.productScan[index] : null,
        'action': action
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData.data !== '') {
        let dataProduct: any[] = modelData.data;
        let productList: string = '';
        this.productListDisplay= '';

        if(dataProduct.length > 0) {
          for(let i = 0; i < dataProduct.length; i++) {
            productList = productList + dataProduct[i].id;
            this.productListDisplay =this.productListDisplay + dataProduct[i].id;
            if((i+1) !== dataProduct.length) {
              productList = productList + ';';
              this.productListDisplay = this.productListDisplay + ', ';
            }
          }
          this.productScan[index] = productList;
          this.productScanDisplay[index] = this.productListDisplay;
        } else {
          this.productScan[index] = null;
          this.productScanDisplay[index] = null;
        }
      } 
      else {
        this.productScan[index] = null;
        this.productScanDisplay[index] = null;
      }
    });

    return await modal.present();
  }

  get getDetailArray() {
    return (<FormArray>this.formDetailCreate.get('detail'));
  }

  addDetail() {
    const detail = this.fb.group({
      minTotalTrans: [0],
      reward: [],
      rewardQty:[0],
      multipleFlag:[0]
    });
    this.getDetailArray.push(detail);
  }  

  productChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    let product: any = event.value;
    this.selectedBrandList=product;
  }
  
  periodeChange(event:any){
    let periode=(event.target.value);
    if(periode==1){
      this.customPeriode = true;
    }
    else {
      this.customPeriode=false;
    }
  }
}