import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';
import { MstPurpose } from '../../models/mst-purpose.model';

@Injectable({
  providedIn: 'root'
})
export class PurposeService {

  constructor(private httpService: HttpService) { }

  getPurpose(params: any): Observable<any> {
    return this.httpService.get('purpose', params);
  }

  getPurposeforEdit(purposeId: any, params: any): Observable<any> {
    return this.httpService.get(`purpose/${purposeId}/edit`, params);
  }

  addPurpose(data: MstPurpose): Observable<any> {
    return this.httpService.post('purpose', data);
  }

  updatePurpose(purposeId: any, data: MstPurpose): Observable<any> {
    return this.httpService.put(`purpose/${purposeId}`, data);
  }

  deletePurpose(purposeId: any): Observable<any> {
    return this.httpService.delete(`purpose/${purposeId}`);
  }
}
