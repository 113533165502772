import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PriceGroupCreatePageRoutingModule } from './price-group-create-routing.module';

import { PriceGroupCreatePage } from './price-group-create.page';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    PriceGroupCreatePageRoutingModule,
    IonicSelectableModule
  ],
  declarations: [PriceGroupCreatePage],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PriceGroupCreatePageModule {}
