import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { PosResellerService } from '../../pages/pos-reseller/pos-reseller.service';
import { UtilService } from '../../service/util.service';
import { SearchProductPriceGroupService } from './search-product-price-group.service';

@Component({
  selector: 'app-search-product-price-group',
  templateUrl: './search-product-price-group.component.html',
  styleUrls: ['./search-product-price-group.component.scss'],
})
export class SearchProductPriceGroupComponent implements OnInit {
  
  @Input() token: any;
  @Input() priceGroupId: any;
  keyword: any;
  products: any[]=[];
  constructor(
    public modalController: ModalController,
    private searchProductPriceGroupService: SearchProductPriceGroupService,
    private utilService: UtilService,
    private toastCtrl: ToastController
  ) { }

  ngOnInit() {}

  dismissModal() {
    this.modalController.dismiss();
  }

  async search() {
    this.products = [];
    if(this.keyword.length >= 3) {
      this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
        let options = {
          "token": this.token,
          "keyword": this.keyword
        };

        this.searchProductPriceGroupService.getProductSearch(options).subscribe(async (response) => {
          if(response.results) {
            this.products = [];
            if(response.results.data.length > 0) {
              for (let index = 0; index < response.results.data.length; index++) {
                this.products.push({
                  id: response.results.data[index].id,
                  barcode: response.results.data[index].barcode,
                  product_name: response.results.data[index].product_name,
                  price: response.results.data[index].price
                }); 
              }
            }
            this.utilService.loadingDismiss();
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari product.' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari product.' }).then(t => t.present());
        });
      });
    }
  }

  chooseProduct(productId: any) {
    let product = this.products.find(x => x.id === productId);
    this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
      let options = {
        "token": this.token,
        "price_group_id": this.priceGroupId,
        "product_id": product.id,
      };

      this.searchProductPriceGroupService.checkProductInPriceGroupDetail(options).subscribe(async (response) => {
        if(response.results) {
          if(response.results.data.length > 0) {
            this.toastCtrl.create({ duration: 5000, message: 'PCode '+ response.results.data[0].product_id+' sudah ada di group harga ini.' }).then(t => t.present());
          }else{
            this.modalController.dismiss({product: product});
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari product.' }).then(t => t.present());
        }
        this.utilService.loadingDismiss();
        }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari product.' }).then(t => t.present());
      });
    });
  }
}
