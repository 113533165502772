import { Component, OnInit ,Input} from '@angular/core';
import { ClaimIncentiveService } from '../../pages/claim-incentive/claim-incentive.service';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-reseller-transaction-list',
  templateUrl: './reseller-transaction-list.component.html',
  styleUrls: ['./reseller-transaction-list.component.scss'],
})
export class ResellerTransactionListComponent implements OnInit {

  @Input() username:any;
  @Input() setupId: any;
  @Input() resellerId: any;
  @Input() year: any;
  @Input() month: any;
  @Input() totalBelanja: any;
  @Input() userProfile:any;
  @Input() typeForm:any;
  arrSumData: any[] = [];
  reportData: any;
  phoneNumber :any='';
  referralCode:any='';
  resellerName:any='';
  startDate:any='';
  endDate:any='';
  rewardList :any[]=[];
  reward:any;
  rewardMessage:any='';
  qty:number=0;
  monthList: any[] = ['', 'JAN', 'FEB', 'MAR', 'APR', 'MEI', 'JUN', 'JUL', 'AGU', 'SEP', 'OKT', 'NOV', 'DES'];
  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];
  selectedPrinter: any;
  printers: string[];
  isDefaultPrinterSelected = false;
  showListReward:boolean=false;
  constructor(
    private claimIncentiveService:ClaimIncentiveService,
    private modalController : ModalController,
    private utilService : UtilService,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private navCtrl : NavController
  ) { }

  ngOnInit() {
    if(this.typeForm=='give'){
      this.showListReward = true;
    }
    this.generate();
    // WebSocket settings
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (this.jspmWSStatus()) {
            // get client installed printers
            JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
              this.printers = myPrinters;
              this.isDefaultPrinterSelected = true;
            });
        }
    };
  }

  jspmWSStatus() { // Check JSPM WebSocket status
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
        alert('JSPM has blocked this website!');
        return false;
    }
  }
  async dismissModal() {
    await this.modalController.dismiss('');
  }
  
  generate() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
         setup_id: this.setupId,
         reseller_id: this.resellerId,
         year:this.year,
         month:this.month,
         total_belanja:this.totalBelanja
       };
      
      this.claimIncentiveService.generate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.reportData = response.results;
          this.countTotal(response.results);
          this.phoneNumber =response.reseller.phone;;
          this.referralCode = response.reseller.referral_code;
          this.resellerName = response.reseller.customer_name;
          this.startDate = response.start_date;
          this.endDate = response.end_date;
          this.rewardList = response.reward;
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        this.dismissModal();
      });
    });
  }

  countTotal(report: any) {
    let sumTotal: number = 0;
    for(let i = 0; i < report.length; i++) {
      sumTotal += parseInt(report[i].total);
    }

    let data = {
      sum_total: sumTotal
    };
    this.arrSumData.push(data);
  }

  giveReward(){
    if(this.reward){
      let paidDateConvert = this.utilService.convertDate(new Date());
      let paidDate = paidDateConvert.years + '-' + paidDateConvert.months + '-' + paidDateConvert.dates;

      let options ={
        setup_claim_incentive_id:this.setupId,
        customer_id : this.resellerId,
        period_transaction_start : this.startDate,
        period_transaction_end : this.endDate,
        total_transaction :this.totalBelanja,
        paid_date: paidDate,
        master_reward_id :this.reward.id,
        reward_name:this.reward.reward_name,
        nominal:this.reward.nominal_cashback,
        created_by : this.username,
        qty:this.qty
      }

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.claimIncentiveService.giveResellerReward(options)
        .subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.showConfirmInput();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    }
    else{      
      this.toastCtrl.create({ duration: 2000, message: 'Mohon Pilih Reward' }).then(t => t.present());        
    }
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.dismissModal();
            this.navCtrl.navigateForward(['/claim-incentive']);
          }
        }
      ]
    });
    await alert.present();
  }

  rewardChange(event:any){
    this.rewardMessage='';
    let selectedBrand: any = this.rewardList.find(x => x.id ==  event.value.id);
    if(selectedBrand){
      let idx =this.rewardList.indexOf(selectedBrand);
      let qty =this.rewardList[idx]['reward_qty'];
      if(this.rewardList[idx]['multiple_flag']=='1'){
        qty = Math.floor((parseFloat(this.totalBelanja) /parseFloat(this.rewardList[idx]['minimum'])) * this.rewardList[idx]['reward_qty'])
      }
      this.qty = qty;
      this.rewardMessage =' Reseller ini berhak mendapatkan '+this.rewardList[idx]['reward_name']+ ' sebanyak '+qty;    
    }
  }
  

  print() {
    let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
    let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
    let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
    let convertDate = this.utilService.convertDate(transactionDate);
    let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
    let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;
    //Create a ClientPrintJob
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += '---------------------------------';
    cmds += newLine;
    cmds += 'RESELLER : '+this.resellerName;
    cmds += newLine;
    cmds += 'KODE RESELLER  : '+this.referralCode;
    cmds += newLine;
    cmds += 'PERIODE  : '+this.startDate+' s/d '+this.endDate;
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += newLine;
    console.log(this.reportData);
   for(let i = 0; i < this.reportData.length; i++) {
      let grandTotal: number =  this.reportData[i].total; 
      let counterCode: String = this.reportData[i].counter_code;
      let receiptNo: String = this.reportData[i].receipt_no;
        cmds += counterCode+' '+receiptNo+' '+this.getLblGt(this.formatRupiah(grandTotal.toString()), counterCode.length+1, receiptNo.length+1);
        cmds += newLine;
    }
    let total: number =  this.totalBelanja; 
    let lblTotal: String = 'TOTAL : ';
    cmds += this.getLblTotal(this.formatRupiah(total.toString()), lblTotal);
    cmds += newLine;
    cmds += newLine;
    cmds += '          KLAIM INSENTIF';
    cmds += newLine;
    cmds += '           TERIMA KASIH';
    cmds += newLine;
    cmds += newLine;
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
    cmds += printPhone;
    cmds += newLine;
    cmds += cut;
    console.log(cmds);
    cpj.printerCommands = cmds;
    
    //Send print job to printer!
    cpj.sendToClient();
  }

  setPaper() {
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
    cmds += printPhone;
    cmds += newLine; 
    cmds += cut;
    cpj.printerCommands = cmds;
    //Send print job to printer!
    cpj.sendToClient();
  }

  getLabelHeaderReceipt(headerText: String) {
    let totalLength: number = headerText.length;
    let maxTextLengthforReceipt = 33;
    let resultText = "";
    if(totalLength > maxTextLengthforReceipt) {
      resultText = headerText.slice(0, maxTextLengthforReceipt);
    } else {
      let diff = maxTextLengthforReceipt - totalLength;
      let divide = (diff - (diff % 2)) / 2;
      let space = "";
      for(let i = 0; i < divide; i++) {
        space = space + " ";
      }
      
      resultText = space + headerText + space;
    }

    return resultText;
  }

  formatRupiah(angka: string) {
    var angka = angka.replace('.00', '').toString();
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
    // return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }

  getLblGt(gt: String, counterCodeLength: number, receiptNoLenght: number): String {
    let spaceLbl: String = '';
    let receiptRowLength: number = 33;

    let gtLength: number = gt.length;
    let diff: number = receiptRowLength - (gtLength + counterCodeLength + receiptNoLenght);
    console.log('diff', diff);
    for(let i = 0; i < diff; i++) {
      spaceLbl = spaceLbl + ' ';
    }
    const lblGt = spaceLbl + gt.toString();
    return lblGt;
  }
  getLblTotal(total: String, lbl: String): String {
    let spaceLbl: String = '';
    let receiptRowLength: number = 33;

    let gtLength: number = total.length;
    let diff: number = receiptRowLength - (gtLength + lbl.length);
    console.log('diff', diff);
    for(let i = 0; i < diff; i++) {
      spaceLbl = spaceLbl + ' ';
    }
    const lblTotal = spaceLbl +''+ lbl +''+ total.toString();
    return lblTotal;
  }
  
  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table-list');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'List Transaction.xlsx');
  }

}
