import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReportOtherReceivingPageRoutingModule } from './report-other-receiving-routing.module';

import { ReportOtherReceivingPage } from './report-other-receiving.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReportOtherReceivingPageRoutingModule
  ],
  declarations: [ReportOtherReceivingPage]
})
export class ReportOtherReceivingPageModule {}
