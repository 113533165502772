import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { OtherReceivingService } from '../other-receiving.service';
import { OtherReceivingStatus } from '../../../enum/OtherReceivingStatus';
import { RoleAccess } from '../../../models/role-access.model';
import { OtherReceivingClosedBundle } from '../../../models/other-receiving-cl-bundle.model';
import { FindProductComponent } from '../../../component/find-product/find-product.component';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-other-receiving-edit',
  templateUrl: './other-receiving-edit.page.html',
  styleUrls: ['./other-receiving-edit.page.scss'],
})
export class OtherReceivingEditPage implements OnInit {

  token: any;
  productSearch: Product[];
  productList: Product[];
  userProfile: UserProfile = new UserProfile();
  counterData: any[] = [];
  warehouseData: any[] = [];
  purposeList: any[] = [];
  otherReceivingData: any;
  otherReceivingDetailData: any[] = [];
  statusOtherReceiving: any = 0;
  otherReceivingDetailList: any[] = [];

  formOtherReceivingEdit: FormGroup;
  otherReceivingId: any;

  receivingStatus = OtherReceivingStatus;
  receivingStatusList = Object.keys(OtherReceivingStatus).filter(
    receivingStatus => typeof this.receivingStatus[receivingStatus] === 'number'
  );

  approvalHistoryData: any[] = [];
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;

  userAccess: any[] = [];
  roleAccess = new RoleAccess();
  roleName: any;

  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];  
  
  warehouseId :any='';
  counterId :any='';
  purposeId:any='';
  selectedWarehouses:any[];
  warehouseListShow:any[]=[];
  selectedPurposes:any[]=[];

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private activeRoute: ActivatedRoute,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private receivingService: OtherReceivingService,
    private router: Router,
    private modalController: ModalController,
    private userDataProvider: UserData
  ) { }

  ngOnInit() {
    this.buildFormOtherReceiving();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userAccess = access;
        this.roleName = this.userProfile.role_detail ? this.userProfile.role_detail.role_name : null;
        this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getAllProduct() {
    this.receivingService.getProduct({ "token": this.token }).subscribe((response) => {
      if (response.status.code === 200) {
        this.productList = response.results;
      }
    }, () => {
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  buildFormOtherReceiving() {
    let newDate = new Date();
    let convertDate = this.utilService.convertDate(newDate);
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formOtherReceivingEdit = this.fb.group({
      otherReceivingId: [this.otherReceivingId],
      counterId: [null],
      warehouseId: [null],
      purposeId: [null],
      docDate: [todayDate],
      description: [null],
      status: [null]
    });
  }

  filterProducts(products: Product[], text: string) {
    console.log(text);
    return products.filter(product => {
      return product.product_name.toLowerCase().indexOf(text) !== -1 ||
        product.id.toString().toLowerCase().indexOf(text.toLowerCase()) !== -1;
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.otherReceivingId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.otherReceivingId = this.otherReceivingId.replaceAll('"','');

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let options = {
          "token": this.token,
          "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
        };

        this.receivingService.getOtherReceivingforEdit(this.otherReceivingId, options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.otherReceivingData = response.results.other_receiving_data;
          this.otherReceivingDetailData = response.results.other_receiving_detail_data;
          this.productList = response.results.product_data;
          this.counterData = response.results.counter_data;
          this.warehouseData = response.results.warehouse_data;
          this.warehouseListShow = this.warehouseData;
          this.purposeList = response.results.purpose_data;

          let userCreate: any = response.results.user_create_data;
          this.createdBy = userCreate ? userCreate[0].name : null;
          let userUpdate: any = response.results.user_update_data;
          this.updatedBy = userUpdate ? userUpdate[0].name : null;
          this.approvalHistoryData = response.results.approval_history_data;
          
          if(this.otherReceivingData.length > 0) {
            this.createdAt = this.otherReceivingData[0].created_at;
            this.updatedAt = this.otherReceivingData[0].updated_at;
            this.statusOtherReceiving = this.otherReceivingData[0].status;
            let statusName = this.receivingStatusList[parseInt(this.statusOtherReceiving)];
           
            this.warehouseId = this.otherReceivingData[0].warehouse_id;
            let selectedWarehouse: any = this.warehouseData.find(x => x.id ==  this.warehouseId);
            if(selectedWarehouse){
              let idx =this.warehouseData.indexOf(selectedWarehouse);
              this.selectedWarehouses=(this.warehouseData[idx]);
            }

            this.counterId=this.otherReceivingData[0].counter_id;
            let filterWarehouse = this.warehouseData.filter(x => parseInt(x.counter_id) === parseInt(this.counterId));
            this.warehouseListShow = filterWarehouse;  

            this.purposeId = this.otherReceivingData[0].purpose_id;
            let selectedPurpose: any = this.purposeList.find(x => x.id ==  this.purposeId);
            if(selectedPurpose){
              let idx =this.purposeList.indexOf(selectedPurpose);
              this.selectedPurposes=this.purposeList[idx];
            }

            this.formOtherReceivingEdit = this.fb.group({
              otherReceivingId: [this.otherReceivingId],
              counterId: [this.otherReceivingData[0].counter_id],
              warehouseId: [this.otherReceivingData[0].warehouse_id],
              purposeId: [this.otherReceivingData[0].purpose_id],
              docDate: [this.otherReceivingData[0].doc_date],
              description: [this.otherReceivingData[0].desc],
              status: [statusName]
            });
          }

          if(this.otherReceivingDetailData.length > 0) {
            this.otherReceivingDetailList = [];
            for(let i = 0; i < this.otherReceivingDetailData.length; i++) {
              this.otherReceivingDetailList.push({
                'id' : i + 1,
                'product_id' : this.otherReceivingDetailData[i].product_id,
                'product_name' : this.otherReceivingDetailData[i].product_name,
                'qty_receive': this.otherReceivingDetailData[i].qty,
                'desc': this.otherReceivingDetailData[i].description
              });
            }
          } else {
            this.otherReceivingDetailList = [];
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateBundle(action: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formOtherReceiving = this.formOtherReceivingEdit.value;
      let docDateConvert = this.utilService.convertDate(formOtherReceiving.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      const otherReceivingClosedBundle = new OtherReceivingClosedBundle();
      otherReceivingClosedBundle.otherReceiving.id = this.otherReceivingId;
      otherReceivingClosedBundle.otherReceiving.warehouse_id = this.warehouseId;
      otherReceivingClosedBundle.otherReceiving.purpose_id = this.purposeId;
      otherReceivingClosedBundle.otherReceiving.doc_date = documentDate;
      otherReceivingClosedBundle.otherReceiving.desc = formOtherReceiving.description;
      otherReceivingClosedBundle.otherReceiving.status = action === 'data' ? '0' : '1';
      otherReceivingClosedBundle.otherReceiving.updated_by = this.userProfile.username;

      let arrProduct: any = [];
      let arrQty: any = [];
      let arrUnitType: any = [];
      let arrDesc: any = [];

      for(let x = 0; x < this.otherReceivingDetailList.length; x++) {
        let htmlIdQtyReceive: any = 'qtyReceive_' + x;
        let htmlIdDesc: any = 'description_' + x;
        let qtyReceive: any = (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;
        let description: any = (<HTMLInputElement>document.getElementById(htmlIdDesc)).value;

        arrProduct[x] = this.otherReceivingDetailList[x].product_id;
        arrQty[x] = parseInt(qtyReceive);
        arrUnitType[x] = 'PCS';
        arrDesc[x] = description;
      }

      otherReceivingClosedBundle.otherReceivingDetail.product_id = arrProduct;
      otherReceivingClosedBundle.otherReceivingDetail.qty = arrQty;
      otherReceivingClosedBundle.otherReceivingDetail.unit = arrUnitType;
      otherReceivingClosedBundle.otherReceivingDetail.description = arrDesc;

      if(action === 'status') {
        let timezoneName = this.userProfile.counter_detail && this.userProfile.counter_detail.timezone !== undefined ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
        let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
        let convertDate = this.utilService.convertDate(new Date());
        let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
        let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

        otherReceivingClosedBundle.approvalHistory.transaction_id = this.otherReceivingId;
        otherReceivingClosedBundle.approvalHistory.username = this.userProfile.username;
        otherReceivingClosedBundle.approvalHistory.status = '1';
        otherReceivingClosedBundle.approvalHistory.trans_date = transDate + ' ' + transTime;

        let arrWarehouseId: any[] = [];
        let arrTransactionId: any[] = [];
        let arrStockMutationTypeId: any[] = [];
        let arrProductId: any[] = [];
        let arrQty: any[] = [];
        let arrValue: any[] = [];
        let arrStockMove: any[] = [];
        let arrTransDate: any[] = [];
        let arrYear: any[] = [];
        let arrMonth: any[] = [];

        if(this.otherReceivingDetailList.length > 0) {
          for(let x = 0; x < this.otherReceivingDetailList.length; x++) {
            let htmlIdQtyReceive: any = 'qtyReceive_' + x;
            let qtyReceive: any = (<HTMLInputElement>document.getElementById(htmlIdQtyReceive)).value;

            arrYear[x] = convertDate.years;
            arrMonth[x] = convertDate.months;
            arrWarehouseId[x] = this.warehouseId;
            arrTransactionId[x] = this.otherReceivingId;
            arrStockMutationTypeId[x] = 'DL'; // DL = PENERIMAAN LAIN
            arrStockMove[x] = 'I';
            arrProductId[x] = this.otherReceivingDetailList[x].product_id;
            arrQty[x] = parseInt(qtyReceive);
            arrValue[x] = 0;
            arrTransDate[x] = transDate;
          }

          otherReceivingClosedBundle.stockMutation.warehouse_id = arrWarehouseId;
          otherReceivingClosedBundle.stockMutation.transaction_id = arrTransactionId;
          otherReceivingClosedBundle.stockMutation.stock_mutation_type_id = arrStockMutationTypeId;
          otherReceivingClosedBundle.stockMutation.product_id = arrProductId;
          otherReceivingClosedBundle.stockMutation.qty = arrQty;
          otherReceivingClosedBundle.stockMutation.value = arrValue;
          otherReceivingClosedBundle.stockMutation.stock_move = arrStockMove;
          otherReceivingClosedBundle.stockMutation.trans_date = arrTransDate;
        }
      }

      this.receivingService.updateOtherReceivingBundle(this.otherReceivingId, otherReceivingClosedBundle)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/other-receiving']);;
          }
        }
      ]
    });

    await alert.present();
  }

  addDetail() {
    let length = this.otherReceivingDetailList.length;
    this.otherReceivingDetailList.push({
      'id' : length + 1,
      'product_id' : null,
      'product_name' : null,
      'unit': null
    });
  }

  async findProduct(index: any) {
    const modal = await this.modalController.create({
      component: FindProductComponent,
      componentProps: {
        'productList': this.productList
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if(data) {
        let findProduct = this.otherReceivingDetailList.indexOf(data);
        if(findProduct === -1) {
          this.otherReceivingDetailList[index].product_id = data.id;
          this.otherReceivingDetailList[index].product_name = data.product_name;
        }
      }
    });

    return await modal.present();
  }

  deleteProduct(index: any) {
    this.otherReceivingDetailList.splice(index, 1);
  }  
  
  public selectWarehouse(event){
    this.warehouseId = event.value.id;
  }
  
  public selectPurpose(event){
    this.purposeId = event.value.id;
  }
}
