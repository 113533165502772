export class MstPurpose {

    id: number;
    purpose_name: string;
    status: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.purpose_name = model.purpose_name || null;
        this.status = model.status || '1';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}