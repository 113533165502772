import { Component, OnInit } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { ResellerCashbackService } from './reseller-cashback.service';
import { RoleAccess } from '../../models/role-access.model';

@Component({
  selector: 'app-reseller-cashback',
  templateUrl: './reseller-cashback.page.html',
  styleUrls: ['./reseller-cashback.page.scss'],
})
export class ResellerCashbackPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  token: any;
  userProfile: UserProfile = new UserProfile();
  rows: any;

  flagFind: boolean = false;
  
  roleAccess = new RoleAccess();

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private resellerCashbackService: ResellerCashbackService,
    private router: Router) { }

    ngOnInit() {
    }

    ionViewDidEnter() {
      Promise.all([
        this.storage.get('user_token'),
        this.storage.get('user_profile'),
        this.storage.get('user_menu_access')
      ])
      .then(([token, profile, access]) => {
        if(token) {          
          this.token = token;
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.update!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          this.pageCallback({ offset: this.page.offset });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
          this.navCtrl.navigateForward(['/login']);
        }
      });
    }
    
    pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
      this.page.offset = pageInfo.offset;
      this.reloadTable();
    }
    
    sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
      this.page.orderDir = sortInfo.sorts[0].dir;
      this.page.orderBy = sortInfo.sorts[0].prop;
      this.reloadTable();
    }
  
    reloadTable() {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let offset = this.page.offset + 1;
        let options = {
          "token": this.token,
          "page": offset.toString()
        };
  
        this.resellerCashbackService.getResellerCashback(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.page.count = response.results.total;
          this.rows = response.results.data;
        }, (err) => {
          this.utilService.loadingDismiss();
          console.log(err);
          if(err.error.error === 'token_expired') {
            this.userData.logout().then(() => {
              this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
              this.navCtrl.navigateForward(['/login']);
            });
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          }
        });
      });
    }

    goToResellerCashbackCreate() {
      this.navCtrl.navigateForward(['/reseller-cashback/reseller-cashback-create']);
    }
  
    goToUpdatePage(id: any) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          resellerMonthlyRewardId: id
        }
      };
      this.navCtrl.navigateForward(['/reseller-cashback/reseller-cashback-detail'], navigationExtras);
    }

    openAdvancedFilters() {
      //
    }

}
