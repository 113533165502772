import { Component, OnInit } from '@angular/core';
import { RoleAccess } from '../../models/role-access.model';
import { UserProfile } from '../../models/user-profile.model';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { UserData } from '../../providers/user-data';
import { NavigationExtras, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { UserService } from '../user/user.service';
import { AES } from 'crypto-js';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-register-approval',
  templateUrl: './register-approval.page.html',
  styleUrls: ['./register-approval.page.scss'],
})
export class RegisterApprovalPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };
  isApproval:boolean=false;
  userId :number=0;

  token: any;
  userProfile: UserProfile = new UserProfile();
  modelData: any;
  rows: any=[];
  filteredData: any;
  counterId: any='';
  counterList: any[] = [];
  keywordSearch: any;
  
  roleAccess = new RoleAccess();
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private alertController : AlertController,
    private userData: UserData,
    private modalController: ModalController,
    private router: Router,
    private httpClient:HttpClient,
    private userService:UserService
  ) { }
  ngOnInit() {
  }
  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userId = this.userProfile.id;
        console.log(this.userProfile);
        console.log(this.userId);

        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        this.pageCallback({ offset: this.page.offset });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords" :this.keywordSearch ? this.keywordSearch : 'null',
        "filter" :this.userProfile.id,
        "counter_id": this.counterId !== '' ? this.counterId : this.userProfile.counter_id_list
      };

      this.userService.getWaitingApprovalRegistration(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        let approval= response.results.approval;
        if(approval.length>0){
          this.isApproval=false;
          for(let i=0;i<approval.length;i++){
            if(approval[i].user_id==this.userId){
              this.isApproval=true;
            }
          }
        }

      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }
  async showConfirmAction(userId:number,roleId:number,action:string){
    let actionName='';
    if(action=='Y'){
      actionName='Approve';
    }
    else if(action=='N'){
      actionName ='Reject';
    }
    const alert = await this.alertController.create({
      header: 'Approval Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk '+actionName+' user ini?',
      buttons: [
        {
          text: 'Tidak',
          handler: () => {}
        },
        {
          text: 'Ya',
          handler: () => {
            if(action=='N'){
              this.showInputApprovalReason(userId,roleId,action);
            }
            else{
              this.approvalUser(userId,roleId,action,'');
            }
          }
        }
      ]
    });
    await alert.present();
  }

  approvalUser(userId:number,roleId:number,action:string,remark:string){
    
    let options = {
      "token": this.token,
      "approvalBy": this.userProfile.username,
      "action" :action,
      "userId" :userId,
      "remark": remark
    };
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.userService.approvalRegistration(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(action=='N'){
          window.location.reload();
        }
        else{        
          let myKey = environment.myKey;
          let encryptID = AES.encrypt(JSON.stringify(userId),myKey).toString();
          let encryptBackPage = AES.encrypt(JSON.stringify('approval'),myKey).toString();
          let navigationExtras: NavigationExtras = {
            queryParams: { i: encryptID,b:encryptBackPage }
          };
          this.utilService.loadingDismiss();
          if(roleId==0){
            this.navCtrl.navigateForward(['/user/user-edit'], navigationExtras); 
          }
          else{
            window.location.reload();
          }
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }); 
    });

  }
  async showInputApprovalReason(userId: number,roleId,action:string) {
    let actionName='';
    if(action=='Y'){
      actionName='Approve';
    }
    else if(action=='N'){
      actionName ='Reject';
    }
    const alert = await this.alertController.create({
      header: 'Confirmation',
      subHeader: 'Keterangan',
      message: 'Silahkan masukkan alasan '+actionName+' user',
      inputs: [
        {
          name: 'approvalRemark',
          placeholder: 'Keterangan',
          type: 'text'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: (input: any) => {
            let remark = input.approvalRemark ? input.approvalRemark : '';
            this.approvalUser(userId,roleId,action,remark);
          }
        }
      ]
    });
    
    await alert.present();
  }

}
