export class TutupKasir {

    user_id: number;
    counter_id: number;
    trans_date: string;

    constructor(model?) {
        model = model || {};
        this.user_id = model.user_id || 0;
        this.counter_id = model.counter_id || 0;
        this.trans_date = model.trans_date || null;
    }
}