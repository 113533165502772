import { Component, OnInit ,Input} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { SetupApprovalService } from '../setup-approval/setup-approval.service';

@Component({
  selector: 'app-setup-pic',
  templateUrl: './setup-pic.component.html',
  styleUrls: ['./setup-pic.component.scss'],
})
export class SetupPicComponent implements OnInit {

  @Input() approvalName: any;
  @Input() token: any;
  @Input() no: any;
  @Input() roleList :any;
  @Input() roleListExist:any;
  @Input() userListExist :any;
  
  users: any[]=[];
  userList: any[] = [];
  roleIdList :any[]=[];
  keyword: any;
  roleId=0;
  selectedRoles: any[];
  basedOn:any='';
  checkedAllUser=false;
  checkedAllRole=false;
  constructor(
    public modalController: ModalController,
    private setupApprovalService : SetupApprovalService,
    private utilService: UtilService,
    private toastCtrl: ToastController
  ) { }

  ngOnInit() {
  }
  

  async dismissModal() {
    let dataClose =
    {
      basedOn:this.basedOn,
      user:this.userList,
      role:this.roleIdList,
      no:this.no
    };
    this.modalController.dismiss(dataClose);
  }
  selectBasedOn(){
    if(this.basedOn=='role'){
      this.userList=[];
      this.users=[];
    }
    else if(this.basedOn=='employee'){
      this.roleIdList=[];
    }
  }

 selectRole(event){
    this.roleId = event.value.id;
    this.roleIdList = [];
    for(let i= 0; i < event.value.length; i++) {
      let role = this.roleList.find(x => x.id === event.value[i]['id']);
      this.roleIdList.push(role);
    }
  }

  async search() {
    this.users = [];
    if(this.keyword.length >= 3) {
      this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
        let options = {
          "token": this.token,
          "keyword": this.keyword
        };
        this.setupApprovalService.getUserSearch(options).subscribe(async (response) => {
          if(response.results) {
            this.users = [];
            if(response.results.data.length > 0) {
              for (let index = 0; index < response.results.data.length; index++) {
                this.users.push({
                  user_id: response.results.data[index].id,
                  name: response.results.data[index].name,
                  email: response.results.data[index].email,
                  employee_id: response.results.data[index].employee_id,
                  no:this.no
                }); 
              }
            }
            this.utilService.loadingDismiss();
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari user.' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari user.' }).then(t => t.present());
        });
      });
    }
  }
  chooseUser(user: any) {
    if(user !== '' && user !== undefined) {
      let checkItemPcode: any[] = this.userList.find(x => x.id === user.user_id);
      let checkUserListExist: any[] = this.userListExist.find(x => x.employee_id === user.employee_id);
      if (checkUserListExist===undefined) {
        if(checkItemPcode === undefined) {
          this.userList.push({
            id: user.user_id,
            name: user.name,
            email: user.email,
            employee_id:user.employee_id
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'User sudah ada di list!' }).then(t => t.present());
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'User sudah ada di list!' }).then(t => t.present());
      }
    }
  }
  uncheckAllUser() {
    if(this.users.length > 0) {
      for(let i = 0; i < this.users.length; i++) {
        if(this.checkedAllUser === true) {
          this.users[i].isChecked = false; 
          let cari =this.userList.find(x=>x.id==this.users[i].user_id);
          if(cari!==undefined){
            let index = this.userList.indexOf(cari);
            this.userList.splice(index, 1);
          }      
        }else{
          this.users[i].isChecked = true;
          let cari =this.userList.find(x=>x.id==this.users[i].user_id);
          if(cari==undefined){
            this.chooseUser(this.users[i]);
          }
        }
      }
      if(this.checkedAllUser=== true) {
        this.checkedAllUser = false;
      }
      else{          
        this.checkedAllUser = true;
      }
    }
    console.log(this.userList);
  }
  
  deleteDetail(index: any) {
    this.userList.splice(index, 1);
  }
  selectCheckboxUser(userId:any,checked:any){
    if(this.users.length > 0) {
      let Checbox = (this.users.find(x => x.user_id === userId));      
      let indexChecbox = this.users.indexOf(Checbox);
      if(checked=='check'){
        this.users[indexChecbox].isChecked=true;
        let cari =this.userList.find(x=>x.id==userId);
        if(cari==undefined){
          this.chooseUser(Checbox);
          if(this.userList.length==this.users.length){
            this.checkedAllUser=true;
          }
          else{
            this.checkedAllUser==false;
          }
        }
      }
      else{
        this.users[indexChecbox].isChecked=false;
        let cari =this.userList.find(x=>x.id==userId);
        if(cari!==undefined){
          let index = this.userList.indexOf(cari);
          this.userList.splice(index, 1);
          this.checkedAllUser=false;
        }
      }
    }
    
  }
}
