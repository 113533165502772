import { Component, ElementRef, OnInit ,ViewChild} from '@angular/core';
import { Storage } from '@ionic/storage';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { UserProfile } from '../../../models/user-profile.model';
import { UserData } from '../../../providers/user-data';
import { UtilService } from '../../../service/util.service';
import { SalesTargetService } from '../sales-target.service';
import { RoleAccess } from '../../../models/role-access.model';
import { SalesTargetComponent } from '../../../component/sales-target/sales-target.component';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import { MyService } from '../../../service/my.service';

@Component({
  selector: 'app-sales-target-create',
  templateUrl: './sales-target-create.page.html',
  styleUrls: ['./sales-target-create.page.scss'],
})
export class SalesTargetCreatePage implements OnInit {
  @ViewChild('uploadFileInput', { static: false }) uploadFileInput: ElementRef;

  formSalesTargetCreate: FormGroup;
  counterList: any[] = [];
  token:any;
  userProfile: UserProfile = new UserProfile();
  productList: any[] = [];
  rows: any[];
  showProductList : boolean = true;
  
  roleAccess = new RoleAccess();
  counterId :any='';
  constructor(
    
   private fb: FormBuilder,
   private utilService:UtilService,
   private salesTargetService : SalesTargetService,
   private toastCtrl :ToastController,
   private navCtrl : NavController,
   private alertController : AlertController,
   private modalController :ModalController,
   private storage: Storage,
   private userData: UserData,
   private router: Router,
   private myService:MyService
  ) { }

  ngOnInit() {
    this.buildFormSalesTargetCreate();
    
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        this.getSalesTargetforCreate();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    })
  }

  buildFormSalesTargetCreate() {
    this.formSalesTargetCreate = this.fb.group({
      counterId: [null, Validators.required],
      periode: [null, Validators.required],
      target: [null, Validators.required],
      description :[null],
      keywordSearch : [null]
    });
  }
  
  getSalesTargetforCreate() {    
    console.log('panggil api untuk ambil data counter');
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };
      this.salesTargetService.getSalesTargetCreate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.counterList = response.results.mst_counter_data;
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  
  input() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formSalesTarget = this.formSalesTargetCreate.value;
      let arrProduct: any = [];
      let arrTarget: any = [];

      let periodeConvert = this.utilService.convertDate(formSalesTarget.periode);
      for(let x = 0; x < this.productList.length; x++) {
        arrProduct[x] = this.productList[x].product_id;
        arrTarget[x] = parseInt(this.productList[x].target_product);
      }
    
      let body = {
        counter_id : this.counterId,
        month :periodeConvert.months,
        year : periodeConvert.years,
        target : this.myService.removeSeparator(formSalesTarget.target),
        description : formSalesTarget.description,
        created_by : this.userProfile.username,
        product_id: arrProduct,
        target_product:arrTarget
      }
      this.salesTargetService.addSalesTarget(body).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/sales-target']);;
          }
        }
      ]
    });

    await alert.present();
  }
  
  searchProduct() {
    const formSalesTarget = this.formSalesTargetCreate.value;
    /*this.rows = this.salesTargetDetailData.filter(item => {
      return item.product_name.toLowerCase().indexOf(formPriceGroup.keywordSearch) !== -1 ||
        item.product_id.toString().toLowerCase().indexOf(formPriceGroup.keywordSearch) !== -1;
    });*/
  }
  
  toggleProductList() {
    if(this.showProductList === false) {
      this.showProductList = true;
    } else {
      this.showProductList = false;
    }
  }
  getProductByCounter() {  
    this.productList = [];
    let counterId = this.counterId;  
    console.log('panggil api untuk ambil data product per counter');
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": counterId
      };
      this.salesTargetService.getProductByCounter(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.productList = response.results.products;
          this.rows =  this.productList;
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal Mendapatkan Daftar Product' }).then(t => t.present());
      });
    });
  }
  
  async changeTargetProduct(productData: any) {
    console.log(productData);
    const modal = await this.modalController.create({
      component: SalesTargetComponent,
      componentProps: {
        'productId': productData.product_id,
        'productName': productData.product_name,
        'productTarget': productData.target_product,
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      console.log(this.productList);
      console.log(modelData);
      let data = modelData.data;
      let checkProduct: any[] = this.productList.find(x => x.product_id === data[0].product_id);
      console.log(checkProduct);
      if (checkProduct !== undefined) {
        let index: number = this.productList.indexOf(checkProduct);
        console.log(index);
        this.productList[index]['target_product'] = data[0].target;
        this.rows[this.rows.indexOf(this.rows.find(x => x.product_id === data[0].product_id))]['target_product'] = data[0].target;
      }
      console.log(this.rows);
    });

    return await modal.present();
  }
  
  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let filename = 'Sales Target.xlsx';

    /* save to file */
    XLSX.writeFile(wb, filename);
  }

  onFileSelect(event) {
    let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      if (!_.includes(af, file.type)) {
        this.toastCtrl.create({ duration: 2000, message: 'Hanya untuk upload file excel !' }).then(t => t.present());
      }
    }
  }
  
  uploadFile() {
    if (!this.uploadFileInput.nativeElement.files[0]) {
      alert('Please fill valid details!');
      return false;
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formSalesTarget= this.formSalesTargetCreate.value;
      let target=this.myService.removeSeparator(formSalesTarget.target);
      let periodeConvert = this.utilService.convertDate(formSalesTarget.periode);

      const formData = new FormData();
      formData.append('counter_id', this.counterId);
      formData.append('target', target.toString());
      formData.append('month', periodeConvert.months);
      formData.append('year', periodeConvert.years);
      formData.append('description', formSalesTarget.description);
      formData.append('created_by', this.userProfile.username);
      formData.append('file', this.uploadFileInput.nativeElement.files[0]);

      this.salesTargetService.uploadFile(formData).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.uploadFileInput.nativeElement.value = "";
          this.showConfirmInput();
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  selectCounter(event){
    this.counterId = event.value.id;
    this.getProductByCounter();
  }
  
  getChangeNominal(columnname:any){
    let arrform=<FormArray>this.formSalesTargetCreate.controls[columnname];    
    let cur= arrform.value;
    let curWithSeparator='0';
    if(cur!='0' && cur!='' && cur!=null){
      curWithSeparator = this.myService.separator(cur);
      curWithSeparator = String(curWithSeparator).replace(/[^0-9,]/g, '');
    }
    this.formSalesTargetCreate.controls[columnname].setValue(curWithSeparator);
  }

}
