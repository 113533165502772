import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { MstCounter } from '../../models/mst-counter.model';

@Injectable({
  providedIn: 'root'
})
export class ClosedMonthService {

  constructor(private httpService: HttpService) { }

  processClosedMonth(params: any): Observable<any> {
    return this.httpService.get('process-closed-month', params);
  }

  updateCounter(counterId: any, data: MstCounter): Observable<any> {
    return this.httpService.put(`counter/${counterId}`, data);
  }
}
