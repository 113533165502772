import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PettyCashCategoryPage } from './petty-cash-category.page';

const routes: Routes = [
  {
    path: '',
    component: PettyCashCategoryPage
  },
  {
    path: 'petty-cash-category-create',
    loadChildren: () => import('./petty-cash-category-create/petty-cash-category-create.module').then( m => m.PettyCashCategoryCreatePageModule)
  },
  {
    path: 'petty-cash-category-edit',
    loadChildren: () => import('./petty-cash-category-edit/petty-cash-category-edit.module').then( m => m.PettyCashCategoryEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PettyCashCategoryPageRoutingModule {}
