import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../../service/util.service';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportService } from '../../report.service';
import { OtherExpencesStatus } from '../../../../enum/OtherExpencesStatus';
import { ReportOtherExpencesComponent } from '../../../../component/advanced-filter/report-other-expences/report-other-expences.component';
import { ReportOtherExpencesDetailComponent } from '../../../../component/report-other-expences-detail/report-other-expences-detail.component';
import { ReportCashierCounterListComponent } from '../../../../component/report-cashier-counter-list/report-cashier-counter-list.component';
import { WarehouseListComponent } from '../../../../component/warehouse-list/warehouse-list.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-other-expences',
  templateUrl: './report-other-expences.page.html',
  styleUrls: ['./report-other-expences.page.scss'],
})
export class ReportOtherExpencesPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  paramOtherExpencesId: any = null;
  paramBranchId: any;
  paramCounterId: any;
  paramWarehouseId: any;
  paramDocDateStart: any = null;
  paramDocDateEnd: any = null;
  paramPurposeId: any = null;

  reportData: any[] = [];
  reportDataDetail: any[] = [];
  
  counterData: any[] = [];
  warehouseData: any[] = [];
  purposeData: any[] = [];
  counterName: any = null;
  warehouseName: any = null;
  purposeName: any = null;
  otherExpencesIdList:any[] =[];
  fileName= 'Report_Pengeluaran_Lain_Lain.xlsx';
  
  showBtnCounterList: boolean = false;
  showBtnWarehouseList: boolean = false;
  counterListForShow: any[] = [];
  warehouseListForShow: any[] = [];

  otherExpencesStatus = OtherExpencesStatus;
  otherExpencesStatusList = Object.keys(OtherExpencesStatus).filter(
    oeStatus => typeof this.otherExpencesStatus[oeStatus] === 'number'
  );
  constructor(
    private storage: Storage,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private reportService: ReportService
    ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.clearFilter();
        });
      }
    });
  }
  
  async openAdvancedFilters(){
    
    const modal = await this.modalController.create({
      component: ReportOtherExpencesComponent,
      componentProps: {
        counterList: this.counterData,
        warehouseList: this.warehouseData,
        paramOtherExpencesId: this.paramOtherExpencesId,
        paramBranchId: this.paramBranchId,
        paramCounterId: this.paramCounterId,
        paramWarehouseId: this.paramWarehouseId,
        paramDocDateStart: this.paramDocDateStart,
        paramDocDateEnd: this.paramDocDateEnd,
        purposeList: this.purposeData,
        paramPurposeId: this.paramPurposeId
      },
      backdropDismiss: false
    });
    
    modal.onDidDismiss().then((modelData) => {      
      let data = modelData.data;
      console.log(data);
      if (data.length > 0) {
        this.paramOtherExpencesId = data[0].otherExpencesId;
        this.paramCounterId = data[0].counterId;
        this.paramBranchId = data[0].branchId;
        this.counterName = data[0].counterName;
        this.paramWarehouseId = data[0].warehouseId;
        this.warehouseName = data[0].warehouseName;
        this.paramDocDateStart = data[0].docDateStart;
        this.paramDocDateEnd = data[0].docDateEnd;
        this.paramPurposeId = data[0].purposeId;
        this.purposeName = data[0].purposeName;
        this.showBtnCounterList = false;
        this.showBtnWarehouseList = false;
        this.generateReport();
      }
    })

    return await modal.present();
  }
  
  async showCounterList() {
    const modal = await this.modalController.create({
      component: ReportCashierCounterListComponent,
      componentProps: {
        counterList: this.counterListForShow
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }

  async showWarehouseList(){
    const modal = await this.modalController.create({
      component: WarehouseListComponent,
      componentProps: {
        warehouseList: this.warehouseListForShow
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }

  clearFilter(){
    this.reportData = [];
    this.reportDataDetail = [];
    this.paramOtherExpencesId ='';
    
    let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
    let options = { 
      "token": this.token,
      "counter_id": counterId
    };
    this.utilService.loadingDismiss();
    this.reportService.getReportOtherExpencesIndex(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.counterData = response.results.counter_data;
      this.warehouseData = response.results.warehouse_data;
      this.purposeData = response.results.purpose_data;
                 
      this.counterListForShow =  this.counterData;

      if(this.counterListForShow.length > 0) {
        let loopCounter = 0;
        if(this.counterListForShow.length > 5) {
          loopCounter = 5;
          //this.showBtnCounterList = true;
        } else {
          loopCounter = this.counterListForShow.length;
          this.showBtnCounterList = false;
        }
      
        this.counterName = '';
        this.purposeName = '';
        this.paramPurposeId = '';
      /*  for(let i = 0; i < loopCounter; i++) {
          if(this.counterName === '') {
            this.counterName = this.counterListForShow[i].counter_name;
          } else {
            this.counterName = this.counterName + ', ' + this.counterListForShow[i].counter_name;
          }
        }*/
      }  
      
      this.warehouseListForShow =  this.warehouseData;

      if(this.warehouseListForShow.length > 0) {
        let loopWarehouse = 0;
        if(this.warehouseListForShow.length > 5) {
          loopWarehouse = 5;
          //this.showBtnWarehouseList = true;
        } else {
          loopWarehouse = this.warehouseListForShow.length;
          this.showBtnWarehouseList = false;
        }
      
        this.warehouseName = '';
       /* for(let i = 0; i < loopWarehouse; i++) {
          if(this.warehouseName === '') {
            this.warehouseName = this.warehouseListForShow[i].warehouse_name;
          } else {
            this.warehouseName = this.warehouseName + ', ' + this.warehouseListForShow[i].warehouse_name;
          }
        }*/
      }
      let todayDate = new Date();
      let convertDate = this.utilService.convertDate(todayDate);
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      this.paramDocDateStart = transDate;
      this.paramDocDateEnd = transDate;
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
    
  }

  otherExpencesDetail(otherExpencesId: any, action: any) {
    let sendId = '';
    if (Array.isArray(otherExpencesId)){
      for(let i = 0; i < otherExpencesId.length; i++) {
        if(i==0){
          sendId = "'"+otherExpencesId[i].id+"'";
        }
        sendId = sendId + ', ' + "'"+otherExpencesId[i].id+"'";
        
      }
      otherExpencesId = sendId;
    }
    else{
      otherExpencesId = "'"+otherExpencesId+"'";
    }
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "other_expences_id": otherExpencesId

      };
      this.reportService.getReportOtherExpencesDetail(options).subscribe((response) => {
        if(response.status.code === 200) {
          this.reportDataDetail = response.report_data;
          if(action === 'detail') {
            this.utilService.loadingDismiss();
            this.openReportProductDetail();
          }

          if(action === 'export' || action==='export-detail') {
            this.waitForDataToLoad(action);
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    })
  }

  async waitForDataToLoad(action:any) {
    await new Promise(resolve => setTimeout(resolve, 1500));
    if(action==='export'){
      this.exportToExcel();
    }
    else{
      this.exportToExcelDetail();
    }
  }
  exportToExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    delete (ws['I1']);
    for(let i=0;i<this.reportData.length;i++){
      delete (ws['I'+(i+2)]);
    }

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.utilService.loadingDismiss();
  }

  exportToExcelDetail() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table-detail');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.utilService.loadingDismiss();
  }

  async openReportProductDetail() {
    const modal = await this.modalController.create({
      component: ReportOtherExpencesDetailComponent,
      componentProps: {
        'reportDataDetail': this.reportDataDetail
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }
  generateReport() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      if (this.paramCounterId==null){
        this.paramCounterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
      }
      let options = {
        "token": this.token,
        "other_expences_id": this.paramOtherExpencesId,
        "counter_id": this.paramCounterId,
        "warehouse_id": this.paramWarehouseId,
        "doc_date_start": this.paramDocDateStart,
        "doc_date_end": this.paramDocDateEnd,
        "purpose_id":this.paramPurposeId,
      };
      this.otherExpencesIdList = [];
      this.reportService.getReportOtherExpences(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.reportData = response.report_data;
          console.log(this.reportData);
          if(this.reportData.length > 0) {
            if(this.paramOtherExpencesId !== null) {
             // this.paramOtherExpencesId = this.reportData[0].id;
              this.paramCounterId = this.reportData[0].counter_id;
              this.counterName = this.reportData[0].counter_name;
              this.paramWarehouseId = this.reportData[0].warehouse_id;
              this.warehouseName = this.reportData[0].warehouse_name;
              this.paramDocDateStart = this.reportData[0].doc_date;
              this.paramDocDateEnd = this.reportData[0].doc_date;
             
            }
            for(let i:any=0;i< this.reportData.length;i++){
              let data = {                
                id:this.reportData[i].id
              }
              this.otherExpencesIdList.push(data
              );
            }
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });

    
  }
  
  checkStatus(status: any) {
    let cssClass: any = '';
    if(status === '0') {}
    if(status === '1') {}
    if(status === '2') {}
    return cssClass;
  }
}
