import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpService } from '../../service/http.service';

@Injectable({
  providedIn: 'root'
})
export class AddProductPriceGroupService {

  constructor(private httpService: HttpService) { }

  _addProductPriceGroup(params: any): Observable<any> {
    return this.httpService.post('price-group/add-product', params);
  }
}
