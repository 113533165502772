import { Component, OnInit } from '@angular/core';
import { NavController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { VoucherComponent } from '../../component/voucher/voucher.component';
import { SearchProductComponent } from '../../component/search-product/search-product.component';
import { PromotionFreeItemComponent } from '../../component/promotion-free-item/promotion-free-item.component';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';
import { CompletePluginService } from '../../service/complete-plugin.service';
import { PosResellerService } from '../pos-reseller/pos-reseller.service';
import { ConnectivityProvider } from '../../providers/connectivity.provider';
import { MemberResellerComponent } from '../../component/member-reseller/member-reseller.component';
import { SalesTransactionBundleReseller } from '../../models/sales-transaction-bundle-reseller.model';
import { UserData } from '../../providers/user-data';
import { Router } from '@angular/router';
import { RoleAccess } from '../../models/role-access.model';
@Component({
  selector: 'app-pos-promo-oto-reseller',
  templateUrl: './pos-promo-oto-reseller.page.html',
  styleUrls: ['./pos-promo-oto-reseller.page.scss'],
})
export class PosPromoOtoResellerPage implements OnInit {
  
  productBarcode: any = "";
  transactionTime: any = '';
  productId: any = "";
  modelData: any;
  otherPayment: boolean = false;
  totalPayment: number = 0;
  totalPaymentWithoutPromo: number = 0;
  totalChange: number = 0;
  totalCustomerPay: number = 0;
  customerCash: number = 0;
  customerTransfer: number = 0;
  totalCustomerCash: number = 0;
  customerCredit: number = 0;
  customerDebit: number = 0;
  transactionDiscount: number = 0;
  totalItem: number = 0;
  ppn: number = 0;
  valueUSD: number = 0;
  valueCNY: number = 0;
  valueWeChat: number = 0;
  totalChangeUSD: any = 0;
  totalChangeCNY: any = 0;
  exchangeRateUSD: number = 14257;
  exchangeRateCNY: number = 2204;
  exchangeRateWeChat: number = 2204;
  receiptNo: any;

  creditBankList: any[] = [];
  debitBankList: any[] = [];
  foreignCurrencyList: any[] = [];
  promotionDetailList: any[] = [];
  promotionFreeItemList: any[] = [];
  promotionProductScanData: any[] = [];
  stockFreeItem: any[] = [];
  edcMachineList: any[] = [];

  productList: Array<any>;
  productSales: Array<any>;

  printers: string[];
  isDefaultPrinterSelected = false;
  selectedPrinter: any;

  mstBarcodeList: Array<any>;
  mstProductList: Array<any>;
  mstProductListSearch: Array<any>;
  token: string;
  userProfile: UserProfile = new UserProfile();

  customerCreditId: any;
  customerDebitId: any;
  customerCreditName: any;
  customerDebitName: any;
  foreignCurrencyId: any;
  foreignCurrencyName: string;
  foreignCurrencyRate: number = 0;
  voucherUseList: any[] = [];
  voucherValue: number = 0;
  edcId: any;

  memberPhone: any = '';
  resellerName: any = '';
  resellerCode: any = '';
  memberPointUsed: number = 0;
  memberPointValueUsed: number = 0;
  memberPointValue: number = 0;
  memberId: any;
  totalPointAdd: number = 0;
  pointMutationType: any = '';

  db: any;

  cashierSetupList: any = null;

  voucherMethodId: any = '';
  memberPointMethodId: any = '';
  valasMethodId: any[] = [];
  cashMethodId: any = '';
  transferMethodId: any = '';

  monthList: any[] = ['JAN', 'FEB', 'MAR', 'APR', 'MEI', 'JUN', 'JUL', 'AGU', 'SEP', 'OKT', 'NOV', 'DES'];
  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];
  //untuk print ulang struk
  productSalesTemp: Array<any>;
  transactionTimeTemp: any = '';
  totalItemTemp: number = 0;
  totalPaymentTemp: number = 0;
  transactionDiscountTemp: number = 0;
  customerCashTemp: number = 0;
  totalCustomerCashTemp: number = 0;
  customerCreditTemp: number = 0;
  customerDebitTemp: number = 0;
  totalCustomerPayTemp: number = 0;
  totalChangeTemp: number = 0;
  totalChangeUSDTemp: number = 0;
  totalChangeCNYTemp: number = 0;
  valueWeChatTemp: number = 0;
  valueCNYTemp: number = 0;
  valueUSDTemp: number = 0;
  voucherValueTemp: number = 0;
  customerCreditIdTemp: any;
  customerDebitIdTemp: any;
  foreignCurrencyIdTemp: any;
  edcIdTemp: any;
  ppnTemp: number = 0;
  memberIdTemp: any;
  memberPhoneTemp: any = '';
  memberPointValueTemp: number = 0;
  memberPointUsedTemp: number = 0;
  totalPointAddTemp: number = 0;
  pointMutationTypeTemp: any = '';
  voucherUseListTemp: any[] = [];

  currentTransDate: any;
  currentTimezone: any;
  currentUsername: any = '-';
  currentCashierName: any = '-';
  
  visitorId: any;
  

  //reseller_code_sign
  rewards : any = {
    downline: {
      resellerId: null,
      pointMutation: {
        customerId: null,
        point: 0,
        pointValue: 0,
        type: 'I'
      },
      reward: {
        sisa:0,
        pakai:0,
        readonlyPakai:true,
        paymentMethodId:null,
      },
      terms: {
        name: null,
        minBelanjaPoint: {
          term: 0,
          reward: 0
        }
      }
    },
    upline: {
      resellerId: null,
      rewardMutation: [],
      terms: {
        name: null,
        minBelanja500rb: {
          term: 0,
          reward: 0
        },
        reward2setengahPersen: {
          term: 0,
          reward: 0
        }
      }
    },
  }
  buttonDisabled: boolean = false;
  online: boolean;
  lamp: any;
  message : any = '';
  promoItemNominal : any;
  countFreeItem : number = 0;

  arrayFreeItemUsed : any[] = [];
  listFreeItemByTotalBelanja : any[] =[];
  arraySum :any[] = [];
  arraySumNominal : any[] =[];
  roleAccess = new RoleAccess();

  constructor(
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private modalController: ModalController,
    private storage: Storage,
    private alertController: AlertController,
    public completePluginService: CompletePluginService,
    private posResellerService: PosResellerService,
    private connectivityProvider: ConnectivityProvider,
    private userData: UserData,
    private router: Router
  ) {
    this.currentTime();
  }

  checkConnectivity() {
    this.connectivityProvider.appIsOnline$.subscribe(online => {
      if (online) {
        this.online = online;
        this.lamp = 'green';
        console.log('online', this.online);
      } else {
        this.online = online;
        this.lamp = 'red';
        console.log('online', this.online);
      }
    })
  }
  
  ngOnInit() {
    // this.checkConnectivity();
    this.productList = new Array();
    this.productSales = new Array();
    this.mstProductList = new Array();
    
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access'),
      this.storage.get('visitor_id')
    ])
    .then(async([token, profile, access,visitorId]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.visitorId = visitorId;
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        this.currentUsername = this.userProfile.username;
        let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
        let convertDate = this.utilService.convertDate(transactionDate);
        this.currentTransDate = convertDate.dates + '-' + convertDate.months + '-' + convertDate.years;
        this.openDB();
        this.getSetupCashier();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });

     // WebSocket settings
     JSPM.JSPrintManager.auto_reconnect = true;
     JSPM.JSPrintManager.start();
     JSPM.JSPrintManager.WS.onStatusChanged = () => {
         if (this.jspmWSStatus()) {
             // get client installed printers
             JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
               this.printers = myPrinters;
               this.isDefaultPrinterSelected = true;
               (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
             });
         }
     };
  }

  ionViewDidEnter() {
    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }

  currentTime() {
    let intervalVar = setInterval(function () {
      let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
      let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
      this.currentTimezone = convertTime.times;
    }.bind(this),500);
  }
  getSetupCashier() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "cashier_id": this.userProfile.cashier_id ? this.userProfile.cashier_id : 0,
       // "counter_id": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
        //"setup_device_id": this.visitorId
      };

      this.posResellerService.getSetupCashier(options).subscribe(async(response) => {
        if(response.results) {
          this.cashierSetupList = response.results;
          this.currentCashierName = this.cashierSetupList.cashier_name;
          (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
          
          await this.getMstProduct();
          await this.getRewardTerm();
          await this.runWebSqlReseller();
        } else {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Tidak ditemukan kode Kassa pada device ini. Silahkan pasang kode Kassa terlebih dahulu.' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data setup Kassa! Silahkan refresh!' }).then(t => t.present());
      });
    });
  }

  async getMstProduct() {
    let options = {
      "counter_id": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
      "token": this.token
    };
    this.posResellerService.getProductReseller(options).subscribe((response) => {
      if (response.status.code == 200) {
        this.execQuery("DELETE FROM mst_products");
        this.mstProductListSearch = [];
        this.mstBarcodeList = [];
        this.mstProductList = [];
        for (let i = 0; i < response.results.length; i++) {
          // this.execQuery(`INSERT INTO mst_products VALUES ( '${String(response.results[i].id)}',
          //                                                   '${response.results[i].barcode}',
          //                                                   '${response.results[i].product_name}',
          //                                                   '${response.results[i].initial_name}',
          //                                                   '${response.results[i].price}')`);

          this.mstBarcodeList[response.results[i].barcode] = {
            id: String(response.results[i].id),
            barcode: response.results[i].barcode,
            product_name: response.results[i].product_name,
            initial_name: response.results[i].initial_name,
            price: response.results[i].price,
          };

          let id = String(response.results[i].id);
          let index: number = parseInt(id);
          if (Number.isNaN(index)) {
            //
          } else {
            this.mstProductList[index] = {
              id: String(response.results[i].id),
              barcode: response.results[i].barcode,
              product_name: response.results[i].product_name,
              initial_name: response.results[i].initial_name,
              price: response.results[i].price,
            };
          }
          this.mstProductListSearch.push({
            id: String(response.results[i].id),
            barcode: response.results[i].barcode,
            product_name: response.results[i].product_name,
            initial_name: response.results[i].initial_name,
            price: response.results[i].price,
          });
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getPaymentMethods();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Product! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getPaymentMethods() {
    this.posResellerService.getPaymentMethods({ "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          for (let i = 0; i < response.results.length; i++) {
            if(response.results[i].payment_method_type_id === '02') {
              this.creditBankList.push(response.results[i]);
            }
  
            if(response.results[i].payment_method_type_id === '01') {
              this.debitBankList.push(response.results[i]);
            }

            if(response.results[i].payment_method_type_id === '03') {//Voucher
              this.voucherMethodId = response.results[i].id;
            }

            if(response.results[i].payment_method_type_id === '04') {//Member Point
              this.memberPointMethodId = response.results[i].id;
            }

            if(response.results[i].payment_method_type_id === '05') {//Mata Uang Asing
              this.valasMethodId.push(response.results[i]);
            }

            if(response.results[i].payment_method_type_id === '06') {//Tunai
              this.cashMethodId = response.results[i].id;
            }

            if(response.results[i].payment_method_type_id === '07') {//Reward
              this.rewards.downline.reward.paymentMethodId = response.results[i].id;
            }

            if(response.results[i].payment_method_type_id === '07') {//Reward
              this.rewards.downline.reward.paymentMethodId = response.results[i].id;
            }

            if(response.results[i].payment_method_type_id === '08') {//Transfer
              this.transferMethodId = response.results[i].id;
            }
          }
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getForeignCurrencies();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Metode Pembayaran! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getForeignCurrencies() {
    this.posResellerService.getCurrencies({ "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          for (let i = 0; i < response.results.length; i++) {
            this.foreignCurrencyList.push(response.results[i]);
          }
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getTodayPromotion('first_load');
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Mata Uang Asing! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getTodayPromotion(action: any) {
    let options = {
      "counter_id": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
      "token": this.token
    };
    console.log('PROMOSI HARI INI');
    this.posResellerService.getPromotion(options).subscribe((response) => {
      console.log(response);
      if (response.status.code == 200) {
        if(response.results.promotion_detail_data.length > 0) {
          for (let i = 0; i < response.results.promotion_detail_data.length; i++) {
            this.promotionDetailList.push(response.results.promotion_detail_data[i]);
          }
        }

        if(response.results.stock_free_item.length > 0) {
          for (let i = 0; i < response.results.stock_free_item.length; i++) {
            this.stockFreeItem.push(response.results.stock_free_item[i]);
          }
        }

        if(response.results.promotion_free_item_data.length > 0) {
          for (let i = 0; i < response.results.promotion_free_item_data.length; i++) {
            this.promotionFreeItemList.push(response.results.promotion_free_item_data[i]);
          }
        }

        if(response.results.promotion_product_scan_data.length > 0) {
          for (let i = 0; i < response.results.promotion_product_scan_data.length; i++) {
            this.promotionProductScanData.push(response.results.promotion_product_scan_data[i]);
          }
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      if(action === 'first_load') { this.getEdcMachine(); }
      if(action === 'after_trans') { this.getVoucherList(); }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Promosi! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getEdcMachine() {
    this.posResellerService.getEdcMachine({ "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          for (let i = 0; i < response.results.length; i++) {
            this.edcMachineList.push(response.results[i]);
          }
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getVoucherList();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Mesin EDC! Silahkan refresh!' }).then(t => t.present());
    });
  }

  checkValidation() {
    
    this.utilService.loadingPresent('Cek Koneksi Server...')
    .then(() => {
        this.posResellerService.checkConnectionServer([]).subscribe((response: any) => {
          this.utilService.loadingDismiss();

          console.log('koneksi', response);
          
          let validate: any = false;
          if(this.productSales.length === 0) {
            this.toastCtrl.create({ duration: 2000, message: 'Silahkan scan product terlebih dahulu.' }).then(t => t.present());
            validate = true;
          }

          if(this.cashierSetupList === null) {
            this.toastCtrl.create({ duration: 2000, message: 'Tidak ditemukan kode Kassa pada device ini. Silahkan pasang kode Kassa terlebih dahulu.' }).then(t => t.present());
            validate = true;
          }
          
          if(this.customerCredit !== 0 && this.customerCredit !== null) {
            if(this.customerCreditId === undefined) {
              this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih nama Bank terlebih dahulu.' }).then(t => t.present());
              validate = true;
            }
            if(this.edcId === undefined) {
              this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih mesin EDC yang digunakan.' }).then(t => t.present());
              validate = true;
            }
          }
          
          if(this.customerDebit !== 0 && this.customerDebit !== null) {
            if(this.customerDebitId === undefined) {
              this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih nama Bank terlebih dahulu.' }).then(t => t.present());
              validate = true;
            }
            if(this.edcId === undefined) {
              this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih mesin EDC yang digunakan.' }).then(t => t.present());
              validate = true;
            }
          }
          
          if(this.totalChange < 0) {
            this.toastCtrl.create({ duration: 2000, message: 'Total pembayaran masih belum cukup!' }).then(t => t.present());
            validate = true;
          }
          if(this.rewards.downline.resellerId == null) {
            this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih reseller' }).then(t => t.present());
            validate = true;
          }
          if(validate === false) {
            this.saveTransactionBundle();
          }

        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
    });
    
  }

  saveTransactionBundle() {
    this.buttonDisabled = true;
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
      let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
      let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
      let convertDate = this.utilService.convertDate(transactionDate);
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;
      let cashierId = this.cashierSetupList ? this.cashierSetupList.id : 0;

      let arrProduct: any[] = [];
      let arrQty: any[] = [];
      let arrPrice: any[] = [];
      let arrNet: any[] = [];
      let arrPercentDisc: any[] = [];
      let arrDisc: any[] = [];
      let arrFreeQty: any[] = [];

      let arrPaymentMethodId: any[] = [];
      let arrEdcId: any[] = [];
      let arrPointSP: any[] = [];
      let arrPaymentValue: any[] = [];
      let arrVoucherCode: any[] = [];
      let arrExchangeRate: any[] = [];
      let arrPaymentList: any[] = [];

      let dateOneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      let convertDate2 = this.utilService.convertDate(dateOneYearFromNow);
      let expiredDate = convertDate2.years + '-' + convertDate2.months + '-' + convertDate2.dates;
      let paymentRemains = this.totalPayment - this.memberPointValue;
      let pointArr: any[] = [];
      let arrCustomerId: any[] = [];
      let arrPointMutationTypeId: any[] = [];
      let arrPointMP: any[] = [];
      let arrPointValue: any[] = [];
      let arrTransDate: any[] = [];
      let arrExpiredDate: any[] = [];
      let arrStatusMP: any[] = [];
      let arrCreatedBy: any[] = [];

      let arrVoucherId: any[] = [];
      let arrStatusMV: any[] = [];
      let arrUpdatedBy: any[] = [];

      let arrStockMutationTypeId: any[] = [];
      let arrProductId: any[] = [];
      let arrQtySM: any[] = [];
      let arrValue: any[] = [];
      let arrStockMove: any[] = [];
      let arrTransDateSM: any[] = [];

      // FI = Free Item
      let arrFIStockMutationTypeId: any[] = [];
      let arrFIWarehouseId: any[] = [];
      let arrFIProductId: any[] = [];
      let arrFIQty: any[] = [];
      let arrFIValue: any[] = [];
      let arrFIStockMove: any[] = [];
      let arrFITransDateSM: any[] = [];

      let arrPromotionFreeItemId: any[] = [];
      let arrPromotionDetailId: any[] = [];
      let arrFreeItemUsed: any[] = [];
      
      //sales_transaction_details start
      for(let x = 0; x < this.productSales.length; x++) {
        arrProduct[x] = this.productSales[x].id;
        arrQty[x] = this.productSales[x].qty;
        arrPrice[x] = this.productSales[x].typePromo === 'FREE ITEM (ITEM)' || this.productSales[x].typePromo === 'FREE ITEM (NOMINAL)' ? this.productSales[x].priceAfterPromo : this.productSales[x].price;
        arrPercentDisc[x] = this.productSales[x].valPromoPercentage;
        
        arrFreeQty[x] = this.productSales[x].qtyFree;
        let countPromoDiff:number =0;
        if(this.productSales[x].valPromoNominal!=0){
          countPromoDiff = this.productSales[x].valPromoNominal;
        }
        else if(this.productSales[x].valPromoPercentage!=0){
          countPromoDiff = this.productSales[x].maxDiscount;
        }
        arrDisc[x] = countPromoDiff;
        arrNet[x] = this.productSales[x].nett;
      }
      //sales_transaction_details end
      
      //sales_transaction_payments start
      if(this.customerCash !== 0) {
        let data = {
          'payment_method_id': this.cashMethodId,
          'edc_id': 0,
          'point': 0,
          'payment_value': this.customerCash,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.customerTransfer !== 0) {
        let data = {
          'payment_method_id': this.transferMethodId,
          'edc_id': 0,
          'point': 0,
          'payment_value': this.customerTransfer,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.customerCredit !== 0) {
        let data = {
          'payment_method_id': this.customerCreditId,
          'edc_id': this.edcId,
          'point': 0,
          'payment_value': this.customerCredit,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.customerDebit !== 0) {
        let data = {
          'payment_method_id': this.customerDebitId,
          'edc_id': this.edcId,
          'point': 0,
          'payment_value': this.customerDebit,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.voucherValue !== 0) {
        for(let x = 0; x < this.voucherUseList.length; x++) {
          let data = {
            'payment_method_id': this.voucherMethodId,
            'edc_id': 0,
            'point': 0,
            'payment_value': this.voucherUseList[x].nominal,
            'voucher_code': this.voucherUseList[x].voucher_code,
            'exchange_rate': 0
          };
          arrPaymentList.push(data);
        }
      }

      if(this.memberPointValue !== 0) {
        let data = {
          'payment_method_id': this.memberPointMethodId,
          'edc_id': 0,
          'point': 0,
          'payment_value': this.memberPointValue,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.otherPayment === true) {
        if(this.valueUSD !== 0) {
          let method: any = this.valasMethodId.find(x => x.payment_name === 'USD');
          let data = {
            'payment_method_id': method ? method.id : 0,
            'edc_id': 0,
            'point': 0,
            'payment_value': this.valueUSD,
            'voucher_code': null,
            'exchange_rate': this.exchangeRateUSD
          };
          arrPaymentList.push(data);
        }

        if(this.valueCNY !== 0) {
          let method: any = this.valasMethodId.find(x => x.payment_name === 'CNY');
          let data = {
            'payment_method_id': method ? method.id : 0,
            'edc_id': 0,
            'point': 0,
            'payment_value': this.valueCNY,
            'voucher_code': null,
            'exchange_rate': this.exchangeRateCNY
          };
          arrPaymentList.push(data);
        }

        if(this.valueWeChat !== 0) {
          let method: any = this.valasMethodId.find(x => x.payment_name === 'Wechat');
          let data = {
            'payment_method_id': method ? method.id : 0,
            'edc_id': 0,
            'point': 0,
            'payment_value': this.valueWeChat,
            'voucher_code': null,
            'exchange_rate': this.exchangeRateWeChat
          };
          arrPaymentList.push(data);
        }
      }

      for(let x = 0; x < arrPaymentList.length; x++) {
        arrPaymentMethodId[x] = arrPaymentList[x].payment_method_id;
        arrEdcId[x] = arrPaymentList[x].edc_id;
        arrPointSP[x] = arrPaymentList[x].point;
        arrPaymentValue[x] = arrPaymentList[x].payment_value;
        arrVoucherCode[x] = arrPaymentList[x].voucher_code;
        arrExchangeRate[x] = arrPaymentList[x].exchange_rate;
      }
      //sales_transaction_payments end

      //point_mutations start
      /*if(this.memberPhone !== '') {
        if(paymentRemains !== 0 && paymentRemains >= 10000) {
          let countPoint: any = paymentRemains / 10000;
          this.totalPointAdd = parseInt(countPoint);
          let value: any = this.totalPointAdd * 100;
          pointArr.push({
            'pointMutationType': 'I',
            'point': this.totalPointAdd,
            'pointValue': value
          });
        }
  
        if(this.pointMutationType === 'O') {
          pointArr.push({
            'pointMutationType': 'O',
            'point': this.memberPointUsed,
            'pointValue': this.memberPointValue
          });
        }
        
        for(let x = 0; x < pointArr.length; x++) {
          arrCustomerId[x] = this.memberId;
          arrPointMutationTypeId[x] = pointArr[x].pointMutationType;
          arrPointMP[x] = pointArr[x].point;
          arrPointValue[x] = pointArr[x].pointValue;
          arrTransDate[x] = transDate;
          arrExpiredDate[x] = expiredDate;
          arrStatusMP[x] = 1;
          arrCreatedBy[x] = this.userProfile.username;
        }
      }*/
      //point_mutations end

      //member_point start
      let pointTotal: any = this.totalPointAdd;
      let pointTotalValue: any = pointTotal * 100;
      let pointUsed: any = this.memberPointUsed;
      let pointUsedValue: any = pointUsed * 100;
      let pointRemains: any = 0;
      
      if(this.totalPointAdd > 0) {
        pointRemains = pointRemains + this.totalPointAdd;
      }
      if(this.memberPointUsed > 0) {
        pointRemains = pointRemains - this.memberPointUsed;
      }

      let pointRemainsValue: any = pointRemains * 100;
      //member_point end

      //mst_voucher start
      if(this.voucherValue !== 0) {
        if(this.voucherUseList.length > 0) {
          for(let i = 0; i < this.voucherUseList.length; i++) {
            arrVoucherId[i] = this.voucherUseList[i].id;
            arrStatusMV[i] = '1';
            arrUpdatedBy[i] = this.userProfile.username;
          }
        }
      }
      //mst_voucher end

      //stock_mutations start
      for(let x = 0; x < this.productSales.length; x++) {
        if(parseInt(this.productSales[x].qty) >0 && this.productSales[x].typePromo !== 'FREE ITEM (ITEM)' && this.productSales[x].typePromo !== 'FREE ITEM (NOMINAL)') {
          arrStockMutationTypeId[x] = 'R'; // R = RETAIL (PENJUALAN)
          arrProductId[x] = this.productSales[x].id;
          arrQtySM[x] = this.productSales[x].qty;
          arrValue[x] = 0;
          arrStockMove[x] = 'O';
          arrTransDateSM[x] = transDate;
        }

        if(this.productSales[x].qtyFree > 0) {
          arrFIStockMutationTypeId.push('PB'); // PB = PRODUCT BONUS
          arrFIWarehouseId.push(this.productSales[x].warehouseIdPromo);
          arrFIProductId.push(this.productSales[x].id);
          arrFIQty.push(this.productSales[x].qtyFree);
          arrFIValue.push(0);
          arrFIStockMove.push('O');
          arrFITransDateSM.push(transDate);

          arrPromotionFreeItemId.push(this.productSales[x].promotionFreeItemId);
          arrPromotionDetailId.push(this.productSales[x].promotionDetailId);
          arrFreeItemUsed.push(this.productSales[x].qtyFree);
        }
      }
      //stock_mutations end

      const salesTransactionBundleReseller = new SalesTransactionBundleReseller();
      salesTransactionBundleReseller.salesTransaction.cashier_id = cashierId;
      salesTransactionBundleReseller.salesTransaction.user_id = this.userProfile.id;
      salesTransactionBundleReseller.salesTransaction.counter_id = this.userProfile.counter_id_pos;
      salesTransactionBundleReseller.salesTransaction.customer_id = this.memberId ? this.memberId : 0;
      salesTransactionBundleReseller.salesTransaction.trans_date = transDate;
      salesTransactionBundleReseller.salesTransaction.trans_time = transTime;
      salesTransactionBundleReseller.salesTransaction.total_item = this.totalItem;
      salesTransactionBundleReseller.salesTransaction.total_value = this.totalPayment;
      salesTransactionBundleReseller.salesTransaction.total_payment = this.totalCustomerPay;
      salesTransactionBundleReseller.salesTransaction.discount = this.transactionDiscount ? this.transactionDiscount : 0;
      salesTransactionBundleReseller.salesTransaction.change = this.totalChange;
      salesTransactionBundleReseller.salesTransaction.cash = this.customerCash ? this.customerCash : 0;
      salesTransactionBundleReseller.salesTransaction.transfer = this.customerTransfer ? this.customerTransfer : 0;
      salesTransactionBundleReseller.salesTransaction.credit_card = this.customerCredit ? this.customerCredit : 0;
      salesTransactionBundleReseller.salesTransaction.debit_card = this.customerDebit ? this.customerDebit : 0;
      salesTransactionBundleReseller.salesTransaction.voucher = this.voucherValue;
      salesTransactionBundleReseller.salesTransaction.member_point = this.memberPointValue;
      salesTransactionBundleReseller.salesTransaction.foreign_currency_1 = this.valueUSD;
      salesTransactionBundleReseller.salesTransaction.exchange_rate_1 = this.exchangeRateUSD;
      salesTransactionBundleReseller.salesTransaction.currency_1 = 'USD';
      salesTransactionBundleReseller.salesTransaction.foreign_currency_2 = this.valueCNY;
      salesTransactionBundleReseller.salesTransaction.exchange_rate_2 = this.exchangeRateCNY;
      salesTransactionBundleReseller.salesTransaction.currency_2 = 'CNY';
      salesTransactionBundleReseller.salesTransaction.foreign_currency_3 = this.valueWeChat;
      salesTransactionBundleReseller.salesTransaction.exchange_rate_3 = this.exchangeRateWeChat;
      salesTransactionBundleReseller.salesTransaction.currency_3 = 'Wechat';
      salesTransactionBundleReseller.salesTransaction.transaction_type = '1';
      salesTransactionBundleReseller.salesTransaction.status = '1';
      salesTransactionBundleReseller.salesTransaction.created_by = this.userProfile.username;

      salesTransactionBundleReseller.salesTransactionDetail.product_id = arrProduct;
      salesTransactionBundleReseller.salesTransactionDetail.qty = arrQty;
      salesTransactionBundleReseller.salesTransactionDetail.price = arrPrice;
      salesTransactionBundleReseller.salesTransactionDetail.percent_disc_1 = arrPercentDisc;
      salesTransactionBundleReseller.salesTransactionDetail.disc_1 = arrDisc;
      salesTransactionBundleReseller.salesTransactionDetail.free_qty = arrFreeQty;
      salesTransactionBundleReseller.salesTransactionDetail.net = arrNet;

      salesTransactionBundleReseller.salesTransactionPayments.payment_method_id = arrPaymentMethodId;
      salesTransactionBundleReseller.salesTransactionPayments.edc_id = arrEdcId;
      salesTransactionBundleReseller.salesTransactionPayments.point = arrPointSP;
      salesTransactionBundleReseller.salesTransactionPayments.payment_value = arrPaymentValue;
      salesTransactionBundleReseller.salesTransactionPayments.voucher_code = arrVoucherCode;
      salesTransactionBundleReseller.salesTransactionPayments.exchange_rate = arrExchangeRate;

      salesTransactionBundleReseller.pointMutation.customer_id = arrCustomerId;
      salesTransactionBundleReseller.pointMutation.point_mutation_type_id = arrPointMutationTypeId;
      salesTransactionBundleReseller.pointMutation.point = arrPointMP;
      salesTransactionBundleReseller.pointMutation.point_value = arrPointValue;
      salesTransactionBundleReseller.pointMutation.trans_date = arrTransDate;
      salesTransactionBundleReseller.pointMutation.expired_date = arrExpiredDate;
      salesTransactionBundleReseller.pointMutation.status = arrStatusMP;
      salesTransactionBundleReseller.pointMutation.created_by = arrCreatedBy;

      salesTransactionBundleReseller.memberPoint.customer_id = this.memberId;
      salesTransactionBundleReseller.memberPoint.point_total = pointTotal;
      salesTransactionBundleReseller.memberPoint.point_total_value = pointTotalValue;
      salesTransactionBundleReseller.memberPoint.point_used = pointUsed;
      salesTransactionBundleReseller.memberPoint.point_used_value = pointUsedValue;
      salesTransactionBundleReseller.memberPoint.point_remains = pointRemains;
      salesTransactionBundleReseller.memberPoint.point_remains_value = pointRemainsValue;
      salesTransactionBundleReseller.memberPoint.updated_by = this.userProfile.username;

      salesTransactionBundleReseller.mstVoucherDetail.id = arrVoucherId;
      salesTransactionBundleReseller.mstVoucherDetail.status = arrStatusMV;
      salesTransactionBundleReseller.mstVoucherDetail.updated_by = arrUpdatedBy;

      salesTransactionBundleReseller.stockMutation.stock_mutation_type_id = arrStockMutationTypeId;
      salesTransactionBundleReseller.stockMutation.product_id = arrProductId;
      salesTransactionBundleReseller.stockMutation.qty = arrQty;
      salesTransactionBundleReseller.stockMutation.value = arrValue;
      salesTransactionBundleReseller.stockMutation.stock_move = arrStockMove;
      salesTransactionBundleReseller.stockMutation.trans_date = arrTransDateSM;
      salesTransactionBundleReseller.reward = this.rewards;//reseller_code_sign

      salesTransactionBundleReseller.freePromoMutation.stock_mutation_type_id = arrFIStockMutationTypeId;
      salesTransactionBundleReseller.freePromoMutation.warehouse_id = arrFIWarehouseId;
      salesTransactionBundleReseller.freePromoMutation.product_id = arrFIProductId;
      salesTransactionBundleReseller.freePromoMutation.qty = arrFIQty;
      salesTransactionBundleReseller.freePromoMutation.value = arrFIValue;
      salesTransactionBundleReseller.freePromoMutation.stock_move = arrFIStockMove;
      salesTransactionBundleReseller.freePromoMutation.trans_date = arrFITransDateSM;

      salesTransactionBundleReseller.mstPromotionDetail.free_item_id = arrPromotionFreeItemId;
      salesTransactionBundleReseller.mstPromotionDetail.id = arrPromotionDetailId;
      salesTransactionBundleReseller.mstPromotionDetail.free_item_used = arrFreeItemUsed;

      this.posResellerService.addSalesTransactionBundle(salesTransactionBundleReseller).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          let ppnVal: any = this.totalPayment * 0.1;
          this.ppn = parseInt(ppnVal);
          this.receiptNo = response.results.data.receipt_no;
          this.printReceipt();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
        this.buttonDisabled = false;
      }, () => {
        this.buttonDisabled = false;
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  getVoucherList() {
    this.posResellerService.getVoucher({ "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          /* dihilangkan 02 november 2023
          let tableName = 'mst_voucher';
          let columnList = '(id, voucher_code, nominal, currency, expired_date, status, created_by, updated_by, created_at, updated_at)';
          
          this.createTable(tableName, columnList).then((res) => {
            for (let i = 0; i < response.results.length; i++) {

              let insertQuery = 'INSERT INTO ' + tableName + columnList + ' VALUES (' +
              response.results[i].id + ', "' + response.results[i].voucher_code + '", ' +
              response.results[i].nominal + ', "' + response.results[i].currency +'", "' +
              response.results[i].expired_date + '", ' + response.results[i].status + ', "' +
              response.results[i].created_by + '", "' + response.results[i].updated_by + '", "' +
              response.results[i].created_at + '", "' + response.results[i].updated_at + '")';

              let selectQuery = 'SELECT * FROM ' + tableName + ' WHERE id = ' + response.results[i].id;
              let deleteQuery = 'DELETE FROM ' + tableName + ' WHERE id = ' + response.results[i].id;

              this.db.transaction((tx) => {
                tx.executeSql(selectQuery, [],
                (tx, result) => {
                  if(result.rows.length === 0) {
                    this.execQuery(insertQuery);
                  } else {
                    this.execQuery(deleteQuery);
                    this.execQuery(insertQuery);
                  }
                }, (error) => {
                  console.log(error);
                });
              });
            }
          }, (err) => {
            console.log(err);
          });
          */
        }
      }

      this.utilService.loadingDismiss();
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Voucher! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getMemberList() {
    this.posResellerService.getMember({ "token": this.token }).subscribe((response) => {
      this.utilService.loadingDismiss();
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          /* dihilangkan 02 november 2023
          let tableName = 'customers';
          let columnList = '(id, customer_type_id, card_id, referral_code, customer_name, identity_number, phone, point_remains, point_remains_value, created_by, updated_by, created_at, updated_at)';
          
          this.createTable(tableName, columnList).then((res) => {
            this.execQuery("DELETE FROM  customers;");
            for (let i = 0; i < response.results.length; i++) {

              let insertQuery = 'INSERT INTO ' + tableName + columnList + ' VALUES (' +
              response.results[i].id + ', ' + response.results[i].customer_type_id + ', "' + 
              response.results[i].card_id + '", "' + response.results[i].referral_code + '", "' + 
              response.results[i].customer_name + '", "' +
              response.results[i].identity_number + '", "' + response.results[i].phone + '", ' +
              response.results[i].point_remains + ', ' + response.results[i].point_remains_value + ', "' +
              response.results[i].created_by + '", "' + response.results[i].updated_by + '", "' +
              response.results[i].created_at + '", "' + response.results[i].updated_at + '")';

              let selectQuery = 'SELECT * FROM ' + tableName + ' WHERE id = ' + response.results[i].id;
              let deleteQuery = 'DELETE FROM ' + tableName + ' WHERE id = ' + response.results[i].id;

              this.db.transaction((tx) => {
                tx.executeSql(selectQuery, [],
                (tx, result) => {
                  if(result.rows.length === 0) {
                    this.execQuery(insertQuery);
                  } else {
                    this.execQuery(deleteQuery);
                    this.execQuery(insertQuery);
                  }
                }, (error) => {
                  console.log(error);
                });
              });
            }
          }, (err) => {
            console.log(err);
          });*/
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Member! Silahkan refresh!' }).then(t => t.present());
    });
  }

  async showAlertBarcodeNotValid(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
          }
        }
      ]
    });

    await alert.present();
  }

  async openVoucher() {
    const modal = await this.modalController.create({
      component: VoucherComponent,
      componentProps: {
        'totalTransaction': this.totalPayment,
        'voucherUseList': Array.isArray(this.voucherUseList) ? this.voucherUseList : [],
        'token': this.token
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      this.voucherUseList = modelData.data;
      if (this.voucherUseList.length > 0) {
        this.voucherValue = 0;
        for(let x = 0; x < this.voucherUseList.length; x++) {
          this.voucherValue = this.voucherValue + parseInt(this.voucherUseList[x].nominal);
        }

        if(this.voucherValue >= this.totalPayment) {
          this.voucherValue = this.totalPayment;
        }

        this.getChangeNominal();
      }
    });

    return await modal.present();
  }

   //reseller_code_sign
   async openMemberReseller() {
    const modal = await this.modalController.create({
      component: MemberResellerComponent,
      componentProps: {
        'totalTransaction': this.totalPayment,
        'token': this.token,
        'customer': []
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      console.log('modelData', modelData);
      this.clearReward();
      if(modelData.data.length > 0) { 
        this.memberId = modelData.data[0].id;
        this.memberPhone = modelData.data[0].phone;
        this.resellerName = modelData.data[0].customer_name;
        this.resellerCode = modelData.data[0].referral_code;
        this.rewards.downline.resellerId = modelData.data[0].id;
        this.rewards.downline.pointMutation.customerId = modelData.data[0].id;
        this.rewards.downline.reward.sisa = modelData.data[0].sisaReward;
        this.rewards.downline.reward.readonlyPakai = modelData.data[0].readonlyPakaiReward;
        this.rewards.upline.resellerId = modelData.data[0].uplineId;
        this.setReward();
      }
    });
    return await modal.present();
  }

  //reseller_code_sign
  setReward() {
    if(this.rewards.downline.resellerId !== null) {
      //downline dapat point 
      if(this.totalPayment > this.rewards.downline.terms.minBelanjaPoint.term) {
        let sisaBagi = this.totalPayment % this.rewards.downline.terms.minBelanjaPoint.term;
        console.log('this.totalPayment',this.totalPayment);
        console.log('this.rewards.downline.terms.minBelanjaPoint.term',this.rewards.downline.terms.minBelanjaPoint.term);
        console.log('sisaBagi',sisaBagi);
        let nilaiPas = this.totalPayment - sisaBagi;
        let totalPoint = (nilaiPas / this.rewards.downline.terms.minBelanjaPoint.term) * this.rewards.downline.terms.minBelanjaPoint.reward;
        this.rewards.downline.pointMutation.point = totalPoint;
        this.totalPointAdd = totalPoint;
      }

      // upline dapat reward
      if(this.rewards.upline.resellerId !== null) {
        /* dihilangkan 02 november 2023
        let whereDownlineId = this.rewards.downline.resellerId;
        let whereMinBelanja500rb = this.rewards.upline.terms.minBelanja500rb.term;
        this.db.transaction((tx) => {
          tx.executeSql(`SELECT * FROM reward_mutations WHERE downline_id = '${whereDownlineId}' AND total_sales >= ${whereMinBelanja500rb}`, [],
            (tx, result) => {
              if(result.rows.length > 0) {
                //reward 2.5 %
                let reward = this.totalPayment * (this.rewards.upline.terms.reward2setengahPersen.reward / 100);
                this.rewards.upline.rewardMutation.push({
                  uplineId: this.rewards.upline.resellerId,
                  downlineId: this.rewards.downline.resellerId,
                  rewardMutationTypeId: 'I',
                  totalSales: this.totalPayment,
                  rewardValue: reward,
                  rewardPercent: this.rewards.upline.terms.reward2setengahPersen.reward
                });
              }else {
                if(this.totalPayment >= this.rewards.upline.terms.minBelanja500rb.term) {
                  //reward 50rb
                  this.rewards.upline.rewardMutation.push({
                    uplineId: this.rewards.upline.resellerId,
                    downlineId: this.rewards.downline.resellerId,
                    rewardMutationTypeId: 'I',
                    totalSales: this.rewards.upline.terms.minBelanja500rb.term,
                    rewardValue: this.rewards.upline.terms.minBelanja500rb.reward,
                    rewardPercent: 0
                  });

                  //sisa dari 500rb reward 2.5%
                  if(this.totalPayment > this.rewards.upline.terms.minBelanja500rb.term) {
                    let sisaDari500rb = this.totalPayment - this.rewards.upline.terms.minBelanja500rb.term;
                    let rewardDariSisa = sisaDari500rb * (this.rewards.upline.terms.reward2setengahPersen.reward / 100);
                    this.rewards.upline.rewardMutation.push({
                      uplineId: this.rewards.upline.resellerId,
                      downlineId: this.rewards.downline.resellerId,
                      rewardMutationTypeId: 'I',
                      totalSales: sisaDari500rb,
                      rewardValue: rewardDariSisa,
                      rewardPercent: this.rewards.upline.terms.reward2setengahPersen.reward
                    });
                  }
                }
              }
          }, (error) => {
            console.log('error', error)
          });
        });
        */
      }
    }

    console.log('this.rewards',this.rewards);
  }

  //reseller_code_sign
  async clearReward() {
    this.memberId = null;
    this.memberPhone = "";
    this.rewards.downline.resellerId = null;
    this.rewards.downline.pointMutation.customerId = null;
    this.rewards.downline.pointMutation.point = 0;
    this.rewards.downline.reward.sisa = 0;
    this.rewards.downline.reward.pakai = 0;
    this.rewards.downline.reward.readonlyPakai = true;
    this.rewards.upline.resellerId = null;
    this.rewards.upline.rewardMutation = [];
  }
  
  async openFreeItem(checkPromoFreeItem: any,  freeItemList: any[], index:number) {
    let checkFreeItemUsed = this.arrayFreeItemUsed.filter(x => parseInt(x.promo_id_detail) === parseInt(freeItemList[0].promotion_detail_id));
    let promoId: any = this.promotionDetailList.find(x => x.id ==  checkFreeItemUsed[0]['promo_id_detail']);
    let warehouseId = 0;
    let productScan = 0;
    if(promoId){
      warehouseId = promoId.warehouse_id;
    }
    if(index!=-1){
      productScan = this.productSales[index]['id'];
    }
    const modal = await this.modalController.create({
      component: PromotionFreeItemComponent,
      componentProps: {
        'freeItemList': freeItemList,
        'arrayFreeItemUsed': checkFreeItemUsed,
        'warehouseId':warehouseId,
        'productScan':productScan
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData.data !== '') {
        console.log(modelData);
        for(let i=0;i<modelData.data.freeItemList.length;i++){
          let idPromotionFreeItem: any = '';
          let idPromotionFreeItemByNominalId :any='';
          let productId: any = modelData.data.freeItemList[i].product_id;
          let promotionId = modelData.data.freeItemList[i].promotion_detail_id;
          let idPromotionDetail =modelData.data.freeItemList[i].promotion_detail_id;
          let freeQty = modelData.data.freeItemList[i].used;
          if(checkPromoFreeItem.promotion_type_name=='FREE ITEM (NOMINAL)' && (checkPromoFreeItem.promo_based_on==1 || (parseInt(checkPromoFreeItem.promo_based_on)==2 && checkPromoFreeItem.product_id!='')) )
          {           
            if(index == -1){ // promo by total belanja
              let checkRemainingByTotal = this.arrayFreeItemUsed.find(x => x.product_id === 0  && parseInt(x.promo_id_detail) === parseInt(promotionId));
              if(checkRemainingByTotal){              
                let idxByTotal = this.arrayFreeItemUsed.indexOf(checkRemainingByTotal);                
                this.countFreeItem = this.arrayFreeItemUsed[idxByTotal].free_item_remaining;             
                if (this.arrayFreeItemUsed[idxByTotal].free_item_remaining==0){
                  this.message ='';
                }
              }
            }
          }

          let checkFreeItemUsed = [];
          if(index == -1){
            checkFreeItemUsed= this.arrayFreeItemUsed.find(x => x.product_id === 0  && parseInt(x.promo_id_detail) === parseInt(promotionId));
            idPromotionFreeItemByNominalId = modelData.data.freeItemList[i].id;
          }
          else{            
            checkFreeItemUsed = this.arrayFreeItemUsed.find(x => x.product_id === productScan && parseInt(x.promo_id_detail) === parseInt(promotionId));
            if(checkFreeItemUsed){              
              let idxProduct = this.arrayFreeItemUsed.indexOf(checkFreeItemUsed);              
              if (modelData.data.arrayFreeItemUsed[idxProduct].free_item_remaining==0){
                this.productSales[index]['getFree'] = 0;
              }
            }
            idPromotionFreeItem = modelData.data.freeItemList[i].id;
          }
          if (this.mstProductList[parseInt(productId)] !== undefined ) {
            let checkProductSales = this.productSales.find(x => x.id === productId);
            if(checkProductSales) {
              let indexProductSales = this.productSales.indexOf(checkProductSales);
              let idx = -1;
              if(index > -1) { 
                if(idPromotionFreeItem!=''){
                  this.productSales[indexProductSales]['promotionFreeItemId'] = idPromotionFreeItem; 
                }
                if(idPromotionFreeItemByNominalId!=''){
                  this.productSales[indexProductSales]['promotionFreeItemByNominalId'] = idPromotionFreeItemByNominalId; 
                }
              }
              if(checkFreeItemUsed){
                idx = this.arrayFreeItemUsed.indexOf(checkFreeItemUsed);
                if(index != -1){
           //     this.arrayFreeItemUsed[idx]['free_item_used'] =  modelData.data.arrayFreeItemUsed[idx].free_item_used;  
             //   this.arrayFreeItemUsed[idx]['free_item_remaining'] = modelData.data.arrayFreeItemUsed[idx].free_item_remaining;                
                }
              }

              let arrayListPromo ={
                'promotion_id' : modelData.data.freeItemList[i].id,
                'product_scan' :this.arrayFreeItemUsed[idx].product_id,
                'qty' : freeQty
              };
              let checkArrayListPromo = this.productSales[indexProductSales]['arrayPromoFreeItem'].find(x => x.promotion_id === modelData.data.freeItemList[i].id &&  x.product_scan === modelData.data.freeItemList[i].product_scan);
              if(checkArrayListPromo) {
                let idxPromo = this.productSales[indexProductSales]['arrayPromoFreeItem'].indexOf(checkArrayListPromo);
                this.productSales[indexProductSales]['arrayPromoFreeItem'][idxPromo]=arrayListPromo;
              }
              else{
                this.productSales[indexProductSales]['arrayPromoFreeItem'].push(arrayListPromo);
              }
              let sumFree = 0;
              console.log(this.productSales[indexProductSales])
              for(let i=0;i<this.productSales[indexProductSales]['arrayPromoFreeItem'].length;i++){
                sumFree+=this.productSales[indexProductSales]['arrayPromoFreeItem'][i]['qty'];
              }
              if(sumFree>0){
                this.productSales[indexProductSales]['qtyFree'] = sumFree;
              }

              let array_item= {
                'product_id':productId,
                'qty_free':freeQty
              };

              let checkArrayFreeItem= this.arrayFreeItemUsed[idx]['array_item'].find(x => parseInt(x.product_id) === parseInt(productId));
              if(checkArrayFreeItem){
                let idxFree = this.arrayFreeItemUsed[idx]['array_item'].indexOf(checkArrayFreeItem);
                this.arrayFreeItemUsed[idx]['array_item'][idxFree]=array_item;
              }
              else{
                this.arrayFreeItemUsed[idx]['array_item'].push(array_item);
              }
              if(this.productSales[indexProductSales]['qtyFree']==0 && this.productSales[indexProductSales]['qty']==0){
               // this.productSales.splice(indexProductSales, 1);
              }
            }
            else {
              if(parseInt(freeQty) > 0){
                if(checkFreeItemUsed){
                  let idx = this.arrayFreeItemUsed.indexOf(checkFreeItemUsed);
                  if(index!=-1){
                    //this.arrayFreeItemUsed[idx]['free_item_used'] =  modelData.data.arrayFreeItemUsed[index].free_item_used;  
                    //this.arrayFreeItemUsed[idx]['free_item_remaining'] = modelData.data.arrayFreeItemUsed[index].free_item_remaining;
                  }
                  let array_item= {
                    'product_id':productId,
                    'qty_free':freeQty
                  };
                  let checkArrayFreeItem= this.arrayFreeItemUsed[idx]['array_item'].find(x => parseInt(x.product_id) === parseInt(productId));
                  if(checkArrayFreeItem){
                    let idxFree = this.arrayFreeItemUsed[idx]['array_item'].indexOf(checkArrayFreeItem);
                    this.arrayFreeItemUsed[idx]['array_item'][idxFree]=array_item;
                  }
                  else{
                    this.arrayFreeItemUsed[idx]['array_item'].push(array_item);
                  }
                }

                let totalFreeItemExist: number = 0;
                let checkProductSalesFree = this.productSales.filter(x => x.promotionDetailId === idPromotionDetail && x.typePromo === checkPromoFreeItem.promotion_type_name);
                if(checkProductSalesFree.length > 0) {
                  for(let x = 0; x < checkProductSalesFree.length; x++) {
                    totalFreeItemExist = totalFreeItemExist + checkProductSalesFree[x].qtyFree;
                  }
                }
                let arrayListPromo =[];
                arrayListPromo.push({
                  'promotion_id' : modelData.data.freeItemList[i].id,
                  'product_scan' : modelData.data.freeItemList[i].product_scan,
                  'qty' : freeQty
                });
                this.productSales.push({
                  barcode_view: parseInt(this.mstProductList[parseInt(productId)]['id']),
                  id: this.mstProductList[parseInt(productId)]['id'],
                  barcode: this.mstProductList[parseInt(productId)]['barcode'],
                  name: this.mstProductList[parseInt(productId)]['product_name'],
                  initialName: this.mstProductList[parseInt(productId)]['initial_name'],
                  qty: 0,
                  price: 0,
                  priceAfterPromo: this.mstProductList[parseInt(productId)]['price'],
                  nett: 0,
                  typePromo: checkPromoFreeItem.promotion_type_name,
                  valPromoPercentage: 0,
                  valPromoNominal: 0,
                  qtyFree: freeQty,
                  multipleFlag: checkPromoFreeItem ? checkPromoFreeItem.multiple_flag : 0,
                  warehouseIdPromo: warehouseId,
                  promotionDetailId: idPromotionDetail,
                  promotionFreeItemId: idPromotionFreeItem,
                  promotionFreeItemByNominalId :idPromotionFreeItemByNominalId,
                  getFree : 0,
                  promo : '',
                  arrayPromoFreeItem :arrayListPromo,
                  maxDiscount : 0 // ini untuk max discount persentase
                });
              }
            }
          }
        }
        this.totalItem = 0;
        for (let i=0;i < this.productSales.length;i++) {
          this.totalItem += (parseInt(this.productSales[i].qty) + parseInt(this.productSales[i].qtyFree));
        }        
      }
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    });

    return await modal.present();
  }

  //Start => Local Function

  scrollDownEnd() {
    setTimeout(()=>{   
        var elem = document.getElementById('item');
        elem.scrollTop = elem.scrollHeight;
    }, 50);
  }

  jspmWSStatus() { // Check JSPM WebSocket status
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
        alert('JSPM has blocked this website!');
        return false;
    }
  }
  
  printReceipt() {
    let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
    let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
    let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
    let convertDate = this.utilService.convertDate(transactionDate);

    let monthName: any = this.monthList[(parseInt(convertDate.months)-1)];
    let transDate = convertDate.dates + '-' + monthName + '-' + convertDate.years;
    let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;
    this.transactionTime = transTime;

    let totalPromo: number = 0;
    let cashierName: string = this.userProfile.name;

    //Create a ClientPrintJob
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += '---------------------------------';
    cmds += 'NO STRUK : ' + this.receiptNo;
    cmds += newLine;
    cmds += 'TANGGAL  : ' + transDate + ' ' + transTime;
    cmds += newLine;
    cmds += 'KASIR    : ' + cashierName.toUpperCase();
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    //Start List Barang Belanja
    for(let i = 0; i < this.productSales.length; i++) {
      if(this.productSales[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSales[i].typePromo !== 'FREE ITEM (NOMINAL)') {
        let priceProduct: number = parseInt(this.productSales[i].price);
        let qtyBuy: number = parseInt(this.productSales[i].qty);
        let nettProduct: number = priceProduct * qtyBuy;

        let qtyFree: number = parseInt(this.productSales[i].qtyFree);
        let nettFree: number = priceProduct * qtyFree;

        if(this.productSales[i].qtyFree > 0) {
          if(qtyBuy>0){
            let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
            let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
            cmds += this.productSales[i].initialName;
            cmds += newLine;
            cmds += lblPriceXQty.toString() + lblNettProduct.toString();
            cmds += newLine;
          }
          
          let lblPriceXQtyFree: String = 'FREE ' + qtyFree + ' x ' + this.formatRupiah(priceProduct.toString());
          let lblNettFree: String = this.getLblNett('('+this.formatRupiah(nettFree.toString())+')', lblPriceXQtyFree.length);
          cmds += this.productSales[i].initialName;
          cmds += newLine;
          cmds += lblPriceXQtyFree.toString() + lblNettFree.toString();
          cmds += newLine;

          totalPromo = totalPromo + (priceProduct * qtyFree);
        } else {
          if(qtyBuy > 0){
            let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
            let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
            cmds += this.productSales[i].initialName;
            cmds += newLine;
            cmds += lblPriceXQty.toString() + lblNettProduct.toString();
            cmds += newLine;
          }
        }
      }

      if(this.productSales[i].typePromo === 'FREE ITEM (ITEM)' || this.productSales[i].typePromo === 'FREE ITEM (NOMINAL)') {
        let price: number = parseInt(this.productSales[i].priceAfterPromo);
        let qty: number = parseInt(this.productSales[i].qtyFree);
        let nett: number = price * qty;
        let lblPriceAndQty: String = 'FREE ' + qty + ' x ' + this.formatRupiah(price.toString());
        let lblNett: String = this.getLblNett('('+this.formatRupiah(nett.toString())+')', lblPriceAndQty.length);
        cmds += this.productSales[i].initialName;
        cmds += newLine;
        cmds += lblPriceAndQty.toString() + lblNett.toString();
        cmds += newLine;
      }
    }
    //End List Barang Belanja
    cmds += '---------------------------------';
    let lblPaymentWithoutPromo: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPaymentWithoutPromo.toString()));
    cmds += '        HARGA JUAL : ' + lblPaymentWithoutPromo;
    cmds += newLine;
    if(this.transactionDiscount>0){
      let countPromoDiff: number = this.transactionDiscount;
      let strCountPromoDiff: string = '(' + this.formatRupiah(countPromoDiff.toString()) + ')';
      let lblDiskonByNominal: String = this.getLabelPrintNominal(strCountPromoDiff);    
      cmds += '        Diskon :     ' + lblDiskonByNominal;
      cmds += newLine;
      totalPromo = this.transactionDiscount;
    }

    //Start Detail Promo Product
    for(let i = 0; i < this.productSales.length; i++) {      
      if(this.productSales[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSales[i].typePromo !== 'FREE ITEM (NOMINAL)') {
        if(this.productSales[i].typePromo === 'NOMINAL' || this.productSales[i].typePromo === 'PERSENTASE') {
          let countPromo: number = 0;
           
          if(this.productSales[i].valPromoNominal!=0){
            countPromo = this.productSales[i].valPromoNominal;
           }
           else if(this.productSales[i].valPromoPercentage!=0){
            //countPromo = (this.productSales[i].valPromoPercentage /100) * (this.productSales[i].price * this.productSales[i].qty);
            countPromo = this.productSales[i].maxDiscount;
           }
           countPromo = Math.floor(countPromo);
           totalPromo = totalPromo + countPromo;
            let strCountPromoDiff: string = '(' + this.formatRupiah(countPromo.toString()) + ')';
            let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);

          cmds += this.productSales[i].initialName;
          cmds += newLine;
          cmds += '            DISKON : ' + lblCountPromo;
          cmds += newLine;
        } else {
          // promo by total belanja
          if(this.productSales[i].valPromoNominal !== 0 || this.productSales[i].valPromoPercentage !== 0) {
           let countPromo: number = 0;
           if(this.productSales[i].valPromoNominal!=0){
            countPromo = this.productSales[i].valPromoNominal;
           }
           else if(this.productSales[i].valPromoPercentage!=0){
            //countPromo = (this.productSales[i].valPromoPercentage /100) * (this.productSales[i].price * this.productSales[i].qty);
            countPromo = this.productSales[i].maxDiscount;
           }
           countPromo = Math.floor(countPromo);
           totalPromo = totalPromo + countPromo;
            let strCountPromoDiff: string = '(' + this.formatRupiah(countPromo.toString()) + ')';
            let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);

            cmds += this.productSales[i].initialName;
            cmds += newLine;
            cmds += '            DISKON : ' + lblCountPromo;
            cmds += newLine;
          }
        }
        
      }

      if(this.productSales[i].typePromo === 'FREE ITEM (ITEM)' || this.productSales[i].typePromo === 'FREE ITEM (NOMINAL)') {
        totalPromo = totalPromo + (this.productSales[i].priceAfterPromo * this.productSales[i].qtyFree);
      }
    }
    //End Detail Promo Product

    cmds += '---------------------------------';
    let lblTotalPayment: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPayment.toString()));
    let lblTotalItem: String = this.getLabelTotalItem(this.totalItem);
    cmds += '' + lblTotalItem + lblTotalPayment;
    cmds += newLine;
    let lblTotalCustomerCash: String = this.getLabelPrintNominal(this.formatRupiah(this.totalCustomerCash.toString()));
    cmds += '             TUNAI : ' + lblTotalCustomerCash;
    cmds += newLine;

    //Start transfer
    if(this.customerTransfer !== 0) {
      let lblCustomerTransfer: String = this.getLabelPrintNominal(this.formatRupiah(this.customerTransfer.toString()));
      cmds += '          TRANSFER : ' + lblCustomerTransfer;
      cmds += newLine;
    }

    //Start Voucher
    if(this.voucherValue !== 0) {
      let lblVoucherValue: String = this.getLabelPrintNominal(this.formatRupiah(this.voucherValue.toString()));
      cmds += '           VOUCHER : ' + lblVoucherValue;
      cmds += newLine;
    }
    //End Voucher

    //Start Penggunaan Point
    if(this.pointMutationType === 'O') {
      let lblPointValue: String = this.getLabelPrintNominal(this.formatRupiah(this.memberPointValue.toString()));
      cmds += '             POINT : ' + lblPointValue;
      cmds += newLine
    }
    //End Penggunaan Point

    //Start Pembayaran Non Tunai
    if(this.customerCredit !== 0 && this.customerCredit !== null) {
      let lblCustomerCredit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerCredit.toString()));
      cmds += '            KREDIT : ' + lblCustomerCredit;
      cmds += newLine;
    }
    if(this.customerDebit !== 0 && this.customerDebit !== null) {
      let lblCustomerDebit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerDebit.toString()));
      cmds += '             DEBIT : ' + lblCustomerDebit;
      cmds += newLine;
    }
    //End Pembayaran Non Tunai

    let lblTotalChange: String = this.getLabelPrintNominal(this.formatRupiah(this.totalChange.toString()));
    cmds += '        KEMBALI RP : ' + lblTotalChange;
    cmds += newLine;

    //Start detail Member Point

    let lblTotalPromo: String = this.getLabelPrintNominal(this.formatRupiah(totalPromo.toString()));
    cmds += '        ANDA HEMAT : ' + lblTotalPromo;
    cmds += newLine;
    
    if(this.memberPhone !== '') {
      cmds += '---------------------------------';
      cmds += newLine;
      cmds += 'NAMA RESELLER  : ' + this.resellerName;
      cmds += newLine;
      cmds += 'KODE RESELLER  : ' + this.resellerCode;
      // cmds += newLine;
      // cmds += 'POINT DITAMBAHKAN : ' + this.totalPointAdd;
      // cmds += newLine;
      // cmds += 'POINT TERPAKAI    : ' + this.memberPointUsed;
      cmds += newLine;
    }
    //End detail Member Point
    cmds += newLine;
    cmds += ' BARANG YANG SUDAH DIBELI TIDAK ';
    cmds += newLine;
    cmds += ' DAPAT DITUKAR ATAU DIKEMBALIKAN ';
    cmds += newLine;
    cmds += newLine;
    cmds += '           TERIMA KASIH';
    cmds += newLine;
    cmds += newLine;
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
    cmds += printPhone;
    cmds += newLine;
    cmds += cut;

    cpj.printerCommands = cmds;
    
    //Send print job to printer!
    console.log(cmds);
    cpj.sendToClient();
    this.saveTransactionTemp();
    this.clearTransaction();
    this.clearReward();

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.getTodayPromotion('after_trans');
    });
  }

  rePrintReceipt() {
    if(this.productSalesTemp.length > 0) {
      let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
      let convertDate = this.utilService.convertDate(transactionDate);

      let monthName: any = this.monthList[(parseInt(convertDate.months)-1)];
      let transDate = convertDate.dates + '-' + monthName + '-' + convertDate.years;
      let transTime = this.transactionTimeTemp;

      let totalPromo: number = 0;
      let cashierName: string = this.userProfile.name;

      //Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();

      //Set Printer type (Refer to the help, there many of them!)
      if ( this.isDefaultPrinterSelected ) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
      }

      //Set content to print...
      //Create ESP/POS commands for sample label
      let esc = '\x1B'; //ESC byte in hex notation
      let newLine = '\x0A'; //LF byte in hex notation
      let cut = "\x1b" + "\x69";
      let cmds = esc + "@"; //Initializes the printer (ESC @)
      cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
      cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
      cmds += '         OEMAH HERBORIST';
      cmds += newLine;
      cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
      cmds += newLine;
      cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
      cmds += newLine;
      cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
      cmds += newLine;
      let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
      cmds += printPhone;
      cmds += newLine;
      cmds += '---------------------------------';
      cmds += 'NO STRUK : ' + this.receiptNo;
      cmds += newLine;
      cmds += 'TANGGAL  : ' + transDate + ' ' + transTime;
      cmds += newLine;
      cmds += 'KASIR    : ' + cashierName.toUpperCase();
      cmds += newLine;
      cmds += '---------------------------------';
      cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)

      //Start List Barang Belanja
      for(let i = 0; i < this.productSalesTemp.length; i++) {
        if(this.productSalesTemp[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSalesTemp[i].typePromo !== 'FREE ITEM (NOMINAL)') {
          let priceProduct: number = parseInt(this.productSalesTemp[i].price);
          let qtyBuy: number = parseInt(this.productSalesTemp[i].qty);
          let nettProduct: number = priceProduct * qtyBuy;
  
          let qtyFree: number = parseInt(this.productSalesTemp[i].qtyFree);
          let nettFree: number = priceProduct * qtyFree;
  
          if(this.productSalesTemp[i].qtyFree > 0) {
            if(qtyBuy > 0){
              let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
              let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
              cmds += this.productSalesTemp[i].initialName;
              cmds += newLine;
              cmds += lblPriceXQty.toString() + lblNettProduct.toString();
              cmds += newLine;
            }
            
            let lblPriceXQtyFree: String = 'FREE ' + qtyFree + ' x ' + this.formatRupiah(priceProduct.toString());
            let lblNettFree: String = this.getLblNett('('+this.formatRupiah(nettFree.toString())+')', lblPriceXQtyFree.length);
            cmds += this.productSalesTemp[i].initialName;
            cmds += newLine;
            cmds += lblPriceXQtyFree.toString() + lblNettFree.toString();
            cmds += newLine;
  
            totalPromo = totalPromo + (priceProduct * qtyFree);
          } else {
            if(qtyBuy > 0){
              let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
              let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
              cmds += this.productSalesTemp[i].initialName;
              cmds += newLine;
              cmds += lblPriceXQty.toString() + lblNettProduct.toString();
              cmds += newLine;
            }
          }
        }

        if(this.productSalesTemp[i].typePromo === 'FREE ITEM (ITEM)' || this.productSalesTemp[i].typePromo === 'FREE ITEM (NOMINAL)') {
          let price: number = parseInt(this.productSalesTemp[i].priceAfterPromo);
          let qty: number = parseInt(this.productSalesTemp[i].qtyFree);
          let nett: number = price * qty;
          let lblPriceAndQty: String = 'FREE ' + qty + ' x ' + this.formatRupiah(price.toString());
          let lblNett: String = this.getLblNett('('+this.formatRupiah(nett.toString())+')', lblPriceAndQty.length);
          cmds += this.productSalesTemp[i].initialName;
          cmds += newLine;
          cmds += lblPriceAndQty.toString() + lblNett.toString();
          cmds += newLine;
        }
      }
      //End List Barang Belanja
      cmds += '---------------------------------';
      let lblPaymentWithoutPromo: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPaymentWithoutPromo.toString()));
      cmds += '        HARGA JUAL : ' + lblPaymentWithoutPromo;
      cmds += newLine;
      
    if(this.transactionDiscountTemp>0){
      let countPromoDiff: number = this.transactionDiscountTemp;
      let strCountPromoDiff: string = '(' + this.formatRupiah(countPromoDiff.toString()) + ')';
      let lblDiskonByNominal: String = this.getLabelPrintNominal(strCountPromoDiff);    
      cmds += '        Diskon :      ' + lblDiskonByNominal;
      cmds += newLine;
      totalPromo = this.transactionDiscountTemp;
    }

      //Start Detail Promo Product
      for(let i = 0; i < this.productSalesTemp.length; i++) {
        if(this.productSalesTemp[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSalesTemp[i].typePromo !== 'FREE ITEM (NOMINAL)') {
          if(this.productSalesTemp[i].typePromo === 'NOMINAL' || this.productSalesTemp[i].typePromo === 'PERSENTASE') {
            let countPromoDiff: number = (this.productSalesTemp[i].price * this.productSalesTemp[i].qty) - (this.productSalesTemp[i].priceAfterPromo * this.productSalesTemp[i].qty);
            totalPromo = totalPromo + countPromoDiff;
            let strCountPromoDiff: string = '(' + this.formatRupiah(countPromoDiff.toString()) + ')';
            let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);

            cmds += this.productSalesTemp[i].initialName;
            cmds += newLine;
            cmds += '            DISKON : ' + lblCountPromo;
            cmds += newLine;
          } else {
            if(this.productSalesTemp[i].valPromoNominal !== 0 || this.productSalesTemp[i].valPromoPercentage !== 0) {
              let countPromo: number = ((this.productSalesTemp[i].price * this.productSalesTemp[i].qty) * (this.productSalesTemp[i].valPromoPercentage / 100)) + this.productSalesTemp[i].valPromoNominal;
              totalPromo = totalPromo + countPromo;
              let strCountPromoDiff: string = '(' + this.formatRupiah(countPromo.toString()) + ')';
              let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);
  
              cmds += this.productSalesTemp[i].initialName;
              cmds += newLine;
              cmds += '            DISKON : ' + lblCountPromo;
              cmds += newLine;
            }
          }
          
        }
  
        if(this.productSalesTemp[i].typePromo === 'FREE ITEM (ITEM)' || this.productSalesTemp[i].typePromo === 'FREE ITEM (NOMINAL)') {
          totalPromo = totalPromo + (this.productSalesTemp[i].priceAfterPromo * this.productSalesTemp[i].qtyFree);
        }
      }
      //End Detail Promo Product

      cmds += '---------------------------------';
      let lblTotalPayment: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPaymentTemp.toString()));
      let lblTotalItem: String = this.getLabelTotalItem(this.totalItemTemp);
      cmds += '' + lblTotalItem + lblTotalPayment;
      cmds += newLine;
      let lblTotalCustomerCash: String = this.getLabelPrintNominal(this.formatRupiah(this.totalCustomerCashTemp.toString()));
      cmds += '             TUNAI : ' + lblTotalCustomerCash;
      cmds += newLine;

      //Start transfer
      if(this.customerTransfer !== 0) {
        let lblCustomerTransfer: String = this.getLabelPrintNominal(this.formatRupiah(this.customerTransfer.toString()));
        cmds += '          TRANSFER : ' + lblCustomerTransfer;
        cmds += newLine;
      }

      //Start Voucher
      if(this.voucherValueTemp !== 0) {
        let lblVoucherValue: String = this.getLabelPrintNominal(this.formatRupiah(this.voucherValueTemp.toString()));
        cmds += '           VOUCHER : ' + lblVoucherValue;
        cmds += newLine;
      }
      //End Voucher

      //Start Penggunaan Point
      if(this.pointMutationTypeTemp === 'O') {
        let lblPointValue: String = this.getLabelPrintNominal(this.formatRupiah(this.memberPointValueTemp.toString()));
        cmds += '             POINT : ' + lblPointValue;
        cmds += newLine
      }
      //End Penggunaan Point

      //Start Pembayaran Non Tunai
      if(this.customerCreditTemp !== 0 && this.customerCreditTemp !== null) {
        let lblCustomerCredit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerCreditTemp.toString()));
        cmds += '            KREDIT : ' + lblCustomerCredit;
        cmds += newLine;
      }
      if(this.customerDebitTemp !== 0 && this.customerDebitTemp !== null) {
        let lblCustomerDebit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerDebitTemp.toString()));
        cmds += '             DEBIT : ' + lblCustomerDebit;
        cmds += newLine;
      }
      //End Pembayaran Non Tunai

      let lblTotalChange: String = this.getLabelPrintNominal(this.formatRupiah(this.totalChangeTemp.toString()));
      cmds += '        KEMBALI RP : ' + lblTotalChange;
      cmds += newLine;

      //Start detail Member Point

      let lblTotalPromo: String = this.getLabelPrintNominal(this.formatRupiah(totalPromo.toString()));
      cmds += '        ANDA HEMAT : ' + lblTotalPromo;
      cmds += newLine;
      
      if(this.memberPhoneTemp !== '') {
        cmds += '---------------------------------';
        cmds += newLine;
        cmds += 'MEMBER HERBORIST  : ' + this.memberPhoneTemp;
        cmds += newLine;
       // cmds += 'POINT DITAMBAHKAN : ' + this.totalPointAddTemp;
        //cmds += newLine;
        cmds += 'POINT TERPAKAI    : ' + this.memberPointUsedTemp;
        cmds += newLine;
      }
      //End detail Member Point

      cmds += newLine + newLine;
      cmds += ' BARANG YANG SUDAH DIBELI TIDAK ';
      cmds += newLine;
      cmds += ' DAPAT DITUKAR ATAU DIKEMBALIKAN ';
      cmds += newLine + newLine + newLine;
      cmds += '           TERIMA KASIH';
      cmds += newLine + newLine + newLine + newLine + newLine + newLine + newLine + newLine + newLine;
      cmds += cut;

      cpj.printerCommands = cmds;
      
      //Send print job to printer!
      console.log(cmds);
      cpj.sendToClient();
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    } else {
      this.toastCtrl.create({ duration: 2000, message: 'Tidak terdapat struk transaksi sebelumnya!' }).then(t => t.present());
    }
  }

  setPaperPrintReceipt() {
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
    cmds += printPhone;
    cmds += newLine; 
    cmds += cut;
    cpj.printerCommands = cmds;
    //Send print job to printer!
    cpj.sendToClient();
  }

  getProduct(productBarcode: any) {
    if(productBarcode !== '' && productBarcode !== undefined) {
      let barcodetoString: string = productBarcode.toString();
      let dataInput = barcodetoString.split('*');
      let dataInput2 = barcodetoString.split('_');
      let inputQty: number = 0;
      let barcode: string;
      if(dataInput.length > 1) {
        inputQty = parseInt(dataInput[0]);
        barcode = dataInput[1];
      } else {
        inputQty = 1;
        barcode = dataInput[0];
      }

      if(dataInput2.length > 1) {
        barcode = dataInput2[0];
      }
      
      let promoType: any = null;
      let promoValPercentage: any = 0;
      let promoValNominal: any = 0;
      let freeQty: any = 0;
      let promoWarehouseId: any = 0;
      let idPromotionDetail: any = 0;
      let priceProduct: any = 0;
      let nettPriceAfterPromo: any = 0;
      let latestProductScan: any;
      let checkItemBarcode: any[] = this.productSales.find(x => x.barcode === barcode && x.typePromo !== 'FREE ITEM (ITEM)' && x.typePromo !== 'FREE ITEM (NOMINAL)');
      let checkItemPcode: any[] = this.productSales.find(x => x.id === barcode && x.typePromo !== 'FREE ITEM (ITEM)' && x.typePromo !== 'FREE ITEM (NOMINAL)');

      // jika barang sudah ter-scan sebelumnya
      if (checkItemBarcode !== undefined || checkItemPcode !== undefined) {
        let checkIndex: any;
        if(checkItemBarcode !== undefined) { checkIndex = checkItemBarcode; }
        if(checkItemPcode !== undefined) { checkIndex = checkItemPcode; }
        let index: number = this.productSales.indexOf(checkIndex);
        let price: number = this.productSales[index]['price'];
        let qty: number = this.productSales[index]['qty'];
        
        let updateQty: number = qty + inputQty;
        if(updateQty < 0){
          updateQty=0;
        }
        let updateNett: number = nettPriceAfterPromo !== 0 ? nettPriceAfterPromo : price * updateQty;
        if(updateQty === 0) {
          this.productSales[index]['qty'] =  updateQty;
          this.productSales[index]['nett'] =  updateNett;
          this.checkPromoFreeItemByItem(this.productSales[index]);
          this.checkPromoFreeItemByNominalProduct(this.productSales[index]);
          this.productSales.splice(index, 1);
        } else {
          this.productSales[index]['qty'] =  updateQty;
          this.productSales[index]['nett'] =  updateNett;
        }
       
        latestProductScan = this.productSales[index];
        if(updateQty>0){
          this.checkPromoNominalBySelectedProduct(latestProductScan);
          this.checkPromoPercentBySelectedProduct(latestProductScan);
          this.checkPromoFreeItemByItem(latestProductScan);
          this.checkPromoFreeItemByNominalProduct(latestProductScan);
        }
      } else {
        if (this.mstBarcodeList[barcode] !== undefined) {
          // ini kalau yg diinput barcode
          if(inputQty <= 0){
            inputQty=0;            
            let message = 'Minimal Input 1';
            this.showAlertBarcodeNotValid(message);
          }else{
            priceProduct = this.mstBarcodeList[barcode]['price'];
            let productScanData = {
              barcode_view: this.mstBarcodeList[barcode]['barcode'],
              id: this.mstBarcodeList[barcode]['id'],
              barcode: this.mstBarcodeList[barcode]['barcode'],
              name: this.mstBarcodeList[barcode]['product_name'],
              initialName: this.mstBarcodeList[barcode]['initial_name'],
              qty: inputQty,
              price: this.mstBarcodeList[barcode]['price'],
              priceAfterPromo: priceProduct,
              nett: nettPriceAfterPromo !== 0 ? nettPriceAfterPromo : priceProduct * inputQty,
              typePromo: promoType,
              valPromoPercentage: promoValPercentage,
              valPromoNominal: promoValNominal,
              qtyFree: freeQty,
              multipleFlag: 0,
              warehouseIdPromo: promoWarehouseId,
              promotionDetailId: idPromotionDetail,
              promotionFreeItemId: 0,
              promotionFreeItemByNominalId :0,
              getFree : 0,
              promo : '',
              arrayPromoFreeItem : [],
              maxDiscount : 0 // ini untuk max discount persentase
            };

            this.productSales.push(productScanData);
            latestProductScan = productScanData;
            
            this.checkPromoNominalBySelectedProduct(latestProductScan);
            this.checkPromoPercentBySelectedProduct(latestProductScan);
            this.checkPromoFreeItemByItem(latestProductScan);
            this.checkPromoFreeItemByNominalProduct(latestProductScan);
          }

        } else if (this.mstProductList[parseInt(barcode)] !== undefined) {
          // ini kalau yg diinput pakai pcode
          if(inputQty <= 0){
            inputQty=0;            
            let message = 'Minimal Input 1';
            this.showAlertBarcodeNotValid(message);
          }else{
            let id: any = parseInt(barcode);
            barcode = id;
            priceProduct = this.mstProductList[barcode]['price'];          
            let productScanData = {
              barcode_view: parseInt(this.mstProductList[barcode]['id']),
              id: this.mstProductList[barcode]['id'],
              barcode: this.mstProductList[barcode]['barcode'],
              name: this.mstProductList[barcode]['product_name'],
              initialName: this.mstProductList[barcode]['initial_name'],
              qty: inputQty,
              price: this.mstProductList[barcode]['price'],
              priceAfterPromo: priceProduct,
              nett: nettPriceAfterPromo !== 0 ? nettPriceAfterPromo : priceProduct * inputQty,
              typePromo: promoType,
              valPromoPercentage: promoValPercentage,
              valPromoNominal: promoValNominal,
              qtyFree: freeQty,
              multipleFlag: 0,
              warehouseIdPromo: promoWarehouseId,
              promotionDetailId: idPromotionDetail,
              promotionFreeItemId: 0,
              promotionFreeItemByNominalId :0,
              getFree :0,
              promo: '',
              arrayPromoFreeItem : [],
              maxDiscount : 0 // ini untuk max discount persentase
            };

            this.productSales.push(productScanData);
            latestProductScan = productScanData;
            this.checkPromoNominalBySelectedProduct(latestProductScan);
            this.checkPromoPercentBySelectedProduct(latestProductScan);
            this.checkPromoFreeItemByItem(latestProductScan);
            this.checkPromoFreeItemByNominalProduct(latestProductScan);
          }
        } else {
          let message = 'Barcode tidak valid,\ncoba gunakan kode produk.\nAtau belum di bikin di group harga';
          this.showAlertBarcodeNotValid(message);
        }
      }
      this.productBarcode = "";
      this.productId = "";
      this.calcTotal();
      this.checkPromoFreeProductByNominal();
      this.getChangeNominal();
      this.setReward();
      if(this.productSales.length>0){
        this.scrollDownEnd();
      }
      
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    } else {
      let message = 'Field Barcode kosong, silakan isi dengan kode Barcode / kode produk terlebih dahulu.'
      this.showAlertBarcodeNotValid(message);
      
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    }
  }

  checkPromoFreeProductByNominal() {
    // CHECK PROMO FREE ITEM (NOMINAL) - TOTAL BELANJA
    let promoFreeItemNominal =this.promotionDetailList.find(x => x.promotion_type_name === 'FREE ITEM (NOMINAL)' && x.product_id === null && x.promo_based_on == 1);
    let resetFreeByNominal = 0;
    if(promoFreeItemNominal){
      let detailPromoFreeItemNominal = 'Ada promo free item (nominal).';
      let minimum = promoFreeItemNominal.minimum_transaction;
      let berlakuKelipatan = promoFreeItemNominal.multiple_flag;
      let free = promoFreeItemNominal.free_qty;
      let idpromodetail = promoFreeItemNominal.id;
      let idpromo = promoFreeItemNominal.promotion_id;
      if (this.totalPayment < parseInt(minimum)){
        detailPromoFreeItemNominal+='Belum memenuhi minimum belanja.';
        this.message = "";
        this.arrayFreeItemUsed =[];
        resetFreeByNominal =1;
      }
      else{
        detailPromoFreeItemNominal += 'Sudah memenuhi minimum belanja.';
        if(berlakuKelipatan=='1'){
          detailPromoFreeItemNominal += 'Berlaku kelipatan.';
          free = free * Math.floor(this.totalPayment/parseInt(minimum));
        }
        else{
          detailPromoFreeItemNominal += 'Tidak belaku kelipatan.';
        }
        detailPromoFreeItemNominal += 'Mendapat free item:';
        
        this.countFreeItem = free;
        this.promoItemNominal = promoFreeItemNominal;

        let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
        if(checkArray){
          let index = this.arrayFreeItemUsed.indexOf(checkArray);
          this.arrayFreeItemUsed[index]['free_item_max']=free;
          
          if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
            resetFreeByNominal = 1;
            this.arrayFreeItemUsed[index]['free_item_used'] = 0;
            for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
              this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
            }
          }
          this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
          if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
            this.message = " Get Free Item";
          }
        }
        else{
          this.message = " Get Free Item";
          this.arrayFreeItemUsed.push({
            product_id:0,
            promo_id: idpromo,
            promo_id_detail: idpromodetail,
            free_item_max: free,
            free_item_remaining: free,
            free_item_used: 0,
            array_item:[]
          });
        }
      }
      this.listFreeItemByTotalBelanja = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
     
      if(resetFreeByNominal==1 ){
        for(let a=0;a < this.listFreeItemByTotalBelanja.length;a++){
        let searchFreeProductByNominal = this.productSales.filter(x => x.id == this.listFreeItemByTotalBelanja[a]['product_id']);
        if(searchFreeProductByNominal){
          for(let a=0;a<searchFreeProductByNominal.length;a++){
            searchFreeProductByNominal[a]['qtyFree'] = 0;
          }
        }
          this.listFreeItemByTotalBelanja[a]['product_id'];
        }
      }
    }
    this.totalItem = 0;
    for(let c=(this.productSales.length-1);c>=0;c--){
      this.totalItem += (parseInt(this.productSales[c].qty) + parseInt(this.productSales[c].qtyFree));
      if(this.productSales[c]['qtyFree']==0 && this.productSales[c]['qty']==0){
        this.productSales.splice(c,1);
      }
    }
  // END OF PROMO FREE ITEM (NOMINAL);
  }

  checkPromoFreeItemByNominalProduct(latestProductSales:any) {
    let pcode = latestProductSales.id;
    let product_name = latestProductSales.name;
    // check jika ada promo free item by nominal untuk semua produk
    let detailPromoFreeItemByNominalProduct  = '';
    let promoFreeItemNominalAllProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM (NOMINAL)' && x.product_id == null && x.promo_based_on == 2);
    if(promoFreeItemNominalAllProduct.length > 0){
       detailPromoFreeItemByNominalProduct += 'Ada promo free product by nominal product';
      for(let a =0;a< promoFreeItemNominalAllProduct.length;a++){
        let idpromodetail = promoFreeItemNominalAllProduct[a].id;
         let idpromo = promoFreeItemNominalAllProduct[a].promotion_id;
        let minimum = promoFreeItemNominalAllProduct[a].minimum_transaction;
        let multipleFlag = promoFreeItemNominalAllProduct[a].multiple_flag;
        let free = promoFreeItemNominalAllProduct[a].free_qty;
        let free_item_type = promoFreeItemNominalAllProduct[a].free_item_type;
        let maximum = promoFreeItemNominalAllProduct[a].maximum_free_item;
        if(latestProductSales.nett < parseInt(minimum)){
          detailPromoFreeItemByNominalProduct +='Belum memenuhi minimum belanja.';
          free = 0;
        }
        else{
          detailPromoFreeItemByNominalProduct+='Sudah memenuhi minimum belanja.';
          if(multipleFlag=='1'){
            detailPromoFreeItemByNominalProduct += 'Berlaku kelipatan';
            free = free * Math.floor(latestProductSales.nett/parseInt(minimum));
          }
          else{
            detailPromoFreeItemByNominalProduct+='Tidak belaku kelipatan';
          }
          detailPromoFreeItemByNominalProduct+='\nMaksimum hadiah :'+maximum;
          if(maximum>0 && free> maximum){
            free = maximum;
          }
        }
            // array free item //
            let countFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === pcode && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
            let freeItemRemaining = 0;
            if(countFreeItem) {
              let index = this.arrayFreeItemUsed.indexOf(countFreeItem);
              this.arrayFreeItemUsed[index]['free_item_max'] = free;
              
              //NEW UPDATE
              if(free==0){            
                this.arrayFreeItemUsed[index]['free_item_used'] = 0;
                for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                  this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
                }
              }
              if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
                this.arrayFreeItemUsed[index]['free_item_used'] = 0;
                for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                  this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
                }
              }
              // END OF NEW UPDATE
              let used = this.arrayFreeItemUsed[index]['free_item_used'];
              freeItemRemaining = parseInt(free)-parseInt(used);
              this.arrayFreeItemUsed[index]['free_item_remaining'] =freeItemRemaining;
            }
            else{
              freeItemRemaining = free;
              this.arrayFreeItemUsed.push({
                product_id:pcode,
                promo_id: idpromo,
                promo_id_detail: idpromodetail,
                free_item_max: free,
                free_item_remaining: free,
                free_item_used: 0,
                array_item:[]
              });
            }
            if(freeItemRemaining==0){
              free = 0;
            }
            //end of array free item

          latestProductSales.getFree = free;
          latestProductSales.promo= promoFreeItemNominalAllProduct;
          if(free_item_type==1){
            let promotionFreeItemSameAsProductScan: any[] = [];
            promotionFreeItemSameAsProductScan.push({
              'id' :idpromo,
              'product_id': pcode,
              'product_name':product_name,
              'promotion_detail_id': idpromodetail
            });
            latestProductSales.free_item_list = promotionFreeItemSameAsProductScan;
          }
          else{
            latestProductSales.free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
          }
        
      }
    }
    
    this.arraySumNominal = [];
    for(let a=0;a<this.arrayFreeItemUsed.length;a++){
      let lengthFreeItem = this.arrayFreeItemUsed[a]['array_item'].length;
      if (lengthFreeItem > 0){
        for(let b=0;b<lengthFreeItem;b++){            
          let checkProduct =this.arraySumNominal.find(x => x.product_id==this.arrayFreeItemUsed[a]['array_item'][b]['product_id']);
          
          if(checkProduct){
            checkProduct.qty +=this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
          }
          else{
            this.arraySumNominal.push({
                'product_id' :this.arrayFreeItemUsed[a]['array_item'][b]['product_id'],
                'qty' :this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']
              });
          }
        }
      }
    }
    if(this.arraySumNominal.length>0){
      for(let a=0;a<this.arraySumNominal.length;a++){
        let checkProduct =this.productSales.find(x => x.id==this.arraySumNominal[a]['product_id']);
        
        let indexProductSales = this.productSales.indexOf(checkProduct);
        if(checkProduct){
          checkProduct.qtyFree = this.arraySumNominal[a]['qty'];
          for(let b=0;b<checkProduct.arrayPromoFreeItem.length;b++){
            if(checkProduct.arrayPromoFreeItem[b]['promotion_id']==checkProduct.promotionFreeItemId && 
            checkProduct.arrayPromoFreeItem[b]['product_scan']==pcode){
              checkProduct.arrayPromoFreeItem[b]['qty']=this.arraySumNominal[a]['qty'];
            }
          }
          if(checkProduct.qtyFree==0 && checkProduct.qty==0){
            this.productSales.splice(indexProductSales, 1);
          }
        }
      }
    }
   // console.log(detailPromoFreeItemByNominalProduct);

    // check jika ada promo free item by nominal produk untuk produk tertentu
    let promoFreeItemNominalSelectedProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM (NOMINAL)' && x.product_id !== null);
    if(promoFreeItemNominalSelectedProduct.length > 0){
      let detailPromoFreeItemByNominalProduct = 'Ada promo free item (nominal) selected product.';
      for(let a =0; a < promoFreeItemNominalSelectedProduct.length;a++){
        let idpromodetail = promoFreeItemNominalSelectedProduct[a].id;
        let idpromo = promoFreeItemNominalSelectedProduct[a].promotion_id;
        let minimum = promoFreeItemNominalSelectedProduct[a].minimum_transaction;
        let multipleFlag = promoFreeItemNominalSelectedProduct[a].multiple_flag;
        let free = promoFreeItemNominalSelectedProduct[a].free_qty;
        let product = promoFreeItemNominalSelectedProduct[a].product_id;
        let free_item_type = promoFreeItemNominalSelectedProduct[a].free_item_type;
        let maximum = promoFreeItemNominalSelectedProduct[a].maximum_free_item;
        

        let arrProduct = [];
        arrProduct= product.split(';');
        let checkProduct =arrProduct.find(x => x==pcode);
        if(checkProduct != undefined){
          product = checkProduct;
        }
        if(product==pcode){
          if(latestProductSales.nett < parseInt(minimum)){
            detailPromoFreeItemByNominalProduct+='Belum memenuhi minimum belanja.';
            free = 0;
          }
          else{
            detailPromoFreeItemByNominalProduct += 'Sudah memenuhi minimum belanja.';
            if(multipleFlag=='1'){
              detailPromoFreeItemByNominalProduct += 'Berlaku kelipatan.';
              free = free * Math.floor(latestProductSales.nett/parseInt(minimum));
            }
            else{
              detailPromoFreeItemByNominalProduct += 'Tidak belaku kelipatan';
            }
            
            detailPromoFreeItemByNominalProduct+='\nMaksimum hadiah :'+maximum;
            if(maximum>0 && free> maximum){
              free = maximum;
            }
          }

            // array free item //
            let countFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === pcode && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
            let freeItemRemaining = 0;
            if(countFreeItem) {
              let index = this.arrayFreeItemUsed.indexOf(countFreeItem);
              this.arrayFreeItemUsed[index]['free_item_max'] = free;
              //NEW UPDATE
              if(free==0){            
                this.arrayFreeItemUsed[index]['free_item_used'] = 0;
                for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                  this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
                }
              }
              if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
                this.arrayFreeItemUsed[index]['free_item_used'] = 0;
                for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                  this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
                }
              }
              // END OF NEW UPDATE
              let used = this.arrayFreeItemUsed[index]['free_item_used'];
              freeItemRemaining = parseInt(free)-parseInt(used);
              this.arrayFreeItemUsed[index]['free_item_remaining'] =freeItemRemaining;
            }
            else{
              freeItemRemaining = free;
              this.arrayFreeItemUsed.push({
                product_id:pcode,
                promo_id: idpromo,
                promo_id_detail: idpromodetail,
                free_item_max: free,
                free_item_remaining: free,
                free_item_used: 0,
                array_item:[]
              });
            }
            if(freeItemRemaining==0){
              free = 0;
            }
            //end of array free item

            detailPromoFreeItemByNominalProduct += 'Mendapat free '+free+' item';
            latestProductSales.getFree = free;
            latestProductSales.promo= promoFreeItemNominalSelectedProduct;
            if(free_item_type==1){
              let promotionFreeItemSameAsProductScan: any[] = [];
              promotionFreeItemSameAsProductScan.push({
                'id' :idpromo,
                'product_id': pcode,
                'product_name':product_name,
                'promotion_detail_id': idpromodetail
              });

              latestProductSales.free_item_list = promotionFreeItemSameAsProductScan;
            }
            else{
             latestProductSales.free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);        
            }
          }
        
      }this.arraySumNominal = [];
      for(let a=0;a<this.arrayFreeItemUsed.length;a++){
        let lengthFreeItem = this.arrayFreeItemUsed[a]['array_item'].length;
        if (lengthFreeItem > 0){
          for(let b=0;b<lengthFreeItem;b++){            
            let checkProduct =this.arraySumNominal.find(x => x.product_id==this.arrayFreeItemUsed[a]['array_item'][b]['product_id']);
            
            if(checkProduct){
              checkProduct.qty +=this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
            }
            else{
              this.arraySumNominal.push({
                  'product_id' :this.arrayFreeItemUsed[a]['array_item'][b]['product_id'],
                  'qty' :this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']
                });
            }
          }
        }
      }
      if(this.arraySumNominal.length>0){
        for(let a=0;a<this.arraySumNominal.length;a++){
          let checkProduct =this.productSales.find(x => x.id==this.arraySumNominal[a]['product_id']);
          
          let indexProductSales = this.productSales.indexOf(checkProduct);
          if(checkProduct){
            checkProduct.qtyFree = this.arraySumNominal[a]['qty'];
            for(let b=0;b<checkProduct.arrayPromoFreeItem.length;b++){
              if(checkProduct.arrayPromoFreeItem[b]['promotion_id']==checkProduct.promotionFreeItemId && 
              checkProduct.arrayPromoFreeItem[b]['product_scan']==pcode){
                checkProduct.arrayPromoFreeItem[b]['qty']=this.arraySumNominal[a]['qty'];
              }
            }
            if(checkProduct.qtyFree==0 && checkProduct.qty==0){
              this.productSales.splice(indexProductSales, 1);
            }
          }
        }
      }
      console.log(detailPromoFreeItemByNominalProduct);
    }
  }

  // CHECK PROMO NOMINAL BY SELECTED PRODUCT
  checkPromoNominalBySelectedProduct(latestProductSales:any) {
    let pcode = latestProductSales.id;
    // check jika ada promo potongan by nominal belanja per produk untuk semua produk
    let promoNominalAllProduct =this.promotionDetailList.find(x => x.promotion_type_name === 'NOMINAL' && x.product_id === null && x.promo_based_on == 2);

    if(promoNominalAllProduct){
      let detailPromoNominalAllProduct = 'ada promo nominal product untuk semua produk.';
      let minimum = promoNominalAllProduct.minimum_transaction;
      let diskon = parseInt(promoNominalAllProduct.value);
      let berlakuKelipatan = promoNominalAllProduct.multiple_flag;
      let max_discount = parseInt(promoNominalAllProduct.max_discount);
      if (latestProductSales.nett < parseInt(minimum)){
        detailPromoNominalAllProduct += 'Belum memenuhi minimum belanja.';
      }
      else{
        detailPromoNominalAllProduct +='Sudah memenuhi minimum belanja.';
        detailPromoNominalAllProduct += '\n diskon Maksimum :'+max_discount+'.';
        if(berlakuKelipatan=='1'){
          detailPromoNominalAllProduct +='Berlaku kelipatan.';          
          diskon = diskon * Math.floor(latestProductSales.nett/minimum);;
        }
        else{
          detailPromoNominalAllProduct += 'Tidak berlaku kelipatan.';
        }
        if (max_discount >0  && diskon > max_discount){
          diskon = max_discount;
        }
        detailPromoNominalAllProduct += 'Mendapat diskon sebesar :'+diskon;
        latestProductSales.valPromoNominal =  diskon;
        latestProductSales.nett =  parseInt(latestProductSales.nett)-diskon;
        latestProductSales.multipleFlag =  parseInt(berlakuKelipatan);
      }
      //console.log(detailPromoNominalAllProduct);
    }

    // check jika ada promo potongan by nominal belanja per produk untuk produk tertentu
    let promoNominalByNominalProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'NOMINAL' && x.product_id !== null && x.promo_based_on == 2);

    if(promoNominalByNominalProduct.length > 0){
      let detailPromoNominalSelectedProduct = 'Ada promo potongan by nominal belanja per produk untuk produk tertentu.';
      for(let a =0; a< promoNominalByNominalProduct.length;a++){
        let minimum = promoNominalByNominalProduct[a].minimum_transaction;
        let diskon = parseInt(promoNominalByNominalProduct[a].value);
        let berlakuKelipatan = promoNominalByNominalProduct[a].multiple_flag;
        let product = promoNominalByNominalProduct[a].product_id;
        let max_discount = parseInt(promoNominalByNominalProduct[a].max_discount);
        let arrProduct = [];
        arrProduct= product.split(';');
        let checkProduct =arrProduct.find(x => x==pcode);
        if(checkProduct != undefined){
          if (latestProductSales.nett < parseInt(minimum)){
            detailPromoNominalSelectedProduct+='Belum memenuhi minimum belanja.';
          }
          else{
            detailPromoNominalSelectedProduct+='Sudah memenuhi minimum belanja.';
            detailPromoNominalSelectedProduct += '\n diskon Maksimum :'+max_discount+'.';
            if(berlakuKelipatan=='1'){
              detailPromoNominalSelectedProduct+='Berlaku kelipatan.';
              diskon = diskon * Math.floor(latestProductSales.nett/minimum);
            }
            else{
              detailPromoNominalSelectedProduct+='Tidak berlaku kelipatan.';
            }
            
            if (max_discount >0  && diskon > max_discount){
              diskon = max_discount;
            }
            detailPromoNominalSelectedProduct+='Mendapat diskon sebesar:'+diskon;
            latestProductSales.valPromoNominal =  diskon;
            latestProductSales.nett =  (parseInt(latestProductSales.nett)-diskon);
            latestProductSales.multipleFlag =  parseInt(berlakuKelipatan);
          }
        }
      }
      //console.log(detailPromoNominalSelectedProduct);
    }

    // check jika ada promo potongan by produk per produk untuk semua produk
    let promoNominalByAllProduct =this.promotionDetailList.find(x => x.promotion_type_name === 'NOMINAL' && x.product_id === null && x.promo_based_on == 3);

    if(promoNominalByAllProduct){
      let detailPromo = 'ada promo nominal product untuk semua produk.';
      let minimum = promoNominalByAllProduct.minimum_buy;
      let maximum = promoNominalByAllProduct.maximum_buy;
      let diskon = promoNominalByAllProduct.value;
      let berlakuKelipatan = promoNominalByAllProduct.multiple_flag;
      if (latestProductSales.qty < parseInt(minimum)){
        detailPromo += 'Belum memenuhi minimum belanja.';
      }
      else{
        detailPromo +='Sudah memenuhi minimum belanja.';
        if(berlakuKelipatan=='1'){
          detailPromo +='Berlaku kelipatan.';
          if(maximum > 0 && latestProductSales.qty > maximum){
            detailPromo +='Sudah mencapai maximum pembelian : '+maximum+'.';
            diskon = diskon * maximum;
          }
          else{
          diskon = diskon * Math.floor(latestProductSales.qty/minimum);
        }
        }
        else{
          detailPromo += 'Tidak berlaku kelipatan.';
        }
        detailPromo += 'Mendapat diskon sebesar:'+parseInt(diskon);
        latestProductSales.valPromoNominal =  parseInt(diskon);
        latestProductSales.nett =  parseInt(latestProductSales.nett)-parseInt(diskon);
        latestProductSales.multipleFlag =  parseInt(berlakuKelipatan);
      }
      //console.log(detailPromo);
    }
    // check jika ada promo potongan by produk untuk produk tertentu
    let promoNominalByProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'NOMINAL' && x.product_id !== null && x.promo_based_on == 3);

    if(promoNominalByProduct.length > 0){
      let detailPromoNominalSelectedProduct = 'Ada promo potongan by per produk untuk produk tertentu.';
      for(let a =0; a< promoNominalByProduct.length;a++){
        let minimum = parseInt(promoNominalByProduct[a].minimum_buy);
        let maximum = parseInt(promoNominalByProduct[a].maximum_buy);
        let diskon = parseInt(promoNominalByProduct[a].value);
        let berlakuKelipatan = promoNominalByProduct[a].multiple_flag;
        let product = promoNominalByProduct[a].product_id;
        let arrProduct = [];
        arrProduct= product.split(';');
        let checkProduct =arrProduct.find(x => x==pcode);
        if(checkProduct != undefined){
          if (latestProductSales.qty < minimum){
            detailPromoNominalSelectedProduct+='Belum memenuhi minimum belanja.';
          }
          else{
            detailPromoNominalSelectedProduct+='Sudah memenuhi minimum belanja.';
            if(berlakuKelipatan=='1'){
              detailPromoNominalSelectedProduct+='Berlaku kelipatan.';
              if (maximum > 0 && latestProductSales.qty > maximum){
                diskon = diskon * maximum;
              }
              else{
                diskon = diskon * Math.floor(latestProductSales.qty/minimum);
              }
            }
            else{
              detailPromoNominalSelectedProduct+='Tidak belaku kelipatan.';
            }
            detailPromoNominalSelectedProduct+='Mendapat diskon sebesar:'+diskon;
            latestProductSales.valPromoNominal =  diskon;
            latestProductSales.nett =  (parseInt(latestProductSales.nett)-diskon);
            latestProductSales.multipleFlag =  parseInt(berlakuKelipatan);
          }
        }
      }
      //console.log(detailPromoNominalSelectedProduct);
    }
  }

  // CHECK PROMO NOMINAL BY SELECTED PRODUCT
  checkPromoPercentBySelectedProduct(latestProductSales:any) {
    let pcode = latestProductSales.id;
    // check jika ada promo potongan persentase by nominal belanja per produk untuk semua produk
    let promoPersentaseAllProduct =this.promotionDetailList.find(x => x.promotion_type_name === 'PERSENTASE' && x.product_id === null && x.promo_based_on == 2);
    if(promoPersentaseAllProduct){
      let detailPromoPercentAllProduct = 'Ada promo persentase product untuk semua produk.';
      let minimum = promoPersentaseAllProduct.minimum_transaction;
      let max_discount = parseInt(promoPersentaseAllProduct.max_discount);
      let diskonValue = promoPersentaseAllProduct.value;
      let totalPaymentProduct = latestProductSales.price * latestProductSales.qty;
      let diskon = Math.floor(parseInt(diskonValue)/100 * totalPaymentProduct);
      if (totalPaymentProduct < parseInt(minimum)){
        detailPromoPercentAllProduct+='Belum memenuhi minimum belanja.';
      }
      else{
        detailPromoPercentAllProduct+='Sudah memenuhi minimum belanja.';
        if (max_discount >0  && diskon > max_discount){
          diskon = max_discount;
        }
        detailPromoPercentAllProduct+='Mendapat diskon sebesar:'+parseInt(diskonValue) +'%';
        latestProductSales.valPromoPercentage = parseInt(diskonValue);
        latestProductSales.nett = totalPaymentProduct - diskon;
        latestProductSales.maxDiscount = diskon;
      }
      //console.log(detailPromoPercentAllProduct);
    }

    // check jika ada promo potongan persentase by nominal belanja per produk untuk produk tertentu
    let promoPersentaseSelectedProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'PERSENTASE' && x.product_id != null && x.promo_based_on == 2);
    
    if(promoPersentaseSelectedProduct.length >0){
      let detailPromoPercentSelectedProduct = 'Ada promo persentase product untuk produk tertentu.';
      for (let a=0;a<promoPersentaseSelectedProduct.length;a++){
        let minimum = promoPersentaseSelectedProduct[a].minimum_transaction;
        let diskonValue = promoPersentaseSelectedProduct[a].value;
        let totalPaymentProduct = latestProductSales.price * latestProductSales.qty;
        let diskon = Math.floor(parseInt(diskonValue)/100 * totalPaymentProduct);
        let product = promoPersentaseSelectedProduct[a].product_id;
        let arrProduct = [];
        arrProduct= product.split(';');
        let checkProduct =arrProduct.find(x => x== pcode);
        if(checkProduct != undefined){
          if (totalPaymentProduct < parseInt(minimum)){
            detailPromoPercentSelectedProduct+='Belum memenuhi minimum belanja.';
          }
          else{
            detailPromoPercentSelectedProduct+='Sudah memenuhi minimum belanja.';
            detailPromoPercentSelectedProduct+='Mendapat diskon sebesar:'+parseInt(promoPersentaseSelectedProduct[a].value) +'%.';
            let max_discount = promoPersentaseSelectedProduct[a].max_discount;
            detailPromoPercentSelectedProduct+='\nMaximum diskon :'+max_discount;
            if (max_discount >0  && diskon > parseInt(max_discount)){
              diskon = max_discount;
            }
            latestProductSales.valPromoPercentage = parseInt(diskonValue);
            latestProductSales.nett = totalPaymentProduct - diskon;
            latestProductSales.maxDiscount = diskon;
          }
        }
      }
     // console.log(detailPromoPercentSelectedProduct);
    }
  }

  // CHECK PROMO FREE ITEM (ITEM)
  checkPromoFreeItemByItem(latestProductSales:any) {
    let pcode = latestProductSales.id;
    let product_name = latestProductSales.name;
    // check jika ada promo free item by item untuk semua produk
    let promoFreeItemItemAllProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM (ITEM)' && x.product_id == null);
    if(promoFreeItemItemAllProduct.length > 0){
      let detailPromoFreeItemItemAllProduct = 'Ada promo free item by item untuk semua produk.';
      for(let a =0;a< promoFreeItemItemAllProduct.length;a++){
        let idpromodetail = promoFreeItemItemAllProduct[a].id;
        let idpromo = promoFreeItemItemAllProduct[a].promotion_id;
        let minimum = promoFreeItemItemAllProduct[a].minimum_buy;
        let multipleFlag = promoFreeItemItemAllProduct[a].multiple_flag;
        let free = promoFreeItemItemAllProduct[a].free_qty;
        let free_item_type = promoFreeItemItemAllProduct[a].free_item_type;
        if(latestProductSales.qty < parseInt(minimum)){
          detailPromoFreeItemItemAllProduct+='Belum memenuhi minimum belanja.';
          free = 0;
        }
        else{
          detailPromoFreeItemItemAllProduct+='Sudah memenuhi minimum belanja.';
          if(multipleFlag=='1'){
            detailPromoFreeItemItemAllProduct+='Berlaku kelipatan.';
            free = free * Math.floor(latestProductSales.qty/parseInt(minimum));
          }
          else{
            detailPromoFreeItemItemAllProduct+='Tidak belaku kelipatan';
          }
          
          let maximum = promoFreeItemItemAllProduct[a].maximum_free_item;
          detailPromoFreeItemItemAllProduct+='\nMaksimum hadiah :'+maximum;
          if(maximum>0 && free> maximum){
            free = maximum;
          }
          latestProductSales.getFree = free;
          latestProductSales.promo= promoFreeItemItemAllProduct;
          if(free_item_type==1){
            let promotionFreeItemSameAsProductScan: any[] = [];
            promotionFreeItemSameAsProductScan.push({
              'id' :idpromo,
              'product_id': pcode,
              'product_name':product_name,
              'promotion_detail_id': idpromodetail
            });
            latestProductSales.free_item_list = promotionFreeItemSameAsProductScan;
          }
          else{
            latestProductSales.free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
          }
        }

        let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail &&  x.product_id === pcode);
         
        if(checkArray){
          let index = this.arrayFreeItemUsed.indexOf(checkArray);
          this.arrayFreeItemUsed[index]['free_item_max']=free;
          if(free==0){            
            this.arrayFreeItemUsed[index]['free_item_used'] = 0;
            for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
              this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
            }
          }
          if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
            this.arrayFreeItemUsed[index]['free_item_used'] = 0;
            for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
              this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
            }
          }
          this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);  
        }
        else{
          this.arrayFreeItemUsed.push({
            product_id:pcode,
            promo_id: idpromo,
            promo_id_detail: idpromodetail,
            free_item_max: free,
            free_item_remaining: free,
            free_item_used: 0,
            array_item:[]
          });
        }    
      }
      this.arraySum = [];
      for(let a=0;a<this.arrayFreeItemUsed.length;a++){
        let lengthFreeItem = this.arrayFreeItemUsed[a]['array_item'].length;
        if (lengthFreeItem > 0){
          for(let b=0;b<lengthFreeItem;b++){            
            let checkProduct =this.arraySum.find(x => x.product_id==this.arrayFreeItemUsed[a]['array_item'][b]['product_id']);
            
            if(checkProduct){
              checkProduct.qty +=this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
            }
            else{
              this.arraySum.push({
                  'product_id' :this.arrayFreeItemUsed[a]['array_item'][b]['product_id'],
                  'qty' :this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']
                });
            }
          }
        }
      }
      if(this.arraySum.length>0){
        for(let a=0;a<this.arraySum.length;a++){
          let checkProduct =this.productSales.find(x => x.id==this.arraySum[a]['product_id']);
          
          let indexProductSales = this.productSales.indexOf(checkProduct);
          if(checkProduct){
            checkProduct.qtyFree = this.arraySum[a]['qty'];
            for(let b=0;b<checkProduct.arrayPromoFreeItem.length;b++){
              if(checkProduct.arrayPromoFreeItem[b]['promotion_id']==checkProduct.promotionFreeItemId && 
              checkProduct.arrayPromoFreeItem[b]['product_scan']==pcode){
                checkProduct.arrayPromoFreeItem[b]['qty']=this.arraySum[a]['qty'];
              }
            }
            if(checkProduct.qtyFree==0 && checkProduct.qty==0){
              this.productSales.splice(indexProductSales, 1);
            }
          }
        }
      }
      //console.log(detailPromoFreeItemItemAllProduct);      
    }
    // check jika ada promo free item by item untuk produk tertentu
    let promoFreeItemItemSelectedProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM (ITEM)' && x.product_id !== null);
    if(promoFreeItemItemSelectedProduct.length > 0){
      let detailPromoFreeItemItemSelectedProduct ='Ada promo free item (item) selected product.';
      for(let a =0; a < promoFreeItemItemSelectedProduct.length;a++){
        let idpromodetail = promoFreeItemItemSelectedProduct[a].id;
        let idpromo = promoFreeItemItemSelectedProduct[a].promotion_id;
        let minimum = promoFreeItemItemSelectedProduct[a].minimum_buy;
        let multipleFlag = promoFreeItemItemSelectedProduct[a].multiple_flag;
        let free = promoFreeItemItemSelectedProduct[a].free_qty;
        let product = promoFreeItemItemSelectedProduct[a].product_id;
        let free_item_type = promoFreeItemItemSelectedProduct[a].free_item_type;
        
        let arrProduct = [];
        arrProduct= product.split(';');
        let checkProduct =arrProduct.find(x => x==pcode);
        if(checkProduct != undefined){
          product = checkProduct;
        }
        if(product==pcode){
          if(latestProductSales.qty < parseInt(minimum)){
            detailPromoFreeItemItemSelectedProduct+='Belum memenuhi minimum belanja.';
            free = 0;
          }
          else{
            detailPromoFreeItemItemSelectedProduct+='Sudah memenuhi minimum belanja.';
            if(multipleFlag=='1'){
              detailPromoFreeItemItemSelectedProduct+='Berlaku kelipatan.';
              free = free * Math.floor(latestProductSales.qty/parseInt(minimum));
            }
            else{
              detailPromoFreeItemItemSelectedProduct+='Tidak belaku kelipatan';
            }
            detailPromoFreeItemItemSelectedProduct+='Mendapat free '+free+' item.';
            
            let maximum = promoFreeItemItemSelectedProduct[a].maximum_free_item;
            detailPromoFreeItemItemSelectedProduct+='\nMaksimum hadiah :'+maximum;
            if(maximum>0 && free> maximum){
              free = maximum;
            }
          }
            let countFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === pcode && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
            let freeItemRemaining = 0;
            if(countFreeItem) {
              let index = this.arrayFreeItemUsed.indexOf(countFreeItem);
              this.arrayFreeItemUsed[index]['free_item_max'] = free;
              //NEW UPDATE
              if(free==0){            
                this.arrayFreeItemUsed[index]['free_item_used'] = 0;
                for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                  this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
                }
              }
              if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
                this.arrayFreeItemUsed[index]['free_item_used'] = 0;
                for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                  this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
                }
              }
              // END OF NEW UPDATE
              let used = this.arrayFreeItemUsed[index]['free_item_used'];              
              freeItemRemaining = parseInt(free)-parseInt(used);
              this.arrayFreeItemUsed[index]['free_item_remaining'] =freeItemRemaining;
            }
            else{
              freeItemRemaining = free;
              this.arrayFreeItemUsed.push({
                product_id:pcode,
                promo_id: idpromo,
                promo_id_detail: idpromodetail,
                free_item_max: free,
                free_item_remaining: free,
                free_item_used: 0,
                array_item:[]
              });
            }
            if(freeItemRemaining==0){
              free = 0;
            }
            latestProductSales.getFree = free;
            latestProductSales.promo= promoFreeItemItemSelectedProduct;
            if(free_item_type==1){
              let promotionFreeItemSameAsProductScan: any[] = [];
              promotionFreeItemSameAsProductScan.push({
                'id' :idpromo,
                'product_id': pcode,
                'product_name':product_name,
                'promotion_detail_id': idpromodetail
              });
              latestProductSales.free_item_list = promotionFreeItemSameAsProductScan;
            }
            else{
             latestProductSales.free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);        
            }
          
        }
      }
      this.arraySum = [];
      for(let a=0;a<this.arrayFreeItemUsed.length;a++){
        let lengthFreeItem = this.arrayFreeItemUsed[a]['array_item'].length;
        if (lengthFreeItem > 0){
          for(let b=0;b<lengthFreeItem;b++){            
            let checkProduct =this.arraySum.find(x => x.product_id==this.arrayFreeItemUsed[a]['array_item'][b]['product_id']);
            
            if(checkProduct){
              checkProduct.qty +=this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
            }
            else{
              this.arraySum.push({
                  'product_id' :this.arrayFreeItemUsed[a]['array_item'][b]['product_id'],
                  'qty' :this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']
                });
            }
          }
        }
      }
      if(this.arraySum.length>0){
        for(let a=0;a<this.arraySum.length;a++){
          let checkProduct =this.productSales.find(x => x.id==this.arraySum[a]['product_id']);
          
          let indexProductSales = this.productSales.indexOf(checkProduct);
          if(checkProduct){
            checkProduct.qtyFree = this.arraySum[a]['qty'];
            for(let b=0;b<checkProduct.arrayPromoFreeItem.length;b++){
              if(checkProduct.arrayPromoFreeItem[b]['promotion_id']==checkProduct.promotionFreeItemId && 
              checkProduct.arrayPromoFreeItem[b]['product_scan']==pcode){
                checkProduct.arrayPromoFreeItem[b]['qty']=this.arraySum[a]['qty'];
              }
            }
            if(checkProduct.qtyFree==0 && checkProduct.qty==0){
              this.productSales.splice(indexProductSales, 1);
            }
          }
        }
      }
      //console.log(detailPromoFreeItemItemSelectedProduct);
    }
  // END OF PROMO FREE ITEM (ITEM);
  }
 checkStockFreeItem( productId: any, promotionId:any) {
    let stockItemPromo: any = 0;
    let promoWarehouseId: any = 0;
    let idPromotionDetail: any = 0;
    let freeQty: any = 0;
    let promoId: any = this.promotionDetailList.find(x => x.id == promotionId);
    let warehouseId = promoId.warehouse_id
    let checkStock: any ;
    checkStock = this.stockFreeItem.find(x => x.product_id === productId);

    if( promoId){
      if( promoId.free_item_type==1){ //bonus produk = produk scan
        this.utilService.loadingPresent('Harap tunggu...')
        .then( () => {
          let options ={
            "token": this.token,
            "warehouse_id" : warehouseId,
            "product_id" : productId
          }
          this.posResellerService.checkStock(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          if (response.status.code == 200) {
            if(response.results) {
              checkStock =response.results.stock_data[0];
              if(checkStock) {
                let stockNow = checkStock["end_stock"];
                if(stockNow > 0) {
                  let bonusItemQty: number = 0;
                  stockItemPromo = stockNow;
                  promoWarehouseId = warehouseId;
                  idPromotionDetail = promoId.id;
                  bonusItemQty = promoId.free_qty;
                  if((parseInt(stockItemPromo) - bonusItemQty) < 0) {
                    let message = 'Stock promo untuk produk ' + checkStock.product_name + ' tersisa: ' + stockItemPromo;
                    this.showAlertBarcodeNotValid(message);
                    freeQty = stockItemPromo;
                  } else {
                    freeQty = bonusItemQty;
                  }
                } else {
                  let message = 'Stock free item telah habis';
                  this.showAlertBarcodeNotValid(message);
                }
              } else {
                let message = 'Stock free item tidak ada.';
                this.showAlertBarcodeNotValid(message);
              }
              if(freeQty > 0) {
                if (this.mstProductList[parseInt(productId)] !== undefined) {
                  let checkProductSales = this.productSales.find(x => x.id === productId);
                  if(checkProductSales) {
                    let checkFreeItemUsed= this.arrayFreeItemUsed.find(x => x.product_id === productId  && parseInt(x.promo_id_detail) === parseInt(idPromotionDetail));
                    let used = 0;
                    let index = this.productSales.indexOf(checkProductSales);
                    let updateQtyFree = parseInt(this.productSales[index]['qtyFree']);
                    if(checkFreeItemUsed){
                      let idx = this.arrayFreeItemUsed.indexOf(checkFreeItemUsed);
                      used = this.arrayFreeItemUsed[idx]['free_item_used'];
                      let max = this.arrayFreeItemUsed[idx]['free_item_max'];
                      freeQty = parseInt(max) -used;
                      this.arrayFreeItemUsed[idx]['free_item_used'] = freeQty;
                      updateQtyFree =updateQtyFree+parseInt(freeQty)
                      let remainingFree = parseInt(max)-updateQtyFree;
                      this.arrayFreeItemUsed[idx]['free_item_used'] = updateQtyFree;
                      this.arrayFreeItemUsed[idx]['free_item_remaining'] = remainingFree;
                    }
                    this.productSales[index]['qtyFree'] = updateQtyFree;
                    this.productSales[index]['getFree'] = 0;
                  }
                }
              }
              this.totalItem = 0;
              for (let i=0;i < this.productSales.length;i++) {
                this.totalItem += (parseInt(this.productSales[i].qty) + parseInt(this.productSales[i].qtyFree));
              }
            }
          }
        }, () => {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan Stock' }).then(t => t.present());
        });
      });
      }
      else{
        if(checkStock) {
          let arrayStock: any[] = [];
          arrayStock.push(checkStock);
          let convertDate = this.utilService.convertDate(new Date());
          let month = convertDate.months;
          let fieldEnd = "end_" + month;
          let stockNow = arrayStock[0][fieldEnd];
    
          if(stockNow > 0) {
            let bonusItemQty: number = 0;
            stockItemPromo = arrayStock[0][fieldEnd];
            promoWarehouseId = arrayStock[0]["warehouse_id"];
            idPromotionDetail = promoId.id;
            bonusItemQty = promoId.free_qty;
            if((parseInt(stockItemPromo) - bonusItemQty) < 0) {
              let message = 'Stock promo untuk produk ' + checkStock.product_name + ' tersisa: ' + stockItemPromo;
              this.showAlertBarcodeNotValid(message);
              freeQty = stockItemPromo;
            } else {
              freeQty = bonusItemQty;
            }
          } else {
            let message = 'Stock free item telah habis.sss';
            this.showAlertBarcodeNotValid(message);
          }
        } else {
          let message = 'Stock free item tidak ada.';
          this.showAlertBarcodeNotValid(message);
        }
        return {
          'promo_warehouse_id': promoWarehouseId,
          'promotion_detail_id': idPromotionDetail,
          'free_qty': freeQty
        };
      }
    }
  }

  clearTransaction() {
    this.productSales = new Array();
    this.transactionTime = '';
    this.totalItem = 0;
    this.totalPayment = 0;
    this.transactionDiscount = 0;
    this.customerCash = 0;
    this.customerTransfer = 0;
    this.totalCustomerCash = 0;
    this.customerCredit = 0;
    this.customerDebit = 0;
    this.totalCustomerPay = 0;
    this.totalChange = 0;
    this.totalChangeUSD = 0;
    this.totalChangeCNY = 0;
    this.valueWeChat = 0;
    this.valueCNY = 0;
    this.valueUSD = 0;
    this.voucherValue = 0;
    this.customerCreditId = undefined;
    this.customerDebitId = undefined;
    this.foreignCurrencyId = undefined;
    this.edcId = undefined;
    this.ppn = 0;
    this.memberId = undefined;
    this.memberPhone = '';
    this.memberPointValue = 0;
    this.memberPointUsed = 0;
    this.totalPointAdd = 0;
    this.pointMutationType = '';
    this.voucherUseList = [];

    this.countFreeItem = 0;
    this.promoItemNominal = '';
    this.message = '';
    this.promotionFreeItemList = [];
    this.arrayFreeItemUsed = [];

    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }

  saveTransactionTemp() {
    this.productSalesTemp = this.productSales;
    this.transactionTimeTemp = this.transactionTime;
    this.totalItemTemp = this.totalItem;
    this.totalPaymentTemp = this.totalPayment;
    this.transactionDiscountTemp = this.transactionDiscount;
    this.customerCashTemp = this.customerCash;
    this.totalCustomerCashTemp = this.totalCustomerCash;
    this.customerCreditTemp = this.customerCredit;
    this.customerDebitTemp = this.customerDebit;
    this.totalCustomerPayTemp = this.totalCustomerPay;
    this.totalChangeTemp = this.totalChange;
    this.totalChangeUSDTemp = this.totalChangeUSD;
    this.totalChangeCNYTemp = this.totalChangeCNY;
    this.valueWeChatTemp = this.valueWeChat;
    this.valueCNYTemp = this.valueCNY;
    this.valueUSDTemp = this.valueUSD;
    this.voucherValueTemp = this.voucherValue;
    this.customerCreditIdTemp = this.customerCreditId;
    this.customerDebitIdTemp = this.customerDebitId;
    this.foreignCurrencyIdTemp = this.foreignCurrencyId;
    this.edcIdTemp = this.edcId;
    this.ppnTemp = this.ppn;
    this.memberIdTemp = this.memberId;
    this.memberPhoneTemp = this.memberPhone;
    this.memberPointValueTemp = this.memberPointValue;
    this.memberPointUsedTemp = this.memberPointUsed;
    this.totalPointAddTemp = this.totalPointAdd;
    this.pointMutationTypeTemp = this.pointMutationType;
    this.voucherUseListTemp = this.voucherUseList;
  }

  addQty(index: any) {
    const qtyProduct = this.productList[index].qty;
    const productPrice = this.productList[index].totalPrice;
    const newPrice = parseInt(productPrice) + parseInt(this.productList[index].price);
    this.totalPayment += parseInt(this.productList[index].price);
    const newQty = qtyProduct + 1;
    this.productList[index].qty = newQty;
    this.productList[index].totalPrice = newPrice;
  }

  removeQty(index: any) {
    const qtyProduct = this.productList[index].qty;
    const productPrice = this.productList[index].totalPrice;
    if(qtyProduct >= 2) {
      const newPrice = parseInt(productPrice) - parseInt(this.productList[index].price);
      this.totalPayment -= parseInt(this.productList[index].price);
      const newQty = qtyProduct - 1;
      this.productList[index].qty = newQty;
      this.productList[index].totalPrice = newPrice;
    } else {
      this.totalPayment -= parseInt(this.productList[index].price);
      this.productList.splice(index,1);
    }
  }

  removeProduct(index: any) {
    this.totalPayment -= parseInt(this.productList[index].totalPrice);
    this.productList.splice(index,1);
  }

  calcTotal() {
    this.totalPayment = 0;
    this.totalItem = 0;
    this.totalPaymentWithoutPromo = 0;

    for (let i=0;i < this.productSales.length;i++) {
      this.totalPaymentWithoutPromo += (this.productSales[i].price * this.productSales[i].qty);
      this.totalPayment += this.productSales[i].nett;
      this.totalItem += (parseInt(this.productSales[i].qty) + parseInt(this.productSales[i].qtyFree));
    }
    
    /* CHECK PROMO BERDASARKAN TOTAL BELANJA */
    // check jika ada promo potongan by nominal belanja
    let promoNominal =this.promotionDetailList.find(x => x.promotion_type_name === 'NOMINAL' && x.product_id === null && x.promo_based_on == 1);
    
    if(promoNominal){
      let detailPromoNominal = 'ada promo nominal.';
      let minimum = promoNominal.minimum_transaction;
      let diskon = promoNominal.value;
      let max_discount = promoNominal.max_discount;
      let berlakuKelipatan = promoNominal.multiple_flag;
      if (this.totalPayment < parseInt(minimum)){
        detailPromoNominal += ' Belum memenuhi minimum belanja';
        diskon =0;
      }
      else{
        detailPromoNominal += '. Sudah memenuhi minimum belanja';
        if(berlakuKelipatan=='1'){
          detailPromoNominal += '. Berlaku kelipatan';
          diskon = diskon * Math.floor(this.totalPayment/minimum);
        }
        else{
          detailPromoNominal += '. Tidak belaku kelipatan';
        }
        
        if (max_discount >0  && diskon > max_discount){
          diskon = max_discount;
        }
        detailPromoNominal +='\nMendapat diskon sebesar:'+parseInt(diskon);
        detailPromoNominal +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
       
      }
      this.transactionDiscount = parseInt(diskon);
      this.totalPayment = this.totalPayment - diskon;
      //console.log(detailPromoNominal);
    }
    // check jika ada promo potongan (persen) by nominal belanja
    let promoPersen =this.promotionDetailList.find(x => x.promotion_type_name === 'PERSENTASE' && x.product_id === null && x.promo_based_on == 1);
    
    console.log(this.promotionDetailList);
    console.log(promoPersen);
    if(promoPersen){
      let detailPromoPersen = 'Ada promo persentase.';
      let minimum = promoPersen.minimum_transaction;
      let diskon = promoPersen.value;
      let max_discount = promoPersen.max_discount;
      diskon = (parseInt(diskon)/100) * this. totalPayment;
      diskon = Math.floor(diskon);
      if (max_discount >0  && diskon > max_discount){
        diskon = max_discount;
      }
      if (this.totalPayment < parseInt(minimum)){
        detailPromoPersen+= 'Belum memenuhi minimum belanja.';
        diskon =0;
      }
      else{
        detailPromoPersen+='Sudah memenuhi minimum belanja.';
        detailPromoPersen+='Mendapat diskon sebesar:'+promoPersen.value +'%';
      }
      this.transactionDiscount = diskon;
      this.totalPayment = this.totalPayment - diskon;
      console.log(detailPromoPersen);
    }
  }

  async getChangeNominal() {
    let valUSDtoIDR: number = this.exchangeRateUSD * this.valueUSD;
    let valCNYtoIDR: number = this.exchangeRateCNY * this.valueCNY;
    let valWeChattoIDR: number = this.exchangeRateWeChat * this.valueWeChat;
    let rewardPakai: number = this.rewards.downline.reward.pakai;

    //reseller_code_sign
    if(rewardPakai > this.rewards.downline.reward.sisa) {
      const alert = await this.alertController.create({
        header: 'Notification',
        cssClass:'custom-alert-class',
        message: 'Pemakaian reward melebihi sisa reward',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.rewards.downline.reward.pakai = 0;
            }
          }
        ]
      });
      await alert.present();
      return false;
    }

    if(this.voucherValue !== 0) {
      let value: any = 0;
      for(let i = 0; i < this.voucherUseList.length; i++) {
        value = value + parseInt(this.voucherUseList[i].nominal);
      }

      if(value > this.totalPayment) {
        this.voucherValue = this.totalPayment;
      } else {
        this.voucherValue = value;
      }
    }

    if(this.memberPointValue !== 0) {
      if(this.memberPointValueUsed > this.totalPayment) {
        this.memberPointValue = this.totalPayment;
      } else {
        this.memberPointValue = this.memberPointValueUsed;
      }
    }

    this.totalCustomerPay = this.customerCash + this.customerTransfer  + this.customerCredit + this.customerDebit + this.voucherValue +
      this.memberPointValue + valUSDtoIDR + valCNYtoIDR + valWeChattoIDR + rewardPakai;

    this.totalCustomerCash = this.customerCash + valUSDtoIDR + valCNYtoIDR + valWeChattoIDR;

    let change: number = this.totalCustomerPay - this.totalPayment;
    this.totalChange = change;

    let changeUSD: any = change / this.exchangeRateUSD;
    let changeCNY: any = change / this.exchangeRateCNY;
    this.totalChangeUSD = parseFloat(changeUSD).toFixed(2);
    this.totalChangeCNY = parseFloat(changeCNY).toFixed(2);
  }

  getCreditName() {
    let check: any = this.creditBankList.find(x => x.id === this.customerCreditId);
    if(check) {
      this.customerCreditName = check.payment_name;
    }
  }

  getDebitName() {
    let check: any = this.debitBankList.find(x => x.id === this.customerDebitId);
    if(check) {
      this.customerDebitName = check.payment_name;
    }
  }

  getForeignCurrencyName() {
    let check: any = this.foreignCurrencyList.find(x => x.id === this.foreignCurrencyId);
    if(check) {
      this.foreignCurrencyName = check.foreign_currency_name;
      this.foreignCurrencyRate = check.exchange_rate;
    }
  }

  clearNonCash() {
    this.customerCreditId = undefined;
    this.customerDebitId = undefined;
    this.edcId = undefined;

    this.getChangeNominal();
  }

  getLabelPrintNominal(nominal: any): String {
    let nominalString = new String(nominal);
    let totalLength: number = 12;

    let diff: number = totalLength - nominalString.length;
    let lblNominal: String = '';

    if(diff !== 0) {
      for(let i = 0; i < diff; i++) {
        lblNominal = lblNominal + ' ';
      }

      lblNominal = lblNominal + nominalString.toString();
    } else {
      lblNominal = nominalString;
    }

    return lblNominal;
  }

  getLblNett(nett: String, priceAndQtylength: number): String {
    let lblNominal: String = '';
    let receiptRowLength: number = 33;

    let nettLength: number = nett.length;
    let diff: number = receiptRowLength - (nettLength + priceAndQtylength);
    for(let i = 0; i < diff; i++) {
      lblNominal = lblNominal + ' ';
    }
    lblNominal = lblNominal + nett.toString();
    return lblNominal;
  }

  getLabelDppAndPpn(label: String, lblLength: number): String {
    let lblNominal: String = '';
    let receiptRowLength: number = 33;
    let frontLblLength: number = 6;

    let diff: number = receiptRowLength - (frontLblLength + lblLength);
    for(let i = 0; i < diff; i++) {
      lblNominal = lblNominal + ' ';
    }
    lblNominal = lblNominal + label.toString();
    return lblNominal;
  }

  getLabelTotalItem(nominal: any): String {
    let nominalString = new String(nominal);
    let label: String = 'TOTAL ITEM ' + nominalString + ' : ';
    let lblNominal: String = '';

    let totalLength: number = label.length;
    let diff: number = 21 - totalLength;
    if(diff !== 0) {
      let space: any = '';
      for(let i = 0; i < diff; i++) {
        space = space + ' ';
      }

      lblNominal = space + label;
    } else {
      lblNominal = label;
    }

    return lblNominal;
  }

  getLabelHeaderReceipt(headerText: String) {
    let totalLength: number = headerText.length;
    let maxTextLengthforReceipt = 33;
    let resultText = "";
    if(totalLength > maxTextLengthforReceipt) {
      resultText = headerText.slice(0, maxTextLengthforReceipt);
    } else {
      let diff = maxTextLengthforReceipt - totalLength;
      let divide = (diff - (diff % 2)) / 2;
      let space = "";
      for(let i = 0; i < divide; i++) {
        space = space + " ";
      }
      
      resultText = space + headerText + space;
    }

    return resultText;
  }

  showOtherPayment() {
    this.valueUSD = 0;
    this.valueCNY = 0;
    this.valueWeChat = 0;
    this.foreignCurrencyId = undefined;

    if(this.otherPayment) {
      this.otherPayment = false;
    } else {
      this.otherPayment = true;
    }

    this.getChangeNominal();
  }

  //End => Local Function

  //Start => WebSQL Function

  openDB() {
    /* dihilangkan 02 november 2023
    this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
    */
  }

  createTable(tableName: any, columnList: any) {
    /* dihilangkan 02 november 2023
    let sqlQuery: string = 'CREATE TABLE IF NOT EXISTS ' + tableName + columnList;

    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(sqlQuery, [],
        (tx, result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    });
    */
  }

  async execQuery(sqlQuery: any) {
    /* dihilangkan 02 november 2023
    this.db.transaction((tx) => {
      tx.executeSql(sqlQuery, [],
      (tx, result) => {
      }, (error) => {
        console.log(error);
      });
    });
    */
  }

  formatRupiah(angka: string) {
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
  }
//reseller_code_sign
async getRewardTerm() {
  this.posResellerService.getRewardTerm({ "token": this.token }).subscribe((response) => {
    if (response.status.code == 200) {
      let rewardTerm = response.results.reward_term_data;
      if(rewardTerm) {
        this.rewards.downline.terms.name = rewardTerm[0].reward_term_name;
        this.rewards.downline.terms.minBelanjaPoint.term = rewardTerm[0].term;
        this.rewards.downline.terms.minBelanjaPoint.reward = rewardTerm[0].reward;
        this.rewards.upline.terms.name = rewardTerm[0].reward_term_name;
        this.rewards.upline.terms.minBelanja500rb.term = parseInt(rewardTerm[1].term);
        this.rewards.upline.terms.minBelanja500rb.reward = parseInt(rewardTerm[1].reward);
        this.rewards.upline.terms.reward2setengahPersen.term = rewardTerm[2].term;
        this.rewards.upline.terms.reward2setengahPersen.reward = parseFloat(rewardTerm[2].reward);
      }
    }

    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }, () => {
    this.utilService.loadingDismiss();
    this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Reseller Reward Term! Silahkan refresh!' }).then(t => t.present());
  });
}

async getCustomerDownline() {
  let tableName = 'customer_downlines';
  let columnList = '( id,upline_id,downline_id,currency,reward_value,created_by,updated_by,created_at,updated_at)';
  /* dihilangkan 02 november 2023
    await this.createTable(tableName, columnList).then((res) => {
      console.log('success create table customer_downlines', res);      
  }, (err) => {
      console.log('error create table customer_downlines', err);      
  })
  */
  this.posResellerService.getCustomerDownline({ "token": this.token }).subscribe(async (response) => {
    if (response.status.code == 200) {
      /* dihilangkan 02 november 2023
        let customerDownline = response.results.data;
          await this.execQuery(`DELETE FROM ${tableName}`);
          if(customerDownline.length > 0) {
            for (let i = 0; i < customerDownline.length; i++) {
              let insertQuery = `INSERT INTO ${tableName} ${columnList} VALUES ('${customerDownline[i].id}',
                                                                                '${customerDownline[i].upline_id}',
                                                                                '${customerDownline[i].downline_id}',
                                                                                '${customerDownline[i].currency}',
                                                                                '${customerDownline[i].reward_value}',
                                                                                '${customerDownline[i].created_by}',
                                                                                '${customerDownline[i].updated_by}',
                                                                                '${customerDownline[i].created_at}',
                                                                                '${customerDownline[i].updated_at}')`;
              await this.db.transaction((tx) => {
                tx.executeSql(insertQuery, [],
                (tx, result) => {
                    console.log('success insert table customer_downlines');
                }, (error) => {
                  console.log('error insert table customer_downlines ',error);
                });
              });
            }
          }
          */
    }

    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }, () => {
    this.utilService.loadingDismiss();
    this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Reseller Downline! Silahkan refresh!' }).then(t => t.present());
  });
}

async getRewardMutation() {
  let tableName = 'reward_mutations';
  let columnList = '(id,upline_id,downline_id,sales_transaction_id,reward_mutation_type_id,transaction_date,currency,total_sales,reward_value,reward_percent,created_by,updated_by,created_at,updated_at)';
 
     /* dihilangkan 02 november 2023 await this.createTable(tableName, columnList).then((res) => {
      console.log('success create table reward_mutations', res);      
    }, (err) => {
    console.log('error create table reward_mutations', err);      
  })
  */

  this.posResellerService.getRewardMutation({ "token": this.token }).subscribe(async (response) => {
    if (response.status.code == 200) {
      /* dihilangkan 02 november 2023
          let customerDownline = response.results.data;
          await this.execQuery(`DELETE FROM ${tableName}`);
          if(customerDownline.length > 0) {
            for (let i = 0; i < customerDownline.length; i++) {
              let insertQuery = `INSERT INTO ${tableName} ${columnList} VALUES ('${customerDownline[i].id}',
                                                                                '${customerDownline[i].upline_id}',
                                                                                '${customerDownline[i].downline_id}',
                                                                                '${customerDownline[i].sales_transaction_id}',
                                                                                '${customerDownline[i].reward_mutation_type_id}',
                                                                                '${customerDownline[i].transaction_date}',
                                                                                '${customerDownline[i].currency}',
                                                                                '${customerDownline[i].total_sales}',
                                                                                '${customerDownline[i].reward_value}',
                                                                                '${customerDownline[i].reward_percent}',
                                                                                '${customerDownline[i].created_by}',
                                                                                '${customerDownline[i].updated_by}',
                                                                                '${customerDownline[i].created_at}',
                                                                                '${customerDownline[i].updated_at}')`;
              await this.db.transaction((tx) => {
                tx.executeSql(insertQuery, [],
                (tx, result) => {
                    console.log('success insert table reward_mutations');
                }, (error) => {
                  console.log('error insert table reward_mutations ',error);
                });
              });
            }
          }
          */
    }

    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }, () => {
    this.utilService.loadingDismiss();
    this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Mutasi Reward Reseller! Silahkan refresh!' }).then(t => t.present());
  });
}

async getRewardReseller() {
  let tableName = 'customer_rewards';
  let columnList = '(id,customer_id,currency,reward_value,reward_used,reward_remains,created_by,updated_by,created_at,updated_at)';
  
     /* dihilangkan 02 november 2023await this.createTable(tableName, columnList).then((res) => {
    console.log('success create table customer_rewards', res);
  }, (err) => {
    console.log('error create table customer_rewards', err);
  })
  */
  this.posResellerService.getRewardReseller({ "token": this.token }).subscribe(async (response) => {
    if (response.status.code == 200) {
      /* dihilangkan 02 november 2023
        let customerDownline = response.results.data;
          await this.execQuery(`DELETE FROM ${tableName}`);
          if(customerDownline.length > 0) {
            for (let i = 0; i < customerDownline.length; i++) {
              let insertQuery = `INSERT INTO ${tableName} ${columnList} VALUES ('${customerDownline[i].id}',
                                                                                '${customerDownline[i].customer_id}',
                                                                                '${customerDownline[i].currency}',
                                                                                '${customerDownline[i].reward_value}',
                                                                                '${customerDownline[i].reward_used}',
                                                                                '${customerDownline[i].reward_remains}',
                                                                                '${customerDownline[i].created_by}',
                                                                                '${customerDownline[i].updated_by}',
                                                                                '${customerDownline[i].created_at}',
                                                                                '${customerDownline[i].updated_at}')`;
              await this.db.transaction((tx) => {
                tx.executeSql(insertQuery, [],
                (tx, result) => {
                   // console.log('success insert table customer_rewards');
                }, (error) => {
                  console.log('error insert table customer_rewards ',error);
                });
              });
            }
          }
          */
    }

    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }, () => {
    this.utilService.loadingDismiss();
    this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Reward Reseller! Silahkan refresh!' }).then(t => t.present());
  });
}
  async openSearchProduct() {
    const modal = await this.modalController.create({
      component: SearchProductComponent,
      componentProps: {
        "counterId": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
        'token': this.token
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null || modelData.data.productId !== undefined || modelData.data.productId !== null) {
        this.getProduct(modelData.data.productId);
      }
    });

    return await modal.present();
  }
  
  async runWebSqlReseller() {
    // await this.getMemberList();
    await this.getCustomerDownline();
    await this.getRewardReseller();
    await this.getRewardMutation();
    await this.utilService.loadingDismiss();

  }

  changeZeroDiscount() {
    if(this.transactionDiscount==0){
      this.transactionDiscount = null;
    }
  }
  checkValueDiscount(){
    if(this.transactionDiscount==null){
      this.transactionDiscount = 0;
    }
  }

  changeZeroCash() {
    if(this.customerCash==0){
      this.customerCash = null;
    }
  }
  checkValueCash(){
    if(this.customerCash==null){
      this.customerCash = 0;
    }
  }

  changeZeroTf() {
    if(this.customerTransfer==0){
      this.customerTransfer = null;
    }
  }
  checkValueTf(){
    if(this.customerTransfer==null){
      this.customerTransfer = 0;
    }
  }

  changeZeroCredit() {
    if(this.customerCredit==0){
      this.customerCredit = null;
    }
  }
  checkValueCredit(){
    if(this.customerCredit==null){
      this.customerCredit = 0;
    }
  }

  changeZeroDebit() {
    if(this.customerDebit==0){
      this.customerDebit = null;
    }
  }
  checkValueDebit(){
    if(this.customerDebit==null){
      this.customerDebit = 0;
    }
  }

  changeZeroUsd() {
    if(this.valueUSD==0){
      this.valueUSD = null;
    }
  }
  checkValueUsd(){
    if(this.valueUSD==null){
      this.valueUSD = 0;
    }
  }

  changeZeroCny() {
    if(this.valueCNY==0){
      this.valueCNY = null;
    }
  }
  checkValueCny(){
    if(this.valueCNY==null){
      this.valueCNY = 0;
    }
  }

  changeZeroWeChat() {
    if(this.valueWeChat==0){
      this.valueWeChat = null;
    }
  }
  checkValueWeChat(){
    if(this.valueWeChat==null){
      this.valueWeChat = 0;
    }
  }
}