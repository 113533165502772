import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterProductGimmickPage } from './master-product-gimmick.page';

const routes: Routes = [
  {
    path: '',
    component: MasterProductGimmickPage
  },
  {
    path: 'master-product-gimmick-detail',
    loadChildren: () => import('./master-product-gimmick-detail/master-product-gimmick-detail.module').then( m => m.MasterProductGimmickDetailPageModule)
  },
  {
    path: 'master-product-gimmick-create',
    loadChildren: () => import('./master-product-gimmick-create/master-product-gimmick-create.module').then( m => m.MasterProductGimmickCreatePageModule)
  },
  {
    path: 'master-product-gimmick-edit',
    loadChildren: () => import('./master-product-gimmick-edit/master-product-gimmick-edit.module').then( m => m.MasterProductGimmickEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterProductGimmickPageRoutingModule {}
