import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VoucherEditPageRoutingModule } from './voucher-edit-routing.module';

import { VoucherEditPage } from './voucher-edit.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    VoucherEditPageRoutingModule
  ],
  declarations: [VoucherEditPage]
})
export class VoucherEditPageModule {}
