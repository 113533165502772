import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DisplayCustomerPage } from './display-customer.page';

const routes: Routes = [
  {
    path: '',
    component: DisplayCustomerPage
  },
  {
    path: 'slider-list',
    loadChildren: () => import('./slider-list/slider-list.module').then( m => m.SliderListPageModule)
  },
  {
    path: 'slider-form',
    loadChildren: () => import('./slider-form/slider-form.module').then( m => m.SliderFormPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DisplayCustomerPageRoutingModule {}
