import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UtilService } from '../../../service/util.service';
import { environment } from '../../../../environments/environment';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.page.html',
  styleUrls: ['./customer-create.page.scss'],
})
export class CustomerCreatePage implements OnInit {

  baseUrl: any;
  token: any;
  formCustomerCreate: FormGroup;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private http: HttpClient,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private userData: UserData,
    private router: Router
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
    this.buildFormCustomerCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
      }
    });
  }

  buildFormCustomerCreate() {
    this.formCustomerCreate = this.fb.group({
      customerName: [null, Validators.required],
      identityNumber: [null, Validators.required],
      phone: [null, Validators.required]
    });
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  inputCustomer() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const customerData = this.formCustomerCreate.getRawValue();

      const paramsOpts = new HttpParams()
        .set('token', this.token)

      const reqOpts = new HttpHeaders()
        .set('Accept', 'application/json'),
      formData = new FormData();
      formData.append('customer_name', customerData.customerName);
      formData.append('identity_number', customerData.identityNumber);
      formData.append('phone', customerData.phone);
      formData.append('created_by', this.userProfile.name);

      this.http.post(this.baseUrl + 'customer', formData, { headers: reqOpts, params: paramsOpts })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.inputMemberPointData(response.results.data.id);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  inputMemberPointData(id: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const paramsOpts = new HttpParams()
        .set('token', this.token)

      const reqOpts = new HttpHeaders()
        .set('Accept', 'application/json'),
      formData = new FormData();
      formData.append('customer_id', id);
      formData.append('created_by', this.userProfile.name);

      this.http.post(this.baseUrl + 'member-point', formData, { headers: reqOpts, params: paramsOpts })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/customer']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
