import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, ToastController, ModalController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { ReceiptVoucherService } from './receipt-voucher.service';
import { ReceiptVoucherStatus } from '../../enum/ReceiptVoucherStatus';

@Component({
  selector: 'app-receipt-voucher',
  templateUrl: './receipt-voucher.page.html',
  styleUrls: ['./receipt-voucher.page.scss'],
})
export class ReceiptVoucherPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  rows: any;
  receiptVoucherList: any;
  filteredData: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterId: any = '';

  db: any;

  counterTableName = 'mst_counters';
  counterColumnList = '(id, branch_id, counter_name, trans_date, first_address, last_address, phone, footer_text, latitude, longitude, active, created_by, updated_by, created_at, updated_at)';
  cashBankTableName = 'mst_cash_banks';
  cashBankColumnList = '(id, coa_id, name, created_by, updated_by, created_at, updated_at)';
  coaTableName = 'mst_coas';
  coaColumnList = '(id, name, position, level, parent, created_by, updated_by, created_at, updated_at)';
  currencyTableName = 'mst_foreign_currencies';
  currencyColumnList = '(id, foreign_currency_name, exchange_rate, status, created_by, updated_by, created_at, updated_at)';

  receiptStatus = ReceiptVoucherStatus;
  receiptStatusList = Object.keys(ReceiptVoucherStatus).filter(
    receiptStatus => typeof this.receiptStatus[receiptStatus] === 'number'
  );

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private modalController: ModalController,
    private receiptVoucherService : ReceiptVoucherService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.pageCallback({ offset: this.page.offset });
        this.getLocalData(this.counterTableName, this.counterColumnList, 'counter');
        this.getLocalData(this.cashBankTableName, this.cashBankColumnList, 'cash-bank');
        this.getLocalData(this.coaTableName, this.coaColumnList, 'coa');
        this.getLocalData(this.currencyTableName, this.currencyColumnList, 'currency');
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;

      let options = {
        "token": this.token,
        "page": offset.toString()
      };

      this.receiptVoucherService.getReceiptVoucher(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.receiptVoucherList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  goToReceiptVoucherCreate() {
    this.navCtrl.navigateForward(['/receipt-voucher/receipt-voucher-create']);
  }

  goToDetailPage(id: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        receiptVoucherId: id
      }
    };
    this.navCtrl.navigateForward(['/receipt-voucher/receipt-voucher-edit'], navigationExtras);
  }

  async openAdvancedFilters() {
    // const modal = await this.modalController.create({
    //   component: AfProductReceivingComponent,
    //   componentProps: {}
    // });

    // modal.onDidDismiss().then((modelData) => {
    //   if (modelData.data !== undefined) {
    //     //
    //   } else {
    //     //
    //   }
    // });

    // return await modal.present();
  }

  getLocalData(table: any, column: any, name: any) {
    this.receiptVoucherService.getLocalData(name, { "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          let tableName = table;
          let columnList = column;
          
          this.createTable(tableName, columnList).then((res) => {
            if(tableName === 'mst_counters') {
              for (let i = 0; i < response.results.length; i++) {
                let insertQuery = 'INSERT INTO ' + this.counterTableName + this.counterColumnList + ' VALUES (' +
                  response.results[i].id + ', ' + response.results[i].branch_id + ', "' +
                  response.results[i].counter_name + '", "' +
                  response.results[i].trans_date + '", "' + response.results[i].first_address + '", "' +
                  response.results[i].last_address + '", "' + response.results[i].phone + '", "' + 
                  response.results[i].footer_text + '", "' + response.results[i].latitude + '", "' + 
                  response.results[i].longitude + '", "' + response.results[i].active + '", "' +
                  response.results[i].created_by + '", "' + response.results[i].updated_by + '", "' +
                  response.results[i].created_at + '", "' + response.results[i].updated_at + '")';
                
                this.inputLocalData(insertQuery, this.counterTableName, response.results[i].id);
              }
            }

            if(tableName === 'mst_cash_banks') {
              for (let i = 0; i < response.results.length; i++) {
                let insertQuery = 'INSERT INTO ' + this.cashBankTableName + this.cashBankColumnList + ' VALUES (' +
                  response.results[i].id + ', "' + response.results[i].coa_id + '", "' +
                  response.results[i].name + '", "' + response.results[i].created_by + '", "' + 
                  response.results[i].updated_by + '", "' + response.results[i].created_at + '", "' + 
                  response.results[i].updated_at + '")';
                
                this.inputLocalData(insertQuery, this.cashBankTableName, response.results[i].id); 
              }
            }

            if(tableName === 'mst_coas') {
              for (let i = 0; i < response.results.length; i++) {
                let insertQuery = 'INSERT INTO ' + this.coaTableName + this.coaColumnList + ' VALUES (' +
                  response.results[i].id + ', "' + response.results[i].name + '", ' +
                  response.results[i].position + ', ' + response.results[i].level + ', ' +
                  response.results[i].parent + ', "' + response.results[i].created_by + '", "' + 
                  response.results[i].updated_by + '", "' + response.results[i].created_at + '", "' + 
                  response.results[i].updated_at + '")';
                  
                this.inputLocalData(insertQuery, this.coaTableName, response.results[i].id);
              }
            }

            if(tableName === 'mst_foreign_currencies') {
              for (let i = 0; i < response.results.length; i++) {
                let insertQuery = 'INSERT INTO ' + this.currencyTableName + this.currencyColumnList + ' VALUES (' +
                  response.results[i].id + ', "' + response.results[i].foreign_currency_name + '", ' +
                  response.results[i].exchange_rate + ', "' + response.results[i].status + '", "' +
                  response.results[i].created_by + '", "' + response.results[i].updated_by + '", "' +
                  response.results[i].created_at + '", "' + response.results[i].updated_at + '")';
                  
                this.inputLocalData(insertQuery, this.currencyTableName, response.results[i].id);
              }
            }
          }, (err) => {
            console.log(err);
          });
        }
      }
    }, () => {
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  createTable(tableName: any, columnList: any) {
    let sqlQuery: string = 'CREATE TABLE IF NOT EXISTS ' + tableName + columnList;

    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(sqlQuery, [],
        (tx, result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  execQuery(sqlQuery: any) {
    this.db.transaction((tx) => {
      tx.executeSql(sqlQuery, [],
      (tx, result) => {
      }, (error) => {
        console.log(error);
      });
    });
  }

  inputLocalData(insertQuery: any, tableName: any, id: any) {
    let selectQuery = 'SELECT * FROM ' + tableName + ' WHERE id = ' + id;
    let deleteQuery = 'DELETE FROM ' + tableName + ' WHERE id = ' + id;

    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
      (tx, result) => {
        if(result.rows.length === 0) {
          this.execQuery(insertQuery);
        } else {
          this.execQuery(deleteQuery);
          this.execQuery(insertQuery);
        }
      }, (error) => {
        console.log(error);
      });
    });
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 6;
    let keys = Object.keys(this.receiptVoucherList[0]);
    this.rows = this.filteredData.filter(function(item){
      for (let i=0; i < columnLength; i++){
        if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });

    this.page.offset = 0;
  }

  checkStatus(status: any) {
    let cssClass: any = '';
    if(status === '0') { cssClass = 'status pending'}
    if(status === '1') { cssClass = 'status ok'}
    if(status === '2') { cssClass = 'status void'}
    return cssClass;
  }

}
