import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ClosedMonthPageRoutingModule } from './closed-month-routing.module';

import { ClosedMonthPage } from './closed-month.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ClosedMonthPageRoutingModule
  ],
  declarations: [ClosedMonthPage]
})
export class ClosedMonthPageModule {}
