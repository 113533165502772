import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReportMemberRegularPageRoutingModule } from './report-member-regular-routing.module';

import { ReportMemberRegularPage } from './report-member-regular.page';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReportMemberRegularPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [ReportMemberRegularPage]
})
export class ReportMemberRegularPageModule {}
