import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class MasterRewardService {
  constructor(private httpService: HttpService) { }
  
  getMasterReward(params: any): Observable<any> {
    return this.httpService.get('master-reward', params);
  }
  
  getRewardforCreate(params: any): Observable<any> {
    return this.httpService.get('master-reward/create', params);
  }

  getRewardforEdit(rewardId: any, params: any): Observable<any> {
    return this.httpService.get(`master-reward/${rewardId}/edit`, params);
  }

  addReward(data:any): Observable<any> {
    return this.httpService.post('master-reward', data);
  }

  updateReward(rewardId: any, data: any): Observable<any> {
    return this.httpService.put(`master-reward/${rewardId}`, data);
  }
}