import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { RoleService } from '../role.service';
import { Role } from '../../../models/role.model';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.page.html',
  styleUrls: ['./role-edit.page.scss'],
})
export class RoleEditPage implements OnInit {

  formRoleEdit: FormGroup;
  token: any;
  roleData: any;
  roleId: any;
  userProfile: UserProfile = new UserProfile();
  accessOptions: any[] = ['No', 'Yes'];
  statusMenu: any[] = ['Not Active', 'Active'];
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private roleService: RoleService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormRoleEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormRoleEdit() {
    this.formRoleEdit = this.fb.group({
      roleName: [],
      allAccessOpt: [],
      activeStatus: []
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.roleId = AES.decrypt(snapshot.i, myKey).toString(utf8);

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.roleService.getRoleforEdit(this.roleId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.roleData = response.results.data;

          this.formRoleEdit = this.fb.group({
            roleName: [this.roleData.role_name, Validators.required],
            allAccessOpt: [parseInt(this.roleData.all_access), Validators.required],
            activeStatus: [parseInt(this.roleData.active), Validators.required]
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const roleForm = this.formRoleEdit.getRawValue();
      const role = new Role();
      role.role_name = roleForm.roleName;
      role.all_access = roleForm.allAccessOpt.toString();
      role.active = roleForm.activeStatus;
      role.updated_by = this.userProfile.username;

      this.roleService.updateRole(this.roleId, role).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/role']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
