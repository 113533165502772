import { Component, OnInit } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { DisplayCustomerProvider } from '../../providers/display-provider.provider';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';
import { RoleAccess } from '../../models/role-access.model';
import { UserData } from '../../providers/user-data';
import { Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { DisplayCustomerService } from '../display-customer/display-customer.service';
@Component({
  selector: 'app-display-customer',
  templateUrl: './display-customer.page.html',
  styleUrls: ['./display-customer.page.scss'],
})
export class DisplayCustomerPage implements OnInit {
  
  token: string;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  online:any='';
  productSales:any[]=[];
  currentTimezone: any='';
  currentTransDate:any='';
  currentUsername:any='';
  currentCashierName:any='';
  totalItem:number=0;
  totalPayment:number=0;
  totalPaymentRp :string='';
  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];
  imageSlider:any[]=[];
  constructor(
    private userData: UserData,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private displayCustomerProvider:DisplayCustomerProvider,
    private storage: Storage,
    private router: Router,
    private utilService: UtilService,
    private displayCustomerService:DisplayCustomerService
  ) {  
    this.initializeApp();
    this.currentTime();
  }

  option = {
    speed: 5000,
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    spaceBetween: 10,
     autoplay:true,
  }
  ngOnInit() {
    const container = document.body; 
    container.requestFullscreen();
    this.getStorage();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        this.currentUsername = this.userProfile.username;
        this.currentCashierName = this.userProfile.name;
        let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
        let convertDate = this.utilService.convertDate(transactionDate);
        this.currentTransDate = convertDate.dates + '-' + convertDate.months + '-' + convertDate.years;
        this.activeSlider();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  activeSlider(){
    let options = {
      "token": this.token,
      "counter_id": this.userProfile.counter_id_pos,
      "menu":"display",
      "active_flag":"Y"
    };
    this.displayCustomerService.getSlider(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.imageSlider = response.results.data;
    }, (err) => {
      console.log(err);
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });}
  currentTime() {
    setInterval(function () {
      let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
      let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
      this.currentTimezone = convertTime.times;
    }.bind(this),500);
  }
initializeApp() {
}
async getStorage() {
  this.displayCustomerProvider.appIsOnline$.subscribe(async online => {
    this.productSales = JSON.parse(localStorage.getItem('1'));
    this.totalItem =0;
    this.totalPayment=0;
    for (let i=0;i < this.productSales.length;i++) {
      let product = this.productSales[i];
      let discount =0;
      if(product. promoAuto=='Y'){
      this.productSales[i].qty =parseInt(this.productSales[i].qty_withoutpromo)+parseInt(this.productSales[i].qtyFree);
      this.totalItem += (parseInt(this.productSales[i].qty) );
      discount = (parseInt(product.nominalPromoPercentage)+parseInt(product.valPromoNominal));
      product.nett =(parseInt(product.qty_withoutpromo)*parseInt(product.price))-discount;
      }
      else{
        this.totalItem += (parseInt(this.productSales[i].qty) ); 
        let productwithoupromo=(this.productSales[i].price * (this.productSales[i].qty-this.productSales[i].qtyFree));
        discount =parseInt(product.valPromoNominal)+((((product.valPromoPercentage)/100) * productwithoupromo));
        let qty = parseInt(product.qty)-parseInt(product.qtyFree);
        product.nett =(qty*parseInt(product.price))- discount;
     }
     product.discount = discount;
     this.totalPayment += product.nett;
    }
    this.totalPaymentRp =this.formatRupiah(this.totalPayment.toString());
  })
}

formatRupiah(angka: string) {
  var number_string = angka.replace(/[^,\d]/g, '').toString();
  var split = number_string.split(',');
  var sisa = split[0].length % 3;
  var rupiah = split[0].substr(0, sisa);
  var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    var separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return rupiah;
}

}

