
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { AirportService } from './../airport.service';
@Component({
  selector: 'app-airport-register',
  templateUrl: './airport-register.page.html',
  styleUrls: ['./airport-register.page.scss'],
})
export class AirportRegisterPage implements OnInit {
  formAirport: FormGroup;
  airportList:any=[];
  merchantTypeList:any=[];
  counterList:any=[];
  token: any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  counterId:number=0;
  airportCode:any='';
  signature:any='';
  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private userData: UserData,
    private router: Router,
    private toastCtrl:ToastController,
    private navCtrl:NavController,
    private airportService:AirportService,
    private alertController:AlertController
  ) { }

  ngOnInit() {
    this.buildFormAirport();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.create!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          else{
            let options={
              "token": this.token,
              "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
            }
            this.airportService.getAirportforCreate(options).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.counterList = response.results.counter_data;
              this.airportList = response.results.airport_data;
              this.merchantTypeList = response.results.merchant_type_data;
            });
          }
        });
      }
      else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  buildFormAirport(){
    this.formAirport = this.fb.group({
      merchantName: [null, Validators.required],
      terminal: [null, Validators.required],
      merchantType: [null, Validators.required],
      merchantAddress:[null, Validators.required]
    })
  }
  register() {
    if(this.airportCode=='' || this.counterId==0){      
      this.toastCtrl.create({ duration: 2000, message: 'Mohon isi semua kolom' }).then(t => t.present());
    }
    else{
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        const warehouseForm = this.formAirport.getRawValue();
        let options ={
          "token":this.token,
          "merchantName": warehouseForm.merchantName,
          "merchantAddress": warehouseForm.merchantAddress,
          "terminal": warehouseForm.terminal,
          "airport": this.airportCode,
          "merchantType": warehouseForm.merchantType,
          "counterId":this.counterId
        }
        this.airportService.registerAirport(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 200) {
            this.showConfirmInput(response.results.rc,response.results.message);
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        })
      });
    }
  }

  async showConfirmInput(rc:any,msg:any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: msg,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            if(rc=='00'){
              this.navCtrl.navigateForward(['/airport']);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  selectCounter(event){
    this.counterId = event.value.id;
  }

  selectAirport(event){
    this.airportCode = event.value.airport_code;
  }
}
