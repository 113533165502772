import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable, observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecalculateStockService {

  constructor(private httpService: HttpService) { }

  getWarehouse(params: any): Observable<any> {
    return this.httpService.get('recalculate-stock-warehouse', params);
  }

  recalculateStock(data: any): Observable<any> {
    return this.httpService.post('recalculate-stock', data);
  }
}
