import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { MstProduct } from '../../models/mst-product.model';

@Injectable({
  providedIn: 'root'
})
export class MasterProductService {

  constructor(private httpService: HttpService) { }

  getProduct(params: any): Observable<any> {
    return this.httpService.get('product', params);
  }

  getProductForCreate(params: any): Observable<any> {
    return this.httpService.get('product-create', params);
  }

  getDataforAdvancedFilter(params: any): Observable<any> {
    return this.httpService.get('advanced-filter-product', params);
  }

  getProductForEdit(PCode: any, params: any): Observable<any> {
    return this.httpService.get(`product/${PCode}/edit`, params);
  }

  addProduct(data: MstProduct): Observable<any> {
    return this.httpService.post('product-add', data);
  }

  updateProduct(PCode: any, data: MstProduct): Observable<any> {
    return this.httpService.put(`product/${PCode}`, data);
  }

  pullProduct(data: MstProduct): Observable<any> {
    return this.httpService.post('product/pull', data);
  }
  getCounterListing(params:any) : Observable<any>{
    return this.httpService.get('list-counter-listing-product',params);
  }
}
