import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../../models/user-profile.model';
import { NavController, AlertController, ToastController, ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { UserData } from '../../../providers/user-data';
import { ReportService } from '../report.service';
import { ReportPointRegularComponent } from '../../../component/report-point-regular/report-point-regular.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-member-regular',
  templateUrl: './report-member-regular.page.html',
  styleUrls: ['./report-member-regular.page.scss'],
})
export class ReportMemberRegularPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  baseUrl: any;
  rows: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  customerList: any;
  filteredData: any;
  keywordSearch: any;
  
  reportDataDetail: any[] = [];
  reportDataPoint:any[]=[];
  pointTotal:any=0;
  pointUsed:any=0;
  pointRemains:any=0;


  constructor(
    private storage: Storage,    
    private navCtrl: NavController,
    private modalController: ModalController,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private userData: UserData,
    private reportService: ReportService
  ) { }
  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.pageCallback({ offset: this.page.offset });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  ngOnInit() {
  }

  searchWithKeywords() {
    this.pageCallback({ offset: this.page.offset });
  }
  
  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
        let options = {
          "token": this.token,
          "page": offset.toString(),
          "keywords": this.keywordSearch ? this.keywordSearch : 'null' 
        };
  
        this.reportService.getReportMemberPointRegular( options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.utilService.loadingDismiss();
          this.page.count = response.results.total;
        this.rows = response.results.data;
          console.log(response);
          
        console.log(this.rows);
          this.filteredData = response.results.data;
          this.customerList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  pointMutationDetail(customerId: any, pointTotal:any, pointUsed:any, pointRemain:any,action: any){
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "customer_id": customerId

      };
      this.reportService.getMutationPoint(options).subscribe((response) => {
        if(response.status.code === 200) {
          this.reportDataDetail = response.results.customer;
          this.pointTotal = pointTotal;
          this.pointUsed = pointUsed;
          this.pointRemains = pointRemain;
          this.reportDataPoint = response.results.point;
          if(action === 'detail') {
            this.utilService.loadingDismiss();
            this.openMutationPointDetail();
          }

          if(action === 'export') {
            this.utilService.loadingDismiss();
            this.waitForDataToLoad(response.results.customer.customer_name);
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    })

  }

  async openMutationPointDetail() {
    const modal = await this.modalController.create({
      component: ReportPointRegularComponent,
      componentProps: {        
        'reportDataDetail': this.reportDataDetail,
        'reportDataPoint':this.reportDataPoint,
        'pointTotal':this.pointTotal,
        'pointUsed':this.pointUsed,
        'pointRemains':this.pointRemains
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }

  
  
  async waitForDataToLoad(customerName:any) {
    await new Promise(resolve => setTimeout(resolve, 1500));
    this.exportToExcelDetail(customerName);
  }

  exportToExcel() {
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Report Point Member Regular.xlsx');
    this.utilService.loadingDismiss();
  }

  
  exportToExcelDetail(customerName:any) {
    let element = document.getElementById('excel-table-detail');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Mutasi Point - '+customerName+'.xlsx');
    this.utilService.loadingDismiss();
  }
}
