import { Component, OnInit } from '@angular/core';
import { PaymentVoucherService } from './payment-voucher.service';
import { Storage } from '@ionic/storage';
import { NavController, AlertController, ToastController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';

@Component({
  selector: 'app-payment-voucher',
  templateUrl: './payment-voucher.page.html',
  styleUrls: ['./payment-voucher.page.scss'],
})
export class PaymentVoucherPage implements OnInit {


  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  rows: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  modalKasirList: any;
  filteredData: any;

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private alertController: AlertController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private paymentVoucherService: PaymentVoucherService

  ) { }

  ngOnInit() {

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.pageCallback({ offset: this.page.offset });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });

  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString()
      };

      this.paymentVoucherService.getPaymentVoucher(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.modalKasirList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });

    });
  }

  filterDatatable(e) {

  }

  goToUpdatePage(id) {

  }

  showConfirmDelete(id) {

  }

  goToPaymentVoucherCreate() {
      this.navCtrl.navigateForward(['/payment-voucher/payment-voucher-create']);
  }
}
