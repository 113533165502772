import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { UserProfile } from '../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { RoleAccessService } from './role-access.service';
import { RoleAccess } from '../../models/role-access.model';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-role-access',
  templateUrl: './role-access.page.html',
  styleUrls: ['./role-access.page.scss'],
})
export class RoleAccessPage implements OnInit {

  token: any;
  roleId: any;
  roleName: any;
  menuData: any;
  roleData: any;
  roleAccessData: any;
  userProfile: UserProfile = new UserProfile();

  formRoleAccessEdit: FormGroup;
  checkedAllView:boolean=false;
  checkedAllRead:boolean=false;
  checkedAllReadAll:boolean=false;
  checkedAllCreate:boolean=false;
  checkedAllUpdate:boolean=false;
  checkedAllDelete:boolean=false;
  checkedAllApprove:boolean=false;
  checkedAllDownload:boolean=false;
  

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private roleAccessService: RoleAccessService
  ) { }

  ngOnInit() {
    this.buildFormRoleAccessEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.getData();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormRoleAccessEdit() {
    this.formRoleAccessEdit = this.fb.group({
      roleAccess: this.fb.array([])
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.roleId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.roleName = AES.decrypt(snapshot.rn, myKey).toString(utf8);
      this.roleName = this.roleName.replaceAll('"','');

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.roleAccessService.getRoleAccessforEdit(this.roleId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.menuData = response.results.menu_data;
          this.roleData = response.results.role_data;
          this.roleAccessData = response.results.role_access_data;
          this.addRoleAccessField();
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  addRoleAccessField() {
    let countView :number = 0;
    let countRead: number = 0;
    let countReadAll: number = 0;
    let countCreate : number = 0;
    let countUpdate : number = 0;
    let countDelete : number = 0;
    let countApprove : number = 0;
    let countDownload : number = 0;
    if(this.menuData.length > 0) {
      for(let i = 0; i < this.menuData.length; i++) {
        let viewValue, readValue, readAllValue, createValue,
        updateValue, deleteValue, approveValue, downloadValue = false;

        for(let x = 0; x < this.roleAccessData.length; x++) {
          if(this.roleAccessData[x].menu_id == this.menuData[i].id) {
            if(this.roleAccessData[x].view === '1') { viewValue = true; countView++;};
            if(this.roleAccessData[x].read === '1') { readValue = true; countRead++;};
            if(this.roleAccessData[x].read_all === '1') { readAllValue = true; countReadAll++; };
            if(this.roleAccessData[x].create === '1') { createValue = true; countCreate++;};
            if(this.roleAccessData[x].update === '1') { updateValue = true; countUpdate++; };
            if(this.roleAccessData[x].delete === '1') { deleteValue = true; countDelete++;};
            if(this.roleAccessData[x].approve === '1') { approveValue = true; countApprove++;};
            if(this.roleAccessData[x].download === '1') { downloadValue = true; countDownload++;};
          }
        }

        let menuName = this.menuData[i].menu_name;

        const accessRole = this.fb.group({
          
          menuId: [this.menuData[i].id],
          menuName: [menuName],
          view: [viewValue],
          read: [readValue],
          readAll: [readAllValue],
          create: [createValue],
          update: [updateValue],
          delete: [deleteValue],
          approve: [approveValue],
          download: [downloadValue]
        });

        this.getRoleAccessArray.push(accessRole);
      }
      if (countView==this.menuData.length){
        this.checkedAllView = true;
      }
      if (countRead==this.menuData.length){
        this.checkedAllRead = true;
      }
      if (countReadAll==this.menuData.length){
        this.checkedAllReadAll = true;
      }
    }
  }

  get getRoleAccessArray() {
    return (<FormArray>this.formRoleAccessEdit.get('roleAccess'));
  }

  onSubmitForm() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formRoleAccess = this.formRoleAccessEdit.value;
      let arrRoleId: any = [], arrMenuId: any = [], arrViewVal: any = [], arrReadVal: any = [],
      arrReadAllVal: any = [], arrCreateVal: any = [], arrUpdateVal: any = [], arrDeleteVal: any = [],
      arrApproveVal: any = [], arrDownloadVal: any = [];

      for(let x = 0; x < formRoleAccess.roleAccess.length; x++) {
        let viewValue: number = 0, readValue: number = 0, readAllValue: number = 0, createValue: number = 0,
        updateValue: number = 0, deleteValue: number = 0, approveValue: number = 0, downloadValue: number = 0;

        if(formRoleAccess.roleAccess[x].view === true) { viewValue = 1; };
        if(formRoleAccess.roleAccess[x].read === true) { readValue = 1; };
        if(formRoleAccess.roleAccess[x].readAll === true) { readAllValue = 1; };
        if(formRoleAccess.roleAccess[x].create === true) { createValue = 1; };
        if(formRoleAccess.roleAccess[x].update === true) { updateValue = 1; };
        if(formRoleAccess.roleAccess[x].delete === true) { deleteValue = 1; };
        if(formRoleAccess.roleAccess[x].approve === true) { approveValue = 1; };
        if(formRoleAccess.roleAccess[x].download === true) { downloadValue = 1; };

        arrRoleId[x] = this.roleId;
        arrMenuId[x] = formRoleAccess.roleAccess[x].menuId;
        arrViewVal[x] = viewValue;
        arrReadVal[x] = readValue;
        arrReadAllVal[x] = readAllValue;
        arrCreateVal[x] = createValue;
        arrUpdateVal[x] = updateValue;
        arrDeleteVal[x] = deleteValue;
        arrApproveVal[x] = approveValue;
        arrDownloadVal[x] = downloadValue;
      }

      const roleAccess = new RoleAccess();
      roleAccess.role_id = arrRoleId;
      roleAccess.menu_id = arrMenuId;
      roleAccess.view = arrViewVal;
      roleAccess.read = arrReadVal;
      roleAccess.read_all = arrReadAllVal;
      roleAccess.create = arrCreateVal;
      roleAccess.update = arrUpdateVal;
      roleAccess.delete = arrDeleteVal;
      roleAccess.approve = arrApproveVal;
      roleAccess.download = arrDownloadVal;

      let action = this.roleAccessData.length === 0 ? 'post' : 'put';

      this.roleAccessService.processRoleAccess(this.roleId, roleAccess, action).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/role']);;
          }
        }
      ]
    });

    await alert.present();
  }
  
  uncheckAllView() {        
    let setView:boolean;
    if(this.checkedAllView==false){
      setView  =true;
      this.checkedAllView = true;
    }
    else{
      setView= false;
      this.checkedAllView = false;
    }   
   for(let i = 0; i < this.menuData.length; i++) {
    const myForm = (<FormArray>this.formRoleAccessEdit.get("roleAccess")).at(i);
     myForm.patchValue({
        view:setView
      })
    }
  }
  
  uncheckAllRead() {        
    let setView:boolean;
    if(this.checkedAllRead==false){
      setView  =true;
      this.checkedAllRead = true;
    }
    else{
      setView= false;
      this.checkedAllRead = false;
    }   
   for(let i = 0; i < this.menuData.length; i++) {
    const myForm = (<FormArray>this.formRoleAccessEdit.get("roleAccess")).at(i);
     myForm.patchValue({
        read:setView
      })
    }    
  
  }

  uncheckAllReadAll() {        
    let setView:boolean;
    if(this.checkedAllReadAll==false){
      setView  =true;
      this.checkedAllReadAll = true;
    }
    else{
      setView= false;
      this.checkedAllReadAll = false;    
    }
   for(let i = 0; i < this.menuData.length; i++) {
    const myForm = (<FormArray>this.formRoleAccessEdit.get("roleAccess")).at(i);
     myForm.patchValue({
      readAll:setView
      })
    }
  }
  
  uncheckAllCreate() {
    let setView:boolean;
    if(this.checkedAllCreate==false){
      setView  =true;
      this.checkedAllCreate = true;
    }
    else{
      setView= false;
      this.checkedAllCreate = false;    
    }
   for(let i = 0; i < this.menuData.length; i++) {
    const myForm = (<FormArray>this.formRoleAccessEdit.get("roleAccess")).at(i);
     myForm.patchValue({
      create:setView
      })
    }
  }
  
  uncheckAllUpdate() {
    let setView:boolean;
    if(this.checkedAllUpdate==false){
      setView  =true;
      this.checkedAllUpdate = true;
    }
    else{
      setView= false;
      this.checkedAllUpdate = false;    
    }
   for(let i = 0; i < this.menuData.length; i++) {
    const myForm = (<FormArray>this.formRoleAccessEdit.get("roleAccess")).at(i);
     myForm.patchValue({
      update:setView
      })
    }
  }
  
  uncheckAllDelete() {
    let setView:boolean;
    if(this.checkedAllDelete==false){
      setView  =true;
      this.checkedAllDelete = true;
    }
    else{
      setView= false;
      this.checkedAllDelete = false;    
    }
    for(let i = 0; i < this.menuData.length; i++) {
      const myForm = (<FormArray>this.formRoleAccessEdit.get("roleAccess")).at(i);
      myForm.patchValue({
        delete:setView
      })
    }
  }
  
  uncheckAllApprove() {
    let setView:boolean;
    if(this.checkedAllApprove==false){
      setView  =true;
      this.checkedAllApprove = true;
    }
    else{
      setView= false;
      this.checkedAllApprove = false;    
    }
    for(let i = 0; i < this.menuData.length; i++) {
      const myForm = (<FormArray>this.formRoleAccessEdit.get("roleAccess")).at(i);
      myForm.patchValue({
        approve:setView
      })
    }
  }
  
  uncheckAllDownload() {
    let setView:boolean;
    if(this.checkedAllDownload==false){
      setView  =true;
      this.checkedAllDownload = true;
    }
    else{
      setView= false;
      this.checkedAllDownload = false;    
    }
    for(let i = 0; i < this.menuData.length; i++) {
      const myForm = (<FormArray>this.formRoleAccessEdit.get("roleAccess")).at(i);
      myForm.patchValue({
        download:setView
      })
    }    
  }


}
