import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule),
    canLoad: [CheckTutorial]
  },
  {
    path: 'gudang',
    loadChildren: () => import('./pages/gudang/gudang.module').then( m => m.GudangPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'satuan',
    loadChildren: () => import('./pages/satuan/satuan.module').then( m => m.SatuanPageModule)
  },
  {
    path: 'mata-uang',
    loadChildren: () => import('./pages/mata-uang/mata-uang.module').then( m => m.MataUangPageModule)
  },
  {
    path: 'registrasi',
    loadChildren: () => import('./pages/registrasi/registrasi.module').then( m => m.RegistrasiPageModule)
  },
  {
    path: 'tutup-kasir',
    loadChildren: () => import('./pages/tutup-kasir/tutup-kasir.module').then( m => m.TutupKasirPageModule)
  },
  {
    path: 'stock-opname',
    loadChildren: () => import('./pages/stock-opname/stock-opname.module').then( m => m.StockOpnamePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'pos',
    loadChildren: () => import('./pages/pos/pos.module').then( m => m.PosPageModule)
  },
  {
    path: 'master-product',
    loadChildren: () => import('./pages/master-product/master-product.module').then( m => m.MasterProductPageModule)
  },
  {
    path: 'master-product-packet',
    loadChildren: () => import('./pages/master-product-packet/master-product-packet.module').then( m => m.MasterProductPacketPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'role',
    loadChildren: () => import('./pages/role/role.module').then( m => m.RolePageModule)
  },
  {
    path: 'role-access',
    loadChildren: () => import('./pages/role-access/role-access.module').then( m => m.RoleAccessPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'employee',
    loadChildren: () => import('./pages/employee/employee.module').then( m => m.EmployeePageModule)
  },
  {
    path: 'counter',
    loadChildren: () => import('./pages/counter/counter.module').then( m => m.CounterPageModule)
  },
  {
    path: 'cashier',
    loadChildren: () => import('./pages/cashier/cashier.module').then( m => m.CashierPageModule)
  },
  {
    path: 'product-brand',
    loadChildren: () => import('./pages/product-brand/product-brand.module').then( m => m.ProductBrandPageModule)
  },
  {
    path: 'product-subbrand',
    loadChildren: () => import('./pages/product-subbrand/product-subbrand.module').then( m => m.ProductSubbrandPageModule)
  },
  {
    path: 'product-type',
    loadChildren: () => import('./pages/product-type/product-type.module').then( m => m.ProductTypePageModule)
  },
  {
    path: 'product-subtype',
    loadChildren: () => import('./pages/product-subtype/product-subtype.module').then( m => m.ProductSubtypePageModule)
  },
  {
    path: 'product-category',
    loadChildren: () => import('./pages/product-category/product-category.module').then( m => m.ProductCategoryPageModule)
  },
  {
    path: 'product-subcategory',
    loadChildren: () => import('./pages/product-subcategory/product-subcategory.module').then( m => m.ProductSubcategoryPageModule)
  },
  {
    path: 'promotions',
    loadChildren: () => import('./pages/promotions/promotions.module').then( m => m.PromotionsPageModule)
  },
  {
    path: 'promotion-detail',
    loadChildren: () => import('./pages/promotion-detail/promotion-detail.module').then( m => m.PromotionDetailPageModule)
  },
  {
    path: 'promotion-type',
    loadChildren: () => import('./pages/promotion-type/promotion-type.module').then( m => m.PromotionTypePageModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./pages/customer/customer.module').then( m => m.CustomerPageModule)
  },
  {
    path: 'report-cashier',
    loadChildren: () => import('./pages/report/transaction/report-cashier/report-cashier.module').then( m => m.ReportCashierPageModule)
  },
  {
    path: 'product-request',
    loadChildren: () => import('./pages/product-request/product-request.module').then( m => m.ProductRequestPageModule)
  },
  {
    path: 'product-receiving',
    loadChildren: () => import('./pages/product-receiving/product-receiving.module').then( m => m.ProductReceivingPageModule)
  },
  {
    path: 'modal-kasir',
    loadChildren: () => import('./pages/modal-kasir/modal-kasir.module').then( m => m.ModalKasirPageModule)
  },
  {
    path: 'setor-kasir',
    loadChildren: () => import('./pages/setor-kasir/setor-kasir.module').then( m => m.SetorKasirPageModule)
  },
  {
    path: 'voucher',
    loadChildren: () => import('./pages/voucher/voucher.module').then( m => m.VoucherPageModule)
  },
  {
    path: 'report-stock',
    loadChildren: () => import('./pages/report/inventory/report-stock/report-stock.module').then( m => m.ReportStockPageModule)
  },
  {
    path: 'purpose',
    loadChildren: () => import('./pages/purpose/purpose.module').then( m => m.PurposePageModule)
  },
  {
    path: 'other-receiving',
    loadChildren: () => import('./pages/other-receiving/other-receiving.module').then( m => m.OtherReceivingPageModule)
  },
  {
    path: 'other-expences',
    loadChildren: () => import('./pages/other-expences/other-expences.module').then( m => m.OtherExpencesPageModule)
  },
  {
    path: 'receipt-voucher',
    loadChildren: () => import('./pages/receipt-voucher/receipt-voucher.module').then( m => m.ReceiptVoucherPageModule)
  },
  {
    path: 'price-group',
    loadChildren: () => import('./pages/price-group/price-group.module').then( m => m.PriceGroupPageModule)
  },
  {
    path: 'payment-voucher',
    loadChildren: () => import('./pages/payment-voucher/payment-voucher.module').then( m => m.PaymentVoucherPageModule)
  },
  {
    path: 'petty-cash',
    loadChildren: () => import('./pages/petty-cash/petty-cash.module').then( m => m.PettyCashPageModule)
  },
  {
    path: 'petty-cash-category',
    loadChildren: () => import('./pages/petty-cash-category/petty-cash-category.module').then( m => m.PettyCashCategoryPageModule)
  },
  {
    path: 'warehouse-mutation-out',
    loadChildren: () => import('./pages/warehouse-mutation-out/warehouse-mutation-out.module').then( m => m.WarehouseMutationOutPageModule)
  },
  {
    path: 'warehouse-mutation-in',
    loadChildren: () => import('./pages/warehouse-mutation-in/warehouse-mutation-in.module').then( m => m.WarehouseMutationInPageModule)
  },
  {
    path: 'report-petty-cash',
    loadChildren: () => import('./pages/report/finance/report-petty-cash/report-petty-cash.module').then( m => m.ReportPettyCashPageModule)
  },
  {
    path: 'pos-reseller',
    loadChildren: () => import('./pages/pos-reseller/pos-reseller.module').then( m => m.PosResellerPageModule)
  },
  {
    path: 'register-reseller',
    loadChildren: () => import('./pages/register-reseller/register-reseller.module').then( m => m.RegisterResellerPageModule)
  },
  {
    path: 'reward-end-point',
    loadChildren: () => import('./pages/reward-end-point/reward-end-point.module').then( m => m.RewardEndPointPageModule)
  },
  {
    path: 'closed-day',
    loadChildren: () => import('./pages/closed-day/closed-day.module').then( m => m.ClosedDayPageModule)
  },
  {
    path: 'closed-month',
    loadChildren: () => import('./pages/closed-month/closed-month.module').then( m => m.ClosedMonthPageModule)
  },
  {
    path: 'reseller-cashback',
    loadChildren: () => import('./pages/reseller-cashback/reseller-cashback.module').then( m => m.ResellerCashbackPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'report-reprint-struck',
    loadChildren: () => import('./pages/report/transaction/report-reprint-struck/report-reprint-struck.module').then( m => m.ReportReprintStruckPageModule)
  },
  {
    path: 'report-stock-auditrail',
    loadChildren: () => import('./pages/report/inventory/report-stock-auditrail/report-stock-auditrail.module').then( m => m.ReportStockAuditrailPageModule)
  },
  {
    path: 'report-product-request',
    loadChildren: () => import('./pages/report/inventory/report-product-request/report-product-request.module').then( m => m.ReportProductRequestPageModule)
  },
  {
    path: 'report-product-receiving',
    loadChildren: () => import('./pages/report/inventory/report-product-receiving/report-product-receiving.module').then( m => m.ReportProductReceivingPageModule)
  },
  {
    path: 'monthly-rewards',
    loadChildren: () => import('./pages/monthly-rewards/monthly-rewards.module').then( m => m.MonthlyRewardsPageModule)
  },
  {
    path: 'report-sumdate',
    loadChildren: () => import('./pages/report/transaction/report-sumdate/report-sumdate.module').then( m => m.ReportSumdatePageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'recalculate-stock',
    loadChildren: () => import('./pages/recalculate-stock/recalculate-stock.module').then( m => m.RecalculateStockPageModule)
  },
  {
    path: 'claim-insentif',
    loadChildren: () => import('./pages/claim-insentif/claim-insentif.module').then( m => m.ClaimInsentifPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'request-void',
    loadChildren: () => import('./pages/request-void/request-void.module').then( m => m.RequestVoidPageModule)
  },
  {
    path: 'claim-insentif-summary',
    loadChildren: () => import('./pages/claim-insentif-summary/claim-insentif-summary.module').then( m => m.ClaimInsentifSummaryPageModule)
  },
  {
    path: 'report-other-expences',
    loadChildren: () => import('./pages/report/inventory/report-other-expences/report-other-expences.module').then( m => m.ReportOtherExpencesPageModule)
  },
  {
    path: 'report-other-receiving',
    loadChildren: () => import('./pages/report/inventory/report-other-receiving/report-other-receiving.module').then( m => m.ReportOtherReceivingPageModule)
  },
  {
    path: 'report-member-regular',
    loadChildren: () => import('./pages/report/report-member-regular/report-member-regular.module').then( m => m.ReportMemberRegularPageModule)
  },
  {
    path: 'setup-approval',
    loadChildren: () => import('./pages/setup-approval/setup-approval.module').then( m => m.SetupApprovalPageModule)
  },
  {
    path: 'price-group-approval',
    loadChildren: () => import('./pages/price-group-approval/price-group-approval.module').then( m => m.PriceGroupApprovalPageModule)
  },
  {
    path: 'sales-target',
    loadChildren: () => import('./pages/sales-target/sales-target.module').then( m => m.SalesTargetPageModule)
  },
  {
    path: 'master-product-counter',
    loadChildren: () => import('./pages/master-product-counter/master-product-counter.module').then( m => m.MasterProductCounterPageModule)
  },
  {
    path: 'pos-promo-oto',
    loadChildren: () => import('./pages/pos-promo-oto/pos-promo-oto.module').then( m => m.PosPromoOtoPageModule)
  },
  {
    path: 'pos-promo-oto-reseller',
    loadChildren: () => import('./pages/pos-promo-oto-reseller/pos-promo-oto-reseller.module').then( m => m.PosPromoOtoResellerPageModule)
  },
  {
    path: 'empty-page',
    loadChildren: () => import('./pages/empty-page/empty-page.module').then( m => m.EmptyPagePageModule)
  },
  {
    path: 'report-summary-sales',
    loadChildren: () => import('./pages/report/transaction/report-summary-sales/report-summary-sales.module').then( m => m.ReportSummarySalesPageModule)
  },
  {
    path: 'report-timeseries-sales',
    loadChildren: () => import('./pages/report/transaction/report-timeseries-sales/report-timeseries-sales.module').then( m => m.ReportTimeseriesSalesPageModule)
  },
  {
    path: 'report-summary-stock',
    loadChildren: () => import('./pages/report/inventory/report-summary-stock/report-summary-stock.module').then( m => m.ReportSummaryStockPageModule)
  },
  {
    path: 'report-counter',
    loadChildren: () => import('./pages/report/report-counter/report-counter.module').then( m => m.ReportCounterPageModule)
  },
  {
    path: 'report-national-stock',
    loadChildren: () => import('./pages/report/inventory/report-national-stock/report-national-stock.module').then( m => m.ReportNationalStockPageModule)
  },
  {
    path: 'claim-incentive',
    loadChildren: () => import('./pages/claim-incentive/claim-incentive.module').then( m => m.ClaimIncentivePageModule)
  },
  {
    path: 'master-reward',
    loadChildren: () => import('./pages/master-reward/master-reward.module').then( m => m.MasterRewardPageModule)
  },
  {
    path: 'report-claim-incentives',
    loadChildren: () => import('./pages/report/report-claim-incentives/report-claim-incentives.module').then( m => m.ReportClaimIncentivesPageModule)
  },
  {
    path: 'report-summary-transaction-reseller',
    loadChildren: () => import('./pages/report/report-reseller/report-summary-transaction-reseller/report-summary-transaction-reseller.module').then( m => m.ReportSummaryTransactionResellerPageModule)
  },
  {
    path: 'setup-reseller-reward',
    loadChildren: () => import('./pages/setup-reseller-reward/setup-reseller-reward.module').then( m => m.SetupResellerRewardPageModule)
  },
  {
    path: 'master-product-gimmick',
    loadChildren: () => import('./pages/master-product-gimmick/master-product-gimmick.module').then( m => m.MasterProductGimmickPageModule)
  },
  {
    path: 'promotion-approval',
    loadChildren: () => import('./pages/promotion-approval/promotion-approval.module').then( m => m.PromotionApprovalPageModule)
  },
  {
    path: 'airport',
    loadChildren: () => import('./pages/airport/airport.module').then( m => m.AirportPageModule)
  },
  {
    path: 'display-customer',
    loadChildren: () => import('./pages/display-customer/display-customer.module').then( m => m.DisplayCustomerPageModule)
  },
  {
    path: 'rekap-promo',
    loadChildren: () => import('./pages/report/rekap-promo/rekap-promo.module').then( m => m.RekapPromoPageModule)
  },
  {
    path: 'select-cashier',
    loadChildren: () => import('./pages/select-cashier/select-cashier.module').then( m => m.SelectCashierPageModule)
  },
  {
    path: 'pic',
    loadChildren: () => import('./pages/pic/pic.module').then( m => m.PicPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'register-approval',
    loadChildren: () => import('./pages/register-approval/register-approval.module').then( m => m.RegisterApprovalPageModule)
  },
  {
    path: 'view-detail',
    loadChildren: () => import('./pages/view-detail/view-detail.module').then( m => m.ViewDetailPageModule)
  },  {
    path: 'forget-password',
    loadChildren: () => import('./pages/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  }








];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}