import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../service/http.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseUrl: any;
  kpiApi:any;

  constructor(
    private http: HttpClient,
    private httpService: HttpService
  ) {
    this.baseUrl = environment.apiUrl;
    this.kpiApi = environment.kpiApi;
  }


  checkActiveUser(username: string): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('username', username);
    return this.http.post(this.baseUrl + 'check-active-user', formData, { headers: reqOpts })
  }
  userLogin(username: string, password: string): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    //formData.append('setup_device_id', setup_device_id);

    return this.http.post(this.baseUrl + 'signin', formData, { headers: reqOpts })
  }
  userLoginKPI(username: string, password: string): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('identity',username);
    formData.append('password', password);
    return this.http.post(this.kpiApi + 'api/login', formData)
  }
  genJWTtoken(username: string, password: string,name:string,email:string,employee_id:string,nik:string): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('username',username);
    formData.append('password', password);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('employee_id', employee_id);   
    formData.append('nik', nik);
    return this.http.post(this.baseUrl + 'create-token', formData, { headers: reqOpts })
  }
  
  checkUserNonKPI(params: any): Observable<any> {
    return this.httpService.get('check-user-non-kpi', params);
  }
}
