import { Component, OnInit,Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  
  @Input() productList: any;
  @Input() paramProductId: any;
  @Input() typeProduct: any;
  keyword:any='';
  products:any=[];
  selectedProducts:any[]=[];
  checkedAllProduct:boolean=false;
  constructor(
    private toastCtrl : ToastController,
    private modalController:ModalController
  ) { }

  ngOnInit() {}
  
  ionViewDidEnter() {
    let productId = this.paramProductId ? this.paramProductId : '';
    let productIdSplit = productId !== '' ? String(productId).split(',') : [];
    if(productIdSplit.length>0){
      for(let i=0;i<productIdSplit.length;i++){
        productIdSplit[i] = productIdSplit[i].replace(/'/g, "");
        if(productIdSplit[i]!=''){
          let Checbox = (this.productList.find(x => x.id ===productIdSplit[i]));
          let cari =this.selectedProducts.find(x=>x.id==productIdSplit[i]);
          if(cari==undefined){
            if(Checbox){
              Checbox.id=Checbox.id;
              this.chooseProduct(Checbox);
            }
          }
        }
      }
    }
    if(this.typeProduct=='paket'){
      this.productList = this.productList.filter(x=>x.is_virtual_bundling=='1');
    }
    else if(this.typeProduct=='isi'){
      this.productList = this.productList.filter(x=>x.is_virtual_bundling=='0');
    }
  }
  async getProduct(productBarcode: any) {
    this.keyword=productBarcode;
    this.products = [];
    if(this.keyword.length >= 3) {
      this.products = [];
      let product: any =this.productList.filter(x=> x.id.toUpperCase().includes(this.keyword.toUpperCase()) || x.product_name.toUpperCase().includes(this.keyword.toUpperCase()) || (x.barcode !==null && x.barcode.includes(this.keyword.toUpperCase())));
      if(product.length >0){
        for(let g=0;g<product.length;g++){
          this.products.push({
            id: product[g].id,
            barcode: product[g].barcode,
            product_name: product[g].product_name
          });
        }
      }
    }
    else{
      this.toastCtrl.create({ duration: 2000, message: 'Minimum 3 karakter' }).then(t => t.present());
    }
  }
  toggleAllProducts() {
    if(this.products.length > 0) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.checkedAllProduct === true) {
          this.products[i].isChecked = false; 
          let cari =this.selectedProducts.find(x=>x.id==this.products[i].id);
          if(cari!==undefined){
            let index = this.selectedProducts.indexOf(cari);
            this.selectedProducts.splice(index, 1);
          }      
        }else{
          this.products[i].isChecked = true;
          let cari =this.selectedProducts.find(x=>x.id==this.products[i].id);
          if(cari==undefined){
            this.chooseProduct(this.products[i]);
          }
        }
      }
      if(this.checkedAllProduct === true) {
        this.checkedAllProduct = false;
      }
      else{          
        this.checkedAllProduct = true;
      }
    }
  }
  
  chooseProduct(productId: any) {
    if(productId !== '' && productId !== undefined) {
      let checkItemPcode: any[] = this.selectedProducts.find(x => x.id === productId.id);
      if (checkItemPcode === undefined) {
        this.selectedProducts.push({
          id: productId.id,
          barcode: productId.barcode,
          name: productId.product_name
        });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Product sudah ada di list!' }).then(t => t.present());
      }
    }
  }
  
  selectCheckboxProduct(product_id:any,checked:any){
    if(this.products.length > 0) {
      let Checbox = (this.products.find(x => x.id === product_id));
     let indexChecbox = (this.products.findIndex(x => x.id === product_id));
      if(checked=='check'){
        this.products[indexChecbox].isChecked=true;
        let cari =this.selectedProducts.find(x=>x.id==product_id);
        if(cari==undefined){
          this.chooseProduct(Checbox);
          if(this.selectedProducts.length==this.products.length){
            this.checkedAllProduct=true;
          }
          else{
            this.checkedAllProduct==false;
          }
        }
      }
      else{
        this.products[indexChecbox].isChecked=false;
        let cari =this.selectedProducts.find(x=>x.id==product_id);
        if(cari!==undefined){
          let index = this.selectedProducts.indexOf(cari);
          this.selectedProducts.splice(index, 1);
          this.checkedAllProduct=false;
        }
      }
    }
  }
  
  deleteDetail(index: any) {
    this.selectedProducts.splice(index, 1);
  }
  async dismissModal(code: any) {
    let dataReturn= {
      'selectedProducts':this.selectedProducts
    }
    await this.modalController.dismiss(dataReturn);
  }
}
