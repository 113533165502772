import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { PettyCashCategory } from '../../models/petty-cash-category.model';


@Injectable({
  providedIn: 'root'
})
export class PettyCashCategoryService {

  constructor(private httpService: HttpService) { }
  
  getPettyCashCategory(params: any):  Observable<any> {
   return this.httpService.get('petty-cash-category', params);
  }

  addPettyCashCategory(data: PettyCashCategory): Observable<any> {
    return this.httpService.post('petty-cash-category', data);
  }

  getPettyCashCategoryEdit(pettyCashCategoryId: any, params: any): Observable<any> {
    return this.httpService.get(`petty-cash-category/${pettyCashCategoryId}/edit`, params);
  }
  
  updatePettyCashCategory(pettyCashCategoryId: any, data: PettyCashCategory): Observable<any> {
    return this.httpService.put(`petty-cash-category/${pettyCashCategoryId}`, data);
  }

  deletePettyCashCategory(pettyCashCategoryId: any): Observable<any> {
    return this.httpService.delete('petty-cash-category/'+pettyCashCategoryId);
  }

}
