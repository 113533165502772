import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { GudangService } from '../gudang.service';
import { MstWarehouse } from '../../../models/mst-warehouse.model';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-gudang-edit',
  templateUrl: './gudang-edit.page.html',
  styleUrls: ['./gudang-edit.page.scss'],
})
export class GudangEditPage implements OnInit {

  formWarehouseEdit: FormGroup;
  warehouseId: any;
  warehouseData: any;
  token: any;
  counterData: any[] = [];
  warehouseTypeData: any[] = [];
  statusWarehouse: any[] = ['Not Active', 'Active'];
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private gudangService: GudangService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormWarehouseEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {       
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormWarehouseEdit() {
    this.formWarehouseEdit = this.fb.group({
      counterId: [],
      warehouseName: [],
      warehouseTypeId: [],
      unitSize: [],
      lengthSize: [],
      widthSize: [],
      heightSize: [],
      activeStatus: []
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.warehouseId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.gudangService.getWarehouseforEdit(this.warehouseId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.warehouseData = response.results.data;
          this.counterData = response.results.counter_data;
          this.warehouseTypeData = response.results.warehouse_type_data;
          this.formWarehouseEdit = this.fb.group({
            counterId: [parseInt(this.warehouseData.counter_id), Validators.required],
            warehouseName: [this.warehouseData.warehouse_name, Validators.required],
            warehouseTypeId: [parseInt(this.warehouseData.warehouse_type_id), Validators.required],
            unitSize: [this.warehouseData.unit_size, Validators.required],
            lengthSize: [this.warehouseData.length_size, Validators.required],
            widthSize: [this.warehouseData.width_size, Validators.required],
            heightSize: [this.warehouseData.height_size, Validators.required],
            activeStatus: [parseInt(this.warehouseData.active), Validators.required]
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const warehouseForm = this.formWarehouseEdit.getRawValue();
      const mstWarehouse = new MstWarehouse();
      mstWarehouse.counter_id = warehouseForm.counterId;
      mstWarehouse.warehouse_name = warehouseForm.warehouseName;
      mstWarehouse.warehouse_type_id = warehouseForm.warehouseTypeId;
      mstWarehouse.unit_size = warehouseForm.unitSize;
      mstWarehouse.length_size = warehouseForm.lengthSize;
      mstWarehouse.width_size = warehouseForm.widthSize;
      mstWarehouse.height_size = warehouseForm.heightSize;
      mstWarehouse.active = warehouseForm.activeStatus;
      mstWarehouse.updated_by = this.userProfile.username;

      this.gudangService.updateWarehouse(this.warehouseId, mstWarehouse).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/gudang']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
