import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UtilService } from '../../../service/util.service';
import { environment } from '../../../../environments/environment';
import { UserProfile } from '../../../models/user-profile.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');

@Component({
  selector: 'app-register-reseller-edit',
  templateUrl: './register-reseller-edit.page.html',
  styleUrls: ['./register-reseller-edit.page.scss'],
})
export class RegisterResellerEditPage implements OnInit {

  baseUrl: any;
  token: any;
  formRegisterResellerEdit: FormGroup;
  userProfile: UserProfile = new UserProfile();
  resellerId: any;
  customerResellerData: any;
  
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private http: HttpClient,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private activeRoute: ActivatedRoute,
    private navCtrl: NavController,
    private userData: UserData,
    private router: Router
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
    this.buildFormRegisterResellerEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
        this.getData();
      }else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormRegisterResellerEdit() {
    this.formRegisterResellerEdit = this.fb.group({
      customerName: [null, Validators.required],
      identityNumber: [null, Validators.required],
      phone: [null, Validators.required],
      birthPlace: ["", ""],
      birthDate: ["", ""],
      gender: [null, Validators.required],
      profession: ["", ""],
      areaCode: [null, Validators.required],
      area: [null, Validators.required],
      address: [null, Validators.required],
      joinDate: [null, Validators.required],
      internalEmployee: [null, Validators.required],
      kodeReferral: ["", ""],
      kodeReferralUpline: ["", ""]
    });
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const resellerRegisterData = this.formRegisterResellerEdit.getRawValue();

      const paramsOpts = new HttpParams()
        .set('token', this.token)

      const reqOpts = new HttpHeaders()
        .set('Accept', 'application/json'),
      formData = new FormData();
      formData.append('customer_name', resellerRegisterData.customerName);
      formData.append('identity_number', resellerRegisterData.identityNumber);
      formData.append('phone', resellerRegisterData.phone);
      formData.append('birth_place', resellerRegisterData.birthPlace);
      formData.append('birth_date', resellerRegisterData.birthDate);
      formData.append('gender', resellerRegisterData.gender);
      formData.append('profession', resellerRegisterData.profession);
      formData.append('area_code', resellerRegisterData.areaCode);
      formData.append('area', resellerRegisterData.area);
      formData.append('address', resellerRegisterData.address);
      formData.append('join_date', resellerRegisterData.joinDate);
      formData.append('internal_employee', resellerRegisterData.internalEmployee);
      formData.append('referral_code_upline', resellerRegisterData.kodeReferralUpline);
      formData.append('updated_by', this.userProfile.username);
      formData.append('_method', 'PUT');

      this.http.post(this.baseUrl + 'customer-reseller/' + this.resellerId, formData, { headers: reqOpts, params: paramsOpts })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/register-reseller']);;
          }
        }
      ]
    });

    await alert.present();
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.resellerId = AES.decrypt(snapshot.i, myKey).toString(utf8);

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.http.get(this.baseUrl + 'customer-reseller/' + this.resellerId + '/edit?token=' + this.token)
        .subscribe((response: any) => {
          this.utilService.loadingDismiss();
          this.customerResellerData = response.results.data;

          this.formRegisterResellerEdit = this.fb.group({
            customerName: [this.customerResellerData.customer_name, Validators.required],
            identityNumber: [this.customerResellerData.identity_number, Validators.required],
            phone: [this.customerResellerData.phone, Validators.required],
            birthPlace: [this.customerResellerData.birth_place, ""],
            birthDate: [this.customerResellerData.birth_date, ""],
            gender: [this.customerResellerData.gender, Validators.required],
            profession: [this.customerResellerData.profession, ""],
            areaCode: [this.customerResellerData.area_code, Validators.required],
            area: [this.customerResellerData.area, Validators.required],
            address: [this.customerResellerData.address, Validators.required],
            joinDate: [this.customerResellerData.join_date, Validators.required],
            internalEmployee: [this.customerResellerData.internal_employee, Validators.required],
            kodeReferral: [this.customerResellerData.referral_code, ""],
            kodeReferralUpline: [this.customerResellerData.referral_code_upline, ""]
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

}
