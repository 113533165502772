export class MstPromotion {

    id: number;
    counter_id: string;
    warehouse_id: string;
    promotion_name: string;
    early_period: string;
    end_period: string;
    promotion_type_id: number;
    promotion_cashier_type: string;
    minimum: number;
    valid_on_su: string;
    valid_on_mo: string;
    valid_on_tu: string;
    valid_on_we: string;
    valid_on_th: string;
    valid_on_fr: string;
    valid_on_sa: string;
    status: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || '';
        this.warehouse_id = model.warehouse_id || '';
        this.promotion_name = model.promotion_name || null;
        this.early_period = model.early_period || null;
        this.end_period = model.end_period || null;
        this.promotion_type_id = model.promotion_type_id || 0;
        this.promotion_cashier_type = model.promotion_cashier_type || '1';
        this.minimum = model.minimum || 0;
        this.valid_on_su = model.valid_on_su || '0';
        this.valid_on_mo = model.valid_on_mo || '0';
        this.valid_on_tu = model.valid_on_tu || '0';
        this.valid_on_we = model.valid_on_we || '0';
        this.valid_on_th = model.valid_on_th || '0';
        this.valid_on_fr = model.valid_on_fr || '0';
        this.valid_on_sa = model.valid_on_sa || '0';
        this.status = model.status || '1';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}

export class MstPromotionDetail {

    id: number[];
    promotion_id: number[];
    product_id: string[];
    type: string[];
    value: number[];
    free_qty: number[];
    minimum_buy: number[];
    minimum_transaction: number[];
    free_item_used: number[];
    multiple_flag: string[];
    free_item: string[];
    free_item_id: number[];
    promo_bundle: string[];
    
    constructor(model?) {
        model = model || {};
        this.id = model.id || [];
        this.promotion_id = model.promotion_id || [];
        this.product_id = model.product_id || [];
        this.type = model.type || [];
        this.value = model.value || [];
        this.free_qty = model.free_qty || [];
        this.minimum_buy = model.minimum_buy || [];
        this.minimum_transaction = model.minimum_transaction || [];
        this.free_item_used = model.free_item_used || [];
        this.multiple_flag = model.multiple_flag || [];
        this.free_item = model.free_item || [];
        this.free_item_id = model.free_item_id || [];
        this.promo_bundle= model.promo_bundle || [];
    }
}

export class MstPromotionType {

    id: number;
    promotion_type_name: string;
    active: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.promotion_type_name = model.promotion_type_name || null;
        this.active = model.active || null;
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}