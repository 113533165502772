export class MstCashier {

    id: number;
    counter_id: number;
    cashier_name: string;
    active: string;
    setup: string;
    setup_device_id: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || 0;
        this.cashier_name = model.cashier_name || null;
        this.active = model.active || '1';
        this.setup = model.setup || '0';
        this.setup_device_id = model.setup_device_id || null;
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}