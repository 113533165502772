import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReportPettyCashPageRoutingModule } from './report-petty-cash-routing.module';

import { ReportPettyCashPage } from './report-petty-cash.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReportPettyCashPageRoutingModule,
    NgxDatatableModule
    
  ],
  declarations: [ReportPettyCashPage]
})
export class ReportPettyCashPageModule {}
