import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { NavController, ToastController } from '@ionic/angular';
import { TutupKasir } from '../../models/tutup-kasir.model';
import { TutupKasirService } from './tutup-kasir.service';

@Component({
  selector: 'app-tutup-kasir',
  templateUrl: './tutup-kasir.page.html',
  styleUrls: ['./tutup-kasir.page.scss'],
})
export class TutupKasirPage implements OnInit {

  printers: string[];
  isDefaultPrinterSelected = false;
  selectedPrinter: any;
  monthList: any[] = ['JAN', 'FEB', 'MAR', 'APR', 'MEI', 'JUN', 'JUL', 'AGU', 'SEP', 'OKT', 'NOV', 'DES'];

  formClosedCashier: FormGroup;
  
  token: string;
  userProfile: UserProfile = new UserProfile();

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private storage: Storage,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private tutupKasirService: TutupKasirService
  ) { }

  ngOnInit() {
    this.buildFormClosedCashier();

    // WebSocket settings
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (this.jspmWSStatus()) {
            // get client installed printers
            JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
              this.printers = myPrinters;
              this.isDefaultPrinterSelected = true;
            });
        }
    };

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  // Check JSPM WebSocket status
  jspmWSStatus() {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
        alert('JSPM has blocked this website!');
        return false;
    }
  }

  buildFormClosedCashier() {
    let newDate = new Date();
    let convertDate = this.utilService.convertDate(newDate);
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formClosedCashier = this.fb.group({
      transDate: [todayDate]
    });
  }

  printRekap() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formClosedCashier = this.formClosedCashier.value;
      let transDateConvert = this.utilService.convertDate(formClosedCashier.transDate);
      let transDate = transDateConvert.years + '-' + transDateConvert.months + '-' + transDateConvert.dates;
  
      const tutupKasir = new TutupKasir();
      tutupKasir.user_id = this.userProfile.id;
      tutupKasir.counter_id = this.userProfile.counter_id_pos !== 0 ? this.userProfile.counter_id_pos : this.userProfile.counter_id_list
      tutupKasir.trans_date = transDate;
      this.tutupKasirService.addTutupKasir(tutupKasir).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code == 200) {
          if(parseInt(response.results.data.total_receipt_no) > 0) {
            let cashierName: string = this.userProfile.name;
            let totalReceiptNo: string = response.results.data.total_receipt_no;
            let totalValue: string = response.results.data.total_value;
            let cash: string = (parseInt(response.results.data.cash) - parseInt(response.results.data.change)).toString();
            let creditCard: string = response.results.data.credit_card;
            let debitCard: string = response.results.data.debit_card;
            let transfer: string = response.results.data.transfer;
            let voucher: string = response.results.data.voucher;
            let memberPoint: string = response.results.data.member_point;
            //Create a ClientPrintJob
            const cpj = new JSPM.ClientPrintJob();

            //Set Printer type (Refer to the help, there many of them!)
            if ( this.isDefaultPrinterSelected ) {
              cpj.clientPrinter = new JSPM.DefaultPrinter();
            } else {
              cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
            }

            let transDateResponse = response.results.data.trans_date;
            let convertDate = this.utilService.convertDate(transDateResponse);
            let monthName: any = this.monthList[(parseInt(convertDate.months)-1)];
            let newTransDate = convertDate.dates + '-' + monthName + '-' + convertDate.years;
            //Set content to print...
            //Create ESP/POS commands for sample label
            let esc = '\x1B'; //ESC byte in hex notation
            let newLine = '\x0A'; //LF byte in hex notation
            let cut = "\x1b" + "\x69";
            let cmds = esc + "@"; //Initializes the printer (ESC @)
            
            cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
            cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
            cmds += '---------------------------------';
            cmds += '           REKAP KASIR';
            cmds += newLine;
            cmds += newLine;
            cmds += 'KASIR   : ' + cashierName.toUpperCase();
            cmds += newLine;
            cmds += 'TANGGAL : ' + newTransDate;
            cmds += newLine;
            cmds += '=================================';
            cmds += newLine;
            cmds += '              STRUK : ' + this.getLabelPrintNominal(totalReceiptNo);
            cmds += newLine;
            cmds += '         PENDAPATAN : ' + this.getLabelPrintNominal(this.formatRupiah(totalValue));
            cmds += newLine;
            cmds += '---------------------------------';
            cmds += newLine;
            cmds += '             RINCIAN';
            cmds += newLine;
            cmds += '           TUNAI RP : ' + this.getLabelPrintNominal(this.formatRupiah(cash));
            cmds += newLine;
            cmds += '            KKREDIT : ' + this.getLabelPrintNominal(this.formatRupiah(creditCard));
            cmds += newLine;
            cmds += '             KDEBIT : ' + this.getLabelPrintNominal(this.formatRupiah(debitCard));
            cmds += newLine;
            cmds += '           TRANSFER : ' + this.getLabelPrintNominal(this.formatRupiah(transfer));
            cmds += newLine;
            cmds += '            VOUCHER : ' + this.getLabelPrintNominal(this.formatRupiah(voucher));
            cmds += newLine;
            cmds += '       MEMBER POINT : ' + this.getLabelPrintNominal(this.formatRupiah(memberPoint));
            cmds += newLine;
            cmds += newLine;
            cmds += newLine;
            cmds += '         OEMAH HERBORIST';
            cmds += newLine;
            cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
            cmds += newLine;
            cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
            cmds += newLine;
            cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
            cmds += newLine;
            cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone));
            cmds += newLine;
            // cmds += newLine + newLine + newLine + newLine + newLine + newLine + newLine + newLine + newLine + newLine;
            cmds += cut;
            console.log(cmds);
            cpj.printerCommands = cmds;
            //Send print job to printer!
            cpj.sendToClient();
          } else {
            let monthName: any = this.monthList[(parseInt(transDateConvert.months)-1)];
            let newTransDate = transDateConvert.dates + '-' + monthName + '-' + transDateConvert.years;
            let messages: any = "Tidak ada transaksi di tanggal: " + newTransDate;
            this.toastCtrl.create({ duration: 2000, message: messages }).then(t => t.present());
          }
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Terdapat error.' }).then(t => t.present());
      });
    });
  }

  getLabelPrintNominal(nominal: any): String {
    let nominalString = new String(nominal);
    let totalLength: number = 11;

    let diff: number = totalLength - nominalString.length;
    let lblNominal: String = '';

    if(diff !== 0) {
      for(let i = 0; i < diff; i++) {
        lblNominal = lblNominal + ' ';
      }

      lblNominal = lblNominal + nominalString.toString();
    } else {
      lblNominal = nominalString;
    }

    return lblNominal;
  }

  formatRupiah(angka: string) {
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
    // return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }

  getLabelHeaderReceipt(headerText: String) {
    let totalLength: number = headerText.length;
    let maxTextLengthforReceipt = 33;
    let resultText = "";
    if(totalLength > maxTextLengthforReceipt) {
      resultText = headerText.slice(0, maxTextLengthforReceipt);
    } else {
      let diff = maxTextLengthforReceipt - totalLength;
      let divide = (diff - (diff % 2)) / 2;
      let space = "";
      for(let i = 0; i < divide; i++) {
        space = space + " ";
      }
      
      resultText = space + headerText + space;
    }

    return resultText;
  }

}
