export class WarehouseMutationDetail {
    
    warehouse_mutation_id: string[];
    product_id: string[];
    qty: number[];
    desc_detail: string[];
    
    constructor(model?) {
        model = model || {};
        this.warehouse_mutation_id = model.warehouse_mutation_id || [];
        this.product_id = model.product_id || [];
        this.qty = model.qty || [];
        this.desc_detail = model.desc_detail || [];
    }
}