export class Menu {

    id: number;
    parent_id: number;
    menu_name: string;
    root: string;
    weight: number;
    icon: string;
    active: string;
    created_by: string;
    updated_by: string;
  
    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.parent_id = model.parent_id || 0;
        this.menu_name = model.menu_name || null;
        this.root = model.root || null;
        this.weight = model.weight || 0;
        this.icon = model.icon || null;
        this.active = model.active || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}