import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';

@Injectable({
  providedIn: 'root'
})
export class SearchProductPriceGroupService {

  constructor(private httpService: HttpService) { }

  getProductSearch(params: any): Observable<any> {
    return this.httpService.get('price-group-search/get-product', params);
  }

  checkProductInPriceGroupDetail(params: any): Observable<any> {
    return this.httpService.post('price-group-search/check-product', params);
  }
}
