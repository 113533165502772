export class MstPriceGroup {

    id: number;
    counter_id: number;
    price_group_types: string;
    valid_periode: string;
    description: string;
    active: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || 0;
        this.price_group_types = model.price_group_types || null;
        this.valid_periode = model.valid_periode || null;
        this.description = model.description || null;
        this.active = model.active || '1';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}

export class MstPriceGroupDetail {

    price_group_id: number;
    product_id: string;
    price: number;

    constructor(model?) {
        model = model || {};
        this.price_group_id = model.price_group_id || 0;
        this.product_id = model.product_id || null;
        this.price = model.price || 0;
    }
}