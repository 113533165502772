import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PicDetailPageRoutingModule } from './pic-detail-routing.module';

import { PicDetailPage } from './pic-detail.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PicDetailPageRoutingModule,
    NgxDatatableModule,
    ReactiveFormsModule
  ],
  declarations: [PicDetailPage]
})
export class PicDetailPageModule {}
