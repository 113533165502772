import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegisterResellerPage } from './register-reseller.page';

const routes: Routes = [
  {
    path: '',
    component: RegisterResellerPage
  },
  {
    path: 'register-reseller-create',
    loadChildren: () => import('./register-reseller-create/register-reseller-create.module').then( m => m.RegisterResellerCreatePageModule)
  },
  {
    path: 'register-reseller-edit',
    loadChildren: () => import('./register-reseller-edit/register-reseller-edit.module').then( m => m.RegisterResellerEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegisterResellerPageRoutingModule {}
