import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PriceGroupPage } from './price-group.page';

const routes: Routes = [
  {
    path: '',
    component: PriceGroupPage
  },
  {
    path: 'price-group-create',
    loadChildren: () => import('./price-group-create/price-group-create.module').then( m => m.PriceGroupCreatePageModule)
  },
  {
    path: 'price-group-edit',
    loadChildren: () => import('./price-group-edit/price-group-edit.module').then( m => m.PriceGroupEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PriceGroupPageRoutingModule {}
