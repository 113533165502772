import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { PromotionsService } from '../../promotions/promotions.service';
import { MstPromotionType } from '../../../models/mst-promotion.model';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-promotion-type-edit',
  templateUrl: './promotion-type-edit.page.html',
  styleUrls: ['./promotion-type-edit.page.scss'],
})
export class PromotionTypeEditPage implements OnInit {

  formPromotionTypeEdit: FormGroup;
  promotionTypeId: any;
  promotionTypeData: any;
  token: any;
  statusPromotionType: any[] = ['Not Active', 'Active'];
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private promotionService: PromotionsService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormPromotionTypeEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormPromotionTypeEdit() {
    this.formPromotionTypeEdit = this.fb.group({
      promotionTypeName: [],
      activeStatus: []
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      this.promotionTypeId = snapshot.promotionTypeId;

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.promotionService.getPromotionTypeforEdit(this.promotionTypeId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.promotionTypeData = response.results.data;

          this.formPromotionTypeEdit = this.fb.group({
            promotionTypeName: [this.promotionTypeData.promotion_type_name, Validators.required],
            activeStatus: [parseInt(this.promotionTypeData.active), Validators.required]
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const promotionTypeData = this.formPromotionTypeEdit.getRawValue();
      const promotionType = new MstPromotionType();
      promotionType.promotion_type_name = promotionTypeData.promotionTypeName;
      promotionType.active = promotionTypeData.activeStatus;
      promotionType.updated_by = this.userProfile.username;

      this.promotionService.updatePromotionType(this.promotionTypeId, promotionType).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/promotion-type']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
