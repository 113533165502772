import { Component, OnInit, Input } from '@angular/core';
import { ToastController, ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../service/util.service';
import { ReportService } from '../../pages/report/report.service';

@Component({
  selector: 'app-report-stock',
  templateUrl: './report-stock.component.html',
  styleUrls: ['./report-stock.component.scss'],
})
export class ReportStockComponent implements OnInit {

  @Input() warehouseId: any;
  @Input() productId: any;
  @Input() reportDate: any;
  @Input() earlyStock: any;
  @Input() inStock: any;
  @Input() outStock: any;
  @Input() endStock: any;
  @Input() type :any;
  
  token: any;
  stockMutationData: any[] = [];
  productData: any;
  productName: any;
  warehouseName: any;
  productUnit: any;
  title : any;
  showEarlyStock : boolean =false;
  showEndStock : boolean = false;
  showInStock : boolean = false;
  showOutStock : boolean = false;

  constructor(
    public modalController: ModalController,
    private storage: Storage,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private utilService: UtilService,
    private reportService: ReportService) { }

  ngOnInit() {
    this.storage.get('user_token')
    .then((token) => {
      if(token) {
        this.token = token;
        this.title = '';
        this.getData();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getData() {
    let monthYear = this.reportDate.split('-');
    let dateReport = monthYear[0] + '-' + monthYear[1] + '-' + '01';
    let date = this.utilService.getFirstAndLastDateofMonth(dateReport);

    let options = {
      "token": this.token,
      "warehouse_id": this.warehouseId,
      "product_id": this.productId,
      "start_date": date.startDate,
      "end_date": date.endDate,
      "type": this.type
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      if(this.type=='I'){
        this.title = 'Masuk';
        this.showInStock = true;
      }
      else if(this.type=='O'){
        this.title = 'Keluar';
        this.showOutStock = true;
      }
      else{
        this.showEarlyStock = true;
        this.showEndStock = true;
        this.showInStock = true;
        this.showOutStock = true;
      }
      this.reportService.getMutationStock(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.stockMutationData = response.results.stock_mutation_data;
          this.productData = response.results.product_data;
          if(response.results.stock_data.length > 0) {
            this.earlyStock = response.results.stock_data[0]['qty_early'];
            this.endStock = response.results.stock_data[0]['qty_end'];
          }
          else{
            this.earlyStock = 0;
            this.endStock = 0;
          }
          if(this.stockMutationData.length > 0) {
            this.productName = this.stockMutationData[0].product_name;
            this.warehouseName = this.stockMutationData[0].warehouse_name;
            this.productUnit = this.stockMutationData[0].unit;
          } else {
            this.productName = this.productData[0].product_name;
            this.productUnit = this.productData[0].unit;
          }
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }

}
