import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-af-price-group',
  templateUrl: './af-price-group.component.html',
  styleUrls: ['./af-price-group.component.scss'],
})
export class AfPriceGroupComponent implements OnInit {

  @Input() counterList: any[];

  baseUrl: any;
  counterSelect: any;

  constructor(
    public modalController: ModalController
  ) {
  }

  ngOnInit() {}

  async dismissModal() {
    const counterId: any = this.counterSelect;
    await this.modalController.dismiss(counterId);
  }
  selectCounter(event){
    this.counterSelect = event.value.id;
  }

}
