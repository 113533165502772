import { ProductReceiving } from './product-receiving.model';
import { StockMutation } from './stock-mutation.model';
import { ApprovalHistory } from './approval-history.model';

export class ProductReceivingClosed {

    productReceiving: ProductReceiving;
    stockMutation: StockMutation;
    approvalHistory: ApprovalHistory;

    constructor(model?) {
        model = model || {};
        this.productReceiving = model.productReceiving || new ProductReceiving();
        this.stockMutation = model.stockMutation || new StockMutation();
        this.approvalHistory = model.approvalHistory || new ApprovalHistory();
    }
}