import { SalesTransaction, SalesTransactionDetail, SalesTransactionPayments } from './sales-transaction.model';
import { PointMutation } from './point-mutation.model';
import { MemberPoint } from './member-point.model';
import { MstVoucherDetail } from './mst-voucher.model';
import { StockMutation } from './stock-mutation.model';
import { MstPromotionDetail } from './mst-promotion.model';

export class SalesTransactionBundle {

    salesTransaction: SalesTransaction;
    salesTransactionDetail: SalesTransactionDetail;
    salesTransactionPayments: SalesTransactionPayments;
    pointMutation: PointMutation;
    memberPoint: MemberPoint;
    mstVoucherDetail: MstVoucherDetail;
    stockMutation: StockMutation;
    freePromoMutation: StockMutation;
    mstPromotionDetail: MstPromotionDetail;

    constructor(model?) {
        model = model || {};
        this.salesTransaction = model.salesTransaction || new SalesTransaction();
        this.salesTransactionDetail = model.salesTransactionDetail || new SalesTransactionDetail();
        this.salesTransactionPayments = model.salesTransactionPayments || new SalesTransactionPayments();
        this.pointMutation = model.pointMutation || new PointMutation();
        this.memberPoint = model.memberPoint || new MemberPoint();
        this.mstVoucherDetail = model.mstVoucherDetail || new MstVoucherDetail();
        this.stockMutation = model.stockMutation || new StockMutation();
        this.freePromoMutation = model.freePromoMutation || new StockMutation();
        this.mstPromotionDetail = model.mstPromotionDetail || new MstPromotionDetail();
    }
}