import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReportStockPageRoutingModule } from './report-stock-routing.module';

import { ReportStockPage } from './report-stock.page';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ReportStockPageRoutingModule,
    NgxDatatableModule,
    IonicSelectableModule
  ],
  declarations: [ReportStockPage],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ReportStockPageModule {}
