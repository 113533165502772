import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { PromotionsService } from '../promotions.service';
import { MstPromotion } from '../../../models/mst-promotion.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-promotion-edit',
  templateUrl: './promotion-edit.page.html',
  styleUrls: ['./promotion-edit.page.scss'],
})
export class PromotionEditPage implements OnInit {

  formPromotionEdit: FormGroup;
  promotionId: any;
  promotionData: any;
  token: any;
  promotionTypeData: any[] = [];
  counterList: any[] = [];
  warehouseList: any[] = [];
  //cashierType: any[] = ['Semua Kasir', 'Kasir Reguler', 'Kasir Reseller'];
  cashierType: any[] = ['Kasir Reguler'];
  statusPromotion: any[] = ['Not Active', 'Active'];
  userProfile: UserProfile = new UserProfile();

  db: any;
  checkedAll : boolean = false;
  countChecked : number = 0;

  roleAccess = new RoleAccess();
  approval_status:number=0;
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private promotionService: PromotionsService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormPromotionEdit();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormPromotionEdit() {
    this.formPromotionEdit = this.fb.group({
      counterId: [null],
      warehouseId: [null],
      promotionName: [],
      earlyPeriod: [],
      endPeriod: [],
      promotionType: [],
      promotionCashierType: [],
      minimum: [],
      validOnSun: [],
      validOnMon: [],
      validOnTue: [],
      validOnWed: [],
      validOnThu: [],
      validOnFri: [],
      validOnSat: [],
      activeStatus: [],
      checkedAll : []
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.promotionId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let options = {
          "token": this.token,
          "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
        };
        this.promotionService.getPromotionforEdit(this.promotionId, options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.promotionData = response.results.data;
          this.promotionTypeData = response.results.promotion_type_data;
          this.counterList = response.results.counter_data;
          this.warehouseList = response.results.warehouse_data;

          let validOnSun: any = false, validOnMon: any = false, validOnTue: any = false, validOnWed: any = false,
          validOnThu: any = false, validOnFri: any = false, validOnSat: any = false;

          if(this.promotionData.valid_on_su === '1') { validOnSun = true; this.countChecked++; };
          if(this.promotionData.valid_on_mo === '1') { validOnMon = true; this.countChecked++; };
          if(this.promotionData.valid_on_tu === '1') { validOnTue = true; this.countChecked++; };
          if(this.promotionData.valid_on_we === '1') { validOnWed = true; this.countChecked++; };
          if(this.promotionData.valid_on_th === '1') { validOnThu = true; this.countChecked++; };
          if(this.promotionData.valid_on_fr === '1') { validOnFri = true; this.countChecked++; };
          if(this.promotionData.valid_on_sa === '1') { validOnSat = true; this.countChecked++; };
          if(this.countChecked==7){
            this.checkedAll = true;
          }
          this.approval_status = this.promotionData.approval_status;
          this.formPromotionEdit = this.fb.group({
            counterId: [parseInt(this.promotionData.counter_id)],
            warehouseId: [parseInt(this.promotionData.warehouse_id)],
            promotionName: [this.promotionData.promotion_name, Validators.required],
            earlyPeriod: [this.promotionData.early_period, Validators.required],
            endPeriod: [this.promotionData.end_period, Validators.required],
            promotionType: [parseInt(this.promotionData.promotion_type_id), Validators.required],
            promotionCashierType: [parseInt(this.promotionData.promotion_cashier_type), Validators.required],
            minimum: [this.promotionData.minimum, Validators.required],
            validOnSun: [validOnSun],
            validOnMon: [validOnMon],
            validOnTue: [validOnTue],
            validOnWed: [validOnWed],
            validOnThu: [validOnThu],
            validOnFri: [validOnFri],
            validOnSat: [validOnSat],
            checkedAll : [this.checkedAll],
            activeStatus: [parseInt(this.promotionData.status), Validators.required]
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const promotionData = this.formPromotionEdit.getRawValue();
      let earlyPeriods = this.utilService.convertDate(promotionData.earlyPeriod);
      let endPeriods = this.utilService.convertDate(promotionData.endPeriod);
      let newEarlyPeriod = earlyPeriods.years + '-' + earlyPeriods.months + '-' + earlyPeriods.dates;
      let newEndPeriod = endPeriods.years + '-' + endPeriods.months + '-' + endPeriods.dates;

      let validOnSun: any = '0', validOnMon: any = '0', validOnTue: any = '0', validOnWed: any = '0', validOnThu: any = '0',
      validOnFri: any = '0', validOnSat: any = '0';

      if(promotionData.validOnSun === true) { validOnSun = '1'; };
      if(promotionData.validOnMon === true) { validOnMon = '1'; };
      if(promotionData.validOnTue === true) { validOnTue = '1'; };
      if(promotionData.validOnWed === true) { validOnWed = '1'; };
      if(promotionData.validOnThu === true) { validOnThu = '1'; };
      if(promotionData.validOnFri === true) { validOnFri = '1'; };
      if(promotionData.validOnSat === true) { validOnSat = '1'; };

      const mstPromotion = new MstPromotion();
      mstPromotion.promotion_name = promotionData.promotionName;
      mstPromotion.early_period = newEarlyPeriod;
      mstPromotion.end_period = newEndPeriod;
      mstPromotion.promotion_type_id = promotionData.promotionType;
      mstPromotion.promotion_cashier_type = String(promotionData.promotionCashierType);
      mstPromotion.minimum = promotionData.minimum;
      mstPromotion.valid_on_su = validOnSun;
      mstPromotion.valid_on_mo = validOnMon;
      mstPromotion.valid_on_tu = validOnTue;
      mstPromotion.valid_on_we = validOnWed;
      mstPromotion.valid_on_th = validOnThu;
      mstPromotion.valid_on_fr = validOnFri;
      mstPromotion.valid_on_sa = validOnSat;
      mstPromotion.status = String(promotionData.activeStatus);
      mstPromotion.updated_by = this.userProfile.username;

      this.promotionService.updatePromotion(this.promotionId, mstPromotion).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/promotions']);;
          }
        }
      ]
    });

    await alert.present();
  }

  uncheckAll() {
    let counterId = this.formPromotionEdit.getRawValue().counterId;
    let warehouseId = this.formPromotionEdit.getRawValue().warehouseId;
    let promotionName = this.formPromotionEdit.getRawValue().promotionName;
    let earlyPeriod = this.formPromotionEdit.getRawValue().earlyPeriod;
    let endPeriod = this.formPromotionEdit.getRawValue().endPeriod;
    let promotionType = this.formPromotionEdit.getRawValue().promotionType;
    let promotionCashierType = this.formPromotionEdit.getRawValue().promotionCashierType;
    let minimum = this.formPromotionEdit.getRawValue().minimum;
    
    let validOnSun :boolean =true;
    let validOnMon :boolean =true;
    let validOnTue :boolean =true;
    let validOnWed :boolean =true;
    let validOnThu :boolean =true;
    let validOnFri :boolean =true;
    let validOnSat :boolean =true;
    if(this.checkedAll==false){
      validOnSun = true;
      validOnMon = true;
      validOnTue = true;
      validOnWed = true;
      validOnThu = true;
      validOnFri = true;
      validOnSat = true;
      this.checkedAll = true;
    }
    else{
      validOnSun = false;
      validOnMon = false;
      validOnTue = false;
      validOnWed = false;
      validOnThu = false;
      validOnFri = false;
      validOnSat = false;
      this.checkedAll = false;
    }   
    this.formPromotionEdit = this.fb.group({
      counterId: [counterId, Validators.required],
      warehouseId: [warehouseId, Validators.required],
      promotionName: [promotionName, Validators.required],
      earlyPeriod: [earlyPeriod, Validators.required],
      endPeriod: [endPeriod, Validators.required],
      promotionType: [promotionType, Validators.required],
      promotionCashierType: [promotionCashierType, Validators.required],
      minimum: [minimum, Validators.required],
      validOnSun: [validOnSun],
      validOnMon: [validOnMon],
      validOnTue: [validOnTue],
      validOnWed: [validOnWed],
      validOnThu: [validOnThu],
      validOnFri: [validOnFri],
      validOnSat: [validOnSat],
      checkedAll : [this.checkedAll],
    });    
  }
}