import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OtherExpencesCreatePageRoutingModule } from './other-expences-create-routing.module';

import { OtherExpencesCreatePage } from './other-expences-create.page';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    IonicSelectableModule,
    OtherExpencesCreatePageRoutingModule
  ],
  declarations: [OtherExpencesCreatePage]
})
export class OtherExpencesCreatePageModule {}
