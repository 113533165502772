import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  baseUrl: any;
  token: any;
  headers = new HttpHeaders();

  constructor(
    private http: HttpClient,
    private storage: Storage
  ) {
    this.baseUrl = environment.apiUrl;
    this.storage.get('user_token')
    .then((token: any) => {
      this.headers = this.headers.set('Accept', 'application/json');

      if(token) {
        this.token = token;
      } else {
        //
      }
    });
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    reqOpts = this.headers;
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        if (params.hasOwnProperty(k)) {
          reqOpts.params = reqOpts.params.set(k, params[k]);
        }
      }
    }

    return this.http.get(this.baseUrl + endpoint, { headers: reqOpts, params: reqOpts.params });
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    reqOpts = this.headers;
    reqOpts.params = new HttpParams();
    reqOpts.params = reqOpts.params.set('token', this.token);
    
    return this.http.post(this.baseUrl + endpoint, body, { headers: reqOpts, params: reqOpts.params });
  }

  put(endpoint: string, body: any, params?: any, reqOpts?: any) {
    reqOpts = this.headers;
    reqOpts.params = new HttpParams();
    reqOpts.params = reqOpts.params.set('token', this.token);

    return this.http.put(this.baseUrl + endpoint, body, { headers: reqOpts, params: reqOpts.params });
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    reqOpts = this.headers;
    reqOpts.params = new HttpParams();
    reqOpts.params = reqOpts.params.set('token', this.token);

    return this.http.patch(this.baseUrl + endpoint, body, { headers: reqOpts, params: reqOpts.params });
  }

  delete(endpoint: string, reqOpts?: any) {
    reqOpts = this.headers;
    reqOpts.params = new HttpParams();
    reqOpts.params = reqOpts.params.set('token', this.token);
    
    return this.http.delete(this.baseUrl + endpoint, { headers: reqOpts, params: reqOpts.params });
  }
}
