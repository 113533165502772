export class MemberPoint {
    
    id: number;
    customer_id: number;
    point_total: number;
    point_total_value: number;
    point_used: number;
    point_used_value: number;
    point_remains: number;
    point_remains_value: number;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.customer_id = model.customer_id || 0;
        this.point_total = model.point_total || 0;
        this.point_total_value = model.point_total_value || 0;
        this.point_used = model.point_used || 0;
        this.point_used_value = model.point_used_value || 0;
        this.point_remains = model.point_remains || 0;
        this.point_remains_value = model.point_remains_value || 0;
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}