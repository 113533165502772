import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PaymentVoucherPage } from './payment-voucher.page';

const routes: Routes = [
  {
    path: '',
    component: PaymentVoucherPage
  },
  {
    path: 'payment-voucher-create',
    loadChildren: () => import('./payment-voucher-create/payment-voucher-create.module').then( m => m.PaymentVoucherCreatePageModule)
  },
  {
    path: 'payment-voucher-edit',
    loadChildren: () => import('./payment-voucher-edit/payment-voucher-edit.module').then( m => m.PaymentVoucherEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentVoucherPageRoutingModule {}
