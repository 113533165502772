import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TutupKasirPageRoutingModule } from './tutup-kasir-routing.module';

import { TutupKasirPage } from './tutup-kasir.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    TutupKasirPageRoutingModule
  ],
  declarations: [TutupKasirPage]
})
export class TutupKasirPageModule {}
