import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';
import { UtilService } from '../../service/util.service';
import { ReportService } from '../report/report.service';
import { RecalculateStockService } from './recalculate-stock.service';

@Component({
  selector: 'app-recalculate-stock',
  templateUrl: './recalculate-stock.page.html',
  styleUrls: ['./recalculate-stock.page.scss'],
})
export class RecalculateStockPage implements OnInit {

  warehouseList: any[] = [];
  formRecalculateStock: FormGroup;
  year: any;
  month: any;
  monthName: any;
  userProfile: UserProfile = new UserProfile();
  token: any;

  warehouseId:number=0;
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private fb: FormBuilder,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private recalculateStockService: RecalculateStockService
  ) { }

  ngOnInit() {
    let todayDate = new Date();
    let convertDate = this.utilService.convertDate(todayDate);
    this.month = convertDate.months;
    this.buildRecalculateStockForm();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.getWarehouse();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildRecalculateStockForm() {
    this.formRecalculateStock = this.fb.group({
      stockDate: [],
      warehouseId: []
    });
  }

  getWarehouse() {
    let options = {
      "token": this.token,
      "counter_id": this.userProfile.counter_id_pos !== 0 ? this.userProfile.counter_id_pos : this.userProfile.counter_id_list
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.recalculateStockService.getWarehouse(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.warehouseList = response.results.warehouse_data;
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  recalculate() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const recalculateStock = this.formRecalculateStock.getRawValue();
      let dateConvert = this.utilService.convertDate(recalculateStock.stockDate);
      this.year = dateConvert.years;
      this.month = dateConvert.months;
      let body = {
        warehouse_id: this.warehouseId,//recalculateStock.warehouseId,
        year: this.year,
        month: this.month
      };
      
      this.recalculateStockService.recalculateStock(body).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.toastCtrl.create({ duration: 2000, message: 'Sukses' }).then(t => t.present());
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  public selectWarehouse(event){
    this.warehouseId = event.value.id;
  }

}
