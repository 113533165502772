
import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { Storage } from '@ionic/storage';
import * as XLSX from 'xlsx';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';
import { UserProfile } from '../../../models/user-profile.model';
import { ReportCounterComponent } from '../../../component/report-counter/report-counter.component';

@Component({
  selector: 'app-report-counter',
  templateUrl: './report-counter.page.html',
  styleUrls: ['./report-counter.page.scss'],
})
export class ReportCounterPage implements OnInit {
  dataSummaryStock:any[]=[];
  year : any=(new Date()).getFullYear();
  month: any=(new Date()).getMonth();
  token : any;  
  periodeReport:any = '';
  periodeStart:any = new Date().toISOString();
  periodeEnd:any = new Date().toISOString();
  colspanTotal:number=1;

  selectedBranchs='';
  selectedLocations = '';

  roleAccess = new RoleAccess();
  userProfile: UserProfile = new UserProfile();
  branchList:any;
  counterList:any;

  counterByLogin : any;
  //filter
  rekapPerId:any='';
  rekapPerList:any=[];
  rekapPerName:any='';
  filterCounterName:any='';
  filterBranchName:any='';
  filterCounterShapeName:any='';
  filterLocationName:any='';
  counterShape:any='';
  showReport:boolean=true;
  reportTitle:any='Hide Report';

  //filter kolom result
  filterBySDM:boolean=false;
  filterByLuas:boolean=false;
  filterByOperational:boolean=false;
  filterByKonsiNon:boolean=false;
  filterByLocation:boolean=false;
  paramCounter = '';
  paramBrand = '';

  arrayDate:any[]=[];
  rowspanResult:number =1;
  colspanSpg:number=1;
  constructor(
    private reportService :ReportService,
    private toastCtrl : ToastController,
    private utilService :UtilService,
    private storage: Storage,
    private userData: UserData,
    private navCtrl: NavController,
    private router: Router,
    private modalController :ModalController
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        
        this.rekapPerList = [
          {'name':'COH SDM','id':1},
          {'name':'COH by Luas Area','id':2},
          {'name':'COH By Operasional','id':3},
          {'name':'COH By Konsi-non','id':4},
          {'name':'COH By Lokasi','id':5}
        ];
        
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {     
          this.userProfile = new UserProfile(profile);
          this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": this.counterByLogin
          };
          this.reportService.getReportSummaryIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.branchList = response.results.branch_data;
            this.counterList = response.results.counter_data;
          });
        });
      }
    });
  }
  
  getDates(startDate:any, stopDate:any) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(currentDate)
      var dat = new Date(currentDate)
      dat.setDate(dat.getDate() + 1);
      currentDate = dat;
    }
    return dateArray;
  }

  resetfilter(){
    this.dataSummaryStock = [];
    this.rekapPerName ='';
    this.rekapPerId ='';
    this.filterBySDM=false;
    this.filterByLuas= false;
    this.filterByOperational= false;
    this.filterByKonsiNon= false;
    this.filterByLocation= false;
    
    this.filterCounterName ='';
    this.filterBranchName='';
  }
  
  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component:  ReportCounterComponent,
      componentProps: {
        rekapPer:this.rekapPerId,
        counterShape:this.counterShape,
        selectedBranchList:this.selectedBranchs,
        counterLocation:this.selectedLocations
      },
      backdropDismiss: false
    });
    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if (data !== 'null') {
        this.resetfilter();        
        this.rekapPerName = data[0]['rekapPerName'];
        this.rekapPerId = data[0]['rekapPer'];
        this.selectedBranchs = data[0]['selectedBranchList'];
        this.filterBranchName = data[0]['selectedBranchListName'];
        this.counterShape ='';
        this.selectedLocations ='';
        this.filterLocationName = '';
        if(data[0]['rekapPer']=='1'){
          this.filterBySDM=true;
        }
        if(data[0]['rekapPer']=='2'){
          this.filterByLuas=true;
          this.rowspanResult=2;
          this.counterShape = data[0]['counterShape'];
          this.filterCounterName = data[0]['counterShapeName'];
        }
        if(data[0]['rekapPer']=='3'){
          this.filterByOperational=true;
        }
        if(data[0]['rekapPer']=='4'){
          this.filterByKonsiNon=true;
        }
        if(data[0]['rekapPer']=='5'){
          this.filterByLocation=true;
          this.selectedLocations =  data[0]['counterLocation'];
          this.filterLocationName = data[0]['counterLocationName'];
        }       
        
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          let body = {
            rekapPer:data[0]['rekapPer'],
            counterShape :this.counterShape,
            selectedBranchs:this.selectedBranchs,
            selectedLocations:this.selectedLocations
          };
          this.reportService.getReportCounter(body).subscribe((response) => {
            this.utilService.loadingDismiss();
            if(response.status.code === 200) {
              this.colspanSpg =response.results.max_spg;
              this.dataSummaryStock = response.results.summary_stock;
              this.showReport=true;
            } else {
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            }
          }, (err) => {
            this.utilService.loadingDismiss();
            console.log(err);
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        })
      }
    });
    return await modal.present();
  }
   
  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table-counter');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    for(let i=0;i<(this.dataSummaryStock.length+3);i++){
      if(i==0){
        ws['!cols'].push({ width: 5 });
      }
      else if(i==1){
        ws['!cols'].push({ width: 10 });
      }
      else if(i==3){
        ws['!cols'].push({ width: 3 });
      }
      else{
        ws['!cols'].push({ width: 20 });
      }
    }

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Report Counter.xlsx');
  }
  
  toggleReportStock() {
    if(this.showReport === false) {
      this.showReport = true;
      this.reportTitle = 'Hide Report';
    } else {
      this.showReport = false;
      this.reportTitle = 'Show Report';
    }
  }
}