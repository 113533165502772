import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';
import { UserProfile } from '../../models/user-profile.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService) { }

  getUser(params: any): Observable<any> {
    return this.httpService.get('user', params);
  }
  getAllUser(params: any): Observable<any> {
    return this.httpService.get('all-user', params);
  }

  getUserbyId(userId: any, params: any): Observable<any> {
    return this.httpService.get(`user/${userId}/edit`, params);
  }

  getRole(params: any): Observable<any> {
    return this.httpService.get('role-get-all', params);
  }

  getCounter(params: any): Observable<any> {
    return this.httpService.get('counter-all', params);
  }
  getCashierByCounterId(params: any): Observable<any> {
    return this.httpService.get('cashier-list-by-counter', params);
  }

  pullUser(data: UserProfile): Observable<any> {
    return this.httpService.post('user/pull', data);
  }

  updateUser(userId: any, data: UserProfile): Observable<any> {
    return this.httpService.put(`user/${userId}`, data);
  }
  createUser(data: UserProfile): Observable<any> {
    return this.httpService.post(`user`, data);
  }

  getCounterByBranch(data: any): Observable<any> {
    return this.httpService.get('user/get-counter-by-branch', data);
  }

  getCashier(params: any): Observable<any> {
    return this.httpService.get('cashier-all', params);
  }

  searchUser(data: any): Observable<any> {
    return this.httpService.get('search-user', data);
  }
  
  getWaitingApprovalRegistration(params: any): Observable<any> {
    return this.httpService.get('waiting-approval-registration', params);
  }
  approvalRegistration(params: any): Observable<any> {
    return this.httpService.post('approval-registration', params);
  }
  getUserRegistration(id:number): Observable<any> {
    return this.httpService.get('req-approval-registration/'+id);
  }
  

}
