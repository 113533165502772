import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { ClosedDay } from '../../models/closed-day.model';
import { MstCounter } from '../../models/mst-counter.model';

@Injectable({
  providedIn: 'root'
})
export class ClosedDayService {

  constructor(private httpService: HttpService) { }

  processClosedDay(params: any): Observable<any> {
    return this.httpService.get('process-closed-day', params);
  }

  updateCounter(counterId: any, data: MstCounter): Observable<any> {
    return this.httpService.put(`counter/${counterId}`, data);
  }
  listWarehouse(counterId: any): Observable<any> {
    return this.httpService.post('list-warehouse-by-counter',counterId);
  }
}
