import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MyService } from '../../service/my.service';

@Component({
  selector: 'app-product-sales',
  templateUrl: './product-sales.component.html',
  styleUrls: ['./product-sales.component.scss'],
})
export class ProductSalesComponent implements OnInit {

  @Input() pCode: any;
  @Input() productName: any;
  @Input() qtyFree: any;
  @Input() valPromoNominal: any;
  @Input() valPromoPercentage: any;

  constructor(public modalController: ModalController,
    private myService:MyService
  ) { }

  ngOnInit() {}

  async dismissModal() {
    let close: any[] = [];

    let productSalesData = {
      qtyFree: this.qtyFree ? this.myService.removeSeparator(this.qtyFree) : 0,
      valPromoNominal: this.valPromoNominal ? this.myService.removeSeparator(this.valPromoNominal): 0,
      valPromoPercentage: this.valPromoPercentage ? this.myService.removeSeparator(this.valPromoPercentage) : 0
    };

    close.push(productSalesData);
    
    await this.modalController.dismiss(close);
  }
  
  separatorQty() {
    this.qtyFree = this.myService.separator(this.qtyFree);
    this.qtyFree = String(this.qtyFree).replace(/[^0-9,]/g, '');
  }
  separatorDiskonNominal(){
    this.valPromoNominal = this.myService.separator(this.valPromoNominal);
    this.valPromoNominal = String(this.valPromoNominal).replace(/[^0-9,]/g, '');
  }
  separatorDiskonPersen(){
    this.valPromoPercentage = this.myService.separator(this.valPromoPercentage);
    this.valPromoPercentage = String(this.valPromoPercentage).replace(/[^0-9,]/g, '');
  }
  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

}
