import { ProductRequest, ProductRequestDetail } from './product-request.model';
import { ApprovalHistory } from './approval-history.model';

export class ProductRequestBundle {

    productRequest: ProductRequest;
    productRequestDetail: ProductRequestDetail;
    approvalHistory: ApprovalHistory;

    constructor(model?) {
        model = model || {};
        this.productRequest = model.productRequest || new ProductRequest();
        this.productRequestDetail = model.productRequestDetail || new ProductRequestDetail();
        this.approvalHistory = model.approvalHistory || new ApprovalHistory();
    }
}