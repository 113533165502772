import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-voucher-edit',
  templateUrl: './payment-voucher-edit.page.html',
  styleUrls: ['./payment-voucher-edit.page.scss'],
})
export class PaymentVoucherEditPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
