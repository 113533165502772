import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PosPromoOtoResellerPageRoutingModule } from './pos-promo-oto-reseller-routing.module';

import { PosPromoOtoResellerPage } from './pos-promo-oto-reseller.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PosPromoOtoResellerPageRoutingModule
  ],
  declarations: [PosPromoOtoResellerPage]
})
export class PosPromoOtoResellerPageModule {}
