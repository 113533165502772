import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterProductGimmickService {
  constructor(private httpService: HttpService) { }
  
  getProductGimmick(params: any): Observable<any> {
    return this.httpService.get('mst-product-gimmick', params);
  }
  
  getProductGimmickForEdit(product_id: any, params: any): Observable<any> {
    return this.httpService.get(`mst-product-gimmick/${product_id}/edit`, params);
  }

  addProduct(params:any): Observable<any> {
    return this.httpService.post('mst-product-gimmick-add', params);
  }
  updateProduct(params:any): Observable<any> {
    return this.httpService.put('mst-product-gimmick-edit', params);
  }
  getProductSearch(params: any): Observable<any> {
    return this.httpService.get('mst-product-gimmick/get-product', params);
  }
}
