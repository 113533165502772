import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { StockOpname, StockOpnameDetail, StockOpnameBundle, StockOpnameBundleUpload } from '../../models/stock-opname.model';
import { ApprovalHistory } from '../../models/approval-history.model';
import { StockMutation } from '../../models/stock-mutation.model';

@Injectable({
  providedIn: 'root'
})
export class StockOpnameService {

  constructor(private httpService: HttpService) { }

  getStockOpname(params: any): Observable<any> {
    return this.httpService.get('stock-opname', params);
  }

  getWarehouse(params: any): Observable<any> {
    return this.httpService.get('warehouse-all', params);
  }

  getCounter(params: any): Observable<any> {
    return this.httpService.get('counter-all', params);
  }

  getStockOpnameforCreate(params: any): Observable<any> {
    return this.httpService.get('stock-opname/create', params);
  }

  getStockOpnameDetailProduct(params: any): Observable<any> {
    return this.httpService.get('stock-opname-product', params);
  }

  getStockOpnameforEdit(stockOpnameId: any, params: any): Observable<any> {
    return this.httpService.get(`stock-opname/${stockOpnameId}/edit`, params);
  }

  getStockOpnameLatestId(params: any): Observable<any> {
    return this.httpService.get('stock-opname-latestid', params);
  }

  getStockOpnameforPrint(params: any): Observable<any> {
    return this.httpService.get('so-for-print', params);
  }

  addStockOpname(data: StockOpname): Observable<any> {
    return this.httpService.post('stock-opname', data);
  }

  addStockOpnameDetail(data: StockOpnameDetail): Observable<any> {
    return this.httpService.post('stock-opname-detail', data);
  }

  addApprovalHistory(data: ApprovalHistory): Observable<any> {
    return this.httpService.post('approval-history', data);
  }

  addStockMutation(data: StockMutation): Observable<any> {
    return this.httpService.post('stock-mutation/store', data);
  }

  addStockOpnameBundle(data: StockOpnameBundle): Observable<any> {
    return this.httpService.post('stock-opname', data);
  }

  updateStockOpname(stockOpnameId: any, data: StockOpname): Observable<any> {
    return this.httpService.put(`stock-opname/${stockOpnameId}`, data);
  }

  updateStockOpnameBundle(stockOpnameId: any, data: StockOpnameBundle): Observable<any> {
    return this.httpService.put(`stock-opname/${stockOpnameId}`, data);
  }

  manageStock(params: any): Observable<any> {
    return this.httpService.get('stock/manage-stock', params);
  }

  uploadFile(data: any): Observable<any> {
    return this.httpService.post('upload-data-so', data);
  }
}
