import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { SalesTransaction, SalesTransactionDetail, SalesTransactionPayments } from '../../models/sales-transaction.model';
import { PointMutation } from '../../models/point-mutation.model';
import { MemberPoint } from '../../models/member-point.model';
import { StockMutation } from '../../models/stock-mutation.model';
import { MstVoucherDetail } from '../../models/mst-voucher.model';
import { SalesTransactionBundle } from '../../models/sales-transaction-bundle.model';

@Injectable({
  providedIn: 'root'
})
export class PosResellerService {

  constructor(private httpService: HttpService) { }

  getSetupCashier(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller-cashier', params);
  }

  getProduct(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/product', params);
  }

  getProductReseller(params: any): Observable<any> {
    return this.httpService.post('sales-transaction-reseller/product-reseller', params);
  }

  getLatestReceiptNo(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/latest-receipt-no', params);
  }

  getMember(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/member', params);
  }

  getMemberById(params: any, memberId: any): Observable<any> {
    return this.httpService.get(`member-point/get-by-customer/${memberId}`, params);
  }

  getVoucher(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/voucher', params);
  }

  getEdcMachine(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/edc', params);
  }

  getPromotion(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/promotion', params);
  }

  getCurrencies(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/currency', params);
  }

  getPaymentMethods(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/payment-methods', params);
  }

  addSalesTransaction(data: SalesTransaction): Observable<any> {
    return this.httpService.post('sales-transaction-reseller/store', data);
  }

  addSalesTransactionDetail(data: SalesTransactionDetail): Observable<any> {
    return this.httpService.post('sales-transaction-detail/store', data);
  }

  addSalesTransactionPayment(data: SalesTransactionPayments): Observable<any> {
    return this.httpService.post('sales-transaction-payment/store', data);
  }

  addPointMutation(data: PointMutation): Observable<any> {
    return this.httpService.post('point-mutation', data);
  }

  addStockMutation(data: StockMutation): Observable<any> {
    return this.httpService.post('stock-mutation/store', data);
  }

  addSalesTransactionBundle(data: SalesTransactionBundle): Observable<any> {
    return this.httpService.post('sales-transaction-reseller/storebundle', data);
  }

  updateMemberPoint(data: MemberPoint, memberId: any): Observable<any> {
    return this.httpService.put(`member-point/${memberId}`, data);
  }

  updateVoucherUsed(data: MstVoucherDetail): Observable<any> {
    return this.httpService.put('voucher-used-update', data);
  }

  manageStock(params: any): Observable<any> {
    return this.httpService.get('stock/manage-stock', params);
  }

  getRewardTerm(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/reward-term', params);
  }

  getCustomerDownline(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/downline', params);
  }

  getRewardMutation(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/reward-mutation', params);
  }

  getRewardReseller(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/reward-reseller', params);
  }

  getReseller(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/get-reseler', params);
  }

  getProductSearch(params: any): Observable<any> {
    return this.httpService.get('sales-transaction-reseller/get-product', params);
  }

  checkConnectionServer(params: any): Observable<any> {
    return this.httpService.post('check-connection', params);
  }
  checkStock(data: any): Observable<any> {
    return this.httpService.get('check-stock',data);
  }
}
