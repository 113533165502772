
import { Component, ViewChild, OnInit } from '@angular/core';
import { IonInput, ToastController, NavController, AlertController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserData } from '../../providers/user-data';
import { UserOptions } from '../../interfaces/user-options';
import { UtilService } from '../../service/util.service';
import { Storage } from '@ionic/storage';
import { RegisterService } from './register.service';
import { LoginService } from '../login/login.service';
import { CounterService } from '../counter/counter.service';
import { IonicSelectableComponent } from 'ionic-selectable';
@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
  @ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;

  @ViewChild('username', {static: false}) username: IonInput;
  @ViewChild('nik', {static: false}) nik: IonInput;
  @ViewChild('email', {static: false}) email: IonInput;
  @ViewChild('fullname', {static: false}) fullname: IonInput;
  @ViewChild('whatsappNumber', {static: false}) whatsappNumber: IonInput;
  @ViewChild('password', {static: false}) password: IonInput;
  @ViewChild('confirmPassword',{static:false}) confirmPassword :IonInput;

  register: UserOptions = { username: '', password: '' ,
    email:'',
    nik:'',
    whatsappNumber:'',
    fullname:'',confirmPassword:''};
  submitted:boolean = false;
  multipleCashier:boolean = false;
  msgCounter:any='';
  msgFullname:any='';
  msgUsername:any='';
  msgEmail:any='';
  msgWhatsappNumber:any='';
  msgPassword:any='';
  msgConfirmPassword:any='';
  showPassword:boolean;
  showConfirmNewPassword:boolean;
  listActiveCounter:any[]=[];
  counterId:any='0';
  selectedCounterList:any[]=[];
  constructor(
    public userData: UserData,
    public router: Router,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private storage: Storage,
    private loginService:LoginService,
    private registerService: RegisterService,
    private counterService:CounterService,
    private navCtrl: NavController,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.checkLogin();
  }

  ionViewDidEnter() {
    this.counterId='0';
    this.showPassword=false;
    this.showConfirmNewPassword=false;
    this.msgCounter='';
    this.msgFullname='';
    this.msgUsername='';
    this.msgEmail='';
    this.msgWhatsappNumber='';
    this.msgPassword='';
    this.msgConfirmPassword='';
    this.nik.setFocus();
    this.selectedCounterList=[];
    this.counterService.getAllActiveCounter().subscribe((res:any)=>{
      this.listActiveCounter =res.results;
    })
    this.checkLogin();
  }
  checkLogin() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
      .then(([token, profile]) => {
      if(token) {
        this.navCtrl.navigateForward(['/home']);
      }
    });
  }

  checkUsername(form:NgForm){
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let param ={
        'username':this.register.username
      }
      this.loginService.checkUserNonKPI(param).subscribe((res: any) => {
        if(res.code!=300){
          this.utilService.loadingDismiss();
          this.showAlert('Username sudah ada. Silahkan login atau ganti username','stay');
        }
        else{
          this.submitRegister(form);
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Registrasi Gagal' }).then(t => t.present());
      });
    }, (err) => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Registrasi Gagal' }).then(t => t.present());
    });
  }
  onSubmit(form: NgForm) {
    this.submitted = true;
    let arrform =form.controls['email'];
    let arrpassword =form.controls['password'];
    let arrconfirmpassword =form.controls['confirmPassword'];
    let email= arrform.value;
    let password= arrpassword.value;
    let confirmPassword= arrconfirmpassword.value;
    let error = 0;
    this.msgCounter='';
    this.msgFullname='';
    this.msgUsername='';
    this.msgEmail='';
    this.msgWhatsappNumber='';
    this.msgEmail='';
    this.msgWhatsappNumber='';
    this.msgPassword='';
    this.msgConfirmPassword='';
    if(this.counterId=='0'){
      error++;
      this.msgCounter='Counter is Required';
    }
    if(this.fullname.value==''){
      error++;
      this.msgFullname='Fullname is Required';
    }
    if(this.username.value==''){
      error++;
      this.msgUsername='Username is Required';
    }
    if(this.email.value==''){
      error++;
      this.msgEmail='Email is Required';
    }
    else{
      let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      let serchfind = regexp.test(email);
      if(!serchfind){
        error++;
        this.msgEmail='Format Email not Valid';
      }
    }
    if(this.whatsappNumber.value==''){
      error++;
      this.msgWhatsappNumber='Whatsapp Number is Required';
    }
    if(this.password.value==''){
      error++;
      this.msgPassword='Password is Required';
    }else{
      if(password.length<6){
        error++;
        this.msgPassword='Password minimum 6 character';
      }
    }
    if(this.confirmPassword.value==''){
      error++;
      this.msgConfirmPassword='Confirm Password is Required';
    }else{
      if(confirmPassword.length<6){
        error++;
        this.msgConfirmPassword='Password minimum 6 character';
      }
      else{
        if(this.password.value!=this.confirmPassword.value){
          error++;
          this.msgConfirmPassword="Password and Confirm Password didn't match";

        }
      }
    }
    if (error==0) {
      this.checkUsername(form);
    }
  }
  
  confirm() {
    this.msgCounter='';
    this.selectedCounterList = [];
    this.counterComponent.confirm();
    this.counterComponent.close();
    this.selectedCounterList = this.counterComponent.value;
    let idCounter='';
    for(let i=0;i<this.selectedCounterList.length;i++){
      idCounter += this.selectedCounterList[i].id+',';
    }
    idCounter = idCounter.replace(/(^,)|(,$)/g, "");
    this.counterId = idCounter;
    if(this.selectedCounterList.length==0){
      this.msgCounter='Counter is Required';
    }
  }
  toggleItems() {
    this.counterComponent.toggleItems(
      this.counterComponent.itemsToConfirm.length ? false : true
    );
  }


  submitRegister(form:NgForm){
      this.registerService.userRegister(this.register.username, this.register.password,this.register.nik,this.register.fullname,this.register.email,this.register.whatsappNumber,this.counterId)
      .subscribe( (response: any) => {
        this.utilService.loadingDismiss();
        if(response.status=='200'){
          form.resetForm();
          this.showAlert('Registrasi Berhasil.<br>Kami akan meninjau permintaan akun Anda.','goto');
        }
        else if(response.status=100){
          this.utilService.loadingDismiss();
          this.showAlert(response.message,'stay');
        }
        else{
          this.toastCtrl.create({ duration: 2000, message: 'Registrasi Gagal' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Registrasi Gagal' }).then(t => t.present());
      });
    }
  async showAlert(message: any,action:any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            if(action=='goto'){
              this.gotoLoginPage();
            }
          }
        }
      ]
    });
    await alert.present();
  }
  gotoLoginPage(){
    this.navCtrl.navigateForward(['/login']);
  }
  validateInput(code:any){
    if(code=='fullname'){
      this.msgFullname='';
      if(this.fullname.value==''){
        this.msgFullname='Fullname is Required';
      }
    }
    else if(code=='username'){
      this.msgUsername='';
      if(this.username.value==''){
        this.msgUsername='Username is Required';
      }
    }
    else if(code=='email'){
      this.msgEmail='';
      if(this.email.value==''){
        this.msgEmail='Email is Required';
      }
    }
    else if(code=='whatsappNumber'){
      this.msgWhatsappNumber='';
      if(this.whatsappNumber.value==''){
        this.msgWhatsappNumber='whatsapp Number is Required';
      }
    }
    else if(code=='password'){
      this.msgPassword='';
      if(this.password.value==''){
        this.msgPassword='Password is Required';
      }
    }
    else if(code=='confirmPassword'){
      this.msgConfirmPassword='';
      if(this.confirmPassword.value==''){
        this.msgConfirmPassword='Confirm Password is Required';
      }
    }
  }
  moveField(code: any) {
    switch(code.name) {
        case "fullname":
          setTimeout(() => {
            this.fullname.setFocus();
          }, 300);
        break;
          case "email":
            setTimeout(() => {
              this.email.setFocus();
            }, 300);
        break;
        case "username":
          setTimeout(() => {
            this.username.setFocus();
          }, 300);
        break;
        case "password":
          setTimeout(() => {
            this.password.setFocus();
          }, 300);
        break;

        default:
          break;
    }
  }

  clearCache() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      caches.keys().then(function(names) {
        for(let name of names) {
          console.log(name);
          caches.delete(name);
        }
      });

      this.utilService.loadingDismiss();
      this.showNotifAfterUpdates("Berhasil lakukan update!");
    });
  }

  async showNotifAfterUpdates(messageValue: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: messageValue,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            window.location.href = "./";
          }
        }
      ]
    });

    await alert.present();
  }
  clsAlphaNoOnly (event: any) { 
    const pattern = /[0-9a-zA-Z]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  numOnly (event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  toggleConfirmPasswordVisibility() {
    this.showConfirmNewPassword = !this.showConfirmNewPassword;
  }
}