

import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import {  FormBuilder } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { MasterRewardService } from '../master-reward.service';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';

class User {
  public id: string;
  public name: string;
}
@Component({
  selector: 'app-master-reward-create',
  templateUrl: './master-reward-create.page.html',
  styleUrls: ['./master-reward-create.page.scss'],
})
export class MasterRewardCreatePage implements OnInit {
    rewardId: any;
    masterRewardData: any;
    token: any;
    userProfile: UserProfile = new UserProfile();
    roleAccess = new RoleAccess();  
    rewardTypeList:any[];
    statusList: any[];
    nominalCashback:number=0;
    itemName:any='';
    rewardType:number=0;
    rewardName:any='';
    showItemName:boolean=false;
    showNominal:boolean=false;
    selectedRewards:any;
    selectedStatus:any;
    activeStatus:any;
    constructor(
      private fb: FormBuilder,
      private activeRoute: ActivatedRoute,
      private utilService: UtilService,
      private alertController: AlertController,
      private navCtrl: NavController,
      private toastCtrl: ToastController,
      private storage: Storage,
      private masterRewardService: MasterRewardService,
      private userData: UserData,
      private router: Router,
      private modalController: ModalController
    ) { }
  
    ngOnInit() {
      Promise.all([
        this.storage.get('user_token'),
        this.storage.get('user_profile'),
        this.storage.get('user_menu_access')
      ])
      .then(([token, profile, access]) => {
        if(token) {
          this.token = token;
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.update!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          let typeReward=[
            {
                'id':1,
                'reward_type_name':'Cashback'
              },{
              'id':2,
            'reward_type_name':'Item'}
          ];
          this.statusList =[{'id':'0',
          'status_name':
          'Not Active'},{'id':'1','status_name':'Active'}];
          this.rewardTypeList= typeReward;
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
          this.navCtrl.navigateForward(['/login']);
        }
      });
    }
  
    insertData() {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let detail ={          
          reward_id:this.rewardId,
          reward_name:this.rewardName,
          reward_type_id:this.rewardType,
          nominal_cashback:this.nominalCashback,
          item_name:this.itemName,
          is_active:this.activeStatus,
          updated_by:this.userProfile.username
        }
  
        this.masterRewardService.addReward(detail).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 200) {
            this.showConfirmUpdate();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    }
  
    async showConfirmUpdate() {
      const alert = await this.alertController.create({
        header: 'Notification',
        cssClass:'custom-alert-class',
        message: 'Data sudah tersimpan!',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.navCtrl.navigateForward(['/master-reward']);;
            }
          }
        ]
      });
  
      await alert.present();
    }
    
    selectRewardType(event:any){
      this.rewardType = event.value.id;
      this.showHideColumn();
    }

    showHideColumn(){
      if(this.rewardType==1){
        this.showNominal=true;
        this.showItemName=false;
      }else{
        this.showItemName=true;
        this.showNominal=false;
      }
    }
    selectStatus(event:any){
      this.activeStatus = event.value.id;
    }
  }
