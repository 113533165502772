import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { StockOpnameCreatePageRoutingModule } from './stock-opname-create-routing.module';

import { StockOpnameCreatePage } from './stock-opname-create.page';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    StockOpnameCreatePageRoutingModule,
    IonicSelectableModule
  ],
  declarations: [StockOpnameCreatePage]
})
export class StockOpnameCreatePageModule {}
