import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SatuanPageRoutingModule } from './satuan-routing.module';

import { SatuanPage } from './satuan.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SatuanPageRoutingModule
  ],
  declarations: [SatuanPage]
})
export class SatuanPageModule {}
