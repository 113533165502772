import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MasterProductGimmickPageRoutingModule } from './master-product-gimmick-routing.module';

import { MasterProductGimmickPage } from './master-product-gimmick.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MasterProductGimmickPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [MasterProductGimmickPage]
})
export class MasterProductGimmickPageModule {}
