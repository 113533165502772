import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { ModalKasirEditPageRoutingModule } from './modal-kasir-edit-routing.module';

import { ModalKasirEditPage } from './modal-kasir-edit.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalKasirEditPageRoutingModule,
    ReactiveFormsModule
  ],
  declarations: [ModalKasirEditPage]
})
export class ModalKasirEditPageModule {}
