import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SetorKasirPage } from './setor-kasir.page';

const routes: Routes = [
  {
    path: '',
    component: SetorKasirPage
  },
  {
    path: 'setor-kasir-edit',
    loadChildren: () => import('./setor-kasir-edit/setor-kasir-edit.module').then( m => m.SetorKasirEditPageModule)
  },
  {
    path: 'setor-kasir-create',
    loadChildren: () => import('./setor-kasir-create/setor-kasir-create.module').then( m => m.SetorKasirCreatePageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SetorKasirPageRoutingModule {}
