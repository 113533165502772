import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-report-cashier-counter-list',
  templateUrl: './report-cashier-counter-list.component.html',
  styleUrls: ['./report-cashier-counter-list.component.scss'],
})
export class ReportCashierCounterListComponent implements OnInit {

  @Input() counterList: any[];

  constructor(
    public modalController: ModalController) { }

  ngOnInit() {}

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }

}
