import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { RoleAccess } from '../../models/role-access.model';
import { UserData } from '../../providers/user-data';
import { Router } from '@angular/router';
import {  NavController, ToastController } from '@ionic/angular';
import { AirportService } from './airport.service';

@Component({
  selector: 'app-airport',
  templateUrl: './airport.page.html',
  styleUrls: ['./airport.page.scss'],
})
export class AirportPage implements OnInit {
  token: any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  rows:any='';
  constructor(
    private storage: Storage,
    private utilService: UtilService,
    private userData: UserData,
    private router: Router,
    private toastCtrl:ToastController,
    private navCtrl:NavController,
    private airportService:AirportService
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.create!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          else{
            let options={
              "token": this.token,
              "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
            }
            this.airportService.getAirport(options).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.rows = response.results.airport_data;
            });
          }
        });
      }
      else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  
  goToAirportRegister() {
    this.navCtrl.navigateForward(['/airport/airport-register']);
  }
}
