import { Component, OnInit } from '@angular/core';
import { NavController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UtilService } from '../../../../service/util.service';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportService } from '../../report.service';

@Component({
  selector: 'app-report-reprint-struck',
  templateUrl: './report-reprint-struck.page.html',
  styleUrls: ['./report-reprint-struck.page.scss'],
})
export class ReportReprintStruckPage implements OnInit {

  monthList: any[] = ['JAN', 'FEB', 'MAR', 'APR', 'MEI', 'JUN', 'JUL', 'AGU', 'SEP', 'OKT', 'NOV', 'DES'];
  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];

  formReprintReceipt: FormGroup;
  token: string;
  userProfile: UserProfile = new UserProfile();
  printers: string[];
  isDefaultPrinterSelected = false;
  selectedPrinter: any;

  productSales: any[] = [];
  salesData: any;
  pointMutationData: any[] = [];
  customerData: any;
  receiptNo: any;
  transDate: any;
  transTime: any;
  cashierName: string = '';
  totalPaymentWithoutPromo: number = 0;
  totalPayment: number = 0;
  totalItem: number = 0;
  voucherValue: number = 0;
  pointMutationType: any = '';
  customerCredit: number = 0;
  customerDebit: number = 0;
  memberPhone: any = '';
  totalPointAdd: number = 0;
  memberPointValueUsed: number = 0;
  memberPointValue: number = 0;
  memberPointUsed: number = 0;
  totalChange: number = 0;
  totalCustomerCash: number = 0;
  firstAddress: any;
  lastAddress: any;
  counterPhone: any;
  transactionType: any;
  customerTransfer: number = 0;
  resellerName: any = '';
  resellerCode: any = '';
  transactionDiscount : number =0;

  constructor(
    private fb: FormBuilder,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private modalController: ModalController,
    private storage: Storage,
    private alertController: AlertController,
    private reportService: ReportService) { }

  ngOnInit() {
    this.buildFormReprintReceipt();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
      }

      // WebSocket settings
     JSPM.JSPrintManager.auto_reconnect = true;
     JSPM.JSPrintManager.start();
     JSPM.JSPrintManager.WS.onStatusChanged = () => {
       if (this.jspmWSStatus()) {
         // get client installed printers
         JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
           this.printers = myPrinters;
           this.isDefaultPrinterSelected = true;
          });
        }
      };
    });
  }

  jspmWSStatus() { // Check JSPM WebSocket status
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
        alert('JSPM has blocked this website!');
        return false;
    }
  }

  buildFormReprintReceipt() {
    this.formReprintReceipt = this.fb.group({
      receiptNo: [null]
    });
  }

  getReceipt() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formReprintReceipt = this.formReprintReceipt.value;

      this.reportService.getSalesByReceiptNumber({ "token": this.token }, formReprintReceipt.receiptNo)
      .subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code == 200) {
          this.productSales = response.results.product_sales;
          this.salesData = response.results.sales_data;
          this.pointMutationData = response.results.point_mutation_data;
          this.customerData = response.results.customer_data;
          this.firstAddress = this.salesData.first_address;
          this.lastAddress = this.salesData.last_address;
          this.counterPhone = this.salesData.phone;
          this.receiptNo = this.salesData.receipt_no;
          this.transDate = this.salesData.trans_date;
          this.transTime = this.salesData.trans_time;
          this.cashierName = this.salesData.name_user;
          this.totalCustomerCash = this.salesData.cash;
          this.totalChange = this.salesData.change;
          this.customerDebit = this.salesData.debit_card;
          this.customerCredit = this.salesData.credit_card;
          this.voucherValue = this.salesData.voucher;
          this.transactionType = this.salesData.transaction_type;
          this.customerTransfer = this.salesData.transfer;
          this.transactionDiscount = this.salesData.discount;

          if(this.pointMutationData.length > 0) {
            for(let x = 0; x < this.pointMutationData.length; x++) {
              this.memberPhone = this.pointMutationData[x].phone;

              if(this.pointMutationData[x].point_mutation_type_id === 'O') {
                this.memberPointValue = parseInt(this.pointMutationData[x].point) * 100;
                this.memberPointUsed = this.pointMutationData[x].point;
              }

              if(this.pointMutationData[x].point_mutation_type_id === 'I') {
                this.totalPointAdd = this.pointMutationData[x].point;
              }
            }
          }

          if(this.customerData !== null) {
            this.resellerName = this.customerData.customer_name;
            this.resellerCode = this.customerData.referral_code;
          }

          this.calcTotal();
          this.printReceipt();
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Sales!' }).then(t => t.present());
      });
    });
  }

  printReceipt() {
    console.log('this.userProfile.counter_detail.trans_date', this.userProfile.counter_detail.trans_date);
    console.log('this.transDate', this.transDate);
    console.log('new Date()', new Date());
    var dT = new Date(this.transDate+' '+this.transTime);
    let transactionDate = dT;
    let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
    let convertTime = this.utilService.convertDateWithMoment(dT, timezoneName);
    let convertDate = this.utilService.convertDate(transactionDate);

    let monthName: any = this.monthList[(parseInt(convertDate.months)-1)];
    let transDate = convertDate.dates + '-' + monthName + '-' + convertDate.years;
    let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

    let totalPromo: number = 0;

    //Create a ClientPrintJob
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += '---------------------------------';
    cmds += 'NO STRUK : ' + this.receiptNo;
    cmds += newLine;
    cmds += 'TANGGAL  : ' + transDate + ' ' + transTime;
    cmds += newLine;
    cmds += 'KASIR    : ' + this.cashierName.toUpperCase();
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)

    //Start List Barang Belanja
    for(let i = 0; i < this.productSales.length; i++) {
      if(this.productSales[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSales[i].typePromo !== 'FREE ITEM (NOMINAL)') {
        let priceProduct: number = parseInt(this.productSales[i].price);
        let qtyBuy: number = parseInt(this.productSales[i].qty);
        let nettProduct: number = priceProduct * qtyBuy;

        let qtyFree: number = parseInt(this.productSales[i].qtyFree);
        let nettFree: number = priceProduct * qtyFree;

        if(this.productSales[i].qtyFree > 0) {
          if(qtyBuy>0){
            let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
            let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
            cmds += this.productSales[i].initialName;
            cmds += newLine;
            cmds += lblPriceXQty.toString() + lblNettProduct.toString();
            cmds += newLine;
          }

          
          let lblPriceXQtyFree: String = 'FREE ' + qtyFree + ' x ' + this.formatRupiah(priceProduct.toString());
          let lblNettFree: String = this.getLblNett('('+this.formatRupiah(nettFree.toString())+')', lblPriceXQtyFree.length);
          cmds += this.productSales[i].initialName;
          cmds += newLine;
          cmds += lblPriceXQtyFree.toString() + lblNettFree.toString();
          cmds += newLine;

         // totalPromo = totalPromo + (priceProduct * qtyFree);
        } else {
          let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
          let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
          cmds += this.productSales[i].initialName;
          cmds += newLine;
          cmds += lblPriceXQty.toString() + lblNettProduct.toString();
          cmds += newLine;
        }
      }

      if(this.productSales[i].typePromo === 'FREE ITEM (ITEM)' || this.productSales[i].typePromo === 'FREE ITEM (NOMINAL)') {
        let price: number = parseInt(this.productSales[i].priceAfterPromo);
        let qty: number = parseInt(this.productSales[i].qtyFree);
        let nett: number = price * qty;
        let lblPriceAndQty: String = 'FREE ' + qty + ' x ' + this.formatRupiah(price.toString());
        let lblNett: String = this.getLblNett('('+this.formatRupiah(nett.toString())+')', lblPriceAndQty.length);
        cmds += this.productSales[i].initialName;
        cmds += newLine;
        cmds += lblPriceAndQty.toString() + lblNett.toString();
        cmds += newLine;
      }
    }
    //End List Barang Belanja
    cmds += '---------------------------------';
    let lblPaymentWithoutPromo: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPaymentWithoutPromo.toString()));
    cmds += '        HARGA JUAL : ' + lblPaymentWithoutPromo;
    cmds += newLine;
    cmds += newLine;
    if(this.transactionDiscount>0){
      /*let countPromoDiff: number = this.transactionDiscount;
      let strCountPromoDiff: string = '(' + this.formatRupiah(countPromoDiff.toString()) + ')';
      let lblDiskonByNominal: String = this.getLabelPrintNominal(strCountPromoDiff);
      cmds += '        Diskon :      ' + lblDiskonByNominal;
      cmds += newLine;
      totalPromo = this.transactionDiscount;*/
    }

    //Start Detail Promo Product
    for(let i = 0; i < this.productSales.length; i++) {
      if(this.productSales[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSales[i].typePromo !== 'FREE ITEM (NOMINAL)') {
        if(this.productSales[i].typePromo === 'NOMINAL' || this.productSales[i].typePromo === 'PERSENTASE') {
          let countPromoDiff: number = (this.productSales[i].price * this.productSales[i].qty) - (this.productSales[i].priceAfterPromo * this.productSales[i].qty);
          totalPromo = totalPromo + countPromoDiff;
          let strCountPromoDiff: string = '(' + this.formatRupiah(countPromoDiff.toString()) + ')';
          let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);

          cmds += this.productSales[i].initialName;
          cmds += newLine;
          cmds += '            DISKON : ' + lblCountPromo;
          cmds += newLine;
        } else {
          if(this.productSales[i].valPromoNominal !== 0 || this.productSales[i].valPromoPercentage !== 0) {
            let countPromo: number = ((this.productSales[i].price * this.productSales[i].qty) * (this.productSales[i].valPromoPercentage / 100)) + this.productSales[i].valPromoNominal;
            totalPromo = totalPromo + countPromo;
            let strCountPromoDiff: string = '(' + this.formatRupiah(countPromo.toString()) + ')';
            let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);

            cmds += this.productSales[i].initialName;
            cmds += newLine;
            cmds += '            DISKON : ' + lblCountPromo;
            cmds += newLine;
          }
        }
      }

      if(this.productSales[i].typePromo === 'FREE ITEM (ITEM)' || this.productSales[i].typePromo === 'FREE ITEM (NOMINAL)') {
       // totalPromo = totalPromo + (this.productSales[i].priceAfterPromo * this.productSales[i].qtyFree);
      }
        
      if(this.productSales[i].qtyFree>0 ) {
        totalPromo = totalPromo + (this.productSales[i].price * this.productSales[i].qtyFree);
      }
    }
    //End Detail Promo Product

    cmds += '---------------------------------';
    let lblTotalPayment: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPayment.toString()));
    let lblTotalItem: String = this.getLabelTotalItem(this.totalItem);
    cmds += '' + lblTotalItem + lblTotalPayment;
    cmds += newLine;
    let lblTotalCustomerCash: String = this.getLabelPrintNominal(this.formatRupiah(this.totalCustomerCash.toString()));
    cmds += '             TUNAI : ' + lblTotalCustomerCash;
    cmds += newLine;

    //Start transfer
    if(this.customerTransfer !== 0) {
      let lblCustomerTransfer: String = this.getLabelPrintNominal(this.formatRupiah(this.customerTransfer.toString()));
      cmds += '          TRANSFER : ' + lblCustomerTransfer;
      cmds += newLine;
    }

    //Start Voucher
    if(this.voucherValue !== 0) {
      let lblVoucherValue: String = this.getLabelPrintNominal(this.formatRupiah(this.voucherValue.toString()));
      cmds += '           VOUCHER : ' + lblVoucherValue;
      cmds += newLine;
    }
    //End Voucher

    //Start Penggunaan Point
    if(this.pointMutationType === 'O') {
      let lblPointValue: String = this.getLabelPrintNominal(this.formatRupiah(this.memberPointValue.toString()));
      cmds += '             POINT : ' + lblPointValue;
      cmds += newLine
    }
    //End Penggunaan Point

    //Start Pembayaran Non Tunai
    if(this.customerCredit !== 0 && this.customerCredit !== null) {
      let lblCustomerCredit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerCredit.toString()));
      cmds += '            KREDIT : ' + lblCustomerCredit;
      cmds += newLine;
    }
    if(this.customerDebit !== 0 && this.customerDebit !== null) {
      let lblCustomerDebit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerDebit.toString()));
      cmds += '             DEBIT : ' + lblCustomerDebit;
      cmds += newLine;
    }
    //End Pembayaran Non Tunai

    let lblTotalChange: String = this.getLabelPrintNominal(this.formatRupiah(this.totalChange.toString()));
    cmds += '        KEMBALI RP : ' + lblTotalChange;
    cmds += newLine;

    //Start detail Member Point

    let lblTotalPromo: String = this.getLabelPrintNominal(this.formatRupiah(totalPromo.toString()));
    cmds += '        ANDA HEMAT : ' + lblTotalPromo;
    cmds += newLine;
    
    
      if(this.transactionType == '1') {
        if(this.memberPhone !== '') {
          cmds += '---------------------------------';
          cmds += newLine;
          cmds += 'MEMBER HERBORIST  : ' + this.memberPhone;
          cmds += newLine;
          cmds += 'POINT DITAMBAHKAN : ' + this.totalPointAdd;
          cmds += newLine;
          cmds += 'POINT TERPAKAI    : ' + this.memberPointUsed;
          cmds += newLine;
        }
      }else {
        cmds += '---------------------------------';
        cmds += newLine;
        cmds += 'NAMA RESELLER  : ' + this.resellerName;
        cmds += newLine;
        cmds += 'KODE RESELLER  : ' + this.resellerCode;
        cmds += newLine;
      }
    //End detail Member Point

    cmds += newLine;
    cmds += ' BARANG YANG SUDAH DIBELI TIDAK ';
    cmds += newLine;
    cmds += ' DAPAT DITUKAR ATAU DIKEMBALIKAN ';
    cmds += newLine;
    cmds += newLine;
    cmds += '           TERIMA KASIH';
    cmds += newLine;
    cmds += newLine;
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    if(this.userProfile.counter_detail.phone !== null && this.userProfile.counter_detail.phone !== '') {
      cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone));
    }
    cmds += newLine;
    cmds += cut;
    console.log('this.userProfile.counter_detail.phone', this.userProfile.counter_detail.phone)
    console.log(cmds);
    cpj.printerCommands = cmds;
    //Send print job to printer!
    cpj.sendToClient();

    this.clearVariable();
  }

  clearVariable() {
    this.productSales = [];
    this.salesData = undefined;
    this.pointMutationData = [];
    this.receiptNo = undefined;
    this.transDate = undefined;
    this.transTime = undefined;
    this.cashierName = '';
    this.totalPaymentWithoutPromo = 0;
    this.totalPayment = 0;
    this.totalItem = 0;
    this.voucherValue = 0;
    this.pointMutationType = '';
    this.customerCredit = 0;
    this.customerDebit = 0;
    this.memberPhone = '';
    this.totalPointAdd = 0;
    this.memberPointValueUsed = 0;
    this.memberPointValue = 0;
    this.memberPointUsed = 0;
    this.totalChange = 0;
    this.totalCustomerCash = 0;
    this.firstAddress = undefined;
    this.lastAddress = undefined;
    this.counterPhone = undefined;
  }

  calcTotal() {
    this.totalPayment = 0;
    this.totalItem = 0;
    this.totalPaymentWithoutPromo = 0;
    console.log(this.productSales);

    for (let i=0;i < this.productSales.length;i++) {
      this.totalPaymentWithoutPromo += (this.productSales[i].price * this.productSales[i].qty);
      this.totalPayment += this.productSales[i].nett;
      this.totalItem += this.productSales[i].qty;
     // this.totalItem += this.productSales[i].qtyFree;
    }
  }

  formatRupiah(angka: string) {
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
    // return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }

  getLabelPrintNominal(nominal: any): String {
    let nominalString = new String(nominal);
    let totalLength: number = 12;

    let diff: number = totalLength - nominalString.length;
    let lblNominal: String = '';

    if(diff !== 0) {
      for(let i = 0; i < diff; i++) {
        lblNominal = lblNominal + ' ';
      }

      lblNominal = lblNominal + nominalString.toString();
    } else {
      lblNominal = nominalString;
    }

    return lblNominal;
  }

  getLblNett(nett: String, priceAndQtylength: number): String {
    let lblNominal: String = '';
    let receiptRowLength: number = 33;

    let nettLength: number = nett.length;
    let diff: number = receiptRowLength - (nettLength + priceAndQtylength);
    for(let i = 0; i < diff; i++) {
      lblNominal = lblNominal + ' ';
    }
    lblNominal = lblNominal + nett.toString();
    return lblNominal;
  }

  getLabelDppAndPpn(label: String, lblLength: number): String {
    let lblNominal: String = '';
    let receiptRowLength: number = 33;
    let frontLblLength: number = 6;

    let diff: number = receiptRowLength - (frontLblLength + lblLength);
    for(let i = 0; i < diff; i++) {
      lblNominal = lblNominal + ' ';
    }
    lblNominal = lblNominal + label.toString();
    return lblNominal;
  }

  getLabelTotalItem(nominal: any): String {
    let nominalString = new String(nominal);
    let label: String = 'TOTAL ITEM ' + nominalString + ' : ';
    let lblNominal: String = '';

    let totalLength: number = label.length;
    let diff: number = 21 - totalLength;
    if(diff !== 0) {
      let space: any = '';
      for(let i = 0; i < diff; i++) {
        space = space + ' ';
      }

      lblNominal = space + label;
    } else {
      lblNominal = label;
    }

    return lblNominal;
  }

  getLabelHeaderReceipt(headerText: String) {
    let totalLength: number = headerText.length;
    let maxTextLengthforReceipt = 33;
    let resultText = "";
    if(totalLength > maxTextLengthforReceipt) {
      resultText = headerText.slice(0, maxTextLengthforReceipt);
    } else {
      let diff = maxTextLengthforReceipt - totalLength;
      let divide = (diff - (diff % 2)) / 2;
      let space = "";
      for(let i = 0; i < divide; i++) {
        space = space + " ";
      }
      
      resultText = space + headerText + space;
    }

    return resultText;
  }

}
