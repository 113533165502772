import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { MstPromotion, MstPromotionDetail, MstPromotionType } from '../../models/mst-promotion.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  constructor(private httpService: HttpService) { }

  getPromotion(params: any): Observable<any> {
    return this.httpService.get('promotions', params);
  }

  getLocalData(name: any, params: any): Observable<any> {
    let apiUrl: any;
    if(name === 'counter') { apiUrl = 'counter-all'; }
    if(name === 'warehouse') { apiUrl = 'warehouse-all'; }

    return this.httpService.get(apiUrl, params);
  }

  getPromotionType(params: any): Observable<any> {
    return this.httpService.get('promotion-types', params);
  }

  getPromotionforCreate(params: any): Observable<any> {
    return this.httpService.get('promotions/create', params);
  }

  getPromotionforEdit(promotionId: any, params: any): Observable<any> {
    return this.httpService.get(`promotions/${promotionId}/edit`, params);
  }

  getPromotionDetailforEdit(promotionId: any, params: any): Observable<any> {
    return this.httpService.get(`promotion-details/${promotionId}/edit`, params);
  }

  getPromotionTypeforEdit(promotionTypeId: any, params: any): Observable<any> {
    return this.httpService.get(`promotion-types/${promotionTypeId}/edit`, params);
  }

  addPromotion(data: MstPromotion): Observable<any> {
    return this.httpService.post('promotions', data);
  }

  addPromotionDetail(data:any): Observable<any> {
    return this.httpService.post('promotion-details', data);
  }

  addPromotionType(data: MstPromotionType): Observable<any> {
    return this.httpService.post('promotion-types', data);
  }

  updatePromotion(promotionId: any, data: MstPromotion): Observable<any> {
    return this.httpService.put(`promotions/${promotionId}`, data);
  }

  updatePromotionType(promotionTypeId: any, data: MstPromotionType): Observable<any> {
    return this.httpService.put(`promotion-types/${promotionTypeId}`, data);
  }

  deletePromotion(promotionId: any): Observable<any> {
    return this.httpService.delete(`promotions/${promotionId}`);
  }

  deletePromotionType(promotionTypeId: any): Observable<any> {
    return this.httpService.delete(`promotion-types/${promotionTypeId}`);
  }
  sendApproval(data: any): Observable<any> {
    return this.httpService.get('promotion/send-approval', data);
  }
  activate(data: any): Observable<any> {
    return this.httpService.get('promotion/activate', data);
  }
  deactivate(data: any): Observable<any> {
    return this.httpService.get('promotion/deactivate', data);
  }

  getPromotionUpload(params: any): Observable<any> {
    return this.httpService.get('promotion-upload', params);
  }
  uploadFile(data: any): Observable<any> {
    return this.httpService.post('upload-data-promo', data);
  }
  detailUploadById(id: any): Observable<any> {
    return this.httpService.get('upload-promo/'+id);
  }
}
