import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { MstCounter } from '../../models/mst-counter.model';

@Injectable({
  providedIn: 'root'
})
export class CounterService {

  constructor(private httpService: HttpService) { }

  getAllActiveCounter(): Observable<any> {
    return this.httpService.get('all-active-counter');
  }

  getCounter(params: any): Observable<any> {
    return this.httpService.get('counter', params);
  }

  getCounterforCreate(params: any): Observable<any> {
    return this.httpService.get('counter/create', params);
  }

  getCounterforEdit(counterId: any, params: any): Observable<any> {
    return this.httpService.get(`counter/${counterId}/edit`, params);
  }

  addCounter(data): Observable<any> {
    return this.httpService.post('counter', data);
  }

  updateCounter(counterId: any, data: any): Observable<any> {
    return this.httpService.put(`counter/${counterId}`, data);
  }

  deleteCounter(counterId: any): Observable<any> {
    return this.httpService.delete(`counter/${counterId}`);
  }
}
