import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { RoleAccess } from '../../models/role-access.model';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { UtilService } from '../../service/util.service';
import { SalesTargetService } from './sales-target.service';
import { MonthName } from '../../enum/MonthName';
import { AfSalesTargetComponent } from '../../component/advanced-filter/af-sales-target/af-sales-target.component';
import { AES } from 'crypto-js';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sales-target',
  templateUrl: './sales-target.page.html',
  styleUrls: ['./sales-target.page.scss'],
})
export class SalesTargetPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };
  rows: any;
  token : any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  filteredData: any;
  counterId: any='';
  counterList: any[] = [];
  keywordSearch: any;
  priceGroupList: any;
  year : any;
  month : any;
  periode :any;
  constructor(
    
    private navCtrl: NavController,
    private storage : Storage,
    private toastCtrl :ToastController,
    private modalController : ModalController,
    private userData: UserData,
    private router: Router,
    private utilService : UtilService,
    private salesTargetService : SalesTargetService
  ) { }

  ngOnInit() {
    
    this.periode = new Date().toISOString();
    let dateConvert = this.utilService.convertDate(this.periode);
    this.year = dateConvert.years;
    this.month = Number(dateConvert.months);
  }

  monthName = MonthName;
  approvalStatusList = Object.keys(MonthName).filter(
    monthName => typeof this.monthName[monthName] === 'number'
  );
  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        this.pageCallback({ offset: this.page.offset });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  goToSalesTargetCreate() {
    this.navCtrl.navigateForward(['/sales-target/sales-target-create']);
  }
  

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords" :this.keywordSearch ? this.keywordSearch : 'null',
        "month" :this.month ? this.month : 0,
        "year" :this.year ? this.year : 0,
        "counter_id": this.counterId !== '' ? this.counterId : this.userProfile.counter_id_list
      };

      this.salesTargetService.getSalesTarget(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        //this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.priceGroupList = response.results.data;

        if(this.counterList.length === 0) {
          this.getCounterList();
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  getCounterList() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.counterId !== '' ? this.counterId : this.userProfile.counter_id_list
      };
      this.salesTargetService.getCounter(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.counterList = response.results;
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Counter' }).then(t => t.present());
      });
    });
  }

  goToUpdatePage(id: any, action :number) {
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let encryptAction = AES.encrypt(JSON.stringify(action),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID,
        a : encryptAction
      }
    };
    this.navCtrl.navigateForward(['/sales-target/sales-target-edit'], navigationExtras);
  } 
  
  clearFilters() {
    this.counterId = '';
    this.pageCallback({ offset: this.page.offset });
  }
  
  async openAdvancedFilters() {
    
    const modal = await this.modalController.create({
      component: AfSalesTargetComponent,
      componentProps: {
        counterList: this.counterList,        
        paramYear: this.year,        
        paramMonthId: this.month
      }
    });

    modal.onDidDismiss().then((modelData) => {
      console.log(modelData);
     if (modelData.data !== undefined) {
        this.counterId = modelData.data[0].counterId;
        this.year = modelData.data[0].year;
        this.month = modelData.data[0].month;
        this.reloadTable();
      } else {
        this.counterId = '';
        this.year ='';
        this.month ='';
        this.reloadTable();
      }
    });

    return await modal.present();
  }
}
