import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-report-other-receiving-detail',
  templateUrl: './report-other-receiving-detail.component.html',
  styleUrls: ['./report-other-receiving-detail.component.scss'],
})
export class ReportOtherReceivingDetailComponent implements OnInit {

  @Input() reportDataDetail: any[];

  otherReceivingId: any;
  counterName: any;
  warehouseName: any;
  docDate: any;
  statusName: any;
  description: any;

  constructor(
    public modalController: ModalController
    ) { }

  ngOnInit() {
      if(this.reportDataDetail.length > 0) {
        this.otherReceivingId = this.reportDataDetail[0].other_receiving_id;
        this.counterName = this.reportDataDetail[0].counter_name;
        this.warehouseName = this.reportDataDetail[0].warehouse_name;
        this.docDate = this.reportDataDetail[0].doc_date;
        this.statusName = this.reportDataDetail[0].status === '0' ? 'PENDING' : this.reportDataDetail[0].status === '1' ? 'OK' : this.reportDataDetail[0].status === '2' ? 'VOID' : '-';
        this.description = this.reportDataDetail[0].desc;
      }
    }
  
    async dismissModal() {
      const close: string = "Modal Removed";
      await this.modalController.dismiss(close);
    }

}
