import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Storage } from '@ionic/storage';
import { ReportService } from '../../../pages/report/report.service';
import { UserProfile } from '../../../models/user-profile.model';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-report-stock-auditrail',
  templateUrl: './report-stock-auditrail.component.html',
  styleUrls: ['./report-stock-auditrail.component.scss'],
})
export class ReportStockAuditrailComponent implements OnInit {

  @Input() productList: Product[];
  @Input() counterList: any[];
  @Input() warehouseList: any[];
  @Input() paramMonthId: any;
  @Input() paramYear: any;
  @Input() paramBranchId: any;
  @Input() paramCounterId: any;
  @Input() paramProduct: any;
  @Input() paramWarehouseId: any;
  
  productSearch: Product[];
  branchData: any[] = [];
  counterId: any;
  productId: any;
  warehouseId: any;
  monthId: any;
  year: any;
  monthList: any[] = ['', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterByLogin:any;
  selectedBranchs:any[];
  selectedCounters: any[];
  selectedWarehouses: any[];
  branchId: any;

  constructor(
    public modalController: ModalController,
    private utilService: UtilService,
    private reportService: ReportService,
    private storage: Storage,
    private toastCtrl: ToastController) { }

  ngOnInit() {
    let convertDate = this.utilService.convertDate(new Date());
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.monthId = this.paramMonthId ? this.paramMonthId : 0;
    this.year = this.paramYear ? this.paramYear + '-01-01' : todayDate;
    this.branchId = this.paramBranchId ? parseInt(this.paramBranchId) : '';
    this.counterId = this.paramCounterId ? parseInt(this.paramCounterId) : '';
    this.productId = this.paramProduct ? this.paramProduct : null;
    this.warehouseId = this.paramWarehouseId ? parseInt(this.paramWarehouseId) : '';
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
      }
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.userProfile = new UserProfile(profile);
        this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
        let options = { 
          "token": this.token,
          "counter_id": this.counterByLogin
        };
        this.reportService.getReportCashierIndex(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.branchData = response.results.branch_data;
          let selectedBranch: any = this.branchData.find(x => x.id ==  this.branchId);
          if(selectedBranch){
            let idx =this.branchData.indexOf(selectedBranch);
            this.selectedBranchs=(this.branchData[idx]);                
          }
          this.filterCounterByBranch();
        })
      })
    })
  }

  async dismissModal(code: any) {
    let dataReturn: any[] = [];
    let convertDate = this.utilService.convertDate(this.year);

    if(code === 'generate') {
      let counterSelected = this.counterId ? this.counterList.find(x => x.id === this.counterId) : null;
      let warehouseSelected = this.warehouseId ? this.warehouseList.find(x => x.id === this.warehouseId) : null;
      
      let data = {
        monthId: this.monthId,
        monthName: this.monthList[this.monthId],
        year: convertDate.years,
        branchId: this.branchId,
        counterId: this.counterId,
        counterName: counterSelected !== null ? counterSelected.counter_name : null,
        productId: this.productId,
        warehouseId: this.warehouseId,
        warehouseName : warehouseSelected !== null ? warehouseSelected.warehouse_name : null
      };
      console.log(data);

      dataReturn.push(data);
    }

    if(code === 'close') {
      dataReturn = [];
    }

    await this.modalController.dismiss(dataReturn);
  }

  filterProducts(products: Product[], text: string) {
    return products.filter(product => {
      return product.product_name.toLowerCase().indexOf(text) !== -1 ||
        product.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  searchProducts(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterProducts(this.productList, text);
    event.component.endSearch();
  }
    
  public selectCounter(event){
    this.counterId = event.value.id;    
    this.filterWarehouseByCounter();
  }

  public selectWarehouse(event){
    this.warehouseId = event.value.id;
  }

  public selectBranch(event){
    this.branchId = event.value.id;       
    this.filterCounterByBranch();
  }
  
  filterCounterByBranch(){    
    this.selectedCounters=[];
    let options = { 
      "token": this.token,
      "branch_id": this.branchId,
      "counter_id": this.counterByLogin 
    };
    this.reportService.getCounterByBranchId(options).subscribe((response) => {
      this.utilService.loadingDismiss();      
      this.counterList = [];
      this.counterList = response.results;
      this.counterId = this.paramCounterId ? parseInt(this.paramCounterId) : '';
      let selectedCounter: any = this.counterList.find(x => x.id ==  this.counterId);
      if(selectedCounter){
        let idx =this.counterList.indexOf(selectedCounter);
        this.selectedCounters=(this.counterList[idx]);
      }
      this.filterWarehouseByCounter();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });  
  }
  
  filterWarehouseByCounter(){
    this.selectedWarehouses = [];
    let options = { 
      "token": this.token,
      "counter_id": this.counterId 
    };
    
    this.reportService.getWarehouseByCounterId(options).subscribe((response) => {
      this.utilService.loadingDismiss();      
      this.warehouseId = this.paramWarehouseId ? parseInt(this.paramWarehouseId) : '';
      this.warehouseList = [];
      this.warehouseList = response.results;
      if(this.warehouseList.length>0){
        this.warehouseList.unshift({
          'id': 0,
          'counter_id': 0,
          'warehouse_name': 'SEMUA GUDANG'
        });
      }
      let selectedWarehouse: any = this.warehouseList.find(x => x.id ==  this.warehouseId);
      if(selectedWarehouse){
        let idx =this.warehouseList.indexOf(selectedWarehouse);
        this.selectedWarehouses=(this.warehouseList[idx]);                
      }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }
}
