import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReceiptVoucherService } from '../receipt-voucher.service';
import { ReceiptVoucher, ReceiptVoucherDetail } from '../../../models/receipt-voucher.model';
import { ApprovalHistory } from '../../../models/approval-history.model';
import { ReceiptVoucherStatus } from '../../../enum/ReceiptVoucherStatus';

class Coa {
  public id: string;
  public name: string;
}

@Component({
  selector: 'app-receipt-voucher-edit',
  templateUrl: './receipt-voucher-edit.page.html',
  styleUrls: ['./receipt-voucher-edit.page.scss'],
})
export class ReceiptVoucherEditPage implements OnInit {

  token: any;
  coaSearch: Coa[];
  coaList: Coa[];
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  cashBankList: any[] = [];
  currencyList: any[] = [];
  receiptVoucherId: any;

  db: any;
  
  receiptVoucherData: any;
  receiptVoucherDetailData: any[] = [];
  approvalHistoryData: any[] = [];
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;

  formReceiptVoucherEdit: FormGroup;

  statusReceipt: any = 0;
  receiptStatus = ReceiptVoucherStatus;
  receiptStatusList = Object.keys(ReceiptVoucherStatus).filter(
    receiptStatus => typeof this.receiptStatus[receiptStatus] === 'number'
  );

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private receiptVoucherService: ReceiptVoucherService
  ) { }

  ngOnInit() {
    this.buildFormReceiptVoucher();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.getData();
        this.getAllCoa();
        this.getCounterList();
        this.getCashBankList();
        this.getCurrencyList();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getAllCoa() {
    this.receiptVoucherService.getMstCoaConcat({ "token": this.token }).subscribe((response) => {
      if (response.status.code === 200) {
        this.coaList = response.results;
      }
    }, () => {
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  buildFormReceiptVoucher() {
    this.formReceiptVoucherEdit = this.fb.group({
      receiptVoucherId: [null],
      cashBankId: [null],
      docDate: [null],
      receiptFrom: [null],
      currency: [null],
      amount: [0],
      exchangeRate: [0],
      receiptProofNo: [null],
      status: [null],
      detail: this.fb.array([])
    });
  }

  addDetail() {
    const detail = this.fb.group({
      coa: [null],
      counterId: [null],
      amount: [0],
      desc: [null]
    });
    this.getDetailArray.push(detail);
  }

  get getDetailArray() {
    return (<FormArray>this.formReceiptVoucherEdit.get('detail'));
  }

  filterCoa(coas: Coa[], text: string) {
    return coas.filter(coa => {
      return coa.name.toLowerCase().indexOf(text) !== -1 ||
        coa.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  searchCoa(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterCoa(this.coaList, text);
    event.component.endSearch();
  }

  deleteDetail(i: any) {
    this.getDetailArray.removeAt(i);
  }

  getCounterList() {
    let selectQuery = 'SELECT * FROM mst_counters';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.counterList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let counterData = {
                'id': result.rows[x].id,
                'counter_name': result.rows[x].counter_name
              };
              this.counterList.push(counterData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getCashBankList() {
    let selectQuery = 'SELECT * FROM mst_cash_banks';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.cashBankList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let cashBankData = {
                'id': result.rows[x].id,
                'cash_bank_name': result.rows[x].name
              };
              this.cashBankList.push(cashBankData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getCurrencyList() {
    let selectQuery = 'SELECT * FROM mst_foreign_currencies';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.currencyList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let currencyData = {
                'id': result.rows[x].id,
                'foreign_currency_name': result.rows[x].foreign_currency_name,
                'exchange_rate': result.rows[x].exchange_rate
              };
              this.currencyList.push(currencyData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getExchangeRate(event: any) {
    let id = event.target.value;
    let checkCurrency: any = this.currencyList.find(x => x.id === id);
    if(checkCurrency) {
      this.formReceiptVoucherEdit.controls.exchangeRate.setValue(checkCurrency.exchange_rate);
    }
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      this.receiptVoucherId = snapshot.receiptVoucherId;

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.receiptVoucherService.getReceiptVoucherbyId(this.receiptVoucherId, { "token": this.token })
        .subscribe((response: any) => {
          this.utilService.loadingDismiss();
          this.receiptVoucherData = response.results.receipt_voucher_data;
          this.receiptVoucherDetailData = response.results.receipt_voucher_detail_data;
          let userCreate: any = response.results.user_create_data;
          this.createdBy = userCreate ? userCreate[0].name : null;
          let userUpdate: any = response.results.user_update_data;
          this.updatedBy = userUpdate ? userUpdate[0].name : null;
          this.approvalHistoryData = response.results.approval_history_data;

          if(this.receiptVoucherData.length > 0) {
            this.statusReceipt = this.receiptVoucherData[0].status;
            this.createdAt = this.receiptVoucherData[0].created_at;
            this.updatedAt = this.receiptVoucherData[0].updated_at;
            let statusName = this.receiptStatusList[parseInt(this.statusReceipt)];

            this.formReceiptVoucherEdit = this.fb.group({
              receiptVoucherId: [this.receiptVoucherData[0].id],
              cashBankId: [parseInt(this.receiptVoucherData[0].cash_bank_id), Validators.required],
              docDate: [this.receiptVoucherData[0].doc_date, Validators.required],
              receiptFrom: [this.receiptVoucherData[0].receipt_from, Validators.required],
              currency: [parseInt(this.receiptVoucherData[0].currency), Validators.required],
              amount: [parseInt(this.receiptVoucherData[0].amount), Validators.required],
              exchangeRate: [parseInt(this.receiptVoucherData[0].exchange_rate), Validators.required],
              receiptProofNo: [this.receiptVoucherData[0].receipt_proof_no, Validators.required],
              status: [statusName],
              detail: this.fb.array([])
            });
          }

          if(this.receiptVoucherDetailData.length > 0) {
            for(let i = 0; i < this.receiptVoucherDetailData.length; i++) {
              if(this.statusReceipt === '0') {
                let coaId: string = this.receiptVoucherDetailData[i].coa.trim().toLowerCase();
                let filterCoa = this.filterCoa(this.coaList, coaId);
                const detail = this.fb.group({
                  coa: [filterCoa[0], Validators.required],
                  counterId: [this.receiptVoucherDetailData[0].counter_id],
                  amount: [parseInt(this.receiptVoucherDetailData[i].amount), Validators.required],
                  desc: [this.receiptVoucherDetailData[i].desc, Validators.required]
                });
                this.getDetailArray.push(detail);
              } else {
                const detail = this.fb.group({
                  coaId: [this.receiptVoucherDetailData[i].coa],
                  coa: [this.receiptVoucherDetailData[i].coa_name],
                  counter: [this.receiptVoucherDetailData[0].counter_name],
                  amount: [parseInt(this.receiptVoucherDetailData[i].amount)],
                  desc: [this.receiptVoucherDetailData[i].desc]
                });
                this.getDetailArray.push(detail);
              }
            }
          } else {
            this.getDetailArray.clear();
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  saveUpdate(action: any, status: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formReceiptVoucher = this.formReceiptVoucherEdit.value;
      let cashBankId = formReceiptVoucher.cashBankId;
      let receiptFrom = formReceiptVoucher.receiptFrom;
      let currency = formReceiptVoucher.currency;
      let amount = formReceiptVoucher.amount;
      let exchangeRate = formReceiptVoucher.exchangeRate;
      let receiptProofNo = formReceiptVoucher.receiptProofNo;

      let docDateConvert = this.utilService.convertDate(formReceiptVoucher.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      const receiptVoucher = new ReceiptVoucher();
      receiptVoucher.cash_bank_id = cashBankId;
      receiptVoucher.doc_date = documentDate;
      receiptVoucher.receipt_from = receiptFrom;
      receiptVoucher.currency = currency;
      receiptVoucher.exchange_rate = exchangeRate;
      receiptVoucher.amount = amount;
      receiptVoucher.receipt_proof_no = receiptProofNo;
      receiptVoucher.status = status;
      receiptVoucher.updated_by = this.userProfile.username;

      this.receiptVoucherService.updateReceiptVoucher(this.receiptVoucherId, receiptVoucher).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          if(action === 'data') { this.saveReceiptVoucherDetail(this.receiptVoucherId); }
          if(action === 'status') { this.saveApprovalHistory(status); }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  saveReceiptVoucherDetail(receiptVoucherId: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formReceiptDetail = this.formReceiptVoucherEdit.value;
      let arrReceiptVoucherId: any = [];
      let arrCounterId: any = [];
      let arrCoa: any = [];
      let arrAmount: any = [];
      let arrDesc: any = [];

      for(let x = 0; x < formReceiptDetail.detail.length; x++) {
        arrReceiptVoucherId[x] = receiptVoucherId;
        arrCounterId[x] = formReceiptDetail.detail[x].counterId;
        arrCoa[x] = formReceiptDetail.detail[x].coa.id;
        arrAmount[x] = parseInt(formReceiptDetail.detail[x].amount);
        arrDesc[x] = formReceiptDetail.detail[x].desc;
      }

      const receiptDetail = new ReceiptVoucherDetail();
      receiptDetail.receipt_voucher_id = arrReceiptVoucherId;
      receiptDetail.counter_id = arrCounterId;
      receiptDetail.coa = arrCoa;
      receiptDetail.amount = arrAmount;
      receiptDetail.desc = arrDesc;

      this.receiptVoucherService.addReceiptVoucherDetail(receiptDetail).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  saveApprovalHistory(status: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let convertTime = this.utilService.convertDateWithMoment(new Date(), 'Asia/Jakarta');
      let convertDate = this.utilService.convertDate(new Date());
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;

      const approvalHistory = new ApprovalHistory();
      approvalHistory.transaction_id = this.receiptVoucherId;
      approvalHistory.username = this.userProfile.username;
      approvalHistory.status = status;
      approvalHistory.trans_date = transDate + ' ' + transTime;

      this.receiptVoucherService.addApprovalHistory(approvalHistory).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/receipt-voucher']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
