import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../report.service';
import { NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../../service/util.service';
import { Storage } from '@ionic/storage';
import * as XLSX from 'xlsx';
import { RoleAccess } from '../../../../models/role-access.model';
import { UserProfile } from '../../../../models/user-profile.model';
import { UserData } from '../../../../providers/user-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-timeseries-sales',
  templateUrl: './report-timeseries-sales.page.html',
  styleUrls: ['./report-timeseries-sales.page.scss'],
})
export class ReportTimeseriesSalesPage implements OnInit {

  dataSummarySales:any[]=[];
  dataTimeseriesSales:any[]=[];
  year : any=(new Date()).getFullYear();
  month: any=(new Date()).getMonth();
  token : any;  
  yearTimeSales : any = new Date().toISOString();
  periodeSales :any = new Date().toISOString();
  
  roleAccess = new RoleAccess();
  userProfile: UserProfile = new UserProfile();
  constructor(
    private reportService :ReportService,
    private toastCtrl : ToastController,
    private utilService :UtilService,
    private storage: Storage,
    private userData: UserData,
    private navCtrl: NavController,
    private router: Router
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.getReportTimeseriesSales();
        });
      }
    });
  }
  
  getReportTimeseriesSales(){     
    let dateConvert = this.utilService.convertDate(this.yearTimeSales);
    this.yearTimeSales = dateConvert.years;
    let body = {
      year: this.yearTimeSales
    };
     
    this.reportService.getReportTimeseriesSales(body).subscribe((response) => {
      this.utilService.loadingDismiss();
      if(response.status.code === 200) {
        this.dataTimeseriesSales = response.results.timeseries_sales;
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      }
    }, (err) => {
      this.utilService.loadingDismiss();
      console.log(err);
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }   
   
  exportToExcelTimeseries() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table-timeseries');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Report Timeseries Sales Tahun'+this.year+'.xlsx');
  }
}