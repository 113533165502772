export class PointMutation {

    customer_id: number[];
    point_mutation_type_id: string[];
    sales_transaction_id: string[];
    point: number[];
    point_value: number[];
    trans_date: string[];
    expired_date: string[];
    status: string[];
    created_by: string[];
    updated_by: string[];

    constructor(model?) {
        model = model || {};
        this.customer_id = model.customer_id || [];
        this.point_mutation_type_id = model.point_mutation_type_id || [];
        this.sales_transaction_id = model.sales_transaction_id || [];
        this.point = model.point || [];
        this.point_value = model.point_value || [];
        this.trans_date = model.trans_date || [];
        this.expired_date = model.expired_date || [];
        this.status = model.status || [];
        this.created_by = model.created_by || [];
        this.updated_by = model.updated_by || [];
    }
}