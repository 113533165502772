import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SalesTargetPageRoutingModule } from './sales-target-routing.module';

import { SalesTargetPage } from './sales-target.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SalesTargetPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [SalesTargetPage]
})
export class SalesTargetPageModule {}
