import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-product-request',
  templateUrl: './product-request.component.html',
  styleUrls: ['./product-request.component.scss'],
})
export class ProductRequestComponent implements OnInit {

  @Input() counterList: any[];
  @Input() counterId: any;

  @Input() docDateStart: any;
  @Input() docDateEnd: any;

  @Input() statusId: any;

  statusList: any[] = [
    'Pending',
    'OK',
    'Cancel',
    'Reject',
    'Waiting',
    'On Process',
    'Delivery'
  ];

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() {}

  async dismissModal(code: any) {
    let dataReturn: any[] = [];
    if(code === 'search') {
      let data = {
        'counter_id': this.counterId,
        'doc_date_start': this.docDateStart,
        'doc_date_end': this.docDateEnd,
        'status_id': this.statusId
      };

      dataReturn.push(data);
    }

    if(code === 'close') {
      dataReturn = [];
    }
    
    await this.modalController.dismiss(dataReturn);
  }

  clearField() {
    this.counterId = undefined;
    this.docDateStart = undefined;
    this.docDateEnd = undefined;
    this.statusId = undefined;
  }
  
  public selectCounter(event){
    this.counterId = event.value.id;
  }
}
