import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RequestVoidPageRoutingModule } from './request-void-routing.module';

import { RequestVoidPage } from './request-void.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RequestVoidPageRoutingModule
  ],
  declarations: [RequestVoidPage]
})
export class RequestVoidPageModule {}
