import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CounterPage } from './counter.page';

const routes: Routes = [
  {
    path: '',
    component: CounterPage
  },
  {
    path: 'counter-create',
    loadChildren: () => import('./counter-create/counter-create.module').then( m => m.CounterCreatePageModule)
  },
  {
    path: 'counter-edit',
    loadChildren: () => import('./counter-edit/counter-edit.module').then( m => m.CounterEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CounterPageRoutingModule {}
