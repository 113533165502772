export const environment = {
  production: true,
  apiUrl: 'https://api-pos.iria.co.id/v1/',
  baseUrl :'https://pos.iria.co.id/',
  //apiUrl: 'http://localhost:8001/v1/',
  //baseUrl :'http://localhost:8100/',
  waApi : 'https://wab.vci.co.id/',
  kpiApi : 'https://kpi.vci.co.id/',
  myKey :'Wno-27082024'
};

