import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PettyCashEditPageRoutingModule } from './petty-cash-edit-routing.module';

import { PettyCashEditPage } from './petty-cash-edit.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PettyCashEditPageRoutingModule,
    ReactiveFormsModule
  ],
  declarations: [PettyCashEditPage]
})
export class PettyCashEditPageModule {}
