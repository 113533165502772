import { Component, OnInit } from '@angular/core';

import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';
import { NavController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-empty-page',
  templateUrl: './empty-page.page.html',
  styleUrls: ['./empty-page.page.scss'],
})
export class EmptyPagePage implements OnInit {

  token: any;
  userProfile: UserProfile= new UserProfile();

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private toastCtrl: ToastController) { }

  ngOnInit() {
  }


  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        window.dispatchEvent(new CustomEvent('user:getmenu'));
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
}
