import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { ResellerMonthlyReward } from '../../../models/reseller-monthly-reward.model';
import { ResellerCashbackService } from '../reseller-cashback.service';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-reseller-cashback-create',
  templateUrl: './reseller-cashback-create.page.html',
  styleUrls: ['./reseller-cashback-create.page.scss'],
})
export class ResellerCashbackCreatePage implements OnInit {

  token: any;
  formResellerCashback: FormGroup;
  userProfile: UserProfile = new UserProfile();
  flagFind: boolean = false;

  startDate: any = null;
  endDate: any = null;
  customerId: any;
  resellerName: any = null;
  totalSales: number = 0;
  totalCashback: number = 0;
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private resellerCashbackService: ResellerCashbackService,
    private userData: UserData,
    private router: Router) { }

    ngOnInit() {
      this.buildFormResellerCashback();
  
      Promise.all([
        this.storage.get('user_token'),
        this.storage.get('user_profile'),
        this.storage.get('user_menu_access')
      ])
      .then(([token, profile, access]) => {
        if(token) {
          this.token = token;
          this.userProfile = new UserProfile(profile);  
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.update!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
        }
      });
    }
  
    buildFormResellerCashback() {
      this.formResellerCashback = this.fb.group({
        phoneNumber: [null, Validators.required],
        name: [{value: null, disabled: true}],
        transDateStart: [{value: null, disabled: true}],
        transDateEnd: [{value: null, disabled: true}],
        totalTransaction: [{value: 0, disabled: true}]
      });
    }

    findReseller() {
      const formResellerCashback = this.formResellerCashback.value;
      let phoneNumber = formResellerCashback.phoneNumber;

      let options: any = {  
        'phone': phoneNumber
      };

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.resellerCashbackService.getTotalTransactionMonthly(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 200) {
            this.startDate = response.results.start_date;
            this.endDate = response.results.end_date;
            this.customerId = response.results.customer_id;
            this.resellerName = response.results.name;
            this.totalSales = response.results.total_sales;
            this.totalCashback = response.results.cashback_amount;

            if(this.startDate !== null && this.endDate !== null) {
              this.flagFind = true;
              this.formResellerCashback = this.fb.group({
                phoneNumber: [phoneNumber, Validators.required],
                name: [{value: this.resellerName, disabled: true}],
                transDateStart: [{value: this.startDate, disabled: true}],
                transDateEnd: [{value: this.endDate, disabled: true}],
                totalTransaction: [{value: this.totalSales, disabled: true}]
              });
            } else {
              this.flagFind = false;
            }
          } else {
            this.flagFind = false;
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.flagFind = false;
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    }

    process() {
      let paidDateConvert = this.utilService.convertDate(new Date());
      let paidDate = paidDateConvert.years + '-' + paidDateConvert.months + '-' + paidDateConvert.dates;

      const resellerMonthlyReward = new ResellerMonthlyReward();
      resellerMonthlyReward.customer_id = this.customerId;
      resellerMonthlyReward.period_transaction_start = this.startDate;
      resellerMonthlyReward.period_transaction_end = this.endDate;
      resellerMonthlyReward.total_transaction = this.totalSales;
      resellerMonthlyReward.paid_date = paidDate;
      resellerMonthlyReward.paid_amount = this.totalCashback;
      resellerMonthlyReward.created_by = this.userProfile.username;

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.resellerCashbackService.addResellerMonthlyReward(resellerMonthlyReward)
        .subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.showConfirmInput();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    }

    async showConfirmInput() {
      const alert = await this.alertController.create({
        header: 'Notification',
        cssClass:'custom-alert-class',
        message: 'Data sudah tersimpan!',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              this.navCtrl.navigateForward(['/reseller-cashback']);;
            }
          }
        ]
      });
  
      await alert.present();
    }

}
