import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { RoleAccess } from '../../../models/role-access.model';
import { UserProfile } from '../../../models/user-profile.model';
import { UserData } from '../../../providers/user-data';
import { UtilService } from '../../../service/util.service';
import { MasterProductGimmickService } from '../master-product-gimmick.service';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-master-product-gimmick-detail',
  templateUrl: './master-product-gimmick-detail.page.html',
  styleUrls: ['./master-product-gimmick-detail.page.scss'],
})
export class MasterProductGimmickDetailPage implements OnInit {
  token : any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  productId:any;
  productData: any;
  productName:any;
  initialName:any;
  id:any;
  unit:any;
  productSize:any;
  barcode:any;
  constructor(
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private mstProductGimmickService: MasterProductGimmickService,
    private router: Router) { }
  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getMstProductforDetail();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  
  getMstProductforDetail() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.productId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.productId = this.productId.replaceAll('"','');

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.mstProductGimmickService.getProductGimmickForEdit(this.productId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          console.log(response);
          this.productData = response.results.mst_product_gimmick;
          this.productName= this.productData.product_name;
          this.initialName= this.productData.initial_name;
          this.id = this.productData.id;
          this.unit = this.productData.unit;
          let length = this.productData.length_size;
          let width = this.productData.width_size;
          let height = this.productData.height_size;
          let unit_size = this.productData.unit_size;
          this.productSize = width+'x'+length+'x'+height+' '+unit_size;
          this.barcode = this.productData.barcode;
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

}
