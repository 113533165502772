import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RegisterApprovalPageRoutingModule } from './register-approval-routing.module';

import { RegisterApprovalPage } from './register-approval.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RegisterApprovalPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [RegisterApprovalPage]
})
export class RegisterApprovalPageModule {}
