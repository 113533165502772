
import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserService } from '../user.service';
import { forkJoin } from 'rxjs';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.page.html',
  styleUrls: ['./user-create.page.scss'],
})
export class UserCreatePage implements OnInit {

  formUserEdit: FormGroup;
  token: any;
  userProfile: UserProfile = new UserProfile();
  userId: any;
  counterData: any[] = [];
  branchData: any[] = [];
  roleData: any[] = [];
  cashierData:any=[];
  accessId: any;
  selectedCashiers:any[];
  showBranchCheckBox: boolean = false;  
  showCounterCheckBox: boolean = false;  
  checkedAll : boolean = true;
  checkedAllCounter : boolean = true;
  branchNameCheckBox: any[] = [];
  counterNameCheckBox: any[] = [];
  countChecked:number=0;
  countCheckedCounter:number=0;
  branchList : any ='0';
  counterList : any ='0';
  roleAccess = new RoleAccess();
  visitorId : any; 
  listUser:any[]=[];
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userService: UserService,
    private userData: UserData,
    private router: Router,public platform: Platform) { }

  ngOnInit() {
    this.buildFormUserEdit();
    this.platform.ready().then(() => { console.log('ready'); 
      if (this.platform.is('android')) {
           console.log('android');
      } else if (this.platform.is('ios')) {
           console.log('ios');
      } else {
           //fallback to browser APIs or
           console.log('The platform is not supported');
           console.log(this.platform.is('desktop'));
             }
      });

    //document.addEventListener("deviceready", this.onDeviceReady, false);
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access'),
      this.storage.get('visitor_id'),
    ])
    .then(([token, profile, access,visitorId]) => {
      if(token) {
        this.token = token;
        this.visitorId = visitorId;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  buildFormUserEdit() {
    this.formUserEdit = this.fb.group({
      roleId: [], counterId: [], username: [], name: [], email: [], branchId: [], accessId: [], startPage: [], waNumber:[], cashierId:[]
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      this.userId = snapshot.userId;
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let options = { "token": this.token,"visitorId":this.visitorId };
        forkJoin([
          this.userService.getAllUser(options),
          this.userService.getRole(options),
          this.userService.getCounter(options),
          this.userService.getCashier(options)
        ]).subscribe(([ user,role, counter,cashier]) => {
          this.utilService.loadingDismiss();
          console.log(user);
          if(user.results.data.length > 0) { 
            this.listUser = user.results.data;
          }

          if(role.results.length > 0) { this.roleData = role.results; }
          if(counter.results.length > 0) { this.counterData = counter.results; }
          if(cashier.results.length > 0) { this.cashierData = cashier.results; }
         

          for(let x = 0; x < this.branchData.length; x++) {
            let isChecked = false;
            
            if(isChecked){
              this.countChecked++;
              this.branchList = this.branchList +','+this.branchData[x].id ;              
            }
      
            let data = {
              'branch_id': this.branchData[x].id,
              'branch_name': this.branchData[x].branch_name,
              'isChecked': isChecked
            };
      
            this.branchNameCheckBox.push(data);
          }
          for(let x = 0; x < this.counterData.length; x++) {
            let isChecked = false;
            if(isChecked){
              this.countCheckedCounter++;
              this.counterList = this.counterList +','+this.counterData[x].id ;              
            }
            let data = {
              'counter_id': this.counterData[x].id,
              'counter_name': this.counterData[x].counter_name,
              'isChecked': isChecked
            };
            this.counterNameCheckBox.push(data);
          }
          if(this.branchList.charAt(0) === ',')
          {
            this.branchList = this.branchList.substring(1);
          }
          if (this.countChecked== this.branchData.length){
            this.checkedAll = true;
          }
          else{
            this.checkedAll = false;
          }
          
          if(this.counterList.charAt(0) === ',')
          {
            this.counterList = this.counterList.substring(1);
          }
          if (this.countCheckedCounter== this.counterData.length){
            this.checkedAllCounter = true;
          }
          else{
            this.checkedAllCounter = false;
          }

          this.getCashierListByCounter();
          this.formUserEdit = this.fb.group({
            roleId: ['', Validators.required],
            counterId: [null],
            username: [''],
            name: ['', Validators.required],
            email: [''],
            branchId: [this.branchList],
            accessId: [this.accessId],
            startPage:['',Validators.required],
            waNumber:[''],
            cashierId:[0]
          });

        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  createData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let counterId: number = 0;
      let branchId: number = 0;
      const formUser = this.formUserEdit.getRawValue();

      if(this.accessId === '1') {
        //branchId = formUser.branchId;
      }

      if(this.accessId === '2') {
        counterId = formUser.counterId;
      }
      const userData = new UserProfile();
      userData.role_id = formUser.roleId;
      userData.counter_id = counterId;
      userData.branch_id = branchId;
      userData.name = formUser.name;
      userData.created_by = this.userProfile.username;
      userData.start_page = formUser.startPage;
      userData.whatsapp_number = formUser.waNumber;
      userData.branch_list = this.branchList;
      userData.counter_list = this.counterList;
      userData.username = formUser.username;
      userData.email = formUser.email;
      userData.cashier_id = formUser.cashierId;
      let userExist=false;
      let existMessage='';
      let checkUsername = this.listUser.find(x=>x.username==formUser.username);
      if(checkUsername){
        userExist=true;
        existMessage +=' Username Already Exists.';
      }
      let checkEmail = this.listUser.find(x=>x.email==formUser.email);
      if(checkEmail){
        userExist=true;
        existMessage +=' Email Already Exists.';
      }
      if(!userExist){
        this.userService.createUser(userData).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 200) {
            this.showConfirmCreate();
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      }
      else{
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: existMessage }).then(t => t.present());
      }
    });
  }

  async showConfirmCreate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/user']);;
          }
        }
      ]
    });

    await alert.present();
  }

  checkAccess() {
    let accessIdBefore = this.accessId;
    const formUser = this.formUserEdit.getRawValue();
    this.accessId = formUser.accessId;
    if(this.accessId !== accessIdBefore){
      this.branchList ='0';
      this.counterList ='0';
      for(let i = 0; i < this.branchNameCheckBox.length; i++) {
        this.branchNameCheckBox[i].isChecked = false; 
      }
    }
    let valuecounterId = this.counterList;   
    if(this.counterList == '0'){
      valuecounterId = null; 
    }
    
    this.getCounterListByBranch();
        
    this.formUserEdit = this.fb.group({
      roleId: [formUser.roleId, Validators.required],
      counterId: this.accessId === '2' ? [valuecounterId, Validators.required] : this.accessId === '1' ? [valuecounterId, Validators.required] : [formUser.counterId],
      username: [formUser.username],
      name: [formUser.name, Validators.required],
      email: [formUser.email],
      branchId: this.accessId === '1' ? [formUser.branchId, Validators.required] : [formUser.branchId],
      accessId: [this.accessId],
      startPage:[formUser.startPage,Validators.required],
      waNumber :[formUser.waNumber],
      cashierId:[formUser.cashierId]
    });
  } 

  showBranchListCheckBox() {
    if(this.showBranchCheckBox === false) {
      this.showBranchCheckBox = true;
    } else {
      this.showBranchCheckBox = false;
    }
  }

  showCounterListCheckBox() {
    if(this.showCounterCheckBox === false) {
      this.showCounterCheckBox = true;
    } else {
      this.showCounterCheckBox = false;
    }
  }

  selectCheckboxBranch(branch_id:any,checked:any){
    let listBranchId: any = '0';
    if(this.branchNameCheckBox.length > 0) {
     let indexChecbox = (this.branchNameCheckBox.findIndex(x => x.branch_id === branch_id));
      if(checked=='check'){
        this.branchNameCheckBox[indexChecbox].isChecked=true;
      }
      else{
        this.branchNameCheckBox[indexChecbox].isChecked=false;
      }
      for(let i = 0; i < this.branchNameCheckBox.length; i++) {
        if(this.branchNameCheckBox[i].isChecked === true) {
          if(listBranchId === '0') {
            listBranchId = this.branchNameCheckBox[i].branch_id;
          } else {
            listBranchId = listBranchId + ',' + this.branchNameCheckBox[i].branch_id;
          }
        }
      }
      
    }
    
    this.branchList = listBranchId;
    if(this.branchList!='0'){
      this.getCounterListByBranch();
    }
    else{
      this.counterList = '0';
    }
    const formUser = this.formUserEdit.getRawValue();
    this.accessId = formUser.accessId;
    let valuecounterId = this.counterList;   
    if(this.counterList == '0'){
      valuecounterId = null; 
    }
    this.formUserEdit = this.fb.group({
      roleId: [formUser.roleId, Validators.required],
      counterId: this.accessId === '2' ? [valuecounterId, Validators.required] : this.accessId === '1' ? [valuecounterId, Validators.required]: this.accessId === '1' ? [this.counterList, Validators.required] : [formUser.counterId],
      username: [formUser.username],
      name: [formUser.name, Validators.required],
      email: [formUser.email],
      branchId: this.accessId === '1' ? [this.branchList, Validators.required] : [formUser.branchId],
      accessId: [this.accessId],
      startPage:[formUser.startPage,Validators.required],
      waNumber: [formUser.waNumber],
      cashierId: [formUser.cashierId],
    });
  }

  getCounterListByBranch(){
    
    this.counterNameCheckBox =[];
    if((this.branchList!=0 && this.accessId=='1') || this.accessId=='2'){
      this.userService.getCounterByBranch({'branch_list':this.branchList}).subscribe((response) => {
        let counterIdSplit = this.counterList!== '0' ? String(this.counterList).split(',') : [];
          
        this.counterData =response.results.user_counter;
        for(let x = 0; x < response.results.user_counter.length; x++) {
          let isChecked = false;
          if(counterIdSplit.length > 0) {
            let findId = counterIdSplit.indexOf(String(this.counterData[x].id));
            isChecked = findId !== -1 ? true : false;
          }
          if(isChecked){
            this.countCheckedCounter++;
            this.counterList = this.counterList +','+this.counterData[x].id ;              
          }
    
          let data = {
            'counter_id': this.counterData[x].id,
            'counter_name': this.counterData[x].counter_name,
            'isChecked': isChecked
          };
          this.counterNameCheckBox.push(data);
        }
        if (this.countCheckedCounter== this.counterData.length){
          this.checkedAllCounter = true;
        }
        else{
          this.checkedAllCounter = false;
        }      
      })
    }
  }

  uncheckAll() {
    if(this.branchNameCheckBox.length > 0) {
      this.counterList =0;
      let listBranchId:any='0';
      for(let i = 0; i < this.branchNameCheckBox.length; i++) {
        if(this.checkedAll === true) {
          this.branchNameCheckBox[i].isChecked = false;       
        }else{
          this.branchNameCheckBox[i].isChecked = true;
          if(listBranchId === '0') {
            listBranchId = this.branchNameCheckBox[i].branch_id;
          } else {
            listBranchId = listBranchId + ',' + this.branchNameCheckBox[i].branch_id;
          }
        }
      }
      this.branchList = listBranchId;
      this.getCounterListByBranch();
      if(this.checkedAll === true) {
        this.checkedAll = false;
        
      }
      else{          
        this.checkedAll = true;
      }
      
      const formUser = this.formUserEdit.getRawValue();
      this.accessId = formUser.accessId;
      let valuecounterId = this.counterList;   
      if(this.counterList == '0'){
        valuecounterId = null; 
      }
      this.formUserEdit = this.fb.group({
        roleId: [formUser.roleId, Validators.required],
        counterId: this.accessId === '2' ? [valuecounterId, Validators.required]:this.accessId === '1' ? [valuecounterId, Validators.required] : [formUser.counterId],
        username: [formUser.username],
        name: [formUser.name, Validators.required],
        email: [formUser.email],
        branchId: this.accessId === '1' ? [this.branchList, Validators.required] : [formUser.branchId],
        accessId: [this.accessId],
        startPage:[formUser.startPage,Validators.required],
        waNumber:[formUser.waNumber],
        cashierId:[formUser.cashierId]
      });
    }
  }
  
  selectCheckboxCounter(counter_id:any,checked:any){
    let listCounterId: any = '';
    if(this.counterNameCheckBox.length > 0) {
     let indexChecbox = (this.counterNameCheckBox.findIndex(x => x.counter_id === counter_id));
      if(checked=='check'){
        this.counterNameCheckBox[indexChecbox].isChecked=true;
      }
      else{
        this.counterNameCheckBox[indexChecbox].isChecked=false;
      }
      for(let i = 0; i < this.counterNameCheckBox.length; i++) {
        if(this.counterNameCheckBox[i].isChecked === true) {
          if(listCounterId === '') {
            listCounterId = this.counterNameCheckBox[i].counter_id;
          } else {
            listCounterId = listCounterId + ',' + this.counterNameCheckBox[i].counter_id;
          }
        }
      }
    }
    this.counterList = listCounterId;
    
    this.getCashierListByCounter();
    const formUser = this.formUserEdit.getRawValue();
    this.accessId = formUser.accessId;
    this.formUserEdit = this.fb.group({
      roleId: [formUser.roleId, Validators.required],
      counterId: this.accessId === '2' ? [this.counterList, Validators.required] :this.accessId === '1' ? [this.counterList, Validators.required]: [formUser.counterId],
      username: [formUser.username],
      name: [formUser.name, Validators.required],
      email: [formUser.email],
      branchId: this.accessId === '1' ? [this.branchList, Validators.required] : [formUser.branchId],
      accessId: [this.accessId],
      startPage:[formUser.startPage,Validators.required],
      waNumber: [formUser.waNumber],
      cashierId:[formUser.cashierId]
    });
  }


  getCashierListByCounter(){
    
    if((this.counterList!=0 && this.accessId=='1') || this.accessId=='2'){
      this.userService.getCashierByCounterId({'counter_id':this.counterList}).subscribe((response) => {
       
        this.cashierData =response.results;
           
      })
    }
  }
  uncheckAllCounter() {
    if(this.counterNameCheckBox.length > 0) {
      let listCounterId:any='';
      for(let i = 0; i < this.counterNameCheckBox.length; i++) {
        if(this.checkedAllCounter === true) {
          this.counterNameCheckBox[i].isChecked = false;       
        }else{
          this.counterNameCheckBox[i].isChecked = true;
          if(listCounterId === '') {
            listCounterId = this.counterNameCheckBox[i].counter_id;
          } else {
            listCounterId = listCounterId + ',' + this.counterNameCheckBox[i].counter_id;
          }
        }
      }
      this.counterList = listCounterId;
      if(this.checkedAllCounter === true) {
        this.checkedAllCounter = false;
      }
      else{          
        this.checkedAllCounter = true;
      }
      
      const formUser = this.formUserEdit.getRawValue();
      this.accessId = formUser.accessId;
      this.formUserEdit = this.fb.group({
        roleId: [formUser.roleId, Validators.required],
        counterId: this.accessId === '2' ? [this.counterList, Validators.required] : this.accessId === '1' ? [this.counterList, Validators.required]: [formUser.counterId],
        username: [formUser.username],
        name: [formUser.name, Validators.required],
        email: [formUser.email],
        branchId: this.accessId === '1' ? [this.branchList, Validators.required] : [formUser.branchId],
        accessId: [this.accessId],
        startPage:[formUser.startPage,Validators.required],
        waNumber:[formUser.waNumber],
        cashierId:[formUser.cashierId]
      });
    }
  }

}
