import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SetupResellerRewardEditPage } from './setup-reseller-reward-edit.page';

const routes: Routes = [
  {
    path: '',
    component: SetupResellerRewardEditPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SetupResellerRewardEditPageRoutingModule {}
