import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UserProfile } from '../../../models/user-profile.model';
import { UtilService } from '../../../service/util.service';
import { ModalKasir } from '../../../models/modal-kasir.model';
import { ModalKasirService } from '../modal-kasir.service';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';



@Component({
  selector: 'app-modal-kasir-create',
  templateUrl: './modal-kasir-create.page.html',
  styleUrls: ['./modal-kasir-create.page.scss'],
})
export class ModalKasirCreatePage implements OnInit {

  formModalKasirCreate: FormGroup;
  token: any;
  userProfile: UserProfile = new UserProfile();
  userKasirList: any[] = [];
  
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private modalKasirService: ModalKasirService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
     this.buildFormModalKasirCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      this.token = token;
      this.userProfile = new UserProfile(profile);
      this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
      if(!this.roleAccess || this.roleAccess.create!='1'){
        this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
        this.navCtrl.navigateForward(['/']);
      } 
      else{
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let convertDate = this.utilService.convertDate(new Date());
          let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
          this.modalKasirService.getKasirUser({ "token": this.token, 'counter_id': this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list }).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.userKasirList = response.results.user_kasir_data;
            this.formModalKasirCreate.patchValue({transDate: this.userProfile.counter_detail?this.userProfile.counter_detail.trans_date:todayDate});
          });
        });
      }
    })
  }

  buildFormModalKasirCreate() {
    this.formModalKasirCreate = this.fb.group({
      transDate: [0, Validators.required],
      userKasir: [0, Validators.required],
      nilaiModal: [null, Validators.required],
      keterangan: [null, Validators.required]
    });
  }

  inputModalKasir() {
      this.utilService.loadingPresent('Harap Tunggu ...')
      .then(() => {
        const modalKasirForm = this.formModalKasirCreate.getRawValue();
        let dateConvert = this.utilService.convertDate(modalKasirForm.transDate);
        let newTransDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;

        const modalKasir = new ModalKasir();
        modalKasir.counter_id = this.userProfile.counter_id;
        modalKasir.user_id = modalKasirForm.userKasir;
        modalKasir.transaction_date = newTransDate;
        modalKasir.amount = modalKasirForm.nilaiModal;
        modalKasir.description = modalKasirForm.keterangan;
        modalKasir.created_by = this.userProfile.username;
        
        this.modalKasirService.addModalKasir(modalKasir).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.showConfirmInput();
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        })

      })
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/modal-kasir']);;
          }
        }
      ]
    });

    await alert.present();
  }
}
