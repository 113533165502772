import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MasterProductGimmickDetailPageRoutingModule } from './master-product-gimmick-detail-routing.module';

import { MasterProductGimmickDetailPage } from './master-product-gimmick-detail.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MasterProductGimmickDetailPageRoutingModule
  ],
  declarations: [MasterProductGimmickDetailPage]
})
export class MasterProductGimmickDetailPageModule {}
