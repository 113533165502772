import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { AddProductPriceGroupService } from './add-product-price-group.service';

@Component({
  selector: 'app-add-product-price-group',
  templateUrl: './add-product-price-group.component.html',
  styleUrls: ['./add-product-price-group.component.scss'],
})
export class AddProductPriceGroupComponent implements OnInit {

  @Input() token: any;
  @Input() priceGroupId: any;
  @Input() productId: any;
  @Input() productName: any;
  @Input() productPrice: any;
  newProductPrice: number = 0;

  constructor(
    public modalController: ModalController, 
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private addProductPriceGroupService: AddProductPriceGroupService
    ) { }

  ngOnInit() {}

  async dismissModal() {
    await this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
      let options = {
        "token": this.token,
        "price_group_id": this.priceGroupId, 
        "product_id": this.productId,
        "price": this.newProductPrice
      };
      console.log('options', options);
      this.addProductPriceGroupService._addProductPriceGroup(options).subscribe(async (response) => {
        if(response.results) {
          this.toastCtrl.create({ duration: 2000, message: 'Sukses simpan product.' }).then(t => t.present());
          this.utilService.loadingDismiss();
        } else {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal simpan product.' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal simpan product.' }).then(t => t.present());
      });
    });
    await this.modalController.dismiss();
    
  }
}
