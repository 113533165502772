export class UserProfile {
    email: string;
    id: number;
    name: string;
    role_id: number;
    branch_id: number;
    counter_id: number;
    username: string;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    counter_detail: any;
    branch_detail: any;
    counter_id_list: any;
    cashier_id_list: any;
    role_detail: any;
    start_page: any;
    branch_list:any;
    counter_list:any;
    whatsapp_number:any;
   counter_id_pos:any;
   cashier_id:number;
   employee_id:any;
   status:any;
   is_user_kpi:any;
    /**
     *
     * @param model
     */
     constructor(model?) {
        model = model || {};
        this.email = model.email || null;
        this.id = model.id || 0;
        this.name = model.name || null;
        this.branch_id = model.branch_id || 0;
        this.role_id = model.role_id || 0;
        this.counter_id = model.counter_id || 0;
        this.username = model.username || null;
        this.created_by = model.created_by || null;
        this.created_at = model.created_at || null;
        this.updated_by = model.updated_by || null;
        this.updated_at = model.updated_at || null;
        this.counter_detail = model.counter_detail || null;
        this.branch_detail = model.branch_detail || null;
        this.counter_id_list = model.counter_id_list || null;
        this.cashier_id_list = model.cashier_id_list || null;
        this.role_detail = model.role_detail || null;
        this.start_page = model.start_page || null;
        this.branch_list = model.branch_list || null;
        this.counter_list = model.counter_list || null;
        this.whatsapp_number = model.whatsapp_number || null;
        this.counter_id_pos = model.counter_id_pos || null;
        this.cashier_id = model.cashier_id || 0;
        this.employee_id =model.employee_id || null;
        this.status = model.status || 0;
        this.is_user_kpi =model.is_user_kpi || null;
     }
}