import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomerPage } from './customer.page';

const routes: Routes = [
  {
    path: '',
    component: CustomerPage
  },
  {
    path: 'customer-create',
    loadChildren: () => import('./customer-create/customer-create.module').then( m => m.CustomerCreatePageModule)
  },
  {
    path: 'customer-edit',
    loadChildren: () => import('./customer-edit/customer-edit.module').then( m => m.CustomerEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerPageRoutingModule {}
