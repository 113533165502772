import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cashier',
  templateUrl: './cashier.component.html',
  styleUrls: ['./cashier.component.scss'],
})
export class CashierComponent implements OnInit {
  
  @Input() counterList: any[];

  baseUrl: any;
  counterSelect: any;

  constructor(
    public modalController: ModalController
  ) {
  }

  ngOnInit() {
  }

  async dismissModal() {
    const counterId: any = this.counterSelect;
    await this.modalController.dismiss(counterId);
  }

  /*openDB() {
    this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  getCounterList() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let selectQuery = 'SELECT * FROM mst_counters';
      this.db.transaction((tx) => {
        tx.executeSql(selectQuery, [],
          (tx, result) => {
            if(result.rows.length > 0) {
              this.utilService.loadingDismiss();
              this.counterList = [];
              for(let x = 0; x < result.rows.length; x++) {
                let counterData = {
                  'id': result.rows[x].id,
                  'counter_name': result.rows[x].counter_name
                };
                this.counterList.push(counterData);
              }
            }
        }, (error) => {
          this.utilService.loadingDismiss();
          console.log(error);
        });
      });
    });
  }*/

}
