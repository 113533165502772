import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RoleAccess } from '../../models/role-access.model';
import { UserProfile } from '../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../providers/user-data';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { MasterProductCounterService } from '../master-product-counter/master-product-counter.service';
import { ListingStatus } from '../../enum/ListingStatus';
import { AfProductPerCounterComponent} from '../../component/advanced-filter/af-product-per-counter/af-product-per-counter.component'
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { MasterProductService } from '../master-product/master-product.service';

@Component({
  selector: 'app-master-product-counter',
  templateUrl: './master-product-counter.page.html',
  styleUrls: ['./master-product-counter.page.scss'],
})

export class MasterProductCounterPage implements OnInit {
  
  @ViewChild('uploadFileInput', { static: false }) uploadFileInput: ElementRef;

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };
  rows: any;
  listProduct : number = 0;
  token : any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  filteredData: any;
  counterId: any= 0;
  counterName : any ='';
  counterList: any[] = [];
  keywordSearch: any;

  // advanced filter
  brandId: any;
  typeId: any;
  categoryId: any;
  listingStatusId : any;
  brandList: any[] = [];
  typeList: any[] = [];
  categoryList: any[] = [];  
  listingStatus: any[] = ['Not_Listing', 'Listing'];
  
  constructor(
    private storage:Storage,
    private userData: UserData,
    private router:Router,
    private toastCtrl : ToastController,
    private navCtrl : NavController,
    private alertController : AlertController,
    private modalController : ModalController,
    private utilService: UtilService,
    private masterProductCounterService : MasterProductCounterService,
    private mstProductService : MasterProductService

  ) { }

  ngOnInit() {
  }
  
  ListingStatus: any[] = ['Not Listing', 'Listing'];
  listingStatusName = ListingStatus;
  productStatusListing = Object.keys(ListingStatus).filter(
    listingStatusName => typeof this.listingStatusName[listingStatusName] === 'number'
  );

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.mstProductService.getDataforAdvancedFilter({"token": this.token})
          .subscribe((response) => {
            this.utilService.loadingDismiss();
            this.brandList = response.results.brand_data;
            this.typeList = response.results.type_data;
            this.categoryList = response.results.category_data;

            this.pageCallback({ offset: this.page.offset });
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          //  this.pageCallback({ offset: this.page.offset });
          });
        // this.pageCallback({ offset: this.page.offset });
          
          if(this.counterList.length === 0) {
            this.getCounterList();
          }
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  } 

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    if(this.counterId!=0){
      //this.reloadTable();
    }
  }
  
  reloadTable() {
    this.listProduct = 0;
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords" :this.keywordSearch ? this.keywordSearch : 'null',
        "counter_id": this.counterId,
        "product_brand_id": this.brandId ? this.brandId : 'null',
        "product_type_id": this.typeId ? this.typeId : 'null',
        "product_category_id": this.categoryId ? this.categoryId : 'null',
        "product_listing_status_id" : this.listingStatusId ? this.listingStatusId : 'null'
       };
      this.masterProductCounterService.countProductBeforeLoad(options).subscribe((response)=>{
        this.utilService.loadingDismiss();
        if (response.results.mst_product == response.results.product_per_counter){
          this.utilService.loadingPresent('Harap tunggu...')
          this.masterProductCounterService.getProducts(options).subscribe((response2) => {
            this.utilService.loadingDismiss();
            console.log(response2);
            this.rows = response2.results.data;
            this.listProduct = this.rows.length;
            this.filteredData = response2.results.data;
    
          }, (err) => {
            this.utilService.loadingDismiss();
            console.log(err);
            if(err.error.error === 'token_expired') {
              this.userData.logout().then(() => {
                this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
                this.navCtrl.navigateForward(['/login']);
              });
            } else {
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            }
          });
        }
        else{ // pull
          this.pullProduct();
        }
      })
    });
  }


  getCounterList() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };
      this.masterProductCounterService.getCounterList(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.counterList = response.results;
        console.log(response);
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Counter' }).then(t => t.present());
      });
    });
  }
  goToUpdatePage(id: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        PCode: id
      }
    };
    this.navCtrl.navigateForward(['/master-product-counter/master-product-counter-edit'], navigationExtras);
  }

  generate(){
    let checkCounter = this.counterList.find(x => x.id === this.counterId);
    if(checkCounter !== undefined) {
      this.counterName = checkCounter['counter_name'];
    }
      
    this.reloadTable();
  }

  async showConfirmPull() {
    const alert = await this.alertController.create({
      header: 'Pull Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk menarik data Product?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: () => {
            this.pullProduct();
          }
        }
      ]
    });

    await alert.present();
  }
  

  pullProduct() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => { 
      let options = {
      "token": this.token,
      "counter_id" : this.counterId,
      "created_by":this.userProfile.username
    };

      this.masterProductCounterService.pullProductforCounter(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.pageCallback({ offset: this.page.offset });
         // this.showPullSuccess();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }
  async showPullSuccess() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Berhasil tarik data Product!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.pageCallback({ offset: this.page.offset });
          }
        }
      ]
    });

    await alert.present();
  }

  
  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let filename = 'List Product - Counter '+this.counterName+'.xlsx';

    /* save to file */
    XLSX.writeFile(wb, filename);
  }


  onFileSelect(event) {
    let af = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      if (!_.includes(af, file.type)) {
        this.toastCtrl.create({ duration: 2000, message: 'Hanya untuk upload file excel !' }).then(t => t.present());
      }
    }
  }
  
  uploadFile() {
    if (!this.uploadFileInput.nativeElement.files[0]) {
      alert('Please fill valid details!');
      return false;
    }

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {     

      const formData = new FormData();
      formData.append('counter_id', this.counterId);
      formData.append('created_by', this.userProfile.username);
      formData.append('file', this.uploadFileInput.nativeElement.files[0]);

      this.masterProductCounterService.uploadFile(formData).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.uploadFileInput.nativeElement.value = "";
          this.reloadTable();
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  
  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: AfProductPerCounterComponent,
      componentProps: {
        brandList: this.brandList,
        typeList: this.typeList,
        categoryList: this.categoryList,
        listingStatus : this.listingStatus,
        brandId: this.brandId,
        typeId: this.typeId,
        categoryId: this.categoryId,
        listingStatusId:this.listingStatusId
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data: any = modelData.data;
      console.log(data);
      if (data.length > 0) {
        this.keywordSearch = undefined;
        this.brandId = data[0].brand_id;
        this.typeId = data[0].type_id;
        this.categoryId = data[0].category_id;
        this.listingStatusId = data[0].listing_status_id;

        this.pageCallback({ offset: this.page.offset });
      }
    });

    return await modal.present();
  }

  clearFilters() {

    this.keywordSearch = undefined;
    this.brandId = undefined;
    this.typeId = undefined;
    this.categoryId = undefined;
    this.listingStatusId = undefined;
    this.pageCallback({ offset: this.page.offset });
  }
  
  selectCounter(event){
    this.counterId = event.value.id;
    this.generate();
  }
}
