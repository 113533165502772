import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { PettyCashCategoryService } from '../petty-cash-category.service';
import { PettyCashCategory } from '../../../models/petty-cash-category.model';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';


@Component({
  selector: 'app-petty-cash-category-create',
  templateUrl: './petty-cash-category-create.page.html',
  styleUrls: ['./petty-cash-category-create.page.scss'],
})
export class PettyCashCategoryCreatePage implements OnInit {

  activeList = [{id: '1', name: 'Active'},{id: '0', name: 'Non Active'}];
  formPettyCashCategoryCreate: FormGroup;

  userProfile: UserProfile = new UserProfile();
  token: any;
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private pettyCashCategoryService: PettyCashCategoryService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormPettyCashCategoryCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormPettyCashCategoryCreate() {
    this.formPettyCashCategoryCreate = this.fb.group({
      nmKatPettyCash: [null, Validators.required],
      active: [0, Validators.required],
    })
  }
  
  inputPettyCashCategory() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const pettyCashCategoryForm = this.formPettyCashCategoryCreate.getRawValue();
      const pettyCashCategory = new PettyCashCategory();
      pettyCashCategory.petty_cash_category_name= pettyCashCategoryForm.nmKatPettyCash;
      pettyCashCategory.active= pettyCashCategoryForm.active;
      pettyCashCategory.created_by = this.userProfile.username;

      this.pettyCashCategoryService.addPettyCashCategory(pettyCashCategory).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/petty-cash-category']);;
          }
        }
      ]
    });

    await alert.present();
  }
}
