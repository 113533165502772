export enum ReportCashierType {
    Transaksi = 0,
    Detail = 1,
    Barang = 2,
    Brand = 3,
    Hadiah = 4,
    Void = 5,
    Sum_date = 6,
    Pecah_Paket_Barang = 7,
    Pecah_Paket_Brand = 8
}

export enum TransactionType {
    Semua_Kasir = 0,
    Kasir_Reguler = 1,
    Kasir_Reseller = 2
}