import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';

import { ReportService } from '../../pages/report/report.service';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-report-summary',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.scss'],
})
export class ReportSummaryComponent implements OnInit {
  @Input() reportTypeId:number;
  @Input() periodType: any;
  @Input() periodStart:any;
  @Input() periodEnd:any;
  @Input() rekapPer: any;
  @Input() rekapPer2: any;
  @Input() counterByLogin: any[];
  @Input() arrayBranch: any[];
  @Input() arrayBrand: any[];
  @Input() arrayCounter:any[];
  @Input() arrayBrand2: any[];
  @Input() arraySubBrand: any[];
  @Input() arraySubBrand2: any[];
  @Input() arrayType: any[];
  @Input() arrayType2: any[];
  @Input() arraySubType: any[];
  @Input() arraySubType2: any[];
  @Input() arrayCategory: any[];
  @Input() arrayCategory2: any[];
  @Input() arraySubCategory: any[];
  @Input() arraySubCategory2: any[];
  @Input() arrayCashierType: any[];
  @Input() arrayCashierType2: any[];
  @Input() arrayCashierId: any[];
  @Input() arrayCashierId2: any[];
  @Input() brandList: any[];
  @Input() subBrandList: any[];
  @Input() categoryList: any[];
  @Input() subCategoryList: any[];
  @Input() typeList: any[];
  @Input() subTypeList: any[];
  @Input() cashierTypeList: any[];
  @Input() cashierList: any[];
  
  @ViewChild('branchComponent', { static: false }) branchComponent: IonicSelectableComponent;
  @ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;
  @ViewChild('brandComponent', { static: false }) brandComponent: IonicSelectableComponent;
  @ViewChild('subBrandComponent', { static: false }) subBrandComponent: IonicSelectableComponent;
  @ViewChild('typeComponent', { static: false }) typeComponent: IonicSelectableComponent;
  @ViewChild('subTypeComponent', { static: false }) subTypeComponent: IonicSelectableComponent;
  @ViewChild('categoryComponent', { static: false }) categoryComponent: IonicSelectableComponent;
  @ViewChild('subCategoryComponent', { static: false }) subCategoryComponent: IonicSelectableComponent;
  @ViewChild('cashierTypeComponent', { static: false }) cashierTypeComponent: IonicSelectableComponent;
  @ViewChild('cashierIdComponent', { static: false }) cashierIdComponent: IonicSelectableComponent;
  
  @ViewChild('counterComponentSecond', { static: false }) counterComponentSecond: IonicSelectableComponent;
  @ViewChild('brandComponentSecond', { static: false }) brandComponentSecond: IonicSelectableComponent;
  @ViewChild('subBrandComponentSecond', { static: false }) subBrandComponentSecond: IonicSelectableComponent;
  @ViewChild('typeComponentSecond', { static: false }) typeComponentSecond: IonicSelectableComponent;
  @ViewChild('subTypeComponentSecond', { static: false }) subTypeComponentSecond: IonicSelectableComponent;
  @ViewChild('categoryComponentSecond', { static: false }) categoryComponentSecond: IonicSelectableComponent;
  @ViewChild('subCategoryComponentSecond', { static: false }) subCategoryComponentSecond: IonicSelectableComponent;
  @ViewChild('cashierTypeComponentSecond', { static: false }) cashierTypeComponentSecond: IonicSelectableComponent;
  @ViewChild('cashierIdComponentSecond', { static: false }) cashierIdComponentSecond: IonicSelectableComponent;

  @ViewChild('rekapPerComponent', { static: false }) rekapPerComponent: IonicSelectableComponent;
  @ViewChild('rekapPerSecondComponent', { static: false }) rekapPerSecondComponent: IonicSelectableComponent;
  
    
  branchList: any[];
  rekapPerList:any[];
  counterList:any[];

  filterBrand1: boolean = false;
  filterSubBrand1: boolean = false;
  filterType1: boolean = false;
  filterSubType1: boolean = false;
  filterCategory1: boolean = false;
  filterSubCategory1: boolean = false;
  filterCashier1: boolean = false;
  filterCashierId1: boolean = false;

  filterBrand2: boolean = false;
  filterSubBrand2: boolean = false;
  filterType2: boolean = false;
  filterSubType2: boolean = false;
  filterCategory2: boolean = false;
  filterSubCategory2: boolean = false;
  filterCashier2: boolean = false;
  filterCashierId2: boolean = false;

  token: any;
  userProfile: UserProfile = new UserProfile();

  selectedRekapPer1:any ='';
  selectedRekapPer2:any='';
  selectedBranchs:any[];
  selectedCounters: any[];
  selectedBrands:any[];
  selectedSubBrands:any[];
  selectedTypes:any[];
  selectedSubTypes:any[];
  selectedCategory:any[];
  selectedSubCategory:any[];
  selectedCashierType:any[];
  selectedCashierId:any[];
  
  selectedBrandsSecond:any[];
  selectedSubBrandsSecond:any[];
  selectedTypesSecond:any[];
  selectedSubTypesSecond:any[];
  selectedCategorySecond:any[];
  selectedSubCategorySecond:any[];
  selectedCashierTypeSecond:any[];
  selectedCashierIdSecond:any[];

  //untuk di return
  branchId:any;
  counterId:any='';
  brandId:any='';
  subBrandId:any='';
  categoryId:any='';
  subCategoryId:any='';
  typeId:any='';
  subTypeId:any='';
  cashierTypeId:any='';
  cashierId:any='';
  counterId2:any='';
  brandId2:any='';
  subBrandId2:any='';
  categoryId2:any='';
  subCategoryId2:any='';
  typeId2:any='';
  subTypeId2:any='';
  cashierTypeId2:any='';
  cashierId2:any='';

  constructor(
    private storage: Storage,
    private reportService: ReportService,
    public modalController: ModalController,
    public toastCtrl : ToastController,
    private utilService: UtilService
  ) {
  }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.rekapPerList = [
         // {'name':'Counter','id':1},
          {'name':'Brand','id':2},
          {'name':'Sub Brand','id':3},
          {'name':'Category','id':4},
          {'name':'Sub Category','id':5},
          {'name':'Type','id':6},
          {'name':'Sub Type','id':7},
          {'name':'Product','id':8},
          {'name':'Cashier Type','id':9},
          {'name':'Cashier','id':10},
        ];
        this.token = token;
        
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let options = { 
            "token": this.token,
            "counter_id": this.counterByLogin
          };
          this.reportService.getReportSummaryIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.branchList = response.results.branch_data;
            this.counterList = response.results.counter_data;
            this.brandList = response.results.brand_data;
            this.subBrandList = response.results.data_sub_brand;
            this.categoryList = response.results.data_category;
            this.subCategoryList = response.results.data_sub_category;
            this.typeList = response.results.data_type;
            this.subTypeList = response.results.data_sub_type;
            this.cashierTypeList = response.results.data_cashier_type;
            this.cashierList = response.results.data_cashier;
            
            this.branchId='';
            this.selectedBranchs=[];
            if(this.arrayBranch.length>0){
              for(let i= 0; i < this.arrayBranch.length; i++) {
                let selectedBranch: any = this.branchList.find(x => x.id ==  this.arrayBranch[i]);
                if(selectedBranch){
                  this.selectedBranchs.push(selectedBranch);
                  this.branchId += selectedBranch.id+',';
                }
              }
              this.branchId = this.branchId.replace(/(^,)|(,$)/g, "");
            }
            this.filterCounterByBranch();

            this.selectedCounters=[];
            this.counterId='';
            if(this.arrayCounter.length>0){
              for(let i= 0; i < this.arrayCounter.length; i++) {
                let selectedCounter: any = this.counterList.find(x => x.id ==  this.arrayCounter[i]);
                if(selectedCounter){
                  this.selectedCounters.push(selectedCounter);
                  this.counterId += selectedCounter.id+',';
                }
              }
              this.counterId = this.counterId.replace(/(^,)|(,$)/g, "");
            }

            let selectedRekapPer: any = this.rekapPerList.find(x => x.id ==  this.rekapPer);
            if(selectedRekapPer){
              let idx =this.rekapPerList.indexOf(selectedRekapPer);
              this.selectedRekapPer1=(this.rekapPerList[idx]);        
            }

            this.selectedRekapPer2 ='';            
            let selectedRekapPer2: any = this.rekapPerList.find(x => x.id ==  this.rekapPer2);
            if(selectedRekapPer2){
              let idx =this.rekapPerList.indexOf(selectedRekapPer2);
              this.selectedRekapPer2=(this.rekapPerList[idx]);        
            }

            this.selectedBrands=[];
            this.brandId ='';
            if(this.arrayBrand.length>0){
              for(let i= 0; i < this.arrayBrand.length; i++) {
                let selectedBrand: any = this.brandList.find(x => x.id ==  this.arrayBrand[i]);
                if(selectedBrand){
                  this.selectedBrands.push(selectedBrand);
                  this.brandId += selectedBrand.id+',';
                }
              }
              this.brandId = this.brandId.replace(/(^,)|(,$)/g, "");
            }

            this.selectedBrandsSecond=[];
            this.brandId2 ='';
            if(this.arrayBrand2.length>0){
              for(let i= 0; i < this.arrayBrand2.length; i++) {
                let selectedBrand: any = this.brandList.find(x => x.id ==  this.arrayBrand2[i]);
                if(selectedBrand){
                  this.selectedBrandsSecond.push(selectedBrand);
                  this.brandId2 += selectedBrand.id+',';
                }
              }
              this.brandId2 = this.brandId2.replace(/(^,)|(,$)/g, "");
            }

            this.selectedSubBrands=[];
            this.subBrandId ='';
            if(this.arraySubBrand.length>0){
              for(let i= 0; i < this.arraySubBrand.length; i++) {
                let selectedSubBrand: any = this.subBrandList.find(x => x.id ==  this.arraySubBrand[i]);
                if(selectedSubBrand){
                  this.selectedSubBrands.push(selectedSubBrand);
                  this.subBrandId += selectedSubBrand.id+',';
                }
              }
              this.subBrandId = this.subBrandId.replace(/(^,)|(,$)/g, "");
            }

            this.selectedSubBrandsSecond=[];
            this.subBrandId2 ='';
            if(this.arraySubBrand2.length>0){
              for(let i= 0; i < this.arraySubBrand2.length; i++) {
                let selectedSubBrand: any = this.subBrandList.find(x => x.id ==  this.arraySubBrand2[i]);
                if(selectedSubBrand){
                  this.selectedSubBrandsSecond.push(selectedSubBrand);
                  this.subBrandId2 += selectedSubBrand.id+',';
                }
              }
              this.subBrandId2 = this.subBrandId2.replace(/(^,)|(,$)/g, "");
            }

            this.selectedCategory=[];
            this.categoryId ='';
            if(this.arrayCategory.length>0){
              for(let i= 0; i < this.arrayCategory.length; i++) {
                let selectedCategory: any = this.categoryList.find(x => x.id ==  this.arrayCategory[i]);
                if(selectedCategory){
                  this.selectedCategory.push(selectedCategory);
                  this.categoryId += selectedCategory.id+',';
                }
              }
              this.categoryId = this.categoryId.replace(/(^,)|(,$)/g, "");
            }

            this.selectedCategorySecond=[];
            this.categoryId2 ='';
            if(this.arrayCategory2.length>0){
              for(let i= 0; i < this.arrayCategory.length; i++) {
                let selectedCategory: any = this.categoryList.find(x => x.id ==  this.arrayCategory2[i]);
                if(selectedCategory){
                  this.selectedCategorySecond.push(selectedCategory);
                  this.categoryId2 += selectedCategory.id+',';
                }
              }
              this.categoryId2 = this.categoryId2.replace(/(^,)|(,$)/g, "");
            }

            this.selectedSubCategory=[];
            this.subCategoryId ='';
            if(this.arraySubCategory.length>0){
              for(let i= 0; i < this.arraySubCategory.length; i++) {
                let selectedSubCategory: any = this.subCategoryList.find(x => x.id ==  this.arraySubCategory[i]);
                if(selectedSubCategory){
                  this.selectedSubCategory.push(selectedSubCategory);
                  this.subCategoryId += selectedSubCategory.id+',';
                }
              }
              this.subCategoryId = this.subCategoryId.replace(/(^,)|(,$)/g, "");
            }

            this.selectedSubCategorySecond=[];
            this.subCategoryId2 ='';
            if(this.arraySubCategory2.length>0){
              for(let i= 0; i < this.arraySubCategory2.length; i++) {
                let selectedSubCategory: any = this.subCategoryList.find(x => x.id ==  this.arraySubCategory2[i]);
                if(selectedSubCategory){
                  this.selectedSubCategorySecond.push(selectedSubCategory);
                  this.subCategoryId2 += selectedSubCategory.id+',';
                }
              }
              this.subCategoryId2 = this.subCategoryId2.replace(/(^,)|(,$)/g, "");
            }

            this.selectedTypes=[];
            this.typeId ='';
            if(this.arrayType.length>0){
              for(let i= 0; i < this.arrayType.length; i++) {
                let selectedType: any = this.typeList.find(x => x.id ==  this.arrayType[i]);
                if(selectedType){
                  this.selectedTypes.push(selectedType);
                  this.typeId += selectedType.id+',';
                }
              }
              this.typeId = this.typeId.replace(/(^,)|(,$)/g, "");
            }

            this.selectedTypesSecond=[];
            this.typeId2 ='';
            if(this.arrayType2.length>0){
              for(let i= 0; i < this.arrayType2.length; i++) {
                let selectedType: any = this.typeList.find(x => x.id ==  this.arrayType2[i]);
                if(selectedType){
                  this.selectedTypesSecond.push(selectedType);
                  this.typeId2 += selectedType.id+',';
                }
              }
              this.typeId2 = this.typeId2.replace(/(^,)|(,$)/g, "");
            }

            this.selectedCashierType=[];
            this.cashierTypeId ='';
            if(this.arrayCashierType.length>0){
              for(let i= 0; i < this.arrayCashierType.length; i++) {
                let selectedCashierType: any = this.cashierTypeList.find(x => x.id ==  this.arrayCashierType[i]);
                if(selectedCashierType){
                  this.selectedCashierType.push(selectedCashierType);
                  this.cashierTypeId += selectedCashierType.id+',';
                }
              }
              this.cashierTypeId = this.cashierTypeId.replace(/(^,)|(,$)/g, "");
            }
            this.selectedCashierTypeSecond=[];
            this.cashierTypeId2 ='';
            if(this.arrayCashierType2.length>0){
              for(let i= 0; i < this.arrayCashierType2.length; i++) {
                let selectedCashierType: any = this.cashierTypeList.find(x => x.id ==  this.arrayCashierType2[i]);
                if(selectedCashierType){
                  this.selectedCashierTypeSecond.push(selectedCashierType);
                  this.cashierTypeId2 += selectedCashierType.id+',';
                }
              }
              this.cashierTypeId2 = this.cashierTypeId2.replace(/(^,)|(,$)/g, "");
            }

            this.selectedCashierId=[];
            this.cashierId ='';
            if(this.arrayCashierId.length>0){
              for(let i= 0; i < this.arrayCashierId.length; i++) {
                let selectedCashierId: any = this.cashierList.find(x => x.id ==  this.arrayCashierId[i]);
                if(selectedCashierId){
                  this.selectedCashierId.push(selectedCashierId);
                  this.cashierId += selectedCashierId.id+',';
                }
              }
              this.cashierId = this.cashierId.replace(/(^,)|(,$)/g, "");
            }
            this.selectedCashierIdSecond=[];
            this.cashierId2 ='';
            if(this.arrayCashierId2.length>0){
              for(let i= 0; i < this.arrayCashierId2.length; i++) {
                let selectedCashierId: any = this.cashierList.find(x => x.id ==  this.arrayCashierId2[i]);
                if(selectedCashierId){
                  this.selectedCashierIdSecond.push(selectedCashierId);
                  this.cashierId2 += selectedCashierId.id+',';
                }
              }
              this.cashierId2 = this.cashierId2.replace(/(^,)|(,$)/g, "");
            }

            this.filter1();
            this.filter2();
          }, () => {});
        });
      }
    })
  }
  filter1(){
    this.filterCashier1 = false;
    this.filterBrand1 = false;
    this.filterSubBrand1 = false;
    this.filterType1 = false;
    this.filterSubType1 = false;
    this.filterCategory1 = false;
    this.filterSubCategory1 = false;
    this.filterCashierId1 = false;
    if(this.rekapPer=='2'){
      this.filterBrand1 = true;
    }
    else if(this.rekapPer=='3'){
      this.filterSubBrand1 = true;
    }
    else if(this.rekapPer=='4'){
      this.filterCategory1 = true;
    }
    else if(this.rekapPer=='5'){
      this.filterSubCategory1 = true;
    }
    else if(this.rekapPer=='6'){
      this.filterType1 = true;
    }
    else if(this.rekapPer=='7'){
      this.filterSubType1 = true;
    }
    else if(this.rekapPer=='9'){
      this.filterCashier1 = true;
    }
    else if(this.rekapPer=='10'){
      this.filterCashierId1 = true;
    }
  }
  
  filter2(){
    this.filterCashier2 = false;
    this.filterBrand2 = false;
    this.filterSubBrand2 = false;
    this.filterType2 = false;
    this.filterSubType2 = false;
    this.filterCategory2 = false;
    this.filterSubCategory2 = false;
    this.filterCashierId2 = false;
    if(this.rekapPer2=='2'){
      this.filterBrand2 = true;
    }
    else if(this.rekapPer2=='3'){
      this.filterSubBrand2 = true;
    }
    else if(this.rekapPer2=='4'){
      this.filterCategory2 = true;
    }
    else if(this.rekapPer2=='5'){
      this.filterSubCategory2 = true;
    }
    else if(this.rekapPer2=='6'){
      this.filterType2 = true;
    }
    else if(this.rekapPer2=='7'){
      this.filterSubType2 = true;
    }
    else if(this.rekapPer2=='9'){
      this.filterCashier2 = true;
    }
    else if(this.rekapPer2=='10'){
      this.filterCashierId2 = true;
    }
  }

  async dismissModal(code: any) {
    let dataReturn: any;
    let error = 0;
    
    if(code === 'generate') {
       dataReturn = [];
  
        let filterData = {
          reportType: this.reportTypeId,
          periodStart: this.periodStart,
          periodEnd: this.periodEnd,
          branchId:this.branchId,
          counterId :this.counterId,
          brandId : this.brandId,
          subBrandId : this.subBrandId,
          typeId : this.typeId,
          subTypeId : this.subTypeId,
          categoryId : this.categoryId,
          subCategoryId : this.subCategoryId,
          cashierTypeId : this.cashierTypeId,
          cashierId : this.cashierId,
          rekapPer:this.rekapPer,
          counterId2 :this.counterId2,
          brandId2 : this.brandId2,
          subBrandId2 : this.subBrandId2,
          typeId2 : this.typeId2,
          subTypeId2 : this.subTypeId2,
          categoryId2 : this.categoryId2,
          subCategoryId2 : this.subCategoryId2,
          cashierTypeId2 : this.cashierTypeId2,
          cashierId2 : this.cashierId2,
          rekapPer2:this.rekapPer2,
          periodType:this.periodType
        };
  
        dataReturn.push(filterData);
        if(this.rekapPer=='' && this.reportTypeId==1){
          this.toastCtrl.create({ duration: 2000, message: 'Mohon Pilih Rekap Per ' }).then(t => t.present());
          error++;
        }
        if(this.periodStart==''){
          this.toastCtrl.create({ duration: 2000, message: 'Mohon Pilih Periode ' }).then(t => t.present());
          error++;
        }
        if(this.counterId==''){
          this.toastCtrl.create({ duration: 2000, message: 'Mohon Pilih Counter ' }).then(t => t.present());
          error++;
        }
        if(this.reportTypeId==0){
          this.toastCtrl.create({ duration: 2000, message: 'Mohon Pilih Tipe Report ' }).then(t => t.present());
          error++;
        }
      
    }
    if(code === 'close') {
      dataReturn = 'null';
    }
    if(error==0){
      await this.modalController.dismiss(dataReturn);
    }
  }

  toggleBranchItems() {
    this.branchComponent.toggleItems(
      this.branchComponent.itemsToConfirm.length ? false : true
    );
  }
  confirmBranch() {
    this.branchComponent.confirm();
    this.branchComponent.close();
    this.filterCounterByBranch();
  }

  filterCounterByBranch(){
    this.counterId ='';
    this.selectedCounters=[];
    let idBranch = '';
    for(let i=0;i<this.selectedBranchs.length;i++){
      idBranch += this.selectedBranchs[i].id+',';
    }
    this.branchId = idBranch.replace(/(^,)|(,$)/g, "");
    if(this.branchId==''){
      this.counterId=this.counterByLogin;
    }
    let options = { 
      "token": this.token,
      "branch_id": this.branchId,
      "counter_id": this.counterByLogin 
    };
    this.reportService.getCounterByBranchId(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.counterList =response.results;
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });    
  }
  
  toggleCounterItems() {
    this.counterComponent.toggleItems(
      this.counterComponent.itemsToConfirm.length ? false : true
    );
  }
  
  confirmCounter() {
    this.counterComponent.confirm();
    this.counterComponent.close();
    let idCounter = '';
    for(let i= 0; i < this.counterComponent.value.length; i++) {
      idCounter += this.counterComponent.value[i]['id']+',';
    }
    this.counterId = idCounter.replace(/(^,)|(,$)/g, "");
  }
  
  toggleItemBrand() {
    this.brandComponent.toggleItems(
      this.brandComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmBrand() {
    this.resetFilter1();
    this.brandComponent.confirm();
    this.brandComponent.close();
    let idBrand = '';
    for(let i= 0; i < this.brandComponent.value.length; i++) {
        idBrand += this.brandComponent.value[i]['id']+',';
    }
    this.brandId = idBrand.replace(/(^,)|(,$)/g, "");
  }

  selectBrand(){
    let idBrand = '';
    for(let i= 0; i < this.brandList.length; i++) {
      let selectedBrand: any = '';//this.brandList.find(x => x.id ==  counterIdSplit[i]);
      if(selectedBrand){
        let idx =this.brandList.indexOf(selectedBrand);
        this.selectedBrands.push(this.brandList[idx]);
        idBrand += this.brandList[idx]['id']+',';
      }
    }
    this.brandId = idBrand.replace(/(^,)|(,$)/g, "");

  }
  
  toggleItemSubBrand() {
    this.subBrandComponent.toggleItems(
      this.subBrandComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmSubBrand() {
    this.resetFilter1();
    this.subBrandComponent.confirm();
    this.subBrandComponent.close();
    let idSubBrand = '';
    for(let i= 0; i < this.subBrandComponent.value.length; i++) {
      idSubBrand += this.subBrandComponent.value[i]['id']+',';
    }
    this.subBrandId = idSubBrand.replace(/(^,)|(,$)/g, "");
  }

  toggleItemType() {
    this.typeComponent.toggleItems(
      this.typeComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmType() {
    this.resetFilter1();
    this.typeComponent.confirm();
    this.typeComponent.close();
    let idType = '';
    for(let i= 0; i < this.typeComponent.value.length; i++) {
      idType += this.typeComponent.value[i]['id']+',';
    }
    this.typeId = idType.replace(/(^,)|(,$)/g, "");
  }

  toggleItemSubType() {
    this.subTypeComponent.toggleItems(
      this.subTypeComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmSubType() {
    this.resetFilter1();
    this.subTypeComponent.confirm();
    this.subTypeComponent.close();
    let idSubType = '';
    for(let i= 0; i < this.subTypeComponent.value.length; i++) {
      idSubType += this.subTypeComponent.value[i]['id']+',';
    }
    this.subTypeId = idSubType.replace(/(^,)|(,$)/g, "");
  }
  
  toggleItemCategory() {
    this.categoryComponent.toggleItems(
      this.categoryComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmCategory() {
    this.resetFilter1();
    this.categoryComponent.confirm();
    this.categoryComponent.close();
    let idCategory = '';
    for(let i= 0; i < this.categoryComponent.value.length; i++) {
      idCategory += this.categoryComponent.value[i]['id']+',';
    }
    this.categoryId = idCategory.replace(/(^,)|(,$)/g, "");
  }
  
  toggleItemSubCategory() {
    this.subCategoryComponent.toggleItems(
      this.subCategoryComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmSubCategory() {
    this.resetFilter1();
    this.subCategoryComponent.confirm();
    this.subCategoryComponent.close();
    let idSubCategory = '';
    for(let i= 0; i < this.subCategoryComponent.value.length; i++) {
      idSubCategory += this.subCategoryComponent.value[i]['id']+',';
    }
    this.subCategoryId = idSubCategory.replace(/(^,)|(,$)/g, "");
  }

  resetFilter1(){
    this.rekapPer='';
    this.rekapPer2='';
    this.brandId = '';
    this.subBrandId = '';
    this.typeId = '';
    this.subTypeId = '';
    this.categoryId = '';
    this.subCategoryId = '';
    this.cashierTypeId = '';
    this.cashierId ='';
  }
  
  toggleItemCashierType() {
    this.cashierTypeComponent.toggleItems(
      this.cashierTypeComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmCashierType() {
    this.resetFilter1();
    this.cashierTypeComponent.confirm();
    this.cashierTypeComponent.close();
    let idCashierType = '';
    for(let i= 0; i < this.cashierTypeComponent.value.length; i++) {
      idCashierType += this.cashierTypeComponent.value[i]['id']+',';
    }
    this.cashierTypeId = idCashierType.replace(/(^,)|(,$)/g, "");
  }
  
  toggleItemCashierId() {
    this.cashierIdComponent.toggleItems(
      this.cashierIdComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmCashierId() {
    this.resetFilter1();
    this.cashierIdComponent.confirm();
    this.cashierIdComponent.close();
    let idCashier = '';
    for(let i= 0; i < this.cashierIdComponent.value.length; i++) {
      idCashier += this.cashierIdComponent.value[i]['id']+',';
    }
    this.cashierId = idCashier.replace(/(^,)|(,$)/g, "");
  }
  
  confirmRekapPer() {
    this.resetFilter1();
    this.rekapPerComponent.confirm();
    this.rekapPerComponent.close();
    this.rekapPer = this.rekapPerComponent.value.id;
    this.filter1();
  }

  resetFilter2(){
    this.brandId2 = '';
    this.subBrandId2 = '';
    this.typeId2 = '';
    this.subTypeId2 = '';
    this.categoryId2 = '';
    this.subCategoryId2 = '';
    this.counterId2 ='';
    this.cashierTypeId2 = '';
    this.cashierId2 ='';
  }

  toggleItemBrandSecond() {
    this.brandComponentSecond.toggleItems(
      this.brandComponentSecond.itemsToConfirm.length ? false : true
    );
  }

  confirmBrandSecond() {
    this.resetFilter2();
    this.brandComponentSecond.confirm();
    this.brandComponentSecond.close();
    let idBrand = '';
    for(let i= 0; i < this.brandComponentSecond.value.length; i++) {
        idBrand += this.brandComponentSecond.value[i]['id']+',';
    }
    this.brandId2 = idBrand.replace(/(^,)|(,$)/g, "");
  }
  
  
  toggleItemSubBrandSecond() {
    this.subBrandComponentSecond.toggleItems(
      this.subBrandComponentSecond.itemsToConfirm.length ? false : true
    );
  }

  confirmSubBrandSecond() {
    this.resetFilter2();
    this.subBrandComponentSecond.confirm();
    this.subBrandComponentSecond.close();
    let idSubBrand = '';
    for(let i= 0; i < this.subBrandComponentSecond.value.length; i++) {
      idSubBrand += this.subBrandComponentSecond.value[i]['id']+',';
    }
    this.subBrandId2 = idSubBrand.replace(/(^,)|(,$)/g, "");
  }

  toggleItemTypeSecond() {
    this.typeComponentSecond.toggleItems(
      this.typeComponentSecond.itemsToConfirm.length ? false : true
    );
  }

  confirmTypeSecond() {
    this.resetFilter2();
    this.typeComponentSecond.confirm();
    this.typeComponentSecond.close();
    let idType = '';
    for(let i= 0; i < this.typeComponentSecond.value.length; i++) {
      idType += this.typeComponentSecond.value[i]['id']+',';
    }
    this.typeId2 = idType.replace(/(^,)|(,$)/g, "");
  }

  toggleItemSubTypeSecond() {
    this.subTypeComponentSecond.toggleItems(
      this.subTypeComponentSecond.itemsToConfirm.length ? false : true
    );
  }

  confirmSubTypeSecond() {
    this.resetFilter2();
    this.subTypeComponentSecond.confirm();
    this.subTypeComponentSecond.close();
    let idSubType = '';
    for(let i= 0; i < this.subTypeComponentSecond.value.length; i++) {
      idSubType += this.subTypeComponentSecond.value[i]['id']+',';
    }
    this.subTypeId2 = idSubType.replace(/(^,)|(,$)/g, "");
  }
  
  toggleItemCategorySecond() {
    this.categoryComponentSecond.toggleItems(
      this.categoryComponentSecond.itemsToConfirm.length ? false : true
    );
  }

  confirmCategorySecond() {
    this.resetFilter2();
    this.categoryComponentSecond.confirm();
    this.categoryComponentSecond.close();
    let idCategory = '';
    for(let i= 0; i < this.categoryComponentSecond.value.length; i++) {
      idCategory += this.categoryComponentSecond.value[i]['id']+',';
    }
    this.categoryId2 = idCategory.replace(/(^,)|(,$)/g, "");
  }
  
  toggleItemSubCategorySecond() {
    this.subCategoryComponentSecond.toggleItems(
      this.subCategoryComponentSecond.itemsToConfirm.length ? false : true
    );
  }

  confirmSubCategorySecond() {
    this.resetFilter2();
    this.subCategoryComponentSecond.confirm();
    this.subCategoryComponentSecond.close();
    let idSubCategory = '';
    for(let i= 0; i < this.subCategoryComponentSecond.value.length; i++) {
      idSubCategory += this.subCategoryComponentSecond.value[i]['id']+',';
    }
    this.subCategoryId2 = idSubCategory.replace(/(^,)|(,$)/g, "");
  }

  toggleItemCashierTypeSecond() {
    this.cashierTypeComponentSecond.toggleItems(
      this.cashierTypeComponentSecond.itemsToConfirm.length ? false : true
    );
  }

  confirmCashierTypeSecond() {
    this.resetFilter1();
    this.cashierTypeComponentSecond.confirm();
    this.cashierTypeComponentSecond.close();
    let idCashierType = '';
    for(let i= 0; i < this.cashierTypeComponentSecond.value.length; i++) {
      idCashierType += this.cashierTypeComponentSecond.value[i]['id']+',';
    }
    this.cashierTypeId2 = idCashierType.replace(/(^,)|(,$)/g, "");
  }
  
  toggleItemCashierIdSecond() {
    this.cashierIdComponentSecond.toggleItems(
      this.cashierIdComponentSecond.itemsToConfirm.length ? false : true
    );
  }

  confirmCashierIdSecond() {
    this.resetFilter2();
    this.cashierIdComponentSecond.confirm();
    this.cashierIdComponentSecond.close();
    let idCashier = '';
    for(let i= 0; i < this.cashierIdComponentSecond.value.length; i++) {
      idCashier += this.cashierIdComponentSecond.value[i]['id']+',';
    }
    this.cashierId2 = idCashier.replace(/(^,)|(,$)/g, "");
  }

  confirmRekapPerSecond() {
    this.resetFilter2();
    this.rekapPerSecondComponent.confirm();
    this.rekapPerSecondComponent.close();
    this.rekapPer2 = this.rekapPerSecondComponent.value.id;
    this.filter2();
  }

  selectPeriodeType(e:any){
    if(this.reportTypeId==1){
    }
    else{
      this.periodType='monthly';
    }
  }  
}