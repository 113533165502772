import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SetupResellerRewardPage } from './setup-reseller-reward.page';

const routes: Routes = [
  {
    path: '',
    component: SetupResellerRewardPage
  },
  {
    path: 'setup-reseller-reward-create',
    loadChildren: () => import('./setup-reseller-reward-create/setup-reseller-reward-create.module').then( m => m.SetupResellerRewardCreatePageModule)
  },
  {
    path: 'setup-reseller-reward-edit',
    loadChildren: () => import('./setup-reseller-reward-edit/setup-reseller-reward-edit.module').then( m => m.SetupResellerRewardEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SetupResellerRewardPageRoutingModule {}
