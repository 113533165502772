import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { RoleAccess } from '../../models/role-access.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleAccessService {

  constructor(private httpService: HttpService) { }

  getRoleAccessforEdit(roleId: any, params: any): Observable<any> {
    return this.httpService.get(`role-access/${roleId}/edit`, params);
  }

  processRoleAccess(roleId: any, data: RoleAccess, action: any): Observable<any> {
    if(action === 'post') {
      return this.httpService.post('role-access', data);
    }

    if(action === 'put') {
      return this.httpService.put(`role-access/${roleId}`, data);
    }
  }
}
