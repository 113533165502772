import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PettyCashCreatePageRoutingModule } from './petty-cash-create-routing.module';

import { PettyCashCreatePage } from './petty-cash-create.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PettyCashCreatePageRoutingModule,
    ReactiveFormsModule
  ],
  declarations: [PettyCashCreatePage]
})
export class PettyCashCreatePageModule {}
