import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyService {


  DECIMAL_SEPARATOR:any=".";
  GROUP_SEPARATOR:any=",";
  constructor() { }
  
  unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/^0+/, '');
    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
};

  separator(paramName:string){
    let parts = this.unFormat(String([paramName])).split(this.DECIMAL_SEPARATOR);
    let result = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
    return result;   
  }
  removeSeparator(paramName:string){
    let result = parseInt(String(paramName).replace(/[^0-9]/g, ''));
    return result;
  }
}
