import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PettyCashCategoryCreatePage } from './petty-cash-category-create.page';

const routes: Routes = [
  {
    path: '',
    component: PettyCashCategoryCreatePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PettyCashCategoryCreatePageRoutingModule {}
