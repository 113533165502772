import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.component.html',
  styleUrls: ['./promotion-detail.component.scss'],
})
export class PromotionDetailComponent implements OnInit {

  productBarcode: any = "";
  @Input() mstProductList: any[];
  @Input() productScan: any;
  @Input() productFreeItem: any;
  @Input() listProductScan:any;
  @Input() productRequired:any;
  @Input() action: any;
  @Input() token: any;
  @Input() counterId: any;
  @Input() mstProductGimmickList:any[];
  @Input() stockData:any[];
  @Input() viewOnly:any;
  productList: any[] = [];
  products: any[]=[];
  keyword:any;
  showStock:boolean=false;
  checkedAllProduct : boolean = false;
  @ViewChild('productComponent', { static: false }) productComponent: IonicSelectableComponent;
  
  
  constructor(
    public modalController: ModalController,
    private toastCtrl: ToastController,
    private alertController: AlertController,
  ) { }

  ngOnInit() {
    if(this.action === 'product') {
      if(this.productScan !== null) {
        this.setProductList(this.productScan);
      }
    }

    else if(this.action === 'bonus') {
      if(this.productFreeItem !== null) {
        this.setProductList(this.productFreeItem);
      }
    }
    else if(this.action=='listProduct') {
      if(this.listProductScan !== null) {
        this.setProductList(this.listProductScan);
      }
    }
    else if(this.action=='productRequired') {
      if(this.productRequired !== null) {
        this.setProductList(this.productRequired);
      }
    }
  }

  setProductList(product: any) {
    this.productList = [];
    let listProduct: any[] = product.split(';');
    if(listProduct.length > 0) {
      for(let i = 0; i < listProduct.length; i++) {
        let getStock: any = this.stockData.find(s => s.product_id === listProduct[i]);
        let stock =0;
        if(getStock){
          stock= getStock.end_stock;
        }
        let reguler: any =this.mstProductList.find(x=> x.id===listProduct[i]);
        if(reguler){
          this.productList.push({
            id: reguler.id,
            barcode: reguler.barcode,
            name: reguler.product_name,
            stock: stock,
            terpakai: 0,
            type:'R'
          });
        }
        else{//gimmick
          let gimmick: any = this.mstProductGimmickList.find(x=> x.id === listProduct[i]);
          if(gimmick){
            this.productList.push({
              id: gimmick.id,
              barcode: gimmick.barcode,
              name: gimmick.product_name,
              stock: stock,
              terpakai: 0,
              type:'P'
            });
          }
        }
      }
    }
  }
  async getProduct(productBarcode: any) {
    this.keyword=productBarcode;
    this.products = [];
    if(this.keyword.length >= 3) {
      this.products = [];
      let reguler: any =this.mstProductList.filter(x=> x.id.toUpperCase().includes(this.keyword.toUpperCase()) || x.product_name.toUpperCase().includes(this.keyword.toUpperCase()) || (x.barcode !==null && x.barcode.includes(this.keyword.toUpperCase())));
      if(reguler.length >0){
        for(let g=0;g<reguler.length;g++){
          this.products.push({
            id: reguler[g].id,
            barcode: reguler[g].barcode,
            product_name: reguler[g].product_name
          });
        }
      }
      let gimmick: any = this.mstProductGimmickList.filter(x=> x.id.toUpperCase().includes(this.keyword.toUpperCase()) || x.product_name.toUpperCase().includes(this.keyword.toUpperCase()) || x.barcode.toUpperCase().includes(this.keyword.toUpperCase()));
      if(gimmick.length >0){
        for(let g=0;g<gimmick.length;g++){
          this.products.push({
            id: gimmick[g].id,
            barcode: gimmick[g].barcode,
            product_name: gimmick[g].product_name
          });
        }
      }
    }
    else{
      this.toastCtrl.create({ duration: 2000, message: 'Minimum 3 karakter' }).then(t => t.present());
    }
  }

  async dismissModal() {
    await this.modalController.dismiss(this.productList.length > 0 ? this.productList : '');
  }

  scrollDownEnd() {
    setTimeout(()=>{   
        var elem = document.getElementById('item');
        elem.scrollTop = elem.scrollHeight;
    }, 50);
  }

  async showAlertBarcodeNotValid(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    });

    await alert.present();
  }

  deleteDetail(index: any) {
    this.productList.splice(index, 1);
  }

  chooseProduct(productId: any) {
    if(productId !== '' && productId !== undefined) {
      let checkItemPcode: any[] = this.productList.find(x => x.id === productId.id);
      if (checkItemPcode === undefined) {
        let getStock: any = this.stockData.find(s => s.product_id === productId.id);
        let stock =0;
        if(getStock){
          stock= getStock.end_stock;
        }
        this.productList.push({
          id: productId.id,
          barcode: productId.barcode,
          name: productId.product_name,
          stock: stock,
          terpakai: 0,
          type:productId.product_type
        });
      //  this.products=[];
        //this.keyword='';
        //this.productBarcode='';
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Product sudah ada di list!' }).then(t => t.present());
      }
    }
    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }
  
  toggleCounterItems() {
    this.productComponent.toggleItems(
      this.productComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmCounter() {
    this.productComponent.confirm();
    this.productComponent.close();
    
    let selectedProductList = [];
    selectedProductList = this.productComponent.value;
    for(let i=0;i<selectedProductList.length;i++){
      let idProduct = selectedProductList[i].id;
      let findProduct = this.products.find(x=>x.id==idProduct)
      if(findProduct){
        
        let getStock: any = this.stockData.find(s => s.product_id === idProduct);
        let stock =0;
        if(getStock){
          stock= getStock.end_stock;
        }
        this.productList.push({
          id: findProduct.id,
          barcode: findProduct.barcode,
          name: findProduct.product_name,
          stock: stock,
          terpakai: 0,
          type:findProduct.product_type
        });
      }
    }
  }
  
  selectCheckboxCounter(product_id:any,checked:any){
    if(this.products.length > 0) {
      let Checbox = (this.products.find(x => x.id === product_id));
     let indexChecbox = (this.products.findIndex(x => x.id === product_id));
      if(checked=='check'){
        this.products[indexChecbox].isChecked=true;
        let cari =this.productList.find(x=>x.id==product_id);
        if(cari==undefined){
          this.chooseProduct(Checbox);
          if(this.productList.length==this.products.length){
            this.checkedAllProduct=true;
          }
          else{
            this.checkedAllProduct==false;
          }
        }
      }
      else{
        this.products[indexChecbox].isChecked=false;
        let cari =this.productList.find(x=>x.id==product_id);
        if(cari!==undefined){
          let index = this.productList.indexOf(cari);
          this.productList.splice(index, 1);
          this.checkedAllProduct=false;
        }
      }
    }
    
  }
  uncheckAllProduct() {
    if(this.products.length > 0) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.checkedAllProduct === true) {
          this.products[i].isChecked = false; 
          let cari =this.productList.find(x=>x.id==this.products[i].id);
          if(cari!==undefined){
            let index = this.productList.indexOf(cari);
            this.productList.splice(index, 1);
          }      
        }else{
          this.products[i].isChecked = true;
          let cari =this.productList.find(x=>x.id==this.products[i].id);
          if(cari==undefined){
            this.chooseProduct(this.products[i]);
          }
        }
      }
      if(this.checkedAllProduct === true) {
        this.checkedAllProduct = false;
      }
      else{          
        this.checkedAllProduct = true;
      }
    }
  }
}