
import { Component, OnInit } from '@angular/core';
import { DisplayCustomerService } from '../display-customer.service';
import { NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';
import { UserProfile } from '../../../models/user-profile.model';

@Component({
  selector: 'app-slider-list',
  templateUrl: './slider-list.page.html',
  styleUrls: ['./slider-list.page.scss'],
})
export class SliderListPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  rows: any;
  token: any;
  userAccess: any[] = [];
  roleAccess = new RoleAccess();
  userProfile: UserProfile = new UserProfile();
  roleName: any;
  keywordSearch: any;
  constructor(
    private navCtrl:NavController,
    private storage: Storage,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private router: Router,
    private displayCustomerService: DisplayCustomerService
  ) { }

  ngOnInit() {
  }
  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userAccess = access;
        this.roleName = this.userProfile.role_detail ? this.userProfile.role_detail.role_name : null;
        this.roleAccess = this.checkAccess(this.router.url, this.userAccess, this.userProfile);
       
        console.log('url '+this.router.url)
        console.log(this.roleAccess);
        console.log(this.userAccess);
        if(!this.roleAccess  || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
         // this.navCtrl.navigateForward(['/']);
        }
       // else{
          this.pageCallback({ offset: this.page.offset });
        //}
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  checkAccess(url: any, userAccess: any, userProfile: any) {
    let arrTemp = String(url).split('/');
    console.log(arrTemp);
    let thisPage = arrTemp[1];
    if(arrTemp.length==3){
      thisPage = arrTemp[1] +'/'+arrTemp[2];
    }
    let checkUserAccess: any = userAccess.find(x => x.root === thisPage);
    const roleAccess = new RoleAccess();
    if(checkUserAccess) {
      roleAccess.approve = checkUserAccess.approve;
      roleAccess.create = checkUserAccess.create;
      roleAccess.delete = checkUserAccess.delete;
      roleAccess.download = checkUserAccess.download;
      roleAccess.menu_id = checkUserAccess.id;
      roleAccess.read = checkUserAccess.read;
      roleAccess.read_all = checkUserAccess.read_all;
      roleAccess.role_id = userProfile.role_id;
      roleAccess.update = checkUserAccess.update;
      roleAccess.view = checkUserAccess.view;
    }

    return roleAccess;
  }
  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords" :this.keywordSearch ? this.keywordSearch : 'null',
        "menu":"setup",
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.displayCustomerService.getSlider(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        //this.page.count = response.results.total;
        this.rows = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }


  goToSliderCreate() {
    localStorage.setItem('slider-id', '0');
    this.navCtrl.navigateForward(['/display-customer/slider-form']);
  }
  goToSliderUpdate(id:any) {
    localStorage.setItem('slider-id', id);
    this.navCtrl.navigateForward(['/display-customer/slider-form']);
  }
}
