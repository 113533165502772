import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-claim-insentif-summary',
  templateUrl: './claim-insentif-summary.page.html',
  styleUrls: ['./claim-insentif-summary.page.scss'],
})
export class ClaimInsentifSummaryPage implements OnInit {
  
  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };
  periode: any= new Date().toISOString();
  totalBelanja: any='all';
  baseUrl: any;
  rows: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  customerList: any;
  filteredData: any;
  keywordSearch: any;
  year: any;
  month: any;
  constructor(
    private storage: Storage,
    private http: HttpClient,
    private navCtrl: NavController,
    private alertController: AlertController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData

  ) { 
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
    console.log('pageCallback');
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
    console.log('sortCallback');
  }

  reloadTable() {
    console.log('s');
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
        let dateConvert = this.utilService.convertDate(this.periode);
        this.year = dateConvert.years;
        this.month = dateConvert.months;
        const params = new HttpParams()
        .set('token', this.token)
        .set('year', this.year)
        .set('month', this.month)
        .set('total_belanja', this.totalBelanja)
        .set('page', offset.toString());

      this.http.get(this.baseUrl + 'summary-claim-insentif', { params })
      .subscribe((response: any) => {
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.customerList = response.results.data;
        this.utilService.loadingDismiss();
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  async showDeleteSuccess() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Berhasil hapus data!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.pageCallback({ offset: this.page.offset });
          }
        }
      ]
    });

    await alert.present();
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 5;
    let keys = Object.keys(this.customerList[0]);
    this.rows = this.filteredData.filter(function(item){
      console.log('item', item);
      for (let i=0; i < columnLength; i++){
        if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
          return true;
        }
      }
    });

    this.page.offset = 0;
  }

}
