import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetupApprovalService {

  constructor(private httpService: HttpService) { }
  getSetupApproval(params: any): Observable<any> {
    return this.httpService.get('setup-approval', params);
  }

  getSetupApprovalforEdit(modules: any, params: any): Observable<any> {
    return this.httpService.get(`setup-approval-detail/${modules}/edit`, params);
  }
  
  updateSetupApproval(data: any): Observable<any> {
    return this.httpService.post('setup-approval/update-approval', data);
  }
  deleteApproval(approvalId:any): Observable<any>{
    return this.httpService.delete(`setup-approval-detail/${approvalId}`);
  }
}
