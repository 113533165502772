export class SalesTransaction {
    
    id: string;
    cashier_id: number;
    user_id: number;
    counter_id: number;
    warehouse_id: number;
    customer_id: number;
    gsa_id: number;
    agent_id: number;
    guide_id: number;
    receipt_no: string;
    trans_date: string;
    trans_time: string;
    total_item: number;
    total_value: number;
    total_payment: number;
    discount: number;
    change: number;
    cash: number;
    transfer: number;
    credit_card: number;
    debit_card: number;
    voucher: number;
    member_point: number;
    travel_voucher: number;
    tax_basis: number;
    vat: number;
    description: string;
    discount_code: string;
    discount_value: number;
    commission_status: string;
    commission_extra_status: string;
    foreign_currency_1: number;
    exchange_rate_1: number;
    currency_1: string;
    foreign_currency_2: number;
    exchange_rate_2: number;
    currency_2: string;
    foreign_currency_3: number;
    exchange_rate_3: number;
    currency_3: string;
    currency_change: string;
    change_rate_usd: number;
    change_rate_cny: number;
    change_fc_usd: number;
    change_fc_cny: number;
    desc_void: string;
    transaction_type: string;
    status: string;
    created_by: string;
    updated_by: string;
    promo_auto:string;
    sticker_number:string;
    
    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.cashier_id = model.cashier_id || 0;
        this.user_id = model.user_id || 0;
        this.counter_id = model.counter_id || 0;
        this.warehouse_id = model.warehouse_id || 0;
        this.customer_id = model.customer_id || 0;
        this.gsa_id = model.gsa_id || 0;
        this.agent_id = model.agent_id || 0;
        this.guide_id = model.guide_id || 0;
        this.receipt_no = model.receipt_no || null;
        this.trans_date = model.trans_date || null;
        this.trans_time = model.trans_time || null;
        this.total_item = model.total_item || 0;
        this.total_value = model.total_value || 0;
        this.total_payment = model.total_payment || 0;
        this.discount = model.discount || 0;
        this.change = model.change || 0;
        this.cash = model.cash || 0;
        this.transfer = model.transfer || 0;
        this.credit_card = model.credit_card || 0;
        this.debit_card = model.debit_card || 0;
        this.voucher = model.voucher || 0;
        this.member_point = model.member_point || 0;
        this.travel_voucher = model.travel_voucher || 0;
        this.tax_basis = model.tax_basis || 0;
        this.vat = model.vat || 0;
        this.description = model.description || null;
        this.discount_code = model.discount_code || null;
        this.discount_value = model.discount_value || 0;
        this.commission_status = model.commission_status || null;
        this.commission_extra_status = model.commission_extra_status || null;
        this.foreign_currency_1 = model.foreign_currency_1 || 0;
        this.exchange_rate_1 = model.exchange_rate_1 || 0;
        this.currency_1 = model.currency_1 || null;
        this.foreign_currency_2 = model.foreign_currency_2 || 0;
        this.exchange_rate_2 = model.exchange_rate_2 || 0;
        this.currency_2 = model.currency_2 || null;
        this.foreign_currency_3 = model.foreign_currency_3 || 0;
        this.exchange_rate_3 = model.exchange_rate_3 || 0;
        this.currency_3 = model.currency_3 || null;
        this.currency_change = model.currency_change || null;
        this.change_rate_usd = model.change_rate_usd || 0;
        this.change_rate_cny = model.change_rate_cny || 0;
        this.change_fc_usd = model.change_fc_usd || 0;
        this.change_fc_cny = model.change_fc_cny || 0;
        this.desc_void = model.desc_void || null;
        this.transaction_type = model.transaction_type || '0';
        this.status = model.status || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
        this.promo_auto = model.promo_auto || 'N';
        this.sticker_number = model.sticker_number || '';
    }
}

export class SalesTransactionDetail {

    sales_transaction_id: string[];
    product_id: string[];
    qty: number[];
    free_qty: number[];
    price: number[];
    percent_disc_1: number[];
    disc_1: number[];
    percent_disc_2: number[];
    disc_2: number[];
    percent_disc_3: number[];
    disc_3: number[];
    percent_disc_4: number[];
    disc_4: number[];
    net: number[];
    cogs: number[];
    percent_vat: number[];
    percent_commission: number[];
    description: string[];
    status: number[];
    created_by: string[];
    updated_by: string[];

    constructor(model?) {
        model = model || {};
        this.sales_transaction_id = model.sales_transaction_id || [];
        this.product_id = model.product_id || [];
        this.qty = model.qty || [];
        this.free_qty = model.free_qty || [];
        this.price = model.price || [];
        this.percent_disc_1 = model.percent_disc_1 || [];
        this.disc_1 = model.disc_1 || [];
        this.percent_disc_2 = model.percent_disc_2 || [];
        this.disc_2 = model.disc_2 || [];
        this.percent_disc_3 = model.percent_disc_3 || [];
        this.disc_3 = model.disc_3 || [];
        this.percent_disc_4 = model.percent_disc_4 || [];
        this.disc_4 = model.disc_4 || [];
        this.net = model.net || [];
        this.cogs = model.cogs || [];
        this.percent_vat = model.percent_vat || [];
        this.percent_commission = model.percent_commission || [];
        this.description = model.description || [];
        this.status = model.status || [];
        this.created_by = model.created_by || [];
        this.updated_by = model.updated_by || [];
    }
}

export class SalesTransactionPayments {

    sales_transaction_id: string[];
    payment_method_id: number[];
    edc_id: number[];
    point: number[];
    payment_value: number[];
    voucher_code: string[];
    exchange_rate: number[];


    constructor(model?) {
        model = model || {};
        this.sales_transaction_id = model.sales_transaction_id || [];
        this.payment_method_id = model.payment_method_id || [];
        this.edc_id = model.edc_id || [];
        this.point = model.point || [];
        this.payment_value = model.payment_value || [];
        this.voucher_code = model.voucher_code || [];
        this.exchange_rate = model.exchange_rate || [];
    }
}