import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../../models/user-profile.model';

import { ReportService } from '../../../pages/report/report.service';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-report-cashier',
  templateUrl: './report-cashier.component.html',
  styleUrls: ['./report-cashier.component.scss'],
})
export class ReportCashierComponent implements OnInit {

  @Input() cashierList: any[];
  @Input() branchList: any[];
  @Input() counterList: any[];
  @Input() userList: any[];
  @Input() typeList: any[];
  @Input() customerResellerList: any[];
  @Input() paramBranchId: any;
  @Input() paramCounterId: any;
  @Input() paramCashierId: any;
  @Input() paramUserId: any;
  @Input() paramTransactionTypeId: any;
  @Input() paramRekapPer: any;
  @Input() paramPeriodStart: any;
  @Input() paramPeriodEnd: any;
  @Input() paramReceiptNumber: any;
  @Input() paramTypeId: any;
  @Input() paramResellerId: any;
  
  @ViewChild('branchComponent', { static: false }) branchComponent: IonicSelectableComponent;
  @ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;
  @ViewChild('cashierComponent', { static: false }) cashierComponent: IonicSelectableComponent;
  @ViewChild('userComponent', { static: false }) userComponent: IonicSelectableComponent;
  
  db: any;
  branchId:any;
  counterId: any;
  cashierId: any;
  userId: any;
  typeId: any;
  resellerId: any;
  transactionTypeId: any = '0';
  rekapPer: any = '0';
  periodStart: any;
  periodEnd: any;
  periodYear: any;
  receiptNumber: any;

  showCounterCheckBox: boolean = false;
  checkedAll : boolean = true;
  countChecked:number=0;
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterData: any[] = [];
  branchData: any[] = [];
  counterByLogin : any;

  selectedCounters: any[];
  selectedBranchs:any[];
  selectedCashiers:any[];
  selectedUsers:any[];
  constructor(
    private storage: Storage,
    private reportService: ReportService,
    public modalController: ModalController,
    public toastCtrl : ToastController,
    private utilService: UtilService
  ) {
  }

  ngOnInit() {
    let todayDate = new Date();
    let convertDateStart = this.paramPeriodStart ? this.utilService.convertDate(this.paramPeriodStart) : this.utilService.convertDate(todayDate);
    let convertDateEnd = this.paramPeriodEnd ? this.utilService.convertDate(this.paramPeriodEnd) : this.utilService.convertDate(todayDate);
    let startDate = convertDateStart.years + '-' + convertDateStart.months + '-' + convertDateStart.dates;
    let endDate = convertDateEnd.years + '-' + convertDateEnd.months + '-' + convertDateEnd.dates;
    this.periodStart = startDate;
    this.periodEnd = endDate;

    this.transactionTypeId = this.paramTransactionTypeId ? this.paramTransactionTypeId : '0';
    this.rekapPer = this.paramRekapPer ? this.paramRekapPer : '0';
    this.receiptNumber = this.paramReceiptNumber ? this.paramReceiptNumber : '';
    this.typeId = this.paramTypeId ? (this.paramTypeId) : '';
    this.counterId = this.paramCounterId ? this.paramCounterId : '';
    this.cashierId = this.paramCashierId ? parseInt(this.paramCashierId) : '';
    this.userId = this.paramUserId ? parseInt(this.paramUserId) : '';
    this.resellerId = this.paramResellerId ? parseInt(this.paramResellerId) : '';
    this.branchId = this.paramBranchId ? this.paramBranchId : '';
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": this.counterByLogin
          };
          this.reportService.getReportCashierIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.branchData = response.results.branch_data;          
            
            let branchIdSplit = this.branchId !== '' ? String(this.branchId).split(',') : [];
            this.selectedBranchs=[];
            for(let i= 0; i < this.branchList.length; i++) {        
              let selectedBranch: any = this.branchData.find(x => x.id ==  branchIdSplit[i]);
              if(selectedBranch){
                let idx =this.branchData.indexOf(selectedBranch);
                this.selectedBranchs.push(this.branchData[idx]);            
              }
            }
            this.filterCounterByBranch();
          }, () => {});
        });
      }
    })
  }

  async dismissModal(code: any) {
    let dataReturn: any;
    if(code === 'generate') {
      if(this.transactionTypeId === '0' || this.transactionTypeId === '1') {
        dataReturn = this.transactionTypeId + '#' + this.rekapPer + '#' + this.periodStart +
          '#' + this.periodEnd + '#' + this.receiptNumber + '#' + this.counterId + '#' + this.cashierId +
          '#' + this.userId + '#' + this.typeId+'#'+this.branchId;
      }

      if(this.transactionTypeId === '2') {
        dataReturn = this.transactionTypeId + '#' + this.rekapPer + '#' + this.periodStart +
          '#' + this.periodEnd + '#' + this.receiptNumber + '#' + this.counterId + '#' + this.cashierId +
          '#' + this.userId + '#' + this.typeId + '#' + this.resellerId+'#'+this.branchId;
      }
    }
    if(code === 'close') {
      dataReturn = 'null';
    }    
    await this.modalController.dismiss(dataReturn);
  }
  
  toggleItems() {
    this.branchComponent.toggleItems(
      this.branchComponent.itemsToConfirm.length ? false : true
    );
  }

  confirm() {
    this.branchList = [];
    this.branchComponent.confirm();
    this.branchComponent.close();
    this.branchList = this.branchComponent.value;
    this.counterId ='';
    this.filterCounterByBranch();
  }

  filterCounterByBranch(){
    this.selectedCounters=[];
    this.selectedCashiers=[];
    this.cashierList = [];
    let idBranch = '';
    for(let i=0;i<this.selectedBranchs.length;i++){
      idBranch += this.selectedBranchs[i].id+',';
    }
    this.branchId = idBranch.replace(/(^,)|(,$)/g, "");
       
    let options = { 
      "token": this.token,
      "branch_id": this.branchId,
      "counter_id": this.counterByLogin 
    };
    this.reportService.getCounterByBranchId(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.counterData = [];
      this.counterData = response.results;
      let checkedCounter =this.counterId !=='' ? this.counterId :this.counterByLogin ;
      let counterIdSplit = checkedCounter !== '' ? String(checkedCounter).split(',') : [];
      let idCounter='';
      for(let i= 0; i < checkedCounter.length; i++) {
        let selectedCounter: any = this.counterData.find(x => x.id ==  counterIdSplit[i]);
        if(selectedCounter){
          let idx =this.counterData.indexOf(selectedCounter);
          this.selectedCounters.push(this.counterData[idx]);          
          idCounter += this.counterData[idx]['id']+',';
        }
      }
      idCounter = idCounter.replace(/(^,)|(,$)/g, "");
      this.counterId = idCounter;
      this.filterCashierByCounter();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });    
  }
  
  toggleCounterItems() {
    this.counterComponent.toggleItems(
      this.counterComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmCounter() {
    this.counterComponent.confirm();
    this.counterComponent.close();
    
    this.counterList = [];
    this.counterList = this.counterComponent.value;
    let idCounter ='';
    let idBranch = [];
    for(let i=0;i<this.counterList.length;i++){
      idCounter += this.counterList[i].id+',';
      idBranch.push(this.counterList[i].branch_id);
    }
    idCounter = idCounter.replace(/(^,)|(,$)/g, "");
    this.counterId = idCounter;

    if(this.branchId!=''&& this.counterId==''){//jika pilih branch tanpa pilih counter, maka ambil all counter di cabang tsb
      let idCounter =''; 
      for(let i=0;i<this.counterData.length;i++){
        idCounter += this.counterData[i].id+',';
      }
      idCounter = idCounter.replace(/(^,)|(,$)/g, "");
      this.counterId = idCounter;

    }
    else if(this.branchId==''&& this.counterId!=''){ // jika tidak pilih branch, setelah pilih counter maka pilih branch sesuai counter
      this.selectedBranchs=[];
      
      for(let i=0;i<idBranch.length;i++){             
        let selectedBranch: any = this.branchData.find(x => x.id ==  idBranch[i]);
        if(selectedBranch){
          let idx =this.branchData.indexOf(selectedBranch);
          this.selectedBranchs.push(this.branchData[idx]);            
        }
      } 
      this.branchId = idBranch;
    }
    else if(this.branchId==''&& this.counterId==''){ // jika tidak pilih branch dan cabang, maka pilih semua counter & cabang
      let idCounter =''; 
      let idBranch='';
      for(let i=0;i<this.counterByLogin.length;i++){
        idCounter += this.counterByLogin[i]+',';
      }
      idCounter = idCounter.replace(/(^,)|(,$)/g, "");
      this.counterId = idCounter;

      for(let i=0;i<this.branchData.length;i++){   
        idBranch += this.branchData[i]['id']+','; 
      } 
      idBranch = idBranch.replace(/(^,)|(,$)/g, "");
      this.branchId = idBranch;
    }
    this.filterCashierByCounter();
  }
  toggleCashierItems() {
    this.cashierComponent.toggleItems(
      this.cashierComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmCashier() {
    this.cashierComponent.confirm();
    this.cashierComponent.close();
    
    let cashierData = [];
    cashierData = this.cashierComponent.value;
    let idCashier ='';
    for(let i=0;i<cashierData.length;i++){
      idCashier += cashierData[i].id+',';
    }
    idCashier = idCashier.replace(/(^,)|(,$)/g, "");
    this.cashierId = idCashier;
  }

  
  filterCashierByCounter(){
    this.selectedCashiers=[];
    let options = { 
      "token": this.token,
      "counter_id": this.counterId 
    };
    this.reportService.getCashierByCounterId(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.cashierList = [];
      this.cashierList = response.results;
      let checkedCashier =this.cashierId !=='' ? this.cashierId :'' ;
      let cashierIdSplit = checkedCashier !== '' ? String(checkedCashier).split(',') : [];
      let idCashier='';
      for(let i= 0; i < checkedCashier.length; i++) {
        let selectedCashier: any = this.cashierList.find(x => x.id ==  cashierIdSplit[i]);
        if(selectedCashier){
          let idx =this.cashierList.indexOf(selectedCashier);
          this.selectedCashiers.push(this.cashierList[idx]);            
          idCashier += this.cashierList[idx]['id']+',';                
        }
      }
      idCashier = idCashier.replace(/(^,)|(,$)/g, "");
      this.cashierId = idCashier;
      this.filterUserByCounter();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  
  toggleUserItems() {
    this.userComponent.toggleItems(
      this.userComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmUser() {
    this.userComponent.confirm();
    this.userComponent.close();
    
    let userData = [];
    userData = this.userComponent.value;
    let idUser ='';
    for(let i=0;i<userData.length;i++){
      idUser += userData[i].id+',';
    }
    idUser = idUser.replace(/(^,)|(,$)/g, "");
    this.userId = idUser;
  }

  filterUserByCounter(){
    this.selectedCashiers=[];
    let options = { 
      "token": this.token,
      "counter_id": this.counterId 
    };
    this.reportService.getUserByCounterId(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.userList = [];
      this.userList = response.results;
      let checkedCashier =this.cashierId !=='' ? this.cashierId :'' ;
      let cashierIdSplit = checkedCashier !== '' ? String(checkedCashier).split(',') : [];
      let idUser='';
      for(let i= 0; i < checkedCashier.length; i++) {
        let selectedUser: any = this.userList.find(x => x.id ==  cashierIdSplit[i]);
        if(selectedUser){
          let idx =this.cashierList.indexOf(selectedUser);
          this.selectedUsers.push(this.userList[idx]);            
          idUser += this.userList[idx]['id']+',';                
        }
      }
      idUser = idUser.replace(/(^,)|(,$)/g, "");
      this.userId = idUser;
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }
}
