import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UserService } from '../../pages/user/user.service';
import { UtilService } from '../../service/util.service';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss'],
})
export class SearchUserComponent implements OnInit {

  
  @Input() token: any;
  @Input() counterId: any;
  @Input() listUser: any;
  keyword: any;
  users: any[]=[];
  db: any;
  
  constructor(
    public modalController: ModalController,
    private userService: UserService,
    private utilService: UtilService,
    private toastCtrl: ToastController
  ) { }
  ionViewDidEnter() {
    (<HTMLInputElement>document.getElementById("inputKeyword")).focus();
  }
  ngOnInit() {
    (<HTMLInputElement>document.getElementById("inputKeyword")).focus();
    this.openDB();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  openDB() {
    //this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  async search() {
    this.users = [];    
    if(this.keyword.length >= 0) {
      this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
        let options = {
          "token": this.token,
          "counter_id": this.counterId,
          "keyword": this.keyword
        };

        this.userService.searchUser(options).subscribe(async (response) => {
          if(response.results) {
            this.users = [];
            if(response.results.data.length > 0) {
              for (let index = 0; index < response.results.data.length; index++) {
                this.users.push({
                  id: response.results.data[index].id,
                  name: response.results.data[index].name,
                  email: response.results.data[index].email,
                }); 
              }
            }
            this.utilService.loadingDismiss();
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari User.' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari User.' }).then(t => t.present());
        });
      });
    }
  }

  chooseUser(userId: any,name:any) {
    this.modalController.dismiss({userId: userId,name:name});
  }
}

