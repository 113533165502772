import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../report.service';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../../service/util.service';
import { ToastController } from '@ionic/angular';
import { UserProfile } from '../../../../models/user-profile.model';
import { RoleAccess } from '../../../../models/role-access.model';
import { UserData } from '../../../../providers/user-data';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-sumdate',
  templateUrl: './report-sumdate.page.html',
  styleUrls: ['./report-sumdate.page.scss'],
})
export class ReportSumdatePage implements OnInit {

  userProfile: UserProfile = new UserProfile();
  reportData: any=[];
  idCounter: any;
  idBranch: any;
  counterData: any[] = [];
  branchData: any[] = [];  
  selectedCounters: any[];
  periode: any;
  token: any;
  rekapPer: any='6';
  userAccess: any;
  roleAccess = new RoleAccess();
  fileName= 'ReportPertanggal.xlsx';

  constructor(
    private storage: Storage,
    private reportService: ReportService,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private router: Router,
    private userDataProvider: UserData
    ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userAccess = access;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": counterId
          };
          this.reportService.getReportCashierIndex(options).subscribe((response) => {
            console.log(response);
            this.utilService.loadingDismiss();
            this.counterData = response.results.counter_data;
            this.branchData = response.results.branch_data;
            this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        });
      }
    });
  }
  public selectBranch(event){
    let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
    this.selectedCounters= [];
    this.idBranch = '';
    /*for(let i=0;i<event.value.length;i++){
      this.idBranch += event.value[i].id+',';
    }*/
    this.idBranch += event.value.id;
    this.idBranch = this.idBranch.replace(/(^,)|(,$)/g, "");
    let options = { 
      "token": this.token,
      "branch_id": this.idBranch,
      "counter_id": counterId
    };
    this.reportService.getCounterByBranchId(options).subscribe((response) => {
      this.utilService.loadingDismiss();
     this.counterData = response.results;   
     console.log(this.counterData);   
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }
  
  
  public selectCounter(event){
    this.idCounter = event.value.id;
  }
  
  getReport() {
    if(!this.periode){
      this.toastCtrl.create({ duration: 2000, message: 'Please Select Periode' }).then(t => t.present());
    }
    else if(!this.idCounter){
      this.toastCtrl.create({ duration: 2000, message: 'Please Select Counter' }).then(t => t.present());
    }
    else{
      this.generateReport();
    }
  }

  generateReport() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let dateConvert = this.utilService.convertDate(this.periode);
      let newTransDate = dateConvert.years + '-' + dateConvert.months;
      let options = {
        "token": this.token,
        "periode": newTransDate,
        "counter_id": this.idCounter,
      };
      this.reportService.getReportCashier(this.rekapPer, options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.reportData = response.results;
          this.countTotalReportTransaction(response.results);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });

    }, (err) => {
      this.utilService.loadingDismiss();
      console.log(err);
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
  }

  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  countTotalReportTransaction(report: any) {
    let counterId: any = '';

    let sumNetto: number = 0, sumDiscount: number = 0, sumDpp: number = 0, sumTax: number = 0,
    sumGrandTotal: number = 0;

    let counterIdCount: number = 0;
    let arrSumData: any[] = [];

    for(let i = 0; i < report.length; i++) {
      let tempCounterId = report[i].counter_id;
      if(i === 0) { counterId = report[i].counter_id; }

      if(counterId === tempCounterId) {
        sumNetto = sumNetto + parseInt(report[i].total);
        sumDiscount = sumDiscount + parseInt(report[i].discount);
        sumDpp = sumDpp + parseInt(report[i].dpp);
        sumTax = sumTax + parseInt(report[i].tax_basis);
        sumGrandTotal = sumGrandTotal + parseInt(report[i].grand_total);

        if((i+1) === report.length) {
          counterIdCount = counterIdCount + 1;
          let indexInsert = i + counterIdCount;
          let data = {
            id: '0',
            counter_id: counterId,
            counter_name: report[i-1].counter_name,
            sum_netto: sumNetto,
            sum_discount: sumDiscount,
            sum_dpp: sumDpp,
            sum_tax: sumTax,
            sum_grand_total: sumGrandTotal,
            index_insert: indexInsert
          };
          arrSumData.push(data);
        }
      } else {
        let indexInsert = i + counterIdCount;
        counterIdCount = counterIdCount + 1;
        let data = {
          id: '0',
          sum_netto: sumNetto,
          sum_discount: sumDiscount,
          sum_dpp: sumDpp,
          sum_tax: sumTax,
          sum_grand_total: sumGrandTotal,
          index_insert: indexInsert
        };        
        arrSumData.push(data);
      }
    }

    for(let x = 0; x < arrSumData.length; x++) {
      let indexInsert: number = arrSumData[x].index_insert;
      this.reportData.splice(indexInsert, 0, arrSumData[x]);
    }
    console.log(this.reportData);
  }
}