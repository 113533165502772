import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, ToastController } from '@ionic/angular';
import { UserProfile } from '../../models/user-profile.model';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  dataStock: any[] = [];

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private homeService: HomeService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        window.dispatchEvent(new CustomEvent('user:getmenu'));
        this.getDataStock();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getDataStock() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let newDate = new Date();
      let convertDate = this.utilService.convertDate(newDate);
      let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let dateOfMonth = this.utilService.getFirstAndLastDateofMonth();

      let options = {
        "token": this.token,
        "year": convertDate.years,
        "month": convertDate.months,
        "today": todayDate,
        "start_date": dateOfMonth.startDate,
        "end_date": dateOfMonth.endDate,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
     };

     this.homeService.getDashboardStockData(options).subscribe((response) => {
       this.utilService.loadingDismiss();
       let stockData: any[] = response.results.stock_data;
        if(stockData.length > 0) {
          let todayTransactionData: any[] = response.results.today_transaction_data;
          let monthTransactionData: any[] = response.results.month_transaction_data;

          for(let x = 0; x < stockData.length; x++) {
            let stockQty = this.getStock(convertDate.months, stockData[x]);
            let pcode: any = stockData[x].pcode;
            let checkTodayTrans: any = todayTransactionData.find(x => x.product_id === pcode);
            let checkMonthTrans: any = monthTransactionData.find(x => x.product_id === pcode);

            let data = {
              pcode: stockData[x].pcode,
              name: stockData[x].product_name,
              early: stockQty.early,
              in: stockQty.in,
              todayTransQty: checkTodayTrans ? parseInt(checkTodayTrans.qty) : 0,
              monthTransQty: checkMonthTrans ? parseInt(checkMonthTrans.qty) : 0,
              end: stockQty.end
            }

            this.dataStock.push(data);

          }
        }
     }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  getStock(month: any, data: any) {
    let earlyStock: any = 0;
    let inStock: any = 0;
    let endStock: any = 0;
    if(month === '01') { earlyStock = data.early_01; inStock = data.in_01; endStock = data.end_01; }
    if(month === '02') { earlyStock = data.early_02; inStock = data.in_02; endStock = data.end_02; }
    if(month === '03') { earlyStock = data.early_03; inStock = data.in_03; endStock = data.end_03; }
    if(month === '04') { earlyStock = data.early_04; inStock = data.in_04; endStock = data.end_04; }
    if(month === '05') { earlyStock = data.early_05; inStock = data.in_05; endStock = data.end_05; }
    if(month === '06') { earlyStock = data.early_06; inStock = data.in_06; endStock = data.end_06; }
    if(month === '07') { earlyStock = data.early_07; inStock = data.in_07; endStock = data.end_07; }
    if(month === '08') { earlyStock = data.early_08; inStock = data.in_08; endStock = data.end_08; }
    if(month === '09') { earlyStock = data.early_09; inStock = data.in_09; endStock = data.end_09; }
    if(month === '10') { earlyStock = data.early_10; inStock = data.in_10; endStock = data.end_10; }
    if(month === '11') { earlyStock = data.early_11; inStock = data.in_11; endStock = data.end_11; }
    if(month === '12') { earlyStock = data.early_12; inStock = data.in_12; endStock = data.end_12; }

    return {
      early   : earlyStock,
      in      : inStock,
      end     : endStock
    };
  }

}
