export class MstVoucher {

    voucher_code: string[];
    nominal: number;
    currency: string;
    expired_date: string;
    batch_no: number;
    status: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.voucher_code = model.voucher_code || null;
        this.nominal = model.nominal || 0;
        this.currency = model.currency || null;
        this.expired_date = model.expired_date || null;
        this.batch_no = model.batch_no || 0;
        this.status = model.status || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}

export class MstVoucherDetail {

    id: number[];
    status: string[];
    updated_by: string[];

    constructor(model?) {
        model = model || {};
        this.id = model.id || [];
        this.status = model.status || [];
        this.updated_by = model.updated_by || [];
    }
}