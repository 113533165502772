import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { PriceGroupService } from '../price-group.service';
import { MstPriceGroupBundle } from '../../../models/mst-price-group-bundle.model';
import { PriceGroupComponent } from '../../../component/price-group/price-group.component';
import { SearchProductPriceGroupComponent } from '../../../component/search-product-price-group/search-product-price-group.component';
import { AddProductPriceGroupComponent } from '../../../component/add-product-price-group/add-product-price-group.component';
import { RoleAccess } from '../../../models/role-access.model';
import { PriceGroupApprovalStatus } from '../../../enum/PriceGroupApprovalStatus';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { MyService } from '../../../service/my.service';
import * as utf8 from 'crypto-js/enc-utf8';
import * as AES from 'crypto-js/aes';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-price-group-edit',
  templateUrl: './price-group-edit.page.html',
  styleUrls: ['./price-group-edit.page.scss'],
})
export class PriceGroupEditPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  priceGroupId: any;
  priceGroupDetailData: any[];
  rows: any[];

  formPriceGroupEdit: FormGroup;

  priceGroupData: any;

  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;
  waNumberRequester :any;
  
  statusPriceGroup: any[] = ['Not Active', 'Active'];
  priceGroupTypeList: any[] = [{id: 'RG', type_name: 'REGULER'}, {id: 'RS', type_name: 'RESELLER'}];

  roleAccess = new RoleAccess();
  statusId: any =0;
  approvalId :any=0;
  approvalName :any;
  userId :any;
  username:any;

  approvalStatusName = PriceGroupApprovalStatus;
  approvalStatusList = Object.keys(PriceGroupApprovalStatus).filter(
    approvalStatusName => typeof this.approvalStatusName[approvalStatusName] === 'number'
  );
  showProductList :boolean=true;
  approvalHistoryData: any[] = [];
  counterName :any;
  formType:string='v';
  statusName:string='';
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private modalController: ModalController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private priceGroupService: PriceGroupService,
    private router: Router,
    private myservice:MyService
  ) { }

  ngOnInit() {
    this.buildFormPriceGroupEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userId = this.userProfile.id;
        this.username= this.userProfile.username;
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.statusName='';
          this.getPriceGroupforEdit();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  buildFormPriceGroupEdit() {
    this.formPriceGroupEdit = this.fb.group({
      counterName: [null],
      priceGroupType: [null],
      docDate: [null],
      description: [null],
      status: [null],
      keywordSearch: [null],
      remark :[null]
    });
  }

  getPriceGroupforEdit() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.priceGroupId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.formType = snapshot.t;
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.priceGroupService.getPriceGroupforEdit(this.priceGroupId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.priceGroupData = response.results.price_group_data;
          this.rows = response.results.price_group_detail_data;
          this.priceGroupDetailData = response.results.price_group_detail_data;
          this.approvalHistoryData = response.results.approval_history_data;
          let userCreate: any = response.results.user_create_data;
          this.createdBy = userCreate ? userCreate[0].name : null;
          let userUpdate: any = response.results.user_update_data;
          this.updatedBy = userUpdate ? userUpdate[0].name : null;
          if(this.priceGroupData.length > 0) {
            this.statusId = this.priceGroupData[0].status_id;
            this.createdAt = this.priceGroupData[0].created_at;
            this.waNumberRequester = userCreate[0].whatsapp_number;
            this.updatedAt = this.priceGroupData[0].updated_at;
            this.counterName = this.priceGroupData[0].counter_name;
            this.formPriceGroupEdit = this.fb.group({
              counterName: [this.priceGroupData[0].counter_name],
              priceGroupType: [this.priceGroupData[0].price_group_types, Validators.required],
              description: [this.priceGroupData[0].description, Validators.required],
              docDate: [this.priceGroupData[0].valid_periode, Validators.required],
              status: [parseInt(this.priceGroupData[0].active)],
              keywordSearch: [null],
              remark:[null]
            });
            this.getApprovalDetail();
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  searchProduct() {
    const formPriceGroup = this.formPriceGroupEdit.value;
    this.rows = this.priceGroupDetailData.filter(item => {
      return item.product_name.toLowerCase().indexOf(formPriceGroup.keywordSearch.toLowerCase()) !== -1 ||
        item.product_id.toString().toLowerCase().indexOf(formPriceGroup.keywordSearch.toLowerCase()) !== -1;
    });
  }

  async changePriceProduct(productData: any) {
    const modal = await this.modalController.create({
      component: PriceGroupComponent,
      componentProps: {
        'productId': productData.product_id,
        'productName': productData.product_name,
        'productPrice': productData.price,
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      let checkProduct: any[] = this.priceGroupDetailData.find(x => x.product_id === data[0].product_id);
      if (checkProduct !== undefined) {
        let index: number = this.priceGroupDetailData.indexOf(checkProduct);
        this.priceGroupDetailData[index]['price'] = data[0].price;
        this.rows[this.rows.indexOf(this.rows.find(x => x.product_id === data[0].product_id))]['price'] = data[0].price;
      }
    });

    return await modal.present();
  }

  update() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formPriceGroup = this.formPriceGroupEdit.value;
      let arrProduct: any = [];
      let arrPrice: any = [];

      let docDateConvert = this.utilService.convertDate(formPriceGroup.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;
      for(let x = 0; x < this.priceGroupDetailData.length; x++) {
        arrProduct[x] = this.priceGroupDetailData[x].product_id;
        arrPrice[x] = this.myservice.removeSeparator(this.priceGroupDetailData[x].price);
      }

      const mstPriceGroupBundle = new MstPriceGroupBundle();
      mstPriceGroupBundle.mstPriceGroup.price_group_types = formPriceGroup.priceGroupType;
      mstPriceGroupBundle.mstPriceGroup.description = formPriceGroup.description;
      mstPriceGroupBundle.mstPriceGroup.valid_periode = documentDate;
      mstPriceGroupBundle.mstPriceGroup.active = formPriceGroup.status;
      mstPriceGroupBundle.mstPriceGroup.updated_by = this.userProfile.username;

      mstPriceGroupBundle.mstPriceGroupDetail.product_id = arrProduct;
      mstPriceGroupBundle.mstPriceGroupDetail.price = arrPrice;

      this.priceGroupService.updatePriceGroup(this.priceGroupId, mstPriceGroupBundle).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/price-group']);;
          }
        }
      ]
    });

    await alert.present();
  }

  async openSearchProduct() {
    const modal = await this.modalController.create({
      component: SearchProductPriceGroupComponent,
      componentProps: {token: this.token,priceGroupId: this.priceGroupId},
      backdropDismiss: false
    });

    modal.onDidDismiss().then(async (modelData) => {
      console.log('modelData', modelData);
      this.openAddProduct(modelData.data.product)
    });

    return await modal.present();
  }

  async openAddProduct(modelData: any) {
    const modal = await this.modalController.create({
      component: AddProductPriceGroupComponent,
      componentProps: {
        'token': this.token,
        'priceGroupId': this.priceGroupId,
        'productId': modelData.id,
        'productName': modelData.product_name,
        'productPrice': modelData.price,
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
        this.rows = [];
        this.priceGroupService.getPriceGroupforEdit(this.priceGroupId, { "token": this.token }).subscribe((response) => {
          this.rows = response.results.price_group_detail_data;
          this.utilService.loadingDismiss();
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
    });

    return await modal.present();
  }
  

  getApprovalDetail() {

      let options = {
        "token": this.token,
        "id": this.priceGroupId
      };

        this.priceGroupService.getApproval(options).subscribe((response) => {
          if(response.results.data.length > 0) {
            this.approvalId = response.results.data[0].username;
            this.approvalName = response.results.data[0].name;
            this.statusName='';
            if( this.statusId==2){
              this.statusName =' approval ' +response.results.data[0].name;
            }
          }
        }, () => {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
  }
  
  async showConfirmSend(action:any) {
    let actionName = 'Approve';
    if(action==2){
      actionName = 'Reject';
    }
    const alert = await this.alertController.create({
      header: 'Approval Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk '+actionName+' data ini?',
      buttons: [
        {
          text: 'Tidak',
          handler: () => {}
        },
        {
          text: 'Ya',
          handler: () => {
            this.sendApproval(action);
          }
        }
      ]
    });

    await alert.present();
  }
  
  sendApproval(action:any) {
    
    const formPriceGroup = this.formPriceGroupEdit.value;

    let remark = formPriceGroup.remark;
    let options = {
      "token": this.token,
      "id": this.priceGroupId,
      "username": this.userProfile.username,
      "action" :action,
      "remark":remark
    };
  
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.priceGroupService.sendApproval(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          if(response.results.data_approval.length>0){
            console.log('DATA APPROVAL');
            console.log(response);
          }
          this.toastCtrl.create({ duration: 2000, message: response.message }).then(t => t.present());
          this.navCtrl.navigateForward(['/price-group-approval']);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  
  toggleProductList() {
    if(this.showProductList === false) {
      this.showProductList = true;
    } else {
      this.showProductList = false;
    }
  }

  exportToExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Group_Harga.xlsx');
  }
}
