import { ApprovalHistory } from './approval-history.model';

export class StockOpname {

    id: string;
    counter_id: number;
    warehouse_id: number;
    doc_date: string;
    desc: string;
    status: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.counter_id = model.counter_id || 0;
        this.warehouse_id = model.warehouse_id || 0;
        this.doc_date = model.doc_date || null;
        this.desc = model.desc || null;
        this.status = model.status || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}

export class StockOpnameDetail {

    stock_opname_id: string[];
    product_id: string[];
    qty_real: number[];
    qty_program: number[];
    price: number[];

    constructor(model?) {
        model = model || {};
        this.stock_opname_id = model.stock_opname_id || [];
        this.product_id = model.product_id || [];
        this.qty_real = model.qty_real || [];
        this.qty_program = model.qty_program || [];
        this.price = model.price || [];
    }
}

export class StockOpnameBundle {

    stockOpname: StockOpname;
    stockOpnameDetail: StockOpnameDetail;
    approvalHistory: ApprovalHistory;

    constructor(model?) {
        model = model || {};
        this.stockOpname = model.stockOpname || new StockOpname();
        this.stockOpnameDetail = model.stockOpnameDetail || new StockOpnameDetail();
        this.approvalHistory = model.approvalHistory || new ApprovalHistory();
    }
}

export class StockOpnameUpload {
    
    file: any;

    constructor(model?) {
        model = model || {};
        this.file = model.file || null;
    }
}

export class StockOpnameBundleUpload {

    stockOpname: StockOpname;
    stockOpnameUpload: StockOpnameUpload;

    constructor(model?) {
        model = model || {};
        this.stockOpname = model.stockOpname || new StockOpname();
        this.stockOpnameUpload = model.stockOpnameUpload || new StockOpnameUpload();
    }
}