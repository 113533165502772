import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplayCustomerService {

  constructor(private httpService: HttpService) { }

  getSlider(params: any): Observable<any> {
    return this.httpService.get('mst-slider', params);
  }
  createSlider(params: any): Observable<any> {
    return this.httpService.post('mst-slider', params);
  }
  updateSlider(params: any): Observable<any> {
    return this.httpService.post(`update-slider`, params);
  }
  getDataforEdit( params: any): Observable<any> {
    return this.httpService.get(`mst-slider/edit`, params);
  }
}
