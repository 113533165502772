export class ModalKasir {

    id: number;
    counter_id: number;
    user_id: number;
    transaction_date: string;
    amount: string;
    description: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || 0;
        this.user_id = model.user_id || 0;
        this.transaction_date = model.transaction_date || null;
        this.amount = model.amount || null;
        this.description = model.description || null;
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}