import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators,FormArray } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { SetupResellerRewardService } from '../setup-reseller-reward.service';
import { Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { PromotionDetailComponent } from '../../../component/promotion-detail/promotion-detail.component';

class Product {
  public id: string;
  public product_name: string;
  public barcode: string;
}
@Component({
  selector: 'app-setup-reseller-reward-create',
  templateUrl: './setup-reseller-reward-create.page.html',
  styleUrls: ['./setup-reseller-reward-create.page.scss'],
})
export class SetupResellerRewardCreatePage implements OnInit {


  token: any;
  userProfile: UserProfile = new UserProfile();
  db: any;

  roleAccess = new RoleAccess();
  
  searchValue: any[] = [];
  productList: Product[];
  brandList:any[];
  selectedProduct: boolean=false;
  productScan: any[] = [];
  productScanDisplay: any[] = [];
  mstBarcodeList: Array<any>;
  mstProductList: Array<any>;
  mstRewardList: any[]=[];

  formDetailCreate: FormGroup;
  selectedBrandList :any='';
  selectedProductList:any='';
  
  productListDisplay: string = '';
  
  productReward: any[] = [];
  producRewardDisplay: any[] = [];
  customPeriode:boolean=false;


  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private modalController : ModalController,
    private navCtrl: NavController,
    private setupResellerRewardService: SetupResellerRewardService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormIncentiveCreate();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.create!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          let options = {
            "token": this.token,
            "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
          };
          this.setupResellerRewardService.getDataforCreate(options).subscribe((response) => {
            this.utilService.loadingDismiss();            
            this.productList = response.results.product_data;
            this.brandList = response.results.brand_data;
            this.mstBarcodeList = [];
            this.mstProductList = [];
            this.mstRewardList = response.results.reward_data;;
            for (let i = 0; i < this.productList.length; i++) {
              this.mstBarcodeList[this.productList[i].barcode] = {
                id: String(this.productList[i].id),
                barcode: this.productList[i].barcode,
                product_name: this.productList[i].product_name,
                stock: 0,
                terpakai: 0
              }
              
              let id = String(this.productList[i].id);
              let index: number = parseInt(id);
              if(Number.isNaN(index)) {
              } else {
                this.mstProductList[index] = {
                  id: String(this.productList[i].id),
                  barcode: this.productList[i].barcode,
                  product_name: this.productList[i].product_name,
                  stock: 0,
                  terpakai: 0
                }
              }
            }

            const detail = this.fb.group({
              minTotalTrans: [null],
              reward: [null],
              rewardQty:[0],
              multipleFlag:[0]
            });
            this.getDetailArray.push(detail);
          });
        });
      }
    });
  }

  buildFormIncentiveCreate() {
    this.formDetailCreate = this.fb.group({
      brand:[],
      periode:[0,Validators.required],
      incentiveName: [null, Validators.required],
      earlyPeriod: [null],
      endPeriod: [null],
      product:[],
      detail: this.fb.array([])
    });
  }

  inputIncentives() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const incentiveData = this.formDetailCreate.getRawValue();
      let newEarlyPeriod ='';
      let newEndPeriod ='';
      
      if(this.customPeriode){
      let earlyPeriods = this.utilService.convertDate(incentiveData.earlyPeriod);
      let endPeriods = this.utilService.convertDate(incentiveData.endPeriod);
      newEarlyPeriod = earlyPeriods.years + '-' + earlyPeriods.months + '-' + earlyPeriods.dates;
      newEndPeriod = endPeriods.years + '-' + endPeriods.months + '-' + endPeriods.dates;
      }
      const formDetail = this.formDetailCreate.value;
      
      let arrDetail: any = [];
      let arrMinimum: any = [];
      let arrReward: any = [];
      let arrRewardQty: any=[];
      let arrMultiple: any=[];
      for(let x = 0; x < formDetail.detail.length; x++) {
        arrDetail[x] =  x;
        arrMinimum[x] =  formDetail.detail[x].minTotalTrans;
        arrReward[x] =   formDetail.detail[x].reward;
        arrRewardQty[x] = formDetail.detail[x].rewardQty;
        arrMultiple = formDetail.detail[x].multipleFlag
      }
      let mstIncentive = {
        arrDetail:arrDetail,
        arrMinimum:arrMinimum,
        arrReward:arrReward,
        arrRewardQty:arrRewardQty,
        arrMultiple:arrMultiple,
        periode:incentiveData.periode,
        incentive_name : incentiveData.incentiveName,
        early_period : newEarlyPeriod,
        end_period : newEndPeriod,
        brand_list : this.selectedBrandList,
        product_list : this.productListDisplay,
        created_by : this.userProfile.username,
     };

      this.setupResellerRewardService.addSetupIncentive(mstIncentive).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/setup-reseller-reward']);;
          }
        }
      ]
    });

    await alert.present();
  } 

  async openListProduct(index: any, action: any) {
    const modal = await this.modalController.create({
      component: PromotionDetailComponent,
      componentProps: {
        'mstProductList': this.mstProductList,
        'mstBarcodeList': this.mstBarcodeList,
        'productScan': this.productScan[index] ? this.productScan[index] : null,
        'action': action
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData.data !== '') {
        let dataProduct: any[] = modelData.data;
        let productList: string = '';
        this.productListDisplay= '';

        if(dataProduct.length > 0) {
          for(let i = 0; i < dataProduct.length; i++) {
            productList = productList + dataProduct[i].id;
            this.productListDisplay =this.productListDisplay + dataProduct[i].id;
            if((i+1) !== dataProduct.length) {
              productList = productList + ';';
              this.productListDisplay = this.productListDisplay + ', ';
            }
          }
          this.productScan[index] = productList;
          this.productScanDisplay[index] = this.productListDisplay;
        } else {
          this.productScan[index] = null;
          this.productScanDisplay[index] = null;
        }
      } 
      else {
        this.productScan[index] = null;
        this.productScanDisplay[index] = null;
      }
    });

    return await modal.present();
  }

  get getDetailArray() {
    return (<FormArray>this.formDetailCreate.get('detail'));
  }

  addDetail() {
    const detail = this.fb.group({
      minTotalTrans: [0],
      reward: [],
      rewardQty:[0],
      multipleFlag:[0]
    });
    this.getDetailArray.push(detail);
  }  

  productChange(event: {
    component: IonicSelectableComponent,
    value: any
  }) {
    let product: any = event.value;
    this.selectedBrandList=product;
  }

  periodeChange(event:any){
    let periode=(event.target.value);
    if(periode==1){
      this.customPeriode = true;
    }
    else {
      this.customPeriode=false;
    }
  }
}