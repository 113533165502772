import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportReprintStruckPage } from './report-reprint-struck.page';

const routes: Routes = [
  {
    path: '',
    component: ReportReprintStruckPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportReprintStruckPageRoutingModule {}
