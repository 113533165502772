import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { GudangService } from '../gudang.service';
import { MstWarehouse } from '../../../models/mst-warehouse.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-gudang-create',
  templateUrl: './gudang-create.page.html',
  styleUrls: ['./gudang-create.page.scss'],
})
export class GudangCreatePage implements OnInit {

  token: any;
  formWarehouseCreate: FormGroup;
  counterData: any[] = [];
  warehouseTypeData: any[] = [];
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private gudangService: GudangService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormWarehouseCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile,access]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.create!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          else{
            this.gudangService.getWarehouseforCreate({ "token": this.token }).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.counterData = response.results.counter_data;
              this.warehouseTypeData = response.results.warehouse_type_data;
            });
          }
        });
      }
      else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormWarehouseCreate() {
    this.formWarehouseCreate = this.fb.group({
      counterId: [0, Validators.required],
      warehouseName: [null, Validators.required],
      warehouseTypeId: [null, Validators.required],
      unitSize: [null, Validators.required],
      lengthSize: [0, Validators.required],
      widthSize: [0, Validators.required],
      heightSize: [0, Validators.required]
    })
  }

  inputWarehouse() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const warehouseForm = this.formWarehouseCreate.getRawValue();
      const mstWarehouse = new MstWarehouse();
      mstWarehouse.counter_id = warehouseForm.counterId;
      mstWarehouse.warehouse_name = warehouseForm.warehouseName;
      mstWarehouse.warehouse_type_id = warehouseForm.warehouseTypeId;
      mstWarehouse.unit_size = warehouseForm.unitSize;
      mstWarehouse.length_size = warehouseForm.lengthSize;
      mstWarehouse.width_size = warehouseForm.widthSize;
      mstWarehouse.height_size = warehouseForm.heightSize;
      mstWarehouse.created_by = this.userProfile.username;

      this.gudangService.addWarehouse(mstWarehouse).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/gudang']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
