import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../../service/util.service';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportService } from '../../report.service';
import { OtherReceivingStatus } from '../../../../enum/OtherReceivingStatus';
import { ReportOtherReceivingComponent } from '../../../../component/advanced-filter/report-other-receiving/report-other-receiving.component';
import { ReportOtherReceivingDetailComponent } from '../../../../component/report-other-receiving-detail/report-other-receiving-detail.component';
import { ReportCashierCounterListComponent } from '../../../../component/report-cashier-counter-list/report-cashier-counter-list.component';
import { WarehouseListComponent } from '../../../../component/warehouse-list/warehouse-list.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-other-receiving',
  templateUrl: './report-other-receiving.page.html',
  styleUrls: ['./report-other-receiving.page.scss'],
})
export class ReportOtherReceivingPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  paramOtherReceivingId: any = null;
  
  paramBranchId: any;
  paramCounterId: any;
  paramWarehouseId: any;
  paramDocDateStart: any = null;
  paramDocDateEnd: any = null;
  paramPurposeId: any = null;

  reportData: any[] = [];
  reportDataDetail: any[] = [];
  
  counterData: any[] = [];
  warehouseData: any[] = [];
  purposeData: any[] = [];
  counterName: any = null;
  warehouseName: any = null;
  purposeName: any = null;
  otherReceivingIdList:any[] =[];
  fileName= 'Report_Penerimaan_Lain_Lain.xlsx';
  
  showBtnCounterList: boolean = false;
  showBtnWarehouseList: boolean = false;
  counterListForShow: any[] = [];
  warehouseListForShow: any[] = [];

  otherReceivingStatus = OtherReceivingStatus;
  otherReceivingStatusList = Object.keys(OtherReceivingStatus).filter(
    orStatus => typeof this.otherReceivingStatus[orStatus] === 'number'
  );
  constructor(
    private storage: Storage,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private reportService: ReportService) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.clearFilter();
        });
      }
    });
  }

  async showCounterList() {
    const modal = await this.modalController.create({
      component: ReportCashierCounterListComponent,
      componentProps: {
        counterList: this.counterListForShow
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }

  async showWarehouseList(){
    const modal = await this.modalController.create({
      component: WarehouseListComponent,
      componentProps: {
        warehouseList: this.warehouseListForShow
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }
  
  
  clearFilter(){
    this.reportData = [];
    this.reportDataDetail = [];
    this.paramOtherReceivingId ='';
    this.paramBranchId ='';
    this.paramCounterId ='';
    this.paramWarehouseId ='';
    
    let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
    let options = { 
      "token": this.token,
      "counter_id": counterId
    };
    this.utilService.loadingDismiss();
    this.reportService.getReportOtherReceivingIndex(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.counterData = response.results.counter_data;
      this.warehouseData = response.results.warehouse_data;
      this.purposeData = response.results.purpose_data;
                 
      this.counterListForShow =  this.counterData;

      if(this.counterListForShow.length > 0) {
        let loopCounter = 0;
        if(this.counterListForShow.length > 5) {
          loopCounter = 5;
          //this.showBtnCounterList = true;
        } else {
          loopCounter = this.counterListForShow.length;
          this.showBtnCounterList = false;
        }
      
        this.counterName = '';
        this.purposeName = '';
        this.paramPurposeId = '';
       /* for(let i = 0; i < loopCounter; i++) {
          if(this.counterName === '') {
            this.counterName = this.counterListForShow[i].counter_name;
          } else {
            this.counterName = this.counterName + ', ' + this.counterListForShow[i].counter_name;
          }
        }*/
      }  
      
      this.warehouseListForShow =  this.warehouseData;

      if(this.warehouseListForShow.length > 0) {
        let loopWarehouse = 0;
        if(this.warehouseListForShow.length > 5) {
          loopWarehouse = 5;
          //this.showBtnWarehouseList = true;
        } else {
          loopWarehouse = this.warehouseListForShow.length;
          this.showBtnWarehouseList = false;
        }
      
        this.warehouseName = '';
        /*for(let i = 0; i < loopWarehouse; i++) {
          if(this.warehouseName === '') {
            this.warehouseName = this.warehouseListForShow[i].warehouse_name;
          } else {
            this.warehouseName = this.warehouseName + ', ' + this.warehouseListForShow[i].warehouse_name;
          }
        }*/
      }
      let todayDate = new Date();
      let convertDate = this.utilService.convertDate(todayDate);
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      this.paramDocDateStart = transDate;
      this.paramDocDateEnd = transDate;
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });
    
    
  }
  async openAdvancedFilters(){
    const modal = await this.modalController.create({
      component: ReportOtherReceivingComponent,
      componentProps: {
        counterList: this.counterData,
        warehouseList: this.warehouseData,
        paramOtherReceivingId: this.paramOtherReceivingId,
        paramBranchId: this.paramBranchId,
        paramCounterId: this.paramCounterId,
        paramWarehouseId: this.paramWarehouseId,
        paramDocDateStart: this.paramDocDateStart,
        paramDocDateEnd: this.paramDocDateEnd,
        purposeList: this.purposeData,
        paramPurposeId: this.paramPurposeId
      },
      backdropDismiss: false
    });
    
    modal.onDidDismiss().then((modelData) => {      
      let data = modelData.data;
      if (data.length > 0) {
        this.paramOtherReceivingId = data[0].otherReceivingId;
        this.paramCounterId = data[0].counterId;
        this.paramBranchId = data[0].branchId;
        this.counterName = data[0].counterName;
        this.paramWarehouseId = data[0].warehouseId;
        this.warehouseName = data[0].warehouseName;
        this.paramDocDateStart = data[0].docDateStart;
        this.paramDocDateEnd = data[0].docDateEnd;
        this.paramPurposeId = data[0].purposeId;
        this.purposeName = data[0].purposeName;
        this.showBtnCounterList = false;
        this.showBtnWarehouseList = false;
        this.generateReport();
      }
    })

    return await modal.present();
  }
  
  generateReport() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      if (this.paramCounterId==null){
        this.paramCounterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
      }
      let options = {
        "token": this.token,
        "other_receiving_id": this.paramOtherReceivingId,
        "counter_id": this.paramCounterId,
        "warehouse_id": this.paramWarehouseId,
        "doc_date_start": this.paramDocDateStart,
        "doc_date_end": this.paramDocDateEnd,
        "purpose_id":this.paramPurposeId,
      };
      this.otherReceivingIdList = [];
      this.reportService.getReportOtherReceiving(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.reportData = response.report_data;
          if(this.reportData.length > 0) {
            if(this.paramOtherReceivingId !== null) {
              this.paramOtherReceivingId = this.reportData[0].id;
              this.paramCounterId = this.reportData[0].counter_id;
              this.counterName = this.reportData[0].counter_name;
              this.paramWarehouseId = this.reportData[0].warehouse_id;
              this.warehouseName = this.reportData[0].warehouse_name;
              this.paramDocDateStart = this.reportData[0].doc_date;
              this.paramDocDateEnd = this.reportData[0].doc_date;
             
            }
            for(let i:any=0;i< this.reportData.length;i++){
              let data = {                
                id:this.reportData[i].id
              }
              this.otherReceivingIdList.push(data
              );
            }
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });

    
  }

  otherReceivingDetail(otherReceivingId: any, action: any){
    let sendId = '';
    if (Array.isArray(otherReceivingId)){
      for(let i = 0; i < otherReceivingId.length; i++) {
        if(i==0){
          sendId = "'"+otherReceivingId[i].id+"'";
        }
        sendId = sendId + ', ' + "'"+otherReceivingId[i].id+"'";
        
      }
      otherReceivingId = sendId;
    }
    else{
      otherReceivingId = "'"+otherReceivingId+"'";
    }
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "other_receiving_id": otherReceivingId

      };
      this.reportService.getReportOtherReceivingDetail(options).subscribe((response) => {
        if(response.status.code === 200) {
          this.reportDataDetail = response.report_data;
          if(action === 'detail') {
            this.utilService.loadingDismiss();
            this.openReportProductDetail();
          }

          if(action === 'export' || action === 'export-detail') {
            console.log(this.reportDataDetail);
            this.waitForDataToLoad(action);
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    })

  }
  
  async waitForDataToLoad(action:any) {
    await new Promise(resolve => setTimeout(resolve, 1500));
    if(action==='export'){
      this.exportToExcel();
    }
    else{
      this.exportToExcelDetail();
    }
  }
  exportToExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    delete (ws['I1']);
    for(let i=0;i<this.reportData.length;i++){
      delete (ws['I'+(i+2)]);
    }
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.utilService.loadingDismiss();
  }
  
  exportToExcelDetail() {
    let element = document.getElementById('excel-table-detail');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.utilService.loadingDismiss();
  }

  async openReportProductDetail() {
    const modal = await this.modalController.create({
      component: ReportOtherReceivingDetailComponent,
      componentProps: {
        'reportDataDetail': this.reportDataDetail
      },
      backdropDismiss: false
    });
    return await modal.present();
  }

}
