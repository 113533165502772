import { Component, OnInit } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { MasterProductPacketService } from '../master-product-packet.service';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { MyService } from '../../../service/my.service';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-master-product-packet-edit',
  templateUrl: './master-product-packet-edit.page.html',
  styleUrls: ['./master-product-packet-edit.page.scss'],
})
export class MasterProductPacketEditPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  productPackageId: any;
  productList: Product[];
  brandList: any[] = [];
  subBrandList: any[] = [];
  typeList: any[] = [];
  subTypeList: any[] = [];
  categoryList: any[] = [];
  subCategoryList: any[] = [];
  marketingTypeList: any[] = [];
  mstProductData: any;
  productPackageData: any[] = [];
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;

  formProductPackageEdit: FormGroup;
  db: any;
  roleAccess = new RoleAccess();
  packagePrice:number=0;
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private packageService: MasterProductPacketService,
    private userData: UserData,
    private router: Router,
    private myService :MyService
  ) { }

  ngOnInit() {
    this.buildFormProductPackageEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.productPackageId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.productPackageId = this.productPackageId.replaceAll('"','');
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.packageService.getProductPackageforEdit(this.productPackageId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.productPackageData = response.results.mst_product_package_data;
          this.mstProductData = response.results.mst_product_data;
          this.productList = response.results.mst_product_all_data;
          this.brandList = response.results.mst_product_brand_data;
          this.subBrandList = response.results.mst_product_sub_brand_data;
          this.typeList = response.results.mst_product_type_data;
          this.subTypeList = response.results.mst_product_sub_type_data;
          this.categoryList = response.results.mst_product_category_data;
          this.subCategoryList = response.results.mst_product_sub_category_data;
          this.marketingTypeList = response.results.mst_product_marketing_type_data;

          let userCreate: any = response.results.user_create_data;
          this.createdBy = userCreate ? userCreate[0].name : null;
          let userUpdate: any = response.results.user_update_data;
          this.updatedBy = userUpdate ? userUpdate[0].name : null;
          if(this.mstProductData.length > 0) {
            this.createdAt = this.mstProductData[0].created_at;
            this.updatedAt = this.mstProductData[0].updated_at;
            this.packagePrice = parseInt(this.mstProductData[0].price);
            let length_size=this.mstProductData[0].length_size;
            length_size= this.myService.separator(length_size.toString());
            length_size= String( length_size).replace(/[^0-9,]/g, '');
            let width_size=this.mstProductData[0].width_size;
            width_size= this.myService.separator(width_size.toString());
            width_size= String( width_size).replace(/[^0-9,]/g, '');
            let height_size=this.mstProductData[0].height_size;
            height_size= this.myService.separator(height_size.toString());
            height_size= String( height_size).replace(/[^0-9,]/g, '');
            this.formProductPackageEdit = this.fb.group({
              productPackageId: [this.mstProductData[0].id],
              productPackageName: [this.mstProductData[0].product_name],
              productPackageInitialName: [this.mstProductData[0].initial_name],
              brandId: [this.mstProductData[0].product_brand_id],
              subBrandId: [parseInt(this.mstProductData[0].product_sub_brand_id)],
              typeId: [this.mstProductData[0].product_type_id],
              subTypeId: [parseInt(this.mstProductData[0].product_sub_type_id)],
              categoryId: [this.mstProductData[0].product_category_id],
              subCategoryId: [parseInt(this.mstProductData[0].product_sub_category_id)],
              marketingTypeId: [parseInt(this.mstProductData[0].product_marketing_type_id)],
              barcode: [this.mstProductData[0].barcode],
              price: [this.packagePrice],
              length: [length_size],
              width: [width_size],
              height: [height_size],
              detail: this.fb.array([])
            });
          }

          if(this.productPackageData.length > 0) {
            for(let i = 0; i < this.productPackageData.length; i++) {
              let price=this.productPackageData[i].price;
              price= this.myService.separator(price.toString());
              price= String( price).replace(/[^0-9,]/g, '');
              let qty=this.productPackageData[i].qty;
              qty= this.myService.separator(qty.toString());
              qty= String( qty).replace(/[^0-9,]/g, '');
              const detail = this.fb.group({
                productId: [this.productPackageData[i].product_id],
                product: [this.productPackageData[i].product_name],
                price: [price],
                qty: [qty]
              });
              this.getDetailArray.push(detail);
            }
          } else {
            this.getDetailArray.clear();
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  buildFormProductPackageEdit() {
    this.formProductPackageEdit = this.fb.group({
      productPackageId: [null],
      productPackageName: [null],
      productPackageInitialName: [null],
      brandId: [null],
      subBrandId: [null],
      typeId: [null],
      subTypeId: [null],
      categoryId: [null],
      subCategoryId: [null],
      marketingTypeId: [null],
      barcode: [0],
      price: [0],
      length: [0],
      width: [0],
      height: [0],
      detail: this.fb.array([])
    });
  }

  addDetail() {
    const detail = this.fb.group({
      product: [null],
      price: [0],
      qty: [0]
    });
    this.getDetailArray.push(detail);
  }

  get getDetailArray() {
    return (<FormArray>this.formProductPackageEdit.get('detail'));
  }

}
