import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { ProductRequest, ProductRequestDetail } from '../../models/product-request.model';
import { ProductRequestBundle } from '../../models/product-request-bundle.model';
import { ApprovalHistory } from '../../models/approval-history.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductRequestService {

  constructor(private httpService: HttpService) { }

  getProductRequest(params: any): Observable<any> {
    return this.httpService.get('product-request', params);
  }

  getLocalData(name: any, params: any): Observable<any> {
    let apiUrl: any;
    if(name === 'counter') { apiUrl = 'counter-all'; }
    if(name === 'warehouse') { apiUrl = 'warehouse-all'; }

    return this.httpService.get(apiUrl, params);
  }

  getDataforAdvancedFilter(params: any): Observable<any> {
    return this.httpService.get('af-product-request', params);
  }

  getRequestforCreate(params: any): Observable<any> {
    return this.httpService.get('product-request/create', params);
  }

  getRequestforEdit(productRequestId: any, params: any): Observable<any> {
    return this.httpService.get(`product-request/${productRequestId}/edit`, params);
  }

  getProduct(params: any): Observable<any> {
    return this.httpService.get('product-gimmick-and-reguler', params);
  }

  addRequest(data: ProductRequest): Observable<any> {
    return this.httpService.post('product-request', data);
  }

  addRequestBundle(data: ProductRequestBundle): Observable<any> {
    return this.httpService.post('product-request-bundle', data);
  }
  
  addRequestDetail(data: ProductRequestDetail): Observable<any> {
    return this.httpService.post('product-request-details', data);
  }

  addApprovalHistory(data: ApprovalHistory): Observable<any> {
    return this.httpService.post('approval-history', data);
  }

  updateRequest(productRequestId: any, data: ProductRequest): Observable<any> {
    return this.httpService.put(`product-request/${productRequestId}`, data);
  }

  updateRequestBundle(productRequestId: any, data: ProductRequestBundle): Observable<any> {
    return this.httpService.put(`update-product-request-bundle/${productRequestId}`, data);
  }

  updateRequestDetail(productRequestId: any, data: ProductRequestDetail): Observable<any> {
    return this.httpService.put(`product-request-details/${productRequestId}`, data);
  }
}
