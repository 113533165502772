import { Component, OnInit } from '@angular/core';
import { NavController, ToastController, ModalController, AlertController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { VoucherComponent } from '../../component/voucher/voucher.component';
import { MemberPointComponent } from '../../component/member-point/member-point.component';
import { SearchProductComponent } from '../../component/search-product/search-product.component';
import { PromotionFreeItemComponent } from '../../component/promotion-free-item/promotion-free-item.component';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';
import { CompletePluginService } from '../../service/complete-plugin.service';
import { PosService } from '../pos/pos.service';
import { SalesTransactionBundle } from '../../models/sales-transaction-bundle.model';
import { ConnectivityProvider } from '../../providers/connectivity.provider';
import { UserData } from '../../providers/user-data';
import { Router } from '@angular/router';
import { RoleAccess } from '../../models/role-access.model';
import { MyService } from '../../service/my.service';

@Component({
  selector: 'app-pos-promo-oto',
  templateUrl: './pos-promo-oto.page.html',
  styleUrls: ['./pos-promo-oto.page.scss'],
})
export class PosPromoOtoPage implements OnInit {
  productBarcode: any = "";
  transactionTime: any = '';
  productId: any = "";
  modelData: any;
  otherPayment: boolean = false;
  totalPayment: number = 0;
  totalPaymentWithoutPromo: number = 0;
  totalChange: number = 0;
  totalChangeWithSeparator: string = '0';
  totalCustomerPay: number = 0;
  totalCustomerPayWithSeparator: string='0';
  customerCash: number = 0;
  customerCashWithSeparator: string='0';
  customerTransfer: number = 0;
  customerTransferWithSeparator: string='0';
  totalCustomerCash: number = 0;
  customerCredit: number = 0;
  customerCreditWithSeparator: string='0';
  customerDebit: number = 0;
  customerDebitWithSeparator: string='0';
  transactionDiscount: number = 0;
  transactionDiscountWithSeparator: string = '0';
  totalItem: number = 0;
  ppn: number = 0;
  valueUSD: number = 0;
  valueUSDwithSeparator: string = '0';
  valueCNY: number = 0;
  valueCNYwithSeparator: string = '0';
  valueWeChat: number = 0;
  valueWeChatWithSeparator: string = '0';
  totalChangeUSD: any = 0;
  totalChangeCNY: any = 0;
  exchangeRateUSD: number = 14257;
  exchangeRateCNY: number = 2204;
  exchangeRateWeChat: number = 2204;
  receiptNo: any;

  creditBankList: any[] = [];
  debitBankList: any[] = [];
  foreignCurrencyList: any[] = [];
  promotionDetailList: any[] = [];
  promotionFreeItemList: any[] = [];
  promotionProductScanData: any[] = [];
  stockFreeItem: any[] = [];
  edcMachineList: any[] = [];
  productList: Array<any>;
  productSales: Array<any>;

  printers: string[];
  isDefaultPrinterSelected = false;
  selectedPrinter: any;

  regulerStock:Array<any>;
  promoStock:Array<any>;
  mstBarcodeList: Array<any>;
  mstProductList: Array<any>;
  mstProductListSearch: Array<any>;
  mstProductPackageList: Array<any>;
  token: string;
  userProfile: UserProfile = new UserProfile();

  customerCreditId: any;
  customerDebitId: any;
  customerCreditName: any;
  customerDebitName: any;
  foreignCurrencyId: any;
  foreignCurrencyName: string;
  foreignCurrencyRate: number = 0;
  voucherUseList: any[] = [];
  voucherValue: number = 0;
  voucherValueWithSeparator: string='';
  edcId: any;

  memberPhone: any = '';
  memberPointUsed: number = 0;
  memberPointValueUsed: number = 0;
  memberPointValue: number = 0;
  memberPointValueWithSeparator:string='0';
  memberId: any;
  totalPointAdd: number = 0;
  pointMutationType: any = '';
  cashierSetupList: any = null;
  stickerNumber:any='';

  voucherMethodId: any = '';
  memberPointMethodId: any = '';
  valasMethodId: any[] = [];
  cashMethodId: any = '';
  transferMethodId: any = '';

  monthList: any[] = ['JAN', 'FEB', 'MAR', 'APR', 'MEI', 'JUN', 'JUL', 'AGU', 'SEP', 'OKT', 'NOV', 'DES'];
  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];

  //untuk print ulang struk
  productSalesTemp: Array<any>;
  transactionTimeTemp: any = '';
  totalItemTemp: number = 0;
  totalPaymentTemp: number = 0;
  transactionDiscountTemp: number = 0;
  customerCashTemp: number = 0;
  totalCustomerCashTemp: number = 0;
  customerCreditTemp: number = 0;
  customerDebitTemp: number = 0;
  totalCustomerPayTemp: number = 0;
  totalCustomerPayWithSeparatorTemp:string='0';
  totalChangeTemp: number = 0;
  totalChangeWithSeparatorTemp:string='0';
  totalChangeUSDTemp: number = 0;
  totalChangeCNYTemp: number = 0;
  valueWeChatTemp: number = 0;
  valueCNYTemp: number = 0;
  valueUSDTemp: number = 0;
  voucherValueTemp: number = 0;
  customerCreditIdTemp: any;
  customerDebitIdTemp: any;
  foreignCurrencyIdTemp: any;
  edcIdTemp: any;
  ppnTemp: number = 0;
  memberIdTemp: any;
  memberPhoneTemp: any = '';
  memberPointValueTemp: number = 0;
  memberPointUsedTemp: number = 0;
  totalPointAddTemp: number = 0;
  pointMutationTypeTemp: any = '';
  voucherUseListTemp: any[] = [];

  currentTransDate: any;
  currentTimezone: any;
  currentUsername: any = '-';
  currentCashierName: any = '-';
  
  visitorId: any;

  buttonDisabled: boolean = false;
  online: boolean;
  lamp: any;
  message_free :any = '';
  promoItemNominal : any;
  countFreeItem : number = 0;
  promoFreeItem:any[]=[];

  arrayFreeItemUsed : any[] = [];
  listFreeItemByTotalBelanja : any[] =[];
  arraySum :any[] = [];
  arraySumNominal : any[] =[];
  arrayStorage : any[] =[];
  
  roleAccess = new RoleAccess();
  arrFreeProduct:any[]=[];
  stockStatus:number=1;// 0: tidak cukup, 1 cukup,. dipakai untuk cek stok free item
  idfbertingkatbytotal=0;// id promo free item bertingkat by total belanja
  qtyfbertingkatbytotal=0; // qty free item bertingkat by total belanja
  arrSyaratFreeItemBertingkat=[]; //diskon bertingkat free item yang syaratnya sudah tercapai

  promoBundle: any[] = [];
  promoBundleFreeItem: any[] = [];
  DECIMAL_SEPARATOR:any=".";
  GROUP_SEPARATOR:any=",";
  constructor(
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private utilService: UtilService,
    private modalController: ModalController,
    private storage: Storage,
    private alertController: AlertController,
    public completePluginService: CompletePluginService,
    private posService: PosService,
    private connectivityProvider: ConnectivityProvider,
    private userData: UserData,
    private router: Router,
    private myService:MyService
  ) {
    this.currentTime();
  }

  checkConnectivity() {
    this.connectivityProvider.appIsOnline$.subscribe(online => {
      if (online) {
        this.online = online;
        this.lamp = 'green';
        console.log('online', this.online);
      } else {
        this.online = online;
        this.lamp = 'red';
        console.log('online', this.online);
      }
    })
  }
  
  ngOnInit() {
    // this.checkConnectivity();
    this.productList = new Array();
    this.productSales = new Array();
    this.mstProductList = new Array();
    
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access'),
      this.storage.get('visitor_id')
    ])
    .then(([token, profile,access, visitorId]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.visitorId = visitorId;
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.updateStorage();
          this.currentUsername = this.userProfile.username;
          let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
          let convertDate = this.utilService.convertDate(transactionDate);
          this.currentTransDate = convertDate.dates + '-' + convertDate.months + '-' + convertDate.years;
          this.getSetupCashier();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });

     // WebSocket settings
     JSPM.JSPrintManager.auto_reconnect = true;
     JSPM.JSPrintManager.start();
     JSPM.JSPrintManager.WS.onStatusChanged = () => {
         if (this.jspmWSStatus()) {
             // get client installed printers
             JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
               this.printers = myPrinters;
               this.isDefaultPrinterSelected = true;
               (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
             });
         }
     };
  }
  ionViewDidEnter() {
    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }

  currentTime() {
    let intervalVar = setInterval(function () {
      let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
      let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
      this.currentTimezone = convertTime.times;
    }.bind(this),500);
  }

  getSetupCashier() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "cashier_id": this.userProfile.cashier_id ? this.userProfile.cashier_id : 0,
        //"counter_id": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
        //"setup_device_id": this.visitorId
      };

      this.posService.getSetupCashier(options).subscribe((response) => {
        if(response.results) {
          this.cashierSetupList = response.results;
          this.currentCashierName = this.cashierSetupList.cashier_name;
          (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
          this.getMstProduct();
          this.getStock();
        } else {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Tidak ditemukan kode Kassa pada device ini. Silahkan pasang kode Kassa terlebih dahulu.' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data setup Kassa! Silahkan refresh!' }).then(t => t.present());
      });
    });
  }
  async getStock(){
    let options = {
      "counter_id": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
      "counter_date": this.currentTransDate,
      "token": this.token
    };
    this.posService.getStock(options).subscribe((response) => {
      this.regulerStock = [];
      this.promoStock = [];
      if (response.status.code == 200) {
        this.regulerStock = response.results.reguler;
        this.promoStock = response.results.promo;
      }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Stock! Silahkan refresh!' }).then(t => t.present());
    });    
  }
  async getMstProduct() {
    let options = {
      "counter_id": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
      "token": this.token
    };
    this.posService.getProduct(options).subscribe((response) => {
      if (response.status.code == 200) {
        this.mstProductListSearch = [];
        this.mstBarcodeList = [];
        this.mstProductList = [];
        this.mstProductPackageList =[];
        for (let i = 0; i < response.results.length; i++) {
          if(response.results[i].is_virtual_bundling!=1){
            this.mstBarcodeList[response.results[i].barcode] = {
              id: String(response.results[i].id),
              barcode: response.results[i].barcode,
              product_name: response.results[i].product_name,
              initial_name: response.results[i].initial_name,
              price: response.results[i].price,
            };

            let id = String(response.results[i].id);
            let index: number = parseInt(id);
            if (Number.isNaN(index)) {
              //
            } else {
              this.mstProductList[index] = {
                id: String(response.results[i].id),
                barcode: response.results[i].barcode,
                product_name: response.results[i].product_name,
                initial_name: response.results[i].initial_name,
                price: response.results[i].price,
              };
            }
            this.mstProductListSearch.push({
              id: String(response.results[i].id),
              barcode: response.results[i].barcode,
              product_name: response.results[i].product_name,
              initial_name: response.results[i].initial_name,
              price: response.results[i].price,
            });
          }
          else{
            this.mstProductPackageList.push({
              id: String(response.results[i].id),
              barcode: response.results[i].barcode,
              product_name: response.results[i].product_name,
              initial_name: response.results[i].initial_name,
              price: response.results[i].price,
            });

          }
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getPaymentMethods();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Product! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getPaymentMethods() {
    this.posService.getPaymentMethods({ "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          for (let i = 0; i < response.results.length; i++) {
            if(response.results[i].payment_method_type_id === '02') {
              this.creditBankList.push(response.results[i]);
            }
  
            if(response.results[i].payment_method_type_id === '01') {
              this.debitBankList.push(response.results[i]);
            }

            if(response.results[i].payment_method_type_id === '03') {//Voucher
              this.voucherMethodId = response.results[i].id;
            }

            if(response.results[i].payment_method_type_id === '04') {//Member Point
              this.memberPointMethodId = response.results[i].id;
            }

            if(response.results[i].payment_method_type_id === '05') {//Mata Uang Asing
              this.valasMethodId.push(response.results[i]);
            }

            if(response.results[i].payment_method_type_id === '06') {//Tunai
              this.cashMethodId = response.results[i].id;
            }

            if(response.results[i].payment_method_type_id === '08') {//Transfer
              this.transferMethodId = response.results[i].id;
            }
          }
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getForeignCurrencies();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Metode Pembayaran! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getForeignCurrencies() {
    this.posService.getCurrencies({ "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          for (let i = 0; i < response.results.length; i++) {
            this.foreignCurrencyList.push(response.results[i]);
          }
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getTodayPromotion('first_load');
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Mata Uang Asing! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getTodayPromotion(action: any) {
    this.promotionDetailList =[];
    this.stockFreeItem =[];
    this.promotionFreeItemList =[];
    this.promotionProductScanData=[];
    let options = {
      "counter_id": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
      "token": this.token
    };
    console.log('PROMOSI HARI INI');
    this.posService.getPromotion(options).subscribe((response) => {
      console.log(response);
      if (response.status.code == 200) {
        if(response.results.promotion_detail_data.length > 0) {
          for (let i = 0; i < response.results.promotion_detail_data.length; i++) {
            this.promotionDetailList.push(response.results.promotion_detail_data[i]);
          }
        }
        if(response.results.stock_free_item.length > 0) {
          for (let i = 0; i < response.results.stock_free_item.length; i++) {
            this.stockFreeItem.push(response.results.stock_free_item[i]);
          }
        }
        if(response.results.promotion_free_item_data.length > 0) {
          for (let i = 0; i < response.results.promotion_free_item_data.length; i++) {
            this.promotionFreeItemList.push(response.results.promotion_free_item_data[i]);
          }
        }

        if(response.results.promotion_product_scan_data.length > 0) {
          for (let i = 0; i < response.results.promotion_product_scan_data.length; i++) {
            this.promotionProductScanData.push(response.results.promotion_product_scan_data[i]);
          }
        }
      }
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      if(action === 'first_load') { this.getEdcMachine(); }
      if(action === 'after_trans') { this.getVoucherList(); }
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Promosi! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getEdcMachine() {
    this.posService.getEdcMachine({ "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
          for (let i = 0; i < response.results.length; i++) {
            this.edcMachineList.push(response.results[i]);
          }
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getVoucherList();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Mesin EDC! Silahkan refresh!' }).then(t => t.present());
    });
  }

  checkValidation() {
    
    this.utilService.loadingPresent('Cek Koneksi Server...')
    .then(() => {
        this.posService.checkConnectionServer([]).subscribe((response: any) => {
          this.utilService.loadingDismiss();
          console.log('koneksi', response);
          
          let validate: any = false;
          if(this.productSales.length === 0) {
            this.toastCtrl.create({ duration: 2000, message: 'Silahkan scan product terlebih dahulu.' }).then(t => t.present());
            validate = true;
          }

          if(this.cashierSetupList === null) {
            this.toastCtrl.create({ duration: 2000, message: 'Tidak ditemukan kode Kassa pada device ini. Silahkan pasang kode Kassa terlebih dahulu.' }).then(t => t.present());
            validate = true;
          }
          
          if(this.customerCredit !== 0 && this.customerCredit !== null) {
            if(this.customerCreditId === undefined) {
              this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih nama Bank terlebih dahulu.' }).then(t => t.present());
              validate = true;
            }
            if(this.edcId === undefined) {
              this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih mesin EDC yang digunakan.' }).then(t => t.present());
              validate = true;
            }
            if(this.customerCredit!=this.totalPayment){
              this.toastCtrl.create({ duration: 2000, message: 'Nominal Kredit tidak sesuai dengan Total Bayar!' }).then(t => t.present());
              validate = true;
            }
          }
          
          if(this.customerDebit !== 0 && this.customerDebit !== null) {
            if(this.customerDebitId === undefined) {
              this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih nama Bank terlebih dahulu.' }).then(t => t.present());
              validate = true;
            }
            if(this.edcId === undefined) {
              this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih mesin EDC yang digunakan.' }).then(t => t.present());
              validate = true;
            }
            if(this.customerDebit!=this.totalPayment){
              this.toastCtrl.create({ duration: 2000, message: 'Nominal Debit tidak sesuai dengan Total Bayar!' }).then(t => t.present());
              validate = true;
            }
          }
          if(this.customerTransfer !== 0 && this.customerTransfer !== null) {
            if(this.customerTransfer!=this.totalPayment){
              this.toastCtrl.create({ duration: 2000, message: 'Nominal Transfer tidak sesuai dengan Total Bayar!' }).then(t => t.present());
              validate = true;
            }
          }
          if(this.totalChange < 0) {
            this.toastCtrl.create({ duration: 2000, message: 'Total pembayaran masih belum cukup!' }).then(t => t.present());
            validate = true;
          }
          if(validate === false) {
            this.promoBundle = this.promoBundle.concat(this.promoBundleFreeItem);
            this.saveTransactionBundle();
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
    });
    
  }

  saveTransactionBundle() {
    let saveDiscount =0;
    if(this.promoBundle.length>0){
      for(let pb=0;pb<this.promoBundle.length;pb++){
        if(this.promoBundle[pb].add_discount_in_end=='Y'){
          saveDiscount += this.promoBundle[pb].discount ? parseInt(this.promoBundle[pb].discount) :0;
        }
      }
    }
    this.transactionDiscount = this.transactionDiscount ? this.transactionDiscount :0;
    saveDiscount+=this.transactionDiscount;
    this.transactionDiscount=saveDiscount;
    this.buttonDisabled = true;
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
      let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
      let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
      let convertDate = this.utilService.convertDate(transactionDate);
      let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
      let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;
      let cashierId = this.cashierSetupList ? this.cashierSetupList.id : 0;

      let arrProduct: any[] = [];
      let arrQty: any[] = [];
      let arrPrice: any[] = [];
      let arrNet: any[] = [];
      let arrPercentDisc: any[] = [];
      let arrDisc: any[] = [];
      let arrFreeQty: any[] = [];

      let arrPaymentMethodId: any[] = [];
      let arrEdcId: any[] = [];
      let arrPointSP: any[] = [];
      let arrPaymentValue: any[] = [];
      let arrVoucherCode: any[] = [];
      let arrExchangeRate: any[] = [];
      let arrPaymentList: any[] = [];

      let dateOneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      let convertDate2 = this.utilService.convertDate(dateOneYearFromNow);
      let expiredDate = convertDate2.years + '-' + convertDate2.months + '-' + convertDate2.dates;
      let paymentRemains = this.totalPayment - this.memberPointValue;
      let pointArr: any[] = [];
      let arrCustomerId: any[] = [];
      let arrPointMutationTypeId: any[] = [];
      let arrPointMP: any[] = [];
      let arrPointValue: any[] = [];
      let arrTransDate: any[] = [];
      let arrExpiredDate: any[] = [];
      let arrStatusMP: any[] = [];
      let arrCreatedBy: any[] = [];

      let arrVoucherId: any[] = [];
      let arrStatusMV: any[] = [];
      let arrUpdatedBy: any[] = [];

      let arrStockMutationTypeId: any[] = [];
      let arrProductId: any[] = [];
      let arrQtySM: any[] = [];
      let arrValue: any[] = [];
      let arrStockMove: any[] = [];
      let arrTransDateSM: any[] = [];

      // FI = Free Item
      let arrFIStockMutationTypeId: any[] = [];
      let arrFIWarehouseId: any[] = [];
      let arrFIProductId: any[] = [];
      let arrFIQty: any[] = [];
      let arrFIValue: any[] = [];
      let arrFIStockMove: any[] = [];
      let arrFITransDateSM: any[] = [];

      let arrPromotionFreeItemId: any[] = [];
      let arrFreeItemUsed: any[] = [];
      
      //sales_transaction_details start
      for(let x = 0; x < this.productSales.length; x++) {
        arrProduct[x] = this.productSales[x].id;
        arrQty[x] = this.productSales[x].qty;
        arrPrice[x] = this.productSales[x].typePromo === 'FREE ITEM (ITEM)' || this.productSales[x].typePromo === 'FREE ITEM (NOMINAL)' ? this.productSales[x].priceAfterPromo : this.productSales[x].price;
        arrPercentDisc[x] = this.productSales[x].valPromoPercentage;
        
        arrFreeQty[x] = this.productSales[x].qtyFree;
        let countPromoDiff:number =0;
        if(this.productSales[x].valPromoNominal!=0){
          countPromoDiff = this.productSales[x].valPromoNominal;
        }
        else if(this.productSales[x].valPromoPercentage!=0){
          countPromoDiff = this.productSales[x].nominalPromoPercentage;
        }
        arrDisc[x] = countPromoDiff;
        arrNet[x] = this.productSales[x].nett;
      }
      //sales_transaction_details end
      
      //sales_transaction_payments start
      if(this.customerCash !== 0) {
        let data = {
          'payment_method_id': this.cashMethodId,
          'edc_id': 0,
          'point': 0,
          'payment_value': this.customerCash,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.customerTransfer !== 0) {
        let data = {
          'payment_method_id': this.transferMethodId,
          'edc_id': 0,
          'point': 0,
          'payment_value': this.customerTransfer,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.customerCredit !== 0) {
        let data = {
          'payment_method_id': this.customerCreditId,
          'edc_id': this.edcId,
          'point': 0,
          'payment_value': this.customerCredit,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.customerDebit !== 0) {
        let data = {
          'payment_method_id': this.customerDebitId,
          'edc_id': this.edcId,
          'point': 0,
          'payment_value': this.customerDebit,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.voucherValue !== 0) {
        for(let x = 0; x < this.voucherUseList.length; x++) {
          let data = {
            'payment_method_id': this.voucherMethodId,
            'edc_id': 0,
            'point': 0,
            'payment_value': this.voucherUseList[x].nominal,
            'voucher_code': this.voucherUseList[x].voucher_code,
            'exchange_rate': 0
          };
          arrPaymentList.push(data);
        }
      }

      if(this.memberPointValue !== 0) {
        let data = {
          'payment_method_id': this.memberPointMethodId,
          'edc_id': 0,
          'point': 0,
          'payment_value': this.memberPointValue,
          'voucher_code': null,
          'exchange_rate': 0
        };
        arrPaymentList.push(data);
      }

      if(this.otherPayment === true) {
        if(this.valueUSD !== 0) {
          let method: any = this.valasMethodId.find(x => x.payment_name === 'USD');
          let data = {
            'payment_method_id': method ? method.id : 0,
            'edc_id': 0,
            'point': 0,
            'payment_value': this.valueUSD,
            'voucher_code': null,
            'exchange_rate': this.exchangeRateUSD
          };
          arrPaymentList.push(data);
        }

        if(this.valueCNY !== 0) {
          let method: any = this.valasMethodId.find(x => x.payment_name === 'CNY');
          let data = {
            'payment_method_id': method ? method.id : 0,
            'edc_id': 0,
            'point': 0,
            'payment_value': this.valueCNY,
            'voucher_code': null,
            'exchange_rate': this.exchangeRateCNY
          };
          arrPaymentList.push(data);
        }

        if(this.valueWeChat !== 0) {
          let method: any = this.valasMethodId.find(x => x.payment_name === 'Wechat');
          let data = {
            'payment_method_id': method ? method.id : 0,
            'edc_id': 0,
            'point': 0,
            'payment_value': this.valueWeChat,
            'voucher_code': null,
            'exchange_rate': this.exchangeRateWeChat
          };
          arrPaymentList.push(data);
        }
      }

      for(let x = 0; x < arrPaymentList.length; x++) {
        arrPaymentMethodId[x] = arrPaymentList[x].payment_method_id;
        arrEdcId[x] = arrPaymentList[x].edc_id;
        arrPointSP[x] = arrPaymentList[x].point;
        arrPaymentValue[x] = arrPaymentList[x].payment_value;
        arrVoucherCode[x] = arrPaymentList[x].voucher_code;
        arrExchangeRate[x] = arrPaymentList[x].exchange_rate;
      }
      //sales_transaction_payments end

      //point_mutations start
      if(this.memberPhone !== '') {
        if(paymentRemains !== 0 && paymentRemains >= 10000) {
          let countPoint: any = paymentRemains / 10000;
          this.totalPointAdd = parseInt(countPoint);
          let value: any = this.totalPointAdd * 100;
          pointArr.push({
            'pointMutationType': 'I',
            'point': this.totalPointAdd,
            'pointValue': value
          });
        }
  
        if(this.pointMutationType === 'O') {
          pointArr.push({
            'pointMutationType': 'O',
            'point': this.memberPointUsed,
            'pointValue': this.memberPointValue
          });
        }
        
        for(let x = 0; x < pointArr.length; x++) {
          arrCustomerId[x] = this.memberId;
          arrPointMutationTypeId[x] = pointArr[x].pointMutationType;
          arrPointMP[x] = pointArr[x].point;
          arrPointValue[x] = pointArr[x].pointValue;
          arrTransDate[x] = transDate;
          arrExpiredDate[x] = expiredDate;
          arrStatusMP[x] = 1;
          arrCreatedBy[x] = this.userProfile.username;
        }
      }
      //point_mutations end

      //member_point start
      let pointTotal: any = this.totalPointAdd;
      let pointTotalValue: any = pointTotal * 100;
      let pointUsed: any = this.memberPointUsed;
      let pointUsedValue: any = pointUsed * 100;
      let pointRemains: any = 0;
      
      if(this.totalPointAdd > 0) {
        pointRemains = pointRemains + this.totalPointAdd;
      }
      if(this.memberPointUsed > 0) {
        pointRemains = pointRemains - this.memberPointUsed;
      }

      let pointRemainsValue: any = pointRemains * 100;
      //member_point end

      //mst_voucher start
      if(this.voucherValue !== 0) {
        if(this.voucherUseList.length > 0) {
          for(let i = 0; i < this.voucherUseList.length; i++) {
            arrVoucherId[i] = this.voucherUseList[i].id;
            arrStatusMV[i] = '1';
            arrUpdatedBy[i] = this.userProfile.username;
          }
        }
      }
      //mst_voucher end

      //stock_mutations start
      for(let x = 0; x < this.productSales.length; x++) {
        if(parseInt(this.productSales[x].qty) >0) {
          arrStockMutationTypeId[x] = 'R'; // R = RETAIL (PENJUALAN)
          arrProductId[x] = this.productSales[x].id;
          arrQtySM[x] = this.productSales[x].qty;
          if(this.productSales[x].qtyFree>0){
            arrQtySM[x] = this.productSales[x].qty-this.productSales[x].qtyFree;
          }
          arrValue[x] = 0;
          arrStockMove[x] = 'O';
          arrTransDateSM[x] = transDate;
        }

        if(this.productSales[x].qtyFree > 0) {
          arrFIStockMutationTypeId.push('PB'); // PB = PRODUCT BONUS
          arrFIWarehouseId.push(this.productSales[x].warehouseIdPromo);
          arrFIProductId.push(this.productSales[x].id);
          arrFIQty.push(this.productSales[x].qtyFree);
          arrFIValue.push(0);
          arrFIStockMove.push('O');
          arrFITransDateSM.push(transDate);

          arrPromotionFreeItemId.push(this.productSales[x].promotionFreeItemId);
          arrFreeItemUsed.push(this.productSales[x].qtyFree);
        }
      }
      //stock_mutations end

      const salesTransactionBundle = new SalesTransactionBundle();
      salesTransactionBundle.salesTransaction.cashier_id = cashierId;
      salesTransactionBundle.salesTransaction.user_id = this.userProfile.id;
      salesTransactionBundle.salesTransaction.counter_id = this.userProfile.counter_id_pos;
      salesTransactionBundle.salesTransaction.customer_id = this.memberId ? this.memberId : 0;
      salesTransactionBundle.salesTransaction.trans_date = transDate;
      salesTransactionBundle.salesTransaction.trans_time = transTime;
      salesTransactionBundle.salesTransaction.total_item = this.totalItem;
      salesTransactionBundle.salesTransaction.total_value = this.totalPayment;
      salesTransactionBundle.salesTransaction.total_payment = this.totalCustomerPay;
      salesTransactionBundle.salesTransaction.discount = this.transactionDiscount ? this.transactionDiscount : 0;
      salesTransactionBundle.salesTransaction.change = this.totalChange;
      salesTransactionBundle.salesTransaction.cash = this.customerCash ? this.customerCash : 0;
      salesTransactionBundle.salesTransaction.transfer = this.customerTransfer ? this.customerTransfer : 0;
      salesTransactionBundle.salesTransaction.credit_card = this.customerCredit ? this.customerCredit : 0;
      salesTransactionBundle.salesTransaction.debit_card = this.customerDebit ? this.customerDebit : 0;
      salesTransactionBundle.salesTransaction.voucher = this.voucherValue;
      salesTransactionBundle.salesTransaction.member_point = this.memberPointValue;
      salesTransactionBundle.salesTransaction.foreign_currency_1 = this.valueUSD;
      salesTransactionBundle.salesTransaction.exchange_rate_1 = this.exchangeRateUSD;
      salesTransactionBundle.salesTransaction.currency_1 = 'USD';
      salesTransactionBundle.salesTransaction.foreign_currency_2 = this.valueCNY;
      salesTransactionBundle.salesTransaction.exchange_rate_2 = this.exchangeRateCNY;
      salesTransactionBundle.salesTransaction.currency_2 = 'CNY';
      salesTransactionBundle.salesTransaction.foreign_currency_3 = this.valueWeChat;
      salesTransactionBundle.salesTransaction.exchange_rate_3 = this.exchangeRateWeChat;
      salesTransactionBundle.salesTransaction.currency_3 = 'Wechat';
      salesTransactionBundle.salesTransaction.transaction_type = '1';
      salesTransactionBundle.salesTransaction.status = '1';
      salesTransactionBundle.salesTransaction.created_by = this.userProfile.username;
      salesTransactionBundle.salesTransaction.promo_auto = 'Y';
      salesTransactionBundle.salesTransaction.sticker_number = this.stickerNumber;

      salesTransactionBundle.salesTransactionDetail.product_id = arrProduct;
      salesTransactionBundle.salesTransactionDetail.qty = arrQty;
      salesTransactionBundle.salesTransactionDetail.price = arrPrice;
      salesTransactionBundle.salesTransactionDetail.percent_disc_1 = arrPercentDisc;
      salesTransactionBundle.salesTransactionDetail.disc_1 = arrDisc;
      salesTransactionBundle.salesTransactionDetail.free_qty = arrFreeQty;
      salesTransactionBundle.salesTransactionDetail.net = arrNet;

      salesTransactionBundle.salesTransactionPayments.payment_method_id = arrPaymentMethodId;
      salesTransactionBundle.salesTransactionPayments.edc_id = arrEdcId;
      salesTransactionBundle.salesTransactionPayments.point = arrPointSP;
      salesTransactionBundle.salesTransactionPayments.payment_value = arrPaymentValue;
      salesTransactionBundle.salesTransactionPayments.voucher_code = arrVoucherCode;
      salesTransactionBundle.salesTransactionPayments.exchange_rate = arrExchangeRate;

      salesTransactionBundle.pointMutation.customer_id = arrCustomerId;
      salesTransactionBundle.pointMutation.point_mutation_type_id = arrPointMutationTypeId;
      salesTransactionBundle.pointMutation.point = arrPointMP;
      salesTransactionBundle.pointMutation.point_value = arrPointValue;
      salesTransactionBundle.pointMutation.trans_date = arrTransDate;
      salesTransactionBundle.pointMutation.expired_date = arrExpiredDate;
      salesTransactionBundle.pointMutation.status = arrStatusMP;
      salesTransactionBundle.pointMutation.created_by = arrCreatedBy;

      salesTransactionBundle.memberPoint.customer_id = this.memberId;
      salesTransactionBundle.memberPoint.point_total = pointTotal;
      salesTransactionBundle.memberPoint.point_total_value = pointTotalValue;
      salesTransactionBundle.memberPoint.point_used = pointUsed;
      salesTransactionBundle.memberPoint.point_used_value = pointUsedValue;
      salesTransactionBundle.memberPoint.point_remains = pointRemains;
      salesTransactionBundle.memberPoint.point_remains_value = pointRemainsValue;
      salesTransactionBundle.memberPoint.updated_by = this.userProfile.username;

      salesTransactionBundle.mstVoucherDetail.id = arrVoucherId;
      salesTransactionBundle.mstVoucherDetail.status = arrStatusMV;
      salesTransactionBundle.mstVoucherDetail.updated_by = arrUpdatedBy;

      salesTransactionBundle.stockMutation.stock_mutation_type_id = arrStockMutationTypeId;
      salesTransactionBundle.stockMutation.product_id = arrProductId;
      salesTransactionBundle.stockMutation.qty = arrQty;
      salesTransactionBundle.stockMutation.value = arrValue;
      salesTransactionBundle.stockMutation.stock_move = arrStockMove;
      salesTransactionBundle.stockMutation.trans_date = arrTransDateSM;

      salesTransactionBundle.freePromoMutation.stock_mutation_type_id = arrFIStockMutationTypeId;
      salesTransactionBundle.freePromoMutation.warehouse_id = arrFIWarehouseId;
      salesTransactionBundle.freePromoMutation.product_id = arrFIProductId;
      salesTransactionBundle.freePromoMutation.qty = arrFIQty;
      salesTransactionBundle.freePromoMutation.value = arrFIValue;
      salesTransactionBundle.freePromoMutation.stock_move = arrFIStockMove;
      salesTransactionBundle.freePromoMutation.trans_date = arrFITransDateSM;

      salesTransactionBundle.mstPromotionDetail.free_item_id = arrPromotionFreeItemId;
      salesTransactionBundle.mstPromotionDetail.free_item_used = arrFreeItemUsed;
      salesTransactionBundle.mstPromotionDetail.promo_bundle=this.promoBundle;
      this.posService.addSalesTransactionBundle(salesTransactionBundle).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          let ppnVal: any = this.totalPayment * 0.1;
          this.ppn = parseInt(ppnVal);
          this.receiptNo = response.results.data.receipt_no;
          this.printReceipt();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
        this.buttonDisabled = false;
      }, () => {
        this.buttonDisabled = false;
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  getVoucherList() {
    this.posService.getVoucher({ "token": this.token }).subscribe((response) => {
      if (response.status.code == 200) {
        if(response.results.length > 0) {
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
      this.getMemberList();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Voucher! Silahkan refresh!' }).then(t => t.present());
    });
  }

  getMemberList() {
    this.posService.getMember({ "token": this.token }).subscribe((response) => {
      this.utilService.loadingDismiss();
      if (response.status.code == 200) {
        if(response.results.length > 0) {
        }
      }

      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data Member! Silahkan refresh!' }).then(t => t.present());
    });
  }

  async showAlertBarcodeNotValid(message: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: message,
      backdropDismiss: true,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
          }
        }
      ]
    });

    await alert.present();
  }

  async openVoucher() {
    const modal = await this.modalController.create({
      component: VoucherComponent,
      componentProps: {
        'totalTransaction': this.totalPayment,
        'voucherUseList': Array.isArray(this.voucherUseList) ? this.voucherUseList : [],
        'token': this.token
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      this.voucherUseList = modelData.data;
      if (this.voucherUseList.length > 0) {
        this.voucherValue = 0;
        for(let x = 0; x < this.voucherUseList.length; x++) {
          this.voucherValue = this.voucherValue + parseInt(this.voucherUseList[x].nominal);
        }

        if(this.voucherValue >= this.totalPayment) {
          this.voucherValue = this.totalPayment;
        }

        this.getChangeNominal();
      }
    });

    return await modal.present();
  }

  async openMemberPoint() {
    const modal = await this.modalController.create({
      component: MemberPointComponent,
      componentProps: {
        'totalTransaction': this.totalPayment,
        'token': this.token
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData.data !== '') {
        let modelsData: string = modelData.data;
        let memberPointData = modelsData.split('#');
        this.memberId = memberPointData[0];
        this.memberPhone = memberPointData[1];
        this.memberPointUsed = parseInt(memberPointData[2]);
        this.memberPointValueUsed = parseInt(memberPointData[3]);
        this.pointMutationType = memberPointData[4];

        if(parseInt(memberPointData[3]) >= this.totalPayment) {
          this.memberPointValue = this.totalPayment;
        } else {
          this.memberPointValue = parseInt(memberPointData[3]);
        }

        this.getChangeNominal();
      }
    });

    return await modal.present();
  }
  
  async openFreeItem(checkPromoFreeItem: any,  freeItemList: any[], index:number,promoid:number) {
    let checkFreeItemUsed = this.arrayFreeItemUsed.filter(x => parseInt(x.promo_id_detail) === parseInt(freeItemList[0].promotion_detail_id));
    let promoId: any = this.promotionDetailList.find(x => x.id ==  checkFreeItemUsed[0]['promo_id_detail']);
    let warehouseId = 0;
    let productScan = 0;
    let promotion_name ='';
    this.stockStatus = 1;
    if(promoId){
      warehouseId = promoId.warehouse_id;
      promotion_name =promoId.promotion_name;
    }
    if(index!=-1 && index!=-2){
      productScan = this.productSales[index]['id'];
    }
    const modal = await this.modalController.create({
      component: PromotionFreeItemComponent,
      componentProps: {
        'promotion_name':promotion_name,
        'freeItemList': freeItemList,
        'arrayFreeItemUsed': checkFreeItemUsed,
        'productScan':productScan,
        'stock':this.promoStock
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let getProductSelect  = this.arrayFreeItemUsed.find(x => x.product_id === productScan && parseInt(x.promo_id_detail) === parseInt(promoId.id));
      if(getProductSelect){
        getProductSelect.array_item=[];
      }
      if (modelData.data !== '') {
        
        for(let i=0;i<modelData.data.freeItemList.length;i++){
          let idPromotionFreeItem: any = '';
          let idPromotionFreeItemByNominalId :any='';
          let id: any = modelData.data.freeItemList[i].id;
          let productId: any = modelData.data.freeItemList[i].product_id;
          let promotionId = modelData.data.freeItemList[i].promotion_detail_id;
          let idPromotionDetail =modelData.data.freeItemList[i].promotion_detail_id;
          let freeQty = modelData.data.freeItemList[i].used;
          if (freeQty==null){
            freeQty=0;
          }
          
            
          let checkStock:any = this.promoStock.find(x => x.product_id === productId);
          let stock:number=0;
          if (checkStock) {
            stock = checkStock.stock;
          }
          
          let checkProduct:any = this.productSales.find(x => x.id === productId);
          if(checkProduct!==undefined)
          {
            if((parseInt(checkProduct.qtyFree)+parseInt(freeQty))>stock){              
              let message = 'Stock '+this.arrFreeProduct[i].productId+' tidak cukup.\nStock: '+stock+'';
              this.showAlertBarcodeNotValid(message);
              this.stockStatus=0;
            }

          }
          if(this.stockStatus ==1){
            if((checkPromoFreeItem.promotion_type_name=='FREE ITEM AND NOMINAL'  && checkPromoFreeItem.qty_type
              =='1') || (checkPromoFreeItem.promotion_type_name=='FREE ITEM (ITEM)'  && checkPromoFreeItem.qty_type
              =='1') ||(checkPromoFreeItem.promotion_type_name=='FREE ITEM AND NOMINAL'  && checkPromoFreeItem.promo_based_on==0 && checkPromoFreeItem.product_id == null )||(checkPromoFreeItem.promotion_type_name === 'PROMO BERTINGKAT' && checkPromoFreeItem.product_id === null && checkPromoFreeItem.promo_based_on == 1)|| ( checkPromoFreeItem.promotion_type_name=='FREE ITEM AND NOMINAL' || checkPromoFreeItem.promotion_type_name=='FREE ITEM (NOMINAL)') && (checkPromoFreeItem.promo_based_on==1 || (parseInt(checkPromoFreeItem.promo_based_on)==2 && checkPromoFreeItem.product_id!='')) )
            {           
              if(index == -1 || index== -2){ // promo by total belanja
                let checkRemainingByTotal = this.arrayFreeItemUsed.find(x => x.product_id === 0  && parseInt(x.promo_id_detail) === parseInt(promotionId));
                if(checkRemainingByTotal){  
                  let idxByTotal = this.arrayFreeItemUsed.indexOf(checkRemainingByTotal);                 
                  if (this.arrayFreeItemUsed[idxByTotal].free_item_remaining==0){
                    if (index== -1){
                      this.countFreeItem = this.arrayFreeItemUsed[idxByTotal].free_item_remaining;    
                    }
                    else if (index== -2){
                      let caripromo = this.promoFreeItem.find(x=>x.id==promoid);
                      if (caripromo!==undefined){
                        caripromo.countFreeItem = this.arrayFreeItemUsed[idxByTotal].free_item_remaining; 
                      }
                      let carisisa = this.promoFreeItem.find(x=>x.countFreeItem>0);
                      if(carisisa==undefined){
                        this.message_free='';
                      }
                    }
                  }
                }
              }
            }
          
            if(index == -1 || index== -2){
              let freeItemByNominal= this.arrayFreeItemUsed.find(x => x.product_id === 0  && parseInt(x.promo_id_detail) === parseInt(promotionId));
              idPromotionFreeItemByNominalId = modelData.data.freeItemList[i].id;
              let array_item= {
                'product_id':productId,
                'qty_free':freeQty,
                'promotion_id' : modelData.data.freeItemList[i].id,
                'product_name':modelData.data.freeItemList[i].product_name,
                'promotion_type_name': checkPromoFreeItem.promotion_type_name,
                'multiple_flag':checkPromoFreeItem ? checkPromoFreeItem.multiple_flag : 0,
                'warehouse_id':warehouseId,
                'id_promotion_detail':idPromotionDetail,
                'id_promotion_free_item':idPromotionFreeItem,
                'id_promotion_free_item_by_nominal':idPromotionFreeItemByNominalId
              };
              freeItemByNominal.array_item[i]=array_item;
              
              let itembertingkat = this.arrSyaratFreeItemBertingkat.find(x=>x.promotion_detail_id==idPromotionDetail && x.product_id==productId && x.id==id);
              if(itembertingkat!=undefined){
                itembertingkat.qty_free=freeQty;
              }
              else{
                this.arrSyaratFreeItemBertingkat.push({
                  "id":id,
                  "promotion_detail_id":idPromotionDetail,
                  "product_id":productId,
                  "qty_free":freeQty
                })
              }

            }
            else{
              if(getProductSelect){
                let array_item= {
                  'product_id':productId,
                  'qty_free':freeQty,
                  'promotion_id' : modelData.data.freeItemList[i].id,
                  'product_name':modelData.data.freeItemList[i].product_name,
                  'promotion_type_name': checkPromoFreeItem.promotion_type_name,
                  'multiple_flag':checkPromoFreeItem ? checkPromoFreeItem.multiple_flag : 0,
                  'warehouse_id':warehouseId,
                  'id_promotion_detail':idPromotionDetail,
                  'id_promotion_free_item':idPromotionFreeItem,
                  'id_promotion_free_item_by_nominal':idPromotionFreeItemByNominalId
                };
                getProductSelect.array_item[i]=array_item;
                if (getProductSelect.free_item_remaining==0){
                  this.productSales[index].promo_free_item[promoid].getFree=0
                  this.productSales[index]['getFree'] = 0;
                }
              }

            }
          }
        }
      }
      this.inputFreeItem(productScan);
      this.calcTotalItem();
      this.updateStorage();
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    });

    return await modal.present();
  }

  inputFreeItem(productScan:any){
    this.stockStatus=1;
    let arrFreeItemUsed :number=this.arrayFreeItemUsed.length;
    this.arrFreeProduct=[];
    if(arrFreeItemUsed>0){
      let freeQty = 0;
      for(let i =0;i<arrFreeItemUsed;i++){
        let array_item = this.arrayFreeItemUsed[i].array_item.length;
        if(array_item>0){
          for(let j=0;j<array_item;j++){
            let productId =this.arrayFreeItemUsed[i].array_item[j].product_id;
            freeQty = parseInt(this.arrayFreeItemUsed[i].array_item[j].qty_free);
            let promotion_id = this.arrayFreeItemUsed[i].array_item[j].promotion_id;
            let product_name = this.arrayFreeItemUsed[i].array_item[j].product_name;
            let promotion_type_name = this.arrayFreeItemUsed[i].array_item[j].promotion_type_name;
            let multipleFlag = this.arrayFreeItemUsed[i].array_item[j].multiple_flag;
            let warehouseId = this.arrayFreeItemUsed[i].array_item[j].warehouse_id;
            let idPromotionDetail =this.arrayFreeItemUsed[i].array_item[j].id_promotion_detail;
            let promotionFreeItemId=this.arrayFreeItemUsed[i].array_item[j].id_promotion_free_item;
            let promotionFreeItemByNominalId=this.arrayFreeItemUsed[i].array_item[j].id_promotion_free_item_by_nominal
            let findProduct =this.productSales.find(x=> x.id==productId);
            if(findProduct===undefined){
              let arrayListPromo =[];
              arrayListPromo.push({
                'promotion_detail_id':idPromotionDetail,
                'promotion_id' : promotion_id,
                'product_scan' :productScan,
                'qty' : freeQty
              });
              let price:number =0;
              let getProduct=this.mstProductList[parseInt(productId)];
              if(getProduct!==undefined){
                price= getProduct['price'];
              }
              this.productSales.push({
                barcode_view:productId,
                id:productId,
                barcode: productId,
                name: product_name,
                initialName: product_name,
                qty: 0,
                qty_withoutpromo:0,
                price: price,
                priceAfterPromo: price,
                nett: 0,
                typePromo: promotion_type_name,
                valPromoPercentage: 0,
                valPromoNominal: 0,
                nominalPromoPercentage:0,
                qtyFree: freeQty,
                multipleFlag:multipleFlag,
                warehouseIdPromo: warehouseId,
                promotionFreeItemId: promotionFreeItemId,
                promotionFreeItemByNominalId :promotionFreeItemByNominalId,
                getFree : 0,
                promo : '',
                promo_free_item:[],
                arrayPromoFreeItem :arrayListPromo,
                promoNominalBertingkat:'',
                promoPersenBertingkat:'',
                promoFreeBertingkat:'',
                arr_id_promo :[],
                arr_promo_tingkat :[],
                arr_promo_nominal :[],
                arr_kontribusi :[],
                promoAuto:'Y'
              });
            }
            else{
              findProduct.warehouseIdPromo=warehouseId;
              let arrayListPromo =[];
              arrayListPromo.push({
                'promotion_detail_id':idPromotionDetail,
                'promotion_id' : promotion_id,
                'product_scan' :productScan,
                'qty' : freeQty
              });
              if(findProduct.arrayPromoFreeItem.length>0){
                let ff = findProduct.arrayPromoFreeItem.find(x=>x.promotion_detail_id==idPromotionDetail && x.product_scan==productScan);
                if(ff){
                  ff.qty = freeQty;
                }
                else{
                  findProduct.arrayPromoFreeItem.push({
                    'promotion_detail_id':idPromotionDetail,
                    'promotion_id' : promotion_id,
                    'product_scan' :productScan,
                    'qty' : freeQty
                  });
                }
              }
              else{
                findProduct.arrayPromoFreeItem =arrayListPromo;
              }
            }
            let findArrProduct =this.arrFreeProduct.find(x=> x.productId==productId);
            if(findArrProduct!=undefined){
              findArrProduct.freeQty+=freeQty;
            }
            else{
              this.arrFreeProduct.push({
                'productId':productId,
                'freeQty':freeQty
              });
            }
            let cariunused =this.promoBundleFreeItem.find(x=>x.promotion_detail_id!= this.arrayFreeItemUsed[i].promo_id_detail && x.product_scan==this.arrayFreeItemUsed[i].product_id);
            if(cariunused!==undefined){
              let index = this.promoBundleFreeItem.indexOf(cariunused);
              this.promoBundleFreeItem.splice(index, 1);
            }
            let findPromoBundle =this.promoBundleFreeItem.find(x=> x.promotion_detail_id==idPromotionDetail && x.product_scan==productScan && x.product_free_item==productId);
            if(findPromoBundle!=undefined){
              findPromoBundle.qty_free_item=freeQty;
            }
            else{
              this.promoBundleFreeItem.push({
                'product_scan':productScan,
                'promotion_detail_id':idPromotionDetail,
                'qty_get_promo':1,
                'discount':0,
                'product_free_item':productId,
                'qty_free_item':freeQty
              })
            }
            let getproductScan = this.productSales.find(x=>x.id==productScan);
            if(getproductScan){
              let filterpromounused = getproductScan.arr_promo_tingkat.filter(x=>x.terpilih=='N');
              if (filterpromounused.length>0){
                for(let un=0;un<filterpromounused.length;un++){
                  let cariunused =this.promoBundleFreeItem.find(x=>x.promotion_detail_id==filterpromounused[un].idpromodetail && x.product_scan==productScan);
                  if(cariunused!==undefined){
                    let index = this.promoBundleFreeItem.indexOf(cariunused);
                    this.promoBundleFreeItem.splice(index, 1);
                  }
                }
              }
            }
          }
        }        
      }
      if(this.arrFreeProduct.length>0){
        for(let i=0;i<this.arrFreeProduct.length;i++){
          let findProduct =this.productSales.find(x=> x.id==this.arrFreeProduct[i].productId);
          if(findProduct!=undefined){
            findProduct.qtyFree = this.arrFreeProduct[i].freeQty;
          }
        }
      }
    }
  }
  
  updateFreeItem(){
    let arrFreeItemUsed :number=this.arrayFreeItemUsed.length;
    this.arrFreeProduct=[];
    if(arrFreeItemUsed>0){
      let freeQty = 0;
      for(let i =0;i<arrFreeItemUsed;i++){
        let array_item = this.arrayFreeItemUsed[i].array_item.length;
        if(array_item>0){
          for(let j=0;j<array_item;j++){
            let productId =this.arrayFreeItemUsed[i].array_item[j].product_id;
            freeQty = parseInt(this.arrayFreeItemUsed[i].array_item[j].qty_free);
            let findArrProduct =this.arrFreeProduct.find(x=> x.productId==productId);
            if(findArrProduct!=undefined){
              findArrProduct.freeQty+=freeQty;
            }
            else{
              this.arrFreeProduct.push({
                'productId':productId,
                'freeQty':freeQty
              });
            }
        }        
      }
      if(this.arrFreeProduct.length>0){
        for(let i=0;i<this.arrFreeProduct.length;i++){
          let findProduct =this.productSales.find(x=> x.id==this.arrFreeProduct[i].productId);
          if(findProduct!=undefined){
            findProduct.qtyFree = this.arrFreeProduct[i].freeQty;
          }
        }
      }
    }
  }
}

  //Start => Local Function

  scrollDownEnd() {
    setTimeout(()=>{   
        var elem = document.getElementById('item');
        elem.scrollTop = elem.scrollHeight;
    }, 50);
  }

  jspmWSStatus() { // Check JSPM WebSocket status
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
        alert('JSPM has blocked this website!');
        return false;
    }
  }

  cekSyarat(promo:any){
    let syaratDibutuhkan :number=promo.product_required.length;
    let syaratTerpenuhi :number=0;
    for(let j = 0; j < promo.product_required.length; j++) {
      let splitProductRequired: any[] = promo.product_required[j].product_id.split(';');
      let qtyRequired:number=parseInt(promo.product_required[j].qty);
      let qtyTypeRequired:any=promo.product_required[j].qty_type;
      let ada:boolean=false;
      let qtyGabungan:number=0;
      for(let k=0;k<splitProductRequired.length;k++){
        if(qtyTypeRequired=='0'){
          let cek = this.productSales.find(x =>parseInt(x.qty) >= qtyRequired && x.id==splitProductRequired[k]);
          if(cek){
            ada =true;
          }
        }
        else{
          let cek = this.productSales.find(x => x.id==splitProductRequired[k]);
          if(cek){
            qtyGabungan+=parseInt(cek.qty);
          }
        }
      }
      if(qtyTypeRequired=='1' && qtyRequired<=qtyGabungan){
        ada =true;
      }
      if(ada){
        syaratTerpenuhi++;
      }
    }
    if(syaratDibutuhkan==syaratTerpenuhi){
      return 'Y';
    }
    else{
      return 'N';
    }
  }
  
  printReceipt() {
    let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
    let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
    let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
    let convertDate = this.utilService.convertDate(transactionDate);

    let monthName: any = this.monthList[(parseInt(convertDate.months)-1)];
    let transDate = convertDate.dates + '-' + monthName + '-' + convertDate.years;
    let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;
    this.transactionTime = transTime;

    let totalPromo: number = 0;
    let cashierName: string = this.userProfile.name;

    //Create a ClientPrintJob
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += '---------------------------------';
    cmds += 'NO STRUK : ' + this.receiptNo;
    cmds += newLine;
    cmds += 'TANGGAL  : ' + transDate + ' ' + transTime;
    cmds += newLine;
    cmds += 'KASIR    : ' + cashierName.toUpperCase();
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    //Start List Barang Belanja
    for(let i = 0; i < this.productSales.length; i++) {
      if(this.productSales[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSales[i].typePromo !== 'FREE ITEM (NOMINAL)') {
      
        let priceProduct: number = parseInt(this.productSales[i].price);
        let qtyBuy: number = parseInt(this.productSales[i].qty);
        let nettProduct: number = priceProduct * qtyBuy;

        let qtyFree: number = parseInt(this.productSales[i].qtyFree);
        let nettFree: number = priceProduct * qtyFree;

        if(this.productSales[i].qtyFree > 0) {          
          if(qtyBuy > 0){
            let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
            let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
            cmds += this.productSales[i].initialName;
            cmds += newLine;
            cmds += lblPriceXQty.toString() + lblNettProduct.toString();
            cmds += newLine;
          }
          
          let lblPriceXQtyFree: String = 'FREE ' + qtyFree + ' x ' + this.formatRupiah(priceProduct.toString());
          let lblNettFree: String = this.getLblNett('('+this.formatRupiah(nettFree.toString())+')', lblPriceXQtyFree.length);
          cmds += this.productSales[i].initialName;
          cmds += newLine;
          cmds += lblPriceXQtyFree.toString() + lblNettFree.toString();
          cmds += newLine;
        } else {
          if(qtyBuy > 0){
            let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
            let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
            cmds += this.productSales[i].initialName;
            cmds += newLine;
            cmds += lblPriceXQty.toString() + lblNettProduct.toString();
            cmds += newLine;
          }
        }
      }

      if(this.productSales[i].typePromo === 'FREE ITEM (ITEM)' || this.productSales[i].typePromo === 'FREE ITEM (NOMINAL)') {
        let price: number = parseInt(this.productSales[i].priceAfterPromo);
        let qty: number = parseInt(this.productSales[i].qtyFree);
        let nett: number = price * qty;
        let lblPriceAndQty: String = 'FREE ' + qty + ' x ' + this.formatRupiah(price.toString());
        let lblNett: String = this.getLblNett('('+this.formatRupiah(nett.toString())+')', lblPriceAndQty.length);
        cmds += this.productSales[i].initialName;
        cmds += newLine;
        cmds += lblPriceAndQty.toString() + lblNett.toString();
        cmds += newLine;
      }
    }
    //End List Barang Belanja
    cmds += '---------------------------------';
    let lblPaymentWithoutPromo: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPaymentWithoutPromo.toString()));
    cmds += '        HARGA JUAL : ' + lblPaymentWithoutPromo;
    cmds += newLine;
    //Start Detail Promo Product
    for(let i = 0; i < this.productSales.length; i++) {
      if(this.productSales[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSales[i].typePromo !== 'FREE ITEM (NOMINAL)') {
        if(this.productSales[i].typePromo === 'NOMINAL' || this.productSales[i].typePromo === 'PERSENTASE') {
          let countPromo: number = 0;
          if(this.productSales[i].valPromoNominal!=0){
            countPromo = this.productSales[i].valPromoNominal;
           }
           else if(this.productSales[i].valPromoPercentage!=0){
            //countPromo = (this.productSales[i].valPromoPercentage /100) * (this.productSales[i].price * this.productSales[i].qty);
            countPromo = this.productSales[i].nominalPromoPercentage;
           }
           countPromo = Math.floor(countPromo);
           totalPromo = totalPromo + countPromo;
            let strCountPromoDiff: string = '(' + this.formatRupiah(countPromo.toString()) + ')';
            let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);

          cmds += this.productSales[i].initialName;
          cmds += newLine;
          cmds += '            DISKON : ' + lblCountPromo;
          cmds += newLine;
        } else {
          // promo by total belanja
          if(this.productSales[i].valPromoNominal !== 0 || this.productSales[i].valPromoPercentage !== 0) {
           let countPromo: number = 0;
           if(this.productSales[i].valPromoNominal!=0){
            countPromo = this.productSales[i].valPromoNominal;
           }
           else if(this.productSales[i].valPromoPercentage!=0){
            //countPromo = (this.productSales[i].valPromoPercentage /100) * (this.productSales[i].price * this.productSales[i].qty);
            countPromo = this.productSales[i].nominalPromoPercentage;
           }
           countPromo = Math.floor(countPromo);
           totalPromo = totalPromo + countPromo;
            let strCountPromoDiff: string = '(' + this.formatRupiah(countPromo.toString()) + ')';
            let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);

            cmds += this.productSales[i].initialName;
            cmds += newLine;
            cmds += '            DISKON : ' + lblCountPromo;
            cmds += newLine;
          }
        }
        
      }

      if(this.productSales[i].typePromo === 'FREE ITEM (ITEM)' || this.productSales[i].typePromo === 'FREE ITEM (NOMINAL)') {
       // totalPromo = totalPromo + (this.productSales[i].priceAfterPromo * this.productSales[i].qtyFree);
      }
      if(this.productSales[i].qtyFree>0 ) {
       totalPromo = totalPromo + (this.productSales[i].price * this.productSales[i].qtyFree);
      }
    }
    //End Detail Promo Product

    cmds += '---------------------------------';
    let lblTotalPayment: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPayment.toString()));
    let lblTotalItem: String = this.getLabelTotalItem(this.totalItem);
    cmds += '' + lblTotalItem + lblTotalPayment;
    cmds += newLine;
    let lblTotalCustomerCash: String = this.getLabelPrintNominal(this.formatRupiah(this.totalCustomerCash.toString()));
    cmds += '             TUNAI : ' + lblTotalCustomerCash;
    cmds += newLine;

    //Start transfer
    if(this.customerTransfer !== 0) {
      let lblCustomerTransfer: String = this.getLabelPrintNominal(this.formatRupiah(this.customerTransfer.toString()));
      cmds += '          TRANSFER : ' + lblCustomerTransfer;
      cmds += newLine;
    }

    //Start Voucher
    if(this.voucherValue !== 0) {
      let lblVoucherValue: String = this.getLabelPrintNominal(this.formatRupiah(this.voucherValue.toString()));
      cmds += '           VOUCHER : ' + lblVoucherValue;
      cmds += newLine;
    }
    //End Voucher

    //Start Penggunaan Point
    if(this.pointMutationType === 'O') {
      let lblPointValue: String = this.getLabelPrintNominal(this.formatRupiah(this.memberPointValue.toString()));
      cmds += '             POINT : ' + lblPointValue;
      cmds += newLine
    }
    //End Penggunaan Point

    //Start Pembayaran Non Tunai
    if(this.customerCredit !== 0 && this.customerCredit !== null) {
      let lblCustomerCredit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerCredit.toString()));
      cmds += '            KREDIT : ' + lblCustomerCredit;
      cmds += newLine;
    }
    if(this.customerDebit !== 0 && this.customerDebit !== null) {
      let lblCustomerDebit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerDebit.toString()));
      cmds += '             DEBIT : ' + lblCustomerDebit;
      cmds += newLine;
    }
    //End Pembayaran Non Tunai

    let lblTotalChange: String = this.getLabelPrintNominal(this.formatRupiah(this.totalChange.toString()));
    cmds += '        KEMBALI RP : ' + lblTotalChange;
    cmds += newLine;

    //Start detail Member Point

    let lblTotalPromo: String = this.getLabelPrintNominal(this.formatRupiah(totalPromo.toString()));
    cmds += '        ANDA HEMAT : ' + lblTotalPromo;
    cmds += newLine;
    
    if(this.memberPhone !== '') {
      cmds += '---------------------------------';
      cmds += newLine;
      cmds += 'MEMBER HERBORIST  : ' + this.memberPhone;
      cmds += newLine;
      cmds += 'POINT DITAMBAHKAN : ' + this.totalPointAdd;
      cmds += newLine;
      cmds += 'POINT TERPAKAI    : ' + this.memberPointUsed;
      cmds += newLine;
    }
    //End detail Member Point
    cmds += newLine;
    cmds += ' BARANG YANG SUDAH DIBELI TIDAK ';
    cmds += newLine;
    cmds += ' DAPAT DITUKAR ATAU DIKEMBALIKAN ';
    cmds += newLine;
    cmds += newLine;
    cmds += '           TERIMA KASIH';
    cmds += newLine;
    cmds += newLine;
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
    cmds += printPhone;
    cmds += newLine;
    cmds += cut;

    cpj.printerCommands = cmds;
    
    //Send print job to printer!
    console.log(cmds);
    cpj.sendToClient();
    this.saveTransactionTemp();
    this.clearTransaction();

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      //this.getTodayPromotion('after_trans');
      this.getVoucherList();
    });
  }

  rePrintReceipt() {
    if(this.productSalesTemp.length > 0) {
      let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
      let convertDate = this.utilService.convertDate(transactionDate);

      let monthName: any = this.monthList[(parseInt(convertDate.months)-1)];
      let transDate = convertDate.dates + '-' + monthName + '-' + convertDate.years;
      let transTime = this.transactionTimeTemp;

      let totalPromo: number = 0;
      let cashierName: string = this.userProfile.name;

      //Create a ClientPrintJob
      const cpj = new JSPM.ClientPrintJob();

      //Set Printer type (Refer to the help, there many of them!)
      if ( this.isDefaultPrinterSelected ) {
        cpj.clientPrinter = new JSPM.DefaultPrinter();
      } else {
        cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
      }

      //Set content to print...
      //Create ESP/POS commands for sample label
      let esc = '\x1B'; //ESC byte in hex notation
      let newLine = '\x0A'; //LF byte in hex notation
      let cut = "\x1b" + "\x69";
      let cmds = esc + "@"; //Initializes the printer (ESC @)
      cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
      cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
      cmds += '---------------------------------';
      cmds += 'NO STRUK : ' + this.receiptNo;
      cmds += newLine;
      cmds += 'TANGGAL  : ' + transDate + ' ' + transTime;
      cmds += newLine;
      cmds += 'KASIR    : ' + cashierName.toUpperCase();
      cmds += newLine;
      cmds += '---------------------------------';
      cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)

      //Start List Barang Belanja
      for(let i = 0; i < this.productSalesTemp.length; i++) {
        if(this.productSalesTemp[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSalesTemp[i].typePromo !== 'FREE ITEM (NOMINAL)') {
          let priceProduct: number = parseInt(this.productSalesTemp[i].price);
          let qtyBuy: number = parseInt(this.productSalesTemp[i].qty);
          let nettProduct: number = priceProduct * qtyBuy;
  
          let qtyFree: number = parseInt(this.productSalesTemp[i].qtyFree);
          let nettFree: number = priceProduct * qtyFree;
  
          if(this.productSalesTemp[i].qtyFree > 0) {
            if(qtyBuy > 0){            
              let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
              let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
              cmds += this.productSalesTemp[i].initialName;
              cmds += newLine;
              cmds += lblPriceXQty.toString() + lblNettProduct.toString();
              cmds += newLine;
            }
            
            let lblPriceXQtyFree: String = 'FREE ' + qtyFree + ' x ' + this.formatRupiah(priceProduct.toString());
            let lblNettFree: String = this.getLblNett('('+this.formatRupiah(nettFree.toString())+')', lblPriceXQtyFree.length);
            cmds += this.productSalesTemp[i].initialName;
            cmds += newLine;
            cmds += lblPriceXQtyFree.toString() + lblNettFree.toString();
            cmds += newLine;
          } else {
            if(qtyBuy > 0){
              let lblPriceXQty: String = qtyBuy + ' x ' + this.formatRupiah(priceProduct.toString());
              let lblNettProduct: String = this.getLblNett(this.formatRupiah(nettProduct.toString()), lblPriceXQty.length);
              cmds += this.productSalesTemp[i].initialName;
              cmds += newLine;
              cmds += lblPriceXQty.toString() + lblNettProduct.toString();
              cmds += newLine;
            }
          }
        }

        if(this.productSalesTemp[i].typePromo === 'FREE ITEM (ITEM)' || this.productSalesTemp[i].typePromo === 'FREE ITEM (NOMINAL)') {
          let price: number = parseInt(this.productSalesTemp[i].priceAfterPromo);
          let qty: number = parseInt(this.productSalesTemp[i].qtyFree);
          let nett: number = price * qty;
          let lblPriceAndQty: String = 'FREE ' + qty + ' x ' + this.formatRupiah(price.toString());
          let lblNett: String = this.getLblNett('('+this.formatRupiah(nett.toString())+')', lblPriceAndQty.length);
          cmds += this.productSalesTemp[i].initialName;
          cmds += newLine;
          cmds += lblPriceAndQty.toString() + lblNett.toString();
          cmds += newLine;
        }
      }
      //End List Barang Belanja
      cmds += '---------------------------------';
      let lblPaymentWithoutPromo: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPaymentWithoutPromo.toString()));
      cmds += '        HARGA JUAL : ' + lblPaymentWithoutPromo;
      cmds += newLine;
      
    if(this.transactionDiscountTemp>0){
     /* let countPromoDiff: number = this.transactionDiscountTemp;
      let strCountPromoDiff: string = '(' + this.formatRupiah(countPromoDiff.toString()) + ')';
      let lblDiskonByNominal: String = this.getLabelPrintNominal(strCountPromoDiff);    
      cmds += '        Diskon :      ' + lblDiskonByNominal;
      cmds += newLine;
      totalPromo = this.transactionDiscountTemp;*/
    }

      //Start Detail Promo Product
      for(let i = 0; i < this.productSalesTemp.length; i++) {
        if(this.productSalesTemp[i].typePromo !== 'FREE ITEM (ITEM)' && this.productSalesTemp[i].typePromo !== 'FREE ITEM (NOMINAL)') {
          if(this.productSalesTemp[i].typePromo === 'NOMINAL' || this.productSalesTemp[i].typePromo === 'PERSENTASE') {
            let countPromoDiff: number = (this.productSalesTemp[i].price * this.productSalesTemp[i].qty) - (this.productSalesTemp[i].priceAfterPromo * this.productSalesTemp[i].qty);
            totalPromo = totalPromo + countPromoDiff;
            let strCountPromoDiff: string = '(' + this.formatRupiah(countPromoDiff.toString()) + ')';
            let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);

            cmds += this.productSalesTemp[i].initialName;
            cmds += newLine;
            cmds += '            DISKON : ' + lblCountPromo;
            cmds += newLine;
          } else {
            if(this.productSalesTemp[i].valPromoNominal !== 0 || this.productSalesTemp[i].valPromoPercentage !== 0) {
              let countPromo: number = ((this.productSalesTemp[i].price * this.productSalesTemp[i].qty) * (this.productSalesTemp[i].valPromoPercentage / 100)) + this.productSalesTemp[i].valPromoNominal;
              totalPromo = totalPromo + countPromo;
              let strCountPromoDiff: string = '(' + this.formatRupiah(countPromo.toString()) + ')';
              let lblCountPromo: String = this.getLabelPrintNominal(strCountPromoDiff);
  
              cmds += this.productSalesTemp[i].initialName;
              cmds += newLine;
              cmds += '            DISKON : ' + lblCountPromo;
              cmds += newLine;
            }
          }
          
        }
  
        if(this.productSalesTemp[i].typePromo === 'FREE ITEM (ITEM)' || this.productSalesTemp[i].typePromo === 'FREE ITEM (NOMINAL)') {
         // totalPromo = totalPromo + (this.productSalesTemp[i].priceAfterPromo * this.productSalesTemp[i].qtyFree);
        }
        
        if(this.productSalesTemp[i].qtyFree>0 ) {
         totalPromo = totalPromo + (this.productSalesTemp[i].price * this.productSalesTemp[i].qtyFree);
        }
      }
      //End Detail Promo Product

      cmds += '---------------------------------';
      let lblTotalPayment: String = this.getLabelPrintNominal(this.formatRupiah(this.totalPaymentTemp.toString()));
      let lblTotalItem: String = this.getLabelTotalItem(this.totalItemTemp);
      cmds += '' + lblTotalItem + lblTotalPayment;
      cmds += newLine;
      let lblTotalCustomerCash: String = this.getLabelPrintNominal(this.formatRupiah(this.totalCustomerCashTemp.toString()));
      cmds += '             TUNAI : ' + lblTotalCustomerCash;
      cmds += newLine;

      //Start Voucher
      if(this.voucherValueTemp !== 0) {
        let lblVoucherValue: String = this.getLabelPrintNominal(this.formatRupiah(this.voucherValueTemp.toString()));
        cmds += '           VOUCHER : ' + lblVoucherValue;
        cmds += newLine;
      }
      //End Voucher

      //Start Penggunaan Point
      if(this.pointMutationTypeTemp === 'O') {
        let lblPointValue: String = this.getLabelPrintNominal(this.formatRupiah(this.memberPointValueTemp.toString()));
        cmds += '             POINT : ' + lblPointValue;
        cmds += newLine
      }
      //End Penggunaan Point

      //Start Pembayaran Non Tunai
      if(this.customerCreditTemp !== 0 && this.customerCreditTemp !== null) {
        let lblCustomerCredit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerCreditTemp.toString()));
        cmds += '            KREDIT : ' + lblCustomerCredit;
        cmds += newLine;
      }
      if(this.customerDebitTemp !== 0 && this.customerDebitTemp !== null) {
        let lblCustomerDebit: String = this.getLabelPrintNominal(this.formatRupiah(this.customerDebitTemp.toString()));
        cmds += '             DEBIT : ' + lblCustomerDebit;
        cmds += newLine;
      }
      //End Pembayaran Non Tunai

      let lblTotalChange: String = this.getLabelPrintNominal(this.formatRupiah(this.totalChangeTemp.toString()));
      cmds += '        KEMBALI RP : ' + lblTotalChange;
      cmds += newLine;

      //Start detail Member Point

      let lblTotalPromo: String = this.getLabelPrintNominal(this.formatRupiah(totalPromo.toString()));
      cmds += '        ANDA HEMAT : ' + lblTotalPromo;
      cmds += newLine;
      
      if(this.memberPhoneTemp !== '') {
        cmds += '---------------------------------';
        cmds += newLine;
        cmds += 'MEMBER HERBORIST  : ' + this.memberPhoneTemp;
        cmds += newLine;
        cmds += 'POINT DITAMBAHKAN : ' + this.totalPointAddTemp;
        cmds += newLine;
        cmds += 'POINT TERPAKAI    : ' + this.memberPointUsedTemp;
        cmds += newLine;
      }
      //End detail Member Point

      cmds += newLine;
      cmds += ' BARANG YANG SUDAH DIBELI TIDAK ';
      cmds += newLine;
      cmds += ' DAPAT DITUKAR ATAU DIKEMBALIKAN ';
      cmds += newLine;
      cmds += newLine;
      cmds += '           TERIMA KASIH';
      cmds += newLine;
      cmds += newLine;
      cmds += newLine;
      cmds += '         OEMAH HERBORIST';
      cmds += newLine;
      cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
      cmds += newLine;
      cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
      cmds += newLine;
      cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
      cmds += newLine;
      let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
      cmds += printPhone;
      cmds += newLine;
      cmds += cut;

      cpj.printerCommands = cmds;
      
      //Send print job to printer!
      console.log(cmds);
      cpj.sendToClient();
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    } else {
      this.toastCtrl.create({ duration: 2000, message: 'Tidak terdapat struk transaksi sebelumnya!' }).then(t => t.present());
    }
  }

  setPaperPrintReceipt() {
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
    cmds += printPhone;
    cmds += newLine; 
    cmds += cut;
    cpj.printerCommands = cmds;
    //Send print job to printer!
    cpj.sendToClient();
  }

  getProduct(productBarcode: any) {
    if(productBarcode !== '' && productBarcode !== undefined) {
      let barcodetoString: string = productBarcode.toString();
       barcodetoString = barcodetoString.replace(' ', '').toString();
      let dataInput = barcodetoString.split('*');
      let dataInput2 = barcodetoString.split('_');
      let inputQty: number = 0;
      let barcode: string;
      if(dataInput.length > 1) {
        inputQty = parseInt(dataInput[0]);
        barcode = dataInput[1];
      } else {
        inputQty = 1;
        barcode = dataInput[0];
      }
      if(dataInput2.length > 1) {
        barcode = dataInput2[0];
      }
      let promoType: any = null;
      let freeQty: any = 0;
      let promoWarehouseId: any = 0;
      let idPromotionDetail: any = 0;
      let priceProduct: any = 0;
      let nettPriceAfterPromo: any = 0;
      let latestProductScan: any;
      let checkItemBarcode: any[] = this.productSales.find(x => x.barcode_view.toString().toUpperCase() === barcode.toString().toUpperCase() && x.barcode!='0');
      let checkItemPcode: any[] = this.productSales.find(x => x.id.toString().toUpperCase() === barcode.toString().toUpperCase() );
      // jika barang sudah ter-scan sebelumnya
      if (checkItemBarcode !== undefined || checkItemPcode !== undefined) {
        let checkIndex: any;
        if(checkItemBarcode !== undefined) { checkIndex = checkItemBarcode; }
        if(checkItemPcode !== undefined) { checkIndex = checkItemPcode; }
        let index: number = this.productSales.indexOf(checkIndex);
        let price: number = this.productSales[index]['price'];
        let qty_withoutpromo: number = this.productSales[index]['qty_withoutpromo'];
        let updateQty: number = qty_withoutpromo + inputQty;
        if(updateQty < 0){
          updateQty=0;
        }
        let updateNett: number = nettPriceAfterPromo !== 0 ? nettPriceAfterPromo : price * updateQty;
        if(updateQty === 0) {
          this.productSales[index]['qty'] =  updateQty;
          this.productSales[index]['qty_withoutpromo'] =  updateQty;
          this.productSales[index]['nett'] =  updateNett;
          this.checkPromoFreeItemByItem(this.productSales[index]);
          this.checkPromoFreeItemByNominalProduct(this.productSales[index]);
          this.checkPromoDiskonBertingkat(this.productSales[index]);
          this.checkPromoFreeItemAndNominal(this.productSales[index]);
        } else {
          let product_id=this.productSales[index]['id'];
          let product_name= this.productSales[index]['name'];
          let checkStock:any = this.regulerStock.find(x => x.product_id === product_id);
          let listPackage =this.mstProductPackageList.find(x => x.id === barcode.toUpperCase());
          if(listPackage){// kalau paket ga cek stok
            this.productSales[index]['qty'] =  updateQty;
            this.productSales[index]['qty_withoutpromo'] =  updateQty;
            this.productSales[index]['nett'] =  updateNett;
          }
          else{
            let stock:number=0;
            if (checkStock) {
              stock = checkStock.stock;
            }
            if(updateQty > stock){
              let message = 'Stock '+product_name+' tidak cukup.\nStock: '+stock+'';
              this.showAlertBarcodeNotValid(message);
            }else{ 
              this.productSales[index]['qty'] =  updateQty;
              this.productSales[index]['qty_withoutpromo'] =  updateQty;
              this.productSales[index]['nett'] =  updateNett;
            }
          }
        }
        latestProductScan = this.productSales[index];
        this.checkPromoNominalBySelectedProduct(latestProductScan);
        this.checkPromoPercentBySelectedProduct(latestProductScan);
        this.checkPromoFreeItemByItem(latestProductScan);
        this.checkPromoDiskonBertingkat(latestProductScan);
        this.checkPromoFreeItemByNominalProduct(latestProductScan);
        this.checkPromoFreeItemAndNominal(latestProductScan);
        this.calculateDiscount();
      } else {
        if (this.mstBarcodeList[barcode] !== undefined) {
          // ini kalau yg diinput barcode
          if(inputQty <= 0){
            inputQty=0;            
            let message = 'Minimal Input 1';
            this.showAlertBarcodeNotValid(message);
          }else{
            let product_id=this.mstBarcodeList[barcode]['id'];
            let product_name= this.mstBarcodeList[barcode]['product_name'];
            let checkStock:any = this.regulerStock.find(x => x.product_id === product_id);
            let stock:number=0;
            if (checkStock) {
              stock = checkStock.stock;
            }
            if(inputQty > stock){    
              let message = 'Stock '+product_name+' tidak cukup.\nStock: '+stock+'';
              this.showAlertBarcodeNotValid(message);
            }else{ 
              priceProduct = this.mstBarcodeList[barcode]['price'];
              let productScanData = {
                barcode_view: this.mstBarcodeList[barcode]['barcode'],
                id: product_id,
                barcode: this.mstBarcodeList[barcode]['barcode'],
                name:product_name,
                initialName: this.mstBarcodeList[barcode]['initial_name'],
                qty: inputQty,
                qty_withoutpromo:inputQty,
                price: this.mstBarcodeList[barcode]['price'],
                priceAfterPromo: priceProduct,
                nett: nettPriceAfterPromo !== 0 ? nettPriceAfterPromo : priceProduct * inputQty,
                typePromo: promoType,
                valPromoPercentage: 0,
                valPromoNominal: 0,
                nominalPromoPercentage:0,
                qtyFree: freeQty,
                multipleFlag: 0,
                warehouseIdPromo: promoWarehouseId,
                promotionFreeItemId: 0,
                promotionFreeItemByNominalId :0,
                getFree : 0,
                promo : '',
                promoNominalBertingkat:'',
                promoPersenBertingkat:'',
                promoFreeBertingkat:'',
                arrayPromoFreeItem : [],
                promo_free_item:[],
                arr_id_promo :[],
                arr_promo_tingkat :[],
                arr_promo_nominal :[],
                arr_kontribusi :[],
                promoAuto:'Y'
              };

              this.productSales.push(productScanData);
              latestProductScan = productScanData;
              
              this.checkPromoNominalBySelectedProduct(latestProductScan);
              this.checkPromoPercentBySelectedProduct(latestProductScan);
              this.checkPromoFreeItemByItem(latestProductScan);
              this.checkPromoDiskonBertingkat(latestProductScan);
              this.checkPromoFreeItemByNominalProduct(latestProductScan);
              this.checkPromoFreeItemAndNominal(latestProductScan);
              this.calculateDiscount();
            }
          }

        } else if (this.mstProductList[parseInt(barcode)] !== undefined) {
          // ini kalau yg diinput pakai pcode
          if(inputQty <= 0){
            inputQty=0;            
            let message = 'Minimal Input 1';
            this.showAlertBarcodeNotValid(message);
          }else{
            let id: any = parseInt(barcode);
            barcode = id;
            priceProduct = this.mstProductList[barcode]['price'];
            let product_id=this.mstProductList[barcode]['id'];
            let product_name= this.mstProductList[barcode]['product_name'];
            let checkStock:any = this.regulerStock.find(x => x.product_id === product_id);
            let stock:number=0;
            if (checkStock) {
              stock = checkStock.stock;
            }
            if(inputQty > stock){    
              let message = 'Stock '+product_name+' tidak cukup.\nStock: '+stock+'';
              this.showAlertBarcodeNotValid(message);
            }else{ 
              let productScanData = {
                barcode_view: parseInt(this.mstProductList[barcode]['id']),
                id: product_id,
                barcode: this.mstProductList[barcode]['barcode'],
                name: product_name,
                initialName: this.mstProductList[barcode]['initial_name'],
                qty: inputQty,
                qty_withoutpromo:inputQty,
                price: this.mstProductList[barcode]['price'],
                priceAfterPromo: priceProduct,
                nett: nettPriceAfterPromo !== 0 ? nettPriceAfterPromo : priceProduct * inputQty,
                typePromo: promoType,
                valPromoPercentage: 0,
                valPromoNominal: 0,
                nominalPromoPercentage:0,
                qtyFree: freeQty,
                multipleFlag: 0,
                warehouseIdPromo: promoWarehouseId,
                promotionFreeItemId: 0,
                promotionFreeItemByNominalId :0,
                getFree :0,
                promo: '',
                promoNominalBertingkat:'',
                promoPersenBertingkat:'',
                promoFreeBertingkat:'',
                arrayPromoFreeItem : [],
                promo_free_item:[],
                arr_id_promo :[],
                arr_promo_tingkat :[],
                arr_promo_nominal :[],
                arr_kontribusi :[],
                promoAuto:'Y'
              };
              this.productSales.push(productScanData);
              latestProductScan = productScanData;
              this.checkPromoNominalBySelectedProduct(latestProductScan);
              this.checkPromoPercentBySelectedProduct(latestProductScan);
              this.checkPromoFreeItemByItem(latestProductScan);
              this.checkPromoFreeItemByNominalProduct(latestProductScan);
              this.checkPromoDiskonBertingkat(latestProductScan);
              this.checkPromoFreeItemAndNominal(latestProductScan);
              this.calculateDiscount();
            }
          }
        } else {
          let listPackage =this.mstProductPackageList.find(x => x.id === barcode.toUpperCase());
          console.log(this.mstProductPackageList);
          if(listPackage){    
            if(inputQty <= 0){
              inputQty=0;            
              let message = 'Minimal Input 1';
              this.showAlertBarcodeNotValid(message);
            }else{        
              priceProduct = listPackage.price;     
              let productScanData = {
                barcode_view: (listPackage.id),
                id: listPackage.id,
                barcode: listPackage.barcode,
                name:listPackage.product_name,
                initialName: listPackage.initial_name,
                qty: inputQty,
                qty_withoutpromo:inputQty,
                price: listPackage.price,
                priceAfterPromo: priceProduct,
                nett: nettPriceAfterPromo !== 0 ? nettPriceAfterPromo : priceProduct * inputQty,
                typePromo: promoType,
                valPromoPercentage: 0,
                valPromoNominal: 0,
                nominalPromoPercentage:0,
                qtyFree: freeQty,
                multipleFlag:  0,
                warehouseIdPromo: promoWarehouseId,
                promotionFreeItemId: 0,
                promotionFreeItemByNominalId :0,
                getFree :0,
                promo: '',
                promoNominalBertingkat:'',
                promoPersenBertingkat:'',
                promoFreeBertingkat:'',
                arrayPromoFreeItem : [],
                promo_free_item:[],
                arr_id_promo :[],
                arr_promo_tingkat :[],
                arr_promo_nominal :[],
                arr_kontribusi :[],
                promoAuto:'Y'
              };

              this.productSales.push(productScanData);
              latestProductScan = productScanData;
              this.checkPromoNominalBySelectedProduct(latestProductScan);
              this.checkPromoPercentBySelectedProduct(latestProductScan);
              this.checkPromoFreeItemByItem(latestProductScan);
              this.checkPromoFreeItemByNominalProduct(latestProductScan);
              this.checkPromoDiskonBertingkat(latestProductScan);
              this.checkPromoFreeItemAndNominal(latestProductScan);
              this.calculateDiscount();
            }
          }
          else{
          let message = 'Barcode tidak valid,\ncoba gunakan kode produk.\nAtau belum di bikin di group harga';
          this.showAlertBarcodeNotValid(message);
          }
        }
      }
      this.productBarcode = "";
      this.productId = "";
      this.calcTotal();
      this.checkPromoFreeProductByNominal();
      this.getChangeNominal();
      this.updateFreeItem();
      this.calcTotalItem();
      if(this.productSales.length>0){
        this.scrollDownEnd();
      }
      this.updateStorage();
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    } else {
      let message = 'Field Barcode kosong, silakan isi dengan kode Barcode / kode produk terlebih dahulu.'
      this.showAlertBarcodeNotValid(message);
      (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
    }
  }

  checkPromoFreeProductByNominal() {
    // CHECK PROMO FREE ITEM (NOMINAL) - TOTAL BELANJA
    let promoFreeItemNominal =this.promotionDetailList.find(x => x.promotion_type_name === 'FREE ITEM (NOMINAL)' && x.product_id === null && x.promo_based_on == 1);
    let resetFreeByNominal = 0;
    let detailPromoFreeItemNominal ='';
    if(promoFreeItemNominal){
      detailPromoFreeItemNominal = 'Ada promo free item (nominal).';
      let minimum = promoFreeItemNominal.minimum_transaction;
      let berlakuKelipatan = promoFreeItemNominal.multiple_flag;
      let free = promoFreeItemNominal.free_qty;
      let idpromodetail = promoFreeItemNominal.id;
      let idpromo = promoFreeItemNominal.promotion_id;
      let getDiscount = false;
      if (this.totalPayment < parseInt(minimum)){
        detailPromoFreeItemNominal+='Belum memenuhi minimum belanja.';
        resetFreeByNominal =1;
      }
      else{
        detailPromoFreeItemNominal += 'Sudah memenuhi minimum belanja.';
        if(berlakuKelipatan=='1'){
          detailPromoFreeItemNominal += 'Berlaku kelipatan.';
          free = free * Math.floor(this.totalPayment/parseInt(minimum));
        }
        else{
          detailPromoFreeItemNominal += 'Tidak belaku kelipatan.';
        }
        detailPromoFreeItemNominal += 'Mendapat free item:';
        getDiscount=true;
        this.promoItemNominal = promoFreeItemNominal;
      }
      //cek produk yg dibutuhkan
      let syaratTerpenuhi =this.cekSyarat(promoFreeItemNominal)
      if(getDiscount && syaratTerpenuhi=='Y'){
        detailPromoFreeItemNominal+='sudah memenuhi syarat';
        this.countFreeItem = free;
        let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
        if(checkArray){
          let index = this.arrayFreeItemUsed.indexOf(checkArray);
          this.arrayFreeItemUsed[index]['free_item_max']=free;
          if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
            resetFreeByNominal = 1;
            this.arrayFreeItemUsed[index]['free_item_used'] = 0;
            for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
              this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
            }
          }
          this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
          if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
            this.message_free = " Get Free Item";
          }
        }
        else{
          this.message_free = " Get Free Item";
          this.arrayFreeItemUsed.push({
            product_id:0,
            promo_id: idpromo,
            promo_id_detail: idpromodetail,
            free_item_max: free,
            free_item_remaining: free,
            free_item_used: 0,
            array_item:[]
          });
        }
        this.listFreeItemByTotalBelanja = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
        let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
        if(cari===undefined){
          this.promoFreeItem.push({
            'id':idpromodetail,
            'promo':promoFreeItemNominal,
            'countFreeItem':free,
            'list_free_item':this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
          })
        }
        else{
          cari.countFreeItem=free;
        }
        if(resetFreeByNominal==1 ){
          for(let a=0;a < this.listFreeItemByTotalBelanja.length;a++){
            let searchFreeProductByNominal = this.productSales.filter(x => x.id == this.listFreeItemByTotalBelanja[a]['product_id']);
            if(searchFreeProductByNominal){
              for(let a=0;a<searchFreeProductByNominal.length;a++){
                searchFreeProductByNominal[a]['qtyFree'] = 0;
              }
            }
          }
        }
      }
      else{
        detailPromoFreeItemNominal+='belum memenuhi syarat';
        this.countFreeItem = 0;
        let getProductWithPromo =this.productSales.filter(x=>x.arrayPromoFreeItem.length>0);
        if(getProductWithPromo.length>0){
          for(let p=0;p<getProductWithPromo.length;p++){
            let gp = getProductWithPromo[p].arrayPromoFreeItem;
            let diffqty =0;
            for(let gpp=0;gpp<gp.length;gpp++){
              if(gp[gpp].promotion_detail_id==idpromodetail){
                diffqty +=gp[gpp].qty;
                gp[gpp].qty =0;
              }
            }
            getProductWithPromo[p].qtyFree =  getProductWithPromo[p].qtyFree-diffqty;
          }
        }
        let getFreeItem =this.arrayFreeItemUsed.filter(x=>x.promo_id_detail==idpromodetail);
        if(getFreeItem.length>0){
          for(let p=0;p<getFreeItem.length;p++){
            getFreeItem[p].free_item_used=0;
            getFreeItem[p].array_item=[];
          }
        }
        let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
        if(cari!==undefined){
          let index = this.promoFreeItem.indexOf(cari);
          this.promoFreeItem.splice(index, 1);
        }
      }
    }
  // END OF PROMO FREE ITEM (NOMINAL);
  }

  checkPromoFreeItemByNominalProduct(latestProductSales:any) {
    let pcode = latestProductSales.id;
    let product_name = latestProductSales.name;
    // check jika ada promo free item by nominal produk
    let promoFreeItemNominalSelectedProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM (NOMINAL)' && x.promo_based_on == 2 && x.qty_type=='0');
    if(promoFreeItemNominalSelectedProduct.length > 0){
      let detailPromoFreeItemByNominalProduct = 'Ada promo free item (nominal).';
      for(let a =0; a < promoFreeItemNominalSelectedProduct.length;a++){
        let idpromodetail = promoFreeItemNominalSelectedProduct[a].id;
        let idpromo = promoFreeItemNominalSelectedProduct[a].promotion_id;
        let minimum = promoFreeItemNominalSelectedProduct[a].minimum_transaction;
        let multipleFlag = promoFreeItemNominalSelectedProduct[a].multiple_flag;
        let free = promoFreeItemNominalSelectedProduct[a].free_qty;
        let productId = promoFreeItemNominalSelectedProduct[a].product_id;
        let free_item_type = promoFreeItemNominalSelectedProduct[a].free_item_type;
        let maximum = promoFreeItemNominalSelectedProduct[a].maximum_free_item;
        let arrProduct = [];
        if(productId!=null){
          arrProduct= productId.split(';');
        }
        let checkProduct =arrProduct.find(x => x==pcode);
        if((checkProduct != undefined && productId !== null) || productId===null){
          if(latestProductSales.nett < parseInt(minimum)){
            detailPromoFreeItemByNominalProduct+='Belum memenuhi minimum belanja.';
            free = 0;
          }
          else{
            detailPromoFreeItemByNominalProduct += 'Sudah memenuhi minimum belanja.';
            if(multipleFlag=='1'){
              detailPromoFreeItemByNominalProduct += 'Berlaku kelipatan.';
              free = free * Math.floor(latestProductSales.nett/parseInt(minimum));
            }
            else{
              detailPromoFreeItemByNominalProduct += 'Tidak belaku kelipatan';
            };
            detailPromoFreeItemByNominalProduct+='\nMaksimum hadiah :'+maximum;
            if(maximum>0 && free> maximum){
              free = maximum;
            }
          }
          // array free item //
          let countFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === pcode && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
          let freeItemRemaining = 0;
          if(countFreeItem) {
            let index = this.arrayFreeItemUsed.indexOf(countFreeItem);
            this.arrayFreeItemUsed[index]['free_item_max'] = free;
            if(free==0){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            let used = this.arrayFreeItemUsed[index]['free_item_used'];
            freeItemRemaining = parseInt(free)-parseInt(used);
            this.arrayFreeItemUsed[index]['free_item_remaining'] =freeItemRemaining;
          }
          else{
            freeItemRemaining = free;
            this.arrayFreeItemUsed.push({
              product_id:pcode,
              promo_id: idpromo,
              promo_id_detail: idpromodetail,
              free_item_max: free,
              free_item_remaining: free,
              free_item_used: 0,
              array_item:[]
            });
          }
          if(freeItemRemaining==0){
            free = 0;
          }
          //end of array free item
          detailPromoFreeItemByNominalProduct += 'Mendapat free '+free+' item';
          latestProductSales.getFreeTemp = free;
          latestProductSales.getDiscount=true;
          latestProductSales.promo= promoFreeItemNominalSelectedProduct;
          let free_item_list=[];
          if(free_item_type==1){
            let promotionFreeItemSameAsProductScan: any[] = [];
            promotionFreeItemSameAsProductScan.push({
              'id' :idpromo,
              'product_id': pcode,
              'product_name':product_name,
              'promotion_detail_id': idpromodetail
            });
            free_item_list = promotionFreeItemSameAsProductScan;
            latestProductSales.free_item_list = promotionFreeItemSameAsProductScan;
          }
          else{
            free_item_list =this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);  
            latestProductSales.free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);        
          }
          let caripromo1 = this.productSales.find(x=>x.id==pcode && x.promo_free_item.length>0);
          if(caripromo1!==undefined){
            let promolength=[];
            promolength = caripromo1.promo_free_item;
            let caripromo =promolength.find(x=>x.id==idpromo);
            if(caripromo==undefined){
              latestProductSales.promo_free_item.push({
                  'id':idpromo,
                  'promo':promoFreeItemNominalSelectedProduct[a],
                  'free_item_list':free_item_list,
                  'getFreeTemp':free,
                  'getFree':0
              });
            }
          }
          else{
            latestProductSales.promo_free_item.push({
              'id':idpromo,
              'promo':promoFreeItemNominalSelectedProduct[a],
              'free_item_list':free_item_list,
              'getFreeTemp':free,
              'getFree':0
            });
          }
          //cek produk yg dibutuhkan
          let syaratTerpenuhi =this.cekSyarat(promoFreeItemNominalSelectedProduct[a]);
          let getProductWithDiscount =this.productSales.filter(x => x.getDiscount==true);
          if(syaratTerpenuhi=='Y'){
            detailPromoFreeItemByNominalProduct+=('\nsudah memenuhi syarat yg dibutuhkan');
            if(getProductWithDiscount.length>0){
              for(let p=0;p<getProductWithDiscount.length;p++){
                let getFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === getProductWithDiscount[p].id && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
                let diskon =getProductWithDiscount[p].getFreeTemp;
                if(getFreeItem){
                  getFreeItem.syarat_terpenuhi='Y';
                  if(getFreeItem.free_item_remaining==0){
                    diskon=0;
                  }
                }
                getProductWithDiscount[p].inputDiskon = true;
                let caripromo1 = this.productSales.find(x=>x.id==getProductWithDiscount[p].id && x.promo_free_item.length>0);
                if(caripromo1!==undefined){
                  let promolength=[];
                  promolength = caripromo1.promo_free_item;
                  let caripromo =promolength.find(x=>x.promo==promoFreeItemNominalSelectedProduct[a]);
                  if(caripromo!==undefined){
                    caripromo.getFree=diskon;
                  }
                }
              }
            }
          }
          else{
            detailPromoFreeItemByNominalProduct+='\nbelum memenuhi syarat yg dibutuhkan';
            if(getProductWithDiscount.length>0){
              for(let p=0;p<getProductWithDiscount.length;p++){
                let getFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === getProductWithDiscount[p].id && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
                if(getFreeItem){
                  getFreeItem.syarat_terpenuhi='N';
                  getFreeItem.free_item_remaining=getProductWithDiscount[p].getFreeTemp;
                  getFreeItem.free_item_used=0;
                }
                if( getProductWithDiscount[p].inputDiskon){// jika ada produk yg sudah dapat diskon, kemudian produk yg menjadi syarat batal dibeli maka batalkan diskonnya
                  getProductWithDiscount[p].inputDiskon = false;
                }
                let caripromo1 = this.productSales.find(x=>x.id==getProductWithDiscount[p].id && x.promo_free_item.length>0);
                if(caripromo1!==undefined){
                let promolength=[];
                  promolength = caripromo1.promo_free_item;
                  let caripromo =promolength.find(x=>x.promo==promoFreeItemNominalSelectedProduct[a]);
                  if(caripromo!==undefined){
                    caripromo.getFree=0;
                  }
                }
              }
            }
          }
        }
      }
      this.arraySumNominal = [];
      for(let a=0;a<this.arrayFreeItemUsed.length;a++){
        let lengthFreeItem = this.arrayFreeItemUsed[a]['array_item'].length;
        if (lengthFreeItem > 0){
          for(let b=0;b<lengthFreeItem;b++){ 
            this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']= this.arrayFreeItemUsed[a]['free_item_used'];  
            let checkProduct =this.arraySumNominal.find(x => x.product_id==this.arrayFreeItemUsed[a]['array_item'][b]['product_id']);
            if(checkProduct){
              if(this.arrayFreeItemUsed[a]['syarat_terpenuhi']=='N'){
                this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']=0;
                checkProduct.qty=0;
              }
              else{
                checkProduct.qty =this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
              }
            }
            else{
              let qty =this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
              if(this.arrayFreeItemUsed[a]['syarat_terpenuhi']=='N'){
                qty=0;
              }
              this.arraySumNominal.push({
                'product_id' :this.arrayFreeItemUsed[a]['array_item'][b]['product_id'],
                'qty' :qty
              });
            }
          }
        }
      }
      if(this.arraySumNominal.length>0){
        for(let a=0;a<this.arraySumNominal.length;a++){
          let checkProduct =this.productSales.find(x => x.id==this.arraySumNominal[a]['product_id']);
          if(checkProduct){
            checkProduct.qtyFree = this.arraySumNominal[a]['qty'];
            for(let b=0;b<checkProduct.arrayPromoFreeItem.length;b++){
              if(checkProduct.arrayPromoFreeItem[b]['promotion_id']==checkProduct.promotionFreeItemId && 
                checkProduct.arrayPromoFreeItem[b]['product_scan']==pcode){
                checkProduct.arrayPromoFreeItem[b]['qty']=this.arraySumNominal[a]['qty'];
              }
            }
          }
        }
      }
     // console.log(detailPromoFreeItemByNominalProduct);
    }
  }
  checkPromoFreeItemAndNominal(latestProductSales:any) {
    let pcode = latestProductSales.id;
    let product_name = latestProductSales.name;

    // check jika ada promo free item and nominal produk by produk
    let promoFreeItemAndNominalByProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM AND NOMINAL' && (parseInt(x.price_type) == 1 || parseInt(x.price_type)==3 )&& x.promo_based_on == 3 && x.qty_type=='0');
    if(promoFreeItemAndNominalByProduct.length > 0){
      let detailPromoFreeItemAndNominalByProduct = 'Ada promo free item and nominal by product.';
      for(let a =0; a < promoFreeItemAndNominalByProduct.length;a++){
        let idpromodetail = promoFreeItemAndNominalByProduct[a].id;
        let idpromo = promoFreeItemAndNominalByProduct[a].promotion_id;
        let minimum = promoFreeItemAndNominalByProduct[a].minimum_buy;
        let multipleFlag = promoFreeItemAndNominalByProduct[a].multiple_flag;
        let free = promoFreeItemAndNominalByProduct[a].free_qty;
        let productId = promoFreeItemAndNominalByProduct[a].product_id;
        let free_item_type = promoFreeItemAndNominalByProduct[a].free_item_type;
        let maximum = promoFreeItemAndNominalByProduct[a].maximum_free_item;
        let diskon = promoFreeItemAndNominalByProduct[a].value;
        let max_discount = promoFreeItemAndNominalByProduct[a].max_discount;
        let price_type = promoFreeItemAndNominalByProduct[a].price_type;
        let arrProduct = [];
        if(productId!==null){
          arrProduct= productId.split(';');
        }
        let checkProduct =arrProduct.find(x => x==pcode);
        if(latestProductSales.arr_promo_nominal.length >0){
          let caripromo = latestProductSales.arr_promo_nominal.find(x=>x.idpromodetail==idpromodetail)
          if (caripromo==undefined){
            latestProductSales.arr_promo_nominal.push({
              idpromodetail:idpromodetail,
              idpromo:idpromo,
              mencapai_minimum:'N',
              memenuhi_syarat:'N',
              nominal_diskon:0,
              persen:0,
              diskon:0,
              promo_bertingkat:'N',
              persenvalue:0,
              qty_get_promo:0,
              add_discount_in_end:'N'
            })
          }
          else{
            caripromo.mencapai_minimum='N';
            caripromo.memenuhi_syarat='N';
            caripromo.nominal_diskon=0;
            caripromo.qty_get_promo=0;
            caripromo.add_discount_in_end='N';
          }
        }
        else{
          latestProductSales.arr_promo_nominal.push({
            idpromodetail:idpromodetail,
            idpromo:idpromo,
            mencapai_minimum:'N',
            memenuhi_syarat:'N',
            nominal_diskon:0,
            promo_bertingkat:'N',
            persen:0,
            diskon:0,
            persenvalue:0,
            qty_get_promo:0,
            add_discount_in_end:'N'
          })
        }
        if((checkProduct != undefined && productId !== null ) || productId === null){
          if(latestProductSales.qty_withoutpromo < parseInt(minimum)){
            detailPromoFreeItemAndNominalByProduct+='Belum memenuhi minimum pembelian.';
            free = 0;
          }
          else{
            detailPromoFreeItemAndNominalByProduct += 'Sudah memenuhi minimum pembelian.';
            if(price_type==3){
                diskon =Math.floor((diskon /100) * (latestProductSales.price * latestProductSales.qty_withoutpromo));
            }
            if(multipleFlag=='1'){
              detailPromoFreeItemAndNominalByProduct += 'Berlaku kelipatan.';
              free = free * Math.floor(latestProductSales.qty_withoutpromo/parseInt(minimum));
              
              if(minimum>0 && price_type!=3){             
                diskon = diskon * Math.floor(latestProductSales.qty_withoutpromo/minimum);
              }
            }
            else{
              detailPromoFreeItemAndNominalByProduct += 'Tidak berlaku kelipatan';
            };            
            if(maximum>0 && free> maximum){
              free = maximum;
            }
            if (max_discount >0  && diskon > parseInt(max_discount)){
              diskon = max_discount;
            }
            let thispromonominal = latestProductSales.arr_promo_nominal.find(x=>x.idpromo==idpromo && x.idpromodetail==idpromodetail);
            thispromonominal.mencapai_minimum='Y';
            thispromonominal.diskon=parseInt(diskon);
            thispromonominal.add_discount_in_end='Y';
          }

          // array free item //
          let countFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === pcode && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
          let freeItemRemaining = 0;
          if(countFreeItem) {
            let index = this.arrayFreeItemUsed.indexOf(countFreeItem);
            this.arrayFreeItemUsed[index]['free_item_max'] = free;
            if(free==0){            
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            let used = this.arrayFreeItemUsed[index]['free_item_used'];
            freeItemRemaining = parseInt(free)-parseInt(used);
            this.arrayFreeItemUsed[index]['free_item_remaining'] =freeItemRemaining;
          }
          else{
            freeItemRemaining = free;
            this.arrayFreeItemUsed.push({
              product_id:pcode,
              promo_id: idpromo,
              promo_id_detail: idpromodetail,
              free_item_max: free,
              free_item_remaining: free,
              free_item_used: 0,
              array_item:[]
            });
          }
          if(freeItemRemaining==0){
            free = 0;
          }
          //end of array free item
          let free_item_list =[];
          detailPromoFreeItemAndNominalByProduct += 'Mendapat free '+free+' item';
          detailPromoFreeItemAndNominalByProduct += '\nMendapat Diskon '+diskon;
          latestProductSales.getFreeTemp = free;
          latestProductSales.getDiscount=true;
          latestProductSales.promo= promoFreeItemAndNominalByProduct;
          if(free_item_type==1){
            let promotionFreeItemSameAsProductScan: any[] = [];
            promotionFreeItemSameAsProductScan.push({
              'id' :idpromo,
              'product_id': pcode,
              'product_name':product_name,
              'promotion_detail_id': idpromodetail
            });
            free_item_list =  promotionFreeItemSameAsProductScan;
            latestProductSales.free_item_list = promotionFreeItemSameAsProductScan;
          }
          else{
            free_item_list =this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
            latestProductSales.free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);        
          }  
          let caripromo1 = this.productSales.find(x=>x.id==pcode && x.promo_free_item.length>0);
          if(caripromo1!==undefined){
            let promolength=[];
            promolength = caripromo1.promo_free_item;
            let caripromo =promolength.find(x=>x.id==idpromo);
            if(caripromo==undefined){
              latestProductSales.promo_free_item.push({
                'id':idpromo,
                'promo':promoFreeItemAndNominalByProduct[a],
                'free_item_list':free_item_list,
                'getFreeTemp':free,
                'getFree':0
              });
            }
          }
          else{
            latestProductSales.promo_free_item.push({
              'id':idpromo,
              'promo':promoFreeItemAndNominalByProduct[a],
              'free_item_list':free_item_list,
              'getFreeTemp':free,
              'getFree':0
            });
          }
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoFreeItemAndNominalByProduct[a]);
        let getProductWithDiscount =this.productSales.filter(x => x.getDiscount==true);
        if(syaratTerpenuhi=='Y'){
          detailPromoFreeItemAndNominalByProduct+=('\nsudah memenuhi syarat yg dibutuhkan');
          if(getProductWithDiscount.length>0){
            for(let p=0;p<getProductWithDiscount.length;p++){
              let getFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === getProductWithDiscount[p].id && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
              let freeItem =getProductWithDiscount[p].getFreeTemp;
              if(getFreeItem){
                getFreeItem.syarat_terpenuhi='Y';
                if(getFreeItem.free_item_remaining==0){
                  freeItem=0;
                }
              }
              getProductWithDiscount[p].inputDiskon = true;
              let list_promo =getProductWithDiscount[p].promo_free_item;
              if(list_promo.length>0){
                for(let lp=0;lp<list_promo.length;lp++){
                  if(list_promo[lp].promo==promoFreeItemAndNominalByProduct[a])
                  {
                    let diskon = list_promo[lp].getFreeTemp;
                    if(getFreeItem.free_item_remaining==0){
                      diskon=0;
                    }
                    else{//ini solusi bug free item ga muncul kalo scan 1 1
                      diskon=getFreeItem.free_item_remaining;
                    }
                    list_promo[lp].getFree =diskon;
                  }
                }
              }
            }
          }
        }
        else{
          detailPromoFreeItemAndNominalByProduct+='\nbelum memenuhi syarat yg dibutuhkan';
          if(getProductWithDiscount.length>0){
            for(let p=0;p<getProductWithDiscount.length;p++){
              let getFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === getProductWithDiscount[p].id && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
              if(getFreeItem){
                getFreeItem.syarat_terpenuhi='N';
                getFreeItem.free_item_remaining=getProductWithDiscount[p].getFreeTemp;
                getFreeItem.free_item_used=0;
              }
              if( getProductWithDiscount[p].inputDiskon){// jika ada produk yg sudah dapat diskon, kemudian produk yg menjadi syarat batal dibeli maka batalkan diskonnya
                getProductWithDiscount[p].inputDiskon = false;
              }
              let list_promo =getProductWithDiscount[p].promo_free_item;
              if(list_promo.length>0){
                for(let lp=0;lp<list_promo.length;lp++){
                  if(list_promo[lp].promo==promoFreeItemAndNominalByProduct[a])
                  {
                    list_promo[lp].getFree =0;
                  }
                }
              }
            }
          }
        }
      }
      this.arraySumNominal = [];
      for(let a=0;a<this.arrayFreeItemUsed.length;a++){
        let lengthFreeItem = this.arrayFreeItemUsed[a]['array_item'].length;
        if (lengthFreeItem > 0){
          for(let b=0;b<lengthFreeItem;b++){ 
            this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']= this.arrayFreeItemUsed[a]['free_item_used'];  
            let checkProduct =this.arraySumNominal.find(x => x.product_id==this.arrayFreeItemUsed[a]['array_item'][b]['product_id']);
            if(checkProduct){
              if(this.arrayFreeItemUsed[a]['syarat_terpenuhi']=='N'){
                this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']=0;
                checkProduct.qty=0;
              }
              else{
                checkProduct.qty =this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
              }
            }
            else{
              let qty =this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
              if(this.arrayFreeItemUsed[a]['syarat_terpenuhi']=='N'){
                qty=0;
              }
              this.arraySumNominal.push({
                'product_id' :this.arrayFreeItemUsed[a]['array_item'][b]['product_id'],
                'qty' :qty
              });
            }
          }
        }
      }
      
      if(this.arraySumNominal.length>0){
        for(let a=0;a<this.arraySumNominal.length;a++){
          let checkProduct =this.productSales.find(x => x.id==this.arraySumNominal[a]['product_id']);
          if(checkProduct){
            checkProduct.qtyFree = this.arraySumNominal[a]['qty'];
            for(let b=0;b<checkProduct.arrayPromoFreeItem.length;b++){
              if(checkProduct.arrayPromoFreeItem[b]['promotion_id']==checkProduct.promotionFreeItemId && 
                checkProduct.arrayPromoFreeItem[b]['product_scan']==pcode){
                checkProduct.arrayPromoFreeItem[b]['qty']=this.arraySumNominal[a]['qty'];
              }
            }
          }
        }
      }
      //console.log(detailPromoFreeItemAndNominalByProduct);
    }
    
    // check jika ada promo free item and nominal by total belanja per produk
    let promoFreeItemAndNominalSelectedProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM AND NOMINAL' && parseInt(x.price_type) !== 2 && x.promo_based_on == 2 && x.qty_type=='0');
    if(promoFreeItemAndNominalSelectedProduct.length > 0){
      let detailPromoFreeItemAndNominalProduct = 'Ada promo free item and nominal by total belanja per produk';
      for(let a =0; a < promoFreeItemAndNominalSelectedProduct.length;a++){
        let idpromodetail = promoFreeItemAndNominalSelectedProduct[a].id;
        let idpromo = promoFreeItemAndNominalSelectedProduct[a].promotion_id;
        let minimum = promoFreeItemAndNominalSelectedProduct[a].minimum_transaction;
        let multipleFlag = promoFreeItemAndNominalSelectedProduct[a].multiple_flag;
        let free = promoFreeItemAndNominalSelectedProduct[a].free_qty;
        let productId = promoFreeItemAndNominalSelectedProduct[a].product_id;
        let free_item_type = promoFreeItemAndNominalSelectedProduct[a].free_item_type;
        let maximum = promoFreeItemAndNominalSelectedProduct[a].maximum_free_item;
        let diskon = promoFreeItemAndNominalSelectedProduct[a].value;
        let max_discount = promoFreeItemAndNominalSelectedProduct[a].max_discount;
        let price_type = promoFreeItemAndNominalSelectedProduct[a].price_type;

        let arrProduct = [];
        if(productId!==null){
          arrProduct= productId.split(';');
        }
        let checkProduct =arrProduct.find(x => x==pcode);
        if(latestProductSales.arr_promo_nominal.length >0){
          let caripromo = latestProductSales.arr_promo_nominal.find(x=>x.idpromodetail==idpromodetail)
          if (caripromo==undefined){
            latestProductSales.arr_promo_nominal.push({
              idpromodetail:idpromodetail,
              idpromo:idpromo,
              mencapai_minimum:'N',
              memenuhi_syarat:'N',
              nominal_diskon:0,
              persen:0,
              diskon:0,
              promo_bertingkat:'N',
              persenvalue:0,
              qty_get_promo:0,
              add_discount_in_end:'N'
            })
          }
          else{
            caripromo.mencapai_minimum='N';
            caripromo.memenuhi_syarat='N';
            caripromo.nominal_diskon=0;
            caripromo.qty_get_promo=0;
            caripromo.add_discount_in_end='N';
          }
        }
        else{
          latestProductSales.arr_promo_nominal.push({
            idpromodetail:idpromodetail,
            idpromo:idpromo,
            mencapai_minimum:'N',
            memenuhi_syarat:'N',
            nominal_diskon:0,
            promo_bertingkat:'N',
            persen:0,
            diskon:0,
            persenvalue:0,
            qty_get_promo:0,
            add_discount_in_end:'N'
          })
        }
        if((checkProduct != undefined && productId !== null ) || productId === null){
          if(latestProductSales.nett < parseInt(minimum)){
            detailPromoFreeItemAndNominalProduct+='Belum memenuhi minimum belanja.';
            free = 0;
          }
          else{
            detailPromoFreeItemAndNominalProduct += 'Sudah memenuhi minimum belanja.';
            if(price_type==3){
              diskon = (diskon/100) * latestProductSales.nett;
            }
            if(multipleFlag=='1'){
              detailPromoFreeItemAndNominalProduct += 'Berlaku kelipatan.';
              free = free * Math.floor(latestProductSales.nett/parseInt(minimum));
              
              if(minimum>0 && price_type!=3){             
                diskon = diskon * Math.floor(latestProductSales.nett/minimum);
              }
            }
            else{
              detailPromoFreeItemAndNominalProduct += 'Tidak berlaku kelipatan';
            };            
            if(maximum>0 && free> maximum){
              free = maximum;
            }
            if (max_discount >0  && diskon > parseInt(max_discount)){
              diskon = max_discount;
            }
            let thispromonominal = latestProductSales.arr_promo_nominal.find(x=>x.idpromo==idpromo && x.idpromodetail==idpromodetail);
            thispromonominal.mencapai_minimum='Y';
            thispromonominal.diskon=parseInt(diskon);
            thispromonominal.add_discount_in_end='Y';
          }

          // array free item //
          let countFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === pcode && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
          let freeItemRemaining = 0;
          if(countFreeItem) {
            let index = this.arrayFreeItemUsed.indexOf(countFreeItem);
            this.arrayFreeItemUsed[index]['free_item_max'] = free;
            if(free==0){            
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            let used = this.arrayFreeItemUsed[index]['free_item_used'];
            freeItemRemaining = parseInt(free)-parseInt(used);
            this.arrayFreeItemUsed[index]['free_item_remaining'] =freeItemRemaining;
          }
          else{
            freeItemRemaining = free;
            this.arrayFreeItemUsed.push({
              product_id:pcode,
              promo_id: idpromo,
              promo_id_detail: idpromodetail,
              free_item_max: free,
              free_item_remaining: free,
              free_item_used: 0,
              array_item:[]
            });
          }
          if(freeItemRemaining==0){
            free = 0;
          }
          
          //end of array free item
          let free_item_list =[];
          detailPromoFreeItemAndNominalProduct += 'Mendapat free '+free+' item';
          detailPromoFreeItemAndNominalProduct += '\nMendapat Diskon '+diskon;
  
          latestProductSales.getFreeTemp = free;
          latestProductSales.getDiscount=true;
          latestProductSales.promo= promoFreeItemAndNominalSelectedProduct;
          if(free_item_type==1){
            let promotionFreeItemSameAsProductScan: any[] = [];
            promotionFreeItemSameAsProductScan.push({
              'id' :idpromo,
              'product_id': pcode,
              'product_name':product_name,
              'promotion_detail_id': idpromodetail
            });
            free_item_list =  promotionFreeItemSameAsProductScan;
            latestProductSales.free_item_list = promotionFreeItemSameAsProductScan;
          }
          else{
            free_item_list =this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
            latestProductSales.free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);        
          } 
          let caripromo1 = this.productSales.find(x=>x.id==pcode && x.promo_free_item.length>0);
          if(caripromo1!==undefined){
            let promolength=[];
            promolength = caripromo1.promo_free_item;
            let caripromo =promolength.find(x=>x.id==idpromo);
            if(caripromo==undefined){
              latestProductSales.promo_free_item.push({
                  'id':idpromo,
                  'promo':promoFreeItemAndNominalSelectedProduct[a],
                  'free_item_list':free_item_list,
                  'getFreeTemp':free,
                  'getFree':0
              });
            }
          }
          else{
            latestProductSales.promo_free_item.push({
              'id':idpromo,
              'promo':promoFreeItemAndNominalSelectedProduct[a],
              'free_item_list':free_item_list,
              'getFreeTemp':free,
              'getFree':0
            });
          }
        }
         //cek produk yg dibutuhkan
         let syaratTerpenuhi =this.cekSyarat(promoFreeItemAndNominalSelectedProduct[a]);
         let getProductWithDiscount =this.productSales.filter(x => x.getDiscount==true);
         if(syaratTerpenuhi=='Y'){
          detailPromoFreeItemAndNominalProduct+=('\nsudah memenuhi syarat yg dibutuhkan');
           if(getProductWithDiscount.length>0){
             for(let p=0;p<getProductWithDiscount.length;p++){
               let getFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === getProductWithDiscount[p].id && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
               let freeItem =getProductWithDiscount[p].getFreeTemp;
               if(getFreeItem){
                 getFreeItem.syarat_terpenuhi='Y';
                 if(getFreeItem.free_item_remaining==0){
                  freeItem=0;
                 }
               }
               getProductWithDiscount[p].inputDiskon = true;
               let list_promo =getProductWithDiscount[p].promo_free_item;
               if(list_promo.length>0){
                 for(let lp=0;lp<list_promo.length;lp++){
                   if(list_promo[lp].promo==promoFreeItemAndNominalSelectedProduct[a])
                   {
                     let diskon = list_promo[lp].getFreeTemp;
                     if(getFreeItem.free_item_remaining==0){
                       diskon=0;
                     }
                     else{
                      diskon=getFreeItem.free_item_remaining;
                     }
                     list_promo[lp].getFree =diskon;
                   }
                 }
               }
             }
           }
         }
         else{
          detailPromoFreeItemAndNominalProduct+='\nbelum memenuhi syarat yg dibutuhkan';
          if(getProductWithDiscount.length>0){
            for(let p=0;p<getProductWithDiscount.length;p++){
              let getFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === getProductWithDiscount[p].id && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
              if(getFreeItem){
                getFreeItem.syarat_terpenuhi='N';
                getFreeItem.free_item_remaining=getProductWithDiscount[p].getFreeTemp;
                getFreeItem.free_item_used=0;
              }
              if( getProductWithDiscount[p].inputDiskon){// jika ada produk yg sudah dapat diskon, kemudian produk yg menjadi syarat batal dibeli maka batalkan diskonnya
                getProductWithDiscount[p].inputDiskon = false;
              }
              let list_promo =getProductWithDiscount[p].promo_free_item;
              if(list_promo.length>0){
                for(let lp=0;lp<list_promo.length;lp++){
                  if(list_promo[lp].promo==promoFreeItemAndNominalSelectedProduct[a])
                  {
                    list_promo[lp].getFree =0;
                  }
                }
              }
            }
          }
        }
      }
      this.arraySumNominal = [];
      for(let a=0;a<this.arrayFreeItemUsed.length;a++){
        let lengthFreeItem = this.arrayFreeItemUsed[a]['array_item'].length;
        if (lengthFreeItem > 0){
          for(let b=0;b<lengthFreeItem;b++){ 
            this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']= this.arrayFreeItemUsed[a]['free_item_used'];  
            let checkProduct =this.arraySumNominal.find(x => x.product_id==this.arrayFreeItemUsed[a]['array_item'][b]['product_id']);
            if(checkProduct){
              if(this.arrayFreeItemUsed[a]['syarat_terpenuhi']=='N'){
                this.arrayFreeItemUsed[a]['array_item'][b]['qty_free']=0;
                checkProduct.qty=0;
              }
              else{
                checkProduct.qty =this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
              }
            }
            else{
              let qty =this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
              if(this.arrayFreeItemUsed[a]['syarat_terpenuhi']=='N'){
                qty=0;
              }
              this.arraySumNominal.push({
                'product_id' :this.arrayFreeItemUsed[a]['array_item'][b]['product_id'],
                'qty' :qty
              });
            }
          }
        }
      }
      
      if(this.arraySumNominal.length>0){
        for(let a=0;a<this.arraySumNominal.length;a++){
          let checkProduct =this.productSales.find(x => x.id==this.arraySumNominal[a]['product_id']);
          if(checkProduct){
            checkProduct.qtyFree = this.arraySumNominal[a]['qty'];
            for(let b=0;b<checkProduct.arrayPromoFreeItem.length;b++){
              if(checkProduct.arrayPromoFreeItem[b]['promotion_id']==checkProduct.promotionFreeItemId && 
                checkProduct.arrayPromoFreeItem[b]['product_scan']==pcode){
                checkProduct.arrayPromoFreeItem[b]['qty']=this.arraySumNominal[a]['qty'];
              }
            }
          }
        }
      }
      //console.log(detailPromoFreeItemAndNominalProduct);
    }
  }

  // CHECK PROMO NOMINAL BY SELECTED PRODUCT
  checkPromoNominalBySelectedProduct(latestProductSales:any) {
    // check jika ada promo nominal by total belanja per produk
    let pcode = latestProductSales.id;
    let promoNominalByNominalProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'NOMINAL' &&  x.promo_based_on == 2 && x.qty_type=='0');
   if(promoNominalByNominalProduct.length > 0){
      //Ada promo potongan by nominal belanja per produk
      for(let a =0; a< promoNominalByNominalProduct.length;a++){
        let minimum = promoNominalByNominalProduct[a].minimum_transaction;
        let diskon = parseInt(promoNominalByNominalProduct[a].value);
        let berlakuKelipatan = promoNominalByNominalProduct[a].multiple_flag;
        let product = promoNominalByNominalProduct[a].product_id;
        let max_discount = parseInt(promoNominalByNominalProduct[a].max_discount);
        let idpromodetail = promoNominalByNominalProduct[a].id;
        let idpromo = promoNominalByNominalProduct[a].promotion_id;
        let arrProduct = [];
        if(product!=null){
          arrProduct= product.split(';');
        }
        let checkProduct =arrProduct.find(x => x==pcode);
        if(product==null || (product!==null && checkProduct != undefined)){
          if(latestProductSales.arr_promo_nominal.length >0){
            let caripromo = latestProductSales.arr_promo_nominal.find(x=>x.idpromodetail==idpromodetail)
            if (caripromo==undefined){
              latestProductSales.arr_promo_nominal.push({
                idpromodetail:idpromodetail,
                idpromo:idpromo,
                mencapai_minimum:'N',
                memenuhi_syarat:'N',
                nominal_diskon:0,
                promo_bertingkat:'N',
                persen:0,
                diskon:0,
                persenvalue:0,
                qty_get_promo:0,
                add_discount_in_end:'Y'
              })
            }
            else{
              caripromo.mencapai_minimum='N';
              caripromo.memenuhi_syarat='N';
              caripromo.nominal_diskon=0;
              caripromo.qty_get_promo=0;
              caripromo.add_discount_in_end='N';
            }
          }
          else{
            latestProductSales.arr_promo_nominal.push({
              idpromodetail:idpromodetail,
              idpromo:idpromo,
              mencapai_minimum:'N',
              memenuhi_syarat:'N',
              nominal_diskon:0,
              promo_bertingkat:'N',
              persen:0,
              diskon:0,
              persenvalue:0,
              qty_get_promo:0,
              add_discount_in_end:'Y'
            })
          }
          if (latestProductSales.nett >= parseInt(minimum)){
            if(berlakuKelipatan=='1'){
              diskon = diskon * Math.floor(latestProductSales.nett/minimum);
            }
            if (max_discount >0  && diskon > max_discount){
              diskon = max_discount;
            }
            let thispromonominal = latestProductSales.arr_promo_nominal.find(x=>x.idpromo==idpromo && x.idpromodetail==idpromodetail);
            thispromonominal.mencapai_minimum='Y';
            thispromonominal.diskon=diskon;
            thispromonominal.qty_get_promo=1;
            thispromonominal.add_discount_in_end='Y';
          }
        }
      }
    }
    // check jika ada promo nominal by produk untuk produk tertentu
    
    let promoNominalByProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'NOMINAL' && x.promo_based_on == 3 && x.qty_type=='0');
    if(promoNominalByProduct.length > 0){
      let detailPromoNominalSelectedProduct = 'Ada promo nominal per produk untuk produk tertentu.';
      for(let a =0; a< promoNominalByProduct.length;a++){
        let minimum = parseInt(promoNominalByProduct[a].minimum_buy);
        let maximum_buy = parseInt(promoNominalByProduct[a].maximum_buy);
        let max_discount = parseInt(promoNominalByProduct[a].max_discount);
        let diskon = parseInt(promoNominalByProduct[a].value);
        let berlakuKelipatan = promoNominalByProduct[a].multiple_flag;
        let product = promoNominalByProduct[a].product_id;
        let idpromodetail = promoNominalByProduct[a].id;
        let idpromo = promoNominalByProduct[a].promotion_id;
        let applies_to = parseInt(promoNominalByProduct[a].applies_to);//promo berlaku untuk produk ke
       
        let arrProduct = [];
        if(product!=null){
          arrProduct= product.split(';');
        }
        let checkProduct =arrProduct.find(x => x==pcode);
        if((product !== null && checkProduct != undefined) || product=== null){
          if(latestProductSales.arr_promo_nominal.length >0){
            let caripromo = latestProductSales.arr_promo_nominal.find(x=>x.idpromodetail==idpromodetail)
            if (caripromo==undefined){
              latestProductSales.arr_promo_nominal.push({
                idpromodetail:idpromodetail,
                idpromo:idpromo,
                mencapai_minimum:'N',
                memenuhi_syarat:'N',
                nominal_diskon:0,
                promo_bertingkat:'N',
                persen:0,
                diskon:0,
                persenvalue:0,
                qty_get_promo:0,
                add_discount_in_end:'Y'
              })
            }
            else{
              caripromo.mencapai_minimum='N';
              caripromo.memenuhi_syarat='N';
              caripromo.nominal_diskon=0;
              caripromo.qty_get_promo=0;
              caripromo.add_discount_in_end='N';
            }
          }
          else{
            latestProductSales.arr_promo_nominal.push({
              idpromodetail:idpromodetail,
              idpromo:idpromo,
              mencapai_minimum:'N',
              memenuhi_syarat:'N',
              nominal_diskon:0,
              promo_bertingkat:'N',
              persen:0,
              diskon:0,
              persenvalue:0,
              qty_get_promo:0,
              add_discount_in_end:'N'
            })
          }
          if (latestProductSales.qty < minimum){
            detailPromoNominalSelectedProduct+='Belum memenuhi minimum belanja.';
          }
          else{
            detailPromoNominalSelectedProduct+='Sudah memenuhi minimum belanja.';
            let qtyProductDiscount=0;
            if(applies_to>1){
              let capaiProdukKe =Math.floor(parseInt(latestProductSales.qty)/applies_to);
              if (capaiProdukKe>0){
                if(applies_to==minimum){
                  qtyProductDiscount =  Math.floor(parseInt(latestProductSales.qty)/minimum);
                }
                else{
                  let produkKenaDiskon = parseInt(latestProductSales.qty)-(applies_to-1);
                  qtyProductDiscount =  Math.floor(produkKenaDiskon/minimum);
                }
              }
             // qtyProductDiscount=Math.floor(parseInt(latestProductSales.qty)/applies_to);
              //applies to 1
              // beli 1 tdk diskon, beli 2-3 diskon 1000, beli 4 nambah seribu jadi 2K
              //applies to 2
              // beli 1 tdk diskon, beli 2-3 diskon 1000, beli4 nambah seribu jadi 2K
              //applies t0 3
              // beli 1-3 tdk diskon, beli 4-5 diskon 1K, beli 6 +1K, jadi 2K
            //applies t0 4
              // beli 1-3 tdk diskon, beli 4-5 diskon 1K, beli 6-7 2K, beli 8 diskon 3K
            }
            else{
                qtyProductDiscount =  Math.floor(parseInt(latestProductSales.qty)/minimum);
            }
            if(maximum_buy>0 && qtyProductDiscount > maximum_buy){
              qtyProductDiscount =maximum_buy;
            }
            if(qtyProductDiscount>0){
              if(berlakuKelipatan!='1'){
                qtyProductDiscount =1;
                detailPromoNominalSelectedProduct+='ridak Berlaku kelipatan.';
              }
            }
            diskon = diskon*qtyProductDiscount;
            if (max_discount >0  && diskon > max_discount){
              diskon = max_discount;
            } 
            detailPromoNominalSelectedProduct+='Produk kena diskon:'+qtyProductDiscount;
            let thispromonominal = latestProductSales.arr_promo_nominal.find(x=>x.idpromo==idpromo && x.idpromodetail==idpromodetail);
            thispromonominal.mencapai_minimum='Y';
            thispromonominal.diskon=diskon;
            thispromonominal.qty_get_promo = qtyProductDiscount;
            thispromonominal.add_discount_in_end='Y';
            detailPromoNominalSelectedProduct+='Mendapat diskon sebesar:'+diskon;
          }
        }
      }
     // console.log(detailPromoNominalSelectedProduct);
    }  
  }
  calculateDiscount() {
    //cek syarat nominal
    for(let ps=0;ps<this.productSales.length;ps++){
      let thisproduct= this.productSales[ps];
      let arr_promo_nominal = thisproduct.arr_promo_nominal;
      if(arr_promo_nominal.length>0){
        for(let i=0;i<arr_promo_nominal.length;i++){
          let promo =arr_promo_nominal[i];
          //cari yg bukan bertingkat
          if(promo.promo_bertingkat=='N' && promo.mencapai_minimum=='Y'){
            let cekpromo =this.promotionDetailList.find(x => x.id === promo.idpromodetail);
            let syaratNominalTerpenuhi =this.cekSyarat(cekpromo);
            promo.memenuhi_syarat=syaratNominalTerpenuhi;
            if(syaratNominalTerpenuhi=='Y'){
              promo.nominal_diskon=parseInt(promo.diskon);
              promo.persen=parseInt(promo.persenvalue);
            }
            else{
              promo.nominal_diskon=0;
              promo.persen=0
            }
          }
        }
      }
    }
  }
  // CHECK PROMO PERSENTASE BY TOTAL BELANJA ALL PRODUCT
  checkPromoPercentBySelectedProduct(latestProductSales:any) {
    let pcode = latestProductSales.id;
    // check jika ada promo potongan persentase by nominal belanja per produk
    let promoPersentaseSelectedProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'PERSENTASE' && x.promo_based_on == 2 && x.qty_type=='0');
    if(promoPersentaseSelectedProduct.length >0){
      let detailPromoPercentSelectedProduct = 'Ada promo persentase by nominal belanja per produk';
      for (let a=0;a<promoPersentaseSelectedProduct.length;a++){
        let minimum = promoPersentaseSelectedProduct[a].minimum_transaction;
        let diskonValue = parseInt(promoPersentaseSelectedProduct[a].value);
        let totalPaymentProduct = parseInt(latestProductSales.price )* parseInt(latestProductSales.qty);
        let diskon = Math.floor((diskonValue/100) * totalPaymentProduct);
        let product = promoPersentaseSelectedProduct[a].product_id;
        let idpromodetail = promoPersentaseSelectedProduct[a].id;
        let idpromo = promoPersentaseSelectedProduct[a].promotion_id;
        let arrProduct = [];
        let getDiskon =false;   
        if(product!=null){
          arrProduct= product.split(';');
        }
        let checkProduct =arrProduct.find(x => x== pcode);
        if((product != null && checkProduct != undefined) || product==null){
          if(latestProductSales.arr_promo_nominal.length >0){
            let caripromo = latestProductSales.arr_promo_nominal.find(x=>x.idpromodetail==idpromodetail)
            if (caripromo==undefined){
              latestProductSales.arr_promo_nominal.push({
                idpromodetail:idpromodetail,
                idpromo:idpromo,
                mencapai_minimum:'N',
                memenuhi_syarat:'N',
                nominal_diskon:0,
                promo_bertingkat:'N',
                persen:0,
                diskon:0,
                persenvalue:0,
                qty_get_promo:0,
                add_discount_in_end:'Y'
              })
            }
            else{
              caripromo.mencapai_minimum='N';
              caripromo.memenuhi_syarat='N';
              caripromo.nominal_diskon=0;
              caripromo.persen=0;
              caripromo.diskon=0;
              caripromo.persenvalue=0;
              caripromo.qty_get_promo=0;
              caripromo.add_discount_in_end = 'Y';
            }
          }
          else{
            latestProductSales.arr_promo_nominal.push({
              idpromodetail:idpromodetail,
              idpromo:idpromo,
              mencapai_minimum:'N',
              memenuhi_syarat:'N',
              nominal_diskon:0,
              persen:0,
              diskon:0,
              promo_bertingkat:'N',
              persenvalue:0,
              qty_get_promo:0,
              add_discount_in_end:'N'
            })
          }
          if (totalPaymentProduct >= parseInt(minimum)){
            getDiskon = true;
            detailPromoPercentSelectedProduct+='Mendapat diskon sebesar:'+parseInt(promoPersentaseSelectedProduct[a].value) +'%.';
            let max_discount = promoPersentaseSelectedProduct[a].max_discount;
            detailPromoPercentSelectedProduct+='\nMaximum diskon :'+max_discount;
            if (max_discount >0  && diskon > parseInt(max_discount)){
              diskon = max_discount;
            }
            //cek produk yg dibutuhkan
            let syaratTerpenuhi =this.cekSyarat(promoPersentaseSelectedProduct[a]);
            if(getDiskon && syaratTerpenuhi=='Y'){
              detailPromoPercentSelectedProduct+='sudah memenuhi syarat';
              detailPromoPercentSelectedProduct +='\nMendapat diskon sebesar:'+(diskon);
              detailPromoPercentSelectedProduct +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
              let hargasebelumdiskon=0;
              for(let ps=0;ps<this.productSales.length;ps++){
                let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
                hargasebelumdiskon+=hargaTanpaFreeItem;
              }
              let diskonterbagi=0;
              for(let ps=0;ps<this.productSales.length;ps++){
                let diskonkontribusi=0;
                if(ps!=(this.productSales.length-1)){
                  diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                  diskonterbagi+=diskonkontribusi;
                }
                else{
                  diskonkontribusi= diskon-diskonterbagi;
                }
                this.productSales[ps].valPromoNominal+=diskonkontribusi;
              }
            }
            else{
              detailPromoPercentSelectedProduct+='belum memenuhi syarat';
            }
            let thispromonominal = latestProductSales.arr_promo_nominal.find(x=>x.idpromo==idpromo && x.idpromodetail==idpromodetail);
            thispromonominal.mencapai_minimum='Y';
            thispromonominal.diskon=(diskon);
            thispromonominal.persenvalue=(diskonValue);
            thispromonominal.qty_get_promo=1;
          }
        }
      }
      //console.log(detailPromoPercentSelectedProduct);
    }
    let promoPersenByProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'PERSENTASE' && x.promo_based_on == 3 && x.qty_type=='0');
    if(promoPersenByProduct.length > 0){
      let detailPromoNominalSelectedProduct = 'Ada promo persen by produk untuk produk tertentu.';
      for(let a =0; a< promoPersenByProduct.length;a++){
        let minimum = parseInt(promoPersenByProduct[a].minimum_buy);
        let maximum_buy = parseInt(promoPersenByProduct[a].maximum_buy);
        let max_discount = promoPersenByProduct[a].max_discount;
        let diskonValue = parseInt(promoPersenByProduct[a].value);
        let totalPaymentProduct = parseInt(latestProductSales.price )* parseInt(latestProductSales.qty);
        let diskon = Math.floor((diskonValue/100) * totalPaymentProduct);
        let berlakuKelipatan = promoPersenByProduct[a].multiple_flag;
        let product = promoPersenByProduct[a].product_id;
        let idpromodetail = promoPersenByProduct[a].id;
        let idpromo = promoPersenByProduct[a].promotion_id;
        //update april 2024
        
        let applies_to = parseInt(promoPersenByProduct[a].applies_to);//promo berlaku untuk produk ke
        let arrProduct = [];
        if(product!=null){
          arrProduct= product.split(';');
        }
        let checkProduct =arrProduct.find(x => x==pcode);
        if((product !== null && checkProduct != undefined) || product=== null){
          if(latestProductSales.arr_promo_nominal.length >0){
            let caripromo = latestProductSales.arr_promo_nominal.find(x=>x.idpromodetail==idpromodetail)
            if (caripromo==undefined){
              latestProductSales.arr_promo_nominal.push({
                idpromodetail:idpromodetail,
                idpromo:idpromo,
                mencapai_minimum:'N',
                memenuhi_syarat:'N',
                nominal_diskon:0,
                promo_bertingkat:'N',
                persen:0,
                diskon:0,
                persenvalue:0,
                qty_get_promo:0,
                add_discount_in_end:'Y'
              })
            }
            else{
              caripromo.mencapai_minimum='N';
              caripromo.memenuhi_syarat='N';
              caripromo.nominal_diskon=0;
              caripromo.qty_get_promo=0;
              caripromo.add_discount_in_end='Y';
            }
          }
          else{
            latestProductSales.arr_promo_nominal.push({
              idpromodetail:idpromodetail,
              idpromo:idpromo,
              mencapai_minimum:'N',
              memenuhi_syarat:'N',
              nominal_diskon:0,
              promo_bertingkat:'N',
              persen:0,
              diskon:0,
              persenvalue:0,
              qty_get_promo:0,
              add_discount_in_end:'Y'
            })
          }
          if (latestProductSales.qty < minimum){
            detailPromoNominalSelectedProduct+='Belum memenuhi minimum belanja.';
          }
          else{
            detailPromoNominalSelectedProduct+='Sudah memenuhi minimum belanja.';
            let qtyProductDiscount=Math.floor(parseInt(latestProductSales.qty));
            if(applies_to!=0){
              qtyProductDiscount=Math.floor(parseInt(latestProductSales.qty)/applies_to);
            }
            let productPrice =parseInt(latestProductSales.price );
            if(maximum_buy>0 && qtyProductDiscount > maximum_buy){
              qtyProductDiscount =maximum_buy;

            }
            if(qtyProductDiscount>0){
              if(berlakuKelipatan!='1'){
                qtyProductDiscount =1;
              }
            }
            let totalPaymentProduct = productPrice*qtyProductDiscount;
            diskon = Math.floor((diskonValue/100) * totalPaymentProduct);
            diskonValue =0;
            let thispromonominal = latestProductSales.arr_promo_nominal.find(x=>x.idpromo==idpromo && x.idpromodetail==idpromodetail);
            if (max_discount >0  && diskon > max_discount){
              diskon = max_discount;
            }
            thispromonominal.mencapai_minimum='Y';
            thispromonominal.diskon=(diskon);
            thispromonominal.persenvalue=(diskonValue);
            thispromonominal.qty_get_promo = 1;
            detailPromoNominalSelectedProduct+='Mendapat diskon sebesar:'+diskon;
          }
        }
      }
    }  
  }

  // CHECK PROMO FREE ITEM (ITEM)
  checkPromoFreeItemByItem(latestProductSales:any) {
    let pcode = latestProductSales.id;
    let product_name = latestProductSales.name;
    // check jika ada promo free item by item 
    let promoFreeItemItemSelectedProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM (ITEM)' && (x.promo_based_on == 2 || x.promo_based_on==0) && x.qty_type==0);
    if(promoFreeItemItemSelectedProduct.length > 0){
      let detailPromoFreeItemItemSelectedProduct ='Ada promo free item (item)';
      for(let a =0; a < promoFreeItemItemSelectedProduct.length;a++){
        let idpromodetail = promoFreeItemItemSelectedProduct[a].id;
        let idpromo = promoFreeItemItemSelectedProduct[a].promotion_id;
        let minimum = promoFreeItemItemSelectedProduct[a].minimum_buy;
        let multipleFlag = promoFreeItemItemSelectedProduct[a].multiple_flag;
        let free = promoFreeItemItemSelectedProduct[a].free_qty;
        let productId = promoFreeItemItemSelectedProduct[a].product_id;
        let free_item_type = promoFreeItemItemSelectedProduct[a].free_item_type;
        let arrProduct = [];        
        if(productId!=null){
          arrProduct= productId.split(';');
        }
        let checkProduct =arrProduct.find(x => x==pcode);
        if ((checkProduct != undefined && productId !== null) || productId===null){
          let freeItemRemaining = 0;
      
          if(latestProductSales.qty < parseInt(minimum)){
            free = 0;
          }
          else{
            detailPromoFreeItemItemSelectedProduct+='Sudah memenuhi minimum belanja.';
            if(multipleFlag=='1'){
              detailPromoFreeItemItemSelectedProduct+='Berlaku kelipatan.';
              free = free * Math.floor(latestProductSales.qty/parseInt(minimum));
            }
            else{
              detailPromoFreeItemItemSelectedProduct+='Tidak belaku kelipatan';
            }
            
            let maximum = promoFreeItemItemSelectedProduct[a].maximum_free_item;
            if(maximum>0 && free> maximum){
              free = maximum;
            }
          }
          let countFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === pcode && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
          
          if(countFreeItem) {
            let index = this.arrayFreeItemUsed.indexOf(countFreeItem);
            this.arrayFreeItemUsed[index]['free_item_max'] = free;
            //NEW UPDATE
            if(free==0){            
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            // END OF NEW UPDATE
            let used = this.arrayFreeItemUsed[index]['free_item_used'];              
            freeItemRemaining = parseInt(free)-parseInt(used);
            this.arrayFreeItemUsed[index]['free_item_remaining'] =freeItemRemaining;
          }
          else{
            freeItemRemaining = free;
            this.arrayFreeItemUsed.push({
              product_id:pcode,
              promo_id: idpromo,
              promo_id_detail: idpromodetail,
              free_item_max: free,
              free_item_remaining: free,
              free_item_used: 0,
              array_item:[]
            });
          }
          if(freeItemRemaining==0){
            free = 0;
          }            
          latestProductSales.getFreeItemItem=true;
          let free_item_list=[];
          if(free_item_type==1){
            let promotionFreeItemSameAsProductScan: any[] = [];
            promotionFreeItemSameAsProductScan.push({
              'id' :idpromo,
              'product_id': pcode,
              'product_name':product_name,
              'promotion_detail_id': idpromodetail
            });
            free_item_list =promotionFreeItemSameAsProductScan;
            latestProductSales.free_item_list = promotionFreeItemSameAsProductScan;
          }
          else{
            free_item_list= this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
            latestProductSales.free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);        
          }
          let caripromo1 = this.productSales.find(x=>x.id==pcode && x.promo_free_item.length>0);
          if(caripromo1!==undefined){
            let promolength=[];
            promolength = caripromo1.promo_free_item;
            let caripromo =promolength.find(x=>x.id==idpromo);
            if(caripromo==undefined){
              latestProductSales.promo_free_item.push({
                  'id':idpromo,
                  'promo':promoFreeItemItemSelectedProduct[a],
                  'free_item_list':free_item_list,
                  'getFreeTemp':free,
                  'getFree':0
              });
            }
            else{
              caripromo.getFreeTemp=free;
            }
          }
          else{
            latestProductSales.promo_free_item.push({
              'id':idpromo,
              'promo':promoFreeItemItemSelectedProduct[a],
              'free_item_list':free_item_list,
              'getFreeTemp':free,
              'getFree':0
            });
          }
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoFreeItemItemSelectedProduct[a]);
        let getProductWithDiscount =this.productSales.filter(x => x.getFreeItemItem === true );
        if(syaratTerpenuhi=='Y'){
          if(getProductWithDiscount.length>0){
            for(let p=0;p<getProductWithDiscount.length;p++){
              let getFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === getProductWithDiscount[p].id && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
              if(getFreeItem){
                getFreeItem.syarat_terpenuhi='Y';
                getProductWithDiscount[p].inputFreeItemItem = true;
                let list_promo =getProductWithDiscount[p].promo_free_item;
                if(list_promo.length>0){
                  for(let lp=0;lp<list_promo.length;lp++){
                    if(list_promo[lp].promo==promoFreeItemItemSelectedProduct[a])
                    {
                      let diskon = list_promo[lp].getFreeTemp;
                      if(getFreeItem.free_item_remaining==0){
                        diskon=0;
                      }
                      list_promo[lp].getFree =diskon;
                    }
                  }
                }
              }
            }
          }
        }
        else{
          if(getProductWithDiscount.length>0){
            for(let p=0;p<getProductWithDiscount.length;p++){
              let getFreeItem = this.arrayFreeItemUsed.find(x => x.product_id === getProductWithDiscount[p].id && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
              if(getFreeItem){
                getFreeItem.syarat_terpenuhi='N';
                getFreeItem.free_item_remaining=getProductWithDiscount[p].getFreeTemp;
                getFreeItem.free_item_used=0;
              }
              if(getProductWithDiscount[p].inputFreeItemItem){// jika ada produk yg sudah dapat diskon, kemudian produk yg menjadi syarat batal dibeli maka batalkan diskonnya
                getProductWithDiscount[p].inputFreeItemItem = false;
              }
              let list_promo =getProductWithDiscount[p].promo_free_item;
              if(list_promo.length>0){
                for(let lp=0;lp<list_promo.length;lp++){
                  if(list_promo[lp].promo==promoFreeItemItemSelectedProduct[a])
                  {
                    list_promo[lp].getFree =0;
                  }
                }
              }
            }
          }
        }
      }
      this.arraySum = [];
      for(let a=0;a<this.arrayFreeItemUsed.length;a++){
        let lengthFreeItem = this.arrayFreeItemUsed[a]['array_item'].length;
        if (lengthFreeItem > 0){
          for(let b=0;b<lengthFreeItem;b++){            
            let checkProduct =this.arraySum.find(x => x.product_id==this.arrayFreeItemUsed[a]['array_item'][b]['product_id']);
            if(checkProduct){
              checkProduct.qty +=this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
              if(this.arrayFreeItemUsed[a]['syarat_terpenuhi']=='N'){
                checkProduct.qty=0;
              }
            }
            else{
              let qty =this.arrayFreeItemUsed[a]['array_item'][b]['qty_free'];
              if(this.arrayFreeItemUsed[a]['syarat_terpenuhi']=='N'){
                qty=0;
              }
              this.arraySum.push({
                'product_id' :this.arrayFreeItemUsed[a]['array_item'][b]['product_id'],
                'qty' :qty
              });
            }
          }
        }
      }
      if(this.arraySum.length>0){
        for(let a=0;a<this.arraySum.length;a++){
          let checkProduct =this.productSales.find(x => x.id==this.arraySum[a]['product_id']);
          if(checkProduct){
            checkProduct.qtyFree = this.arraySum[a]['qty'];
            for(let b=0;b<checkProduct.arrayPromoFreeItem.length;b++){
              if(checkProduct.arrayPromoFreeItem[b]['promotion_id']==checkProduct.promotionFreeItemId && 
              checkProduct.arrayPromoFreeItem[b]['product_scan']==pcode){
                checkProduct.arrayPromoFreeItem[b]['qty']=this.arraySum[a]['qty'];
              }
            }
          }
        }
      }      
    }
  // END OF PROMO FREE ITEM (ITEM);
  }
  // CHECK PROMO BERTINGKAT
  checkPromoDiskonBertingkat(latestProductSalesx:any) {
    let pcode = latestProductSalesx.id;
    // check jika ada promo potongan bertingkat 
    let arr_promo_tingkat = [];
    let arr_id_promo = [];
    let promoDiskonBertingkat =this.promotionDetailList.filter(x => x.promotion_type_name === 'PROMO BERTINGKAT'  && x.promo_based_on!==1);
    if(promoDiskonBertingkat.length >0){
      let detailPromoDiskonBertingkat = '\nAda promo bertingkat.';
      for (let a=0;a<promoDiskonBertingkat.length;a++){
        let promo_based_on = promoDiskonBertingkat[a].promo_based_on;
        let minimum = promoDiskonBertingkat[a].minimum_buy;
        let minimum_transaction = promoDiskonBertingkat[a].minimum_transaction;
        let product_list = promoDiskonBertingkat[a].product_list;
        let product = promoDiskonBertingkat[a].product;
        let arrProduct = [];
        let idpromodetail = promoDiskonBertingkat[a].id;
        let idpromo = promoDiskonBertingkat[a].promotion_id;
        arr_promo_tingkat.push({
          idpromodetail:idpromodetail,
          idpromo:idpromo,
          mencapai_minimum:'N',
          memenuhi_syarat:'N',
          nominal_diskon:0,
          persen_diskon:0,
          terpilih:'N'
        })
        if(latestProductSalesx.arr_promo_nominal.length >0){
          let caripromo = latestProductSalesx.arr_promo_nominal.find(x=>x.idpromodetail==idpromodetail)
          if (caripromo==undefined){
            latestProductSalesx.arr_promo_nominal.push({
              idpromodetail:idpromodetail,
              idpromo:idpromo,
              mencapai_minimum:'N',
              memenuhi_syarat:'N',
              nominal_diskon:0,
              promo_bertingkat:'Y',
              persen:0,
              diskon:0,
              persenvalue:0,
              qty_get_promo:0,
              add_discount_in_end:'N'
            })
          }
          else{
            caripromo.mencapai_minimum='N';
            caripromo.memenuhi_syarat='N';
            caripromo.nominal_diskon=0;
            caripromo.persen=0;
            caripromo.diskon=0;
            caripromo.qty_get_promo=0;
            caripromo.add_discount_in_end='N';
          }
        }
        else{
          latestProductSalesx.arr_promo_nominal.push({
            idpromodetail:idpromodetail,
            idpromo:idpromo,
            mencapai_minimum:'N',
            memenuhi_syarat:'N',
            nominal_diskon:0,
            promo_bertingkat:'Y',
            persen:0,
            diskon:0,
            persenvalue:0,
            qty_get_promo:0,
            add_discount_in_end:'N'
          })
        }
        let findid = arr_id_promo.find(x=>x.id==idpromo);
        if(findid==undefined){
          arr_id_promo.push({id:idpromo});
        }
        let thispromo = arr_promo_tingkat.find(x=>x.idpromo==idpromo && x.idpromodetail==idpromodetail);
        let thispromonominal = latestProductSalesx.arr_promo_nominal.find(x=>x.idpromo==idpromo && x.idpromodetail==idpromodetail);
        if(product_list!=null){
          arrProduct= product_list.split(';');
        }
        let checkProduct =arrProduct.find(x => x== pcode);
        if((product==2 && checkProduct != undefined) || product==1){
          if((promo_based_on ==2 &&  (latestProductSalesx.qty*latestProductSalesx.price) < parseInt(minimum_transaction)) ||(promo_based_on ==3 && latestProductSalesx.qty < parseInt(minimum))){
            detailPromoDiskonBertingkat+='Belum memenuhi minimum belanja.';
            thispromo.mencapai_minimum='N';
            thispromonominal.mencapai_minimum='N';
          }
          else{ 
            thispromo.mencapai_minimum='Y';
            thispromonominal.mencapai_minimum='Y';
            detailPromoDiskonBertingkat+='Sudah memenuhi minimum belanja.';
            let syaratNominalTerpenuhi =this.cekSyarat(promoDiskonBertingkat[a]);
            thispromo.memenuhi_syarat=syaratNominalTerpenuhi;
            thispromonominal.memenuhi_syarat=syaratNominalTerpenuhi;
          }
        }
      }
    }
    latestProductSalesx.arr_id_promo = arr_id_promo;
    latestProductSalesx.arr_promo_tingkat = arr_promo_tingkat;
    for(let ps=0;ps<this.productSales.length;ps++){
      let thisproduct= this.productSales[ps];
      let arr_id_promo = this.productSales[ps].arr_id_promo;
      let arr_promo_tingkat = this.productSales[ps].arr_promo_tingkat;
      let product_id = this.productSales[ps].id;
      if(arr_id_promo.length>0){
        for(let i=0;i<arr_id_promo.length;i++){
          let filterpromo = arr_promo_tingkat.filter(x=>x.idpromo==arr_id_promo[i].id);
          let promo_yang_diambil =0;
          if(filterpromo.length>0){
            for(let j=filterpromo.length;j>0;j--){// tentukan mana level tertinggi yang diambil
              let index=j-1;
              let promo =filterpromo[index];
              let cekpromo =this.promotionDetailList.find(x => x.id === promo.idpromodetail);
              let syaratNominalTerpenuhi =this.cekSyarat(cekpromo);
              promo.memenuhi_syarat=syaratNominalTerpenuhi;
              if(promo_yang_diambil ==0 && promo.memenuhi_syarat=='Y' && promo.mencapai_minimum=='Y'){
                promo_yang_diambil =promo.idpromodetail;
                promo.terpilih='Y';
              }
              if(promo.memenuhi_syarat=='N' || promo.mencapai_minimum=='N'){
                //16 04 24
                let cariunused =this.promoBundleFreeItem.find(x=>x.promotion_detail_id==promo.idpromodetail && x.product_scan==thisproduct.id);
                if(cariunused!==undefined){
                  let index = this.promoBundleFreeItem.indexOf(cariunused);
                  this.promoBundleFreeItem.splice(index, 1);
                }
              }
            }
            let filterpromounused = arr_promo_tingkat.filter(x=>x.idpromo==arr_id_promo[i].id && x.idpromodetail!=promo_yang_diambil);
            if (filterpromounused.length>0){
              for(let un=0;un<filterpromounused.length;un++){
                filterpromounused[un].terpilih='N';
                let cariunused =this.promoBundleFreeItem.find(x=>x.promotion_detail_id==filterpromounused[un].idpromodetail && x.product_scan==thisproduct.id);
                if(cariunused!==undefined){
                  let index = this.promoBundleFreeItem.indexOf(cariunused);
                  this.promoBundleFreeItem.splice(index, 1);
                }
              }
            }
            //jika tidak ada promo yg memenuhi
            if(promo_yang_diambil==0){            
              let otherlevel= this.arrayFreeItemUsed.filter(x =>x.product_id==product_id && x.promo_id==arr_id_promo[i].id);
              if(otherlevel.length>0){
                for(let ol=0;ol<otherlevel.length;ol++){
                  otherlevel[ol].free_item_remaining =0;
                  otherlevel[ol].free_item_max =0;
                  otherlevel[ol].free_item_used =0;
                  let array_item = otherlevel[ol].array_item;
                  if(array_item.length>0){
                    for(let ai=0;ai<array_item.length;ai++){
                      array_item[ai].qty_free=0;
                    }
                  }
                }
              }
              let caripromo1 = this.productSales.find(x=>x.id==product_id && x.promo_free_item.length>0);
              if(caripromo1!==undefined){
                let promolength=[];
                promolength = caripromo1.promo_free_item;
                let caripromo =promolength.find(x=>x.id==arr_id_promo[i].id);
                if(caripromo!==undefined){
                  caripromo.getFree=0;
                }
              }
            }
            let promotingkatTerpilih =this.promotionDetailList.find(x => x.id === promo_yang_diambil);
            if(promotingkatTerpilih!==undefined){
              
              let discount_type = promotingkatTerpilih.discount_type;
              let diskonValue = promotingkatTerpilih.value;
              let diskon =0;
              let berlakuKelipatan = promotingkatTerpilih.multiple_flag;
              let max_discount = promotingkatTerpilih.max_discount;
              let max_free = promotingkatTerpilih.maximum_free_item;
              let minimum = promotingkatTerpilih.minimum_buy;
              let free = parseInt(promotingkatTerpilih.free_qty);
              let idpromodetail = promotingkatTerpilih.id;
              let idpromo = promotingkatTerpilih.promotion_id;
              let totalPaymentProduct = thisproduct.price * thisproduct.qty;
              if(discount_type==2){
                diskon= Math.floor(parseInt(diskonValue)/100 * totalPaymentProduct);
                if (max_discount >0  && diskon > parseInt(max_discount)){
                  diskon = max_discount;
                }
                thisproduct.valPromoBertingkatPersen=  diskon;
                thisproduct.valPromoPercentage =diskonValue;
                let findpromonominal=thisproduct.arr_promo_nominal.find(x=>x.idpromodetail==promo_yang_diambil)         
                if(findpromonominal!==undefined){
                  findpromonominal.nominal_diskon=diskon;
                  findpromonominal.persen=parseInt(diskonValue);
                  findpromonominal.add_discount_in_end='Y';
                }
              }
              else if(discount_type==1){
                diskon = Math.floor(parseInt(diskonValue));
                if(berlakuKelipatan=='1'){
                  if(minimum>0){
                    diskon = diskon * Math.floor(thisproduct.qty/minimum);
                  }
                }
                if (max_discount >0  && diskon > parseInt(max_discount)){
                  diskon = max_discount;
                }
                thisproduct.valPromoBertingkatNominal=  diskon;
                let findpromonominal=thisproduct.arr_promo_nominal.find(x=>x.idpromodetail==promo_yang_diambil)         
                if(findpromonominal!==undefined){
                  findpromonominal.nominal_diskon=diskon;
                  findpromonominal.add_discount_in_end='Y';
                }
              }
              else if(discount_type==3){
                if(berlakuKelipatan=='1' && minimum >0){
                  free = free * Math.floor(thisproduct.qty/minimum);
                }      
                if (max_free >0  && free > (max_free)){
                  free = max_free;
                }
                //cari promo dengan id ini di level yg lain
                let otherlevel= this.arrayFreeItemUsed.filter(x =>x.product_id==product_id && x.promo_id==idpromo  && parseInt(x.promo_id_detail) !== parseInt(idpromodetail));
                if(otherlevel.length>0){
                  for(let ol=0;ol<otherlevel.length;ol++){
                    otherlevel[ol].free_item_remaining =0;
                    otherlevel[ol].free_item_max =0;
                    otherlevel[ol].free_item_used =0;
                    let array_item = otherlevel[ol].array_item;
                    if(array_item.length>0){
                      for(let ai=0;ai<array_item.length;ai++){
                        array_item[ai].qty_free=0;
                      }
                    }
                  }
                }
                let checkFreeItemUsed= this.arrayFreeItemUsed.find(x => x.product_id === product_id  && parseInt(x.promo_id_detail) === parseInt(idpromodetail));
                if(checkFreeItemUsed!==undefined) {
                  checkFreeItemUsed.free_item_remaining=free;
                  checkFreeItemUsed.free_item_max=free;
                }
                else{
                  this.arrayFreeItemUsed.push({
                    product_id:product_id,
                    promo_id: idpromo,
                    promo_id_detail: idpromodetail,
                    free_item_max: free,
                    free_item_remaining: free,
                    free_item_used: 0,
                    array_item:[]
                  });
                }  
                let free_item_type = promotingkatTerpilih.free_item_type;
                let free_item_list =[];
                if(free_item_type==1){
                  let promotionFreeItemSameAsProductScan: any[] = [];
                  promotionFreeItemSameAsProductScan.push({
                    'id' :idpromo,
                    'product_id': product_id,
                    'product_name': thisproduct.name,
                    'promotion_detail_id': idpromodetail
                  });
                  free_item_list=promotionFreeItemSameAsProductScan;
                }
                else{
                  free_item_list = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
                }
                let caripromo1 = this.productSales.find(x=>x.id==product_id && x.promo_free_item.length>0);
                if(caripromo1!==undefined){
                  let promolength=[];
                  promolength = caripromo1.promo_free_item;
                  let caripromo =promolength.find(x=>x.id==idpromo);
                  if(caripromo==undefined){
                    thisproduct.promo_free_item.push({
                        'id':idpromo,
                        'promo':promotingkatTerpilih,
                        'free_item_list':free_item_list,
                        'getFreeTemp':free,
                        'getFree':free
                    });
                  }
                  else{
                    caripromo.promo= promotingkatTerpilih;
                    caripromo.free_item_list=free_item_list;
                    let getFree=0;
                    for(let fi=0;fi<caripromo.free_item_list.length;fi++){
                      getFree+=caripromo.free_item_list[fi].used;
                    }
                    caripromo.getFreeTemp=free;
                    caripromo.getFree=free-getFree;
                    if(caripromo.getFree<0){
                      caripromo.getFree=0;
                    }                   
                  }
                }
                else{
                  thisproduct.promo_free_item.push({
                    'id':idpromo,
                    'promo':promotingkatTerpilih,
                    'free_item_list':free_item_list,
                    'getFreeTemp':free,
                    'getFree':free
                  });
                }
              }
            }
          }
        }
      }
    }
  }
 checkStockFreeItem( productId: any, promotionId:any) {
    let stockItemPromo: any = 0;
    let promoWarehouseId: any = 0;
    let idPromotionDetail: any = 0;
    let freeQty: any = 0;
    let promoId: any = this.promotionDetailList.find(x => x.id == promotionId);
    let warehouseId = promoId.warehouse_id
    let checkStock: any ;
    checkStock = this.stockFreeItem.find(x => x.product_id === productId);
    if( promoId){
      if( promoId.free_item_type==1){ //bonus produk = produk scan
        this.utilService.loadingPresent('Harap tunggu...')
        .then( () => {
          let options ={
            "token": this.token,
            "warehouse_id" : warehouseId,
            "product_id" : productId
          }
          this.posService.checkStock(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          if (response.status.code == 200) {
            if(response.results) {
              checkStock =response.results.stock_data[0];
              if(checkStock) {
                let stockNow = checkStock["end_stock"];
                if(stockNow > 0) {
                  let bonusItemQty: number = 0;
                  stockItemPromo = stockNow;
                  promoWarehouseId = warehouseId;
                  idPromotionDetail = promoId.id;
                  bonusItemQty = promoId.free_qty;
                  if((parseInt(stockItemPromo) - bonusItemQty) < 0) {
                    let message = 'Stock promo untuk produk ' + checkStock.product_name + ' tersisa: ' + stockItemPromo;
                    this.showAlertBarcodeNotValid(message);
                    freeQty = stockItemPromo;
                  } else {
                    freeQty = bonusItemQty;
                  }
                } else {
                  let message = 'Stock free item telah habis';
                  this.showAlertBarcodeNotValid(message);
                }
              } else {
                let message = 'Stock free item tidak ada.';
                this.showAlertBarcodeNotValid(message);
              }
              if(freeQty > 0) {
                if (this.mstProductList[parseInt(productId)] !== undefined) {
                  let checkProductSales = this.productSales.find(x => x.id === productId);
                  if(checkProductSales) {
                    let checkFreeItemUsed= this.arrayFreeItemUsed.find(x => x.product_id === productId  && parseInt(x.promo_id_detail) === parseInt(idPromotionDetail));
                    let used = 0;
                    let index = this.productSales.indexOf(checkProductSales);
                    let updateQtyFree = parseInt(this.productSales[index]['qtyFree']);
                    if(checkFreeItemUsed){
                      let idx = this.arrayFreeItemUsed.indexOf(checkFreeItemUsed);
                      used = this.arrayFreeItemUsed[idx]['free_item_used'];
                      let max = this.arrayFreeItemUsed[idx]['free_item_max'];
                      freeQty = parseInt(max) -used;
                      this.arrayFreeItemUsed[idx]['free_item_used'] = freeQty;
                      updateQtyFree =updateQtyFree+parseInt(freeQty)
                      let remainingFree = parseInt(max)-updateQtyFree;
                      this.arrayFreeItemUsed[idx]['free_item_used'] = updateQtyFree;
                      this.arrayFreeItemUsed[idx]['free_item_remaining'] = remainingFree;
                    }
                    this.productSales[index]['qtyFree'] = updateQtyFree;
                    this.productSales[index]['getFree'] = 0;
                  }
                }
              }
              this.calcTotalItem();
            }
          }
        }, () => {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan Stock' }).then(t => t.present());
        });
      });
      }
      else{
        if(checkStock) {
          let arrayStock: any[] = [];
          arrayStock.push(checkStock);
          let convertDate = this.utilService.convertDate(new Date());
          let month = convertDate.months;
          let fieldEnd = "end_" + month;
          let stockNow = arrayStock[0][fieldEnd];
    
          if(stockNow > 0) {
            let bonusItemQty: number = 0;
            stockItemPromo = arrayStock[0][fieldEnd];
            promoWarehouseId = arrayStock[0]["warehouse_id"];
            idPromotionDetail = promoId.id;
            bonusItemQty = promoId.free_qty;
            if((parseInt(stockItemPromo) - bonusItemQty) < 0) {
              let message = 'Stock promo untuk produk ' + checkStock.product_name + ' tersisa: ' + stockItemPromo;
              this.showAlertBarcodeNotValid(message);
              freeQty = stockItemPromo;
            } else {
              freeQty = bonusItemQty;
            }
          } else {
            let message = 'Stock free item telah habis';
            this.showAlertBarcodeNotValid(message);
          }
        } else {
          let message = 'Stock free item tidak ada.';
          this.showAlertBarcodeNotValid(message);
        }
        return {
          'promo_warehouse_id': promoWarehouseId,
          'promotion_detail_id': idPromotionDetail,
          'free_qty': freeQty
        };
      }
    }
  }

  clearTransaction() {
    this.productSales = new Array();
    this.transactionTime = '';
    this.totalItem = 0;
    this.totalPayment = 0;
    this.transactionDiscount = 0;
    this.transactionDiscountWithSeparator ='0';
    this.customerCash = 0;
    this.customerCashWithSeparator ='0';
    this.customerTransfer = 0;
    this.customerTransferWithSeparator ='0';
    this.totalCustomerCash = 0;
    this.customerCredit = 0;
    this.customerCreditWithSeparator ='0';
    this.customerDebit = 0;
    this.customerDebitWithSeparator ='0';
    this.totalCustomerPay = 0;
    this.totalCustomerPayWithSeparator ='0';
    this.totalChange = 0;
    this.totalChangeUSD = 0;
    this.totalChangeCNY = 0;
    this.valueWeChat = 0;
    this.valueWeChatWithSeparator = '0';
    this.valueCNY = 0;
    this.valueCNYwithSeparator = '0';
    this.valueUSD = 0;
    this.valueUSDwithSeparator = '0';
    this.voucherValue = 0;
    this.voucherValueWithSeparator = '0';
    this.customerCreditId = undefined;
    this.customerDebitId = undefined;
    this.foreignCurrencyId = undefined;
    this.edcId = undefined;
    this.ppn = 0;
    this.memberId = undefined;
    this.memberPhone = '';
    this.memberPointValue = 0;
    this.memberPointUsed = 0;
    this.totalPointAdd = 0;
    this.pointMutationType = '';
    this.voucherUseList = [];

    this.countFreeItem = 0;
    this.promoItemNominal = '';
    this.message_free='';
    this.promotionFreeItemList = [];
    this.arrayFreeItemUsed = [];   
    this.promoFreeItem =[];
    this.stickerNumber ='';
    this.totalChange =0;
    this.totalChangeCNY=0;
    this.totalChangeUSD=0;
    this.totalChangeWithSeparator='0';
    
    this.getTodayPromotion('first_load'); 
    this.getStock();
    this.updateStorage();
    (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
  }

  saveTransactionTemp() {
    this.productSalesTemp = this.productSales;
    this.transactionTimeTemp = this.transactionTime;
    this.totalItemTemp = this.totalItem;
    this.totalPaymentTemp = this.totalPayment;
    this.transactionDiscountTemp = this.transactionDiscount;
    this.customerCashTemp = this.customerCash;
    this.totalCustomerCashTemp = this.totalCustomerCash;
    this.customerCreditTemp = this.customerCredit;
    this.customerDebitTemp = this.customerDebit;
    this.totalCustomerPayTemp = this.totalCustomerPay;
    this.totalChangeTemp = this.totalChange;
    this.totalChangeUSDTemp = this.totalChangeUSD;
    this.totalChangeCNYTemp = this.totalChangeCNY;
    this.valueWeChatTemp = this.valueWeChat;
    this.valueCNYTemp = this.valueCNY;
    this.valueUSDTemp = this.valueUSD;
    this.voucherValueTemp = this.voucherValue;
    this.customerCreditIdTemp = this.customerCreditId;
    this.customerDebitIdTemp = this.customerDebitId;
    this.foreignCurrencyIdTemp = this.foreignCurrencyId;
    this.edcIdTemp = this.edcId;
    this.ppnTemp = this.ppn;
    this.memberIdTemp = this.memberId;
    this.memberPhoneTemp = this.memberPhone;
    this.memberPointValueTemp = this.memberPointValue;
    this.memberPointUsedTemp = this.memberPointUsed;
    this.totalPointAddTemp = this.totalPointAdd;
    this.pointMutationTypeTemp = this.pointMutationType;
    this.voucherUseListTemp = this.voucherUseList;
  }
  
  
  calcTotalItem(){
    this.totalItem = 0;
    for (let i=0;i < this.productSales.length;i++) {
      this.productSales[i].qty =parseInt(this.productSales[i].qty_withoutpromo)+parseInt(this.productSales[i].qtyFree);
      this.totalItem += (parseInt(this.productSales[i].qty) );
    }
    
    let emptyQty =this.productSales.filter(x => x.qtyFree==0 && x.qty==0);
    if(emptyQty.length>0){
      for( let e=0;e<emptyQty.length;e++){
        let indexProductSales = this.productSales.indexOf(emptyQty[e]);
        this.productSales.splice(indexProductSales, 1);
      }
    }
  }
  calcTotal() {
    this.totalPayment = 0;
    this.totalPaymentWithoutPromo = 0;
    this.transactionDiscount = 0;
    this.transactionDiscountWithSeparator ='0';
    this.message_free='';
    this.promoBundle =[];
    for( let p=0;p<this.productSales.length;p++){
      let product = this.productSales[p];
      product.valPromoNominal =0;
      product.valPromoPercentage =0;
      if(product.arr_promo_nominal.length>0){
        for(let n=0;n<product.arr_promo_nominal.length;n++){
          product.valPromoNominal+=product.arr_promo_nominal[n].nominal_diskon;
          if(product.arr_promo_nominal[n].memenuhi_syarat=='Y' && product.arr_promo_nominal[n].mencapai_minimum=='Y'){
            product.valPromoPercentage+=product.arr_promo_nominal[n].persen;
          }
          if(product.arr_promo_nominal[n].promo_bertingkat!='Y'){
            if(product.arr_promo_nominal[n].memenuhi_syarat=='Y' && product.arr_promo_nominal[n].mencapai_minimum=='Y' && (product.arr_promo_nominal[n].nominal_diskon!=0)){
              this.promoBundle.push({
                'product_scan':product.id,
                'promotion_detail_id':product.arr_promo_nominal[n].idpromodetail,
                'qty_get_promo':product.arr_promo_nominal[n].qty_get_promo,
                'discount':product.arr_promo_nominal[n].nominal_diskon,
                'product_free_item':'',
                'qty_free_item':0,
                'add_discount_in_end':product.arr_promo_nominal[n].add_discount_in_end!==undefined? product.arr_promo_nominal[n].add_discount_in_end :'N'
              })
            }
          }
          else{
            if(product.arr_promo_nominal[n].memenuhi_syarat=='Y' && product.arr_promo_nominal[n].mencapai_minimum=='Y' && (product.arr_promo_nominal[n].nominal_diskon!=0)){
              this.promoBundle.push({
                'product_scan':product.id,
                'promotion_detail_id':product.arr_promo_nominal[n].idpromodetail,
                'qty_get_promo':1,
                'discount':product.arr_promo_nominal[n].nominal_diskon,
                'product_free_item':'',
                'qty_free_item':0,
                'add_discount_in_end':product.arr_promo_nominal[n].add_discount_in_end!==undefined? product.arr_promo_nominal[n].add_discount_in_end :'N'
              })
            }
          }
        }
      }
      product.nett =(parseInt(product.qty_withoutpromo)*parseInt(product.price))-(parseInt(product.nominalPromoPercentage)+parseInt(product.valPromoNominal));
      this.totalPayment += product.nett;
      this.totalPaymentWithoutPromo += (product.price * product.qty_withoutpromo);
    }
    /* CHECK PROMO BERDASARKAN TOTAL BELANJA */
    // check jika ada promo nominal by total belanja
    let promoNominal =this.promotionDetailList.filter(x => x.promotion_type_name === 'NOMINAL' && x.product_id === null && x.promo_based_on == 1);
    if(promoNominal.length>0){
      for (let pn=0;pn<promoNominal.length;pn++){
        let detailPromoNominal = 'ada promo nominal.';
        let promoNominalId = promoNominal[pn].id;
        let minimum = promoNominal[pn].minimum_transaction;
        let diskon = promoNominal[pn].value;
        let max_discount = promoNominal[pn].max_discount;
        let berlakuKelipatan = promoNominal[pn].multiple_flag;
        let getDiskon =false;
        if (this.totalPayment < parseInt(minimum)){
          detailPromoNominal += ' Belum memenuhi minimum belanja';
          diskon =0;
        }
        else{
          getDiskon=true;
          detailPromoNominal += '. Sudah memenuhi minimum belanja';
          if(berlakuKelipatan=='1'){
            detailPromoNominal += '. Berlaku kelipatan';
            diskon = diskon * Math.floor(this.totalPayment/minimum);
          }
          else{
            detailPromoNominal += '. Tidak berlaku kelipatan';
          }
          if (max_discount >0  && diskon > max_discount){
            diskon = max_discount;
          }
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoNominal[pn]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoNominal+='sudah memenuhi syarat';
          this.transactionDiscount += parseInt(diskon);
          detailPromoNominal +='\nMendapat diskon sebesar:'+parseInt(diskon);
          detailPromoNominal +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
          let hargasebelumdiskon=0;
          for(let ps=0;ps<this.productSales.length;ps++){
            let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
            hargasebelumdiskon+=hargaTanpaFreeItem;
          }
          let diskonterbagi=0;
          let detail = '';
          for(let ps=0;ps<this.productSales.length;ps++){
            let diskonkontribusi=0;
            if(ps!=(this.productSales.length-1)){
              diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
              diskonterbagi+=diskonkontribusi;
            }
            else{
              diskonkontribusi= diskon-diskonterbagi;
            }
            this.productSales[ps].valPromoNominal+=diskonkontribusi;
            if(diskonkontribusi>0){
              detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
            }
          }
          detail = detail.replace(/<br>$/, "");
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':promoNominalId,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'detail':detail
          })
        }
        else{
          detailPromoNominal+='belum memenuhi syarat';
        }
        //console.log(detailPromoNominal);
      }
    }
    // check jika ada promo potongan (persen) by nominal belanja
    let promoPersen =this.promotionDetailList.filter(x => x.promotion_type_name === 'PERSENTASE' && x.product_id === null && x.promo_based_on == 1);
    if(promoPersen.length>0){
      let detailPromoPersen = 'Ada promo persentase.';
      for(let pp=0;pp<promoPersen.length;pp++){
        let promoPersenId = promoPersen[pp].id;
        let minimum = promoPersen[pp].minimum_transaction;
        let diskon = promoPersen[pp].value;
        let max_discount = promoPersen[pp].max_discount;
        let getDiskon =false;
        diskon = (parseInt(diskon)/100) * this. totalPayment;
        diskon = Math.floor(diskon);
        if (max_discount >0  && diskon > max_discount){
          diskon = max_discount;
        }
        if (this.totalPayment < parseInt(minimum)){
          detailPromoPersen+= 'Belum memenuhi minimum belanja.';
          diskon =0;
        }
        else{
          detailPromoPersen+='Sudah memenuhi minimum belanja.';
          detailPromoPersen+='Mendapat diskon sebesar:'+promoPersen[pp].value +'% ('+diskon+')';
          detailPromoPersen +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
          getDiskon =true;
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoPersen[pp]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoPersen+='sudah memenuhi syarat';
          if (max_discount >0  && diskon > max_discount){
            diskon = max_discount;
          }
          //kontribusi harga
          let hargasebelumdiskon=0;
          for(let ps=0;ps<this.productSales.length;ps++){
            let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
          hargasebelumdiskon+=hargaTanpaFreeItem;
          }
          let diskonterbagi=0;
          let detail='';
          for(let ps=0;ps<this.productSales.length;ps++){
            let diskonkontribusi=0;
            if(ps!=(this.productSales.length-1)){
              diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price* parseInt(this.productSales[ps].qty_withoutpromo)));
              diskonterbagi+=diskonkontribusi;
            }
            else{
              diskonkontribusi= diskon-diskonterbagi;
            }
            this.productSales[ps].valPromoNominal+=diskonkontribusi;
            if(diskonkontribusi>0){
              detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
            }
          }
          detail = detail.replace(/<br>$/, "");
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':promoPersenId,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'add_discount_in_end':'N',// kalau by total belanja tidak perlu Y karena sudah dihitung di this.transactionDiscount
            'detail':detail
          })
          this.transactionDiscount += parseInt(diskon);
        }
        else{
          detailPromoPersen+='belum memenuhi syarat';
        }
      }
     // console.log(detailPromoPersen);
    }
    //ada promo free item and nominal-special price
    let promoSpecialPrice =this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM AND NOMINAL' && parseInt(x.price_type)===2 );
    if(promoSpecialPrice.length>0){
      let adaPromoHargaKhusus = 'ada promo harga khusus.';
      for(let i=0;i<promoSpecialPrice.length;i++){
        let idpromodetail = promoSpecialPrice[i].id;
        let idpromo = promoSpecialPrice[i].promotion_id;
        let productRequired = promoSpecialPrice[i].product_required;
        let value = parseInt(promoSpecialPrice[i].value);
        let multipleFlag = promoSpecialPrice[i].multiple_flag;
        let free = parseInt(promoSpecialPrice[i].free_qty);
        let max_free = parseInt(promoSpecialPrice[i].maximum_free_item);
        let syaratDibutuhkan =productRequired.length;
        adaPromoHargaKhusus+='harga menjadi :'+value+'.';
        promoSpecialPrice[i].pricebeforediskon =0; 
        promoSpecialPrice[i].free =0; // free yang bisa didapat
        promoSpecialPrice[i].syaratBelumTerpenuhi =syaratDibutuhkan;
        if(syaratDibutuhkan>0){
          for(let j=0;j<syaratDibutuhkan;j++){
            let product = productRequired[j].product_id.split(';');
            let qty = parseInt(productRequired[j].qty);
            productRequired[j].productDibeli=[];
            let productDibeli=productRequired[j].productDibeli;
            productRequired[j].qtyTersedia =0;
            productRequired[j].qtydibutuhkan =qty;
            let jenisQty=productRequired[j].qty_type;//gabungan atau per produk,0 :per produk , 1:gabungan
            
            if(product.length>0){
              for (let k=0;k<product.length;k++){
               let cari = this.productSales.find(x=>x.id==product[k]);
               if(cari!==undefined){
                productRequired[j].productDibeli.push({
                  'product_id':product[k],
                  'qty':parseInt(cari.qty),
                  'price':parseInt(cari.price)
                })
                productRequired[j].qtyTersedia+=cari.qty;
               }
              }
            }
            if(productRequired[j].qtyTersedia >= qty && productDibeli.length>0){
              for(let k=0;k<productDibeli.length;k++){
                productDibeli[k].qty_terpakai=0;
                if(productRequired[j].qtydibutuhkan>0){
                  if(productDibeli[k].qty <= productRequired[j].qtydibutuhkan){
                    productRequired[j].qtydibutuhkan-=productDibeli[k].qty;
                   // productDibeli[k].qty_terpakai =productDibeli[k].qty;
                  }
                  else{
                  //  productDibeli[k].qty_terpakai = productRequired[j].qtydibutuhkan;
                    productRequired[j].qtydibutuhkan=0;
                  }
                }
              }
              if(productRequired[j].qtydibutuhkan==0){
                promoSpecialPrice[i].syaratBelumTerpenuhi = promoSpecialPrice[i].syaratBelumTerpenuhi-1;
              }
            }
          }
        }
        let resetFreeByNominal = 1;
        if(promoSpecialPrice[i].syaratBelumTerpenuhi ==0){
          adaPromoHargaKhusus += 'sudah memenuh syarat.';
          // untuk multiple cek berapa kali syarat terpenuhi
          let jumlah_multiple=0;
          let arr_produk_dibeli = [];
          let arr_pasangan = [];
          if(multipleFlag=='1'){
            promoSpecialPrice[i].pricebeforediskon =0;
            let product_required =promoSpecialPrice[i].product_required;
            //ambil berapa kali dapat promo            
            for(let yy=0;yy<promoSpecialPrice[i].product_required.length;yy++){
              if(yy==0){
                jumlah_multiple = product_required[yy].qtyTersedia;
              }
              else{
                if(product_required[yy].qtyTersedia<jumlah_multiple){
                  jumlah_multiple =product_required[yy].qtyTersedia;
                }
              }
            }
            adaPromoHargaKhusus+='mendapat sebanyak '+jumlah_multiple;
            for(let pp=0;pp<jumlah_multiple;pp++){
              let pasangan=[];
              for(let yy=0;yy<promoSpecialPrice[i].product_required.length;yy++){
                let productDibeli = promoSpecialPrice[i].product_required[yy].productDibeli;
                let  qty_terpakai =0
                for(let k=0;k<productDibeli.length;k++){
                  if(parseInt(product_required[yy].qty)!=qty_terpakai){
                    if(productDibeli[k].qty-productDibeli[k].qty_terpakai!=0){
                      if(productDibeli[k].qty>=product_required[yy].qty){
                        productDibeli[k].qty_terpakai=parseInt(product_required[yy].qty);
                      }
                      else if(productDibeli[k].qty<product_required[yy].qty){
                        productDibeli[k].qty_terpakai=parseInt(productDibeli[k].qty);
                      }
                      qty_terpakai+=productDibeli[k].qty_terpakai;
                      pasangan.push({
                        product_id:productDibeli[k].product_id,
                        qty:productDibeli[k].qty_terpakai,
                        price:productDibeli[k].price
                      })
                    }
                  }
                }
              }
              arr_pasangan.push(pasangan);
            }
            for(let yy=0;yy<promoSpecialPrice[i].product_required.length;yy++){
              let productDibeli = promoSpecialPrice[i].product_required[yy].productDibeli;
              for(let k=0;k<productDibeli.length;k++){
                if(productDibeli[k].qty_terpakai>0){
                arr_produk_dibeli.push(productDibeli[k].product_id);
                }
                promoSpecialPrice[i].pricebeforediskon +=(productDibeli[k].qty_terpakai * productDibeli[k].price);
                let cariProduk=this.productSales.find(x=>x.id==productDibeli[k].product_id);
                if(cariProduk){
                  if( cariProduk.arr_kontribusi.length>0){
                    let kontribusi = cariProduk.arr_kontribusi.find(x=>x.promotion_detail_id==idpromodetail);
                    if(kontribusi){
                      kontribusi.discount =0;
                    }else{
                      cariProduk.arr_kontribusi.push({
                        'promotion_detail_id':idpromodetail,
                        'discount':0
                      })
                    }
                  }
                  else{
                    cariProduk.arr_kontribusi.push({
                      'promotion_detail_id':idpromodetail,
                      'discount':0
                    })
                  }
                }
              }
            }
            free=free* jumlah_multiple;
          }
          if (max_free >0  && free > max_free){
            free = max_free;
          }
          promoSpecialPrice[i].free =free;
          promoSpecialPrice[i].jumlah_multiple=jumlah_multiple;

          let diskon=0;/// jumlah_multiple* ((promoSpecialPrice[i].pricebeforediskon-value)) ;
         if( promoSpecialPrice[i].pricebeforediskon>0){
            if(arr_pasangan.length>0){
              for(let ap=0;ap<arr_pasangan.length;ap++){
                if(arr_pasangan[ap].length>0){
                  let hargatersedia =0;
                  let productScan ='';
                  let detail ='';
                  for(let isi=0;isi<arr_pasangan[ap].length;isi++){
                    hargatersedia+=arr_pasangan[ap][isi].price;
                  } 
                  diskon =hargatersedia - value;
                  this.transactionDiscount+=diskon;
                  let discountterbagi =0;                  
                  let sisa = 0;
                  for(let isi=0;isi<arr_pasangan[ap].length;isi++){
                    let product_id_isi =arr_pasangan[ap][isi].product_id;
                    let filterProduct = this.productSales.find(x=>x.id==product_id_isi);                    
                    if(filterProduct){
                      if(filterProduct.arr_kontribusi.length >0){
                        let yyy= filterProduct.arr_kontribusi.find(x=>x.promotion_detail_id==idpromodetail);
                        let disocunt =0;
                        if(yyy){                          
                          if(isi==arr_produk_dibeli.length-1){
                            disocunt = diskon-discountterbagi;
                            yyy.discount =disocunt;
                          }
                          else{
                            yyy.discount=Math.floor((diskon/hargatersedia) * filterProduct.price);
                            disocunt = yyy.discount;
                          }
                        }
                        discountterbagi+=disocunt;
                        productScan+=arr_pasangan[ap][isi].product_id+',';
                        sisa = diskon-discountterbagi;
                        if(isi==(arr_pasangan[ap].length-1)){
                          disocunt+=sisa;
                        }
                        filterProduct.valPromoNominal+=disocunt;
                        detail+=arr_pasangan[ap][isi].product_id+ ' discount '+disocunt+'.';
                      }
                    }
                  } 
                  
                  this.promoBundle.push({
                    'product_scan':productScan,
                    'detail':detail,
                    'promotion_detail_id':idpromodetail,
                    'qty_get_promo':1,
                    'discount':diskon,
                    'product_free_item':'',
                    'qty_free_item':0
                  })
                }
              }
            }
          }
          if(promoSpecialPrice[i].free>0){
            resetFreeByNominal = 0;
            //free item
            let free=promoSpecialPrice[i].free;
            this.countFreeItem = free;
            let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
            if(checkArray){
              let index = this.arrayFreeItemUsed.indexOf(checkArray);
              this.arrayFreeItemUsed[index]['free_item_max']=free;
              if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              resetFreeByNominal = 1;
                this.arrayFreeItemUsed[index]['free_item_used'] = 0;
                for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                  this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
                }
              }
              this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
              if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
                this.message_free = " Get Free Item";
              }
            }
            else{
              this.message_free = " Get Free Item";
              this.arrayFreeItemUsed.push({
                product_id:0,
                promo_id: idpromo,
                promo_id_detail: idpromodetail,
                free_item_max: free,
                free_item_remaining: free,
                free_item_used: 0,
                array_item:[]
              });
            }
          
            let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
            if(cari===undefined){
              this.promoFreeItem.push({
                'id':idpromodetail,
                'promo':promoSpecialPrice[i],
                'countFreeItem':free,
                'list_free_item':this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
              })
            }
            else{
              cari.countFreeItem=free;
            }
          }
        }
        if(resetFreeByNominal==1 ){
          let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
          if(checkArray!==undefined){
            checkArray.free_item_used = 0;
            checkArray.used = 0;
            for(let d = 0;d<checkArray.array_item.length;d++){
              checkArray.array_item[d].qty_free=0;
            }
          }
        }
        //console.log(adaPromoHargaKhusus);
      }
    }
  
    // check jika ada promo potongan dan free item by nominal belanja
    let promoFreeItemAndNominalByTotalBelanja =this.promotionDetailList.find(x => x.promotion_type_name === 'FREE ITEM AND NOMINAL' && parseInt(x.price_type)!==2 && x.product_id === null && x.promo_based_on == 1 );
    if(promoFreeItemAndNominalByTotalBelanja){
      let resetFreeByNominal = 0;
      let detailPromoFreeItemAndNominal = 'ada promo free item and nominal.';
      let minimum = promoFreeItemAndNominalByTotalBelanja.minimum_transaction;
      let diskon = parseInt(promoFreeItemAndNominalByTotalBelanja.value);
      let max_discount = promoFreeItemAndNominalByTotalBelanja.max_discount;
      let berlakuKelipatan = promoFreeItemAndNominalByTotalBelanja.multiple_flag;
      let getDiskon =false;
      let free = promoFreeItemAndNominalByTotalBelanja.free_qty;
      let max_free = promoFreeItemAndNominalByTotalBelanja.maximum_free_item;
      let idpromodetail = promoFreeItemAndNominalByTotalBelanja.id;
      let idpromo = promoFreeItemAndNominalByTotalBelanja.promotion_id;
      let price_type = promoFreeItemAndNominalByTotalBelanja.price_type;
      if (this.totalPayment < parseInt(minimum)){
        detailPromoFreeItemAndNominal += ' Belum memenuhi minimum belanja';
        diskon =0;
        resetFreeByNominal =1;
      }
      else{
        getDiskon=true;
        detailPromoFreeItemAndNominal += '. Sudah memenuhi minimum belanja';
        if(price_type==3){
          diskon = Math.floor((diskon/100) * this.totalPayment);
        }
        if(berlakuKelipatan=='1'){
          detailPromoFreeItemAndNominal += '. Berlaku kelipatan';
          if(price_type!=3){
            if(parseInt(minimum)>0){
              diskon = diskon * Math.floor(this.totalPayment/minimum);
            }
          }
          if(parseInt(minimum)>0){
            free = free * Math.floor(this.totalPayment/parseInt(minimum));
          }
        }
        else{
          detailPromoFreeItemAndNominal += '. Tidak berlaku kelipatan';
        }
        if (max_discount >0  && diskon > max_discount){
          diskon = max_discount;
        }
        if (max_free >0  && free > max_free){
          free = max_free;
        }
        this.promoItemNominal=promoFreeItemAndNominalByTotalBelanja;
      }
      //cek produk yg dibutuhkan
      
      let syaratTerpenuhi =this.cekSyarat(promoFreeItemAndNominalByTotalBelanja);
      let detail='';
      if(getDiskon && syaratTerpenuhi=='Y'){
        detailPromoFreeItemAndNominal+='sudah memenuhi syarat';
        //kontribusi diskon
        let hargasebelumdiskon=0;
        for(let ps=0;ps<this.productSales.length;ps++){
          let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
          hargasebelumdiskon+=hargaTanpaFreeItem;
        }
        let diskonterbagi=0;
        for(let ps=0;ps<this.productSales.length;ps++){
          let diskonkontribusi=0;
          if(ps!=(this.productSales.length-1)){
            diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
            diskonterbagi+=diskonkontribusi;
          }
          else{
            diskonkontribusi= diskon-diskonterbagi;
          }
          this.productSales[ps].valPromoNominal+=diskonkontribusi;          
          detail+=this.productSales[ps].id+ ' discount '+diskonkontribusi+'.';
        }//k
        this.transactionDiscount += (diskon);
        detailPromoFreeItemAndNominal +='\nMendapat diskon sebesar:'+(diskon);
        detailPromoFreeItemAndNominal +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
        //free item
        detailPromoFreeItemAndNominal +='\nMendapat Free Item sebanyak '+free;
        this.countFreeItem = free;
        let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
        if(checkArray){
          let index = this.arrayFreeItemUsed.indexOf(checkArray);
          this.arrayFreeItemUsed[index]['free_item_max']=free;
          if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
            resetFreeByNominal = 1;
            this.arrayFreeItemUsed[index]['free_item_used'] = 0;
            for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
              this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
            }
          }
          this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
          if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
            this.message_free = " Get Free Item";
          }
        }
        else{
          this.message_free = " Get Free Item";
          this.arrayFreeItemUsed.push({
            product_id:0,
            promo_id: idpromo,
            promo_id_detail: idpromodetail,
            free_item_max: free,
            free_item_remaining: free,
            free_item_used: 0,
            array_item:[]
          });
        }
        this.listFreeItemByTotalBelanja = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
      
        let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
        if(cari===undefined){
          this.promoFreeItem.push({
            'id':idpromodetail,
            'promo':promoFreeItemAndNominalByTotalBelanja,
            'countFreeItem':free,
            'list_free_item':this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
          })
        }
        else{
          cari.countFreeItem=free;
        }
        if(resetFreeByNominal==1 ){
          for(let a=0;a < this.listFreeItemByTotalBelanja.length;a++){
            let searchFreeProductByNominal = this.productSales.filter(x => x.id == this.listFreeItemByTotalBelanja[a]['product_id']);
            if(searchFreeProductByNominal){
              for(let a=0;a<searchFreeProductByNominal.length;a++){
                searchFreeProductByNominal[a]['qtyFree'] = 0;
              }
            }
          }
        }
      }
      else{
        detailPromoFreeItemAndNominal+='belum memenuhi syarat';
        this.countFreeItem = 0;
        let getProductWithPromo =this.productSales.filter(x=>x.arrayPromoFreeItem.length>0);
        if(getProductWithPromo.length>0){
          for(let p=0;p<getProductWithPromo.length;p++){
            let gp = getProductWithPromo[p].arrayPromoFreeItem;
            let diffqty =0;
            
            for(let gpp=0;gpp<gp.length;gpp++){
              if(gp[gpp].promotion_detail_id==idpromodetail){
                diffqty =gp[gpp].qty;
               // gp[gpp].qty =0;
              }
            }
            getProductWithPromo[p].qtyFree =  getProductWithPromo[p].qtyFree-diffqty;
          }
        }
        let getFreeItem =this.arrayFreeItemUsed.filter(x=>x.promo_id_detail==idpromodetail);
        if(getFreeItem.length>0){
          for(let p=0;p<getFreeItem.length;p++){
            getFreeItem[p].free_item_used=0;
            getFreeItem[p].array_item=[];
          }
        }
        let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
        if(cari!==undefined){
          let index = this.promoFreeItem.indexOf(cari);
          this.promoFreeItem.splice(index, 1);
        }
      }
      let caripromobundle=this.promoBundleFreeItem.find(x=>x.promotion_detail_id==idpromodetail);
      if(caripromobundle!==undefined){
        caripromobundle.discount=diskon;
        caripromobundle.detail=detail;
      }else{
        this.promoBundleFreeItem.push({
          'product_scan':0,
          'promotion_detail_id':idpromodetail,
          'qty_get_promo':1,
          'discount':diskon,
          'product_free_item':'',
          'qty_free_item':0,
          'detail':detail
        })
      }
    }
    
    // check jika ada promo bertingkat nominal belanja
    let firstBertingkatByTotalBelanja =this.promotionDetailList.find(x => x.promotion_type_name === 'PROMO BERTINGKAT' && x.product_id === null && x.promo_based_on == 1);
    let id_bertingkat =0;
    if(firstBertingkatByTotalBelanja!==undefined){
      id_bertingkat = firstBertingkatByTotalBelanja.promotion_id
    }

    //cari promo yg memenuhi syarat
    let requirementFullfill =this.promotionDetailList.filter(x => x.promotion_id==id_bertingkat);
    
    let detailPromoDiskonBertingkat='';
    this.idfbertingkatbytotal =0;
    if(requirementFullfill.length>0){
      //cari promo bertingkat yg terpenuhi
      for(let a=0;a<requirementFullfill.length;a++){
        let minimum_transaction = requirementFullfill[a].minimum_transaction;
        let idpromo = requirementFullfill[a].id;
        if(this.totalPayment < parseInt(minimum_transaction)){
          detailPromoDiskonBertingkat+='Belum memenuhi minimum belanja.';
          let checkArray = this.arrayFreeItemUsed.find(x => x.promo_id_detail==idpromo);
          if(checkArray){
            checkArray.free_item_max=0;
            checkArray.free_item_used= 0;
            checkArray.free_item_remaining=0;
            let array_item = checkArray.array_item;
            if(array_item.length>0){
              for(let ai=0;ai<array_item.length;ai++){
                array_item[ai].qty_free=0;
              }
            }
          }
          let cari =this.promoFreeItem.find(x=>x.promo_bertingkat=='Y' && x.id==idpromo);
          if(cari!==undefined){
            cari.countFreeItem=0;
          }  
          let caripromobundle =this.promoBundle.find(x=>x.promotion_detail_id==idpromo);
          if(caripromobundle!==undefined){
            caripromobundle.discount=0;
            caripromobundle.qty_get_promo=0;
            caripromobundle.product_free_item='';
            caripromobundle.qty_free_item=0;
          }        
        }
        else{
          detailPromoDiskonBertingkat+='Sudah memenuhi minimum belanja.';
          let syaratTerpenuhi =this.cekSyarat(requirementFullfill[a]);
          if(syaratTerpenuhi=='Y'){
            detailPromoDiskonBertingkat+='sudah memenuhi syarat';
            this.idfbertingkatbytotal = requirementFullfill[a].id;
          }
        }
      }
    }
    let promoBertingkatByTotalBelanja =this.promotionDetailList.find(x => x.id==this.idfbertingkatbytotal);
    if(promoBertingkatByTotalBelanja!==undefined){
      let diskonValue = promoBertingkatByTotalBelanja.value;
      let discount_type = promoBertingkatByTotalBelanja.discount_type;
      let max_discount = promoBertingkatByTotalBelanja.max_discount;
      let max_free = promoBertingkatByTotalBelanja.maximum_free_item;
      let diskon =0;
      let free=0;
      let idpromodetail = promoBertingkatByTotalBelanja.id;
      let idpromo = promoBertingkatByTotalBelanja.promotion_id;
      let checkArray = this.arrayFreeItemUsed.find(x => x.promo_id==idpromo && x.promo_id_detail!=idpromodetail);
      if(checkArray){
        checkArray.free_item_max=0;
        checkArray.free_item_used= 0;
        checkArray.free_item_remaining=0;
        let array_item = checkArray.array_item;
        if(array_item.length>0){
          for(let ai=0;ai<array_item.length;ai++){
            array_item[ai].qty_free=0;
          }
        }
      }

      if(discount_type==2){
        diskon= Math.floor(parseInt(diskonValue)/100 * this.totalPayment);
        if (max_discount >0  && diskon > parseInt(max_discount)){
          diskon = max_discount;
        }
        this.transactionDiscount+=diskon;
        //kontribusi diskon        
        let hargasebelumdiskon=0;
        for(let ps=0;ps<this.productSales.length;ps++){
          let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
          hargasebelumdiskon+=hargaTanpaFreeItem;
        }
        let diskonterbagi=0;
        let detail='';
        for(let ps=0;ps<this.productSales.length;ps++){
          let diskonkontribusi=0;
          if(ps!=(this.productSales.length-1)){
            diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price* parseInt(this.productSales[ps].qty_withoutpromo)));
            diskonterbagi+=diskonkontribusi;
          }
          else{
            diskonkontribusi= diskon-diskonterbagi;
          }
          this.productSales[ps].valPromoNominal+=diskonkontribusi;
          if(diskonkontribusi>0){
            detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
          } 
        }
        detail = detail.replace(/<br>$/, "");
        let caripromobundle =this.promoBundle.find(x=>x.promotion_detail_id==idpromodetail);
        if(caripromobundle!==undefined){
         caripromobundle.discount=diskon;
         caripromobundle.detail=detail;
        }
        else{
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':idpromodetail,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'detail':detail
          })
        }
      }
      else if(discount_type==1){
        diskon = Math.floor(parseInt(diskonValue));
        if (max_discount >0  && diskon > parseInt(max_discount)){
          diskon = max_discount;
        }
        this.transactionDiscount+=diskon;        
        //kontribusi harga
        let hargasebelumdiskon=0;
        for(let ps=0;ps<this.productSales.length;ps++){
          let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
          hargasebelumdiskon+=hargaTanpaFreeItem;
        }
        let diskonterbagi=0;
        let detail='';
        for(let ps=0;ps<this.productSales.length;ps++){
          let diskonkontribusi=0;
          if(ps!=(this.productSales.length-1)){
            diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price* parseInt(this.productSales[ps].qty_withoutpromo)));
            diskonterbagi+=diskonkontribusi;
          }
          else{
            diskonkontribusi= diskon-diskonterbagi;
          }
          this.productSales[ps].valPromoNominal+=diskonkontribusi;
          if(diskonkontribusi>0){
            detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
          }
        }
        //k
        let caripromobundle =this.promoBundle.find(x=>x.promotion_detail_id==idpromodetail);
        detail = detail.replace(/<br>$/, "");
        if(caripromobundle!==undefined){
          caripromobundle.discount=diskon;
          caripromobundle.detail=detail;
        }
        else{
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':idpromodetail,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'detail':detail
          })
        }
      }
      else if(discount_type==3){
        free = parseInt(promoBertingkatByTotalBelanja.free_qty);
        if (max_free >0  && free > (max_free)){
          free = max_free;
        }
        let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id==idpromo);
        if(checkArray){
          let index = this.arrayFreeItemUsed.indexOf(checkArray);
          this.arrayFreeItemUsed[index]['free_item_max']=free;
          this.arrayFreeItemUsed[index]['promo_id_detail']=idpromodetail;
          if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
            this.arrayFreeItemUsed[index]['free_item_used'] = 0;
          }
          this.arrayFreeItemUsed[index]['free_item_remaining']=(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
          if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
            this.message_free = " Get Free Item";
          }
        }
        else{
          this.message_free = " Get Free Item";
          this.arrayFreeItemUsed.push({
            product_id:0,
            promo_id: idpromo,
            promo_id_detail: idpromodetail,
            free_item_max: free,
            free_item_remaining: free,
            free_item_used: 0,
            array_item:[]
          });
        }
        let cari =this.promoFreeItem.find(x=>x.promo_bertingkat=='Y');
        if(cari===undefined){
          this.promoFreeItem.push({
            'promo_bertingkat':'Y',
            'id':idpromodetail,
            'promo':promoBertingkatByTotalBelanja,
            'countFreeItem':free,
            'list_free_item':this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
          })
        }
        else{
          cari.id=idpromodetail;
          cari.promo=promoBertingkatByTotalBelanja;
          cari.countFreeItem=free;
          cari.list_free_item=this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
        }        
      }
    }
    //free item (nominal), item gabungan misal belanja 200K gabungan produk tertentu free difusser
    let promoFreeItemNominalSelectedProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM (NOMINAL)' && x.promo_based_on == 2 && x.qty_type==1);
    if(promoFreeItemNominalSelectedProduct.length > 0){
      let detailPromoFreeItemByNominalSelectedProduct ='Ada promo free item (nominal) by nominal gabungan';
      for(let a =0; a < promoFreeItemNominalSelectedProduct.length;a++){
        let idpromodetail = promoFreeItemNominalSelectedProduct[a].id;
        let idpromo = promoFreeItemNominalSelectedProduct[a].promotion_id;
        let minimum_transaction = promoFreeItemNominalSelectedProduct[a].minimum_transaction;
        let multipleFlag = promoFreeItemNominalSelectedProduct[a].multiple_flag;
        let free = promoFreeItemNominalSelectedProduct[a].free_qty;
        let productId = promoFreeItemNominalSelectedProduct[a].product_id;
        let promoBasedOn = promoFreeItemNominalSelectedProduct[a].promo_based_on;
        let arrProduct = [];        
        if(productId!=null){
          arrProduct= productId.split(';');
        }
        let nominalGabungan=0;
        for(let cp=0;cp<this.productSales.length;cp++){
          if(promoBasedOn=='0'){
            nominalGabungan+=(parseInt(this.productSales[cp].qty_withoutpromo)*parseInt(this.productSales[cp].price));
          }
          else{
            let checkProduct =arrProduct.find(x => x==this.productSales[cp].id);
            if(checkProduct){
              nominalGabungan+=(parseInt(this.productSales[cp].qty_withoutpromo)*parseInt(this.productSales[cp].price));
            }
          }
        }
        let getDiskon=false;
        if(nominalGabungan < parseInt(minimum_transaction)){
          free = 0;
        }
        else{
          getDiskon=true;
          detailPromoFreeItemByNominalSelectedProduct+='Sudah memenuhi minimum belanja.';
          if(multipleFlag=='1'){
            detailPromoFreeItemByNominalSelectedProduct+='Berlaku kelipatan.';
            free = free * Math.floor(nominalGabungan/parseInt(minimum_transaction));
          }
          else{
            detailPromoFreeItemByNominalSelectedProduct+='Tidak belaku kelipatan';
          }
          let maximum = promoFreeItemNominalSelectedProduct[a].maximum_free_item;
          if(maximum>0 && free> maximum){
            free = maximum;
          }
        }
        let syaratTerpenuhi =this.cekSyarat(promoFreeItemNominalSelectedProduct[a]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoFreeItemByNominalSelectedProduct+='sudah memenuhi syarat';
          detailPromoFreeItemByNominalSelectedProduct +='\nMendapat Free Item sebanyak '+free;
          this.countFreeItem = free;
          let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
          if(checkArray){
            let index = this.arrayFreeItemUsed.indexOf(checkArray);
            this.arrayFreeItemUsed[index]['free_item_max']=free;
            if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
            if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
              this.message_free = " Get Free Item";
            }
          }
          else{
            this.message_free = " Get Free Item";
            this.arrayFreeItemUsed.push({
              product_id:0,
              promo_id: idpromo,
              promo_id_detail: idpromodetail,
              free_item_max: free,
              free_item_remaining: free,
              free_item_used: 0,
              array_item:[]
            });
          }
          this.listFreeItemByTotalBelanja = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
      
          let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
          if(cari===undefined){
            this.promoFreeItem.push({
              'id':idpromodetail,
              'promo':promoFreeItemNominalSelectedProduct[a],
              'countFreeItem':free,
              'list_free_item':this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
            })
          }
          else{
            cari.countFreeItem=free;
          }
        }
        else{
          let getProductWithPromo =this.productSales.filter(x=>x.arrayPromoFreeItem.length>0);
          if(getProductWithPromo.length>0){
            for(let p=0;p<getProductWithPromo.length;p++){
              let gp = getProductWithPromo[p].arrayPromoFreeItem;
              let diffqty =0;
              for(let gpp=0;gpp<gp.length;gpp++){
                if(gp[gpp].promotion_detail_id==idpromodetail){
                  diffqty +=gp[gpp].qty;
                  gp[gpp].qty =0;
                }
              }
              getProductWithPromo[p].qtyFree = getProductWithPromo[p].qtyFree-diffqty;
            }
          }
          let getFreeItem =this.arrayFreeItemUsed.filter(x=>x.promo_id_detail==idpromodetail);
          if(getFreeItem.length>0){
            for(let p=0;p<getFreeItem.length;p++){
              getFreeItem[p].free_item_used=0;
              getFreeItem[p].array_item=[];
            }
          }
          let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
          if(cari!==undefined){
            let index = this.promoFreeItem.indexOf(cari);
            this.promoFreeItem.splice(index, 1);
          }
        }
      }
     // console.log(detailPromoFreeItemByNominalSelectedProduct);
    }
    // free item and nominal, qty gabungan misal beli 5pcs aromaterapi apapun diskon dan dapat free item
    let promoFreeItemAndNominalByQtyGabunganProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM AND NOMINAL' && (parseInt(x.price_type) == 1 || parseInt(x.price_type)==3 )&& x.promo_based_on == 3 && x.qty_type=='1');
    if(promoFreeItemAndNominalByQtyGabunganProduct.length > 0){
      let detailPromoFreeItemAndNominalByQtyNominalProduct = 'Ada promo free item and nominal by gabungan Nominal produk.';
      for(let a =0; a< promoFreeItemAndNominalByQtyGabunganProduct.length;a++){
        let diskon= parseInt(promoFreeItemAndNominalByQtyGabunganProduct[a].value);
        let max_discount = promoFreeItemAndNominalByQtyGabunganProduct[a].max_discount;
        let product = promoFreeItemAndNominalByQtyGabunganProduct[a].product_id;
        let idpromodetail = promoFreeItemAndNominalByQtyGabunganProduct[a].id;
        let promoBasedOn = promoFreeItemAndNominalByQtyGabunganProduct[a].promo_based_on;
        let minimum = parseInt(promoFreeItemAndNominalByQtyGabunganProduct[a].minimum_buy);
        let berlakuKelipatan = promoFreeItemAndNominalByQtyGabunganProduct[a].multiple_flag;
        let free = promoFreeItemAndNominalByQtyGabunganProduct[a].free_qty;
        let idpromo = promoFreeItemAndNominalByQtyGabunganProduct[a].promotion_id;
        let price_type = promoFreeItemAndNominalByQtyGabunganProduct[a].price_type;
        let arrProduct = [];
        if(product!=null){
          arrProduct= product.split(';');
        }
        let nominalGabungan = 0;
        let qtyGabungan = 0;
        for(let cp=0;cp<this.productSales.length;cp++){
          if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
            nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
            qtyGabungan+=parseInt(this.productSales[cp].qty_withoutpromo);
          }
          else{
            let checkProduct =arrProduct.find(x => x==this.productSales[cp].id);
            if(checkProduct){
              nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
              qtyGabungan+=parseInt(this.productSales[cp].qty_withoutpromo);
            }
          }
        }
        let getDiskon =false;
        if (qtyGabungan < (minimum)){
          detailPromoFreeItemAndNominalByQtyNominalProduct += ' Belum memenuhi minimum belanja';
          diskon=0;
        }
        else{
          getDiskon =true;
          detailPromoFreeItemAndNominalByQtyNominalProduct += '. Sudah memenuhi minimum belanja';
          
          if(berlakuKelipatan=='1'){
            detailPromoFreeItemAndNominalByQtyNominalProduct += '. Berlaku kelipatan';
            if(minimum>0){
              diskon = diskon * Math.floor(qtyGabungan/minimum);
              free = free *  Math.floor(qtyGabungan/minimum);
            }
          }
          else{
            detailPromoFreeItemAndNominalByQtyNominalProduct += '. Tidak berlaku kelipatan';
          }
          if(price_type==3){
            diskon = Math.floor((diskon/100) * nominalGabungan);
          }
          if (max_discount >0  && diskon > max_discount){
            diskon = max_discount;
          }
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoFreeItemAndNominalByQtyGabunganProduct[a]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoFreeItemAndNominalByQtyNominalProduct+='sudah memenuhi syarat';
          this.transactionDiscount += (diskon);
          detailPromoFreeItemAndNominalByQtyNominalProduct +='\nMendapat diskon sebesar:'+(diskon);
          detailPromoFreeItemAndNominalByQtyNominalProduct +='\nMendapat Free Item sebanyak '+free;
          detailPromoFreeItemAndNominalByQtyNominalProduct +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
          let hargasebelumdiskon=0;
          //diskon nominal
          for(let ps=0;ps<this.productSales.length;ps++){
            let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              hargasebelumdiskon+=hargaTanpaFreeItem;
            }
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                hargasebelumdiskon+=hargaTanpaFreeItem;
              }
            }
          }
          let diskonterbagi=0;
          let detail ='';
          for(let ps=0;ps<this.productSales.length;ps++){
            let diskonkontribusi=0;
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              if(ps!=(this.productSales.length-1)){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              else{
                diskonkontribusi= diskon-diskonterbagi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }
            if(ps==(this.productSales.length-1)){// kalau diskon masih sisa, dan sudah produk terakhir, masukin sisa ke salah satu yg dpt promo
              if(diskonterbagi<diskon){
                let sisa=(diskon-diskonterbagi);
                diskonkontribusi+=sisa;
                let firstdptdiskon = this.productSales.find(x => x.id==arrProduct[(arrProduct.length-1)]);
                if(firstdptdiskon){
                  firstdptdiskon.valPromoNominal+=sisa;
                }
              }
            }
            if(diskonkontribusi>0){
              detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
            }
          }
          detail = detail.replace(/<br>$/, "");
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':idpromodetail,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'detail':detail
          });
          //free item
          let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
          if(checkArray){
            let index = this.arrayFreeItemUsed.indexOf(checkArray);
            this.arrayFreeItemUsed[index]['free_item_max']=free;
            if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
            if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
              this.message_free = " Get Free Item";
            }
          }
          else{
            this.message_free = " Get Free Item";
            this.arrayFreeItemUsed.push({
              product_id:0,
              promo_id: idpromo,
              promo_id_detail: idpromodetail,
              free_item_max: free,
              free_item_remaining: free,
              free_item_used: 0,
              array_item:[]
            });
          }
          this.listFreeItemByTotalBelanja = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
          let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
          if(cari===undefined){
            this.promoFreeItem.push({
              'id':idpromodetail,
              'promo':promoFreeItemAndNominalByQtyGabunganProduct[a],
              'countFreeItem':free,
              'list_free_item':this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
            })
          }
          else{
            cari.countFreeItem=free;
          }
        }
        else{
          detailPromoFreeItemAndNominalByQtyNominalProduct+='belum memenuhi syarat';
          //remove free item
          let getProductWithPromo =this.productSales.filter(x=>x.arrayPromoFreeItem.length>0);
          if(getProductWithPromo.length>0){
            for(let p=0;p<getProductWithPromo.length;p++){
              let gp = getProductWithPromo[p].arrayPromoFreeItem;
              let diffqty =0;
              for(let gpp=0;gpp<gp.length;gpp++){
                if(gp[gpp].promotion_detail_id==idpromodetail){
                  diffqty +=gp[gpp].qty;
                  gp[gpp].qty =0;
                }
              }
              getProductWithPromo[p].qtyFree = getProductWithPromo[p].qtyFree-diffqty;
            }
          }
          let getFreeItem =this.arrayFreeItemUsed.filter(x=>x.promo_id_detail==idpromodetail);
          if(getFreeItem.length>0){
            for(let p=0;p<getFreeItem.length;p++){
              getFreeItem[p].free_item_used=0;
              getFreeItem[p].array_item=[];
            }
          }
          let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
          if(cari!==undefined){
            let index = this.promoFreeItem.indexOf(cari);
            this.promoFreeItem.splice(index, 1);
          }
        }
      }
      //console.log(detailPromoFreeItemAndNominalByQtyNominalProduct);
    }
    // free item and nominal, nominal gabungan misal beli 50K aromaterapi apapun diskon dan dapat free item
    let promoFreeItemAndNominalByNominalGabunganProduct = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM AND NOMINAL' && parseInt(x.price_type) !== 2 && x.promo_based_on == 2 && x.qty_type=='1');
    if(promoFreeItemAndNominalByNominalGabunganProduct.length > 0){
      let detailPromoFreeItemAndNominalByGabunganNominalProduct = 'Ada promo free item and nominal by gabungan Nominal produk.';
      for(let a =0; a< promoFreeItemAndNominalByNominalGabunganProduct.length;a++){
        let diskon= parseInt(promoFreeItemAndNominalByNominalGabunganProduct[a].value);
        let max_discount = promoFreeItemAndNominalByNominalGabunganProduct[a].max_discount;
        let product = promoFreeItemAndNominalByNominalGabunganProduct[a].product_id;
        let idpromodetail = promoFreeItemAndNominalByNominalGabunganProduct[a].id;
        let promoBasedOn = promoFreeItemAndNominalByNominalGabunganProduct[a].promo_based_on;
        let minimum_transaction = parseInt(promoFreeItemAndNominalByNominalGabunganProduct[a].minimum_transaction);
        let berlakuKelipatan = promoFreeItemAndNominalByNominalGabunganProduct[a].multiple_flag;
        let free = promoFreeItemAndNominalByNominalGabunganProduct[a].free_qty;
        let idpromo = promoFreeItemAndNominalByNominalGabunganProduct[a].promotion_id;
        let price_type = promoFreeItemAndNominalByNominalGabunganProduct[a].price_type;
        let arrProduct = [];
        if(product!=null){
          arrProduct= product.split(';');
        }
        let nominalGabungan =0;
        for(let cp=0;cp<this.productSales.length;cp++){
          if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
            nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
          }
          else{
            let checkProduct =arrProduct.find(x => x==this.productSales[cp].id);
            if(checkProduct){
              nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
            }
          }
        }
        let getDiskon =false;
        if (nominalGabungan < (minimum_transaction)){
          detailPromoFreeItemAndNominalByGabunganNominalProduct += ' Belum memenuhi minimum belanja';
          diskon=0;
        }
        else{
          getDiskon =true;
          detailPromoFreeItemAndNominalByGabunganNominalProduct += '. Sudah memenuhi minimum belanja';
          
          if(berlakuKelipatan=='1'){
            detailPromoFreeItemAndNominalByGabunganNominalProduct += '. Berlaku kelipatan';
            if(minimum_transaction>0){
              diskon = diskon * Math.floor(nominalGabungan/minimum_transaction);
              free = free *  Math.floor(nominalGabungan/minimum_transaction);
            }
          }
          else{
            detailPromoFreeItemAndNominalByGabunganNominalProduct += '. Tidak berlaku kelipatan';
          }
          if(price_type==3){
            diskon = Math.floor((diskon/100) * nominalGabungan);
          }
          if (max_discount >0  && diskon > max_discount){
            diskon = max_discount;
          }
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoFreeItemAndNominalByNominalGabunganProduct[a]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoFreeItemAndNominalByGabunganNominalProduct+='sudah memenuhi syarat';
          this.transactionDiscount += (diskon);
          detailPromoFreeItemAndNominalByGabunganNominalProduct +='\nMendapat diskon sebesar:'+(diskon);
          detailPromoFreeItemAndNominalByGabunganNominalProduct +='\nMendapat Free Item sebanyak '+free;
          detailPromoFreeItemAndNominalByGabunganNominalProduct +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
          let hargasebelumdiskon=0;
          //diskon nominal
          for(let ps=0;ps<this.productSales.length;ps++){
            let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              hargasebelumdiskon+=hargaTanpaFreeItem;
            }
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                hargasebelumdiskon+=hargaTanpaFreeItem;
              }
            }
          }
          let diskonterbagi=0;
          let detail ='';
          for(let ps=0;ps<this.productSales.length;ps++){
            let diskonkontribusi=0;
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              if(ps!=(this.productSales.length-1)){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              else{
                diskonkontribusi= diskon-diskonterbagi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }//
            if(ps==(this.productSales.length-1)){// kalau diskon masih sisa, dan sudah produk terakhir, masukin sisa ke salah satu yg dpt promo
              if(diskonterbagi<diskon){
                let sisa=(diskon-diskonterbagi);
                let firstdptdiskon =  this.productSales.find(x => x.id==arrProduct[(arrProduct.length-1)]);
                if(firstdptdiskon){
                  diskonkontribusi+=sisa;
                  firstdptdiskon.valPromoNominal+=sisa;
                }
              }
            }
            if(diskonkontribusi>0){
              detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
            }
          }
          detail = detail.replace(/<br>$/, "");
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':idpromodetail,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'detail':detail
          });
          //free item
          let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
          if(checkArray){
            let index = this.arrayFreeItemUsed.indexOf(checkArray);
            this.arrayFreeItemUsed[index]['free_item_max']=free;
            if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
            if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
              this.message_free = " Get Free Item";
            }
          }
          else{
            this.message_free = " Get Free Item";
            this.arrayFreeItemUsed.push({
              product_id:0,
              promo_id: idpromo,
              promo_id_detail: idpromodetail,
              free_item_max: free,
              free_item_remaining: free,
              free_item_used: 0,
              array_item:[]
            });
          }
          this.listFreeItemByTotalBelanja = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
          let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
          if(cari===undefined){
            this.promoFreeItem.push({
              'id':idpromodetail,
              'promo':promoFreeItemAndNominalByNominalGabunganProduct[a],
              'countFreeItem':free,
              'list_free_item':this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
            })
          }
          else{
            cari.countFreeItem=free;
          }
        }
        else{
          detailPromoFreeItemAndNominalByGabunganNominalProduct+='belum memenuhi syarat';
          //remove free item
          let getProductWithPromo =this.productSales.filter(x=>x.arrayPromoFreeItem.length>0);
          if(getProductWithPromo.length>0){
            for(let p=0;p<getProductWithPromo.length;p++){
              let gp = getProductWithPromo[p].arrayPromoFreeItem;
              let diffqty =0;
              for(let gpp=0;gpp<gp.length;gpp++){
                if(gp[gpp].promotion_detail_id==idpromodetail){
                  diffqty +=gp[gpp].qty;
                  gp[gpp].qty =0;
                }
              }
              getProductWithPromo[p].qtyFree = getProductWithPromo[p].qtyFree-diffqty;
            }
          }
          let getFreeItem =this.arrayFreeItemUsed.filter(x=>x.promo_id_detail==idpromodetail);
          if(getFreeItem.length>0){
            for(let p=0;p<getFreeItem.length;p++){
              getFreeItem[p].free_item_used=0;
              getFreeItem[p].array_item=[];
            }
          }
          let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
          if(cari!==undefined){
            let index = this.promoFreeItem.indexOf(cari);
            this.promoFreeItem.splice(index, 1);
          }
        }
      }
      //console.log(detailPromoFreeItemAndNominalByGabunganNominalProduct);
    }
    //free item(item), item gabungan misal beli 4 aromaterapi apapun free difusser
    let promoFreeItemItemSelectedProduct: any = this.promotionDetailList.filter(x => x.promotion_type_name === 'FREE ITEM (ITEM)' && (x.promo_based_on == 2 || x.promo_based_on==0) && x.qty_type==1);
    if(promoFreeItemItemSelectedProduct.length > 0){
      let detailPromoFreeItemItemSelectedProduct ='Ada promo free item (item)';
      for(let a =0; a < promoFreeItemItemSelectedProduct.length;a++){
        let idpromodetail = promoFreeItemItemSelectedProduct[a].id;
        let idpromo = promoFreeItemItemSelectedProduct[a].promotion_id;
        let minimum = promoFreeItemItemSelectedProduct[a].minimum_buy;
        let multipleFlag = promoFreeItemItemSelectedProduct[a].multiple_flag;
        let free = promoFreeItemItemSelectedProduct[a].free_qty;
        let productId = promoFreeItemItemSelectedProduct[a].product_id;
        let promoBasedOn = promoFreeItemItemSelectedProduct[a].promo_based_on;
        let product = promoFreeItemItemSelectedProduct[a].product;
        let arrProduct = [];        
        if(productId!=null){
          arrProduct= productId.split(';');
        }
        let qtyGabungan =0;
        for(let cp=0;cp<this.productSales.length;cp++){
          if(product!='2'){
            qtyGabungan+=parseInt(this.productSales[cp].qty_withoutpromo);
          }
          else{
            let checkProduct =arrProduct.find(x => x==this.productSales[cp].id);
            if(checkProduct){
              qtyGabungan+=parseInt(this.productSales[cp].qty_withoutpromo);
            }
          }
        }
        let getDiskon=false;
        if(qtyGabungan < parseInt(minimum)){
          free = 0;
        }
        else{
          getDiskon=true;
          detailPromoFreeItemItemSelectedProduct+='Sudah memenuhi minimum belanja.';
          if(multipleFlag=='1'){
            detailPromoFreeItemItemSelectedProduct+='Berlaku kelipatan.';
            free = free * Math.floor(qtyGabungan/parseInt(minimum));
          }
          else{
            detailPromoFreeItemItemSelectedProduct+='Tidak belaku kelipatan';
          }
          let maximum = promoFreeItemItemSelectedProduct[a].maximum_free_item;
          if(maximum>0 && free> maximum){
            free = maximum;
          }
        }
        let syaratTerpenuhi =this.cekSyarat(promoFreeItemItemSelectedProduct[a]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoFreeItemItemSelectedProduct+='sudah memenuhi syarat';
          detailPromoFreeItemItemSelectedProduct +='\nMendapat Free Item sebanyak '+free;
          this.countFreeItem = free;
          let checkArray = this. arrayFreeItemUsed.find(x => x.promo_id_detail==idpromodetail);
          if(checkArray){
            let index = this.arrayFreeItemUsed.indexOf(checkArray);
            this.arrayFreeItemUsed[index]['free_item_max']=free;
            if(this.arrayFreeItemUsed[index]['free_item_used'] > free){
              this.arrayFreeItemUsed[index]['free_item_used'] = 0;
              for(let d = 0;d<this.arrayFreeItemUsed[index]['array_item'].length;d++){
                this.arrayFreeItemUsed[index]['array_item'][d]['qty_free']=0;
              }
            }
            this.arrayFreeItemUsed[index]['free_item_remaining']=parseInt(free)-parseInt(this.arrayFreeItemUsed[index]['free_item_used']);
            if(parseInt(this.arrayFreeItemUsed[index]['free_item_remaining']) >0 ){
              this.message_free = " Get Free Item";
            }
          }
          else{
            this.message_free = " Get Free Item";
            this.arrayFreeItemUsed.push({
              product_id:0,
              promo_id: idpromo,
              promo_id_detail: idpromodetail,
              free_item_max: free,
              free_item_remaining: free,
              free_item_used: 0,
              array_item:[]
            });
          }
          this.listFreeItemByTotalBelanja = this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail);
          let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
          if(cari===undefined){
            this.promoFreeItem.push({
              'id':idpromodetail,
              'promo':promoFreeItemItemSelectedProduct[a],
              'countFreeItem':free,
              'list_free_item':this.promotionFreeItemList.filter(x => x.promotion_detail_id == idpromodetail)
            })
          }
          else{
            cari.countFreeItem=free;
          }
        }
        else{
          let getProductWithPromo =this.productSales.filter(x=>x.arrayPromoFreeItem.length>0);
          if(getProductWithPromo.length>0){
            for(let p=0;p<getProductWithPromo.length;p++){
              let gp = getProductWithPromo[p].arrayPromoFreeItem;
              let diffqty =0;
              for(let gpp=0;gpp<gp.length;gpp++){
                if(gp[gpp].promotion_detail_id==idpromodetail){
                  diffqty +=gp[gpp].qty;
                  gp[gpp].qty =0;
                }
              }
              getProductWithPromo[p].qtyFree = getProductWithPromo[p].qtyFree-diffqty;
            }
          }
          let getFreeItem =this.arrayFreeItemUsed.filter(x=>x.promo_id_detail==idpromodetail);
          if(getFreeItem.length>0){
            for(let p=0;p<getFreeItem.length;p++){
              getFreeItem[p].free_item_used=0;
              getFreeItem[p].array_item=[];
            }
          }
          let cari =this.promoFreeItem.find(x=>x.id==idpromodetail);
          if(cari!==undefined){
            let index = this.promoFreeItem.indexOf(cari);
            this.promoFreeItem.splice(index, 1);
          }
        }
      }
     // console.log(detailPromoFreeItemItemSelectedProduct);
    }
    // persentase, nominal gabungan misal beli 50K aromaterapi apapun diskon
    let promoPersenByNominalGabunganProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'PERSENTASE' && x.promo_based_on == 2 && x.qty_type=='1');
    if(promoPersenByNominalGabunganProduct.length > 0){
      let detailPromoPersenByGabunganGabunganProduct = 'Ada promo persentase by gabungan Nominal produk.';
      for(let a =0; a< promoPersenByNominalGabunganProduct.length;a++){
        let diskonValue = parseInt(promoPersenByNominalGabunganProduct[a].value);
        let max_discount = promoPersenByNominalGabunganProduct[a].max_discount;
        let product = promoPersenByNominalGabunganProduct[a].product_id;
        let idpromodetail = promoPersenByNominalGabunganProduct[a].id;
        let promoBasedOn = promoPersenByNominalGabunganProduct[a].promo_based_on;
        let minimum_transaction = parseInt(promoPersenByNominalGabunganProduct[a].minimum_transaction);
        let arrProduct = [];
        let diskon=0;
        if(product!=null){
          arrProduct= product.split(';');
        }
        let nominalGabungan =0;
        for(let cp=0;cp<this.productSales.length;cp++){
          if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
            nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
          }
          else{
            let checkProduct =arrProduct.find(x => x==this.productSales[cp].id);
            if(checkProduct){
              nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
            }
          }
        }
        let getDiskon =false;
        if (nominalGabungan < (minimum_transaction)){
          detailPromoPersenByGabunganGabunganProduct += ' Belum memenuhi minimum belanja';
          diskon =0;
          diskonValue=0;
        }
        else{
          getDiskon =true;
          detailPromoPersenByGabunganGabunganProduct += 'diskon Value :'+diskonValue+'. minimum_transaction:'+minimum_transaction;
          detailPromoPersenByGabunganGabunganProduct += '. Sudah memenuhi minimum belanja';          
          diskon = (diskonValue/100) * Math.floor(nominalGabungan);          
          if (max_discount >0  && diskon > max_discount){
            diskon = max_discount;
          }
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoPersenByNominalGabunganProduct[a]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoPersenByGabunganGabunganProduct+='sudah memenuhi syarat';
          this.transactionDiscount += (diskon);
          detailPromoPersenByGabunganGabunganProduct +='\nMendapat diskon sebesar:'+(diskon);
          detailPromoPersenByGabunganGabunganProduct +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
          let hargasebelumdiskon=0;
          for(let ps=0;ps<this.productSales.length;ps++){
            let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
            
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              hargasebelumdiskon+=hargaTanpaFreeItem;
            }
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                hargasebelumdiskon+=hargaTanpaFreeItem;
              }
            }
          }
          let diskonterbagi=0;
          let detail = '';
          for(let ps=0;ps<this.productSales.length;ps++){
            let diskonkontribusi=0;
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              if(ps!=(this.productSales.length-1)){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              else{
                diskonkontribusi= diskon-diskonterbagi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }            
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }
            if(diskonkontribusi>0){
              detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
            }
          }
          detail = detail.replace(/<br>$/, "");
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':idpromodetail,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'detail':detail
          })
        }
        else{
          detailPromoPersenByGabunganGabunganProduct+='belum memenuhi syarat';
        }
      }
      //console.log(detailPromoPersenByGabunganGabunganProduct);
    }
    // persentase, item gabungan misal beli 4 aromaterapi apapun diskon
    let promoPersenByGabunganProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'PERSENTASE' && x.promo_based_on == 3 && x.qty_type=='1');
    if(promoPersenByGabunganProduct.length > 0){
      let detailPromoPersenGabunganProduct = 'Ada promo persen by gabungan qty produk.';
      for(let a =0; a< promoPersenByGabunganProduct.length;a++){
        let minimum = parseInt(promoPersenByGabunganProduct[a].minimum_buy);
        let diskonValue = parseInt(promoPersenByGabunganProduct[a].value);
        let max_discount = promoPersenByGabunganProduct[a].max_discount;
        let product = promoPersenByGabunganProduct[a].product_id;
        let idpromodetail = promoPersenByGabunganProduct[a].id;
        let promoBasedOn = promoPersenByGabunganProduct[a].promo_based_on;
        let arrProduct = [];
        let diskon =0;
        if(product!=null){
          arrProduct= product.split(';');
        }
        let qtyGabungan =0;
        let nominalGabungan =0;
        for(let cp=0;cp<this.productSales.length;cp++){
          if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
            qtyGabungan+=parseInt(this.productSales[cp].qty_withoutpromo);
            nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
          }
          else{
            let checkProduct =arrProduct.find(x => x==this.productSales[cp].id);
            if(checkProduct){
              qtyGabungan+=parseInt(this.productSales[cp].qty_withoutpromo);
              nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
            }
          }
        }
        let getDiskon =false;
        if (qtyGabungan < (minimum)){
          detailPromoPersenGabunganProduct += ' Belum memenuhi minimum belanja';
          diskon =0;
          diskonValue=0;
        }
        else{
          getDiskon =true;
          detailPromoPersenGabunganProduct += '. Sudah memenuhi minimum belanja';
          diskon = (diskonValue/100) * Math.floor(nominalGabungan);
          if (max_discount >0  && diskon > max_discount){
            diskon = max_discount;
          }
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoPersenByGabunganProduct[a]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoPersenGabunganProduct+='sudah memenuhi syarat';
          this.transactionDiscount += (diskon);
          detailPromoPersenGabunganProduct +='\nMendapat diskon sebesar:'+(diskon);
          detailPromoPersenGabunganProduct +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
          let hargasebelumdiskon=0;
          for(let ps=0;ps<this.productSales.length;ps++){
            let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              hargasebelumdiskon+=hargaTanpaFreeItem;
            }
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                hargasebelumdiskon+=hargaTanpaFreeItem;
              }
            }
          }
          let diskonterbagi=0;
          let detail = '';
          for(let ps=0;ps<this.productSales.length;ps++){
            let diskonkontribusi=0;
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              if(ps!=(this.productSales.length-1)){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              else{
                diskonkontribusi= diskon-diskonterbagi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }            
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }
            if(diskonkontribusi>0){
              detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
            }
          }
          detail = detail.replace(/<br>$/, "");
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':idpromodetail,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'detail':detail
          })
        }
        else{
          detailPromoPersenGabunganProduct+='belum memenuhi syarat';
        }
      }
      //console.log(detailPromoPersenGabunganProduct);
    }
    // nominal, nominal gabungan misal beli 50K aromaterapi apapun diskon
    let promoNominalByNominalGabunganProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'NOMINAL' && x.promo_based_on == 2 && x.qty_type=='1');
    if(promoNominalByNominalGabunganProduct.length > 0){
      let detailPromoNominalByGabunganGabunganProduct = 'Ada promo nominal by gabungan Nominal produk.';
      for(let a =0; a< promoNominalByNominalGabunganProduct.length;a++){
        let diskon = parseInt(promoNominalByNominalGabunganProduct[a].value);
        let max_discount = promoNominalByNominalGabunganProduct[a].max_discount;
        let berlakuKelipatan = promoNominalByNominalGabunganProduct[a].multiple_flag;
        let product = promoNominalByNominalGabunganProduct[a].product_id;
        let idpromodetail = promoNominalByNominalGabunganProduct[a].id;
        let promoBasedOn = promoNominalByNominalGabunganProduct[a].promo_based_on;
        let minimum_transaction = parseInt(promoNominalByNominalGabunganProduct[a].minimum_transaction);
        let arrProduct = [];
        if(product!=null){
          arrProduct= product.split(';');
        }
        let nominalGabungan =0;
        for(let cp=0;cp<this.productSales.length;cp++){
          if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
            nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
          }
          else{
            let checkProduct =arrProduct.find(x => x==this.productSales[cp].id);
            if(checkProduct){
              nominalGabungan+=(parseInt(this.productSales[cp].price)*parseInt(this.productSales[cp].qty_withoutpromo));
            }
          }
        }
        let getDiskon =false;
        if (nominalGabungan < (minimum_transaction)){
          detailPromoNominalByGabunganGabunganProduct += ' Belum memenuhi minimum belanja';
          diskon =0;
        }
        else{
          getDiskon =true;
          detailPromoNominalByGabunganGabunganProduct += '. Sudah memenuhi minimum belanja';
          if(berlakuKelipatan=='1'){
            detailPromoNominalByGabunganGabunganProduct += '. Berlaku kelipatan';
            if(minimum_transaction>0){
              diskon = diskon * Math.floor(nominalGabungan/minimum_transaction);
            }
          }
          else{
            detailPromoNominalByGabunganGabunganProduct += '. Tidak berlaku kelipatan';
          }
          if (max_discount >0  && diskon > max_discount){
            diskon = max_discount;
          }
        }
        //cek produk yg dibutuhkan
        let syaratTerpenuhi =this.cekSyarat(promoNominalByNominalGabunganProduct[a]);
        if(getDiskon && syaratTerpenuhi=='Y'){
          detailPromoNominalByGabunganGabunganProduct+='sudah memenuhi syarat';
          this.transactionDiscount += (diskon);
          detailPromoNominalByGabunganGabunganProduct +='\nMendapat diskon sebesar:'+(diskon);
          detailPromoNominalByGabunganGabunganProduct +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
          let hargasebelumdiskon=0;
          for(let ps=0;ps<this.productSales.length;ps++){
            let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              hargasebelumdiskon+=hargaTanpaFreeItem;
            }
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                hargasebelumdiskon+=hargaTanpaFreeItem;
              }
            }
          }
          let diskonterbagi=0;
          let detail ='';
          for(let ps=0;ps<this.productSales.length;ps++){
            let diskonkontribusi=0;
            if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
              if(ps!=(this.productSales.length-1)){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              else{
                diskonkontribusi= diskon-diskonterbagi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
            }
            else{
              let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
              if(checkProduct){
                diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                diskonterbagi+=diskonkontribusi;
              }
              this.productSales[ps].valPromoNominal+=diskonkontribusi;
              if(ps==(this.productSales.length-1)){// kalau diskon masih sisa, dan sudah produk terakhir, masukin sisa ke salah satu yg dpt promo
                if(diskonterbagi<diskon){
                  let sisa=(diskon-diskonterbagi);
                  let firstdptdiskon = this.productSales.find(x => x.id==arrProduct[(arrProduct.length-1)]);
                  if(firstdptdiskon){
                    diskonkontribusi+=sisa;
                    firstdptdiskon.valPromoNominal+=sisa;
                  }
                }
              }
            }
            if(diskonkontribusi>0){
              detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
            }
          }
          detail = detail.replace(/<br>$/, "");
          this.promoBundle.push({
            'product_scan':0,
            'promotion_detail_id':idpromodetail,
            'qty_get_promo':1,
            'discount':diskon,
            'product_free_item':'',
            'qty_free_item':0,
            'detail':detail
          })
        }
        else{
          detailPromoNominalByGabunganGabunganProduct+='belum memenuhi syarat';
        }
      }
      //console.log(detailPromoNominalByGabunganGabunganProduct);
    }
    // nominal, item gabungan misal beli 4 aromaterapi apapun diskon
    let promoNominalByGabunganProduct =this.promotionDetailList.filter(x => x.promotion_type_name === 'NOMINAL' && x.promo_based_on == 3 && x.qty_type=='1');
    if(promoNominalByGabunganProduct.length > 0){
      let detailPromoNominalGabunganProduct = 'Ada promo nominal gabungan produk.';
      for(let a =0; a< promoNominalByGabunganProduct.length;a++){
        let minimum = parseInt(promoNominalByGabunganProduct[a].minimum_buy);
        let maximum_buy = parseInt(promoNominalByGabunganProduct[a].maximum_buy);
        let diskon = parseInt(promoNominalByGabunganProduct[a].value);
        let max_discount = promoNominalByGabunganProduct[a].max_discount;
        let berlakuKelipatan = promoNominalByGabunganProduct[a].multiple_flag;
        let product = promoNominalByGabunganProduct[a].product_id;
        let idpromodetail = promoNominalByGabunganProduct[a].id;
        let idpromo = promoNominalByGabunganProduct[a].promotion_id;
        let applies_to = parseInt(promoNominalByGabunganProduct[a].applies_to);//promo berlaku untuk produk ke
       let promoBasedOn = promoNominalByGabunganProduct[a].promo_based_on;
        let arrProduct = [];
        if(product!=null){
          arrProduct= product.split(';');
        }
        let qtyGabungan =0;
        for(let cp=0;cp<this.productSales.length;cp++){
          if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
            qtyGabungan+=parseInt(this.productSales[cp].qty_withoutpromo);
          }
          else{
            let checkProduct =arrProduct.find(x => x==this.productSales[cp].id);
            if(checkProduct){
              qtyGabungan+=parseInt(this.productSales[cp].qty_withoutpromo);
            }
          }
        }
        let getDiskon =false;
        if (qtyGabungan < (minimum)){
          detailPromoNominalGabunganProduct += ' Belum memenuhi minimum belanja';
          diskon =0;
        }
        else{
          getDiskon =true;
          detailPromoNominalGabunganProduct += '. Sudah memenuhi minimum belanja';
          if(berlakuKelipatan=='1'){
            detailPromoNominalGabunganProduct += '. Berlaku kelipatan';
            if(minimum>0){
              diskon = diskon * Math.floor(qtyGabungan/minimum);
            }
          }
          else{
            detailPromoNominalGabunganProduct += '. Tidak berlaku kelipatan';
          }
          if (max_discount >0  && diskon > max_discount){
            diskon = max_discount;
          }
        }
         //cek produk yg dibutuhkan
          let syaratTerpenuhi =this.cekSyarat(promoNominalByGabunganProduct[a]);
          if(getDiskon && syaratTerpenuhi=='Y'){
            detailPromoNominalGabunganProduct+='sudah memenuhi syarat';
            this.transactionDiscount += (diskon);
            detailPromoNominalGabunganProduct +='\nMendapat diskon sebesar:'+(diskon);
            detailPromoNominalGabunganProduct +='\nDengan maksimum diskon sebesar:'+parseInt(max_discount);
            let hargasebelumdiskon=0;
            for(let ps=0;ps<this.productSales.length;ps++){
              let hargaTanpaFreeItem = parseInt(this.productSales[ps].price) * (parseInt(this.productSales[ps].qty_withoutpromo));
              if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
                hargasebelumdiskon+=hargaTanpaFreeItem;
              }
              else{
                let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
                if(checkProduct){
                  hargasebelumdiskon+=hargaTanpaFreeItem;
                }
              }
            }
            let diskonterbagi=0;
            let detail ='';
            for(let ps=0;ps<this.productSales.length;ps++){
              let diskonkontribusi=0;
              if(promoBasedOn=='0' || (promoBasedOn!='0' && product==null)){
                if(ps!=(this.productSales.length-1)){
                  diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                  diskonterbagi+=diskonkontribusi;
                }
                else{
                  diskonkontribusi= diskon-diskonterbagi;
                }
                this.productSales[ps].valPromoNominal+=diskonkontribusi;
              }
              else{
                let checkProduct =arrProduct.find(x => x==this.productSales[ps].id);
                if(checkProduct){
                  diskonkontribusi = Math.floor((diskon/hargasebelumdiskon)*(this.productSales[ps].price * parseInt(this.productSales[ps].qty_withoutpromo)));
                  diskonterbagi+=diskonkontribusi;
                }
                this.productSales[ps].valPromoNominal+=diskonkontribusi;
              }
              if(ps==(this.productSales.length-1)){// kalau diskon masih sisa, dan sudah produk terakhir, masukin sisa ke salah satu yg dpt promo
                if(diskonterbagi<diskon){
                  let sisa=(diskon-diskonterbagi);
                  let firstdptdiskon = this.productSales.find(x => x.id==arrProduct[(arrProduct.length-1)]);
                  if(firstdptdiskon){
                    diskonkontribusi+=sisa;
                    firstdptdiskon.valPromoNominal+=sisa;
                  }
                }
              }              
              if(diskonkontribusi>0){
                detail+= this.productSales[ps].id+ ' discount '+this.myService.separator(diskonkontribusi.toString())+'.<br>';
              }
            }
            detail = detail.replace(/<br>$/, "");
            this.promoBundle.push({
              'product_scan':0,
              'promotion_detail_id':idpromodetail,
              'qty_get_promo':1,
              'discount':diskon,
              'product_free_item':'',
              'qty_free_item':0,
              'detail':detail
            })
          }
          else{
            detailPromoNominalGabunganProduct+='belum memenuhi syarat';
          }
      }
      //console.log(detailPromoNominalGabunganProduct);
    }
    if(this.transactionDiscount!=0){
      const parts = this.unFormat(String(this.transactionDiscount)).split(this.DECIMAL_SEPARATOR);
      this.transactionDiscountWithSeparator = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
    }
    else{
      this.transactionDiscountWithSeparator = '0';
    }
    for(let ps=0;ps<this.productSales.length;ps++){
    this.productSales[ps].nett =(parseInt(this.productSales[ps].qty_withoutpromo)*parseInt(this.productSales[ps].price))-parseInt(this.productSales[ps].valPromoNominal);
    }  
    this.totalPayment = this.totalPayment - this.transactionDiscount;
  }

  getChangeNominal() {
    if(this.valueUSDwithSeparator!='0' && this.valueUSDwithSeparator!='' && this.valueUSDwithSeparator!=null){
      const parts = this.unFormat(String(this.valueUSDwithSeparator)).split(this.DECIMAL_SEPARATOR);
      this.valueUSDwithSeparator = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
      let valueUSDwithSeparator = String(this.valueUSDwithSeparator).replace(/[^0-9]/g, '');
      this.valueUSD =parseInt(valueUSDwithSeparator);
    }
    else{
      this.valueUSDwithSeparator='0';
      this.valueUSD =0;
    }

    if(this.valueCNYwithSeparator!='0' && this.valueCNYwithSeparator!='' && this.valueCNYwithSeparator!=null){
      const parts = this.unFormat(String(this.valueCNYwithSeparator)).split(this.DECIMAL_SEPARATOR);
      this.valueCNYwithSeparator = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
      let valueCNYwithSeparator = String(this.valueCNYwithSeparator).replace(/[^0-9]/g, '');
      this.valueCNY =parseInt(valueCNYwithSeparator);
    }
    else{
      this.valueCNYwithSeparator='0';
      this.valueCNY =0;
    }

    if(this.valueWeChatWithSeparator!='0' && this.valueWeChatWithSeparator!='' && this.valueWeChatWithSeparator!=null){
      const parts = this.unFormat(String(this.valueWeChatWithSeparator)).split(this.DECIMAL_SEPARATOR);
      this.valueWeChatWithSeparator = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
      let valueWeChatWithSeparator = String(this.valueWeChatWithSeparator).replace(/[^0-9]/g, '');
      this.valueWeChat =parseInt(valueWeChatWithSeparator);
    }
    else{
      this.valueWeChatWithSeparator='0';
      this.valueWeChat =0;
    }

    let valUSDtoIDR: number = this.exchangeRateUSD * this.valueUSD;
    let valCNYtoIDR: number = this.exchangeRateCNY * this.valueCNY;
    let valWeChattoIDR: number = this.exchangeRateWeChat * this.valueWeChat;

    if(this.voucherValue !== 0) {
      let value: any = 0;
      for(let i = 0; i < this.voucherUseList.length; i++) {
        value = value + parseInt(this.voucherUseList[i].nominal);
      }
      if(value > this.totalPayment) {
        this.voucherValue = this.totalPayment;
      } else {
        this.voucherValue = value;
      }
    }
    if(this.memberPointValue !== 0) {
      if(this.memberPointValueUsed > this.totalPayment) {
        this.memberPointValue = this.totalPayment;
      } else {
        this.memberPointValue = this.memberPointValueUsed;
      }
    }
    this.memberPointValueWithSeparator = String(this.memberPointValue);
    if(this.memberPointValue!=0 && this.memberPointValue!=null){
      const parts = this.unFormat(String(this.memberPointValue)).split(this.DECIMAL_SEPARATOR);
      this.memberPointValueWithSeparator = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
    }
    else{
      this.memberPointValueWithSeparator='0';
    }

    if(this.customerCashWithSeparator!='0' && this.customerCashWithSeparator!='' && this.customerCashWithSeparator!=null){
      const parts = this.unFormat(String(this.customerCashWithSeparator)).split(this.DECIMAL_SEPARATOR);
      this.customerCashWithSeparator = parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
    
      let customerCashwithoutseparator = String(this.customerCashWithSeparator).replace(/[^0-9]/g, '');
      this.customerCash =parseInt(customerCashwithoutseparator);
    }
    else{
      this.customerCashWithSeparator='0';
      this.customerCash =0;
    }
    if(this.customerTransferWithSeparator!='0' && this.customerTransferWithSeparator!='' && this.customerTransferWithSeparator!=null){
      const partsTransfer= this.unFormat(String(this.customerTransferWithSeparator)).split(this.DECIMAL_SEPARATOR);
      this.customerTransferWithSeparator = partsTransfer[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!partsTransfer[1] ? '' : this.DECIMAL_SEPARATOR + partsTransfer[1]);
      let customerTransferWithoutseparator = String(this.customerTransferWithSeparator).replace(/[^0-9]/g, '');
      this.customerTransfer =parseInt(customerTransferWithoutseparator);
    }
    else{
      this.customerTransferWithSeparator='0';
      this.customerTransfer=0;
    }
    
    if(this.customerCreditWithSeparator!='0' && this.customerCreditWithSeparator!='' && this.customerCreditWithSeparator!=null){
      const partsCredit = this.unFormat(String(this.customerCreditWithSeparator)).split(this.DECIMAL_SEPARATOR);
      this.customerCreditWithSeparator = partsCredit[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!partsCredit[1] ? '' : this.DECIMAL_SEPARATOR + partsCredit[1]);
      let customerCreditWithoutseparator = String(this.customerCreditWithSeparator).replace(/[^0-9]/g, '');
      this.customerCredit =parseInt(customerCreditWithoutseparator);
    }
    else{
      this.customerCreditWithSeparator='0';
      this.customerCredit =0;
    }
    
    if(this.customerDebitWithSeparator!='0' && this.customerDebitWithSeparator!='' && this.customerDebitWithSeparator!=null){
      const partsDebit= this.unFormat(String(this.customerDebitWithSeparator)).split(this.DECIMAL_SEPARATOR);
      this.customerDebitWithSeparator = partsDebit[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!partsDebit[1] ? '' : this.DECIMAL_SEPARATOR + partsDebit[1]);
      let customerDebitWithoutseparator = String(this.customerDebitWithSeparator).replace(/[^0-9]/g, '');
      this.customerDebit =parseInt(customerDebitWithoutseparator);
    }
    else{
      this.customerDebitWithSeparator='0';
      this.customerDebit =0;
    }

    if(this.voucherValueWithSeparator!='0' && this.voucherValueWithSeparator!='' && this.voucherValueWithSeparator!=null){
      const partsVoucher= this.unFormat(String(this.voucherValueWithSeparator)).split(this.DECIMAL_SEPARATOR);
      this.voucherValueWithSeparator = partsVoucher[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!partsVoucher[1] ? '' : this.DECIMAL_SEPARATOR + partsVoucher[1]);
      let voucherValueWithoutseparator = String(this.voucherValueWithSeparator).replace(/[^0-9]/g, '');
      this.voucherValue =parseInt(voucherValueWithoutseparator);
    }
    else{
      this.voucherValueWithSeparator='0';
      this.voucherValue=0;
    }

    this.totalCustomerPay = this.customerCash + this.customerTransfer +
    this.customerCredit + this.customerDebit+ this.voucherValue +
      this.memberPointValue + valUSDtoIDR + valCNYtoIDR + valWeChattoIDR;

    if(this.totalCustomerPay!=0 && this.totalCustomerPay!=null){
      const partsVoucher= this.unFormat(String(this.totalCustomerPay)).split(this.DECIMAL_SEPARATOR);
      this.totalCustomerPayWithSeparator = partsVoucher[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!partsVoucher[1] ? '' : this.DECIMAL_SEPARATOR + partsVoucher[1]);
    }
    else{
      this.totalCustomerPayWithSeparator = '0';
    }
    this.totalCustomerCash = this.customerCash +  valUSDtoIDR + valCNYtoIDR + valWeChattoIDR;
    let change: number = this.totalCustomerPay - this.totalPayment;
    
    this.totalChange = change;
    
    if(this.totalChange!=0 && this.totalChange!=null){
      const partsVoucher= this.unFormat(String(this.totalChange)).split(this.DECIMAL_SEPARATOR);
      this.totalChangeWithSeparator = partsVoucher[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!partsVoucher[1] ? '' : this.DECIMAL_SEPARATOR + partsVoucher[1]);
    }
    else{
      this.totalChangeWithSeparator ='0';
    }
    let changeUSD: any = change / this.exchangeRateUSD;
    let changeCNY: any = change / this.exchangeRateCNY;
    this.totalChangeUSD = parseFloat(changeUSD).toFixed(2);
    this.totalChangeCNY = parseFloat(changeCNY).toFixed(2);
  }

  getCreditName() {
    let check: any = this.creditBankList.find(x => x.id === this.customerCreditId);
    if(check) {
      this.customerCreditName = check.payment_name;
    }
  }

  getDebitName() {
    let check: any = this.debitBankList.find(x => x.id === this.customerDebitId);
    if(check) {
      this.customerDebitName = check.payment_name;
    }
  }

  getForeignCurrencyName() {
    let check: any = this.foreignCurrencyList.find(x => x.id === this.foreignCurrencyId);
    if(check) {
      this.foreignCurrencyName = check.foreign_currency_name;
      this.foreignCurrencyRate = check.exchange_rate;
    }
  }

  clearNonCash() {
    this.customerCreditId = undefined;
    this.customerDebitId = undefined;
    this.edcId = undefined;
    this.customerCashWithSeparator='0';
    this.customerTransferWithSeparator='0';
    this.customerDebitWithSeparator='0';
    this.customerCreditWithSeparator='0';

    this.getChangeNominal();
  }

  getLabelPrintNominal(nominal: any): String {
    let nominalString = new String(nominal);
    let totalLength: number = 12;

    let diff: number = totalLength - nominalString.length;
    let lblNominal: String = '';

    if(diff !== 0) {
      for(let i = 0; i < diff; i++) {
        lblNominal = lblNominal + ' ';
      }

      lblNominal = lblNominal + nominalString.toString();
    } else {
      lblNominal = nominalString;
    }

    return lblNominal;
  }

  getLblNett(nett: String, priceAndQtylength: number): String {
    let lblNominal: String = '';
    let receiptRowLength: number = 33;

    let nettLength: number = nett.length;
    let diff: number = receiptRowLength - (nettLength + priceAndQtylength);
    for(let i = 0; i < diff; i++) {
      lblNominal = lblNominal + ' ';
    }
    lblNominal = lblNominal + nett.toString();
    return lblNominal;
  }

  getLabelDppAndPpn(label: String, lblLength: number): String {
    let lblNominal: String = '';
    let receiptRowLength: number = 33;
    let frontLblLength: number = 6;

    let diff: number = receiptRowLength - (frontLblLength + lblLength);
    for(let i = 0; i < diff; i++) {
      lblNominal = lblNominal + ' ';
    }
    lblNominal = lblNominal + label.toString();
    return lblNominal;
  }

  getLabelTotalItem(nominal: any): String {
    let nominalString = new String(nominal);
    let label: String = 'TOTAL ITEM ' + nominalString + ' : ';
    let lblNominal: String = '';

    let totalLength: number = label.length;
    let diff: number = 21 - totalLength;
    if(diff !== 0) {
      let space: any = '';
      for(let i = 0; i < diff; i++) {
        space = space + ' ';
      }

      lblNominal = space + label;
    } else {
      lblNominal = label;
    }

    return lblNominal;
  }

  getLabelHeaderReceipt(headerText: String) {
    let totalLength: number = headerText.length;
    let maxTextLengthforReceipt = 33;
    let resultText = "";
    if(totalLength > maxTextLengthforReceipt) {
      resultText = headerText.slice(0, maxTextLengthforReceipt);
    } else {
      let diff = maxTextLengthforReceipt - totalLength;
      let divide = (diff - (diff % 2)) / 2;
      let space = "";
      for(let i = 0; i < divide; i++) {
        space = space + " ";
      }
      
      resultText = space + headerText + space;
    }

    return resultText;
  }

  showOtherPayment() {
    this.valueUSD = 0;
    this.valueUSDwithSeparator ='0';
    this.valueCNY = 0;
    this.valueCNYwithSeparator ='0';
    this.valueWeChat = 0;
    this.valueWeChatWithSeparator ='0';
    this.foreignCurrencyId = undefined;

    if(this.otherPayment) {
      this.otherPayment = false;
    } else {
      this.otherPayment = true;
    }

    this.getChangeNominal();
  }

  //End => Local Function


  formatRupiah(angka: string) {
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
  }

  async openSearchProduct() {
    const modal = await this.modalController.create({
      component: SearchProductComponent,
      componentProps: {
        "counterId": this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : 0,
        'token': this.token
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      if (modelData !== null || modelData.data.productId !== undefined || modelData.data.productId !== null) {
        this.getProduct(modelData.data.productId);
      }
    });

    return await modal.present();
  }

  changeZeroDiscount() {
    if(this.transactionDiscount==0){
      this.transactionDiscount = null;
    }
  }
  checkValueDiscount(){
    if(this.transactionDiscount==null){
      this.transactionDiscount = 0;
    }
  }

  changeZeroCash() {
    if(this.customerCash==0){
      this.customerCash = null;
      this.customerCashWithSeparator ='0';
    }
  }
  checkValueCash(){
    if(this.customerCash==null){
      this.customerCash = 0;
    }
  }

  changeZeroTf() {
    if(this.customerTransfer==0){
      this.customerTransfer = null;
    }
  }
  checkValueTf(){
    if(this.customerTransfer==null){
      this.customerTransfer = 0;
    }
  }

  changeZeroCredit() {
    if(this.customerCredit==0){
      this.customerCredit = null;
    }
  }
  checkValueCredit(){
    if(this.customerCredit==null){
      this.customerCredit = 0;
    }
  }

  changeZeroDebit() {
    if(this.customerDebit==0){
      this.customerDebit = null;
    }
  }
  checkValueDebit(){
    if(this.customerDebit==null){
      this.customerDebit = 0;
    }
  }

  changeZeroUsd() {
    if(this.valueUSD==0){
      this.valueUSD = null;
      this.valueUSDwithSeparator ='0';
    }
  }
  checkValueUsd(){
    if(this.valueUSD==null){
      this.valueUSD = 0;
    }
  }

  changeZeroCny() {
    if(this.valueCNY==0){
      this.valueCNY = null;
    }
  }
  checkValueCny(){
    if(this.valueCNY==null){
      this.valueCNY = 0;
    }
  }

  changeZeroWeChat() {
    if(this.valueWeChat==0){
      this.valueWeChat = null;
    }
  }
  checkValueWeChat(){
    if(this.valueWeChat==null){
      this.valueWeChat = 0;
    }
  }

  //thousand separator
  format(valString) {
    if (!valString) {
        return '';
    }
    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    return parts[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, this.GROUP_SEPARATOR) + (!parts[1] ? '' : this.DECIMAL_SEPARATOR + parts[1]);
};

unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/^0+/, '');
    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
};
numberOnlyValidation(event: any) {
  const pattern = /[0-9.,]/;
  let inputChar = String.fromCharCode(event.charCode);
  if (!pattern.test(inputChar)) {
    // invalid character, prevent input
    event.preventDefault();
  }
}
//end of thousand separator
  updateStorage(){
    this.posService.storeProductSales(this.productSales);
  }
  openDisplayCustomer() {
    var params = [
      'height='+screen.height,
      'width='+screen.width,
      'fullscreen=yes' // only works in IE, but here for completeness
    ].join(',');
     // and any other options from
     // https://developer.mozilla.org/en/DOM/window.open
    var host = window.location.href;
    var popup = window.open(host.replace("pos-promo-oto", "display-customer"), 'popup_window', params); 
    popup.moveTo(0,0);
  }
}