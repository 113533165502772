import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterProductPage } from './master-product.page';

const routes: Routes = [
  {
    path: '',
    component: MasterProductPage
  },
  {
    path: 'master-product-create',
    loadChildren: () => import('./master-product-create/master-product-create.module').then( m => m.MasterProductCreatePageModule)
  },
  {
    path: 'master-product-edit',
    loadChildren: () => import('./master-product-edit/master-product-edit.module').then( m => m.MasterProductEditPageModule)
  },
  {
    path: 'master-product-detail',
    loadChildren: () => import('./master-product-detail/master-product-detail.module').then( m => m.MasterProductDetailPageModule)
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterProductPageRoutingModule {}
