
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { UserData } from '../../../providers/user-data';
import { ReportService } from '../report.service';
import { RoleAccess } from '../../../models/role-access.model';
import * as XLSX from 'xlsx';
import { IonicSelectableComponent } from 'ionic-selectable';
import { ResellerTransactionListComponent } from '../../../component/reseller-transaction-list/reseller-transaction-list.component';

@Component({
  selector: 'app-report-claim-incentives',
  templateUrl: './report-claim-incentives.page.html',
  styleUrls: ['./report-claim-incentives.page.scss'],
})
export class ReportClaimIncentivesPage implements OnInit {


  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  token: any;
  userProfile: UserProfile = new UserProfile();
  rows: any;

  flagFind: boolean = false;
  promoType:any='';
  incentiveList:any[]=[];
  
  roleAccess = new RoleAccess();
  monthly:boolean=false;
  periode: any= new Date().toISOString();
  startDate: any= new Date().toISOString();
  endDate: any= new Date().toISOString();
  giveRewardStartDate: any;//= new Date().toISOString();
  giveRewardEndDate: any;//= new Date().toISOString();
  year: any;
  month: any;
  periodeId:any;
  dataExcel:any=[];

  @ViewChild('promoTypeComponent', { static: false }) promoTypeComponent: IonicSelectableComponent;
  
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController:ModalController,
    private userData: UserData,
    private reportService: ReportService,
    private router: Router) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {          
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          let offset = this.page.offset + 1;
          let options = {
            "token": this.token,
            "page": offset.toString()
          };
    
          this.reportService.loadReportClaimIncentives(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.incentiveList = response.results.data;
          }, (err) => {
            this.utilService.loadingDismiss();
            if(err.error.error === 'token_expired') {
              this.userData.logout().then(() => {
                this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
                this.navCtrl.navigateForward(['/login']);
              });
            } else {
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            }
          });
        });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  generate(){
    this.pageCallback({ offset: this.page.offset });
  }
  
  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    if(this.promoType==''){
      this.toastCtrl.create({ duration: 2000, message: 'Mohon Pilih Jenis Promo' }).then(t => t.present());          
    }
    else{
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        if(this.giveRewardStartDate){
          let dateConvertStart = this.utilService.convertDate(this.giveRewardStartDate);
          this.giveRewardStartDate=  dateConvertStart.years+'-'+dateConvertStart.months+'-'+dateConvertStart.dates;
        }
        if(this.giveRewardEndDate){
          let dateConvertEnd = this.utilService.convertDate(this.giveRewardEndDate);
          this.giveRewardEndDate=  dateConvertEnd.years+'-'+dateConvertEnd.months+'-'+dateConvertEnd.dates;
        }
        let offset = this.page.offset + 1;
        let options = {
          "token": this.token,
          "page": offset.toString(),
          "promo_type":this.promoType,
          "start_date":this.startDate,
          "end_date":this.endDate,
          "start_date_delivery":this.giveRewardStartDate,
          "end_date_delivery":this.giveRewardEndDate
        };

        this.reportService.getReportClaimIncentives(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.page.count = response.results.total;
          this.rows = response.results.data;
          this.dataExcel=response.dataExcel;
        }, (err) => {
          this.utilService.loadingDismiss();
          console.log(err);
          if(err.error.error === 'token_expired') {
            this.userData.logout().then(() => {
              this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
              this.navCtrl.navigateForward(['/login']);
            });
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          }
        });
      });
    }
  }

  getQty(nominal:any, qty:any){
    return nominal*qty;
  }
  
  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Report Claim Incentives.xlsx');
  }

  clearFilter(){
    this.promoType ='';
    this.year='';
    this.month='';
    this.startDate='';
    this.endDate='';
    this.giveRewardStartDate='';
    this.giveRewardEndDate='';
    this.monthly=false;  
    this.page.count = 0;
    this.rows = [];
    this.dataExcel=[];this.promoTypeComponent.value='';
    this.periode='';
  }
  changePeriode(){  
    let dateConvert = this.utilService.convertDate(this.periode);
    this.year = dateConvert.years;
    this.month = dateConvert.months;
    this.month = dateConvert.months;
    this.startDate = this.year+'-'+this.month+'-01';
    let lastDay = this.lastday(this.year,this.month);
    this.endDate = this.year+'-'+this.month+'-'+lastDay;
  }    
  
  confirmPromoType() {
    this.promoType='';
    this.promoTypeComponent.confirm();
    this.promoTypeComponent.close();
    if(this.promoTypeComponent.value){
      this.promoType = this.promoTypeComponent.value.id;
    }      
    let detailSetupIncentive = this.incentiveList.find(x => x.id ===this.promoType);
    this.monthly =false;
    if(detailSetupIncentive){
      let idx = this.incentiveList.indexOf(detailSetupIncentive);
      this.periodeId=this.incentiveList[idx]['periode'];
      if(this.incentiveList[idx]['periode']=='0'){
        
        if(this.periode!=''){
          let dateConvert = this.utilService.convertDate(this.periode);
          this.year = dateConvert.years;
          this.month = dateConvert.months;
          this.startDate = this.year+'-'+this.month+'-01';
          let lastDay = this.lastday(this.year,this.month);
          this.endDate = this.year+'-'+this.month+'-'+lastDay;
        }
        this.monthly=true;
      }
      else{
        this.startDate = this.incentiveList[idx]['early_period'];
        this.endDate = this.incentiveList[idx]['end_period'];
      }
    }
  }
  lastday(y,m){
    return  new Date(y, m +1, 0).getDate();
  }
  async openListTransaction(setupId: any, resellerId: any,totalBelanja:any) {
    console.log(totalBelanja);
    const modal = await this.modalController.create({
      component: ResellerTransactionListComponent,
      componentProps: {
        'setupId': setupId,
        'resellerId': resellerId,
        'totalBelanja':totalBelanja,
        'year':this.year,
        'month':this.month,
        'username': this.userProfile.username,
        'userProfile':this.userProfile,
        'typeForm':'view'
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then(() => {
      this.reloadTable();
    });

    return await modal.present();
  }
}
