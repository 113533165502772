import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { ReceiptVoucherService } from '../receipt-voucher.service';
import { ReceiptVoucher, ReceiptVoucherDetail } from '../../../models/receipt-voucher.model';

class Coa {
  public id: string;
  public name: string;
}

@Component({
  selector: 'app-receipt-voucher-create',
  templateUrl: './receipt-voucher-create.page.html',
  styleUrls: ['./receipt-voucher-create.page.scss'],
})
export class ReceiptVoucherCreatePage implements OnInit {

  token: any;
  coaSearch: Coa[];
  coaList: Coa[];
  userProfile: UserProfile = new UserProfile();
  counterList: any[] = [];
  cashBankList: any[] = [];
  currencyList: any[] = [];

  formReceiptVoucherCreate: FormGroup;
  receiptVoucherId: any;

  db: any;

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private receiptVoucherService: ReceiptVoucherService) { }

  ngOnInit() {
    this.buildFormReceiptVoucher();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.getAllCoa();
        this.getCounterList();
        this.getCashBankList();
        this.getCurrencyList();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getAllCoa() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.receiptVoucherService.getMstCoaConcat({ "token": this.token }).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.coaList = response.results;
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  buildFormReceiptVoucher() {
    let newDate = new Date();
    let convertDate = this.utilService.convertDate(newDate);
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formReceiptVoucherCreate = this.fb.group({
      cashBankId: [null, Validators.required],
      docDate: [todayDate, Validators.required],
      receiptFrom: [null, Validators.required],
      currency: [null, Validators.required],
      amount: [0, Validators.required],
      exchangeRate: [0],
      receiptProofNo: [null, Validators.required],
      detail: this.fb.array([])
    });
  }

  addDetail() {
    const detail = this.fb.group({
      coa: [null, Validators.required],
      counterId: [null, Validators.required],
      amount: [0, Validators.required],
      desc: [null]
    });
    this.getDetailArray.push(detail);
  }

  get getDetailArray() {
    return (<FormArray>this.formReceiptVoucherCreate.get('detail'));
  }

  filterCoa(coas: Coa[], text: string) {
    return coas.filter(coa => {
      return coa.name.toLowerCase().indexOf(text) !== -1 ||
        coa.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  searchCoa(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterCoa(this.coaList, text);
    event.component.endSearch();
  }

  deleteDetail(i: any) {
    this.getDetailArray.removeAt(i);
  }

  getCounterList() {
    let selectQuery = 'SELECT * FROM mst_counters';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.counterList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let counterData = {
                'id': result.rows[x].id,
                'counter_name': result.rows[x].counter_name
              };
              this.counterList.push(counterData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getCashBankList() {
    let selectQuery = 'SELECT * FROM mst_cash_banks';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.cashBankList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let cashBankData = {
                'id': result.rows[x].id,
                'cash_bank_name': result.rows[x].name
              };
              this.cashBankList.push(cashBankData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getCurrencyList() {
    let selectQuery = 'SELECT * FROM mst_foreign_currencies';
    this.db.transaction((tx) => {
      tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.currencyList = [];
            for(let x = 0; x < result.rows.length; x++) {
              let currencyData = {
                'id': result.rows[x].id,
                'foreign_currency_name': result.rows[x].foreign_currency_name,
                'exchange_rate': result.rows[x].exchange_rate
              };
              this.currencyList.push(currencyData);
            }
          }
      }, (error) => {
        console.log(error);
      });
    });
  }

  getExchangeRate(event: any) {
    let id = event.target.value;
    let checkCurrency: any = this.currencyList.find(x => x.id === id);
    if(checkCurrency) {
      this.formReceiptVoucherCreate.controls.exchangeRate.setValue(checkCurrency.exchange_rate);
    }
  }

  inputData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formReceiptVoucher = this.formReceiptVoucherCreate.value;
      let cashBankId = formReceiptVoucher.cashBankId;
      let receiptFrom = formReceiptVoucher.receiptFrom;
      let currency = formReceiptVoucher.currency;
      let amount = formReceiptVoucher.amount;
      let exchangeRate = formReceiptVoucher.exchangeRate;
      let receiptProofNo = formReceiptVoucher.receiptProofNo;

      let docDateConvert = this.utilService.convertDate(formReceiptVoucher.docDate);
      let documentDate = docDateConvert.years + '-' + docDateConvert.months + '-' + docDateConvert.dates;

      let options = { "token": this.token };
      
      this.receiptVoucherService.getLatestReceiptVoucherId(options).subscribe((response) => {
        
        this.generateId(response);

        const receiptVoucher = new ReceiptVoucher();
        receiptVoucher.id = this.receiptVoucherId;
        receiptVoucher.cash_bank_id = cashBankId;
        receiptVoucher.doc_date = documentDate;
        receiptVoucher.receipt_from = receiptFrom;
        receiptVoucher.currency = currency;
        receiptVoucher.exchange_rate = exchangeRate;
        receiptVoucher.amount = amount;
        receiptVoucher.receipt_proof_no = receiptProofNo;
        receiptVoucher.status = '0';
        receiptVoucher.created_by = this.userProfile.username;

        this.receiptVoucherService.addReceiptVoucher(receiptVoucher).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 201) {
            this.saveReceiptVoucherDetail(this.receiptVoucherId);
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  saveReceiptVoucherDetail(receiptVoucherId: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formReceiptDetail = this.formReceiptVoucherCreate.value;
      let arrReceiptVoucherId: any = [];
      let arrCounterId: any = [];
      let arrCoa: any = [];
      let arrAmount: any = [];
      let arrDesc: any = [];

      for(let x = 0; x < formReceiptDetail.detail.length; x++) {
        arrReceiptVoucherId[x] = receiptVoucherId;
        arrCounterId[x] = formReceiptDetail.detail[x].counterId;
        arrCoa[x] = formReceiptDetail.detail[x].coa.id;
        arrAmount[x] = parseInt(formReceiptDetail.detail[x].amount);
        arrDesc[x] = formReceiptDetail.detail[x].desc;
      }

      const receiptDetail = new ReceiptVoucherDetail();
      receiptDetail.receipt_voucher_id = arrReceiptVoucherId;
      receiptDetail.counter_id = arrCounterId;
      receiptDetail.coa = arrCoa;
      receiptDetail.amount = arrAmount;
      receiptDetail.desc = arrDesc;

      this.receiptVoucherService.addReceiptVoucherDetail(receiptDetail).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/receipt-voucher']);;
          }
        }
      ]
    });

    await alert.present();
  }

  generateId(response: any) {
    let todayDate = new Date();
    let convertDate = this.utilService.convertDate(todayDate);
    let years = convertDate.years.substr(2,2);
    let month = convertDate.months;

    if(response.results.length > 0) {
      let latestReceiptVoucherId: any = response.results[0].id;
      let lastCounter: any = latestReceiptVoucherId.substr(6, 3);
      let lastMonth: any = parseInt(latestReceiptVoucherId.substr(4, 2));
      let newCounter: any = (parseInt(lastCounter) + 1);
      if(lastMonth === parseInt(month)) {
        if(newCounter <= 9) { newCounter = '00' + newCounter.toString(); }
        if(newCounter > 9 && newCounter <= 99) { newCounter = '0' + newCounter.toString(); }
      } else {
        newCounter = '001';
      }

      this.receiptVoucherId = 'RV' + years + month + newCounter;
    } else {
      this.receiptVoucherId = 'RV' + years + month + '001';
    }
  }

}
