import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { Menu } from '../../../models/menu.model';
import { MenuService } from '../menu.service';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-menu-edit',
  templateUrl: './menu-edit.page.html',
  styleUrls: ['./menu-edit.page.scss'],
})
export class MenuEditPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  formMenuEdit: FormGroup;
  menuId: any;
  menuData: any;
  parentMenuData: any[] = [];
  statusMenu: any[] = ['Not Active', 'Active'];
  parentId:any;
  selectedParents:any;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.buildFormMenuEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, menu]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        let checkAccess = menu.find(x => x.root === 'menu');
        if(!checkAccess || checkAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.menuId = AES.decrypt(snapshot.i, myKey).toString(utf8);

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.menuService.getMenuforEdit(this.menuId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.menuData = response.results.data;
          this.parentMenuData = response.results.data_parent_menu;
          this.parentId = this.menuData.parent_id;
          let selectedParent: any = this.parentMenuData.find(x => x.id ==  this.parentId);
          if(selectedParent){
            let idx =this.parentMenuData.indexOf(selectedParent);
            this.selectedParents=(this.parentMenuData[idx]);
          }

          this.formMenuEdit = this.fb.group({
            parentId: [this.menuData.parent_id, Validators.required],
            menuName: [this.menuData.menu_name, Validators.required],
            root: [this.menuData.root, Validators.required],
            weight: [this.menuData.weight, Validators.required],
            icon: [this.menuData.icon, Validators.required],
            activeStatus: [parseInt(this.menuData.active), Validators.required]
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  buildFormMenuEdit() {
    this.formMenuEdit = this.fb.group({
      parentId: [],
      menuName: [],
      root: [],
      weight: [],
      icon: [],
      activeStatus: []
    });
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const menuForm = this.formMenuEdit.getRawValue();
      const menu = new Menu();
      menu.parent_id = this.parentId;
      menu.menu_name = menuForm.menuName;
      menu.root = menuForm.root;
      menu.weight = menuForm.weight;
      menu.icon = menuForm.icon;
      menu.active = menuForm.activeStatus;
      menu.updated_by = this.userProfile.username;

      this.menuService.updateMenu(this.menuId, menu).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/menu']);;
          }
        }
      ]
    });

    await alert.present();
  }
  
  selectParent(event){
    this.parentId = event.value.id;
  }

}
