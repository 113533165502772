import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { Customer } from '../../models/customer.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterResellerService {

  constructor(private httpService: HttpService) { }

  getCustomerResllerForCreate(params: any): Observable<any> {
    return this.httpService.get('customer-reseller/create', params);
  }

  addCustomerReseller(data: Customer): Observable<any> {
    return this.httpService.post('customer-reseller', data);
  }
}
