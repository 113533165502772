import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-report-other-expences-detail',
  templateUrl: './report-other-expences-detail.component.html',
  styleUrls: ['./report-other-expences-detail.component.scss'],
})
export class ReportOtherExpencesDetailComponent implements OnInit {

  @Input() reportDataDetail: any[];

  otherExpencesId: any;
  counterName: any;
  warehouseName: any;
  docDate: any;
  statusName: any;
  description: any;

  constructor(
    public modalController: ModalController
    ) { }

  ngOnInit() {
      if(this.reportDataDetail.length > 0) {
        this.otherExpencesId = this.reportDataDetail[0].other_expences_id;
        this.counterName = this.reportDataDetail[0].counter_name;
        this.warehouseName = this.reportDataDetail[0].warehouse_name;
        this.docDate = this.reportDataDetail[0].doc_date;
        this.statusName = this.reportDataDetail[0].status === '0' ? 'PENDING' : this.reportDataDetail[0].status === '1' ? 'OK' : this.reportDataDetail[0].status === '2' ? 'VOID' : '-';
        this.description = this.reportDataDetail[0].desc;
      }
    }
  
    async dismissModal() {
      const close: string = "Modal Removed";
      await this.modalController.dismiss(close);
    }

}
