import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-report-cashier-branch-list',
  templateUrl: './report-cashier-branch-list.component.html',
  styleUrls: ['./report-cashier-branch-list.component.scss'],
})
export class ReportCashierBranchListComponent implements OnInit {

  @Input() branchList: any[];
  constructor(
    public modalController: ModalController) { }

  ngOnInit() {}

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }
}
