import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { MasterRewardPageRoutingModule } from './master-reward-routing.module';
import { MasterRewardPage } from './master-reward.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MasterRewardPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [MasterRewardPage]
})
export class MasterRewardPageModule {}