import { MstPriceGroup, MstPriceGroupDetail } from './mst-price-group.model';

export class MstPriceGroupBundle {

    mstPriceGroup: MstPriceGroup;
    mstPriceGroupDetail: MstPriceGroupDetail;

    constructor(model?) {
        model = model || {};
        this.mstPriceGroup = model.mstPriceGroup || new MstPriceGroup();
        this.mstPriceGroupDetail = model.mstPriceGroupDetail || new MstPriceGroupDetail();
    }
}