import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { ProductRequest } from '../../models/product-request.model';
import { ProductReceiving, ProductReceivingDetail } from '../../models/product-receiving.model';
import { ApprovalHistory } from '../../models/approval-history.model';
import { StockMutation } from '../../models/stock-mutation.model';
import { ProductReceivingBundle } from '../../models/product-receiving-bundle.model';
import { ProductReceivingClosed } from '../../models/product-receiving-closed.model';

@Injectable({
  providedIn: 'root'
})
export class ProductReceivingService {

  constructor(private httpService: HttpService) { }

  getProductReceving(params: any): Observable<any> {
    return this.httpService.get('product-receiving', params);
  }

  getLocalData(name: any, params: any): Observable<any> {
    let apiUrl: any;
    if(name === 'counter') { apiUrl = 'counter-all'; }
    if(name === 'warehouse') { apiUrl = 'warehouse-all'; }

    return this.httpService.get(apiUrl, params);
  }

  getProduct(params: any): Observable<any> {
    return this.httpService.get('product-all', params);
  }

  getProductReceivingforCreate(params: any): Observable<any> {
    return this.httpService.get('product-receiving/create', params);
  }

  getLatestProductReceiveId(params: any): Observable<any> {
    return this.httpService.get('product-receiving-latest-id', params);
  }

  getProductReceivingbyId(productReceivingId: any, params: any): Observable<any> {
    return this.httpService.get(`product-receiving/${productReceivingId}/edit`, params);
  }

  getProductRequestDetail(params: any): Observable<any> {
    return this.httpService.get('product-request-details/show', params);
  }

  addProductReceiving(data: ProductReceiving): Observable<any> {
    return this.httpService.post('product-receiving', data);
  }

  addProductReceivingDetail(data: ProductReceivingDetail): Observable<any> {
    return this.httpService.post('product-receiving-details/store', data);
  }

  addStockMutation(data: StockMutation): Observable<any> {
    return this.httpService.post('stock-mutation/store', data);
  }

  addApprovalHistory(data: ApprovalHistory): Observable<any> {
    return this.httpService.post('approval-history', data);
  }
  
  addReceivingBundle(data: ProductReceivingBundle): Observable<any> {
    return this.httpService.post('product-receiving-bundle', data);
  }

  closedReceivingBundle(data: ProductReceivingClosed): Observable<any> {
    return this.httpService.post('product-receiving-closed', data);
  }

  updateProductRequest(productRequestId: any, data: ProductRequest): Observable<any> {
    return this.httpService.put(`product-request/${productRequestId}`, data);
  }

  updateProductReceiving(productReceivingId: any, data: ProductReceiving): Observable<any> {
    return this.httpService.put(`product-receiving/${productReceivingId}`, data);
  }

  manageStock(params: any): Observable<any> {
    return this.httpService.get('stock/manage-stock', params);
  }
}
