
import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ClaimIncentiveService {

  constructor(private httpService: HttpService) { }
  generate(data: any): Observable<any> {
    return this.httpService.post('list-transactions-reseller', data);
  }  
  
  giveResellerReward(data: any): Observable<any> {
    return this.httpService.post('give-reseller-reward', data);
  }
}
