import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../report.service';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../../service/util.service';
import { Storage } from '@ionic/storage';
import * as XLSX from 'xlsx-js-style';
import { RoleAccess } from '../../../../models/role-access.model';
import { UserData } from '../../../../providers/user-data';
import { Router } from '@angular/router';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportSummaryStockComponent } from '../../../../component/report-summary-stock/report-summary-stock.component';
import { ReportStockComponent } from '../../../../component/report-stock/report-stock.component';


@Component({
  selector: 'app-report-national-stock',
  templateUrl: './report-national-stock.page.html',
  styleUrls: ['./report-national-stock.page.scss'],
})
export class ReportNationalStockPage implements OnInit {

  dataProduct:any[]=[];
  dataCounter:any[]=[];
  year : any=(new Date()).getFullYear();
  month: any=(new Date()).getMonth();
  date: any=(new Date()).getDate();
  token : any;  
  colspanTotal:number=1;

  arrayBottom:any= [];
  arrayRight:any= [];
  totalAll:number=0;
  
  roleAccess = new RoleAccess();
  userProfile: UserProfile = new UserProfile();
  
  monthList :any[]=['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'];
  
  branchList:any;
  counterList:any;
  productList:any;

  counterByLogin : any;
  //filter
  startPeriod:any='';
  endPeriod:any='';
  filterCounterName:any='';

  showTitleSummary:boolean=false;
  showReportSummary:boolean=false;
  showReportStock:boolean=true;
  reportTitle:any='Hide Report';

  //filter kolom result
  filterByProduct:boolean=true;
  filterByCounter:boolean=false;

  // di send ke api untuk generate report
  paramCounter = '';
  paramBranch = '';
  arrayTotalStockAllCounter:any[]=[];

  dataStock:any[]=[];
  constructor(
    private reportService :ReportService,
    private toastCtrl : ToastController,
    private utilService :UtilService,
    private storage: Storage,
    private userData: UserData,
    private navCtrl: NavController,
    private router: Router,
    private modalController :ModalController
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {     
          this.userProfile = new UserProfile(profile);
          this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": this.counterByLogin
          };
          this.reportService.getReportSummaryIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.branchList = response.results.branch_data;
            this.counterList = response.results.counter_data;
          });
        });
      }
    });
  } 

  resetfilter(){
    this.showTitleSummary = false;
    this.dataProduct = [];
    this.filterCounterName = '';
    this.filterByProduct=true;
    this.filterByCounter= false;
  }
  
  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ReportSummaryStockComponent,
      componentProps: {
        branchList:this.branchList,
        counterList: this.counterList,
        productList: this.productList,
        paramCounterId: this.paramCounter,
        paramBranchId: this.paramBranch
      },
      backdropDismiss: false
    });
    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if (data !== 'null') {
        this.resetfilter();
        this.filterByCounter=true;
        let arrCounter= [];
       
        if(data[0]['counterId']!=''){
          arrCounter= data[0]['counterId'].split(',');
        }
        if(arrCounter.length>0){
          for(let i=0;i<arrCounter.length;i++){
            let filterCounter = this.counterList.find(x => x.id == arrCounter[i]);
            this.filterCounterName += filterCounter.counter_name+',';
          }
        }
        this.paramCounter ='';
        if(data[0]['counterId']!=''){
          this.paramCounter +=  data[0]['counterId']+ ',';
        }

        this.paramBranch ='';
        if(data[0]['branchId']!=''){
          this.paramBranch +=  data[0]['branchId']+ ',';
        }

        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          // generate report
          let body = {
            branchId:this.paramBranch,
            counterId: this.paramCounter
          };
          this.reportService.getReportNationalStock(body).subscribe((response) => {
            this.utilService.loadingDismiss();
            if(response.status.code === 200) {
              this.arrayBottom = [];
              this.arrayRight = [];
              this.dataProduct = response.results.data_product;
              this.dataStock = response.results.data_stock;
              this.dataCounter = response.results.data_counter;
              this.showReportStock=true;
              for(let i=0;i<this.dataStock.length;i++){    
                let findProduct = this.arrayRight.find(x => x.product_id==this.dataStock[i]['product_id']);                                 
                let findCounter = this.arrayBottom.find(x => x.counter_id==this.dataStock[i]['counter_id']);
                let qty_01 = 0;
                let qty_02 = 0;
                let qty_03 = 0;
                let qty_04 = 0;
                let qty_05 = 0;
                let qty_06 = 0;
                let qty_07 = 0;
                let qty_08 = 0;
                let qty_09 = 0;
                let qty_10 = 0;
                let qty_11 = 0;
                let qty_12 = 0;
                  
                if(this.dataStock[i]['qty_01']){
                  qty_01 = parseInt(this.dataStock[i]['qty_01']);
                }
                if(this.dataStock[i]['qty_02']){
                  qty_02 = parseInt(this.dataStock[i]['qty_02']);
                }
                if(this.dataStock[i]['qty_03']){
                  qty_03 = parseInt(this.dataStock[i]['qty_03']);
                }
                if(this.dataStock[i]['qty_04']){
                  qty_04 = parseInt(this.dataStock[i]['qty_04']);
                }
                if(this.dataStock[i]['qty_05']){
                  qty_05 = parseInt(this.dataStock[i]['qty_05']);
                }
                if(this.dataStock[i]['qty_06']){
                  qty_06 = parseInt(this.dataStock[i]['qty_06']);
                }
                if(this.dataStock[i]['qty_07']){
                  qty_07 = parseInt(this.dataStock[i]['qty_07']);
                }
                if(this.dataStock[i]['qty_08']){
                  qty_08 = parseInt(this.dataStock[i]['qty_08']);
                }
                if(this.dataStock[i]['qty_01']){
                  qty_09 = parseInt(this.dataStock[i]['qty_09']);
                }
                if(this.dataStock[i]['qty_01']){
                  qty_10 = parseInt(this.dataStock[i]['qty_10']);
                }
                if(this.dataStock[i]['qty_01']){
                  qty_11 = parseInt(this.dataStock[i]['qty_11']);
                }
                if(this.dataStock[i]['qty_01']){
                  qty_12 = parseInt(this.dataStock[i]['qty_12']);
                }
                if(findCounter){
                  findCounter['qty_01'] += qty_01;
                  findCounter['qty_02'] += qty_02;
                  findCounter['qty_03'] += qty_03;
                  findCounter['qty_04'] += qty_04;
                  findCounter['qty_05'] += qty_05;
                  findCounter['qty_06'] += qty_06;
                  findCounter['qty_07'] += qty_07;
                  findCounter['qty_08'] += qty_08;
                  findCounter['qty_09'] += qty_09;
                  findCounter['qty_10'] += qty_10;
                  findCounter['qty_11'] += qty_11;
                  findCounter['qty_12'] += qty_12;
                }
                else{
                  this.arrayBottom.push({
                    'counter_id': this.dataStock[i]['counter_id'],
                    'qty_01': qty_01,
                    'qty_02': qty_02,
                    'qty_03': qty_03,
                    'qty_04': qty_04,
                    'qty_05': qty_05,
                    'qty_06': qty_06,
                    'qty_07': qty_07,
                    'qty_08': qty_08,
                    'qty_09': qty_09,
                    'qty_10': qty_10,
                    'qty_11': qty_11,
                    'qty_12': qty_12
                  })
                }
                let qty = 0;
                let findCounterDate = this.dataCounter.find(x => x.id==this.dataStock[i]['counter_id']);
                
                if(findCounterDate ){
                  let idx = this.dataCounter.indexOf(findCounterDate);
                  let trans_date=  this.dataCounter[idx]['trans_date']
                  let month = trans_date.substring(5, 7);
                  if(month=="01"){
                    qty = qty_01;
                  }
                  else if(month=="02"){
                    qty = qty_02;
                  }
                  else if(month=="03"){
                    qty = qty_03;
                  }
                  else if(month=="04"){
                    qty = qty_04;
                  }
                  else if(month=="05"){
                    qty = qty_05;
                  }
                  else if(month=="06"){
                    qty = qty_06;
                  }
                  else if(month=="07"){
                    qty = qty_07;
                  }
                  else if(month=="08"){
                    qty = qty_08;
                  }
                  else if(month=="09"){
                    qty = qty_09;
                  }
                  else if(month=="10"){
                    qty = qty_10;
                  }
                  else if(month=="11"){
                    qty = qty_11;
                  }
                  else if(month=="12"){
                    qty = qty_12;
                  }
                }
                if(findProduct){
                  findProduct['qty'] += qty;
                }
                else{
                  this.arrayRight.push({
                    'product_id': this.dataStock[i]['product_id'],
                    'qty': qty,
                  })
                }
              }
              this.totalAll =0;
              for(let i=0;i<this.arrayRight.length;i++){
                this.totalAll += this.arrayRight[i]['qty'];
              }
            } else {
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            }
          }, (err) => {
            this.utilService.loadingDismiss();
            console.log(err);
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        })
      }
    });
    return await modal.present();
  }
   
  exportToExcelStock() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table-stock');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
   
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    for (let y in ws) {
      if (typeof(ws[y]) != "object") continue;
      ws[y].s = { // styling for all cells
        font: {
            name: "arial"
        },
        alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: '1', // any truthy value here
        }
      };

    }
    for(let i=0;i<(this.dataCounter.length+3);i++){
      if(i==0){
        ws['!cols'].push({ width: 5 });
      }
      else if(i==1){
        ws['!cols'].push({ width: 10 });
      }
      else{        
        ws['!cols'].push({ width: 40 });
      }
    }
    /* save to file */
    XLSX.writeFile(wb, 'Report Stock '+this.date+'-'+this.monthList[parseInt(this.month)]+'-'+this.year+'.xlsx');
  }
  
  toggleReportStock() {
    if(this.showReportStock === false) {
      this.showReportStock = true;
      this.reportTitle = 'Hide Report';
    } else {
      this.showReportStock = false;
      this.reportTitle = 'Show Report';
    }
  }

  async openMutationStockDetail(productId: any, warehouseId: any,trans_date:any) { 
  
      const modal = await this.modalController.create({
        component: ReportStockComponent,
        componentProps: {
          'warehouseId': warehouseId,
          'productId': productId,
          'reportDate': trans_date,
          'earlyStock': 0,
          'inStock': 0,
          'outStock': 0,
          'endStock': 0,
          'type' : ""
        },
        backdropDismiss: false
      });
  
      modal.onDidDismiss().then((modelData) => {
        let data = modelData.data;
  
        if (data !== 'null') {
          //
        }
      });
  
      return await modal.present();
  }
  getStock(product_id: any, counter_id: any,trans_date:any): any {   
    let qtyEnd=0;
    let month = trans_date.substring(5, 7);
    
    let qty = "qty_"+month;
    let checkStock = this.dataStock.find(x => x.product_id == product_id && x.counter_id==counter_id);
    if(checkStock){
      qtyEnd = (checkStock[qty]);      
    }  
    let countPerCounter = this.arrayBottom.find(x => x.counter_id==counter_id);
    if(countPerCounter){      
      let idx = this.arrayBottom.indexOf(countPerCounter);  
      this.arrayBottom[idx]['qty'] = (countPerCounter[qty]);
    }
    if(qtyEnd==null){
      qtyEnd =0;
    }
    return qtyEnd;
  }
  
  //total qty stock produk di semua counter
  getTotalAllCounter(product_id: any): any {
    let qtyEnd=0;
    let countAllCounter = this.arrayRight.find(x => x.product_id==product_id);
    if(countAllCounter){
     qtyEnd = (countAllCounter['qty']);
    }
    return qtyEnd;
  }
  
  //total qty stock produk per counter
  getTotalPerCounter(counter_id: any): any {
    let qtyEnd=0;
    let countAllCounter = this.arrayBottom.find(x => x.counter_id==counter_id);
    if(countAllCounter){
     qtyEnd = (countAllCounter['qty']);
    }
    return qtyEnd;
  }
}