import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../service/util.service';
import { ClaimInsentifResellerService } from './claim-insentif-reseller.service';
import { UserProfile } from '../../models/user-profile.model';
import { MemberResellerClaimInsentifComponent } from '../../component/member-reseller-claim-insentif/member-reseller-claim-insentif.component';

@Component({
  selector: 'app-claim-insentif',
  templateUrl: './claim-insentif.page.html',
  styleUrls: ['./claim-insentif.page.scss'],
})
export class ClaimInsentifPage implements OnInit {

  periode: any;
  reseller: any;
  resellerId: any;
  resellerCode: any;
  year: any;
  month: any;
  reportData: any;
  arrSumData: any[] = [];
  token: string;
  userProfile: UserProfile = new UserProfile();
  monthList: any[] = ['', 'JAN', 'FEB', 'MAR', 'APR', 'MEI', 'JUN', 'JUL', 'AGU', 'SEP', 'OKT', 'NOV', 'DES'];
  timezone: any[] = ['Asia/Jakarta', 'Asia/Makassar', 'Asia/Jayapura'];
  selectedPrinter: any;
  printers: string[];
  isDefaultPrinterSelected = false;
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private claimInsentifService: ClaimInsentifResellerService
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('visitor_id')
    ])
    .then(([token, profile, visitorId]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });

    // WebSocket settings
    JSPM.JSPrintManager.auto_reconnect = true;
    JSPM.JSPrintManager.start();
    JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (this.jspmWSStatus()) {
            // get client installed printers
            JSPM.JSPrintManager.getPrinters().then((myPrinters: string[]) => {
              this.printers = myPrinters;
              this.isDefaultPrinterSelected = true;
              (<HTMLInputElement>document.getElementById("inputBarcode")).focus();
            });
        }
    };
  }

  jspmWSStatus() { // Check JSPM WebSocket status
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
        return true;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
        alert('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
        alert('JSPM has blocked this website!');
        return false;
    }
  }

  async openMemberReseller() {
    const modal = await this.modalController.create({
      component: MemberResellerClaimInsentifComponent,
      componentProps: {},
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      this.resellerId = modelData.data[0].id;
      this.reseller = modelData.data[0].customer_name;
      this.resellerCode = modelData.data[0].referral_code;
      this.reportData = [];
      this.arrSumData = [];
      this.generate();
    });
    return await modal.present();
  }

  generate() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let dateConvert = this.utilService.convertDate(this.periode);
      this.year = dateConvert.years;
      this.month = dateConvert.months;
      let body = {
        reseller_id: this.resellerId,
        year: this.year,
        month: this.month
      };
      
      this.claimInsentifService.generate(body).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.reportData = response.results;
          this.countTotal(response.results);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  countTotal(report: any) {
    let sumTotal: number = 0, sumDiscount: number = 0, sumNetto: number = 0, sumTaxBasis: number = 0,
    sumGrandTotal: number = 0, sumTunai: number = 0, sumCredit: number = 0, sumDebit: number = 0, sumTransfer: number = 0,
    sumValasUSD: number = 0, sumValasCNY: number = 0, sumValasWNY: number = 0, sumVoucher: number = 0,
    sumTotalBayar: number = 0, sumKembali: number = 0, sumNetTunai: number = 0, sumMemberPoint: number = 0, sumReward: number = 0;
    for(let i = 0; i < report.length; i++) {
        sumTotal += parseInt(report[i].total);
        sumDiscount += parseInt(report[i].discount);
        sumNetto += parseInt(report[i].netto);
        sumTaxBasis += parseInt(report[i].tax_basis);
        sumGrandTotal += parseInt(report[i].grand_total);
        sumNetTunai += parseInt(report[i].nettunai);
        sumTunai += parseInt(report[i].tunai);
        sumCredit += parseInt(report[i].credit_card);
        sumDebit += parseInt(report[i].debit_card);
        sumTransfer += parseInt(report[i].transfer);
        sumValasUSD += parseInt(report[i].valas_usd);
        sumValasCNY += parseInt(report[i].valas_cny);
        sumValasWNY += parseInt(report[i].valas_wny);
        sumVoucher += parseInt(report[i].voucher);
        sumMemberPoint += parseInt(report[i].member_point);
        sumReward += parseInt(report[i].reward);
        sumTotalBayar += parseInt(report[i].total_bayar);
        sumKembali += parseInt(report[i].kembali);
    }

    let data = {
      sum_total: sumTotal,
      sum_discount: sumDiscount,
      sum_netto: sumNetto,
      sum_tax_basis: sumTaxBasis,
      sum_grand_total: sumGrandTotal,
      sum_nettunai: sumNetTunai,
      sum_tunai: sumTunai,
      sum_credit: sumCredit,
      sum_debit: sumDebit,
      sum_transfer: sumTransfer,
      sum_valas_usd: sumValasUSD,
      sum_valas_cny: sumValasCNY,
      sum_valas_wny: sumValasWNY,
      sum_voucher: sumVoucher,
      sum_memberpoint: sumMemberPoint,
      sum_reward: sumReward,
      sum_total_bayar: sumTotalBayar,
      sum_kembali: sumKembali
    };
    this.arrSumData.push(data);
  }

  print() {
    let transactionDate = this.userProfile.counter_detail ? new Date(this.userProfile.counter_detail.trans_date) : new Date();
    let timezoneName = this.userProfile.counter_detail ? this.timezone[parseInt(this.userProfile.counter_detail.timezone)] : this.timezone[0];
    let convertTime = this.utilService.convertDateWithMoment(new Date(), timezoneName);
    let convertDate = this.utilService.convertDate(transactionDate);
    let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;
    let transTime = convertTime.hours + ':' + convertTime.minutes + ':' + convertTime.seconds;
    //Create a ClientPrintJob
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += '---------------------------------';
    cmds += newLine;
    cmds += 'RESELLER : '+this.reseller;
    cmds += newLine;
    cmds += 'KODE RESELLER  : '+this.resellerCode;
    cmds += newLine;
    let monthName: any = this.monthList[(parseInt(this.month))];
    cmds += 'BULAN  : '+monthName+' '+this.year;
    cmds += newLine;
    cmds += '---------------------------------';
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += newLine;
    for(let i = 0; i < this.reportData.length; i++) {
      let grandTotal: number =  this.reportData[i].grand_total; 
      let counterCode: String = this.reportData[i].counter_code;
      let receiptNo: String = this.reportData[i].receipt_no;
        cmds += counterCode+' '+receiptNo+' '+this.getLblGt(this.formatRupiah(grandTotal.toString()), counterCode.length+1, receiptNo.length+1);
        cmds += newLine;
    }
    let total: number =  this.arrSumData[0].sum_grand_total; 
    let lblTotal: String = 'TOTAL : ';
    cmds += this.getLblTotal(this.formatRupiah(total.toString()), lblTotal);
    cmds += newLine;
    cmds += newLine;
    cmds += '          KLAIM INSENTIF';
    cmds += newLine;
    cmds += '           TERIMA KASIH';
    cmds += newLine;
    cmds += newLine;
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
    cmds += printPhone;
    cmds += newLine;
    cmds += cut;
    console.log(cmds);
    cpj.printerCommands = cmds;
    
    //Send print job to printer!
    cpj.sendToClient();
  }

  setPaper() {
    const cpj = new JSPM.ClientPrintJob();

    //Set Printer type (Refer to the help, there many of them!)
    if ( this.isDefaultPrinterSelected ) {
      cpj.clientPrinter = new JSPM.DefaultPrinter();
    } else {
      cpj.clientPrinter = new JSPM.InstalledPrinter(this.selectedPrinter);
    }

    //Set content to print...
    //Create ESP/POS commands for sample label
    let esc = '\x1B'; //ESC byte in hex notation
    let newLine = '\x0A'; //LF byte in hex notation
    let cut = "\x1b" + "\x69";
    let cmds = esc + "@"; //Initializes the printer (ESC @)
    cmds += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)
    cmds += esc + '*' + '\x32'; //Character font A selected (ESC ! 0)
    cmds += newLine;
    cmds += '         OEMAH HERBORIST';
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.counter_name));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.first_address));
    cmds += newLine;
    cmds += this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.last_address));
    cmds += newLine;
    let printPhone = (this.userProfile.counter_detail.phone != null) ? this.getLabelHeaderReceipt(String(this.userProfile.counter_detail.phone)) : '';
    cmds += printPhone;
    cmds += newLine; 
    cmds += cut;
    cpj.printerCommands = cmds;
    //Send print job to printer!
    cpj.sendToClient();
  }

  getLabelHeaderReceipt(headerText: String) {
    let totalLength: number = headerText.length;
    let maxTextLengthforReceipt = 33;
    let resultText = "";
    if(totalLength > maxTextLengthforReceipt) {
      resultText = headerText.slice(0, maxTextLengthforReceipt);
    } else {
      let diff = maxTextLengthforReceipt - totalLength;
      let divide = (diff - (diff % 2)) / 2;
      let space = "";
      for(let i = 0; i < divide; i++) {
        space = space + " ";
      }
      
      resultText = space + headerText + space;
    }

    return resultText;
  }

  formatRupiah(angka: string) {
    var number_string = angka.replace(/[^,\d]/g, '').toString();
    var split = number_string.split(',');
    var sisa = split[0].length % 3;
    var rupiah = split[0].substr(0, sisa);
    var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      var separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return rupiah;
    // return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  }

  getLblGt(gt: String, counterCodeLength: number, receiptNoLenght: number): String {
    let spaceLbl: String = '';
    let receiptRowLength: number = 33;

    let gtLength: number = gt.length;
    let diff: number = receiptRowLength - (gtLength + counterCodeLength + receiptNoLenght);
    console.log('diff', diff);
    for(let i = 0; i < diff; i++) {
      spaceLbl = spaceLbl + ' ';
    }
    const lblGt = spaceLbl + gt.toString();
    return lblGt;
  }
  getLblTotal(total: String, lbl: String): String {
    let spaceLbl: String = '';
    let receiptRowLength: number = 33;

    let gtLength: number = total.length;
    let diff: number = receiptRowLength - (gtLength + lbl.length);
    console.log('diff', diff);
    for(let i = 0; i < diff; i++) {
      spaceLbl = spaceLbl + ' ';
    }
    const lblTotal = spaceLbl +''+ lbl +''+ total.toString();
    return lblTotal;
  }
}
