export class ReceiptVoucher {

    id: string;
    cash_bank_id: number;
    doc_date: string;
    receipt_from: string;
    currency: string;
    exchange_rate: number;
    amount: number;
    receipt_proof_no: string;
    status: string;
    created_by: string;
    updated_by: string;
    
    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.cash_bank_id = model.cash_bank_id || 0;
        this.doc_date = model.doc_date || null;
        this.receipt_from = model.receipt_from || null;
        this.currency = model.currency || null;
        this.exchange_rate = model.exchange_rate || 0;
        this.amount = model.amount || 0;
        this.receipt_proof_no = model.receipt_proof_no || null;
        this.status = model.status || '0';
        this.created_by = model.createed_by || null;
        this.updated_by = model.updated_by || null;
    }
}

export class ReceiptVoucherDetail {

    receipt_voucher_id: string[];
    counter_id: number[];
    coa: string[];
    amount: number[];
    desc: string[];

    constructor(model?) {
        model = model || {};
        this.receipt_voucher_id = model.receipt_voucher_id || [];
        this.counter_id = model.counter_id || [];
        this.coa = model.coa || [];
        this.amount = model.amount || [];
        this.desc = model.desc || [];
    }
}