export class Customer {

    id: number;
    counter_id: number;
    customer_type_id: number;
    card_id: string;
    referral_code: string;
    referral_code_upline: string;
    customer_name: string;
    identity_number: string;
    phone: string;
    birth_place: string;
    birth_date: string;
    gender: string;
    profession: string;
    area_code: string;
    area: string;
    address: string;
    map_location: string;
    join_date: string;
    internal_employee: string;
    active: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || 0;
        this.customer_type_id = model.customer_type_id || 0;
        this.card_id = model.card_id || null;
        this.referral_code = model.referral_code || null;
        this.referral_code_upline = model.referral_code_upline || null;
        this.customer_name = model.customer_name || null;
        this.identity_number = model.identity_number || null;
        this.phone = model.phone || null;
        this.birth_place = model.birth_place || null;
        this.birth_date = model.birth_date || null;
        this.gender = model.gender || null;
        this.profession = model.profession || null;
        this.area_code = model.area_code || null;
        this.area = model.area || null;
        this.address = model.address || null;
        this.map_location = model.map_location || null;
        this.join_date = model.join_date || null;
        this.internal_employee = model.internal_employee || null;
        this.active = model.status || '1';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}