export class ResellerMonthlyReward {

    id: string;
    customer_id: number;
    period_transaction_start: string;
    period_transaction_end: string;
    total_transaction: number;
    paid_date: string;
    paid_amount: number;
    created_by: string;
    updated_by: string;
    
    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.customer_id = model.customer_id || 0;
        this.period_transaction_start = model.period_transaction_start || null;
        this.period_transaction_end = model.period_transaction_end || null;
        this.total_transaction = model.total_transaction || 0;
        this.paid_date = model.paid_date || null;
        this.paid_amount = model.paid_amount || 0;
        this.created_by = model.createed_by || null;
        this.updated_by = model.updated_by || null;
    }
}