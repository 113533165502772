import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit {

  @Input() totalTransaction: string;
  @Input() voucherUseList: any[];
  @Input() token: any;
  voucherCode: any;
  baseUrl: any;
  totalVoucher: any = 0;
  voucherData: any[] = [];

  db: any;

  constructor(
    public modalController: ModalController,
    private utilService: UtilService,
    private toastCtrl: ToastController
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
    this.openDB();
    if(this.voucherUseList.length > 0) {
      this.voucherData = this.voucherUseList;
    }
  }

  openDB() {
   // this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  async dismissModal() {
    const voucherValue: any = this.totalVoucher;
    await this.modalController.dismiss(this.voucherData);
  }

  getVoucher(voucherCode) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let selectQuery = 'SELECT * FROM mst_voucher WHERE voucher_code = "' + voucherCode + '"';
      this.db.transaction((tx) => {
        tx.executeSql(selectQuery, [],
        (tx, result) => {
          if(result.rows.length > 0) {
            this.utilService.loadingDismiss();
            this.voucherCode = '';
            let voucherExpiredDate = new Date(result.rows[0].expired_date);
            let convertDate = this.utilService.convertDate(new Date());
            let todayDate = new Date(convertDate.years + '-' + convertDate.months + '-' + convertDate.dates);
            if(result.rows[0].status === 1) {
              let alertMessage: any = "Voucher code " + voucherCode + " telah digunakan!";
              this.toastCtrl.create({ duration: 2000, message: alertMessage }).then(t => t.present());
            } else if(voucherExpiredDate.getTime() < todayDate.getTime()) {
              let alertMessage: any = "Voucher code " + voucherCode + " telah kadaluarsa!";
              this.toastCtrl.create({ duration: 2000, message: alertMessage }).then(t => t.present());
            } else {
              this.totalVoucher = parseInt(this.totalVoucher) + parseInt(result.rows[0].nominal);
              this.voucherData.push(result.rows[0]);
            }
          } else {
            this.utilService.loadingDismiss();
            this.voucherCode = '';
            this.toastCtrl.create({ duration: 2000, message: 'Voucher tidak ditemukan!' }).then(t => t.present());
          }
        }, (error) => {
          this.utilService.loadingDismiss();
          console.log(error);
        });
      });
    });
  }

}
