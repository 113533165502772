import { Component, OnInit } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../../service/util.service';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportProductRequestComponent } from '../../../../component/advanced-filter/report-product-request/report-product-request.component';
import { ReportProductRequestDetailComponent } from '../../../../component/report-product-request-detail/report-product-request-detail.component';
import * as XLSX from 'xlsx';
import { ReportService } from '../../report.service';
import { ProductRequestStatus } from '../../../../enum/ProductRequestStatus';

@Component({
  selector: 'app-report-product-request',
  templateUrl: './report-product-request.page.html',
  styleUrls: ['./report-product-request.page.scss'],
})
export class ReportProductRequestPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  counterData: any[] = [];
  warehouseData: any[] = [];
  counterName: any = null;
  warehouseName: any = null;
  fileName= 'Report_Product_Request.xlsx';

  paramProductRequestId: any = null;
  paramBranchId: any;
  paramCounterId:any;
  paramWarehouseId: any;
  paramDocDateStart: any = null;
  paramDocDateEnd: any = null;
  paramNeedDateStart: any = null;
  paramNeedDateEnd: any = null;

  reportData: any[] = [];
  reportDataDetail: any[] = [];

  requestStatus = ProductRequestStatus;
  requestStatusList = Object.keys(ProductRequestStatus).filter(
    requestStatus => typeof this.requestStatus[requestStatus] === 'number'
  );

  constructor(
    private storage: Storage,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private reportService: ReportService) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": counterId
          };
          this.reportService.getReportProductRequestIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.counterData = response.results.counter_data;
            this.warehouseData = response.results.warehouse_data;
            this.warehouseData.unshift({
              'id': 0,
              'counter_id': 0,
              'warehouse_name': 'SEMUA GUDANG'
            });
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        });
      }
    });
  }

  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ReportProductRequestComponent,
      componentProps: {
        counterList: this.counterData,
        warehouseList: this.warehouseData,
        paramProductRequestId: this.paramProductRequestId,
        paramBranchId: this.paramBranchId,
        paramCounterId: this.paramCounterId,
        paramWarehouseId: this.paramWarehouseId,
        paramDocDateStart: this.paramDocDateStart,
        paramDocDateEnd: this.paramDocDateEnd,
        paramNeedDateStart: this.paramNeedDateStart,
        paramNeedDateEnd: this.paramNeedDateEnd
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data.length > 0) {
        this.paramProductRequestId = data[0].productRequestId;
        this.paramBranchId = data[0].branchId;
        this.paramCounterId = data[0].counterId;
        this.counterName = data[0].counterName;
        this.paramWarehouseId = data[0].warehouseId;
        this.warehouseName = data[0].warehouseName;
        this.paramDocDateStart = data[0].docDateStart;
        this.paramDocDateEnd = data[0].docDateEnd;
        this.paramNeedDateStart = data[0].needDateStart;
        this.paramNeedDateEnd = data[0].needDateEnd;

        this.generateReport();
      }
    });

    return await modal.present();
  }

  async openReportProductDetail() {
    const modal = await this.modalController.create({
      component: ReportProductRequestDetailComponent,
      componentProps: {
        'reportDataDetail': this.reportDataDetail
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }

  generateReport() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "product_request_id": this.paramProductRequestId,
        "counter_id": this.paramCounterId,
        "warehouse_id": this.paramWarehouseId,
        "doc_date_start": this.paramDocDateStart,
        "doc_date_end": this.paramDocDateEnd,
        "need_date_start": this.paramNeedDateStart,
        "need_date_end": this.paramNeedDateEnd
      };

      this.reportService.getReportProductRequest(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.reportData = response.report_data;
          if(this.reportData.length > 0) {
            if(this.paramProductRequestId !== null) {
              this.paramCounterId = this.reportData[0].counter_id;
              this.counterName = this.reportData[0].counter_name;
              this.paramWarehouseId = this.reportData[0].warehouse_id;
              this.warehouseName = this.reportData[0].warehouse_name;
              this.paramDocDateStart = this.reportData[0].doc_date;
              this.paramDocDateEnd = this.reportData[0].doc_date;
              this.paramNeedDateStart = this.reportData[0].need_date;
              this.paramNeedDateEnd = this.reportData[0].need_date;
            }
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  productRequestDetail(productRequestId: any, action: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "product_request_id": productRequestId
      };

      this.reportService.getReportProductRequestDetail(options).subscribe((response) => {
        if(response.status.code === 200) {
          this.reportDataDetail = response.report_data;
          if(action === 'detail') {
            this.utilService.loadingDismiss();
            this.openReportProductDetail();
          }

          if(action === 'export') {
            this.waitForDataToLoad();
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async waitForDataToLoad() {
    await new Promise(resolve => setTimeout(resolve, 1500));
    this.exportToExcel();
  }

  exportToExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.utilService.loadingDismiss();
  }

  checkStatus(status: any) {
    let cssClass: any = '';
    if(status === '0') { cssClass = 'normal-text status-pending'}
    if(status === '1') { cssClass = 'normal-text status-sent'}
    if(status === '2') { cssClass = 'normal-text status-void'}
    if(status === '3') { cssClass = 'normal-text status-void'}
    if(status === '4') { cssClass = 'normal-text status-pending'}
    if(status === '5') { cssClass = 'normal-text status-pending'}
    if(status === '6') { cssClass = 'normal-text status-sent'}
    return cssClass;
  }

}
