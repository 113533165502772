export class RoleAccess {

    role_id: number;
    menu_id: number;
    view: string;
    read: string;
    read_all: string;
    create: string;
    update: string;
    delete: string;
    approve: string;
    download: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.role_id = model.role_id || 0;
        this.menu_id = model.menu_id || 0;
        this.view = model.view || '0';
        this.read = model.read || '0';
        this.read_all = model.read_all || '0';
        this.create = model.create || '0';
        this.update = model.update || '0';
        this.delete = model.delete || '0';
        this.approve = model.approve || '0';
        this.download = model.download || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}