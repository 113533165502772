import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { RoleAccess } from '../models/role-access.model';

@Injectable({
  providedIn: 'root'
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

  constructor(
    public storage: Storage
  ) { }

  hasFavorite(sessionName: string): boolean {
    return (this.favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  login(email: any, password: any, response: any): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.storage.set('user_email', email);
      this.storage.set('user_password', password);
      this.storage.set('user_token', response.token);
      this.storage.set('user_profile', response.user);
      return window.dispatchEvent(new CustomEvent('user:login'));
    });
  }

  signup(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:signup'));
    });
  }

  logout(): Promise<any> {
    console.log('LOGOUT');
    this.storage.clear();
    return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
      this.storage.remove('username');
      this.storage.remove('user_email');
      this.storage.remove('user_password');
      this.storage.remove('user_token');
      this.storage.remove('user_profile');
      this.storage.remove('user_menu_access');
    }).then(() => {
      window.dispatchEvent(new CustomEvent('user:logout'));
    });
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set('username', username);
  }

  getUsername(): Promise<string> {
    return this.storage.get('username').then((value) => {
      return value;
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  }

  checkAccess(url: any, userAccess: any, userProfile: any) {
    let arrTemp = String(url).split('/');
    let thisPage = arrTemp[1];
    let checkUserAccess: any = userAccess.find(x => x.root === thisPage);
    const roleAccess = new RoleAccess();
    if(checkUserAccess) {
      roleAccess.approve = checkUserAccess.approve;
      roleAccess.create = checkUserAccess.create;
      roleAccess.delete = checkUserAccess.delete;
      roleAccess.download = checkUserAccess.download;
      roleAccess.menu_id = checkUserAccess.id;
      roleAccess.read = checkUserAccess.read;
      roleAccess.read_all = checkUserAccess.read_all;
      roleAccess.role_id = userProfile.role_id;
      roleAccess.update = checkUserAccess.update;
      roleAccess.view = checkUserAccess.view;
    }

    return roleAccess;
  }

  checkAccessType(counterId: any, branchId: any) {
    let accessType: any = '';
    if(counterId === 0 && branchId === 0) {
      accessType = 'ALL_ACCESS';
    }

    if(counterId !== 0 && branchId === 0) {
      accessType = 'COUNTER_ACCESS';
    }

    if(counterId === 0 && branchId !== 0) {
      accessType = 'BRANCH_ACCESS';
    }

    return accessType;
  }
}
