import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { PosResellerService } from '../../pages/pos-reseller/pos-reseller.service';
import { UtilService } from '../../service/util.service';

@Component({
  selector: 'app-member-reseller-claim-insentif',
  templateUrl: './member-reseller-claim-insentif.component.html',
  styleUrls: ['./member-reseller-claim-insentif.component.scss'],
})
export class MemberResellerClaimInsentifComponent implements OnInit {
  @Input() token: any;
  @Input() customer: any;
  listReseller: any[] = [];
  keyword: any;

  constructor(
    public modalController: ModalController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private posResellerService: PosResellerService,
  ) { }

  ngOnInit() {}
  
  async searchReseller() {
    if(this.keyword.length >= 3) {
      this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
        let options = {
          "token": this.token,
          "keyword": this.keyword
        };

        this.posResellerService.getReseller(options).subscribe(async (response) => {
          if(response.results) {
            this.listReseller = [];
            if(response.results.data.length > 0) {
              for (let index = 0; index < response.results.data.length; index++) {
                this.listReseller.push({
                  id: response.results.data[index].id,
                  customer_type_id: response.results.data[index].customer_type_id,
                  card_id: response.results.data[index].card_id,
                  referral_code: response.results.data[index].referral_code,
                  customer_name: response.results.data[index].customer_name,
                  identity_number: response.results.data[index].identity_number,
                  phone: response.results.data[index].phone,
                  birth_place: response.results.data[index].birth_place,
                  birth_date: response.results.data[index].birth_date,
                  gender: response.results.data[index].gender,
                  profession: response.results.data[index].profession,
                  area_code: response.results.data[index].area_code,
                  area: response.results.data[index].area,
                  address: response.results.data[index].address,
                  map_location: response.results.data[index].map_location,
                  join_date: response.results.data[index].join_date,
                  internal_employee: response.results.data[index].internal_employee,
                  active: response.results.data[index].active,
                  created_by: response.results.data[index].created_by,
                  updated_by: response.results.data[index].updated_by,
                  created_at: response.results.data[index].created_at,
                  updated_at: response.results.data[index].updated_at
                });  
              }
            }
            this.utilService.loadingDismiss();
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari reseller.' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari reseller.' }).then(t => t.present());
        });
      });
    }else {
      this.utilService.loadingDismiss();
      this.listReseller = [];
    }
  }

  async choose(id: any) {
    let param = this.listReseller.filter(str => str.id == id);
    this.modalController.dismiss(param);
  }

  async dismissModal() {
    let param = [];
    await this.modalController.dismiss(param);
  }
}
