import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  baseUrl: any;

  constructor(
    private http: HttpClient
  ) {
    this.baseUrl = environment.apiUrl;
  }

  changePassword(userId: any, oldPassword: string, newPassword: string, updatedBy: any): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('user_id', userId);
    formData.append('old_password', oldPassword);
    formData.append('new_password', newPassword);
    formData.append('updated_by', updatedBy);

    return this.http.put(this.baseUrl + 'change-password', formData, { headers: reqOpts });
  }
}
