import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';

@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.page.html',
  styleUrls: ['./product-type.page.scss'],
})
export class ProductTypePage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  baseUrl: any;
  rows: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  typeList: any;
  filteredData: any;

  constructor(
    private storage: Storage,
    private http: HttpClient,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.pageCallback({ offset: this.page.offset });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      const params = new HttpParams()
        .set('token', this.token)
        .set('page', offset.toString());

      this.http.get(this.baseUrl + 'product-type', { params })
      .subscribe((response: any) => {
        this.utilService.loadingDismiss();
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.typeList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let columnLength = 7;
    let keys = Object.keys(this.typeList[0]);
    this.rows = this.filteredData.filter(function(item){
      for (let i=0; i < columnLength; i++){
        if(item[keys[i]] !== null) {
          if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
            return true;
          }
        }
      }
    });

    this.page.offset = 0;
  }

  /*relog() {
    Promise.all([
      this.storage.get('user_email'),
      this.storage.get('user_password')
    ])
    .then(([email, password]) => {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.loginService.userLogin(email, password)
        .subscribe((response: any) => {
          this.utilService.loadingDismiss();
          if(response.token) {
            this.storage.set('user_token', response.token);
            this.toastCtrl.create({ duration: 2000, message: 'Refresh kembali laman ini.' }).then(t => t.present());
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Email atau Password salah!' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }*/

}
