import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { RequestVoidService } from '../request-void/request-void.service';
import { VoidTransactionStatus } from '../../enum/VoidTransactionStatus';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { RoleAccess } from '../../models/role-access.model';
import { RequestVoidComponent } from '../../component/advanced-filter/request-void/request-void.component';

import { UserData } from '../../providers/user-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-void',
  templateUrl: './request-void.page.html',
  styleUrls: ['./request-void.page.scss'],
})
export class RequestVoidPage implements OnInit {

  reportType: any = null;
  voidData: any[] = [];
  counterData: any[] = [];
  token: any; 
  userAccess: any[] = [];
  userProfile: UserProfile = new UserProfile();
  paramCounterId: any;
  paramPeriodStart :any;
  paramPeriodEnd :any;

  counterName: any;
  idCounter: any = 'null';

  dateStart: any;
  dateEnd: any;
  
  counterListForShow: any[] = [];
  voidStatus = VoidTransactionStatus;
  voidStatusList = Object.keys(VoidTransactionStatus).filter(
    voidStatus => typeof this.voidStatus[voidStatus] === 'number'
  );
  
  roleAccess = new RoleAccess();

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: 'Menu_Name',
    orderDir: 'desc'
  };
  constructor(
    private utilService: UtilService,
    private storage: Storage,
    private requestVoidService: RequestVoidService,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private alertController: AlertController,
    private modalController: ModalController,
    private userDataProvider: UserData,
    private router: Router,
    ) { }

  ngOnInit() {
    this.reloadTable();
  }
  
  generateReport(counterId: any, periodStart: any, periodEnd: any) {

    this.setParamReport(counterId, periodStart, periodEnd);
    let startDate = new Date(periodStart);
    let endDate = new Date(periodEnd);
    let convertDateStart = this.utilService.convertDate(startDate);
    let convertDateEnd = this.utilService.convertDate(endDate);
    this.dateStart = convertDateStart.years + '-' + convertDateStart.months + '-' + convertDateStart.dates;
    this.dateEnd = convertDateEnd.years + '-' + convertDateEnd.months + '-' + convertDateEnd.dates;
    this.idCounter = counterId !== 'undefined' && counterId !== '' ? counterId : 'null';

    this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
            
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "start_date": this.dateStart,
        "end_date": this.dateEnd,
        "counter_id": this.idCounter,
      };
      this.requestVoidService.getVoidRequest(options).subscribe((response) => {
       
        
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.utilService.loadingDismiss();
        this.voidData = response.results.data;
        this.counterData = response.results.counter_data;

          let cashierData: any = response.cashier_data;
          let userData: any = response.user_data;
          let typeData: any = response.type_data;
          let resellerData: any = response.reseller_data;
          this.counterListForShow = this.counterData;

          if(this.counterListForShow.length > 0) {
            let loopCounter = 0;
            if(this.counterListForShow.length > 5) {
              loopCounter = 5;
            } else {
              loopCounter = this.counterListForShow.length;
            }

            this.counterName = '';
            for(let i = 0; i < loopCounter; i++) {
              if(this.counterName === '') {
                this.counterName = this.counterListForShow[i].counter_name;
              } else {
                this.counterName = this.counterName + ', ' + this.counterListForShow[i].counter_name;
              }
            }
          } else {
            this.counterName = 'Data Not Found';
          }
          
          
          
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  

  reloadTable() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userAccess = access;
        this.userProfile = new UserProfile(profile);
        let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
        this.generateReport(counterId, new Date(), new Date());
       /* this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          
          this.userProfile = new UserProfile(profile);
          let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          this.generateReport(counterId, new Date(), new Date());
          this.utilService.loadingDismiss();
         /* let options = { 
            "token": this.token,
            "counter_id": counterId
          };
          this.requestVoidService.getVoidRequest(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            console.log(response.results.data);
            this.voidData = response.results.data;
            this.counterData = response.results.counter_data;
            //this.generateReport(counterId, new Date(), new Date());
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        })*/
      }
      });
  }



  async showConfirmVoidTransaction(id: any, voidId:any, receiptNo: any){
    console.log();
    let notifMessage: any = '';
    if(receiptNo !== null) {
      notifMessage = 'Apakah anda yakin untuk melakukan void untuk transaksi dengan struk no. '+ receiptNo + ' ?';
    } else {
      let messageSetup: any = 'Sistem tidak dapat membaca nomor struk';
      this.toastCtrl.create({ duration: 2000, message: messageSetup }).then(t => t.present());
    }

    if(notifMessage !== '') {
      const alert = await this.alertController.create({
        header: 'Void Confirmation',
        cssClass:'custom-alert-class',
        message: notifMessage,
        buttons: [
          {
            text: 'Cancel',
            handler: () => {}
          },
          {
            text: 'OK',
            handler: () => {
             this.checkUserRoles(id, receiptNo, voidId);
            }
          }
        ]
      });
  
      await alert.present();
    }

  }
  
  setParamReport(counterId: any, periodStart: any, periodEnd: any) {
      
      this.paramCounterId = counterId;
      this.paramPeriodStart = periodStart;
      this.paramPeriodEnd = periodEnd;
  }

  checkUserRoles(id: any, receiptNo: any, voidId : any) {
    console.log(this.roleAccess);
   if(this.roleAccess.delete === '1') {
      this.showInputPassword(id, receiptNo, voidId), voidId;
    } else {
      this.showNotifNotAuthorized('Anda tidak memiliki akses untuk melakukan Void !');
   }
  }
  
  async showInputPassword(id: any, receiptNo: any, voidId:any ){
    const alert = await this.alertController.create({
      header: 'Confirmation',
      subHeader: 'Verifikasi Password',
      message: 'Silahkan masukkan password kembali',
      inputs: [
        {
          name: 'password',
          placeholder: 'Password',
          type: 'password'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: (input: any) => {
            this.storage.get('user_password')
            .then((userPassword) => {
              if(input.password === userPassword) {
                this.showInputVoidReason(id, receiptNo, voidId);
              } else {
                this.showNotifNotAuthorized("Password yang anda masukkan salah!");
              }
            });
          }
        }
      ]
    });
    
    await alert.present();
  }
  async showInputVoidReason(id: any, receiptNo: any, voidId:any) {
    const alert = await this.alertController.create({
      header: 'Confirmation',
      subHeader: 'Keterangan Void',
      message: 'Silahkan masukkan keterangan void',
      inputs: [
        {
          name: 'voidReason',
          placeholder: 'Keterangan',
          type: 'text'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: (input: any) => {
            let descVoid = input.voidReason ? input.voidReason : 'Tanpa keterangan';
            this.saveVoidTransaction(id, receiptNo, descVoid, voidId);
          }
        }
      ]
    });
    
    await alert.present();
  }
  
  saveVoidTransaction(id: any, receiptNo: any, descVoid: any, voidId: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let body = {
        id: id,
        void_id: voidId,
        receipt_no: receiptNo,
        desc_void: descVoid,
        created_by: this.userProfile.username,
        updated_by: this.userProfile.username
      };
      
      this.requestVoidService.saveVoidTransaction(body).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showNotifAfterVoidTransaction(receiptNo);
          this.voidData = null;
          
          this.navCtrl.navigateForward(['/request-void']);;
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  async showNotifAfterVoidTransaction(receiptNo: any) {
    let notifMessage: any = 'Berhasil void transaksi struk no. ' + receiptNo;

    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: notifMessage,
      buttons: [
        {
          text: 'OK',
          handler: () => {
           
            this.generateReport(
              this.paramCounterId,  this.paramPeriodStart, 
              this.paramPeriodEnd);
          }
        }
      ]
    });

    await alert.present();
  }
  
  async showNotifNotAuthorized(messageValue: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: messageValue,
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    });

    await alert.present();
  }
  async openAdvancedFilters(){
    let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
         
    this.paramCounterId = counterId;
    console.log(this.paramCounterId);
    const modal = await this.modalController.create({
      component: RequestVoidComponent,
      componentProps: {
        paramCounterId: this.paramCounterId,
        counterList: this.counterData,
      },
      backdropDismiss: false
    });
    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      console.log(data);
      if (data !== 'null') {
        let splitData = data.split('#');
          this.generateReport(splitData[2], splitData[0],splitData[1]);
        
      }
    });


    return await modal.present();

  }

}
