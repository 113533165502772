import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, ModalController, AlertController, NavController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../../service/util.service';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportCashierComponent } from '../../../../component/advanced-filter/report-cashier/report-cashier.component';
import { ReportCashierCounterListComponent } from '../../../../component/report-cashier-counter-list/report-cashier-counter-list.component';
import * as XLSX from 'xlsx';
import { ReportService } from '../../report.service';
import { ReportCashierType, TransactionType } from '../../../../enum/ReportCashierType';
import { UserData } from '../../../../providers/user-data';
import { RoleAccess } from '../../../../models/role-access.model';
import { ReportCashierBranchListComponent } from '../../../../component/report-cashier-branch-list/report-cashier-branch-list.component';

@Component({
  selector: 'app-report-cashier',
  templateUrl: './report-cashier.page.html',
  styleUrls: ['./report-cashier.page.scss'],
})
export class ReportCashierPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  userAccess: any[] = [];
  formCashierReport: FormGroup;
  branchData: any[] = [];
  counterData: any[] = [];
  cashierData: any[] = [];
  userData: any[] = [];
  reportData: any[] = [];
  brandData: any[] = [];
  typeData: any[] = [];
  customerResellerData: any[] = [];
  reportType: any = null;

  dateStart: any;
  dateEnd: any;
  idCounter: any = 'null';
  idBranch: any= 'null';
  idCashier: any = 'null';
  idUser: any = 'null';
  noReceipt: any = 'null';
  typeId: any = 'null';
  transactionType: any;
  resellerId: any = 'null';

  branchName: any;
  counterName: any;
  cashierName: any;
  userName: any;
  typeName: any;
  resellerName: any;
  transactionTypeName: any;
  rekapPerName: any;

  tempIndex: any = 0;
  tempReceiptNumber: any = '0';

  paramBranchId: any;
  paramCounterId: any;
  paramCashierId: any;
  paramUserId: any;
  paramTransactionTypeId: any;
  paramRekapPer: any;
  paramPeriodStart: any;
  paramPeriodEnd: any;
  paramReceiptNumber: any;
  paramTypeId: any;
  paramResellerId: any;

  db: any;

  fileName= 'ReportKasir.xlsx';
  typeReport = ReportCashierType;
  typeTransaction = TransactionType;
  typeReportList = Object.keys(ReportCashierType).filter(
    typeReport => typeof this.typeReport[typeReport] === 'number'
  );
  typeTransactionList = Object.keys(TransactionType).filter(
    typeTransaction => typeof this.typeTransaction[typeTransaction] === 'number'
  );
  
  roleAccess = new RoleAccess();
  showBtnCounterList: boolean = false;
  showBtnBranchList: boolean = false;
  counterListForShow: any[] = [];
  branchListForShow:any[] =[];
  sumQty: number = 0;
  sumBruto: number = 0;
  sumDiscount: number = 0;
  sumNetto: number = 0;

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private reportService: ReportService,
    private alertController: AlertController,
    private router: Router,
    private userDataProvider: UserData,
    private navCtrl:NavController
  ) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userAccess = access;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": counterId
          };
          this.reportService.getReportCashierIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.cashierData = response.results.cashier_data;
            this.branchData = response.results.branch_data;
            this.counterData = response.results.counter_data;
            this.userData = response.results.user_data;
            this.typeData = response.results.type_data;
            this.customerResellerData = response.results.reseller_data;
            this.roleAccess = this.userDataProvider.checkAccess(this.router.url, this.userAccess, this.userProfile);
            let branchId='';
            if(this.branchData.length>0){
              for(let i = 0; i < this.branchData.length; i++) {
                if(branchId === '') {
                  branchId = this.branchData[i].id;
                } else {
                  branchId = branchId + ', ' + this.branchData[i].id;
                }
              }
            }
            this.generateReport(branchId,counterId, '', '', '0', '0', new Date(), new Date(), '', '', '');
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  buildFormReportCashier() {
    let todayDate = new Date();
    let convertDate = this.utilService.convertDate(todayDate);
    let transDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.formCashierReport = this.fb.group({
      transactionTypeId: ['0'],
      rekapPer: ['0'],
      periodStart: [transDate],
      periodEnd: [transDate],
      receiptNumber: [],
      counterId: [],
      cashierId: []
    });
  }

  generateReport(branchId:any,counterId: any, cashierId: any, userId: any,
    transactionTypeId: any, rekapPer: any, periodStart: any, periodEnd: any, receiptNumber: any,
    typeId: any, resellerId: any) {
    this.setParamReport(branchId,counterId, cashierId, userId, transactionTypeId, rekapPer, periodStart, periodEnd, receiptNumber, typeId, resellerId);
    let startDate = new Date(periodStart);
    let endDate = new Date(periodEnd);
    let convertDateStart = this.utilService.convertDate(startDate);
    let convertDateEnd = this.utilService.convertDate(endDate);
    this.dateStart = convertDateStart.years + '-' + convertDateStart.months + '-' + convertDateStart.dates;
    this.dateEnd = convertDateEnd.years + '-' + convertDateEnd.months + '-' + convertDateEnd.dates;
    this.idBranch = branchId !== 'undefined' && branchId !== '' ? branchId : 'null';
    this.idCounter = counterId !== 'undefined' && counterId !== '' ? counterId : 'null';
    
    this.idCashier = cashierId !== 'undefined' && cashierId !== '' ? cashierId : 'null';
    this.idUser = userId !== 'undefined' && userId !== '' ? userId : 'null';
    this.noReceipt = receiptNumber !== 'undefined' && receiptNumber !== '' ? receiptNumber : 'null';
    this.typeId = typeId !== 'undefined' && typeId !== '' ? typeId : 'null';
    this.resellerId = resellerId !== 'undefined' && resellerId !== '' ? resellerId : 'null';
    this.reportType = rekapPer;
    this.transactionType = transactionTypeId;

    this.rekapPerName = this.typeReportList[rekapPer];
    this.transactionTypeName = this.typeTransactionList[transactionTypeId];

    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "start_date": this.dateStart,
        "end_date": this.dateEnd,
        "branch_id":this.idBranch,
        "counter_id": this.idCounter,
        "cashier_id": this.idCashier,
        "user_id": this.idUser,
        "receipt_no": this.noReceipt,
        "type_id": this.typeId,
        "transaction_type": this.transactionType,
        "reseller_id": this.resellerId
      };

      this.reportService.getReportCashier(rekapPer, options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          let cashierData: any = response.cashier_data;
          let userData: any = response.user_data;
          let typeData: any = response.type_data;
          let resellerData: any = response.reseller_data;
          this.counterListForShow = response.counter_data;
          this.branchListForShow = response.branch_data;
          this.reportData = response.results;
          this.branchData =this.branchListForShow;
          if(this.counterListForShow.length > 0) {
            let loopCounter = 0;
            if(this.counterListForShow.length > 5) {
              loopCounter = 5;
              this.showBtnCounterList = true;
            } else {
              loopCounter = this.counterListForShow.length;
              this.showBtnCounterList = false;
            }

            this.counterName = '';
            for(let i = 0; i < loopCounter; i++) {
              if(this.counterName === '') {
                this.counterName = this.counterListForShow[i].counter_name;
              } else {
                this.counterName = this.counterName + ', ' + this.counterListForShow[i].counter_name;
              }
            }
          } else {
            this.counterName = 'Data Not Found';
          }
          
          if(this.branchListForShow.length > 0) {
            let loopBranch = 0;
            if(this.branchListForShow.length >5) {
              loopBranch = 5;
              this.showBtnBranchList = true;
            } else {
              loopBranch = this.branchListForShow.length;
              this.showBtnBranchList = false;
            }

            this.branchName = '';
            for(let i = 0; i < loopBranch; i++) {
              if(this.branchName === '') {
                this.branchName = this.branchListForShow[i].branch_name;
              } else {
                this.branchName = this.branchName + ', ' + this.branchListForShow[i].branch_name;
              }
            }
          } else {
            this.showBtnBranchList = false;
            this.branchName = 'Data Not Found';
          }
          
          this.typeName = typeData.length > 0 ? typeData[0].product_type_name : 'Data Not Found';
        if(cashierData.length > 0) {
            let loopCashier = 0;
            loopCashier = cashierData.length;
            this.cashierName = '';
            for(let i = 0; i < loopCashier; i++) {
              if(this.cashierName === '') {
                this.cashierName = cashierData[i].cashier_name;
              } else {
                this.cashierName = this.cashierName + ', ' + cashierData[i].cashier_name;
              }
            }
          } else {
            this.cashierName = 'Data Not Found';
          }

          if(userData.length > 0) {
            let loopUser = 0;
            loopUser = userData.length;
            this.userName = '';
            for(let i = 0; i < loopUser; i++) {
              if(this.userName === '') {
                this.userName = userData[i].name;
              } else {
                this.userName = this.userName + ', ' + userData[i].name;
              }
            }
          } else {
            this.userName = 'Data Not Found';
          }

          this.resellerName = resellerData.length > 0 ? resellerData[0].referral_code + ' - ' + resellerData[0].customer_name : 'Data Not Found';

          if(rekapPer === '0' || rekapPer === '5') {
            this.countTotalReportTransaction(response.results);
          } else if(rekapPer === '1') {
            this.countTotalReportTransactionDetail(response.results);
          } else if(rekapPer === '3' || rekapPer === '8') {
            this.countTotalReportTransactionBrand(response.results);
          } else if(rekapPer === '2' || rekapPer === '7') {
            this.countTotalReportTransactionProduct(response.results);
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  clearFilter() {
    this.generateReport('','', '', '', '0', this.paramRekapPer, this.paramPeriodStart, this.paramPeriodEnd, '', '', '');
  }

  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ReportCashierComponent,
      componentProps: {
        cashierList: this.cashierData,
        branchList: this.branchData,
        counterList: this.counterData,
        userList: this.userData,
        typeList: this.typeData,
        customerResellerList: this.customerResellerData,
        paramBranchId: this.paramBranchId,
        paramCounterId: this.paramCounterId,
        paramCashierId: this.paramCashierId,
        paramUserId: this.paramUserId,
        paramTransactionTypeId: this.paramTransactionTypeId,
        paramRekapPer: this.paramRekapPer,
        paramPeriodStart: this.paramPeriodStart,
        paramPeriodEnd: this.paramPeriodEnd,
        paramReceiptNumber: this.paramReceiptNumber,
        paramTypeId: this.paramTypeId,
        paramResellerId: this.paramResellerId
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        let splitData = data.split('#');
        if(splitData[0] === '0' || splitData[0] === '1') {
          this.generateReport(splitData[9],splitData[5], splitData[6], splitData[7], splitData[0], splitData[1],
            splitData[2], splitData[3], splitData[4], splitData[8], '');
        }

        if(splitData[0] === '2') {
          this.generateReport(splitData[10],splitData[5], splitData[6], splitData[7], splitData[0], splitData[1],
            splitData[2], splitData[3], splitData[4], splitData[8], splitData[9]);
        }
      }
    });

    return await modal.present();
  }

  countTotalReportTransaction(report: any) {
    let counterId: any = '';

    let sumTotal: number = 0, sumDiscount: number = 0, sumNetto: number = 0, sumTaxBasis: number = 0,
    sumGrandTotal: number = 0, sumTunai: number = 0, sumCredit: number = 0, sumDebit: number = 0, sumTransfer: number = 0,
    sumValasUSD: number = 0, sumValasCNY: number = 0, sumValasWNY: number = 0, sumVoucher: number = 0,
    sumTotalBayar: number = 0, sumKembali: number = 0, sumNetTunai: number = 0, sumMemberPoint: number = 0, sumReward: number = 0;

    let counterIdCount: number = 0;
    let arrSumData: any[] = [];

    for(let i = 0; i < report.length; i++) {
      let tempCounterId = report[i].counter_id;
      if(i === 0) { counterId = report[i].counter_id; }

      if(counterId === tempCounterId) {
        sumTotal = sumTotal + parseInt(report[i].total);
        sumDiscount = sumDiscount + parseInt(report[i].discount);
        sumNetto = sumNetto + parseInt(report[i].netto);
        sumTaxBasis = sumTaxBasis + parseInt(report[i].tax_basis);
        sumGrandTotal = sumGrandTotal + parseInt(report[i].grand_total);
        sumNetTunai = sumNetTunai + parseInt(report[i].nettunai);
        sumTunai = sumTunai + parseInt(report[i].tunai);
        sumCredit = sumCredit + parseInt(report[i].credit_card);
        sumDebit = sumDebit + parseInt(report[i].debit_card);
        sumTransfer = sumTransfer + parseInt(report[i].transfer);
        sumValasUSD = sumValasUSD + parseInt(report[i].valas_usd);
        sumValasCNY = sumValasCNY + parseInt(report[i].valas_cny);
        sumValasWNY = sumValasWNY + parseInt(report[i].valas_wny);
        sumVoucher = sumVoucher + parseInt(report[i].voucher);
        sumMemberPoint = sumMemberPoint + parseInt(report[i].member_point);
        sumReward = sumReward + parseInt(report[i].reward);
        sumTotalBayar = sumTotalBayar + parseInt(report[i].total_bayar);
        sumKembali = sumKembali + parseInt(report[i].kembali);

        if((i+1) === report.length) {
          counterIdCount = counterIdCount + 1;
          let indexInsert = i + counterIdCount;
          let data = {
            id: '0',
            counter_id: counterId,
            counter_name: report[i-1].counter_name,
            sum_total: sumTotal,
            sum_discount: sumDiscount,
            sum_netto: sumNetto,
            sum_tax_basis: sumTaxBasis,
            sum_grand_total: sumGrandTotal,
            sum_nettunai: sumNetTunai,
            sum_tunai: sumTunai,
            sum_credit: sumCredit,
            sum_debit: sumDebit,
            sum_transfer: sumTransfer,
            sum_valas_usd: sumValasUSD,
            sum_valas_cny: sumValasCNY,
            sum_valas_wny: sumValasWNY,
            sum_voucher: sumVoucher,
            sum_memberpoint: sumMemberPoint,
            sum_reward: sumReward,
            sum_total_bayar: sumTotalBayar,
            sum_kembali: sumKembali,
            index_insert: indexInsert
          };

          arrSumData.push(data);
        }
      } else {
        let indexInsert = i + counterIdCount;
        counterIdCount = counterIdCount + 1;
        let data = {
          id: '0',
          counter_id: counterId,
          counter_name: report[i-1].counter_name,
          sum_total: sumTotal,
          sum_discount: sumDiscount,
          sum_netto: sumNetto,
          sum_tax_basis: sumTaxBasis,
          sum_grand_total: sumGrandTotal,
          sum_nettunai: sumNetTunai,
          sum_tunai: sumTunai,
          sum_credit: sumCredit,
          sum_debit: sumDebit,
          sum_transfer: sumTransfer,
          sum_valas_usd: sumValasUSD,
          sum_valas_cny: sumValasCNY,
          sum_valas_wny: sumValasWNY,
          sum_voucher: sumVoucher,
          sum_memberpoint: sumMemberPoint,
          sum_reward: sumReward,
          sum_total_bayar: sumTotalBayar,
          sum_kembali: sumKembali,
          index_insert: indexInsert
        };
        
        arrSumData.push(data);

        //start new sum for next counter
        sumTotal = 0; sumDiscount = 0; sumNetto = 0; sumTaxBasis = 0; sumGrandTotal = 0;
        sumNetTunai = 0; sumTunai = 0; sumCredit = 0; sumDebit = 0; sumTransfer = 0; sumValasUSD = 0; 
        sumValasCNY = 0; sumValasWNY = 0; sumVoucher = 0; sumMemberPoint = 0; sumReward = 0;
        sumTotalBayar = 0; sumKembali = 0;

        sumTotal = sumTotal + parseInt(report[i].total);
        sumDiscount = sumDiscount + parseInt(report[i].discount);
        sumNetto = sumNetto + parseInt(report[i].netto);
        sumTaxBasis = sumTaxBasis + parseInt(report[i].tax_basis);
        sumGrandTotal = sumGrandTotal + parseInt(report[i].grand_total);
        sumNetTunai = sumNetTunai + parseInt(report[i].nettunai);
        sumTunai = sumTunai + parseInt(report[i].tunai);
        sumCredit = sumCredit + parseInt(report[i].credit_card);
        sumDebit = sumDebit + parseInt(report[i].debit_card);
        sumTransfer = sumTransfer + parseInt(report[i].transfer);
        sumValasUSD = sumValasUSD + parseInt(report[i].valas_usd);
        sumValasCNY = sumValasCNY + parseInt(report[i].valas_cny);
        sumValasWNY = sumValasWNY + parseInt(report[i].valas_wny);
        sumVoucher = sumVoucher + parseInt(report[i].voucher);
        sumMemberPoint = sumMemberPoint + parseInt(report[i].member_point);
        sumReward = sumReward + parseInt(report[i].reward);
        sumTotalBayar = sumTotalBayar + parseInt(report[i].total_bayar);
        sumKembali = sumKembali + parseInt(report[i].kembali);
        //end new sum for next counter
        
        counterId = tempCounterId;
      }
    }

    for(let x = 0; x < arrSumData.length; x++) {
      let indexInsert: number = arrSumData[x].index_insert;
      this.reportData.splice(indexInsert, 0, arrSumData[x]);
    }
  }

  countTotalReportTransactionDetail(report: any) {
    let receiptNumber: any = '';

    let sumBruto: number = 0, sumDiscount: number = 0, sumNetto: number = 0, sumCharge: number = 0,
    sumTax: number = 0, sumTotal: number = 0;

    let receiptNumberCount: number = 0;
    let arrSumData: any[] = [];
    let lastCount = report.length - 1;
    let arrGt: any[] = [];

    for(let i = 0; i < report.length; i++) {
      let tempReceiptNumber = report[i].receipt_no;
      if(i === 0) {
        receiptNumber = report[i].receipt_no;
        sumBruto = sumBruto + parseInt(report[i].bruto);
        sumDiscount = sumDiscount + parseInt(report[i].disc);
        sumNetto = sumNetto + parseInt(report[i].netto);
        sumTotal = sumTotal + parseInt(report[i].total);
      } else {
        if(receiptNumber === report[i].receipt_no) {
          sumBruto = sumBruto + parseInt(report[i].bruto);
          sumDiscount = sumDiscount + parseInt(report[i].disc);
          sumNetto = sumNetto + parseInt(report[i].netto);
          sumTotal = sumTotal + parseInt(report[i].total);
        } else {
          let indexInsert = i + receiptNumberCount;
          receiptNumberCount = receiptNumberCount + 1;
          let data = {
            id: '0',
            receipt_no: receiptNumber,
            sum_bruto: sumBruto,
            sum_discount: sumDiscount,
            sum_netto: sumNetto,
            sum_charge: sumCharge,
            sum_tax: sumTax,
            sum_total: sumTotal,
            index_insert: indexInsert
          };
          
          arrSumData.push(data);

          //start new sum for next receipt number
          sumBruto = 0; sumDiscount = 0; sumNetto = 0; sumTotal = 0;

          sumBruto = sumBruto + parseInt(report[i].bruto);
          sumDiscount = sumDiscount + parseInt(report[i].disc);
          sumNetto = sumNetto + parseInt(report[i].netto);
          sumTotal = sumTotal + parseInt(report[i].total);
          //end new sum for next receipt number

          receiptNumber = tempReceiptNumber;
        }

        if(i === lastCount) {

          let indexInsert = i + receiptNumberCount + 1;
          let data = {
            id: '0',
            receipt_no: receiptNumber,
            sum_bruto: sumBruto,
            sum_discount: sumDiscount,
            sum_netto: sumNetto,
            sum_charge: sumCharge,
            sum_tax: sumTax,
            sum_total: sumTotal,
            index_insert: indexInsert
          };
          
          arrSumData.push(data);
        }
      }
    }
    
    let sumGtBruto: number = 0, sumGtDiscount: number = 0, sumGtNetto: number = 0, sumGtCharge: number = 0,
    sumGtTax: number = 0, sumGtTotal: number = 0;

    for(let x = 0; x < arrSumData.length; x++) {
      let indexInsert: number = arrSumData[x].index_insert;
      this.reportData.splice(indexInsert, 0, arrSumData[x]);
      sumGtBruto = sumGtBruto + parseInt(arrSumData[x].sum_bruto);
      sumGtDiscount = sumGtDiscount + parseInt(arrSumData[x].sum_discount);
      sumGtNetto = sumGtNetto + parseInt(arrSumData[x].sum_netto);
      sumGtTotal = sumGtTotal + parseInt(arrSumData[x].sum_total);
    }

    let dataGt = {
      id: '00',
      receipt_no: '',
      sum_gt_bruto: sumGtBruto,
      sum_gt_discount: sumGtDiscount,
      sum_gt_netto: sumGtNetto,
      sum_gt_charge: sumGtCharge,
      sum_gt_tax: sumGtTax,
      sum_gt_total: sumGtTotal
    };
    this.reportData.push(dataGt);
  }

  countTotalReportTransactionBrand(report: any) {
    let brandId: any = '';
    this.sumQty =0;
    this.sumNetto =0;
    let sumQty: number = 0, sumNetto: number = 0;

    let brandIdCount: number = 0;
    let arrSumData: any[] = [];
    report.sort(function(a, b) {
      var textA = a.product_brand_name.toUpperCase();
      var textB = b.product_brand_name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
    for(let i = 0; i < report.length; i++) {
      let tempbrandId = report[i].product_brand_id;
      if(i === 0) { brandId = report[i].product_brand_id; }
      
      if(brandId === tempbrandId) {
        sumQty = sumQty + parseInt(report[i].total_qty);
        sumNetto = sumNetto + parseInt(report[i].netto);
      } else {
        let indexInsert = i + brandIdCount;
        brandIdCount = brandIdCount + 1;
        let data = {
          id: '0',
          product_brand_id: brandId,
          product_brand_name: report[i-1].product_brand_name,
          sum_qty: sumQty,
          sum_netto: sumNetto,
          index_insert: indexInsert
        };
        
        arrSumData.push(data);



        //start new sum for next counter
        sumQty = 0; sumNetto = 0;

        sumQty = sumQty + parseInt(report[i].total_qty);
        sumNetto = sumNetto + parseInt(report[i].netto);
        //end new sum for next counter
        
        brandId = tempbrandId;
      }
      if(i==(report.length-1)){
        let indexInsert = i + brandIdCount+1;
        let data = {
          id: '0',
          product_brand_id: brandId,
          product_brand_name: report[i].product_brand_name,
          sum_qty: sumQty,
          sum_netto: sumNetto,
          index_insert: indexInsert
        };    
        arrSumData.push(data);
      }
    }
    for(let x = 0; x < arrSumData.length; x++) {
      let indexInsert: number = arrSumData[x].index_insert;
      this.reportData.splice(indexInsert, 0, arrSumData[x]);
      this.sumQty += arrSumData[x].sum_qty;
      this.sumNetto += arrSumData[x].sum_netto;
    }
  }

  checkReceiptNo(receiptNumber: any, index: any) {
    
    if(index === 0) {
      this.tempReceiptNumber = receiptNumber;
      return true;
    } else {
      if(this.tempIndex === index) {
        if(this.tempReceiptNumber === receiptNumber) {
          return false;
        } else {
          return true;
        }
      } else {
        this.tempIndex = index;
        if(this.tempReceiptNumber === receiptNumber) {
          return false;
        } else {
          this.tempReceiptNumber = receiptNumber;
          return true;
        }
      }
    }
  }

  createTable(tableName: any, columnList: any) {
    let sqlQuery: string = 'CREATE TABLE IF NOT EXISTS ' + tableName + columnList;

    return new Promise((resolve, reject) => {
      this.db.transaction((tx) => {
        tx.executeSql(sqlQuery, [],
        (tx, result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    });
  }

  execQuery(sqlQuery: any) {
    this.db.transaction((tx) => {
      tx.executeSql(sqlQuery, [],
      (tx, result) => {
      }, (error) => {
        console.log(error);
      });
    });
  }

  getCounterList() {
    let tableName = 'mst_counters';
    let columnList = '(id, branch_id, counter_name, trans_date, first_address, last_address, phone, footer_text, latitude, longitude, active, created_by, updated_by, created_at, updated_at)';
    
    this.createTable(tableName, columnList).then((res) => {
      for (let i = 0; i < this.counterData.length; i++) {

        let insertQuery = 'INSERT INTO ' + tableName + columnList + ' VALUES (' +
        this.counterData[i].id + ', ' + this.counterData[i].branch_id + ', "' +
        this.counterData[i].counter_name + '", "' + this.counterData[i].trans_date + '", "' +
        this.counterData[i].first_address + '", "' + this.counterData[i].last_address + '", "' +
        this.counterData[i].phone + '", "' + this.counterData[i].footer_text + '", "' +
        this.counterData[i].latitude + '", "' + this.counterData[i].longitude + '", "' +
        this.counterData[i].active + '", "' +
        this.counterData[i].created_by + '", "' + this.counterData[i].updated_by + '", "' +
        this.counterData[i].created_at + '", "' + this.counterData[i].updated_at + '")';

        let selectQuery = 'SELECT * FROM ' + tableName + ' WHERE id = ' +this.counterData[i].id;
        let deleteQuery = 'DELETE FROM ' + tableName + ' WHERE id = ' + this.counterData[i].id;

        this.db.transaction((tx) => {
          tx.executeSql(selectQuery, [],
          (tx, result) => {
            if(result.rows.length === 0) {
              this.execQuery(insertQuery);
            } else {
              this.execQuery(deleteQuery);
              this.execQuery(insertQuery);
            }
          }, (error) => {
            console.log(error);
          });
        });
      }
    }, (err) => {
      console.log(err);
    });
  }

  getCashierList() {
    let tableName = 'mst_cashiers';
    let columnList = '(id, counter_id, cashier_name, active, setup, created_by, updated_by, created_at, updated_at)';
    
    this.createTable(tableName, columnList).then((res) => {
      for (let i = 0; i < this.cashierData.length; i++) {

        let insertQuery = 'INSERT INTO ' + tableName + columnList + ' VALUES (' +
        this.cashierData[i].id + ', ' + this.cashierData[i].counter_id + ', "' +
        this.cashierData[i].cashier_name + '", "' + this.cashierData[i].active + '", "' +
        this.cashierData[i].setup + '", "' + this.cashierData[i].created_by + '", "' +
        this.cashierData[i].updated_by + '", "' + this.cashierData[i].created_at + '", "' +
        this.cashierData[i].updated_at + '")';

        let selectQuery = 'SELECT * FROM ' + tableName + ' WHERE id = ' +this.cashierData[i].id;
        let deleteQuery = 'DELETE FROM ' + tableName + ' WHERE id = ' + this.cashierData[i].id;

        this.db.transaction((tx) => {
          tx.executeSql(selectQuery, [],
          (tx, result) => {
            if(result.rows.length === 0) {
              this.execQuery(insertQuery);
            } else {
              this.execQuery(deleteQuery);
              this.execQuery(insertQuery);
            }
          }, (error) => {
            console.log(error);
          });
        });
      }
    }, (err) => {
      console.log(err);
    });
  }

  getUserList() {
    let tableName = 'users';
    let columnList = '(id, name)';
    
    this.createTable(tableName, columnList).then((res) => {
      for (let i = 0; i < this.userData.length; i++) {

        let insertQuery = 'INSERT INTO ' + tableName + columnList + ' VALUES (' +
        this.userData[i].id + ', "' + this.userData[i].name + '")';

        let selectQuery = 'SELECT * FROM ' + tableName + ' WHERE id = ' +this.userData[i].id;
        let deleteQuery = 'DELETE FROM ' + tableName + ' WHERE id = ' + this.userData[i].id;

        this.db.transaction((tx) => {
          tx.executeSql(selectQuery, [],
          (tx, result) => {
            if(result.rows.length === 0) {
              this.execQuery(insertQuery);
            } else {
              this.execQuery(deleteQuery);
              this.execQuery(insertQuery);
            }
          }, (error) => {
            console.log(error);
          });
        });
      }
    }, (err) => {
      console.log(err);
    });
  }

  checkUserRoles(id: any, receiptNo: any) {
    if(this.roleAccess.delete === '1') {
      this.showInputPassword(id, receiptNo);
    } else {
      this.showNotifNotAuthorized('Anda tidak memiliki akses untuk melakukan Void !');
    }
  }

  saveVoidTransaction(id: any, receiptNo: any, descVoid: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let body = {
        id: id,
        receipt_no: receiptNo,
        desc_void: descVoid,
        created_by: this.userProfile.username,
        updated_by: this.userProfile.username
      };
      
      this.reportService.saveVoidTransaction(body).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showNotifAfterVoidTransaction(receiptNo);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  setParamReport(branchId:any,counterId: any, cashierId: any, userId: any,
    transactionTypeId: any, rekapPer: any, periodStart: any, periodEnd: any, receiptNumber: any,
    typeId: any, resellerId: any) {
      
      this.paramBranchId = branchId;
      this.paramCounterId = counterId;
      this.paramCashierId = cashierId;
      this.paramUserId = userId;
      this.paramTransactionTypeId = transactionTypeId;
      this.paramRekapPer = rekapPer;
      this.paramPeriodStart = periodStart;
      this.paramPeriodEnd = periodEnd;
      this.paramReceiptNumber = receiptNumber;
      this.paramTypeId = typeId;
      this.paramResellerId = resellerId;
  }

  async showConfirmVoidTransaction(id: any, receiptNo: any) {
    let notifMessage: any = '';
    if(receiptNo !== null) {
      notifMessage = 'Apakah anda yakin untuk melakukan void untuk transaksi dengan struk no. '+ receiptNo + ' ?';
    } else {
      let messageSetup: any = 'Sistem tidak dapat membaca nomor struk';
      this.toastCtrl.create({ duration: 2000, message: messageSetup }).then(t => t.present());
    }

    if(notifMessage !== '') {
      const alert = await this.alertController.create({
        header: 'Void Confirmation',
        cssClass:'custom-alert-class',
        message: notifMessage,
        buttons: [
          {
            text: 'Cancel',
            handler: () => {}
          },
          {
            text: 'OK',
            handler: () => {
              this.checkUserRoles(id, receiptNo);
            }
          }
        ]
      });
  
      await alert.present();
    }
  }

  async showNotifAfterVoidTransaction(receiptNo: any) {
    let notifMessage: any = 'Berhasil void transaksi struk no. ' + receiptNo;

    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: notifMessage,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.generateReport(
              this.paramBranchId,
              this.paramCounterId, this.paramCashierId, this.paramUserId, 
              this.paramTransactionTypeId, this.paramRekapPer, this.paramPeriodStart, 
              this.paramPeriodEnd, this.paramReceiptNumber, this.paramTypeId,
              this.paramResellerId);
          }
        }
      ]
    });

    await alert.present();
  }

  async showInputPassword(id: any, receiptNo: any) {
    const alert = await this.alertController.create({
      header: 'Confirmation',
      subHeader: 'Verifikasi Password',
      message: 'Silahkan masukkan password kembali',
      inputs: [
        {
          name: 'password',
          placeholder: 'Password',
          type: 'password'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: (input: any) => {
            this.storage.get('user_password')
            .then((userPassword) => {
              if(input.password === userPassword) {
                this.showInputVoidReason(id, receiptNo);
              } else {
                this.showNotifNotAuthorized("Password yang anda masukkan salah!");
              }
            });
          }
        }
      ]
    });
    
    await alert.present();
  }

  async showInputVoidReason(id: any, receiptNo: any) {
    const alert = await this.alertController.create({
      header: 'Confirmation',
      subHeader: 'Keterangan Void',
      message: 'Silahkan masukkan keterangan void',
      inputs: [
        {
          name: 'voidReason',
          placeholder: 'Keterangan',
          type: 'text'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: (input: any) => {
            let descVoid = input.voidReason ? input.voidReason : 'Tanpa keterangan';
            this.saveVoidTransaction(id, receiptNo, descVoid);
          }
        }
      ]
    });
    
    await alert.present();
  }

  async showNotifNotAuthorized(messageValue: any) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: messageValue,
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    });

    await alert.present();
  }

  async showCounterList() {
    const modal = await this.modalController.create({
      component: ReportCashierCounterListComponent,
      componentProps: {
        counterList: this.counterListForShow
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }
  async showBranchList() {
    const modal = await this.modalController.create({
      component: ReportCashierBranchListComponent,
      componentProps: {
        branchList: this.branchListForShow
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }
  countTotalReportTransactionProduct(report: any) {
    this.sumQty = 0,  this.sumBruto=0,  this.sumDiscount =0 , this.sumNetto =0;
    for(let i = 0; i < report.length; i++) {
        this.sumQty = this.sumQty + parseInt(report[i].qty);
        this.sumBruto = this.sumBruto + parseInt(report[i].bruto);
        this.sumDiscount = this.sumDiscount + parseInt(report[i].disc);
        this.sumNetto = this.sumNetto + parseInt(report[i].netto);
    }
  }

}
