import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { MasterProductCounterService } from '../master-product-counter.service';
import { RoleAccess } from '../../../models/role-access.model';
@Component({
  selector: 'app-master-product-counter-edit',
  templateUrl: './master-product-counter-edit.page.html',
  styleUrls: ['./master-product-counter-edit.page.scss'],
})
export class MasterProductCounterEditPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  PCode: any;
  productData: any;
  productCounterData : any;
  brandList: any[] = [];
  subBrandList: any[] = [];
  subBrandListServer: any[] = [];
  typeList: any[] = [];
  subTypeList: any[] = [];
  subTypeListServer: any[] = [];
  categoryList: any[] = [];
  subCategoryList: any[] = [];
  subCategoryListServer: any[] = [];
  marketingTypeList: any[] = [];
  statusProduct: any[] = ['Not Active', 'Active'];
  statusListingProduct: any[] = ['Not_Listing', 'Listing'];

  formMstProductEdit: FormGroup;
  roleAccess = new RoleAccess();
  productType :any;
  counterId : any =0;
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private mstProductCounterService: MasterProductCounterService,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormMstProductEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
        else{
          this.getMstProductforEdit();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormMstProductEdit() {
    this.formMstProductEdit = this.fb.group({
      productId: [],
      productName: [null, Validators.required],
      initialName: [null, Validators.required],
      barcode: [null, Validators.required],
      marketingTypeId: [null, Validators.required],
      brandId: [null, Validators.required],
      subBrandId: [null, Validators.required],
      typeId: [null, Validators.required],
      subTypeId: [null, Validators.required],
      categoryId: [null, Validators.required],
      subCategoryId: [null, Validators.required],
      price: [0, Validators.required],
      listingStatus : [],
      activeStatus: [],
      minStock :[0],
      maxStock :[0]
    });
  }

  getMstProductforEdit() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      this.PCode = snapshot.PCode;

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.mstProductCounterService.getProductForEdit(this.PCode, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.productData = response.results.mst_product;
          this.productCounterData = response.results.mst_product_per_counter;
          this.brandList = response.results.mst_product_brand_data;
          this.subBrandListServer = response.results.mst_product_sub_brand_data;
          this.subBrandList = this.subBrandListServer.filter(x => x.product_brand_id === this.productData.product_brand_id);
          this.typeList = response.results.mst_product_type_data;
          this.subTypeListServer = response.results.mst_product_sub_type_data;
          this.subTypeList = this.subTypeListServer.filter(x => x.product_type_id === this.productData.product_type_id);
          this.categoryList = response.results.mst_product_category_data;
          this.subCategoryListServer = response.results.mst_product_sub_category_data;
          this.subCategoryList = this.subCategoryListServer.filter(x => x.product_category_id === this.productData.product_category_id);
          this.marketingTypeList = response.results.mst_product_marketing_type_data;
          
          this.counterId = this.productCounterData.counter_id;
          this.formMstProductEdit = this.fb.group({
            productId: [this.productData.id],
            productName: [this.productData.product_name, Validators.required],
            initialName: [this.productData.initial_name, Validators.required],
            barcode: [this.productData.barcode],
            marketingTypeId: [parseInt(this.productData.product_marketing_type_id), Validators.required],
            brandId: [this.productData.product_brand_id, Validators.required],
            subBrandId: [parseInt(this.productData.product_sub_brand_id), Validators.required],
            typeId: [this.productData.product_type_id, Validators.required],
            subTypeId: [parseInt(this.productData.product_sub_type_id), Validators.required],
            categoryId: [this.productData.product_category_id, Validators.required],
            subCategoryId: [parseInt(this.productData.product_sub_category_id), Validators.required],
            price: [response.results.mst_price_group_details.price, Validators.required],
            activeStatus: [parseInt(this.productData.active), Validators.required],
            minStock: [parseInt(this.productCounterData.min_stock), Validators.required],
            listingStatus : [parseInt(this.productCounterData.listing_status),Validators.required],
            maxStock :[parseInt(this.productCounterData.max_stock), Validators.required]
          });
          let productType = this.marketingTypeList.find(x => x.id === parseInt(this.productData.product_marketing_type_id));
          
          this.productType =(productType.product_marketing_type_name);
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  getSubTypeList(event) {
    let productTypeId = event.target.value;
    let subTypeList = this.subTypeListServer.filter(x => x.product_type_id === productTypeId);
    this.subTypeList = subTypeList ? subTypeList : this.subTypeListServer;
  }

  getSubCategoryList(event) {
    let productCategoryId = event.target.value;
    let subCategoryList = this.subCategoryListServer.filter(x => x.product_category_id === productCategoryId);
    this.subCategoryList = subCategoryList ? subCategoryList : this.subCategoryListServer;
  }

  getSubBrandList(event) {
    let productBrandId = event.target.value;
    let subBrandList = this.subBrandListServer.filter(x => x.product_brand_id === productBrandId);
    this.subBrandList = subBrandList ? subBrandList : this.subBrandListServer;
  }

  updateProduct() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formMstProduct = this.formMstProductEdit.value;
      let options = {
        "token": this.token,
        "listing_status" : formMstProduct.listingStatus,
        "min_stock": formMstProduct.minStock,
        "max_stock" : formMstProduct.maxStock,
        "updated_by" : this.userProfile.username
      };
     this.mstProductCounterService.updateProduct(this.PCode, options).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/master-product-counter']);;
          }
        }
      ]
    });

    await alert.present();
  }


}
