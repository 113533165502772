import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PurposePage } from './purpose.page';

const routes: Routes = [
  {
    path: '',
    component: PurposePage
  },
  {
    path: 'purpose-create',
    loadChildren: () => import('./purpose-create/purpose-create.module').then( m => m.PurposeCreatePageModule)
  },
  {
    path: 'purpose-edit',
    loadChildren: () => import('./purpose-edit/purpose-edit.module').then( m => m.PurposeEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PurposePageRoutingModule {}
