import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Menu } from '../../models/menu.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private httpService: HttpService) { }

  getMenu(params: any): Observable<any> {
    return this.httpService.get('menu', params);
  }

  getMenuforCreate(params: any): Observable<any> {
    return this.httpService.get('menu/create', params);
  }

  getMenuforEdit(menuId: any, params: any): Observable<any> {
    return this.httpService.get(`menu/${menuId}/edit`, params);
  }

  addMenu(data: Menu): Observable<any> {
    return this.httpService.post('menu', data);
  }

  updateMenu(menuId: any, data: Menu): Observable<any> {
    return this.httpService.put(`menu/${menuId}`, data);
  }

  deleteMenu(menuId: any): Observable<any> {
    return this.httpService.delete(`menu/${menuId}`);
  }
}
