import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { Storage } from '@ionic/storage';
import { UserData } from '../../../providers/user-data';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterProductGimmickService } from '../master-product-gimmick.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { HttpParams } from '@angular/common/http';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-master-product-gimmick-edit',
  templateUrl: './master-product-gimmick-edit.page.html',
  styleUrls: ['./master-product-gimmick-edit.page.scss'],
})
export class MasterProductGimmickEditPage implements OnInit {

  formMstProductGimmickEdit: FormGroup;
  token: any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  id:any;
  productData:any;
  statusProduct: any[] = ['Not Active', 'Active'];
  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private mstProductGimmickService: MasterProductGimmickService,
    private router: Router,
    private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.buildFormMstProductCreate();
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getMstProductforEdit();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormMstProductCreate() {
    this.formMstProductGimmickEdit = this.fb.group({
      id: [0, Validators.required],
      productName: [null, Validators.required],
      initialName: [null, Validators.required],
      barcode: [null],
      productWidth: [0],
      productLength: [0],
      productHeight: [0],
      activeStatus: [0, Validators.required],
    });
  }
  getMstProductforEdit() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.id = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.id = this.id.replaceAll('"','');

      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.mstProductGimmickService.getProductGimmickForEdit(this.id, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.productData = response.results.mst_product_gimmick;

          this.formMstProductGimmickEdit = this.fb.group({
            id: [this.productData.id, Validators.required],
            productName: [this.productData.product_name, Validators.required],
            initialName: [this.productData.initial_name, Validators.required],
            productWidth: [this.productData.width_size],
            productLength: [this.productData.length_size],
            productHeight: [this.productData.height_size],
            barcode: [this.productData.barcode],
            activeStatus: [parseInt(this.productData.active), Validators.required],
          });
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }
  
  updateProduct() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formMstProduct = this.formMstProductGimmickEdit.value;
      let productWidth = formMstProduct.productWidth;
      if(productWidth=='null'){
        productWidth='0';
      }
      let productLength =formMstProduct.productLength;
      if(productLength=='null'){
        productLength='0';
      }
      let productHeight=formMstProduct.productHeight;
      if(productHeight=='null'){
        productHeight='0';
      }
      const params = new HttpParams()
        .set('token', this.token)
        .set('id', this.id)
        .set('product_name', formMstProduct.productName)
        .set('initial_name', formMstProduct.initialName)
        .set('barcode', formMstProduct.barcode)
        .set('product_length',productLength )
        .set('product_width', productWidth)
        .set('product_height', productHeight)
        .set('active', formMstProduct.activeStatus)
        .set('updated_by',  this.userProfile.username);
      this.mstProductGimmickService.updateProduct(params).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/master-product-gimmick']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
