import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MataUangPageRoutingModule } from './mata-uang-routing.module';

import { MataUangPage } from './mata-uang.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MataUangPageRoutingModule
  ],
  declarations: [MataUangPage]
})
export class MataUangPageModule {}
