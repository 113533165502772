export class PaymentVoucher {

    id: number;
    counter_id: number;
    cash_bank_id: number;
    doc_date: string;
    no_referensi: string;
    recipient: string;
    desc: string;
    currency: string;
    exchange_rate: number;
    amount: number;
    payment_proof_no: string;
    created_by: string;
    updated_by: string;
    details: [];
    

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || 0;
        this.cash_bank_id = model.cash_bank_id || 0;
        this.no_referensi = model.recipient || null;
        this.recipient = model.recipient || null;
        this.desc = model.desc || null;
        this.currency = model.currency || null;
        this.exchange_rate = model.exchange_rate || 0;
        this.amount = model.amount || 0;
        this.payment_proof_no = model.payment_proof_no || null;
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
        this.details = model.detail || [];
    }
}