export class StockMutation {
    
    warehouse_id: number[];
    transaction_id: string[];
    stock_mutation_type_id: string[];
    product_id: string[];
    qty: number[];
    value: number[];
    stock_move: string[];
    trans_date: string[];
    status: string[];
    created_at: string[];
    updated_at: string[];

    constructor(model?) {
        model = model || {};
        this.warehouse_id = model.warehouse_id || [];
        this.transaction_id = model.transaction_id || [];
        this.stock_mutation_type_id = model.stock_mutation_type_id || [];
        this.product_id = model.product_id || [];
        this.qty = model.qty || [];
        this.value = model.value || [];
        this.stock_move = model.stock_move || [];
        this.trans_date = model.trans_date || [];
        this.status = model.status || [];
        this.created_at = model.created_at || [];
        this.updated_at = model.updated_at || [];
    }
}