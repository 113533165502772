import { OtherExpences, OtherExpencesDetail } from './other-expences.model';
import { StockMutation } from './stock-mutation.model';
import { ApprovalHistory } from './approval-history.model';

export class OtherExpencesClosedBundle {

    otherExpences: OtherExpences;
    otherExpencesDetail: OtherExpencesDetail;
    stockMutation: StockMutation;
    approvalHistory: ApprovalHistory;

    constructor(model?) {
        model = model || {};
        this.otherExpences = model.otherExpences || new OtherExpences();
        this.otherExpencesDetail = model.otherExpencesDetail || new OtherExpencesDetail();
        this.stockMutation = model.stockMutation || new StockMutation();
        this.approvalHistory = model.approvalHistory || new ApprovalHistory();
    }
}