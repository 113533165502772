export class OtherReceiving {

    id: string;
    counter_id: number;
    warehouse_id: number;
    purpose_id: number;
    doc_date: string;
    desc: string;
    status: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || 0;
        this.warehouse_id = model.warehouse_id || 0;
        this.purpose_id = model.purpose_id || 0;
        this.doc_date = model.doc_date || null;
        this.desc = model.desc || null;
        this.status = model.status || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}

export class OtherReceivingDetail {

    id: number[];
    other_receiving_id: string[];
    product_id: string[];
    qty: number[];
    unit: string[];
    price: number[];
    description: string[];

    constructor(model?) {
        model = model || {};
        this.id = model.id || [];
        this.other_receiving_id = model.other_receiving_id || [];
        this.product_id = model.product_id || [];
        this.qty = model.qty || [];
        this.unit = model.unit || [];
        this.price = model.price || [];
        this.description = model.description || [];
    }
}