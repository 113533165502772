import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';
import { SalesTargetService } from '../sales-target.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { SalesTargetComponent} from '../../../component/sales-target/sales-target.component';
import { MyService } from '../../../service/my.service';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sales-target-edit',
  templateUrl: './sales-target-edit.page.html',
  styleUrls: ['./sales-target-edit.page.scss'],
})
export class SalesTargetEditPage implements OnInit {

  salesTargetId: any;
  action :any;
  salesTargetData: any;
  token:any;
  rows:any[];
  salesTargetDetailData:any[];
  formSalesTargetEdit: FormGroup;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  userId : any;
  counterId : number;
  productList: any[] = [];
  showProductList : boolean = true;
  showPrice:string='0';
  constructor(    
    private activeRoute: ActivatedRoute,
    private utilService : UtilService,
    private salesTargetService : SalesTargetService,
    private toastCtrl : ToastController,
    private modalController : ModalController,
    private fb: FormBuilder,
    private storage : Storage,
    private userData: UserData,
    private router: Router,
    private navCtrl : NavController,
    private alertController : AlertController,
    private myService:MyService
  ) { }

  ngOnInit() {
    this.buildFormSalesTargetEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.userId = this.userProfile.id;
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess ){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        this.getSalesTargetforEdit();
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }  
  buildFormSalesTargetEdit() {
    this.formSalesTargetEdit = this.fb.group({
      counterName: [null],
      periode: [null],
      target: [null],
      description: [null],
      keywordSearch : [null]
    });
  }
  
  getSalesTargetforEdit() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.salesTargetId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.action = AES.decrypt(snapshot.a, myKey).toString(utf8);
      if((this.action =='1' &&  this.roleAccess.update!='1') || (this.action =='2' &&  this.roleAccess.view!='1')  ){
        this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
        this.navCtrl.navigateForward(['/']);
      }
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.salesTargetService.getSalesTargetforEdit(this.salesTargetId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.salesTargetData = response.results.sales_target_data;
          if(this.salesTargetData.length > 0) {
            let periode = this.salesTargetData[0].year+'-'+this.salesTargetData[0].month;            
            this.counterId = this.salesTargetData[0].counter_id;
            this.formSalesTargetEdit = this.fb.group({
              counterName: [this.salesTargetData[0].counter_name],
              periode: [periode, Validators.required],
              description: [this.salesTargetData[0].description, Validators.required],
              target: [this.salesTargetData[0].target, Validators.required],
              keywordSearch : [null]
            });
            
             this.getProductDetails(this.salesTargetId);
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }
  
  update() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const formSalesTarget = this.formSalesTargetEdit.value;
      let arrProduct: any = [];
      let arrTarget: any = [];

      let periodeConvert = this.utilService.convertDate(formSalesTarget.periode);
      for(let x = 0; x < this.productList.length; x++) {
        arrProduct[x] = this.productList[x].product_id;
        arrTarget[x] = parseInt(this.productList[x].target_product);
      }
      let body = {
        counter_id : this.counterId,
        month :periodeConvert.months,
        year : periodeConvert.years,
        target : this.myService.removeSeparator(formSalesTarget.target),
        description : formSalesTarget.description,
        created_by : this.userProfile.username,
        product_id : arrProduct,
        target_product : arrTarget

      // mstPriceGroupBundle.mstPriceGroupDetail.product_id = arrProduct;
        //mstPriceGroupBundle.mstPriceGroupDetail.price = arrPrice;
      }
      this.salesTargetService.updateSalesTarget(this.salesTargetId, body).subscribe((response: any) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/sales-target']);;
          }
        }
      ]
    });

    await alert.present();
  }
  searchProduct() {
    //const formSalesTarget = this.formSalesTargetEdit.value;
    this.getProductDetails(this.salesTargetId);
    /*this.rows = this.rows.filter(item => {
      return item.product_name.toLowerCase().indexOf(formSalesTarget.keywordSearch) !== -1 ||
      item.product_id.toString().toLowerCase().indexOf(formSalesTarget.keywordSearch) !== -1;
    });*/
  } 

  toggleProductList() {
    if(this.showProductList === false) {
      this.showProductList = true;
    } else {
      this.showProductList = false;
    }
  }
  
  getProductDetails(targetId:any) {  
    this.productList = [];
    let counterId = this.counterId; 
    const formSalesTarget = this.formSalesTargetEdit.value;
    let keyword = formSalesTarget.keywordSearch;
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": counterId,
        "target_id":targetId,
        "keyword":keyword
      };
      this.salesTargetService.getProductBySalesTargetId(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if (response.status.code === 200) {
          this.productList = response.results.products;
          this.rows =  this.productList;
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal Mendapatkan Daftar Product' }).then(t => t.present());
      });
    });
  }

  async changeTargetProduct(productData: any) {
    console.log(productData);
    const modal = await this.modalController.create({
      component: SalesTargetComponent,
      componentProps: {
        'productId': productData.product_id,
        'productName': productData.product_name,
        'productTarget': productData.target_product,
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      console.log(this.productList);
      console.log(modelData);
      let data = modelData.data;
      let checkProduct: any[] = this.productList.find(x => x.product_id === data[0].product_id);
      console.log(checkProduct);
      if (checkProduct !== undefined) {
        let index: number = this.productList.indexOf(checkProduct);
        console.log(index);
        this.productList[index]['target_product'] = data[0].target;
        this.rows[this.rows.indexOf(this.rows.find(x => x.product_id === data[0].product_id))]['target_product'] = data[0].target;
      }
      console.log(this.rows);
    });

    return await modal.present();
  }
  getChangeNominal(columnname:any){
    let arrform=<FormArray>this.formSalesTargetEdit.controls[columnname];    
    let cur= arrform.value;
    let curWithSeparator='0';
    if(cur!='0' && cur!='' && cur!=null){
      curWithSeparator = this.myService.separator(cur);
      curWithSeparator = String(curWithSeparator).replace(/[^0-9,]/g, '');
    }
    this.formSalesTargetEdit.controls[columnname].setValue(curWithSeparator);
  }

}
