import { Component, OnInit } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../../service/util.service';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportStockAuditrailComponent } from '../../../../component/advanced-filter/report-stock-auditrail/report-stock-auditrail.component';
import * as XLSX from 'xlsx';
import { ReportService } from '../../report.service';

class Product {
  public id: string;
  public product_name: string;
}

@Component({
  selector: 'app-report-stock-auditrail',
  templateUrl: './report-stock-auditrail.page.html',
  styleUrls: ['./report-stock-auditrail.page.scss'],
})
export class ReportStockAuditrailPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  productList: Product[];
  counterData: any[] = [];
  warehouseData: any[] = [];
  reportData: any[] = [];
  fileName= 'Report_Stock_Auditrail.xlsx';

  counterName: any = null;
  warehouseName: any = null;
  monthName: any = null;
  selectedProduct: any = null;
  
  paramBranchId: any;
  paramCounterId: any;
  paramYear: any = null;
  paramMonthId: any;
  paramProduct: any;
  paramWarehouseId: any;

  constructor(
    private storage: Storage,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private reportService: ReportService) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": counterId
          };
          this.reportService.getReportStockAuditrailIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.counterData = response.results.counter_data;
            this.productList = response.results.product_data;
            this.warehouseData = response.results.warehouse_data;
            this.warehouseData.unshift({
              'id': 0,
              'counter_id': 0,
              'warehouse_name': 'SEMUA GUDANG'
            });
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        });
      }
    });
  }

  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ReportStockAuditrailComponent,
      componentProps: {
        productList: this.productList,
        counterList: this.counterData,
        warehouseList: this.warehouseData,
        paramMonthId: this.paramMonthId,
        paramYear: this.paramYear,
        paramBranchId: this.paramBranchId,
        paramCounterId: this.paramCounterId,
        paramWarehouseId: this.paramWarehouseId,
        paramProduct: this.paramProduct
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data.length > 0) {
        this.paramBranchId = data[0].branchId;
        this.paramCounterId = data[0].counterId;
        this.paramMonthId = data[0].monthId;
        this.paramYear = data[0].year;
        this.counterName = data[0].counterName;
        this.monthName = data[0].monthName;
        this.paramProduct = data[0].productId;
        this.selectedProduct = this.paramProduct ? this.paramProduct.id + ' - ' + this.paramProduct.product_name : null;
        this.paramWarehouseId = data[0].warehouseId;
        this.warehouseName = data[0].warehouseName;

        this.generateReport();
      }
    });

    return await modal.present();
  }

  generateReport() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.paramCounterId,
        "warehouse_id": this.paramWarehouseId,
        "year": this.paramYear,
        "month": parseInt(this.paramMonthId) < 10 ? "0" + (parseInt(this.paramMonthId)).toString() : parseInt(this.paramMonthId),
        "product_id": this.paramProduct.id
      };

      this.reportService.getReportStockAuditrail(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          let getReports: any[] = response.report_data;
          let getStocks: any[] = response.stock_data;
          this.processReportData(getReports, getStocks);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  processReportData(getReports: any[], getStocks: any[]) {
    this.reportData = [];
    let tempWarehouseId: any;
    let sumInStock: number = 0;
    let sumOutStock: number = 0;
    let sumEndStock: number = 0;
    let lastSumData: any;
    if(getReports.length === 1) {
      let stock = getStocks.find(x => x.warehouse_id === getReports[0].warehouse_id);
      if(stock) {
        let data = {
          'transaction_id': '0',
          'sum_title': "SALDO AWAL",
          'stock_move': getReports[0].stock_move,
          'stock_mutation_type_name': getReports[0].stock_mutation_type_name,
          'trans_date': getReports[0].trans_date,
          'qty': getReports[0].qty,
          'warehouse_id': getReports[0].warehouse_id,
          'warehouse_name': getReports[0].warehouse_name,
          'early_stock': stock.early_stock,
          'sum_in_stock': 0,
          'sum_out_stock': 0,
          'sum_end_stock': stock.early_stock
        };

        this.reportData.push(data);

        sumInStock = getReports[0].stock_move === 'I' ? sumInStock + getReports[0].qty : sumInStock;
        sumOutStock = getReports[0].stock_move === 'O' ? sumOutStock + getReports[0].qty : sumOutStock;
        sumEndStock = getReports[0].stock_move === 'I' ? parseInt(stock.early_stock) + getReports[0].qty : parseInt(stock.early_stock) - getReports[0].qty;
        let data2 = {
          'transaction_id': getReports[0].transaction_id,
          'sum_title': "SALDO AWAL",
          'stock_move': getReports[0].stock_move,
          'stock_mutation_type_name': getReports[0].stock_mutation_type_name,
          'trans_date': getReports[0].trans_date,
          'qty': getReports[0].qty,
          'warehouse_id': getReports[0].warehouse_id,
          'warehouse_name': getReports[0].warehouse_name,
          'early_stock': stock.early_stock,
          'sum_in_stock': getReports[0].stock_move === 'I' ? getReports[0].qty : 0,
          'sum_out_stock': getReports[0].stock_move === 'O' ? getReports[0].qty : 0,
          'sum_end_stock': sumEndStock
        };

        this.reportData.push(data2);

        lastSumData = {
          'transaction_id': '0',
          'sum_title': "SALDO AKHIR",
          'stock_move': getReports[0].stock_move,
          'stock_mutation_type_name': getReports[0].stock_mutation_type_name,
          'trans_date': getReports[0].trans_date,
          'qty': getReports[0].qty,
          'warehouse_id': getReports[0].warehouse_id,
          'warehouse_name': getReports[0].warehouse_name,
          'early_stock': stock.early_stock,
          'sum_in_stock': sumInStock,
          'sum_out_stock': sumOutStock,
          'sum_end_stock': sumEndStock
        };

        this.reportData.push(lastSumData);
      }
    } else {
      for(let x = 0; x < getReports.length; x++) {
        let currentWarehouseId = getReports[x].warehouse_id;
        let stock = getStocks.find(x => x.warehouse_id === currentWarehouseId);
        if(stock) {
          if(x === 0) {
            tempWarehouseId = currentWarehouseId;
    
            let data = {
              'transaction_id': '0',
              'sum_title': "SALDO AWAL",
              'stock_move': getReports[x].stock_move,
              'stock_mutation_type_name': getReports[x].stock_mutation_type_name,
              'trans_date': getReports[x].trans_date,
              'qty': getReports[x].qty,
              'warehouse_id': getReports[x].warehouse_id,
              'warehouse_name': getReports[x].warehouse_name,
              'early_stock': stock.early_stock,
              'sum_in_stock': 0,
              'sum_out_stock': 0,
              'sum_end_stock': stock.early_stock
            };
    
            this.reportData.push(data);
    
            sumInStock = getReports[x].stock_move === 'I' ? sumInStock + getReports[x].qty : sumInStock;
            sumOutStock = getReports[x].stock_move === 'O' ? sumOutStock + getReports[x].qty : sumOutStock;
            sumEndStock = getReports[x].stock_move === 'I' ? parseInt(stock.early_stock) + getReports[x].qty : parseInt(stock.early_stock) - getReports[x].qty;
            let data2 = {
              'transaction_id': getReports[x].transaction_id,
              'sum_title': "SALDO AWAL",
              'stock_move': getReports[x].stock_move,
              'stock_mutation_type_name': getReports[x].stock_mutation_type_name,
              'trans_date': getReports[x].trans_date,
              'qty': getReports[x].qty,
              'warehouse_id': getReports[x].warehouse_id,
              'warehouse_name': getReports[x].warehouse_name,
              'early_stock': stock.early_stock,
              'sum_in_stock': getReports[x].stock_move === 'I' ? getReports[x].qty : 0,
              'sum_out_stock': getReports[x].stock_move === 'O' ? getReports[x].qty : 0,
              'sum_end_stock': sumEndStock
            };
    
            this.reportData.push(data2);
          } else {
            if(tempWarehouseId !== currentWarehouseId) {
              let dataEnd = {
                'transaction_id': '0',
                'sum_title': "SALDO AKHIR",
                'stock_move': getReports[x-1].stock_move,
                'stock_mutation_type_name': getReports[x-1].stock_mutation_type_name,
                'trans_date': getReports[x-1].trans_date,
                'qty': getReports[x-1].qty,
                'warehouse_id': getReports[x-1].warehouse_id,
                'warehouse_name': getReports[x-1].warehouse_name,
                'early_stock': stock.early_stock,
                'sum_in_stock': sumInStock,
                'sum_out_stock': sumOutStock,
                'sum_end_stock': sumEndStock
              };
    
              this.reportData.push(dataEnd);
    
              tempWarehouseId = currentWarehouseId;
              
              let dataStart = {
                'transaction_id': '0',
                'sum_title': "SALDO AWAL",
                'stock_move': getReports[x].stock_move,
                'stock_mutation_type_name': getReports[x].stock_mutation_type_name,
                'trans_date': getReports[x].trans_date,
                'qty': getReports[x].qty,
                'warehouse_id': getReports[x].warehouse_id,
                'warehouse_name': getReports[x].warehouse_name,
                'early_stock': stock.early_stock,
                'sum_in_stock': 0,
                'sum_out_stock': 0,
                'sum_end_stock': stock.early_stock
              };
  
              this.reportData.push(dataStart);
  
              sumInStock = 0;
              sumOutStock = 0;
              sumEndStock = 0;
  
              sumInStock = getReports[x].stock_move === 'I' ? sumInStock + getReports[x].qty : sumInStock;
              sumOutStock = getReports[x].stock_move === 'O' ? sumOutStock + getReports[x].qty : sumOutStock;
              sumEndStock = getReports[x].stock_move === 'I' ? parseInt(stock.early_stock) + getReports[x].qty : parseInt(stock.early_stock) - getReports[x].qty;
              let dataFirst = {
                'transaction_id': getReports[x].transaction_id,
                'sum_title': "SALDO AWAL",
                'stock_move': getReports[x].stock_move,
                'stock_mutation_type_name': getReports[x].stock_mutation_type_name,
                'trans_date': getReports[x].trans_date,
                'qty': getReports[x].qty,
                'warehouse_id': getReports[x].warehouse_id,
                'warehouse_name': getReports[x].warehouse_name,
                'early_stock': stock.early_stock,
                'sum_in_stock': getReports[x].stock_move === 'I' ? getReports[x].qty : 0,
                'sum_out_stock': getReports[x].stock_move === 'O' ? getReports[x].qty : 0,
                'sum_end_stock': sumEndStock
              };
  
              this.reportData.push(dataFirst);
            } else {
              sumInStock = getReports[x].stock_move === 'I' ? sumInStock + getReports[x].qty : sumInStock;
              sumOutStock = getReports[x].stock_move === 'O' ? sumOutStock + getReports[x].qty : sumOutStock;
              sumEndStock = getReports[x].stock_move === 'I' ? sumEndStock + getReports[x].qty : sumEndStock - getReports[x].qty;
              
              let data = {
                'transaction_id': getReports[x].transaction_id,
                'sum_title': "SALDO AWAL",
                'stock_move': getReports[x].stock_move,
                'stock_mutation_type_name': getReports[x].stock_mutation_type_name,
                'trans_date': getReports[x].trans_date,
                'qty': getReports[x].qty,
                'warehouse_id': getReports[x].warehouse_id,
                'warehouse_name': getReports[x].warehouse_name,
                'early_stock': stock.early_stock,
                'sum_in_stock': getReports[x].stock_move === 'I' ? getReports[x].qty : 0,
                'sum_out_stock': getReports[x].stock_move === 'O' ? getReports[x].qty : 0,
                'sum_end_stock': sumEndStock
              };
              
              this.reportData.push(data);
  
              if((x+1) === getReports.length) {
                lastSumData = {
                  'transaction_id': '0',
                  'sum_title': "SALDO AKHIR",
                  'stock_move': getReports[x].stock_move,
                  'stock_mutation_type_name': getReports[x].stock_mutation_type_name,
                  'trans_date': getReports[x].trans_date,
                  'qty': getReports[x].qty,
                  'warehouse_id': getReports[x].warehouse_id,
                  'warehouse_name': getReports[x].warehouse_name,
                  'early_stock': stock.early_stock,
                  'sum_in_stock': sumInStock,
                  'sum_out_stock': sumOutStock,
                  'sum_end_stock': sumEndStock
                };
  
                this.reportData.push(lastSumData);
              }
            }
          }
        }
      }
    }
  }

}
