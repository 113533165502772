import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';
import { PosService } from '../../pages/pos/pos.service';

@Component({
  selector: 'app-member-point',
  templateUrl: './member-point.component.html',
  styleUrls: ['./member-point.component.scss'],
})
export class MemberPointComponent implements OnInit {

  @Input() token: any;
  @Input() totalTransaction: any;
  memberParam: any;
  baseUrl: any;
  addPointForm: boolean = false;
  usedPointForm: boolean = false;
  setPointVal: boolean = false;
  btnFindMember: boolean = true;
  memberId: any;
  memberIdentityNumber: any;
  memberName: any;
  memberPhone: any;
  memberPoint: any = 0;
  memberPointUsed: any;
  memberPointforUseList: any[] = [];
  

  db: any;

  constructor(
    public modalController: ModalController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private posService: PosService,
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
    this.openDB();
  }

  openDB() {
    //this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  async dismissModal() {
    let close: string = '';
    let pointMutationType: any;

    if(this.usedPointForm === true) {
      if(this.memberPointUsed !== undefined) {
        if(this.setPointVal === true) {
          let value = parseInt(this.memberPointUsed) * 100;
          pointMutationType = 'O';
          close = this.memberId + '#' + this.memberPhone + '#' + this.memberPointUsed + '#' + value + '#' + pointMutationType;
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Silahkan klik tombol "Pakai Point" terlebih dahulu!' }).then(t => t.present());
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Anda belum memilih jumlah point!' }).then(t => t.present());
      }
    }
    else if(this.addPointForm === true) {
      if(this.memberId !== undefined && this.memberId !== '') {
        pointMutationType = 'I';
        close = this.memberId + '#' + this.memberPhone + '#' + 0 + '#' + 0 + '#' + pointMutationType;
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Anda belum memilih member!' }).then(t => t.present());
      }
    }
    else {
      //
    }

    await this.modalController.dismiss(close);
  }

  radioGroupChange(event: any) {
    let radioId = event.detail.value;
    if(radioId === '0') {
      this.usedPointForm = true;
      this.addPointForm = false;
    }
    else if(radioId === '1') {
      this.usedPointForm = false;
      this.addPointForm = true;
    }
    else {
      console.log('Error');
    }

    this.btnFindMember = false;
    this.clearField();
  }

  searchMember() {
    if(this.memberParam !== undefined && this.memberParam !== '') {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        
      let options = {
        "token": this.token,
        "phoneNumber": this.memberParam
      };
        this.posService.getMemberByPhone(options).subscribe(async (response) => {
          if(response.results) {
            console.log(response.results);
            if(response.results.length > 0) {
              this.utilService.loadingDismiss();
              this.memberId = response.results[0].id;
              this.memberIdentityNumber =response.results[0].identity_number;
              this.memberName = response.results[0].customer_name;
              this.memberPhone = response.results[0].phone;
              this.memberPoint = response.results[0].point_remains;
            }
            else {
              this.utilService.loadingDismiss();
              this.toastCtrl.create({ duration: 2000, message: 'Member tidak ditemukan!' }).then(t => t.present());
            }
           
            if(this.usedPointForm === true) {
              this.memberPointforUseList = [];

              if(this.totalTransaction !== 0) {
                let pointLimit: any = parseInt(this.totalTransaction) / 100;
                if(this.memberPoint !== 0) {
                  let point = 0;
                  let pointVal = 0;
                  let count: any = parseInt(this.memberPoint) / 100;
                  if(parseInt(count) !== 0) {
                    for(let x = 0; x <= parseInt(count); x++) {
                      if(point <= (parseInt(pointLimit) + 100)) {
                        let pointList = {
                          'point': point,
                          'point_value': pointVal
                        };
                        this.memberPointforUseList.push(pointList);
                        point = point + 100;
                        pointVal = pointVal + 10000;
                      }
                    }
                  } else {
                    let pointList = {
                      'point': 0,
                      'point_value': 0
                    }
  
                    this.memberPointforUseList.push(pointList);
                  }
                } else {
                  let pointList = {
                    'point': 0,
                    'point_value': 0
                  }

                  this.memberPointforUseList.push(pointList);
                }
              } else {
                let pointList = {
                  'point': 0,
                  'point_value': 0
                }

                this.memberPointforUseList.push(pointList);
              }
              this.memberParam = undefined;
            }
          } 
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mendapatkan data! Silahkan refresh!' }).then(t => t.present());
        });/*
      
        let selectQuery = 'SELECT * FROM customers WHERE phone = "' + this.memberParam + '"';
        this.db.transaction((tx) => {
          tx.executeSql(selectQuery, [],
          (tx, result) => {
            if(result.rows.length > 0) {
              this.utilService.loadingDismiss();
              this.memberId = result.rows[0].id;
              this.memberIdentityNumber = result.rows[0].identity_number;
              this.memberName = result.rows[0].customer_name;
              this.memberPhone = result.rows[0].phone;
              this.memberPoint = result.rows[0].point_remains;

              if(this.usedPointForm === true) {
                this.memberPointforUseList = [];

                if(this.totalTransaction !== 0) {
                  let pointLimit: any = parseInt(this.totalTransaction) / 100;
                  if(this.memberPoint !== 0) {
                    let point = 0;
                    let pointVal = 0;
                    let count: any = parseInt(this.memberPoint) / 100;
                    if(parseInt(count) !== 0) {
                      for(let x = 0; x <= parseInt(count); x++) {
                        if(point <= (parseInt(pointLimit) + 100)) {
                          let pointList = {
                            'point': point,
                            'point_value': pointVal
                          };
                          this.memberPointforUseList.push(pointList);
                          point = point + 100;
                          pointVal = pointVal + 10000;
                        }
                      }
                    } else {
                      let pointList = {
                        'point': 0,
                        'point_value': 0
                      }
    
                      this.memberPointforUseList.push(pointList);
                    }
                  } else {
                    let pointList = {
                      'point': 0,
                      'point_value': 0
                    }
  
                    this.memberPointforUseList.push(pointList);
                  }
                } else {
                  let pointList = {
                    'point': 0,
                    'point_value': 0
                  }

                  this.memberPointforUseList.push(pointList);
                }
              }
            } else {
              this.utilService.loadingDismiss();
              this.toastCtrl.create({ duration: 2000, message: 'Member tidak ditemukan!' }).then(t => t.present());
            }

            this.memberParam = undefined;
          }, (error) => {
            this.utilService.loadingDismiss();
            console.log(error);
          });
        });*/
      });
    } else {
      this.toastCtrl.create({ duration: 2000, message: 'Silahkan isi kolom pencarian terlebih dahulu!' }).then(t => t.present());
    }
  }

  clearField() {
    this.memberId = '';
    this.memberIdentityNumber = '';
    this.memberName = '';
    this.memberPhone = '';
    this.memberPoint = 0;
    this.memberParam = undefined;
    this.memberPointUsed = undefined;
    this.setPointVal = false;
  }

  setPointUsed() {
    if(this.memberPointUsed === undefined) {
      this.toastCtrl.create({ duration: 2000, message: 'Silahkan pilih jumlah point terlebih dahulu!' }).then(t => t.present());
    } else {
      if(this.setPointVal === false) {
        this.setPointVal = true;
        this.dismissModal();
      } else {
        this.setPointVal = false;
      }
    }
  }

}
