import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReceiptVoucherPage } from './receipt-voucher.page';

const routes: Routes = [
  {
    path: '',
    component: ReceiptVoucherPage
  },
  {
    path: 'receipt-voucher-create',
    loadChildren: () => import('./receipt-voucher-create/receipt-voucher-create.module').then( m => m.ReceiptVoucherCreatePageModule)
  },
  {
    path: 'receipt-voucher-edit',
    loadChildren: () => import('./receipt-voucher-edit/receipt-voucher-edit.module').then( m => m.ReceiptVoucherEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReceiptVoucherPageRoutingModule {}
