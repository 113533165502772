import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PosPromoOtoPageRoutingModule } from './pos-promo-oto-routing.module';

import { PosPromoOtoPage } from './pos-promo-oto.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PosPromoOtoPageRoutingModule
  ],
  declarations: [PosPromoOtoPage]
})
export class PosPromoOtoPageModule {}
