import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MasterProductGimmickCreatePageRoutingModule } from './master-product-gimmick-create-routing.module';

import { MasterProductGimmickCreatePage } from './master-product-gimmick-create.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    MasterProductGimmickCreatePageRoutingModule
  ],
  declarations: [MasterProductGimmickCreatePage]
})
export class MasterProductGimmickCreatePageModule {}
