import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  baseUrl: any;
  constructor(
    private http: HttpClient,
    private httpService: HttpService) { 
      this.baseUrl = environment.apiUrl;}
  
  userRegister(username: string, password: string,nik:string,fullname:string,email:string,whatsappNumber:string,counterId:string): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('nik', nik);
    formData.append('email', email);
    formData.append('username', username);
    formData.append('fullname', fullname);
    formData.append('whatsappNumber', whatsappNumber);
    formData.append('password', password);
    formData.append('counterId', counterId);
    return this.http.post(this.baseUrl + 'register-non-kpi', formData, { headers: reqOpts })
  }
  forgetPassword(identity: string): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('identity', identity);

    return this.http.post(this.baseUrl + 'forget-password', formData, { headers: reqOpts })
  }
  checkToken(token: string): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('token', token);

    return this.http.post(this.baseUrl + 'check-token-forget-password', formData, { headers: reqOpts })
  }
  resetPassword(password: string,userId:string): Observable<any> {
    const reqOpts = new HttpHeaders()
      .set('Accept', 'application/json'),
    formData = new FormData();
    formData.append('password', password);
    formData.append('userId', userId);

    return this.http.post(this.baseUrl + 'reset-password', formData, { headers: reqOpts })
  }
}
