import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PromotionsPage } from './promotions.page';

const routes: Routes = [
  {
    path: '',
    component: PromotionsPage
  },
  {
    path: 'promotion-create',
    loadChildren: () => import('./promotion-create/promotion-create.module').then( m => m.PromotionCreatePageModule)
  },
  {
    path: 'promotion-edit',
    loadChildren: () => import('./promotion-edit/promotion-edit.module').then( m => m.PromotionEditPageModule)
  },  {
    path: 'promotion-detail',
    loadChildren: () => import('./promotion-detail/promotion-detail.module').then( m => m.PromotionDetailPageModule)
  },
  {
    path: 'promotion-upload',
    loadChildren: () => import('./promotion-upload/promotion-upload.module').then( m => m.PromotionUploadPageModule)
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PromotionsPageRoutingModule {}
