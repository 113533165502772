import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StockOpnamePage } from './stock-opname.page';

const routes: Routes = [
  {
    path: '',
    component: StockOpnamePage
  },
  {
    path: 'stock-opname-create',
    loadChildren: () => import('./stock-opname-create/stock-opname-create.module').then( m => m.StockOpnameCreatePageModule)
  },
  {
    path: 'stock-opname-edit',
    loadChildren: () => import('./stock-opname-edit/stock-opname-edit.module').then( m => m.StockOpnameEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StockOpnamePageRoutingModule {}
