import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { PettyCashCategoryService } from '../petty-cash-category.service';
import { PettyCashCategory } from '../../../models/petty-cash-category.model';
import { UserProfile } from '../../../models/user-profile.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-petty-cash-category-edit',
  templateUrl: './petty-cash-category-edit.page.html',
  styleUrls: ['./petty-cash-category-edit.page.scss'],
})
export class PettyCashCategoryEditPage implements OnInit {

  activeList = [{id: '1', name: 'Active'},{id: '0', name: 'Non Active'}];
  formPettyCashCategoryEdit: FormGroup;

  userProfile: UserProfile = new UserProfile();
  token: any;
  pettyCashCategoryId: any;
  pettyCashCategoryData: any;
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private pettyCashCategoryService: PettyCashCategoryService,
    private activeRoute: ActivatedRoute,
    private userData: UserData,
    private router: Router
  ) {}

  ngOnInit() {
    this.buildFormPettyCashCategoryEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });

  }

  buildFormPettyCashCategoryEdit() {
    this.formPettyCashCategoryEdit = this.fb.group({
      nmKatPettyCash: [null, Validators.required],
      active: [0, Validators.required],
    })
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.pettyCashCategoryId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.pettyCashCategoryService.getPettyCashCategoryEdit(this.pettyCashCategoryId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.pettyCashCategoryData = response.results.data;

          if(this.pettyCashCategoryData) {
            this.formPettyCashCategoryEdit = this.fb.group({
              nmKatPettyCash: [this.pettyCashCategoryData.petty_cash_category_name, Validators.required],
              active: [this.pettyCashCategoryData.active, Validators.required]
            });
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updatePettyCashCategory() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const pettyCashCategoryForm = this.formPettyCashCategoryEdit.getRawValue();
      const pettyCashCategory = new PettyCashCategory();
      pettyCashCategory.petty_cash_category_name= pettyCashCategoryForm.nmKatPettyCash;
      pettyCashCategory.active= pettyCashCategoryForm.active;
      pettyCashCategory.updated_by = this.userProfile.username;

      this.pettyCashCategoryService.updatePettyCashCategory(this.pettyCashCategoryId, pettyCashCategory).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/petty-cash-category']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
