import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SalesTargetPage } from './sales-target.page';

const routes: Routes = [
  {
    path: '',
    component: SalesTargetPage
  },
  {
    path: 'sales-target-create',
    loadChildren: () => import('./sales-target-create/sales-target-create.module').then( m => m.SalesTargetCreatePageModule)
  },
  {
    path: 'sales-target-edit',
    loadChildren: () => import('./sales-target-edit/sales-target-edit.module').then( m => m.SalesTargetEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesTargetPageRoutingModule {}
