import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { UserProfile } from '../../../models/user-profile.model';
import { UtilService } from '../../../service/util.service';
import { PaymentVoucher } from '../../../models/payment-voucher.model';
import { PaymentVoucherService } from '../payment-voucher.service';
import { IonicSelectableComponent } from 'ionic-selectable';

class Coa {
  public id: string;
  public name: string;
}

@Component({
  selector: 'app-payment-voucher-create',
  templateUrl: './payment-voucher-create.page.html',
  styleUrls: ['./payment-voucher-create.page.scss'],
})
export class PaymentVoucherCreatePage implements OnInit {

  
  formPaymentVoucherCreate: FormGroup;
  token: any;
  userProfile: UserProfile = new UserProfile();
  kasBankList: any[] = [];
  counterList: any[] = [];
  currencyList: any[] = [];
  unitType: any[] = ['PIECES'];
  coaList: Coa[];

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private paymentVoucherService: PaymentVoucherService
  ) { }

  ngOnInit() {
    this.buildFormPaymentVoucherCreate();
    
    Promise.all([this.storage.get('user_token'),this.storage.get('user_profile')])
    .then(([token, profile]) => {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.paymentVoucherService.getDataCreatePaymentVoucher(this.userProfile.counter_id,{"token": this.token}).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.kasBankList = response.results.data_cash_bank;
            this.currencyList = response.results.data_currency;
            this.coaList = response.results.data_coa;
            this.counterList = response.results.data_counter;
            this.formPaymentVoucherCreate.patchValue({transDate: this.userProfile.counter_detail.trans_date});
          });
        });
    })
  }

  buildFormPaymentVoucherCreate() {
    this.formPaymentVoucherCreate = this.fb.group({
      transDate: [0, Validators.required],
      kasBank: [0, Validators.required],
      penerima: [null, Validators.required],
      noReferensi: [null, Validators.required],
      noBukti: [null, Validators.required],
      jumlah: [0, Validators.required],
      keterangan: [null, Validators.required],
      currency: [null, Validators.required],
      kurs: [null, Validators.required],
      detail: this.fb.array([])
    });
  }
  
  getKurs(e) {
    let currencyId = e.detail.value;
    let find = this.currencyList.find(x => x.id === currencyId);

    this.formPaymentVoucherCreate.patchValue({kurs: find.exchange_rate});
  }

  addDetail() {
    const detail = this.fb.group({
      counter_id: [null, Validators.required],
      coa_id: [null, Validators.required],
      amount: [0, Validators.required],
      desc: [null, Validators.required]
    });
    this.getDetailArray.push(detail);
  }

  get getDetailArray() {
    return (<FormArray>this.formPaymentVoucherCreate.get('detail'));
  }

  searchCoas(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    event.component.items = this.filterCoa(this.coaList, text);
    event.component.endSearch();
  }

  filterCoa(coas: Coa[], text: string) {
    return coas.filter(coa => {
      return coa.name.toLowerCase().indexOf(text) !== -1 || coa.id.toString().toLowerCase().indexOf(text) !== -1;
    });
  }

  deleteDetail(i: any) {
    this.getDetailArray.removeAt(i);
  }

  inputPaymentVoucher() {
    const paymentVoucherForm = this.formPaymentVoucherCreate.getRawValue();
    let dateConvert = this.utilService.convertDate(paymentVoucherForm.transDate);
    let newTransDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;

    const paymentVoucher = new PaymentVoucher;
    paymentVoucher.cash_bank_id =  paymentVoucherForm.kasBank;
    paymentVoucher.doc_date =  newTransDate;
    paymentVoucher.recipient =  paymentVoucherForm.penerima;
    paymentVoucher.no_referensi =  paymentVoucherForm.noReferensi;
    paymentVoucher.desc =  paymentVoucherForm.keterangan;
    paymentVoucher.currency =  paymentVoucherForm.currency;
    paymentVoucher.exchange_rate =  paymentVoucherForm.kurs;
    paymentVoucher.amount =  paymentVoucherForm.jumlah;
    paymentVoucher.payment_proof_no =  paymentVoucherForm.noBukti;
    paymentVoucher.created_by =  this.userProfile.username;

    let detail: any=[];
    paymentVoucherForm.detail.forEach( function(item, index) {
      detail.push({
        counter_id: item.counter_id,
        coa_id:  item.coa_id.id,
        amount:  item.amount,
        desc:  item.desc
      });
    });

    paymentVoucher.details = detail;
    this.paymentVoucherService.addPaymentVoucher(paymentVoucher).subscribe(response => {

    })
    
  }
}
