import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, ToastController, ModalController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { MasterProductPacketService } from './master-product-packet.service';
import { RoleAccess } from '../../models/role-access.model';
import * as XLSX from 'xlsx';
import { ProductPackageComponent } from '../../component/advanced-filter/product-package/product-package.component';
import { ShowMoreComponent } from '../../component/show-more/show-more.component';
import { environment } from '../../../environments/environment';
import AES = require('crypto-js/aes');
@Component({
  selector: 'app-master-product-packet',
  templateUrl: './master-product-packet.page.html',
  styleUrls: ['./master-product-packet.page.scss'],
})
export class MasterProductPacketPage implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  rows: any;
  productPackageList: any;
  filteredData: any;
  token: any;
  userProfile: UserProfile = new UserProfile();

  keywordSearch: any;
  roleAccess = new RoleAccess();
  dataHeader:any[]=[];
  productList:any[]=[];
  dataIsi:any[]=[];
  dataCounter:any[]=[];
  branchData: any[] = [];
  counterData: any[] = [];
  paramBranchId: any;
  paramCounterId: any='0';
  paramProductId:any='';
  paramIsiProductId:any='';
  paramStatus:number=2;
  branchName:string='';
  counterName:string='';
  productName:string='';
  isiProductName:string='';
  showBtnBranchList:boolean=false;
  showBtnCounterList:boolean=false;
  showBtnPaketList:boolean=false;
  showBtnIsiPaketList:boolean=false;
  statusName:string='';
  branchListForShow:any[]=[];
  counterListForShow: any[] = [];
  paketListForShow:any[] =[];
  isiPaketListForShow:any[] =[];
  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private packageService: MasterProductPacketService,
    private router: Router,
    private modalController:ModalController
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.paramCounterId=this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          this.pageCallback({ offset: this.page.offset });
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.dataIsi=[];
    this.dataCounter=[];
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "keywords": this.keywordSearch ? this.keywordSearch : 'null',
        'counterId':this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list,
        'status':this.paramStatus,
        'branch':this.paramBranchId,
        'counter':this.paramCounterId,
        'product':this.paramProductId,
        'isiProduct':this.paramIsiProductId
      };

      this.packageService.getProductPackage(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.page.count = response.results.data.total;
        this.rows = response.results.data.data;
        this.filteredData = response.results.data.data;
        this.productPackageList = response.results.data.data;
        this.productList = response.results.productData;
        this.branchData =response.results.branchData;
        this.counterData =response.results.counterData;
        this.dataHeader =response.results.filterData
        let sheetisi=0;
        let sheetcounter=0;
        if(this.dataHeader.length>0){
          for(let dh=0;dh<(this.dataHeader.length);dh++){
            let isi = this.dataHeader[dh]['isi'];
            if(isi.length>0){
              for(let i=0;i<isi.length;i++){
                this.dataIsi[sheetisi] = isi[i];
                sheetisi++;
              }
            }
            let counter = this.dataHeader[dh]['counter'];
            if(counter.length>0){
              for(let i=0;i<counter.length;i++){
                this.dataCounter[sheetcounter] = counter[i];
                sheetcounter++;
              }
            }
          }
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  clearFilters() {
    this.keywordSearch = undefined;
    this.paramBranchId='';
    this.branchName='';
    this.paramCounterId= this.paramCounterId=this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
    this.counterName='';
    this.paramStatus=2;
    this.statusName='';
    this.paramProductId='';
    this.paramIsiProductId='';
    this.productName='';
    this.isiProductName='';
    this.pageCallback({ offset: this.page.offset });
  }

  goToProductPackageCreate() {
    this.navCtrl.navigateForward(['/master-product-packet/master-product-packet-create']);
  }

  goToUpdatePage(id: any) {
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/master-product-packet/master-product-packet-edit'], navigationExtras);
  }
  

  async showCounterList() { 
    let tableHeader = ['Kode Outlet','Nama']
    let tableContent = [];
    if( this.counterListForShow.length>0){
      for(let i=0;i<this.counterListForShow.length;i++){
        let counter_name = this.counterListForShow[i].counter_name;
        let counter_code = this.counterListForShow[i].counter_code;
        tableContent.push([counter_code,counter_name]);
      }
    }
    const modal = await this.modalController.create({
      component: ShowMoreComponent,
      componentProps: {
        title:'List Counter',
        tableHeader:tableHeader,
        tableContent:tableContent
      },
      backdropDismiss: false
    });
    return await modal.present();
  }
  async showBranchList() {    
    let tableHeader = ['Nama']
    let tableContent = [];
    if( this.branchListForShow.length>0){
      for(let i=0;i<this.branchListForShow.length;i++){
        let branch_name =this.branchListForShow[i].branch_name;
        tableContent.push([branch_name]);
      }
    }
    const modal = await this.modalController.create({
      component: ShowMoreComponent,
      componentProps: {
        title:'List Branch',
        tableHeader:tableHeader,
        tableContent:tableContent
      },
      backdropDismiss: false
    });
    return await modal.present();
  }
  async showPaketList() {
    let tableHeader = ['Kode','Nama']
    let tableContent = [];
    if( this.paketListForShow.length>0){
      for(let i=0;i<this.paketListForShow.length;i++){
        let pcode =this.paketListForShow[i].id;
        let product_name =this.paketListForShow[i].product_name;
        tableContent.push([pcode,product_name]);
      }
    }
    const modal = await this.modalController.create({
      component: ShowMoreComponent,
      componentProps: {
        title:'List Produk',
        tableHeader:tableHeader,
        tableContent:tableContent
      },
      backdropDismiss: false
    });
    return await modal.present();
  }
  
  async showIsiPaketList() {
    let tableHeader = ['Kode','Nama']
    let tableContent = [];
    if( this.isiPaketListForShow.length>0){
      for(let i=0;i<this.isiPaketListForShow.length;i++){
        let pcode =this.isiPaketListForShow[i].id;
        let product_name =this.isiPaketListForShow[i].product_name;
        tableContent.push([pcode,product_name]);
      }
    }
    const modal = await this.modalController.create({
      component: ShowMoreComponent,
      componentProps: {
        title:'List Produk',
        tableHeader:tableHeader,
        tableContent:tableContent
      },
      backdropDismiss: false
    });
    return await modal.present();
  }
  goToCounterList(id: any) {
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/master-product-packet/package-counter'], navigationExtras);
  }
  


  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ProductPackageComponent,
      componentProps: {
        branchList: this.branchData,
        counterList: this.counterData,
        productList:this.productList,
        paramBranchId: this.paramBranchId,
        paramCounterId: this.paramCounterId,
        paramProductId: this.paramProductId,
        paramIsiProductId: this.paramIsiProductId,
        paramStatus : this.paramStatus
      },
      backdropDismiss: false
    });
    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;
      if (data !== 'null') {
        this.branchName='';
        this.counterName='';
        this.productName='';
        this.isiProductName='';
        this.statusName ='';
        this.paramProductId='';
        this.paramIsiProductId='';
        this.paramBranchId = data.branch;

        let splitBranch =[];
        this.branchListForShow=[];
        if(this.paramBranchId!=''){
          splitBranch = this.paramBranchId.split(',');
        }
        if(splitBranch.length>0){
          let loopBranch = 0;
          if(splitBranch.length > 5) {
            loopBranch = 5;
            this.showBtnBranchList = true;
          } else {
            loopBranch = splitBranch.length;
            this.showBtnBranchList = false;
          }
          for(let i=0;i<splitBranch.length;i++){
            let findBranch = this.branchData.find(x=>x.id==splitBranch[i]);
            this.branchListForShow.push(findBranch);
            if(i<loopBranch){
              this.branchName +=findBranch.branch_name+',';
            }
          }
        }
        this.paramCounterId = data.counter;
        let splitCounter =[];
        this.counterListForShow =[];
        if(this.paramCounterId!=''){
          splitCounter = this.paramCounterId.split(',');
        }
        if(splitCounter.length>0){
          let loopCounter = 0;
          if(splitCounter.length > 5) {
            loopCounter = 5;
            this.showBtnCounterList = true;
          } else {
            loopCounter = splitCounter.length;
            this.showBtnCounterList = false;
          }
          for(let i=0;i<splitCounter.length;i++){
            let findCounter = this.counterData.find(x=>x.id==splitCounter[i]);
            this.counterListForShow.push(findCounter);
            if(i<loopCounter){
              this.counterName +=findCounter.counter_name+',';
            }
          }
        }        
        let paramProduct = data.product;
        this.paketListForShow =[];
        if(paramProduct.length>0){          
          let loopPaket = 0;
          if(paramProduct.length > 5) {
            loopPaket = 5;
            this.showBtnPaketList = true;
          } else {
            loopPaket = paramProduct.length;
            this.showBtnPaketList = false;
          }
          for(let i=0;i<paramProduct.length;i++){
            let findProduct= this.productList.find(x=>x.id==paramProduct[i].id);
            if(findProduct){
              this.paramProductId+="'"+findProduct.id+"',";
              this.paketListForShow.push(findProduct);
              if(i<loopPaket){
                this.productName +=findProduct.product_name+',';
              }
            }
          }
          this.paramProductId = this.paramProductId.replace(/(^,)|(,$)/g, "");
        }   
        let paramIsiProduct = data.isiProduct;
        this.isiPaketListForShow =[];
        if(paramIsiProduct.length>0){                  
          let loopIsiPaket = 0;
          if(paramIsiProduct.length > 5) {
            loopIsiPaket = 5;
            this.showBtnIsiPaketList = true;
          } else {
            loopIsiPaket = paramIsiProduct.length;
            this.showBtnIsiPaketList = false;
          }
          for(let i=0;i<paramIsiProduct.length;i++){
            let findProduct= this.productList.find(x=>x.id==paramIsiProduct[i].id);
            if(findProduct){
              this.paramIsiProductId+="'"+findProduct.id+"',";
              this.isiPaketListForShow.push(findProduct);
              if(i<loopIsiPaket){
                this.isiProductName +=findProduct.product_name+',';
              }
            }
          }
          this.paramIsiProductId = this.paramIsiProductId.replace(/(^,)|(,$)/g, "");
        }
        this.paramStatus=data.status;
        if(data.status!=2){
          this.statusName = this.paramStatus==1?'Active':'Not Active'; 
        } 
        this.reloadTable();
      }
    });
    return await modal.present();
  }
  
  exportToExcel() {
    /* table id is passed over here */   
    let element = document.getElementById('table_package_header');
    let isi = document.getElementById('table_isi_package');
    let counter = document.getElementById('table_counter_package');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wsIsi: XLSX.WorkSheet = XLSX.utils.table_to_sheet(isi);
    const wsCounter: XLSX.WorkSheet = XLSX.utils.table_to_sheet(counter);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Paket');
    XLSX.utils.book_append_sheet(wb, wsIsi, 'Isi Paket');
    XLSX.utils.book_append_sheet(wb, wsCounter, 'Counter');

    /* save to file */
    XLSX.writeFile(wb, 'List Package.xlsx');
  }

}
