

import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';

import { ReportService } from '../../pages/report/report.service';
import { IonicSelectableComponent } from 'ionic-selectable';
@Component({
  selector: 'app-report-counter',
  templateUrl: './report-counter.component.html',
  styleUrls: ['./report-counter.component.scss'],
})
export class ReportCounterComponent implements OnInit {

  @Input() periodStart:any;
  @Input() rekapPer: any;
  @Input() selectedBranchList:any;
  @Input() counterList: any[];
  @Input() productList: any[];
  @Input() counterShape: any;
  @Input() counterLocation: any;
  @ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;
  @ViewChild('branchComponent', { static: false }) branchComponent: IonicSelectableComponent;
  
  @ViewChild('rekapPerComponent', { static: false }) rekapPerComponent: IonicSelectableComponent;
  @ViewChild('counterShapeComponent', { static: false }) counterShapeComponent: IonicSelectableComponent;
  @ViewChild('counterLocationComponent', { static: false }) counterLocationComponent: IonicSelectableComponent;
  
  
  
  counterShapeList:any[]=[];
  locationList:any[]=[];
  filterSDM: boolean = false;
  filterBrand1: boolean = false;
  filterSubBrand1: boolean = false;
  filterType1: boolean = false;
  filterSubType1: boolean = false;
  filterCategory1: boolean = false;
  filterSubCategory1: boolean = false;
  branchList:any[]=[];

  db: any;

  branchId:any;
  showCounterCheckBox: boolean = false;
  checkedAll : boolean = true;
  countChecked:number=0;
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterData: any[] = [];
  branchData: any[] = [];
  counterData2: any[] = [];
  branchData2: any[] = [];
  counterByLogin : any;

  selectedRekapPer1:any ='';
  selectedCounterShapes:any='';
  selectedCounterLocations:any='';
  selectedCounters: any[];
  selectedBranchs:any[];
  rekapPerList:any[];

  //untuk di return
  counterId:any='';
  periodEnd: any='';
  cashierTypeId:any='';

  constructor(
    private storage: Storage,
    private reportService: ReportService,
    public modalController: ModalController,
    public toastCtrl : ToastController,
    private utilService: UtilService
  ) {
  }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.rekapPerList = [
          {'name':'COH SDM','id':1},
          {'name':'COH by Luas Area','id':2},
          {'name':'COH By Operasional','id':3},
          {'name':'COH By Konsi-non','id':4},
          {'name':'COH By Lokasi','id':5}
        ];
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": this.counterByLogin
          };
          this.reportService.indexReportCounter(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            
            this.filter1();
            this.branchList = response.results.branch_data;
            this.counterList = response.results.counter_data;
            this.counterShapeList = response.results.counter_shape_data;
            this.locationList = response.results.counter_location_data;
           
            let selectedRekapPer: any = this.rekapPerList.find(x => x.id ==  this.rekapPer);
            if(selectedRekapPer){
              let idx =this.rekapPerList.indexOf(selectedRekapPer);
              this.selectedRekapPer1=(this.rekapPerList[idx]);        
            } 
            
            let branchIdSplit = this.selectedBranchList !== '' ? String(this.selectedBranchList).split(',') : [];
            this.selectedBranchs=[];
            for(let i= 0; i < this.branchList.length; i++) {        
              let selectedBranch: any = this.branchList.find(x => x.id ==  branchIdSplit[i]);
              if(selectedBranch){
                let idx =this.branchList.indexOf(selectedBranch);
                this.selectedBranchs.push(this.branchList[idx]);    
              }
            }
            let counterLocationShape = this.counterLocation !== '' ? String(this.counterLocation).split(',') : [];
            this.selectedCounterLocations=[];
            for(let i= 0; i < this.locationList.length; i++) {        
              let selectedCounterLocation: any = this.locationList.find(x => x.id ==  counterLocationShape[i]);
              if(selectedCounterLocation){
                let idx =this.locationList.indexOf(selectedCounterLocation);
                this.selectedCounterLocations.push(this.locationList[idx]);            
              }
            }
            
            let counterShapeSplit = this.counterShape !== '' ? String(this.counterShape).split(',') : [];
            this.selectedCounterShapes=[];
            for(let i= 0; i < this.counterShapeList.length; i++) {        
              let selectedCounterShape: any = this.counterShapeList.find(x => x.id ==  counterShapeSplit[i]);
              if(selectedCounterShape){
                let idx =this.counterShapeList.indexOf(selectedCounterShape);
                this.selectedCounterShapes.push(this.counterShapeList[idx]);            
              }
            }

          }, () => {});
        });
      }
    })
  }
  filter1(){
    this.filterSDM = false;
    this.filterBrand1 = false;
    this.filterSubBrand1 = false;
    this.filterType1 = false;
    this.filterSubType1 = false;
    if(this.rekapPer=='1'){
      this.filterSDM = true;
    }
    else if(this.rekapPer=='2'){
      this.filterBrand1 = true;
    }
    else if(this.rekapPer=='3'){
      this.filterSubBrand1 = true;
    }
    else if(this.rekapPer=='4'){
      this.filterCategory1 = true;
    }
    else if(this.rekapPer=='5'){
      this.filterSubCategory1 = true;
    }
  }

 
  async dismissModal(code: any) {
    let dataReturn: any;
    let error = 0;
    if(code === 'generate') {
      dataReturn = [];
      let selectedCounter: any = this.rekapPerList.find(x => x.id ==  this.rekapPer);
      let rekapPerName ='';
      if(selectedCounter){
        let idx =this.rekapPerList.indexOf(selectedCounter);
        rekapPerName = this.rekapPerList[idx]['name'];
      }

      let arrCounterShape=  [];      
      let counterShapeName ='';
      arrCounterShape= this.counterShape.split(',');
        
      if(arrCounterShape.length>0){
        for(let i=0;i<arrCounterShape.length;i++){
          let filterCounterShape= this.counterShapeList.find(x => x.id == arrCounterShape[i]);
          if(filterCounterShape){
            counterShapeName += filterCounterShape.counter_shape+',';
          }    
        }
      }

      let arrBranch=  [];      
      let branchName ='';
      arrBranch= this.selectedBranchList.split(',');
        
      if(arrBranch.length>0){
        for(let i=0;i<arrBranch.length;i++){
          let filterBranch= this.branchList.find(x => x.id == arrBranch[i]);
          if(filterBranch){
            branchName += filterBranch.branch_name+',';
          }    
        }
      }

      let arrLocation=  [];      
      let locationName ='';
      arrLocation= this.counterLocation.split(',');
        
      if(arrLocation.length>0){
        for(let i=0;i<arrLocation.length;i++){
          let filterLocation= this.locationList.find(x => x.id == arrLocation[i]);
          if(filterLocation){
            locationName += filterLocation.location_name+',';
          }    
        }
      }

      let filterData = {
        rekapPer:this.rekapPer,
        rekapPerName:rekapPerName,
        counterShape:this.counterShape,
        counterShapeName:counterShapeName,
        selectedBranchList:this.selectedBranchList,
        selectedBranchListName:branchName,
        counterLocation:this.counterLocation,
        counterLocationName:locationName
      };
  
      dataReturn.push(filterData);
      if(this.rekapPer==''){
        this.toastCtrl.create({ duration: 2000, message: 'Mohon Pilih Report By ' }).then(t => t.present());
        error++;
      }      
    }
    if(code === 'close') {
      dataReturn = 'null';
    }
    if(error==0){
      await this.modalController.dismiss(dataReturn);
    }
  }
  
  toggleItemsBranch() {
    this.branchComponent.toggleItems(
      this.branchComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmBranch() {
    this.branchComponent.confirm();
    this.branchComponent.close();
    
    let idBranch = '';
    for(let i= 0; i < this.branchComponent.value.length; i++) {
      idBranch += this.branchComponent.value[i]['id']+',';
    }
    this.selectedBranchList = idBranch.replace(/(^,)|(,$)/g, "");

  }

  
  confirmRekapPer() {
    this.rekapPerComponent.confirm();
    this.rekapPerComponent.close();
    this.rekapPer = this.rekapPerComponent.value.id;
    this.filter1();
  }
  
  
  toggleItemCounterShape() {
    this.counterShapeComponent.toggleItems(
      this.counterShapeComponent.itemsToConfirm.length ? false : true
    );
  }
  confirmCounterShape() {
    this.counterShapeComponent.confirm();
    this.counterShapeComponent.close();
    let idCounterShape = '';
    for(let i= 0; i < this.counterShapeComponent.value.length; i++) {
      idCounterShape += this.counterShapeComponent.value[i]['id']+',';
    }
    this.counterShape = idCounterShape.replace(/(^,)|(,$)/g, "");
  }
  toggleItemLocation() {
    this.counterLocationComponent.toggleItems(
      this.counterLocationComponent.itemsToConfirm.length ? false : true
    );
  }
  confirmLocation() {
    this.counterLocationComponent.confirm();
    this.counterLocationComponent.close();
    let idLocation = '';
    for(let i= 0; i < this.counterLocationComponent.value.length; i++) {
      idLocation += this.counterLocationComponent.value[i]['id']+',';
    }
    this.counterLocation = idLocation.replace(/(^,)|(,$)/g, "");
    console.log(this.counterLocation);
  }  
  
}

