export class MstWarehouse {

    id: number;
    counter_id: number;
    warehouse_name: string;
    warehouse_type_id: number;
    unit_size: string;
    length_size: number;
    width_size: number;
    height_size: number;
    active: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.counter_id = model.counter_id || 0;
        this.warehouse_name = model.warehouse_name || null;
        this.warehouse_type_id = model.warehouse_type_id || 0;
        this.unit_size = model.unit_size || null;
        this.length_size = model.length_size || 0;
        this.width_size = model.width_size || 0;
        this.height_size = model.height_size || 0;
        this.active = model.active || '1';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}