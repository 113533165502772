import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ClaimInsentifSummaryPageRoutingModule } from './claim-insentif-summary-routing.module';

import { ClaimInsentifSummaryPage } from './claim-insentif-summary.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ClaimInsentifSummaryPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [ClaimInsentifSummaryPage]
})
export class ClaimInsentifSummaryPageModule {}
