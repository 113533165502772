import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { Storage } from '@ionic/storage';
import { PurposeService } from '../purpose.service';
import { MstPurpose } from '../../../models/mst-purpose.model';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-purpose-edit',
  templateUrl: './purpose-edit.page.html',
  styleUrls: ['./purpose-edit.page.scss'],
})
export class PurposeEditPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  formPurposeEdit: FormGroup;
  purposeId: any;
  purposeData: any;
  statusPurpose: any[] = ['Not Active', 'Active'];
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private purposeService: PurposeService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormPurposeEdit();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormPurposeEdit() {
    this.formPurposeEdit = this.fb.group({
      purposeName: [],
      activeStatus: []
    });
  }

  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.purposeId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.purposeService.getPurposeforEdit(this.purposeId, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.purposeData = response.results;

          if(this.purposeData) {
            this.formPurposeEdit = this.fb.group({
              purposeName: [this.purposeData.purpose_name, Validators.required],
              activeStatus: [parseInt(this.purposeData.status), Validators.required]
            });
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  updatePurpose() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const purposeForm = this.formPurposeEdit.getRawValue();
      const mstPurpose = new MstPurpose();
      mstPurpose.purpose_name = purposeForm.purposeName;
      mstPurpose.status = purposeForm.activeStatus;
      mstPurpose.updated_by = this.userProfile.username;

      this.purposeService.updatePurpose(this.purposeId, mstPurpose).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/purpose']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
