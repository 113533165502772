import { OtherReceiving, OtherReceivingDetail } from './other-receiving.model';
import { ApprovalHistory } from './approval-history.model';

export class OtherReceivingBundle {

    otherReceiving: OtherReceiving;
    otherReceivingDetail: OtherReceivingDetail;
    approvalHistory: ApprovalHistory;

    constructor(model?) {
        model = model || {};
        this.otherReceiving = model.otherReceiving || new OtherReceiving();
        this.otherReceivingDetail = model.otherReceivingDetail || new OtherReceivingDetail();
        this.approvalHistory = model.approvalHistory || new ApprovalHistory();
    }
}