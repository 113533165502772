export class MstProduct {

    id: string;
    product_brand_id: string;
    product_sub_brand_id: string;
    product_type_id: string;
    product_sub_type_id: string;
    product_category_id: string;
    product_sub_category_id: string;
    product_marketing_type_id: number;
    barcode: string;
    product_name: string;
    initial_name: string;
    unit: string;
    cogs: number;
    price: number;
    mrp: number;
    percent_vat: number;
    include_tax: string;
    min_stock: number;
    max_stock: number;
    unit_size: string;
    length_size: number;
    width_size: number;
    height_size: number;
    active: string;
    listing_status: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || null;
        this.product_brand_id = model.product_brand_id || null;
        this.product_sub_brand_id = model.product_sub_brand_id || null;
        this.product_type_id = model.product_type_id || null;
        this.product_sub_type_id = model.product_sub_type_id || null;
        this.product_category_id = model.product_category_id || null;
        this.product_sub_category_id = model.product_sub_category_id || null;
        this.product_marketing_type_id = model.product_marketing_type_id || 0;
        this.barcode = model.barcode || null;
        this.product_name = model.product_name || null;
        this.initial_name = model.initial_name || null;
        this.unit = model.unit || "PCS";
        this.cogs = model.cogs || 0;
        this.price = model.price || 0;
        this.mrp = model.mrp || 0;
        this.percent_vat = model.percent_vat || 0;
        this.include_tax = model.include_tax || '1';
        this.min_stock = model.min_stock || 0;
        this.max_stock = model.max_stock || 0;
        this.unit_size = model.unit_size || "CM";
        this.length_size = model.length_size || 0;
        this.width_size = model.width_size || 0;
        this.height_size = model.height_size || 0;
        this.active = model.active || '1';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
        this.listing_status = model.listing_status || '0';
    }
}