import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';
import { PaymentVoucher } from '../../models/payment-voucher.model';


@Injectable({
  providedIn: 'root'
})
export class PaymentVoucherService {

  constructor(private httpService: HttpService) { }

  getPaymentVoucher(params: any): Observable<any> {
    return this.httpService.get('payment-voucher', params);
  }

  getDataCreatePaymentVoucher(counterId: any, params: any): Observable<any> {
    return this.httpService.get(`payment-voucher/create/${counterId}`, params);
  }

  addPaymentVoucher(data: PaymentVoucher): Observable<any> {
    return this.httpService.post(`payment-voucher/store`, data);
  }

}
