import { OtherExpences, OtherExpencesDetail } from './other-expences.model';
import { ApprovalHistory } from './approval-history.model';

export class OtherExpencesBundle {

    otherExpences: OtherExpences;
    otherExpencesDetail: OtherExpencesDetail;
    approvalHistory: ApprovalHistory;

    constructor(model?) {
        model = model || {};
        this.otherExpences = model.otherExpences || new OtherExpences();
        this.otherExpencesDetail = model.otherExpencesDetail || new OtherExpencesDetail();
        this.approvalHistory = model.approvalHistory || new ApprovalHistory();
    }
}