export class Role {

    id: number;
    role_name: string;
    all_access: string;
    active: string;
    created_by: string;
    updated_by: string;

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.role_name = model.role_name || null;
        this.all_access = model.all_access || '0';
        this.active = model.active || '0';
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}