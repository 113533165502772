import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { ClosedDayService } from './closed-day.service';
import { MstCounter } from '../../models/mst-counter.model';
import { RecalculateStockService } from '../recalculate-stock/recalculate-stock.service';

@Component({
  selector: 'app-closed-day',
  templateUrl: './closed-day.page.html',
  styleUrls: ['./closed-day.page.scss'],
})
export class ClosedDayPage implements OnInit {

  token: any;
  formClosedDay: FormGroup;
  userProfile: UserProfile = new UserProfile();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private closedDayService: ClosedDayService,
    private recalculateStockService:RecalculateStockService) { }

  ngOnInit() {
    this.buildFormClosedDay();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        if(this.userProfile.counter_detail) {
          this.formClosedDay = this.fb.group({
            transDate: [{value: this.userProfile.counter_detail.trans_date, disabled: true}]
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'User ini belum dipasangkan dengan Kode Counter !' }).then(t => t.present());
        }  
      }
    });
  }

  buildFormClosedDay() {
    this.formClosedDay = this.fb.group({
      transDate: [{value: null, disabled: true}]
    });
  }

  processClosedDay() {
    const formClosedDay = this.formClosedDay.value;
    let transDateConvert = this.utilService.convertDate(formClosedDay.transDate);
    let transDate = transDateConvert.years + '-' + transDateConvert.months + '-' + transDateConvert.dates;

    let nextDate = new Date(transDate);
    nextDate.setDate(nextDate.getDate() + 1);

    let nextDateConvert = this.utilService.convertDate(nextDate);
    let newNextDate = nextDateConvert.years + '-' + nextDateConvert.months + '-' + nextDateConvert.dates;

    let counterId: any = this.userProfile.counter_id_pos ? this.userProfile.counter_id_pos : null;
    if(counterId !== null) {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        //hitung ulang stok dulu
        let options = {
          counter_id: counterId,
        };
        this.closedDayService.listWarehouse(options).subscribe(async (response_wh)=>{
          if(response_wh.results.length>0){
            let recalculate =0;//jumlah berhasil recalculate hari

            for(let wh=0;wh<response_wh.results.length;wh++){
              let warehouse = response_wh.results[wh].id;
              let body = {
                warehouse_id: warehouse,
                year: transDateConvert.years,
                month: transDateConvert.months
              };
              await this.recalculateStockService.recalculateStock(body).subscribe(async (response) => {
                if(response.status.code === 200) {
                  recalculate++;
                  console.log('wh '+warehouse+' Berhasil recalculate');
                  console.log('wh yang Berhasil '+recalculate+' dari '+ response_wh.results.length);
                  //jika ini yg terakhir maka lanjut, ke update tgl counter
                  console.log(wh+','+response_wh.results.length);
                  if(wh==response_wh.results.length-1){
                    this.nextDay(counterId,transDateConvert.months,transDateConvert.years,nextDateConvert.months,nextDateConvert.years,newNextDate);
                    this.utilService.loadingDismiss();
                  }
                } else {
                  this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
                }
              }, (err) => {
                this.utilService.loadingDismiss();
                this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
              });
            }
          }
        })
      //selesai
      })
    } else {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'User ini belum dipasangkan dengan Kode Counter !' }).then(t => t.present());
    }
  }
  
  nextDay(counterId:any,currentMonth:any,currentYear:any,nextMonth:any,nextYear:any,tommorow:any){
    if(currentMonth !== nextMonth) {
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let options: any = {  
          'year': nextYear,
          'month': nextMonth,
          'year_before': currentYear,
          'month_before':currentMonth,
          'counter_id': counterId
        };
        this.closedDayService.processClosedDay(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          if(response.status.code === 200) {
            let periodNow = new Date(this.userProfile.counter_detail.stock_period);
            let periodNext = new Date(periodNow.getFullYear(), periodNow.getMonth() + 2, 0);
            let periodNextConvert = this.utilService.convertDate(periodNext);
            let newPeriodNext = periodNextConvert.years + '-' + periodNextConvert.months + '-' + periodNextConvert.dates;
            this.updateTransDate(counterId, tommorow, newPeriodNext);
          } else {
            this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      })
    } else {
      this.updateTransDate(counterId, tommorow, this.userProfile.counter_detail.stock_period);
    }
  }

  updateTransDate(counterId: any, newTransDate: any, newStockPeriod: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const mstCounter = new MstCounter();
      mstCounter.branch_id = this.userProfile.counter_detail.branch_id;
      mstCounter.counter_code = this.userProfile.counter_detail.counter_code;
      mstCounter.counter_name = this.userProfile.counter_detail.counter_name;
      mstCounter.trans_date = newTransDate;
      mstCounter.stock_period = newStockPeriod;
      mstCounter.first_address = this.userProfile.counter_detail.first_address;
      mstCounter.last_address = this.userProfile.counter_detail.last_address;
      mstCounter.phone = this.userProfile.counter_detail.phone;
      mstCounter.footer_text = this.userProfile.counter_detail.footer_text;
      mstCounter.timezone = this.userProfile.counter_detail.timezone;
      mstCounter.active = this.userProfile.counter_detail.active;
      mstCounter.updated_by = this.userProfile.username;

      this.closedDayService.updateCounter(counterId, mstCounter).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.userProfile.counter_detail = response.results.data;
          this.storage.set('user_profile', this.userProfile);
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  checkTodayAndTransDate() {
    const formClosedDay = this.formClosedDay.value;
    let nextDateConvert = this.utilService.convertDate(new Date().setDate(new Date().getDate() + 1));
    let nextDate = nextDateConvert.years + '-' + nextDateConvert.months + '-' + nextDateConvert.dates;
    
    return nextDate === formClosedDay.transDate ? true : false;
  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Proses tutup hari berhasil!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.formClosedDay = this.fb.group({
              transDate: [{value: this.userProfile.counter_detail.trans_date, disabled: true}]
            });
          }
        }
      ]
    });

    await alert.present();
  }

}
