import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RewardEndPointPageRoutingModule } from './reward-end-point-routing.module';

import { RewardEndPointPage } from './reward-end-point.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RewardEndPointPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [RewardEndPointPage]
})
export class RewardEndPointPageModule {}
