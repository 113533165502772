import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RolePage } from './role.page';

const routes: Routes = [
  {
    path: '',
    component: RolePage
  },
  {
    path: 'role-create',
    loadChildren: () => import('./role-create/role-create.module').then( m => m.RoleCreatePageModule)
  },
  {
    path: 'role-edit',
    loadChildren: () => import('./role-edit/role-edit.module').then( m => m.RoleEditPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RolePageRoutingModule {}
