import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';
import { MstVoucher } from '../../models/mst-voucher.model';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(private httpService: HttpService) { }

  getVoucher(params: any): Observable<any> {
    return this.httpService.get('voucher', params);
  }

  getVoucherforCreate(params: any): Observable<any> {
    return this.httpService.get('voucher/create', params);
  }

  getVoucherforEdit(batchNo: any, params: any): Observable<any> {
    return this.httpService.get(`voucher/${batchNo}/edit`, params);
  }

  getGenerateVoucher(params: any): Observable<any> {
    return this.httpService.get('voucher-generate', params);
  }

  addVoucher(data: MstVoucher): Observable<any> {
    return this.httpService.post('voucher', data);
  }

  updateVoucher(batchNo: any, data: MstVoucher): Observable<any> {
    return this.httpService.put(`voucher/${batchNo}`, data);
  }

  deleteVoucher(batch: any): Observable<any> {
    return this.httpService.delete(`voucher/${batch}`);
  }
}
