import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { ModalKasirService } from '../modal-kasir.service';
import { UserProfile } from '../../../models/user-profile.model';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ModalKasir } from '../../../models/modal-kasir.model';
import { UserData } from '../../../providers/user-data';
import { RoleAccess } from '../../../models/role-access.model';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-modal-kasir-edit',
  templateUrl: './modal-kasir-edit.page.html',
  styleUrls: ['./modal-kasir-edit.page.scss'],
})
export class ModalKasirEditPage implements OnInit {

  token: any;
  modalKasirId: any;
  modalKasirData: any;
  formModalKasirEdit: FormGroup;
  
  userProfile: UserProfile = new UserProfile();
  userKasirList: any[] = [];
  roleAccess = new RoleAccess();
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private utilService: UtilService,
    private modalKasirService: ModalKasirService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private alertController: AlertController,
    private userData: UserData,
    private router: Router

  ) {
    this.activatedRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.modalKasirId = AES.decrypt(snapshot.i, myKey).toString(utf8);
    })  
  }

  ngOnInit() {
      this.buildFormModalKasirEdit();
      Promise.all([
        this.storage.get('user_token'),
        this.storage.get('user_profile'),
        this.storage.get('user_menu_access')
      ])
      .then(([token, profile, access]) => {
        if(token) {
          this.token = token;
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.update!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          else{
            this.getData();
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
          this.navCtrl.navigateForward(['/login']);
        }
      });
  }

  buildFormModalKasirEdit() {
    this.formModalKasirEdit = this.fb.group({
      transDate: [],
      userKasir: [],
      nilaiModal: [],
      keterangan: []
    });
  }

  getData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.modalKasirService.getModalKasirforEdit(this.modalKasirId,this.userProfile.counter_id,{ "token": this.token }).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.userKasirList = response.results.user_kasir_data;
        this.modalKasirData = response.results.data;
        this.formModalKasirEdit = this.fb.group({
          transDate: [this.modalKasirData.transaction_date, Validators.required],
          userKasir: [this.modalKasirData.user_id, Validators.required],
          nilaiModal: [this.modalKasirData.amount, Validators.required],
          keterangan: [this.modalKasirData.description, Validators.required]
        })
      },()=> {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });

    })
  }

  updateData() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const modalKasirForm = this.formModalKasirEdit.getRawValue();
      let dateConvert = this.utilService.convertDate(modalKasirForm.transDate);
      let newTransDate = dateConvert.years + '-' + dateConvert.months + '-' + dateConvert.dates;
      
      const modalKasir = new ModalKasir();

      modalKasir.transaction_date = newTransDate;
      modalKasir.user_id = modalKasirForm.userKasir;
      modalKasir.amount = modalKasirForm.nilaiModal;
      modalKasir.description = modalKasirForm.keterangan;
      modalKasir.updated_by = this.userProfile.username;

      this.modalKasirService.updateModalKasir(this.modalKasirId, modalKasir).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmUpdate();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      },() => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    })

  }

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/modal-kasir']);;
          }
        }
      ]
    });

    await alert.present();
  }
}
