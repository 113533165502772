import { Component, OnInit } from '@angular/core';
import { ToastController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../../service/util.service';
import { UserProfile } from '../../../../models/user-profile.model';
import { ReportProductReceivingComponent } from '../../../../component/advanced-filter/report-product-receiving/report-product-receiving.component';
import { ReportProductReceivingDetailComponent } from '../../../../component/report-product-receiving-detail/report-product-receiving-detail.component';
import * as XLSX from 'xlsx';
import { ReportService } from '../../report.service';
import { ProductReceivingStatus } from '../../../../enum/ProductReceivingStatus';

@Component({
  selector: 'app-report-product-receiving',
  templateUrl: './report-product-receiving.page.html',
  styleUrls: ['./report-product-receiving.page.scss'],
})
export class ReportProductReceivingPage implements OnInit {

  token: any;
  userProfile: UserProfile = new UserProfile();
  counterData: any[] = [];
  warehouseData: any[] = [];
  counterName: any = null;
  warehouseName: any = null;
  fileName= 'Report_Product_Receiving.xlsx';

  paramProductReceivingId: any = null;
  paramProductRequestId: any = null;
  paramBranchId: any;
  paramCounterId: any;
  paramWarehouseId: any;
  paramDocDateStart: any = null;
  paramDocDateEnd: any = null;

  reportData: any[] = [];
  reportDataDetail: any[] = [];

  receivingStatus = ProductReceivingStatus;
  receivingStatusList = Object.keys(ProductReceivingStatus).filter(
    receivingStatus => typeof this.receivingStatus[receivingStatus] === 'number'
  );

  constructor(
    private storage: Storage,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private modalController: ModalController,
    private reportService: ReportService) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          let counterId = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
          let options = { 
            "token": this.token,
            "counter_id": counterId
          };
          this.reportService.getReportProductReceivingIndex(options).subscribe((response) => {
            this.utilService.loadingDismiss();
            this.counterData = response.results.counter_data;
            this.warehouseData = response.results.warehouse_data;
            this.warehouseData.unshift({
              'id': 0,
              'counter_id': 0,
              'warehouse_name': 'SEMUA GUDANG'
            });
          }, () => {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
          });
        });
      }
    });
  }

  async openAdvancedFilters() {
    const modal = await this.modalController.create({
      component: ReportProductReceivingComponent,
      componentProps: {
        counterList: this.counterData,
        warehouseList: this.warehouseData,
        paramProductReceivingId: this.paramProductReceivingId,
        paramProductRequestId: this.paramProductRequestId,
        paramBranchId: this.paramBranchId,
        paramCounterId: this.paramCounterId,
        paramWarehouseId: this.paramWarehouseId,
        paramDocDateStart: this.paramDocDateStart,
        paramDocDateEnd: this.paramDocDateEnd
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data.length > 0) {
        this.paramProductReceivingId = data[0].productReceivingId;
        this.paramProductRequestId = data[0].productRequestId;
        this.paramBranchId = data[0].branchId;
        this.paramCounterId = data[0].counterId;
        this.counterName = data[0].counterName;
        this.paramWarehouseId = data[0].warehouseId;
        this.warehouseName = data[0].warehouseName;
        this.paramDocDateStart = data[0].docDateStart;
        this.paramDocDateEnd = data[0].docDateEnd;

        this.generateReport();
      }
    });

    return await modal.present();
  }

  async openReportProductDetail() {
    const modal = await this.modalController.create({
      component: ReportProductReceivingDetailComponent,
      componentProps: {
        'reportDataDetail': this.reportDataDetail
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let data = modelData.data;

      if (data !== 'null') {
        //
      }
    });

    return await modal.present();
  }

  generateReport() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "product_receiving_id": this.paramProductReceivingId,
        "product_request_id": this.paramProductRequestId,
        "counter_id": this.paramCounterId,
        "warehouse_id": this.paramWarehouseId,
        "doc_date_start": this.paramDocDateStart,
        "doc_date_end": this.paramDocDateEnd
      };

      this.reportService.getReportProductReceiving(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.reportData = response.report_data;
          if(this.reportData.length > 0) {
            if(this.paramProductReceivingId !== null || this.paramProductRequestId !== null) {
              this.paramProductReceivingId = this.reportData[0].product_receiving_id;
              this.paramProductRequestId = this.reportData[0].product_request_id;
              this.paramCounterId = this.reportData[0].counter_id;
              this.counterName = this.reportData[0].counter_name;
              this.paramWarehouseId = this.reportData[0].warehouse_id;
              this.warehouseName = this.reportData[0].warehouse_name;
              this.paramDocDateStart = this.reportData[0].doc_date;
              this.paramDocDateEnd = this.reportData[0].doc_date;
            }
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  productReceivingDetail(productReceivingId: any, action: any) {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "product_receiving_id": productReceivingId
      };

      this.reportService.getReportProductReceivingDetail(options).subscribe((response) => {
        if(response.status.code === 200) {
          this.reportDataDetail = response.report_data;
          if(action === 'detail') {
            this.utilService.loadingDismiss();
            this.openReportProductDetail();
          }

          if(action === 'export') {
            this.waitForDataToLoad();
          }
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async waitForDataToLoad() {
    await new Promise(resolve => setTimeout(resolve, 1500));
    this.exportToExcel();
  }

  exportToExcel() {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
    this.utilService.loadingDismiss();
  }

  checkStatus(status: any) {
    let cssClass: any = '';
    if(status === '0') { cssClass = 'normal-text status-pending'}
    if(status === '1') { cssClass = 'normal-text status-sent'}
    if(status === '2') { cssClass = 'normal-text status-void'}
    return cssClass;
  }

}
