import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PriceGroupEditPageRoutingModule } from './price-group-edit-routing.module';

import { PriceGroupEditPage } from './price-group-edit.page';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    PriceGroupEditPageRoutingModule
  ],
  declarations: [PriceGroupEditPage],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PriceGroupEditPageModule {}
