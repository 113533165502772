import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { RoleAccess } from '../../../models/role-access.model';
import { UserProfile } from '../../../models/user-profile.model';
import { UserData } from '../../../providers/user-data';
import { UtilService } from '../../../service/util.service';
import { MasterProductService } from '../master-product.service';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-master-product-detail',
  templateUrl: './master-product-detail.page.html',
  styleUrls: ['./master-product-detail.page.scss'],
})
export class MasterProductDetailPage implements OnInit {
    token : any;
    userProfile: UserProfile = new UserProfile();
    roleAccess = new RoleAccess();
    PCode: any;

    productName : any;
    initialName :any;
    barcode: any;
    marketingTypeId: any;
    marketingTypeName : any;
    brandId: any;
    brandName : any;
    subBrandId: any;
    subBrandName : any;
    typeId: any;
    typeName : any;
    subTypeId: any;
    subTypeName : any;
    categoryId: any;
    categoryName : any;
    subCategoryId: any;
    subCategoryName : any;
    price : any;
    productData: any;
    listingCounter : any =[]; 

  constructor(
    private activeRoute: ActivatedRoute,
    private utilService: UtilService,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private storage: Storage,
    private userData: UserData,
    private mstProductService: MasterProductService,
    private router: Router

  ) { }

  ngOnInit() {

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{ 
          this.getMstProductforDetail();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  getMstProductforDetail() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.PCode = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.PCode = this.PCode.replaceAll('"','');
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.mstProductService.getProductForEdit(this.PCode, { "token": this.token }).subscribe((response) => {
          this.utilService.loadingDismiss();
          console.log(response);
          this.productData = response.results.mst_product;

          let productType = response.results.mst_product_type_data;
          let productSubType = response.results.mst_product_sub_type_data;
          let category = response.results.mst_product_category_data;
          let subCategory = response.results.mst_product_sub_category_data;
          let brand = response.results.mst_product_brand_data;
          let subBrand = response.results.mst_product_sub_brand_data;
          let marketingType = response.results.mst_product_marketing_type_data;

          this.productName= this.productData.product_name;
          this.initialName= this.productData.initial_name;
          this.barcode = this.productData.barcode;

          this.marketingTypeId = parseInt(this.productData.product_marketing_type_id);
          let findMarketingType = marketingType.find(x => x.id === this.marketingTypeId);          
          if(findMarketingType){
            this.marketingTypeName = findMarketingType.product_marketing_type_name;
          }
          this.brandId = this.productData.product_brand_id;
          let findBrand = brand.find(x => x.id === this.brandId);          
          if(findBrand){
            this.brandName = findBrand.product_brand_name;
          }
          
          this.subBrandId = parseInt(this.productData.product_sub_brand_id);
          let findSubBrand = subBrand.find(x => x.id === this.subBrandId);          
          if(findSubBrand){
            this.subBrandName = findSubBrand.product_sub_brand_name;
          }

          this.typeId = this.productData.product_type_id;
          let findType = productType.find(x => x.id === this.typeId);          
          if(findType){
            this.typeName = findType.product_type_name;
          }

          this.subTypeId = parseInt(this.productData.product_sub_type_id);
          let findSubType = productSubType.find(x => x.id === this.subTypeId);          
          if(findSubType){
            this.subTypeName = findSubType.product_sub_type_name;
          }
          
          this.categoryId = this.productData.product_category_id;
          let findCategory = category.find(x => x.id === this.categoryId);          
          if(findCategory){
            this.categoryName = findCategory.product_category_name;
          }

          this.subCategoryId =parseInt(this.productData.product_sub_category_id);
          let findSubCategory = subCategory.find(x => x.id === this.subCategoryId);          
          if(findSubCategory){
            this.subCategoryName = findSubCategory.product_sub_category_name;
          }
          this.price=this.productData.price;
          this.getListCounter();
         
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }

  getListCounter() {
      let options= 
       { "token": this.token,
      'product_id': this.PCode }
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.mstProductService.getCounterListing(options).subscribe((response) => {
          this.utilService.loadingDismiss();
          console.log(response);
          this.listingCounter = response.results.data;
         
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
  }

}
