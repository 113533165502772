import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { CashierService } from '../cashier.service';
import { MstCashier } from '../../../models/mst-cashier.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cashier-create',
  templateUrl: './cashier-create.page.html',
  styleUrls: ['./cashier-create.page.scss'],
})
export class CashierCreatePage implements OnInit {

  token: any;
  formCashierCreate: FormGroup;
  counterData: any[] = [];
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private cashierService: CashierService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormCashierCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
          this.userProfile = new UserProfile(profile);
          this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
          if(!this.roleAccess || this.roleAccess.create!='1'){
            this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
            this.navCtrl.navigateForward(['/']);
          }
          else{
            let options = {
              "token": this.token,
              "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
            };
            this.cashierService.getCashierforCreate(options).subscribe((response) => {
              this.utilService.loadingDismiss();
              this.counterData = response.results.counter_data;
              this.buildFormCashierCreate();
            }, () => {
              this.utilService.loadingDismiss();
              this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
            });
          }
        });
      }
    });
  }

  buildFormCashierCreate() {
    this.formCashierCreate = this.fb.group({
      counterId: [this.counterData.length > 0 ? this.counterData[0].id : 0, Validators.required],
      cashierName: [null, Validators.required]
    });
  }

  inputCashier() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const cashierForm = this.formCashierCreate.getRawValue();
      const mstCashier = new MstCashier();
      mstCashier.counter_id = cashierForm.counterId;
      mstCashier.cashier_name = cashierForm.cashierName;
      mstCashier.created_by = this.userProfile.username;

      this.cashierService.addCashier(mstCashier).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/cashier']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
