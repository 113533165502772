import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../service/util.service';
import { MasterProductPacketService } from '../master-product-packet.service';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-package-counter',
  templateUrl: './package-counter.page.html',
  styleUrls: ['./package-counter.page.scss'],
})
export class PackageCounterPage implements OnInit {
  token: any;
  userProfile: UserProfile = new UserProfile();
  roleAccess = new RoleAccess();
  productPackageId: any;
  mstProductData: any[] = [];
  counterWithThisPackage:any[]=[];
  productPackageName:string='';
  productPackageInitialName:string='';
  productMarketingTypeName:string='';
  productBrandName:string='';
  productSubBrandName:string='';
  productTypeName:string='';
  productSubTypeName:string='';
  productCategoryName:string='';
  productSubCategoryName:string='';
  barcode:string='';
  packagePrice:string='';
  productLength:string='';
  productWidth:string='';
  productHeight:string='';
  counterList: any[] = [];
  counterId : any[] = [];
  listProductInpackage :any[];
  packageHistory :any[] =[];
  showBtnAddCounter:boolean=false;
  showBtnSaveCounter:boolean=false;
  createdBy: any;
  createdAt: any;
  status:any='';
  active:number=0;
  constructor(
    private storage: Storage,
    private userData: UserData,
    private toastCtrl :ToastController,
    private navCtrl:NavController,
    private alertController:AlertController,
    private router: Router,
    private activeRoute: ActivatedRoute,  
    private utilService: UtilService,
    private packageService: MasterProductPacketService) { }

  ngOnInit() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getData();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  getData() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.productPackageId = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.productPackageId = this.productPackageId.replaceAll('"','');
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        let options={ 
          "counterId":this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list,
          "token": this.token
        };
        this.packageService.getProductPackageforEdit(this.productPackageId, options).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.mstProductData = response.results.mst_product_data;
          let marketingType =response.results.mst_product_marketing_type_data;
          this.listProductInpackage =response.results.mst_product_package_data;
          if(this.mstProductData.length > 0) {
            this.createdAt = this.mstProductData[0].created_at;
            this.createdBy = this.mstProductData[0].created_by;
            this.status = this.mstProductData[0].active=='1'?'Active' : 'Non Active';
            this.active = parseInt(this.mstProductData[0].active);
            this.packageHistory = response.results.activity_history;
            let findMarketingType = marketingType.find(x=>x.id==parseInt(this.mstProductData[0].product_marketing_type_id
            ));
            if(findMarketingType){
              this.productMarketingTypeName = findMarketingType.product_marketing_type_name;
            }
            let brand = response.results.mst_product_brand_data;
            let findBrand = brand.find(x=>x.id==parseInt(this.mstProductData[0].product_brand_id));
            if(findBrand){
              this.productBrandName = findBrand.product_brand_name;
            }
            let subbrand = response.results.mst_product_sub_brand_data;
            let findSubBrand = subbrand.find(x=>x.id==parseInt(this.mstProductData[0].product_sub_brand_id));
            if(findSubBrand){
              this.productSubBrandName = findSubBrand.product_sub_brand_name;
            }
            let type = response.results.mst_product_type_data;
            let findType = type.find(x=>x.id==parseInt(this.mstProductData[0].product_type_id));
            if(findType){
              this.productTypeName = findType.product_type_name;
            }
            let subType = response.results.mst_product_sub_type_data;
            let findSubType= subType.find(x=>x.id==parseInt(this.mstProductData[0].product_sub_type_id));
            if(findSubType){
              this.productSubTypeName = findSubType.product_sub_type_name;
            }
            let category = response.results.mst_product_category_data;
            let findCategory = category.find(x=>x.id==parseInt(this.mstProductData[0].product_category_id));
            if(findCategory){
              this.productCategoryName = findCategory.product_category_name;
            }
            let subCategory = response.results.mst_product_sub_category_data;
            let findSubCategory = subCategory.find(x=>x.id==parseInt(this.mstProductData[0].product_sub_category_id));
            if(findSubCategory){
              this.productSubCategoryName = findSubCategory.product_sub_category_name;
            }
            this.productPackageName = this.mstProductData[0].product_name;
            this.productPackageInitialName = this.mstProductData[0].initial_name;
            this.barcode=this.mstProductData[0].barcode;
            this.packagePrice=this.mstProductData[0].price;
            this.productLength=this.mstProductData[0].length_size;
            this.productWidth=this.mstProductData[0].width_size;
            this.productHeight=this.mstProductData[0].height_size;
          }
          this.counterWithThisPackage = response.results.mst_counter_with_this_package;
          this.counterList = response.results.mst_counter_without_this_package;
          if(this.listProductInpackage.length>0 && this.counterList.length>0){
            this.showBtnAddCounter=true;
          }        
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    });
  }
  saveAdditionalCounter() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options={ 
        "packageId":this.productPackageId,
        "counterId":this.counterId,
        "token": this.token,
        "username":this.userProfile.username
      };
      this.packageService.saveAdditionalCounter(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        window.location.reload();
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
  selectCounter(event){
    this.counterId = [];
    for(let i= 0; i < event.value.length; i++) {
      this.counterId.push(event.value[i]['id']);
    }
    console.log(this.counterId.toString());
  }
  toggleAdditionalCounter(){
    this.showBtnSaveCounter=true;
    this.showBtnAddCounter = false;
  }
  async showConfirmToggleStatus(action:number){    
    let actionName='';
    if(action==1){
      actionName = 'Mengaktifkan';
    }
    else{
      actionName ='Menonaktifkan';
    }
    const alert = await this.alertController.create({
      header: 'Approval Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk '+actionName+' paket ini?',
      buttons: [
        {
          text: 'Tidak',
          handler: () => {}
        },
        {
          text: 'Ya',
          handler: () => {
            this.showInputApprovalReason(action,actionName);
          }
        }
      ]
    });
    await alert.present();
  }
  async showInputApprovalReason(nextStatus: number,actionName:string) {
    const alert = await this.alertController.create({
      header: 'Confirmation',
      subHeader: 'Keterangan',
      message: 'Silahkan masukkan alasan '+actionName+' paket',
      inputs: [
        {
          name: 'approvalRemark',
          placeholder: 'Keterangan',
          type: 'text'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: (input: any) => {
            let remark = input.approvalRemark ? input.approvalRemark : '';
            this.toggleStatus(nextStatus,actionName,remark);
          }
        }
      ]
    });
    
    await alert.present();
  }
  toggleStatus(nextStatus:number,actionName:string,remark:string) {
    let options = {
      "token": this.token,
      "nextStatus":nextStatus,
      "id": this.productPackageId,
      "updated_by": this.userProfile.username,
      "remark":remark
    };
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.packageService.deactivate(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.toastCtrl.create({ duration: 2000, message: actionName+' Paket Berhasil' }).then(t => t.present());
          this.navCtrl.navigateForward(['/master-product-packet']);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }
}
