import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';
import { SetorKasir } from '../../models/setor-kasir.model';
import { SetorKasirDetail } from '../../models/setor-kasir-detail.model';

@Injectable({
  providedIn: 'root'
})
export class SetorKasirService {

  constructor(private httpService: HttpService) { }
  
  getSetorKasir(params: any): Observable<any> {
    return this.httpService.get('cashier-deposite', params);
  }

  getUserKasir(counterId: any, params: any): Observable<any> {
    return this.httpService.get('cashier-deposite/create/'+counterId, params);
  }

  getNilaiSetorKasir(userkasirId: any, transDate: any, params: any): Observable<any> {
    return this.httpService.get(`cashier-deposite/deposite-value/${userkasirId}/${transDate}`, params);
  }
  
  addSetorKasir(data: SetorKasir): Observable<any> {
    return this.httpService.post('cashier-deposite/store', data);
  }

  updateSetorKasir(setorKasirId: any, data: SetorKasir): Observable<any> {
    return this.httpService.put(`cashier-deposite/${setorKasirId}`, data);
  }

  deleteSetorKasir(setorKasirId: any): Observable<any> {
    return this.httpService.delete('cashier-deposite/'+setorKasirId);
  }

  getSetorKasirEdit(setorKasirId: any, counterId: any, params: any): Observable<any> {
    return this.httpService.get(`cashier-deposite/edit/${setorKasirId}/${counterId}`, params);
  }

}
