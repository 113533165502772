import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReportProductRequestPageRoutingModule } from './report-product-request-routing.module';

import { ReportProductRequestPage } from './report-product-request.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReportProductRequestPageRoutingModule
  ],
  declarations: [ReportProductRequestPage]
})
export class ReportProductRequestPageModule {}
