import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { environment } from '../../../../environments/environment';
import { UserProfile } from '../../../models/user-profile.model';
import { Customer } from '../../../models/customer.model';
import { RegisterResellerService } from '../register-reseller.service';
import { RoleAccess } from '../../../models/role-access.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-reseller-create',
  templateUrl: './register-reseller-create.page.html',
  styleUrls: ['./register-reseller-create.page.scss'],
})
export class RegisterResellerCreatePage implements OnInit {

  baseUrl: any;
  token: any;
  formRegisterResellerCreate: FormGroup;
  userProfile: UserProfile = new UserProfile();
  counterList: any[];
  
  roleAccess = new RoleAccess();
  counterId:any;

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private registerResellerService: RegisterResellerService,
    private userData: UserData,
    private router: Router
  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
    this.buildFormRegisterResellerCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        } 
        this.getCustomerResllerForCreate();
      }else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  getCustomerResllerForCreate() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let options = {
        "token": this.token,
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.registerResellerService.getCustomerResllerForCreate(options)
      .subscribe((res: any) => {
        this.utilService.loadingDismiss();
        if (res.status.code === 200) {
          this.counterList = res.results.counter_data;
          this.formRegisterResellerCreate = this.fb.group({
            counterId: [null, Validators.required],
            customerName: [null, Validators.required],
            identityNumber: [null, Validators.required],
            phone: [null, Validators.required],
            birthPlace: ["", ""],
            birthDate: ["", ""],
            gender: [null, Validators.required],
            profession: ["", ""],
            areaCode: [null, Validators.required],
            area: [null, Validators.required],
            address: [null, Validators.required],
            joinDate: [null, Validators.required],
            internalEmployee: [null, Validators.required],
            referralCode: ["", ""],
            referralCodeUpline: ["", ""]
          });
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  buildFormRegisterResellerCreate() {
    this.formRegisterResellerCreate = this.fb.group({
      counterId: [null, Validators.required],
      customerName: [null, Validators.required],
      identityNumber: [null, Validators.required],
      phone: [null, Validators.required],
      birthPlace: ["", ""],
      birthDate: ["", ""],
      gender: [null, Validators.required],
      profession: ["", ""],
      areaCode: [null, Validators.required],
      area: [null, Validators.required],
      address: [null, Validators.required],
      joinDate: [null, Validators.required],
      internalEmployee: [null, Validators.required],
      referralCode: ["", ""],
      referralCodeUpline: ["", ""]
    });
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  inputRegisterReseller() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const resellerRegisterData = this.formRegisterResellerCreate.getRawValue();
      let cBirthDate = this.utilService.convertDate(resellerRegisterData.birthDate);
      let nBirthDate = cBirthDate.years + '-' + cBirthDate.months + '-' + cBirthDate.dates;
      let cJoinDate = this.utilService.convertDate(resellerRegisterData.joinDate);
      let nJoinDate = cJoinDate.years + '-' + cJoinDate.months + '-' + cJoinDate.dates;
      const customer = new Customer();
      customer.counter_id = this.counterId;
      customer.customer_name = resellerRegisterData.customerName;
      customer.identity_number = resellerRegisterData.identityNumber;
      customer.phone = resellerRegisterData.phone;
      customer.birth_place = resellerRegisterData.birthPlace;
      customer.birth_date = nBirthDate;
      customer.gender = resellerRegisterData.gender;
      customer.profession = resellerRegisterData.profession;
      customer.area_code = resellerRegisterData.areaCode;
      customer.area = resellerRegisterData.area;
      customer.address = resellerRegisterData.address;
      customer.join_date = nJoinDate;
      customer.internal_employee = resellerRegisterData.internalEmployee;
      customer.referral_code = resellerRegisterData.referralCode;
      customer.referral_code_upline = resellerRegisterData.referralCodeUpline;
      customer.created_by = this.userProfile.username;
      console.log('customer', customer);

      this.registerResellerService.addCustomerReseller(customer).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        }else if(response.status.code === 200 && response.message == 'Referral code not valid.') {
          this.toastCtrl.create({ duration: 2000, message: response.message }).then(t => t.present());
        }else if(response.status.code === 200 && response.message == 'Failed get Counter Data') {
          this.toastCtrl.create({ duration: 2000, message: response.message }).then(t => t.present());
        }else if(response.status.code === 200 && response.message == 'Referral code is found.') {
          let alertMessage = `Referral code ${response.results.referral_code} sudah digunakan oleh reseller ${response.results.customer_name}, No.Hp: ${response.results.phone}`;
          this.toastCtrl.create({ duration: 5000, message: alertMessage }).then(t => t.present());
        }else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/register-reseller']);;
          }
        }
      ]
    });

    await alert.present();
  }

  selectCounter(event){
    this.counterId = event.value.id;
  }

}
