import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReportNationalStockPageRoutingModule } from './report-national-stock-routing.module';

import { ReportNationalStockPage } from './report-national-stock.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReportNationalStockPageRoutingModule
  ],
  declarations: [ReportNationalStockPage]
})
export class ReportNationalStockPageModule {}
