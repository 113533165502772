import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { MstProductPackageBundle } from '../../models/mst-product-package-bundle.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterProductPacketService {

  constructor(private httpService: HttpService) { }

  getProductPackage(params: any): Observable<any> {
    return this.httpService.get('product-package', params);
  }
  getListProduct(params: any): Observable<any> {
    return this.httpService.get('sales-transaction/product', params);
  }

  getProductPackageforCreate(params: any): Observable<any> {
    return this.httpService.get('product-package/create', params);
  }

  getProductPackageforEdit(productPackageId: any, params: any): Observable<any> {
    return this.httpService.get(`product-package/${productPackageId}/edit`, params);
  }
  saveAdditionalCounter( params: any): Observable<any> {
    return this.httpService.post(`add-counter-for-package/`, params);
  }

  addProductPackage(data: MstProductPackageBundle): Observable<any> {
    return this.httpService.post('product-package', data);
  }
  addProductPackageMultipleCounter(data: any): Observable<any> {
    return this.httpService.post('product-package', data);
  }
  deactivate(data: any): Observable<any> {
    return this.httpService.get('package/deactivate', data);
  }
}
