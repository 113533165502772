import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { WarehouseMutation } from '../../models/warehouse-mutation.model';
import { WarehouseMutationBundle } from '../../models/warehouse-mutation-bundle.model';
import { ApprovalHistory } from '../../models/approval-history.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WarehouseMutationInService {

  constructor(private httpService: HttpService) { }

  getProduct(params: any): Observable<any> {
    return this.httpService.get('product-all', params);
  }

  getLocalData(name: any, params: any): Observable<any> {
    let apiUrl: any;
    if(name === 'counter') { apiUrl = 'counter-all'; }
    if(name === 'warehouse') { apiUrl = 'warehouse-all'; }

    return this.httpService.get(apiUrl, params);
  }

  getWarehouseMutationforCreate(params: any): Observable<any> {
    return this.httpService.get('warehouse-mutation/create', params);
  }

  getWarehouseMutationforEdit(warehouseMutationId: any, params: any): Observable<any> {
    return this.httpService.get(`warehouse-mutation/${warehouseMutationId}/edit`, params);
  }

  getWarehouseMutation(params: any): Observable<any> {
    return this.httpService.get('warehouse-mutation', params);
  }

  addWarehouseMutation(data: WarehouseMutationBundle): Observable<any> {
    return this.httpService.post('warehouse-mutation', data);
  }

  addApprovalHistory(data: ApprovalHistory): Observable<any> {
    return this.httpService.post('approval-history', data);
  }

  updateMutationOut(warehouseMutationId: any, data: WarehouseMutation): Observable<any> {
    return this.httpService.put(`warehouse-mutation/${warehouseMutationId}`, data);
  }
}
