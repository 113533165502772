import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { PosResellerService } from '../../pages/pos-reseller/pos-reseller.service';
import { UtilService } from '../../service/util.service';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss'],
})
export class SearchProductComponent implements OnInit {
  
  @Input() token: any;
  @Input() counterId: any;
  keyword: any;
  products: any[]=[];
  db: any;
  
  constructor(
    public modalController: ModalController,
    private posResellerService: PosResellerService,
    private utilService: UtilService,
    private toastCtrl: ToastController
  ) { }
  ionViewDidEnter() {
    (<HTMLInputElement>document.getElementById("inputKeyword")).focus();
  }
  ngOnInit() {
    (<HTMLInputElement>document.getElementById("inputKeyword")).focus();
    this.openDB();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  openDB() {
    //this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  async search() {
    this.products = [];
    if(this.keyword.length >= 3) {
      this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
        let options = {
          "token": this.token,
          "counter_id": this.counterId,
          "keyword": this.keyword
        };

        this.posResellerService.getProductSearch(options).subscribe(async (response) => {
          if(response.results) {
            this.products = [];
            if(response.results.data.length > 0) {
              for (let index = 0; index < response.results.data.length; index++) {
                this.products.push({
                  id: response.results.data[index].id,
                  barcode: response.results.data[index].barcode,
                  product_name: response.results.data[index].product_name,
                  price: response.results.data[index].price
                }); 
              }
            }
            this.utilService.loadingDismiss();
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari product.' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari product.' }).then(t => t.present());
        });
      });
    }
  }

  chooseProduct(productId: any) {
    this.modalController.dismiss({productId: productId});
  }
}
