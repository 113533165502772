import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { environment } from '../../../environments/environment';
import { PosResellerService } from '../../pages/pos-reseller/pos-reseller.service';


@Component({
  selector: 'app-member-reseller',
  templateUrl: './member-reseller.component.html',
  styleUrls: ['./member-reseller.component.scss'],
})
export class MemberResellerComponent implements OnInit {

  @Input() token: any;
  @Input() totalTransaction: any;
  @Input() customer: any;
  keyword: any;
  baseUrl: any;
  listResellerMaster: any[] = [];
  listReseller: any[] = [];
  db: any;

  constructor(
    public modalController: ModalController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private posResellerService: PosResellerService,

  ) {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit() {
   // this.openDB();
  }

  openDB() {
    this.db = (<any>window).openDatabase('db_pos', '1.0', 'POS Database', 2 * 1024 * 1024);
  }

  async searchReseller() {
    if(this.keyword.length >= 3) {
      this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
        let options = {
          "token": this.token,
          "keyword": this.keyword
        };

        this.posResellerService.getReseller(options).subscribe(async (response) => {
          if(response.results) {
            this.listReseller = [];
            if(response.results.data.length > 0) {
              for (let index = 0; index < response.results.data.length; index++) {
                this.listReseller.push({
                  id: response.results.data[index].id,
                  customer_type_id: response.results.data[index].customer_type_id,
                  card_id: response.results.data[index].card_id,
                  referral_code: response.results.data[index].referral_code,
                  customer_name: response.results.data[index].customer_name,
                  identity_number: response.results.data[index].identity_number,
                  phone: response.results.data[index].phone,
                  birth_place: response.results.data[index].birth_place,
                  birth_date: response.results.data[index].birth_date,
                  gender: response.results.data[index].gender,
                  profession: response.results.data[index].profession,
                  area_code: response.results.data[index].area_code,
                  area: response.results.data[index].area,
                  address: response.results.data[index].address,
                  map_location: response.results.data[index].map_location,
                  join_date: response.results.data[index].join_date,
                  internal_employee: response.results.data[index].internal_employee,
                  active: response.results.data[index].active,
                  created_by: response.results.data[index].created_by,
                  updated_by: response.results.data[index].updated_by,
                  created_at: response.results.data[index].created_at,
                  updated_at: response.results.data[index].updated_at
                });  
              }
            }
            this.utilService.loadingDismiss();
          } else {
            this.utilService.loadingDismiss();
            this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari reseller.' }).then(t => t.present());
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal mencari reseller.' }).then(t => t.present());
        });
      });
    }else {
      this.utilService.loadingDismiss();
      this.listReseller = [];
    }
    /*
    if(event.target.value == '') {
      console.log(event.target.value)
      this.listReseller = this.listResellerMaster;
      return false
    }

    // this.getData();
    let PATTERN = event.target.value;
    this.listReseller = this.listResellerMaster.filter(str => {
      return str.phone.toString().toLowerCase().indexOf(PATTERN) !== -1 ||
        str.customer_name.toString().toLowerCase().indexOf(PATTERN) !== -1;
    });
    */
    // this.listReseller = this.listReseller.filter(str => str.phone == PATTERN);
  }

  async choose(id) {
    /* diubah 3 november 2023
    await this.db.transaction((tx1) => {
      tx1.executeSql(`SELECT upline_id FROM customer_downlines WHERE downline_id = '${id}'`, [],
        (tx2, result2) => {
          tx2.executeSql(`SELECT reward_remains FROM customer_rewards WHERE customer_id = '${id}'`,[],
          (tx3, result3) => {
            let sisaReward = 0;
            let readonlyPakaiReward = true;
            if(result3.rows.length > 0) {
              sisaReward = Number(result3.rows[0].reward_remains);
              if(result3.rows[0].reward_remains > 0) {
                readonlyPakaiReward = false;
              }
            }

            let param = this.listReseller.filter(str => str.id == id);
            if(result2.rows.length > 0) {
              param[0].uplineId = parseInt(result2.rows[0].upline_id);
              param[0].sisaReward = sisaReward;
              param[0].readonlyPakaiReward = readonlyPakaiReward;
            }else {
              param[0].uplineId = null;
              param[0].sisaReward = sisaReward;
              param[0].readonlyPakaiReward = readonlyPakaiReward;
            }
            this.modalController.dismiss(param);
          });

      }, (error) => {
        console.log('error test', error)
      });
    });*/
    let sisaReward = 0;
    let readonlyPakaiReward = true;
    this.posResellerService.getMemberById({ "token": this.token },id).subscribe(async (response) => {
          if(response.results) {
            console.log(response.results);
            if(response.results.length > 0) {
              sisaReward = response.results[0].point_remains;
            }
          }
        });
           
    
    if(sisaReward > 0) {
      readonlyPakaiReward = false;
    }
    let param = this.listReseller.filter(str => str.id == id);
    param[0].uplineId = null;
    param[0].sisaReward = sisaReward;
    param[0].readonlyPakaiReward = readonlyPakaiReward;
  
  this.modalController.dismiss(param);
    
  }

  async dismissModal() {
    let param = [];
    await this.modalController.dismiss(param);
  }

}
