import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleAccess } from '../../../models/role-access.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserData } from '../../../providers/user-data';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { PicService } from '../pic.service';
import { UtilService } from '../../../service/util.service';
import { Storage } from '@ionic/storage';
import { SetupPicComponent } from '../../../component/setup-pic/setup-pic.component';
import * as utf8 from 'crypto-js/enc-utf8';
import AES = require('crypto-js/aes');
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-pic-detail',
  templateUrl: './pic-detail.page.html',
  styleUrls: ['./pic-detail.page.scss'],
})
export class PicDetailPage implements OnInit {

  formPicDetail: FormGroup;
  pic_header_id: any;  
  token: any;
  setupApprovalData: any;
  setupApprovalDetailData:any;
  rows:any[];
  userProfile: UserProfile= new UserProfile();
  roleAccess = new RoleAccess();
  saveButton :boolean = false;
  no:number=1;
  picName :any ='';
  roleList:any[]=[];
  constructor(
    private activeRoute: ActivatedRoute,    
    private fb: FormBuilder,
    private utilService: UtilService,
    private picService: PicService,
    private toastCtrl: ToastController,    
    private navCtrl: NavController,
    private modalController: ModalController,
    private alertController: AlertController,
    private storage: Storage,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    
    this.buildFormPicDetail();
    
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.update!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        else{
          this.getPicDetail();
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }
  
  ionViewDidEnter(){
    this.saveButton=false;
  }

  buildFormPicDetail() {
    this.formPicDetail = this.fb.group({
      picName: [null]
    });
  }
  
  getPicDetail() {
    this.activeRoute.queryParams.subscribe((snapshot) => {
      let myKey=environment.myKey;
      this.pic_header_id = AES.decrypt(snapshot.i, myKey).toString(utf8);
      this.utilService.loadingPresent('Harap tunggu...')
      .then(() => {
        this.picService.detailPic( { "token": this.token,'pic_header_id':this.pic_header_id }).subscribe((response) => {
          this.utilService.loadingDismiss();
          this.setupApprovalData = response.results.data_header;
          this.rows = response.results.data_detail;
          this.picName = response.results.data_header.pic_name;
          this.roleList = response.results.data_role;
          this.no = this.rows.length;
          if(this.no>0){
            for(let i=0;i<this.no;i++){
              this.rows[i].no=(i+1);
            }
          }
          if(this.setupApprovalData) {
            this.formPicDetail = this.fb.group({
              picName: [this.picName]
            });
          }
        }, () => {
          this.utilService.loadingDismiss();
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        });
      });
    })
  }
  

  async showConfirmDelete(id: any) {
    const alert = await this.alertController.create({
      header: 'Delete Confirmation',
      cssClass:'custom-alert-class',
      message: 'Apakah anda yakin untuk hapus data ini?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {}
        },
        {
          text: 'OK',
          handler: () => {
            this.deleteData(id);
          }
        }
      ]
    });

    await alert.present();
  }

  deleteData(id: any) {
    let index = 0;
    let findId = this.rows.find(x=>x.id==id);
    if(findId){
      index =this.rows.indexOf(findId);
    }
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      this.picService.deleteDetail(id).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 200) {
          this.showDeleteSuccess(index);
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      });
    });
  }

  async showDeleteSuccess(index:number) {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Berhasil hapus data!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.getPicDetail();
          }
        }
      ]
    });

    await alert.present();
  }
  async changeApproval(approvalData: any) {
    let userId ='0';
    let approvalName ='';
    let no =this.no;
    if(approvalData!='0'){
      approvalName = approvalData.name;
      no=approvalData.no;
    }
    else{
      no=0;
    }
    const modal = await this.modalController.create({
      component: SetupPicComponent,
      componentProps: {
        'userId': userId,
        'approvalName': approvalName,
        'no': no,
        'roleList':this.roleList,
        'userListExist':this.rows.filter(x=>x.based_on=='employee'),
        'roleListExist':this.rows.filter(x=>x.based_on=='role'),
      },
      backdropDismiss: false
    });

    modal.onDidDismiss().then((modelData) => {
      let user = modelData.data.user;
      let role = modelData.data.role;
      let basedOn = modelData.data.basedOn;
      let no = modelData.data.no;
        let checkUser = this.rows.find(x => x.no === no);
        if (checkUser !== undefined) {
          let index = this.rows.indexOf(checkUser);
          if(basedOn!=''){
            if(role.length==1){
              let roleName= role[0].role_name;
              let roleId= role[0].id;
              let checkRole= this.rows.find(x => parseInt(x.role_id) === roleId);
              if (checkRole == undefined) {
                this.rows[index]['based_on'] = basedOn;
                this.rows[index]['employee_name'] = '';
                this.rows[index]['employee_id'] = 0;
                this.rows[index]['role_name'] = roleName;
                this.rows[index]['role_id'] = roleId;
              }
            }
            else if(user.length==1){
              let employeeName= user[0].name;
              let employeeId = user[0].employee_id;
              let checkUser: any[] = this.rows.find(x => x.employee_id === employeeId);
              if (checkUser == undefined) {
                this.rows[index]['based_on'] = basedOn;
                this.rows[index]['employee_name'] = employeeName;
                this.rows[index]['employee_id'] = employeeId;
                this.rows[index]['role_name'] = '';
                this.rows[index]['role_id'] = 0;
              }
            }
            else{
              this.rows.splice(index, 1);
            }
            this.saveButton = true;
          }
        }
        let roleName='';
        let roleId=0;
        let employeeName='';
        let employeeId =0;
        if(role.length>0){
          for(let r=0;r<role.length;r++){
            roleName= role[r].role_name;
            let roleId= role[r].id;
            let checkRole: any[] = this.rows.find(x => x.role_name === roleName);
            if (checkRole == undefined) {
              let addData = {
                'based_on':basedOn,
                'employee_id':employeeId,
                'employee_name':'',
                'role_name':roleName,
                'role_id':roleId,
                'id':0
              };
              this.rows.push(addData);
           //   this.rows = [...this.rows];
            }
            this.saveButton = true;
          }
        }
        else if(user.length>0){
          for(let u=0;u<user.length;u++){
            employeeName= user[u].name;
            employeeId = user[u].employee_id;
            let checkUser: any[] = this.rows.find(x => x.employee_name === employeeName);
            if (checkUser == undefined) {
              let addData = {
                'based_on':basedOn,
                'employee_id': employeeId,
                'employee_name':employeeName,
                'role_name':'',
                'role_id':roleId,
                'id':0
              };
              this.rows.push(addData);
              //this.rows = [...this.rows];
            }
            this.saveButton = true;
          }
        }
      this.utilService.loadingDismiss();
    });

    return await modal.present();
  }
  
  updatePic(){
    let options = {
      "token": this.token,
      "picHeaderId": this.pic_header_id,
      "username": this.userProfile.username,
      "details" :this.rows
    };

    this.picService.updatePic(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      
     if(response.status.code === 200) {
       this.showConfirmUpdate();
     } else {
       this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
     }
    }, (err) => {
      this.utilService.loadingDismiss();
      if(err.error.error === 'token_expired') {
        this.userData.logout().then(() => {
          this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
          this.navCtrl.navigateForward(['/login']);
        });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      }
    });
  }
  

  async showConfirmUpdate() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/pic']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
