import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProductRequestStatus } from '../../enum/ProductRequestStatus';

@Component({
  selector: 'app-report-product-request-detail',
  templateUrl: './report-product-request-detail.component.html',
  styleUrls: ['./report-product-request-detail.component.scss'],
})
export class ReportProductRequestDetailComponent implements OnInit {
  
  @Input() reportDataDetail: any[];

  productRequestId: any;
  counterName: any;
  warehouseName: any;
  docDate: any;
  needDate: any;
  statusName: any;
  description: any;

  requestStatus = ProductRequestStatus;
  requestStatusList = Object.keys(ProductRequestStatus).filter(
    requestStatus => typeof this.requestStatus[requestStatus] === 'number'
  );

  constructor(
    public modalController: ModalController) { }

  ngOnInit() {
    if(this.reportDataDetail.length > 0) {
      this.productRequestId = this.reportDataDetail[0].product_request_id;
      this.counterName = this.reportDataDetail[0].counter_name;
      this.warehouseName = this.reportDataDetail[0].warehouse_name;
      this.docDate = this.reportDataDetail[0].doc_date;
      this.needDate = this.reportDataDetail[0].need_date;
      this.statusName = this.requestStatusList[parseInt(this.reportDataDetail[0].status)];
      this.description = this.reportDataDetail[0].desc;
    }
  }

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }

}
