export class PettyCashCategory {

    id: number;
    petty_cash_category_name: string;
    active: number;
    created_by: string;
    updated_by: string;
    

    constructor(model?) {
        model = model || {};
        this.id = model.id || 0;
        this.petty_cash_category_name = model.petty_cash_category_name || null;
        this.active = model.active || 0;
        this.created_by = model.created_by || null;
        this.updated_by = model.updated_by || null;
    }
}