import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilService } from '../../../service/util.service';

@Component({
  selector: 'app-af-sales-target',
  templateUrl: './af-sales-target.component.html',
  styleUrls: ['./af-sales-target.component.scss'],
})
export class AfSalesTargetComponent implements OnInit {

  @Input() counterList: any[];
  @Input() paramMonthId: any;
  @Input() paramYear: any;

  baseUrl: any;
  counterSelect: any;
  year:any;
  month:any;
  
  yearList: any;
  monthList: any[] = ['', 'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  

  constructor(
    public modalController: ModalController,
    private utilService: UtilService
  ) {
  }

  ngOnInit() {
    let convertDate = this.utilService.convertDate(new Date());
    let todayDate = convertDate.years + '-' + convertDate.months + '-' + convertDate.dates;

    this.month = this.paramMonthId ? this.paramMonthId : 0;
    this.year = this.paramYear ? this.paramYear + '-01-01' : todayDate;
  }

  async dismissModals() {
    const counterId: any = this.counterSelect;
    const year : any = this.year;
    const month : any = this.month;
    console.log(counterId)
    await this.modalController.dismiss(counterId,year,month);
  }
  
  async dismissModal(code: any) {
    let dataSearch: any[] = [];
    let convertDate = this.utilService.convertDate(this.year);

    if(code === 'search') {
      const counterId: any = this.counterSelect;
      const year : any = convertDate.years;
      const month : any = this.month;
      let data = {
        month:month,
        year: year,
        counterId: counterId
      };
      console.log(data);

      dataSearch.push(data);
    }

    if(code === 'close') {
      dataSearch = [];
    }

    await this.modalController.dismiss(dataSearch);
  }
  
  selectCounter(event){
    this.counterSelect = event.value.id;
  }

}
