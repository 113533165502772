import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SliderListPageRoutingModule } from './slider-list-routing.module';

import { SliderListPage } from './slider-list.page';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SliderListPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [SliderListPage]
})
export class SliderListPageModule {}
