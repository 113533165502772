
import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController, AlertController, ToastController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { UtilService } from '../../service/util.service';
import { UserProfile } from '../../models/user-profile.model';
import { UserData } from '../../providers/user-data';
import { SetupResellerRewardService } from './setup-reseller-reward.service';
import { RoleAccess } from '../../models/role-access.model';
import { AES } from 'crypto-js';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-setup-reseller-reward',
  templateUrl: './setup-reseller-reward.page.html',
  styleUrls: ['./setup-reseller-reward.page.scss'],
})
export class SetupResellerRewardPage implements OnInit {


  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: '',
    orderDir: 'desc'
  };

  rows: any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  promotionList: any;
  filteredData: any;
  counterId: any = '';

  db: any;
  roleAccess = new RoleAccess();

  constructor(
    private storage: Storage,
    private navCtrl: NavController,
    private alertController: AlertController,
    private utilService: UtilService,
    private toastCtrl: ToastController,
    private userData: UserData,
    private setupResellerRewardService: SetupResellerRewardService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.view!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
        this.pageCallback({ offset: this.page.offset });
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }
  
  sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.reloadTable();
  }

  reloadTable() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      let offset = this.page.offset + 1;
      let options = {
        "token": this.token,
        "page": offset.toString(),
        "counter_id": this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list
      };

      this.setupResellerRewardService.getPromotion(options).subscribe((response) => {
        this.utilService.loadingDismiss();
        this.page.count = response.results.total;
        this.rows = response.results.data;
        this.filteredData = response.results.data;
        this.promotionList = response.results.data;
      }, (err) => {
        this.utilService.loadingDismiss();
        console.log(err);
        if(err.error.error === 'token_expired') {
          this.userData.logout().then(() => {
            this.toastCtrl.create({ duration: 2000, message: 'Token telah expired. Silahkan login kembali' }).then(t => t.present());
            this.navCtrl.navigateForward(['/login']);
          });
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
        }
      });
    });
  }

  goToPromotionCreate() {
    this.navCtrl.navigateForward(['/setup-reseller-reward/setup-reseller-reward-create']);
  }

  goToUpdatePage(id: any) {
    let myKey = environment.myKey;
    let encryptID = AES.encrypt(JSON.stringify(id),myKey).toString();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        i: encryptID
      }
    };
    this.navCtrl.navigateForward(['/setup-reseller-reward/setup-reseller-reward-edit'], navigationExtras);
  }

  goToPromotionDetail(id: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        promotionId: id
      }
    };
    this.navCtrl.navigateForward(['/promotion-detail'], navigationExtras);
  }

  filterDatatable(event:any) {
    let val = event.target.value.toLowerCase();
    let columnLength = 18;
    let keys = Object.keys(this.promotionList[0]);
    this.rows = this.filteredData.filter(function(item){
      for (let i=0; i < columnLength; i++){
        if(item[keys[i]] !== null) {
          if (item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val){
            return true;
          }
        }
      }
    });
    this.page.offset = 0;
  }
}
