import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReceiptVoucherPageRoutingModule } from './receipt-voucher-routing.module';

import { ReceiptVoucherPage } from './receipt-voucher.page';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReceiptVoucherPageRoutingModule,
    NgxDatatableModule
  ],
  declarations: [ReceiptVoucherPage],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ReceiptVoucherPageModule {}
