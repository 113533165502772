import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';
import { ModalKasir } from '../../models/modal-kasir.model';

@Injectable({
  providedIn: 'root'
})
export class ModalKasirService {

  constructor(private httpService: HttpService) { }

  getModalKasir(params: any): Observable<any> {
    return this.httpService.get('cashier-capital', params);
  }

  getKasirUser(params: any): Observable<any> {
    return this.httpService.get('cashier-capital/create/'+params.counter_id, {token: params.token});
  } 

  addModalKasir(data: ModalKasir): Observable<any> {
    return this.httpService.post('cashier-capital/store', data);
  }
  
  getModalKasirforEdit(modalKasirId: any, counterId: any, params: any): Observable<any> {
    return this.httpService.get('cashier-capital/edit/'+modalKasirId+'/'+counterId, params);
  }

  updateModalKasir(modalKasirId: any, data: ModalKasir): Observable<any> {
    return this.httpService.put('cashier-capital/'+modalKasirId, data);
  }

  deleteModalKasir(modalKasirId: any): Observable<any> {
    return this.httpService.delete('cashier-capital/'+modalKasirId);
  }

}
