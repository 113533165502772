import { Injectable } from '@angular/core';

import { HttpService } from '../../service/http.service';
import { Observable, observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RequestVoidService {

  constructor(private httpService: HttpService) { }
  

  getVoidRequest(params: any): Observable<any> {
    return this.httpService.get(`sales_existing/void`, params);
  }

  
  saveVoidTransaction(data: any): Observable<any> {
    return this.httpService.post('sales_existing/void/save-void-transaction', data);
  }
}
