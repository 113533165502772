import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../service/http.service';
@Injectable({
  providedIn: 'root'
})
export class SetupApprovalService {

  constructor(private httpService: HttpService) { }
  getUserSearch(params: any): Observable<any> {
    return this.httpService.get('user-search', params);
  }
}
