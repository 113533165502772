import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { UtilService } from '../../service/util.service';
import { Storage } from '@ionic/storage';
import { UserProfile } from '../../models/user-profile.model';

import { ReportService } from '../../pages/report/report.service';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'app-report-summary-stock',
  templateUrl: './report-summary-stock.component.html',
  styleUrls: ['./report-summary-stock.component.scss'],
})
export class ReportSummaryStockComponent implements OnInit {
  @Input() branchList: any[];
  @Input() counterList: any[];
  @Input() paramCounterId: any;
  @Input() paramBranchId: any;  
  
  @ViewChild('branchComponent', { static: false }) branchComponent: IonicSelectableComponent;
  @ViewChild('counterComponent', { static: false }) counterComponent: IonicSelectableComponent;

  db: any;

  branchId:any;
  token: any;
  userProfile: UserProfile = new UserProfile();
  counterData: any[] = [];
  branchData: any[] = [];
  counterByLogin : any;

  selectedCounters: any[];
  selectedBranchs:any[];

  //untuk di return
  counterId:any='';

  constructor(
    private storage: Storage,
    private reportService: ReportService,
    public modalController: ModalController,
    public toastCtrl : ToastController,
    private utilService: UtilService
  ) {
  }

  ngOnInit() {
    this.branchId = this.paramBranchId ? this.paramBranchId : '';
    this.counterId = this.paramCounterId ? this.paramCounterId : '';
    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile')
    ])
    .then(([token, profile]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.counterByLogin = this.userProfile.counter_id !== 0 ? this.userProfile.counter_id : this.userProfile.counter_id_list;
        this.utilService.loadingPresent('Harap tunggu...')
        .then(() => {
            this.utilService.loadingDismiss();
            let branchIdSplit = this.paramBranchId !== '' ? String(this.paramBranchId).split(',') : [];
            this.selectedBranchs=[];
            for(let i= 0; i < this.branchList.length; i++) {
              let selectedBranch: any = this.branchList.find(x => x.id ==  branchIdSplit[i]);
              if(selectedBranch){
                let idx =this.branchList.indexOf(selectedBranch);
                this.selectedBranchs.push(this.branchList[idx]);
              }
            }            
            this.filterCounterByBranch();
        });
      }
    })
  }
 
  async dismissModal(code: any) {
    let dataReturn: any;
    let error = 0;
    if(code === 'generate') {
      dataReturn = [];
      let filterData = {
        branchId:this.branchId,
        counterId :this.counterId
      };
      dataReturn.push(filterData);
    }
    if(code === 'close') {
      dataReturn = 'null';
    }
    if(error==0){
      await this.modalController.dismiss(dataReturn);
    }
  }
  
  toggleItems() {
    this.branchComponent.toggleItems(
      this.branchComponent.itemsToConfirm.length ? false : true
    );
  }

  confirm() {
    this.selectedBranchs = [];
    this.branchComponent.confirm();
    this.branchComponent.close();
    this.selectedBranchs = this.branchComponent.value;
    this.counterId ='';
    this.filterCounterByBranch();
  }

  filterCounterByBranch(){
    this.selectedCounters=[];
    let idBranch = '';
    for(let i=0;i<this.selectedBranchs.length;i++){
      idBranch += this.selectedBranchs[i].id+',';
    }
    this.branchId = idBranch.replace(/(^,)|(,$)/g, "");
       
    let options = { 
      "token": this.token,
      "branch_id": this.branchId,
      "counter_id": this.counterByLogin 
    };
    this.reportService.getCounterByBranchId(options).subscribe((response) => {
      this.utilService.loadingDismiss();
      this.counterData = [];
      this.counterData = response.results;
      let checkedCounter =this.counterId !=='' ? this.counterId :this.counterByLogin ;
      let counterIdSplit = checkedCounter !== '' ? String(checkedCounter).split(',') : [];
      let idCounter='';
      for(let i= 0; i < checkedCounter.length; i++) {
        let selectedCounter: any = this.counterData.find(x => x.id ==  counterIdSplit[i]);
        if(selectedCounter){
          let idx =this.counterData.indexOf(selectedCounter);
          this.selectedCounters.push(this.counterData[idx]);
          idCounter += this.counterData[idx]['id']+',';
        }
      }
      idCounter = idCounter.replace(/(^,)|(,$)/g, "");
      this.counterId = idCounter;
    }, () => {
      this.utilService.loadingDismiss();
      this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
    });    
  }
  
  toggleCounterItems() {
    this.counterComponent.toggleItems(
      this.counterComponent.itemsToConfirm.length ? false : true
    );
  }

  confirmCounter() {
    this.counterComponent.confirm();
    this.counterComponent.close();
    
    this.counterList = [];
    this.counterList = this.counterComponent.value;
    let idCounter ='';
    let idBranch = [];
    for(let i=0;i<this.counterList.length;i++){
      idCounter += this.counterList[i].id+',';
      idBranch.push(this.counterList[i].branch_id);
    }
    idCounter = idCounter.replace(/(^,)|(,$)/g, "");
    this.counterId = idCounter;

    if(this.branchId!=''&& this.counterId==''){//jika pilih branch tanpa pilih counter, maka ambil all counter di cabang tsb
      let idCounter =''; 
      for(let i=0;i<this.counterData.length;i++){
        idCounter += this.counterData[i].id+',';
      }
      idCounter = idCounter.replace(/(^,)|(,$)/g, "");
      this.counterId = idCounter;
    }
    else if(this.branchId==''&& this.counterId!=''){ // jika tidak pilih branch, setelah pilih counter maka pilih branch sesuai counter
      this.selectedBranchs=[];
      
      for(let i=0;i<idBranch.length;i++){
        let selectedBranch: any = this.branchData.find(x => x.id ==  idBranch[i]);
        if(selectedBranch){
          let idx =this.branchData.indexOf(selectedBranch);
          this.selectedBranchs.push(this.branchData[idx]);            
        }
      } 
      this.branchId = idBranch;
    }
    else if(this.branchId==''&& this.counterId==''){ // jika tidak pilih branch dan cabang, maka pilih semua counter & cabang
      let idCounter =''; 
      let idBranch='';
      for(let i=0;i<this.counterByLogin.length;i++){
        idCounter += this.counterByLogin[i]+',';
      }
      idCounter = idCounter.replace(/(^,)|(,$)/g, "");
      this.counterId = idCounter;

      for(let i=0;i<this.branchData.length;i++){ 
        idBranch += this.branchData[i]['id']+',';
      } 
      idBranch = idBranch.replace(/(^,)|(,$)/g, "");
      this.branchId = idBranch;
    }
  }

  resetFilter1(){
    this.counterId ='';
    this.branchId  ='';
  }
}