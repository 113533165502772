import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
})
export class ShowMoreComponent implements OnInit {

  @Input() title: any[];
  @Input() tableHeader: any[];
  @Input() tableContent: any[];
  constructor(
    private modalController : ModalController
  ) { }

  ngOnInit() {
  }

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }

}
