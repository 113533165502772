import { Component, OnInit } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { UtilService } from '../../../service/util.service';
import { UserProfile } from '../../../models/user-profile.model';
import { RoleService } from '../role.service';
import { Role } from '../../../models/role.model';
import { UserData } from '../../../providers/user-data';
import { Router } from '@angular/router';
import { RoleAccess } from '../../../models/role-access.model';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.page.html',
  styleUrls: ['./role-create.page.scss'],
})
export class RoleCreatePage implements OnInit {

  token: any;
  formRoleCreate: FormGroup;
  userProfile: UserProfile = new UserProfile();
  accessOptions: any[] = ['No', 'Yes'];
  roleAccess = new RoleAccess();

  constructor(
    private fb: FormBuilder,
    private storage: Storage,
    private utilService: UtilService,
    private alertController: AlertController,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private roleService: RoleService,
    private userData: UserData,
    private router: Router
  ) { }

  ngOnInit() {
    this.buildFormRoleCreate();

    Promise.all([
      this.storage.get('user_token'),
      this.storage.get('user_profile'),
      this.storage.get('user_menu_access')
    ])
    .then(([token, profile, access]) => {
      if(token) {
        this.token = token;
        this.userProfile = new UserProfile(profile);
        this.roleAccess = this.userData.checkAccess(this.router.url, access, this.userProfile);
        if(!this.roleAccess || this.roleAccess.create!='1'){
          this.toastCtrl.create({ duration: 2000, message: 'You Are Not Allowed to Access this Page' }).then(t => t.present());
          this.navCtrl.navigateForward(['/']);
        }
      } else {
        this.toastCtrl.create({ duration: 2000, message: 'Silahkan login terlebih dahulu.' }).then(t => t.present());
        this.navCtrl.navigateForward(['/login']);
      }
    });
  }

  buildFormRoleCreate() {
    this.formRoleCreate = this.fb.group({
      roleName: [null, Validators.required],
      allAccessOpt: [0, Validators.required]
    });
  }

  inputRole() {
    this.utilService.loadingPresent('Harap tunggu...')
    .then(() => {
      const roleForm = this.formRoleCreate.getRawValue();
      const role = new Role();
      role.role_name = roleForm.roleName;
      role.all_access = roleForm.allAccessOpt.toString();
      role.created_by = this.userProfile.username;

      this.roleService.addRole(role).subscribe((response) => {
        this.utilService.loadingDismiss();
        if(response.status.code === 201) {
          this.showConfirmInput();
        } else {
          this.toastCtrl.create({ duration: 2000, message: 'Terdapat Error' }).then(t => t.present());
        }
      }, () => {
        this.utilService.loadingDismiss();
        this.toastCtrl.create({ duration: 2000, message: 'Gagal terhubung ke server' }).then(t => t.present());
      })
    });
  }

  async showConfirmInput() {
    const alert = await this.alertController.create({
      header: 'Notification',
      cssClass:'custom-alert-class',
      message: 'Data sudah tersimpan!',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.navCtrl.navigateForward(['/role']);;
          }
        }
      ]
    });

    await alert.present();
  }

}
