import { Component, OnInit ,Input} from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-report-point-regular',
  templateUrl: './report-point-regular.component.html',
  styleUrls: ['./report-point-regular.component.scss'],
})
export class ReportPointRegularComponent implements OnInit {

  @Input() reportDataDetail: any;
  @Input() reportDataPoint: any[];
  
  @Input() pointTotal: any;
  @Input() pointUsed: any;
  @Input() pointRemains: any;
  token: any;
  pointMutationData: any[] = [];

  customerName : any;
  customerPhone : any;

  constructor(
    public modalController: ModalController) { }

  ngOnInit() {      
    if(this.reportDataPoint.length > 0){
      this.pointMutationData = this.reportDataPoint;
    }
    this.customerName = this.reportDataDetail.customer_name;
    this.customerPhone = this.reportDataDetail.phone;
  }

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }
}
