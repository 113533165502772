import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss'],
})
export class WarehouseListComponent implements OnInit {
  @Input() warehouseList: any[];

  constructor(
    public modalController: ModalController) { }

  ngOnInit() {}

  async dismissModal() {
    const close: string = "Modal Removed";
    await this.modalController.dismiss(close);
  }

}
