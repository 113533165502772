import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MyService } from '../../service/my.service';

@Component({
  selector: 'app-sales-target',
  templateUrl: './sales-target.component.html',
  styleUrls: ['./sales-target.component.scss'],
})
export class SalesTargetComponent implements OnInit {

  @Input() productId: any;
  @Input() productName: any;
  @Input() productTarget: any;
  newProductTarget: any = '0';

  constructor(
    public modalController: ModalController,
    private myService:MyService
  ) { }

  ngOnInit() {
    this.productTarget = this.productTarget!=0?this.myService.separator(parseInt(this.productTarget).toString()):"0";
  }
  async dismissModal() {
    let close: any[] = [];
    if(this.newProductTarget !== 0) {
      let productData = {
        product_id: this.productId,
        target: this.newProductTarget !='' ? this.myService.removeSeparator(this.newProductTarget) :"0"
      };

      close.push(productData);
    } else {
      let productData = {
        product_id: this.productId,
        target: this.productTarget
      };

      close.push(productData);
    }
    
    await this.modalController.dismiss(close);
  }

  separatorTarget(){
    this.newProductTarget = this.myService.separator(this.newProductTarget.toString());
    this.newProductTarget = String(this.newProductTarget).replace(/[^0-9,]/g, '');
  }
  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
}
